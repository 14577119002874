import { gql, useApolloClient } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { MotiText, MotiView } from 'moti';
import { FC, useEffect, useMemo } from 'react';
import { View } from 'react-native';
import { CheckIcon } from 'react-native-heroicons/outline';
import { Easing } from 'react-native-reanimated';
import { SafeAreaView } from 'react-native-safe-area-context';
import { BUNDLE_SLOTS, STOCK_SLOTS } from '../../constants/portfolioIntended';
import { INSTRUMENT_ESG } from '../../fragments/instrument';
import { PRE_PORTFOLIO_STATS_FIELDS } from '../../fragments/prePortfolio';
import { STACK_FIELDS } from '../../fragments/stack';
import { StackFieldsFragment, useGetPortfolioInsightsQuery } from '../../generated/graphql';
import { AllStackParamList } from '../../navigation';
import { LoggedInStackParamList, LoggedOutStackParamList } from '../../navigation/RootStackNavigator';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { colors, useTailwind } from '../../theme';
import { Loader } from '../../ui/icons';
import { Text } from '../../ui/Text';
import { Budgeting } from '../../ui/VectorImages';
import { round } from '../../util/number';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, usePortfolioIntendedPersistedStore } from '../../zustand/store';
import { flattenPortfolioComponents } from './util';

export type Props = NativeStackScreenProps<
  AllStackParamList & LoggedInStackParamList & LoggedOutStackParamList,
  'OnboardingCreatingPortfolio'
>;

export const GET_PORTFOLIO_INSIGHTS = gql`
  ${PRE_PORTFOLIO_STATS_FIELDS}
  ${INSTRUMENT_ESG}
  query GetPortfolioInsights($cashProportion: Float!, $components: [ComponentInput!]!) {
    portfolioInsights(cashProportion: $cashProportion, components: $components) {
      insights {
        inceptionToDateReturn
        annualisedReturn
        annualisedVolatility
        volatilities {
          volatility
          instrument {
            id
            displayName
          }
        }
        sharpeRatio
        maximumDrawdown
        correlation
        esg {
          ...InstrumentEsg
        }
        countryCashWeight
        countryWeights {
          country {
            id
            name
          }
          weight
        }
        sectorCashWeight
        sectorWeights {
          sector {
            id
            name
          }
          weight
        }
        marketCapCashWeight
        marketCapWeights {
          weight
          marketCap
        }
      }
    }
  }
`;

export const OnboardingCreatingPortfolio: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const apolloClient = useApolloClient();
  const isMarketing = usePersistedStore(({ isMarketing }) => isMarketing);
  const { baseLayer, stackLayer, stockLayer, setInsights } = usePortfolioIntendedPersistedStore(
    ({ baseLayer, stackLayer, stockLayer, setInsights }) => ({ baseLayer, stackLayer, stockLayer, setInsights }),
  );
  // const loggedIn = usePersistedStore(({ isUserLoggedIn }) => isUserLoggedIn);
  const { flattenedPortfolioComponents, cashWeight } = useMemo(() => {
    /**
     * Read selected items directly from cache
     */
    const selectedBase = apolloClient.readFragment<StackFieldsFragment>({
      id: `Stack:${baseLayer.constituentIds[0]}`,
      fragment: STACK_FIELDS,
    });
    const selectedBundles = stackLayer.constituentIds.map((stackId) => {
      return apolloClient.readFragment<StackFieldsFragment>({
        id: `Stack:${stackId}`,
        fragment: STACK_FIELDS,
      });
    });

    const baseItemWeight = baseLayer.weight;
    const bundleItemWeight = stackLayer.weight / BUNDLE_SLOTS;
    const stockItemWeight = stockLayer.weight / STOCK_SLOTS;
    const cashSlots = STOCK_SLOTS - stockLayer.constituents.length;
    const cashWeight = cashSlots * stockItemWeight;

    const flattenedPortfolioComponents = flattenPortfolioComponents(
      selectedBase?.components?.nodes ?? [],
      baseItemWeight,
      (selectedBundles?.map((bundle) => bundle?.components?.nodes ?? []) ?? []).flat(),
      bundleItemWeight,
      stockLayer.constituents,
      stockItemWeight,
    );

    return { flattenedPortfolioComponents, cashWeight };
  }, [apolloClient, baseLayer, stackLayer, stockLayer]);

  const { data, loading, error } = useGetPortfolioInsightsQuery({
    variables: {
      cashProportion: round(cashWeight, 12),
      components: flattenedPortfolioComponents.map(({ instrument, weight }) => ({ instrument: instrument.id, weight })),
    },
  });

  // Do not throw error, only record it
  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (data?.portfolioInsights?.insights) {
      setInsights(data.portfolioInsights.insights, flattenedPortfolioComponents);
    }
  }, [data, setInsights, flattenedPortfolioComponents]);

  // Fast forward to enter email screen or sign up screen
  const onTickAnimationFinish = () => {
    if (isMarketing) {
      navigation.replace('OnboardingEnterEmail');
    } else {
      navigation.replace('SignUp', { external: false, hideBackButton: true });
    }
  };

  return (
    <SafeAreaView style={tailwind('bg-primary-lightest pb-4 flex-grow')}>
      <ScreenSidePadding style={tailwind('flex-grow')}>
        <View style={tailwind('h-2/3 items-center justify-end')}>
          <View style={tailwind('mb-8 justify-center')}>
            <Budgeting />
          </View>
          {/* Fixed height view to prevent layout jump when text changes */}
          <View style={{ height: 96 }}>
            {loading ? (
              <Text style={tailwind('text-default text-center text-3xl font-semibold')}>
                Creating your portfolio...
              </Text>
            ) : (
              <MotiText
                transition={{ type: 'timing', duration: 200 }}
                from={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                style={tailwind('text-default text-center text-3xl font-semibold')}
              >
                All set up!
              </MotiText>
            )}
          </View>
        </View>
        <View style={tailwind('h-1/3')}>
          {loading ? (
            <View style={tailwind('flex-grow items-center pt-24')}>
              <MotiView
                transition={{ type: 'timing', duration: 500, easing: Easing.linear, loop: true, repeatReverse: false }}
                from={{ transform: [{ rotate: '0deg' }] }}
                animate={{ transform: [{ rotate: '360deg' }] }}
                style={{ height: 32, width: 32 }}
              >
                <Loader height={32} width={32} />
              </MotiView>
            </View>
          ) : (
            <View style={tailwind('flex-grow items-center justify-between')}>
              <FadeInTick />
              <MotiView
                transition={{ type: 'timing', delay: 200, duration: 400 }}
                from={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                style={tailwind('self-end')}
                onDidAnimate={onTickAnimationFinish}
              >
                {/* <Button
                  size="large"
                  text={loggedIn ? 'Continue' : 'Create account'}
                  EndIcon={ArrowRightIcon}
                  onPress={() =>
                    loggedIn ? navigation.navigate('Tabs') : navigation.navigate('SignUp', { external: false })
                  }
                /> */}
              </MotiView>
            </View>
          )}
        </View>
      </ScreenSidePadding>
    </SafeAreaView>
  );
});

const FadeInTick: FC = () => {
  const tailwind = useTailwind();
  return (
    <MotiView
      from={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'timing', duration: 200 }}
      style={[tailwind('bg-white items-center rounded-full justify-center'), { height: 52, width: 52 }]}
    >
      <View style={tailwind('items-end rounded-full')}>
        <CheckIcon height={24} width={24} stroke={colors.primary.default} />
        {/* mask, animates to 0 width to for tick effect */}
        <MotiView
          transition={{ type: 'timing', duration: 500, delay: 200 }}
          from={{ width: 24 }}
          animate={{ width: 0 }}
          style={tailwind('h-6 absolute bg-white')}
        />
      </View>
    </MotiView>
  );
};
