import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, View } from 'react-native';
import { DiscoverCategoryType, SectorDiscoverCategoryFeedItem } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { shadowMd, useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';

export type Props = Pick<SectorDiscoverCategoryFeedItem, 'id' | 'sector'>;

export const SectorCard: React.FC<Props> = ({ sector }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <Pressable
      accessibilityRole="button"
      onPress={() =>
        navigation.push('DiscoverCategory', {
          categoryId: sector?.id ?? '',
          categoryType: DiscoverCategoryType.Sector,
        })
      }
      style={[shadowMd, tailwind('rounded-lg px-3 pt-3 w-40 h-24')]}
    >
      <View style={tailwind('h-9 w-9 rounded-full bg-teal-50 items-center justify-center')}>
        {sector?.logoUrl && (
          <Image
            style={tailwind('h-4 w-4')}
            accessibilityIgnoresInvertColors
            resizeMode="contain"
            source={{ uri: sector?.logoUrl ?? '' }}
          />
        )}
      </View>
      <View style={tailwind('justify-center flex-1')}>
        <Text numberOfLines={2}>{sector?.name}</Text>
      </View>
    </Pressable>
  );
};
