import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Account } from '../screens/Account';
import { LoggedInStackParamList } from './RootStackNavigator';
import { TabsParamList } from './TabsNavigator';

export type AccountStackParamList = {
  Account: undefined;
};

const Stack = createNativeStackNavigator<AccountStackParamList>();

type Props = CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'AccountTab'>,
  NativeStackScreenProps<LoggedInStackParamList>
>;

/**
 * Account Stack
 */
export const AccountNavigator: React.FC<Props> = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Account" component={Account} />
    </Stack.Navigator>
  );
};
