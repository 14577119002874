import { useNavigation } from '@react-navigation/native';
import { capitalize } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment, ReportRating } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { colors, useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { Gauge } from '../../icons';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';
import { ReportRatingSnippet } from '../../ReportRatingSnippet';

type ReportRatingFeedItemFragment = NarrowByTypename<FeedCardFieldsFragment, 'ReportRatingFeedItem'>;

export type Props = ReportRatingFeedItemFragment;

export const ReportRatingCard: React.FC<Props> = ({
  buyCount,
  sellCount,
  holdCount,
  averageRating,
  instrument,
  latestPublishDate,
  id,
  __typename,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  const ratingText = capitalize(averageRating ?? '');

  const ratingColours: Record<ReportRating, string> = {
    [ReportRating.Buy]: colors.green[500],
    [ReportRating.Sell]: colors.red[500],
    [ReportRating.Hold]: colors.warmGray[600],
  };

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, instrument }}
        eventTime={new Date(latestPublishDate ?? '')}
        icon={<Gauge />}
      >
        Analyst rating
      </CardTitle>
      <CardHeadline>
        <CardHeadlineText>
          An analyst report with a{' '}
          <Text style={[tailwind('font-medium'), { color: ratingColours[averageRating ?? ReportRating.Buy] }]}>
            {ratingText}
          </Text>{' '}
          rating has been published on{' '}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Feed card type': __typename,
                'Destination screen': 'Instrument',
                'Feed item id': id,
                'Instrument ticker': instrument?.ticker ?? '',
              });
            }}
          >
            {instrument?.name}
          </Link>
          .
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Feed card type': __typename,
            'Destination screen': 'Instrument',
            'Feed item id': id ?? '',
            'Instrument ticker': instrument?.ticker ?? '',
          });
        }}
      >
        <View style={tailwind('mb-3')}>
          <InstrumentPriceRow {...instrument} />
        </View>
        <ReportRatingSnippet
          buyCount={buyCount ?? 0}
          sellCount={sellCount ?? 0}
          holdCount={holdCount ?? 0}
          averageRating={averageRating ?? ReportRating.Hold}
        />
      </OutlineInnerCard>
    </Card>
  );
};
