import * as Device from 'expo-device';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import shallow from 'zustand/shallow';
import { analytics } from '../services/analytics';
import { branch } from '../services/branch';
import { onesignal } from '../services/onesignal';
import { setSentryUser } from '../services/sentry';
import { usePersistedStore } from '../zustand/store';

/**
 * call segment identify and other similar identify methods.
 *
 * Triggered when app bootstraps and persisted logged in true set or when you login/sign up, or email changes etc
 */
export const useIdentify = () => {
  const [hasRun, setHasRun] = useState(false);
  const { userId, email, nickname, isStaff, isUserLoggedIn } = usePersistedStore(
    (state) => ({
      userId: state.userId,
      email: state.email,
      nickname: state.nickname,
      isStaff: state.isStaff,
      isUserLoggedIn: state.isUserLoggedIn,
    }),
    shallow,
  );

  useEffect(() => {
    if (!isUserLoggedIn) {
      setHasRun(false);
      return;
    }
    try {
      console.log(`User logged in, running useIdentify`);

      setSentryUser(`${userId}`, { isStaff: isStaff ?? false });
      analytics.identify(`${userId}`, {
        email,
        nickname,
        firstName: nickname,
        isStaff,
        deviceOsName: Device.osName,
        deviceOsVersion: Device.osVersion,
        deviceBrand: Device.brand,
        deviceManufacturer: Device.manufacturer,
        deviceModelName: Device.modelName,
        devicePlatformApiLevel: Device.platformApiLevel,
        deviceWindowWidth: Math.round(Dimensions.get('window').width),
        deviceWindowHeight: Math.round(Dimensions.get('window').height),
        deviceScreenWidth: Math.round(Dimensions.get('screen').width),
        deviceScreenHeight: Math.round(Dimensions.get('screen').height),
      });
      branch.setIdentity(`${userId}`);
      onesignal.setIdentity(`${userId}`);
    } catch (e) {
      console.error(`Error in useIdentify`, e);
    } finally {
      setHasRun(true);
    }
  }, [email, isStaff, isUserLoggedIn, nickname, userId]);

  // return hasRun to make testing easier and perhaps one day some UI component might say "refreshing"
  // Dont need to use this hook response in App code probably
  return { hasRun };
};
