import * as shape from 'd3-shape';
import { FC } from 'react';
import Svg, { Path } from 'react-native-svg';
import { colors, useTailwind } from '../../theme';

type Props = {
  /**
   * Percentage as a decimal, e.g. 0.5 for 50%.
   */
  progress: number;
  color: string;
};

const arc = shape.arc().innerRadius(42).outerRadius(50).cornerRadius(8);

export const CircularProgress: FC<Props> = ({ color, progress }) => {
  const tailwind = useTailwind();
  return (
    <Svg style={tailwind('h-8 w-8')} viewBox="-50 -50 100 100">
      <Path
        d={arc({ startAngle: 0, endAngle: Math.PI * 2, innerRadius: 0, outerRadius: 0 }) ?? ''}
        fill={colors.greys[200]}
      />
      <Path
        d={arc({ startAngle: 0, endAngle: Math.PI * 2 * progress, innerRadius: 0, outerRadius: 0 }) ?? ''}
        fill={color}
      />
    </Svg>
  );
};
