import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { StackFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { StackCard } from '../../../old/StackCard';
import { useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { isNotNull } from '../../../util/typeGuards';

type Props = {
  items: StackFieldsFragment[];
};

export const StacksSideScroll: React.FC<Props> = ({ items }) => {
  const tailwind = useTailwind();
  return (
    <FlatList
      contentContainerStyle={tailwind('pt-2 pb-4 px-6')}
      horizontal
      data={items ?? []}
      showsHorizontalScrollIndicator={false}
      ItemSeparatorComponent={Separator}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
    />
  );
};

export const Separator: React.FC = () => {
  const tailwind = useTailwind();
  return <View style={tailwind('pl-4')} />;
};

const keyExtractor = (item: StackFieldsFragment, index: number) => `${item && 'id' in item ? item.id : index}`;

const renderItem: ListRenderItem<StackFieldsFragment> = ({ item }) => {
  return <SideScrollStackCard item={item} />;
};

const SideScrollStackCard: React.FC<{ item: StackFieldsFragment }> = ({ item }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <Pressable
      accessibilityRole="button"
      onPress={() => navigation.push('Stack', { id: item?.id ?? '' })}
      style={tailwind('w-64')}
    >
      <StackCard
        instruments={item?.components?.nodes.map((i) => i.instrument).filter(isNotNull) ?? []}
        key={item?.id ?? ''}
        totalCount={item?.components?.pageInfo.totalCount ?? 0}
        name={item?.content?.name ?? ''}
        description={item?.content?.description ?? ''}
      />
    </Pressable>
  );
};
