import React from 'react';

export function useRefresh<TArgs, TRes>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refetch: (args?: TArgs) => Promise<TRes>,
): { refreshing: boolean; onRefresh: (args?: TArgs) => Promise<void> } {
  const [refreshing, setRefreshing] = React.useState(false);
  const onRefresh = React.useCallback(
    async (args?: TArgs) => {
      setRefreshing(true);
      await refetch(args);
      setRefreshing(false);
    },
    [refetch],
  );
  return { refreshing, onRefresh };
}
