import { StyleSheet, View } from 'react-native';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';

export const Empty: React.VFC = () => {
  const tailwind = useTailwind();
  return (
    <View style={[StyleSheet.absoluteFill, tailwind('justify-center')]}>
      <Text style={tailwind('text-center text-lg text-warmGray-600')}>No chart data</Text>
    </View>
  );
};
