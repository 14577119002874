import { Dimensions, PixelRatio } from 'react-native';
import { DESKTOP_MAX_WIDTH } from '../ui/SafeAreaView';

const width = Math.min(Dimensions.get('window').width, DESKTOP_MAX_WIDTH);
const height = Dimensions.get('window').height;

export const layout = {
  window: {
    width,
    height,
  },
  pixelRatio: PixelRatio.get(),
  isSmallDevice: width < 375,
};
