import { MotiPressable } from 'moti/interactions';
import React, { useMemo, useState } from 'react';
import { ActivityIndicator, TextStyle, View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useRedirectPress } from '../../hooks/useRedirectPress';
import { textColors, useTailwind } from '../../theme';
import { Text } from '../Text';

type CommonProps = {
  text: string;
  onPress: () => void;
  variant?: 'contained' | 'outlined' | 'text';
  size?: 'small' | 'default' | 'large';
  /** set true when background image on the screen in question is dark */
  isLightButton?: boolean;
  /** true to flex expand to fill container */
  isFullWidth?: boolean;
  isDisabled?: boolean;
  /**
   * Show native ActivityIndicator instead of Icon (or instead of text if no icon)
   */
  isLoading?: boolean;
  /** true to send to sign up variant if logged out */
  redirectIfLoggedOut?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  /** optional accessibility label if different to text */
  label?: string;
};

/** allowed no icons */
type NoIconProps = {
  StartIcon?: never;
  EndIcon?: never;
};

/** Or allowed just a start icon */
type StartIconProps = {
  /** Start Icon centered and next to text. Start Icon and Text replaced by loading anim */
  StartIcon: React.FC<Pick<SvgProps, 'stroke' | 'color' | 'height' | 'width'>>;
  EndIcon?: never;
};

/** Or allowed just an end icon */
type EndIconProps = {
  StartIcon?: never;
  /** End Icon stretches button so text on far left, icon on far right. EndIcon replaced by loading anim when isLoading true. */
  EndIcon: React.FC<Pick<SvgProps, 'stroke' | 'color' | 'height' | 'width'>>;
};

export type Props = (NoIconProps | StartIconProps | EndIconProps) & CommonProps;

/**
 * Common Button
 *
 */
export const Button: React.VFC<Props> = ({
  text,
  onPress,
  variant = 'contained',
  size = 'default',
  isLightButton = false,
  isFullWidth = false,
  isDisabled = false,
  isLoading = false,
  StartIcon,
  EndIcon,
  redirectIfLoggedOut,
  style,
  textStyle,
  label,
}) => {
  const tailwind = useTailwind();
  // measure width of button with text rendered
  // Then for NoIcon case keep button width the same or spinner showing instead of text will make button shrink in size
  const [initialButtonWidth, setInitialButtonWidth] = useState<number>();
  const [isPressed, setIsPressed] = useState(false);
  const accessibilityLabel = label ?? text;

  const { handlePress } = useRedirectPress({
    trackingType: 'button',
    redirectIfLoggedOut,
    onPress,
    accessibilityLabel,
  });

  const hasNoIcon = !StartIcon && !EndIcon;

  return (
    <MotiPressable
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      onPressIn={() => setIsPressed(true)}
      onPress={handlePress}
      onPressOut={() => setIsPressed(false)}
      onLayout={(event) => setInitialButtonWidth(event.nativeEvent.layout.width)}
      disabled={isDisabled || isLoading}
      style={[
        tailwind('flex-row items-center justify-center'),
        isFullWidth ? tailwind('self-stretch') : tailwind('self-start'),
        getButtonColor({ variant, isDisabled, isLightButton }, tailwind),
        getButtonOutlineColor({ variant, isDisabled, isLightButton }, tailwind),
        getButtonPadding({ size }, tailwind),
        getButtonSize({ variant, size }, tailwind),
        // keep button width the same for NoIcon case when isLoading as spinner replaces text
        isLoading && hasNoIcon && initialButtonWidth ? { minWidth: initialButtonWidth } : null,
        style,
      ]}
      animate={useMemo(
        () =>
          ({ pressed }) => {
            'worklet';
            return {
              scale: pressed ? 0.97 : 1,
            };
          },
        [],
      )}
    >
      <View style={[tailwind('flex-row'), EndIcon ? tailwind('flex-grow justify-between') : null]}>
        {StartIcon && (
          <View style={[tailwind('justify-center'), getIconPadding({ size, isEndIcon: false }, tailwind)]}>
            {isLoading ? (
              <CustomSizeActivityIndicator variant={variant} isDisabled={isDisabled} isLightButton={isLightButton} />
            ) : (
              <StartIcon
                color={getIconStrokeColor({ variant, isDisabled, isLightButton })}
                height={getIconSize({ size })}
                width={getIconSize({ size })}
              />
            )}
          </View>
        )}

        {hasNoIcon && isLoading ? (
          <CustomSizeActivityIndicator variant={variant} isDisabled={isDisabled} isLightButton={isLightButton} />
        ) : (
          <Text
            style={[
              tailwind('font-regular'),
              getTextColor({ isDisabled, isLightButton, variant }, tailwind),
              getTextSize({ size }, tailwind),
              { opacity: isPressed ? 85 : 100 },
              textStyle,
            ]}
          >
            {text}
          </Text>
        )}

        {EndIcon && (
          <View style={[tailwind('justify-center'), getIconPadding({ size, isEndIcon: true }, tailwind)]}>
            {isLoading && (
              <CustomSizeActivityIndicator variant={variant} isDisabled={isDisabled} isLightButton={isLightButton} />
            )}
            {!isLoading && (
              <EndIcon
                color={getIconStrokeColor({ variant, isDisabled, isLightButton })}
                height={getIconSize({ size })}
                width={getIconSize({ size })}
              />
            )}
          </View>
        )}
      </View>
    </MotiPressable>
  );
};

const CustomSizeActivityIndicator: React.VFC<Required<Pick<Props, 'variant' | 'isDisabled' | 'isLightButton'>>> = ({
  variant,
  isDisabled,
  isLightButton,
}) => {
  const tailwind = useTailwind();
  return (
    <ActivityIndicator
      animating={true}
      color={getIconStrokeColor({ variant, isDisabled, isLightButton })}
      size="small"
      style={tailwind('-ml-0.5 -mr-0.5')}
    />
  );
};

const getButtonColor = (
  args: {
    variant: NonNullable<Props['variant']>;
    isDisabled: boolean;
    isLightButton: boolean;
  },

  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  const { variant, isDisabled, isLightButton } = args;
  if (isDisabled) {
    return tailwind('bg-greys-500');
  }

  switch (variant) {
    case 'contained':
      return isDisabled
        ? tailwind('bg-greys-500')
        : isLightButton
        ? tailwind('bg-white')
        : tailwind('bg-primary-default');
    case 'outlined':
    case 'text':
      return tailwind('bg-transparent');
  }
};

const getButtonOutlineColor = (
  args: {
    variant: NonNullable<Props['variant']>;
    isDisabled: boolean;
    isLightButton: boolean;
  },
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle | null => {
  const { variant, isDisabled, isLightButton } = args;
  if (variant !== 'outlined') return null;

  if (isDisabled) {
    return tailwind('border border-greys-500');
  }
  if (isLightButton) {
    return tailwind('border border-greys-50');
  }

  return tailwind('border border-primary-dark');
};

const getButtonPadding = (
  args: { size: NonNullable<Props['size']> },
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  const { size } = args;

  switch (size) {
    case 'small':
      return tailwind('px-2');
    case 'default':
      return tailwind('px-3');
    case 'large':
      return tailwind('px-4');
  }
};

/**
 * height and rounder corners
 *
 * @param args
 * @param args.size
 * @param args.rounded
 */
const getButtonSize = (
  args: {
    size: NonNullable<Props['size']>;
    variant: NonNullable<Props['variant']>;
  },
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  const { size } = args;
  switch (size) {
    case 'small':
      return tailwind('h-8 rounded-lg');
    case 'default':
      return tailwind('h-10 rounded-xl');
    case 'large':
      return tailwind('h-14 rounded-2xl');
  }
};

const getTextColor = (
  args: {
    variant: NonNullable<Props['variant']>;
    isDisabled: boolean;
    isLightButton: boolean;
  },
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  const { variant, isDisabled, isLightButton } = args;

  switch (variant) {
    case 'contained':
      return isDisabled ? tailwind('text-white') : isLightButton ? tailwind('text-default') : tailwind('text-white');
    case 'outlined':
    case 'text':
      return isDisabled ? tailwind('text-grey') : isLightButton ? tailwind('text-white') : tailwind('text-light');
  }
};

const getTextSize = (
  args: { size: NonNullable<Props['size']> },
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  const { size } = args;
  switch (size) {
    case 'small':
      return tailwind('text-sm');
    case 'default':
      return tailwind('text-base');
    case 'large':
      return tailwind('text-lg');
  }
};

const getIconStrokeColor = (args: {
  variant: NonNullable<Props['variant']>;
  isDisabled: boolean;
  isLightButton: boolean;
}): string => {
  const { variant, isDisabled, isLightButton } = args;
  switch (variant) {
    case 'contained':
      return isDisabled ? textColors.white : isLightButton ? textColors.default : textColors.white;
    case 'outlined':
    case 'text':
      return isDisabled ? textColors.grey : isLightButton ? textColors.white : textColors.default;
  }
};

const getIconSize = (args: { size: NonNullable<Props['size']> }): number => {
  const { size } = args;
  switch (size) {
    case 'small':
      return 16;
    case 'default':
      return 20;
    case 'large':
      return 24;
  }
};

const getIconPadding = (
  args: {
    size: NonNullable<Props['size']>;
    isEndIcon: boolean;
  },
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  const { size, isEndIcon } = args;
  switch (size) {
    case 'small':
      return isEndIcon ? tailwind('pl-2') : tailwind('pr-2');
    case 'default':
      return isEndIcon ? tailwind('pl-2') : tailwind('pr-2');
    case 'large':
      return isEndIcon ? tailwind('pl-2') : tailwind('pr-2');
  }
};
