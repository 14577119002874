/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Sector } from '../models/Sector';
import { request as __request } from '../core/request';

export class SectorService {

    /**
     * @returns Sector
     * @throws ApiError
     */
    public static async apiSectorList({
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<Sector>> {
        const result = await __request({
            method: 'GET',
            path: `/api/sector/`,
            query: {
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * @returns Sector
     * @throws ApiError
     */
    public static async apiSectorRead({
        uuid,
    }: {
        /** A UUID string identifying this sector. **/
        uuid: string,
    }): Promise<Sector> {
        const result = await __request({
            method: 'GET',
            path: `/api/sector/${uuid}/`,
        });
        return result.body;
    }

}
