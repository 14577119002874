import { useEffect, useRef, useState } from 'react';
import { Platform, TextInput, TextStyle, ViewStyle } from 'react-native';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { formatNumber, textToNumber } from '../../util/number';
import { isFiniteNumber } from '../../util/typeGuards';

export type Props = {
  endAdornment?: string;
  startAdornment?: string;
  /** Null when cleared or empty */
  value: number | null;
  /** Null returned when cleared or empty */
  onChange: (price: number | null) => void;
  error?: boolean;
  mantissa?: number;
  containerStyles?: ViewStyle;
  startAdornmentStyle?: TextStyle;
  inputStyle?: TextStyle;
  endAdornmentStyle?: TextStyle;
  placeholder?: string;
  disableAutoFocus?: boolean;
};

export const PriceInput: React.VFC<Props> = ({
  value,
  onChange,
  error,
  startAdornment,
  endAdornment,
  mantissa,
  containerStyles,
  startAdornmentStyle,
  inputStyle,
  endAdornmentStyle,
  placeholder,
  disableAutoFocus,
}) => {
  const tailwind = useTailwind();
  const inputRef = useRef<TextInput>(null);
  const [text, setText] = useState(formatPriceInputText(value, mantissa));

  useEffect(() => {
    if (disableAutoFocus) return;
    /** If this input is focused on screen mount, it may cause navigation jank on iOS. Delaying the focus avoids this. https://github.com/react-navigation/react-navigation/issues/6778 */
    if (Platform.OS === 'ios') {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    } else {
      inputRef.current?.focus();
    }
  }, [inputRef, disableAutoFocus]);

  return (
    <Pressable
      accessibilityRole="button"
      style={[
        tailwind('flex-row h-14 rounded-lg items-center px-3 border'),
        error ? tailwind('border-red-500') : tailwind('border-warmGray-200'),
        containerStyles,
      ]}
      onPress={() => {
        inputRef.current?.focus();
      }}
    >
      {startAdornment && (
        <Text style={[tailwind('text-base text-warmGray-500 mr-1'), startAdornmentStyle]}>{startAdornment}</Text>
      )}
      <TextInput
        accessibilityLabel="Amount"
        onChangeText={(text) => {
          setText(text);
          onChange(text ? textToNumber(text) : null);
        }}
        keyboardType="decimal-pad"
        returnKeyType="next"
        underlineColorAndroid="transparent"
        style={[tailwind('text-warmGray-800 font-normal flex-grow self-stretch'), inputStyle]}
        placeholder={placeholder ?? 'Custom amount'}
        value={text}
        ref={inputRef}
      />
      {endAdornment && <Text style={[tailwind('text-base text-warmGray-500'), endAdornmentStyle]}>{endAdornment}</Text>}
    </Pressable>
  );
};

export const formatPriceInputText = (value: number | null, mantissa = 6) =>
  isFiniteNumber(value) ? formatNumber(value, mantissa, true) : '';
