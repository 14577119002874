import { useBottomSheet } from '@gorhom/bottom-sheet';
import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { IconButton } from '../../old/Button';
import { Divider } from '../../old/Divider';
import { ArrowLeft } from '../../old/icons';
import { Close } from '../../old/icons/Close';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

type Props = {
  type: 'Comments' | 'Replies';
  count: number | undefined;
};

export const HEIGHT = 65;

export const Header: React.VFC<Props> = ({ type, count }) => {
  const tailwind = useTailwind();
  const bottomSheet = useBottomSheet();
  const navigation = useNavigation();
  return (
    <>
      <View style={tailwind('pt-6 pb-3 px-4 flex-row justify-between items-center rounded-t-3xl')}>
        <View style={tailwind('flex-row items-center')}>
          {type === 'Replies' && (
            <IconButton
              style={tailwind('pr-4')}
              accessibilityLabel="back"
              icon={ArrowLeft}
              onPress={() => {
                navigation.canGoBack() && navigation.goBack();
              }}
            />
          )}
          <Text style={tailwind('text-lg font-semibold text-warmGray-600')}>{type}</Text>
          <Text
            style={tailwind('text-lg font-normal text-warmGray-500 px-2')}
            accessibilityLabel={`${type} Count ${count}`}
          >
            {count}
          </Text>
        </View>
        <IconButton
          accessibilityLabel="close"
          icon={Close}
          onPress={() => {
            bottomSheet.close();
          }}
        />
      </View>
      <Divider />
    </>
  );
};
