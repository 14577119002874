import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { colors, useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { round } from '../../../util/number';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { CorrelationNegativeFilled } from '../../icons/CorrelationNegativeFilled';
import { CorrelationPositiveFilled } from '../../icons/CorrelationPositiveFilled';
import { Info } from '../../icons/Info';
import { LineChart } from '../../icons/LineChart';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';

export type Props = NarrowByTypename<FeedCardFieldsFragment, 'CorrelationFeedItem'>;

export const CorrelationCard: React.FC<Props> = ({
  correlation,
  objectInstrument,
  subjectInstrument,
  windowSizeDays,
  id,
  __typename,
  created,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  const isPositiveCorrelation = correlation && correlation > 0;
  const cardHeadlineText = isPositiveCorrelation ? 'Positive correlation' : 'Negative correlation';
  const CardIcon = isPositiveCorrelation ? CorrelationPositiveFilled : CorrelationNegativeFilled;

  const info = isPositiveCorrelation
    ? "Consider adding this stock to your portfolio if you're looking for a similar return profile to"
    : 'May provide a good diversification opportunity when combined with';

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, subjectInstrument }}
        eventTime={new Date(created ?? '')}
        icon={<LineChart color={colors.warmGray['400']} />}
      >
        Price correlation
      </CardTitle>
      <CardHeadline icon={<CardIcon height={36} width={36} />}>
        <CardHeadlineText>
          <Text style={tailwind('font-bold text-teal-800 font-medium')}>{`${cardHeadlineText}:`}</Text> here&apos;s a
          stock whose price{' '}
          {isPositiveCorrelation ? 'moved in tandem with' : 'tended to move in the opposite direction to'}{' '}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: subjectInstrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Feed card type': __typename,
                'Destination screen': 'Instrument',
                'Feed item id': id ?? '',
                'Instrument ticker': subjectInstrument?.ticker ?? '',
              });
            }}
          >
            {subjectInstrument?.name}
          </Link>{' '}
          over the past year.
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: objectInstrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Feed card type': __typename,
            'Destination screen': 'Instrument',
            'Feed item id': id ?? '',
            'Instrument ticker': objectInstrument?.ticker ?? '',
          });
        }}
      >
        <View style={tailwind('mb-3')}>
          <InstrumentPriceRow {...objectInstrument} />
        </View>
        <View style={tailwind('flex-row text-xs pt-8 px-3 py-1.5 rounded-lg bg-warmGray-100')}>
          <Text style={tailwind('text-warmGray-500 text-xs')}>{windowSizeDays}D Correlation Coefficient</Text>
          <Text style={tailwind('flex-1 text-right font-medium text-warmGray-800 text-xs')}>
            {correlation ? round(correlation, 1) : ''}
          </Text>
        </View>
        <View style={tailwind('flex-row px-6 pt-5 pb-3')}>
          <View style={tailwind('pr-1 mt-0.5')}>
            <Info />
          </View>
          <Text style={[tailwind('text-warmGray-500'), { fontSize: 10 }]}>
            {info}{' '}
            <Link
              redirectIfLoggedOut
              onPress={() => {
                navigation.push('Instrument', { instrumentId: subjectInstrument?.id ?? '' });
                analytics.track('Feed card pressed', {
                  'Feed card type': __typename,
                  'Destination screen': 'Instrument',
                  'Feed item id': id ?? '',
                  'Instrument ticker': subjectInstrument?.ticker ?? '',
                });
              }}
            >
              {subjectInstrument?.name}
              {'.'}
            </Link>
          </Text>
        </View>
      </OutlineInnerCard>
    </Card>
  );
};
