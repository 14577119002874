import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Watchlist } from '../screens/Watchlist';
import { LoggedInStackParamList } from './RootStackNavigator';
import { TabsParamList } from './TabsNavigator';

export type WatchlistStackParamList = {
  Watchlist: undefined;
};

const Stack = createNativeStackNavigator<WatchlistStackParamList>();

type Props = CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'WatchlistTab'>,
  NativeStackScreenProps<LoggedInStackParamList>
>;

export const WatchlistNavigator: React.FC<Props> = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Watchlist" component={Watchlist} />
    </Stack.Navigator>
  );
};
