import React from 'react';
import { StyleSheet } from 'react-native';
import Animated, { useAnimatedReaction, useAnimatedStyle } from 'react-native-reanimated';
import { useTailwind } from '../../../theme';
import { Props } from './props';
import { SIZE } from './size';

export const CursorPoint: React.VFC<Props> = ({ position, color: animatedColor }) => {
  const tailwind = useTailwind();
  const [color, setColor] = React.useState(animatedColor.value);

  useAnimatedReaction(
    () => animatedColor.value,
    (color, prevColor) => {
      if (color !== prevColor) {
        setColor(color);
      }
    },
  );

  const cursorStyles = useAnimatedStyle(() => ({
    transform: [{ translateX: position.value.x - SIZE / 2 }, { translateY: position.value.y - SIZE / 2 }],
  }));

  return (
    <Animated.View
      style={[
        StyleSheet.absoluteFill,
        { width: SIZE, height: SIZE, backgroundColor: color },
        tailwind('rounded-full'),
        cursorStyles,
      ]}
    />
  );
};
