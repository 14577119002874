import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Discord: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={24} fill="none" {...props}>
      <Path
        d="M19.087 5.525a17.051 17.051 0 00-4.254-1.309c-.201.36-.383.73-.545 1.108a15.84 15.84 0 00-4.72 0 11.734 11.734 0 00-.545-1.108c-1.47.25-2.901.692-4.257 1.312-2.692 3.983-3.422 7.867-3.057 11.695a17.145 17.145 0 005.217 2.62 12.6 12.6 0 001.117-1.802 11.092 11.092 0 01-1.76-.84c.148-.108.293-.218.432-.325a12.254 12.254 0 0010.43 0c.142.115.286.226.432.325-.562.331-1.152.613-1.763.841a12.5 12.5 0 001.117 1.8 17.067 17.067 0 005.22-2.617c.428-4.44-.731-8.288-3.064-11.7zM8.505 14.869c-1.016 0-1.856-.923-1.856-2.058 0-1.135.81-2.066 1.853-2.066s1.876.93 1.858 2.066c-.017 1.135-.818 2.058-1.855 2.058zm6.85 0c-1.018 0-1.855-.923-1.855-2.058 0-1.135.81-2.066 1.855-2.066 1.044 0 1.872.93 1.854 2.066-.018 1.135-.818 2.058-1.854 2.058z"
        fill="#0F766E"
      />
    </Svg>
  );
};
