import { VFC } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { useTailwind } from '../../theme';

export const FooterSpinner: VFC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('items-center py-2')}>
      <View>
        <ActivityIndicator animating={true} />
      </View>
    </View>
  );
};
