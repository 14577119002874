import { View } from 'react-native';
import { CommentSkeleton } from '../../old/Comment/Comment';
import { useTailwind } from '../../theme';
import { SkeletonView } from '../../ui/Skeleton';

export const CommentsSkeleton: React.VFC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-grow')}>
      <SkeletonView>
        {new Array(3).fill(null).map((_, i) => (
          <CommentSkeleton key={i} />
        ))}
      </SkeletonView>
    </View>
  );
};
