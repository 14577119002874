import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC, useState } from 'react';
import { View } from 'react-native';
import { PORTFOLIO_WEIGHTINGS, RiskLevel } from '../../constants/portfolioIntended';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { AllStackParamList } from '../../navigation';
import { analytics } from '../../services/analytics';
import { useTailwind } from '../../theme';
import { Fab } from '../../ui/Button';
import { InfoLink } from '../../ui/InfoLink';
import { ProgressBar } from '../../ui/ProgressBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { portfolioWeightingToRiskLevel } from '../../util/portfolioIntended';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePortfolioIntendedPersistedStore } from '../../zustand/store';
import { OnboardingSlider } from './OnboardingSlider/OnboardingSlider';

export type Props = NativeStackScreenProps<AllStackParamList, 'OnboardingAboutYou'>;

export const OnboardingAboutYou: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const {
    baseLayer: { weight: base },
    stackLayer: { weight: bundles },
    stockLayer: { weight: stocks },
    setWeights,
  } = usePortfolioIntendedPersistedStore(({ baseLayer, stackLayer, stockLayer, setWeights }) => ({
    baseLayer,
    stackLayer,
    stockLayer,
    setWeights,
  }));
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<RiskLevel>(
    portfolioWeightingToRiskLevel({ base, bundles, stocks }) ?? 'medium',
  );
  const tailwind = useTailwind();
  const { present } = useBottomSheet();
  const onRiskLevelChange = (riskLevel: RiskLevel, action: 'drag' | 'press') => {
    analytics.track('Select risk level', {
      action,
      riskLevel,
    });
    setSelectedRiskLevel(riskLevel);
  };

  const onPressNext = () => {
    const { base, bundles, stocks } = PORTFOLIO_WEIGHTINGS[selectedRiskLevel];
    setWeights({ base, stack: bundles, stock: stocks });
    return navigation.navigate('OnboardingSelectBase');
  };

  return (
    <SafeAreaView>
      <ScrollView stickyHeaderIndices={[0]} contentContainerStyle={tailwind('px-6 pb-24')}>
        <View style={tailwind('bg-white')}>
          <TopBar endAdornment={<ProgressBar width={42} progress={2 / 6} />} />
        </View>
        <TitleBar text="About you" />
        <View style={tailwind('flex-row pt-8 pb-5 justify-between')}>
          <Text style={tailwind('text-grey text-sm')}>Weights distribution:</Text>
          <InfoLink onPress={() => present(<InfoSheet />)} text="What is this?" />
        </View>
        <OnboardingSlider onValueChange={onRiskLevelChange} initialValue={selectedRiskLevel} />
      </ScrollView>
      <Fab onPress={onPressNext} />
    </SafeAreaView>
  );
});

const InfoSheet: FC = () => {
  const tailwind = useTailwind();

  return (
    <View style={tailwind('pb-12 px-6')}>
      <Text style={tailwind('text-default text-sm font-medium pt-8 pb-4')}>What{"'"}s this?</Text>
      <Text style={tailwind('text-base text-default h-32')}>
        Portfolio weightings determine the percentage of your portfolio split across our three products: Base, Bundles
        and single stocks.
        {'\n\n'}
        Your portfolio weightings will change depending on how much risk you want to take on.
        {'\n\n'}
        Use the slider to understand the different levels, and find the one that suits you best.
      </Text>
    </View>
  );
};
