import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { relativeTimeTo } from '../../../util/date';
import { formatNumber } from '../../../util/number';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { TrendDownFilled, TrendUp, Warning } from '../../icons';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';

export type Props = Pick<
  NarrowByTypename<FeedCardFieldsFragment, 'RelativeStrengthIndexFeedItem'>,
  'isOverbought' | 'eventDate' | 'rsiStartDate' | '__typename' | 'id' | 'value' | 'instrument'
>;

export const RSICard: React.FC<Props> = ({
  eventDate,
  rsiStartDate,
  instrument,
  isOverbought,
  __typename,
  value,
  id,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, instrument }}
        icon={<Warning />}
        eventTime={eventDate ? new Date(eventDate) : undefined}
      >
        Technical indicator
      </CardTitle>
      <CardHeadline icon={isOverbought ? <TrendDownFilled /> : <TrendUp />}>
        <CardHeadlineText>
          {isOverbought && <Text style={tailwind('text-red-600')}>Overbought: </Text>}
          {!isOverbought && <Text style={tailwind('text-green-600')}>Oversold: </Text>}
          RSI {isOverbought ? 'bearish' : 'bullish'} signal for {relativeTimeTo(rsiStartDate ?? '', true)} indicates{' '}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Feed card type': __typename,
                'Destination screen': 'Instrument',
                'Feed item id': id ?? '',
                'Instrument ticker': instrument?.ticker ?? '',
              });
            }}
          >
            {instrument?.name}
          </Link>{' '}
          may be due a price {isOverbought ? 'downturn' : 'upturn'}.
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Feed card type': __typename,
            'Destination screen': 'Instrument',
            'Feed item id': id ?? '',
            'Instrument ticker': instrument?.ticker ?? '',
          });
        }}
      >
        <View>
          <InstrumentPriceRow {...instrument} />
          <View style={tailwind('flex-row text-xs mt-3 py-1.5 px-3 rounded-lg bg-warmGray-100')}>
            <Text style={tailwind('text-warmGray-500 text-xs')}>RSI</Text>
            <Text style={tailwind('flex-1 text-right font-medium text-warmGray-800 text-xs')}>
              {value && formatNumber(value, 0)}
            </Text>
          </View>
        </View>
      </OutlineInnerCard>
    </Card>
  );
};
