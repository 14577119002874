import { ApolloCache, DefaultContext, gql, MutationHookOptions } from '@apollo/client';
import { ReactNativeFile } from 'apollo-upload-client';
import { CORE_USER_PROFILE_FIELDS } from '../../fragments/userProfile';
import {
  DeleteProfileImageMutation,
  Exact,
  UpdateProfileImageMutation,
  useDeleteProfileImageMutation,
  useUpdateProfileImageMutation,
} from '../../generated/graphql';

export const updateProfileImage = gql`
  ${CORE_USER_PROFILE_FIELDS}
  mutation updateProfileImage($image: Upload!) {
    updateProfileImage(image: $image) {
      error {
        ... on EmptyFile {
          message
        }
        ... on FileSizeInvalid {
          message
        }
        ... on FileTypeInvalid {
          message
        }
      }
      user {
        ...CoreUserProfileFields
      }
    }
  }
`;

export const deleteProfileImage = gql`
  ${CORE_USER_PROFILE_FIELDS}
  mutation deleteProfileImage($mediaId: ID!) {
    deleteProfileImage(mediaId: $mediaId) {
      error {
        ... on FileNotFound {
          message
        }
      }
      user {
        ...CoreUserProfileFields
      }
    }
  }
`;

export const useUpdateProfileImage = (
  options:
    | MutationHookOptions<
        UpdateProfileImageMutation,
        Exact<{
          image: ReactNativeFile | Blob;
        }>,
        DefaultContext,
        ApolloCache<unknown>
      >
    | undefined,
) => {
  return useUpdateProfileImageMutation({ ...options });
};

export const useDeleteProfileImage = (
  options:
    | MutationHookOptions<
        DeleteProfileImageMutation,
        Exact<{
          mediaId: string;
        }>,
        DefaultContext,
        ApolloCache<unknown>
      >
    | undefined,
) => {
  return useDeleteProfileImageMutation({ ...options });
};
