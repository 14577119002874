import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { getPercentageTextColor } from '../../../util/color';
import { formatDate } from '../../../util/date';
import { formatPercent, round } from '../../../util/number';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { Warning } from '../../icons/Warning';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';

export type Props = Pick<
  NarrowByTypename<FeedCardFieldsFragment, 'SignificantDailyMoverFeedItem'>,
  'date' | 'latestReturn' | 'zScore' | 'id' | '__typename' | 'instrument'
>;

export const SignificantDailyMoverCard: React.FC<Props> = ({
  date,
  latestReturn,
  zScore,
  instrument,
  id,
  __typename,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  const up = (latestReturn ?? 0) > 0;
  const formattedPercentColor = getPercentageTextColor(latestReturn);
  const parsedDate = new Date(date ?? '');

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, instrument }}
        icon={<Warning />}
        eventTime={date ? new Date(date) : undefined}
      >
        Price Alert
      </CardTitle>
      <CardHeadline>
        <CardHeadlineText>
          The price of{' '}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Destination screen': 'Instrument',
                'Feed card type': __typename,
                'Feed item id': id,
                'Instrument ticker': instrument?.ticker ?? '',
              });
            }}
          >
            {instrument?.name}
          </Link>{' '}
          {up ? 'increased' : 'decreased'} significantly on the {formatDate(parsedDate, 'Do')} of{' '}
          {formatDate(parsedDate, 'MMMM')}.
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Destination screen': 'Instrument',
            'Feed card type': __typename,
            'Feed item id': id,
            'Instrument ticker': instrument?.ticker ?? '',
          });
        }}
      >
        <View>
          <InstrumentPriceRow {...instrument} />
        </View>
        <View style={tailwind('flex-row mt-3')}>
          <View style={tailwind('flex-1 bg-warmGray-100 mr-1 rounded p-0.5')}>
            <Text style={tailwind('font-light text-xs text-center text-warmGray-500')}>
              {formatDate(parsedDate, 'D MMMM')}
            </Text>
            <Text style={tailwind(`font-normal text-sm text-center ${formattedPercentColor}`)}>
              {latestReturn != null ? formatPercent(latestReturn) : ''}
            </Text>
          </View>
          <View style={tailwind('flex-1 bg-warmGray-100 ml-1 rounded p-0.5')}>
            <Text style={tailwind('font-light text-xs text-center text-warmGray-500')}>z-score</Text>
            <Text style={tailwind('font-normal text-sm text-center text-warmGray-700')}>
              {zScore != null ? round(zScore) : ''}
            </Text>
          </View>
        </View>
      </OutlineInnerCard>
    </Card>
  );
};
