import Svg, { Path, SvgProps } from 'react-native-svg';

export const Coffee = (props: SvgProps) => (
  <Svg width={360} height={360} viewBox="0 0 256 256" fill="none" {...props}>
    <Path
      d="M139.867 11.698a96.325 96.325 0 0 1 27.58 7.343l6.476 2.806c27.602 11.96 48.227 36.187 56.031 65.82l11.956 45.397c4.638 17.61 3.62 36.342-2.904 53.451-8.17 21.424-24.357 38.707-44.944 47.986l-5.328 2.402c-15.25 6.874-31.918 9.679-48.428 8.151l-53.61-4.963c-43.744-4.597-76.849-41.937-77.085-86.947l-.414-78.896c-.14-26.768 15.078-51.316 38.89-62.734 11.163-5.352 23.52-7.462 35.707-6.097l56.073 6.28Z"
      fill="#DBEAFE"
    />
    <Path
      d="M45.776 237.995a2557.247 2557.247 0 0 0 95.928-40.663c.165-.099.123-.444-.083-.345a2730.456 2730.456 0 0 1-95.927 40.663c-.165.05-.083.395.082.345ZM151.185 196.394c31.206 1.725 62.454 3.5 93.66 5.225.206 0 .206-.345 0-.394-31.206-1.726-62.454-3.5-93.66-5.225-.206 0-.206.394 0 .394Z"
      fill="#000"
    />
    <Path
      d="M168.842 247.718c.98-1.455 3.222-3.839 7.964-7.972 1.071-.904 2.059-1.743 2.948-2.499.91-.773 1.717-1.458 2.404-2.035 2.524-.592 5.003-1.183 7.48-1.775.52 2.303 1.072 4.579 1.628 6.873l.264 1.086c-10.876 5.173-16.557 7.515-19.594 8.45-1.533.473-2.36.576-2.83.523-.227-.025-.36-.086-.449-.151a1.01 1.01 0 0 1-.235-.266c-.147-.214-.256-.416-.241-.72.016-.319.172-.786.661-1.514Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.5}
    />
    <Path
      d="M154.948 139.196c.195 1.276.438 3.299.925 5.717 1.605 8.664 3.794 14.997 4.524 17.02 1.994 5.849.583 2.946 10.215 37.117a3851.378 3851.378 0 0 0 10.507 36.59c.681.352 1.8.792 3.211.88a8.848 8.848 0 0 0 5.594-1.496c-.486-6.684-1.021-13.369-1.508-20.054 0-.044-1.265-16.887-1.265-16.887-1.362-18.427-4.086-54.313-4.232-56.336-9.388-.88-18.679-1.715-27.971-2.551Z"
      fill="#fff"
    />
    <Path
      d="M155.533 144.341a3294.477 3294.477 0 0 0 25.1 90.375c.049.219.39.132.341-.088a3290.936 3290.936 0 0 1-25.101-90.375c-.049-.22-.389-.132-.34.088ZM183.553 153.445c2.043 27.179 4.086 54.401 6.129 81.58 0 .22.39.22.39 0l-6.13-81.58c-.048-.219-.389-.219-.389 0ZM180.974 235.771c2.724 1.275 5.788 1.231 8.513-.088.194-.088 0-.396-.195-.264-2.578 1.231-5.546 1.275-8.124.088-.194-.088-.389.176-.194.264Z"
      fill="#000"
    />
    <Path
      d="M173.287 242.59c.973-.264 2.141-.572 3.016.132.195.132.438-.088.244-.22-.973-.792-2.238-.528-3.357-.22-.243.044-.146.352.097.308ZM175.524 240.476c.876-.396 1.946-.264 2.724.264.195.131.438-.088.243-.22a3.134 3.134 0 0 0-3.162-.308c-.194.044 0 .352.195.264ZM177.811 238.146c1.508-.572 3.259-.176 4.232 1.011.146.176.438 0 .292-.176-1.119-1.319-3.064-1.759-4.718-1.143-.195.088 0 .396.194.308ZM177.52 247.338a167.07 167.07 0 0 0 10.459-4.574c.194-.088 0-.396-.195-.264-3.453 1.628-6.907 3.167-10.458 4.574-.195.044 0 .352.194.264Z"
      fill="#292524"
    />
    <Path
      d="M184.151 247.931c.918-1.561 3.095-4.054 7.835-8.186 1.071-.903 2.058-1.742 2.948-2.498.91-.773 1.717-1.459 2.404-2.036 1.27-.303 2.551-.606 3.833-.909h.001c1.218-.289 2.437-.577 3.646-.865.52 2.303 1.072 4.578 1.628 6.872l.263 1.087c-10.875 5.175-16.499 7.631-19.481 8.679-1.507.53-2.307.688-2.757.674-.217-.007-.335-.054-.412-.105a.834.834 0 0 1-.216-.24c-.145-.212-.275-.449-.278-.805-.002-.365.129-.89.586-1.668Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.5}
    />
    <Path
      d="M165.214 143.023c-.487.66-1.703 2.551-1.605 5.102.097 1.803.827 3.122 1.264 3.826l4.962 4.881c.438 1.363 2.286 6.861 2.773 8.224 4.67 13.853 9.291 27.706 13.961 41.559 3.21 9.5 6.372 18.955 9.583 28.454.535.352 1.8 1.099 3.551 1.187 3.259.132 5.302-2.198 5.497-2.462-.292-2.199-.827-6.025-1.459-10.775-1.995-14.821-3.795-28.674-8.319-63.988-.292-2.463-1.897-14.952-2.237-17.459-9.34.484-18.68.967-27.971 1.451Z"
      fill="#fff"
    />
    <Path
      d="M193.332 144.341c3.843 29.597 7.686 59.194 11.529 88.835.048.22.389.22.389 0-3.843-29.597-7.686-59.194-11.529-88.835-.049-.22-.438-.22-.389 0ZM164.728 152.039a79.514 79.514 0 0 1 4.67 4.134c.145.176.437-.088.243-.22a79.88 79.88 0 0 0-4.67-4.134c-.146-.132-.438.088-.243.22ZM169.642 156.876l26.268 78.237c.049.22.438.132.341-.088l-26.269-78.237c-.048-.22-.438-.132-.34.088ZM196.784 235.727c2.773 1.408 5.692.264 7.978-1.451.195-.132-.097-.352-.243-.22-2.141 1.583-4.865 2.727-7.54 1.363-.195-.088-.389.176-.195.308Z"
      fill="#000"
    />
    <Path
      d="M188.613 242.327c1.167-.616 2.286-.484 3.453.088.195.088.39-.176.195-.264-1.265-.615-2.53-.747-3.794-.088-.195.088-.049.352.146.264ZM190.606 240.521a3.962 3.962 0 0 1 3.162.132c.194.088.389-.176.194-.263a4.18 4.18 0 0 0-3.551-.132c-.194.044 0 .351.195.263ZM192.94 238.452c1.071-.307 2.141-.219 3.114.264.194.088.389-.176.194-.264-1.021-.571-2.237-.659-3.405-.307-.194.044-.097.351.097.307ZM190.12 248.613a382.148 382.148 0 0 0 15.615-7.477c.195-.088 0-.395-.194-.264a416.54 416.54 0 0 1-15.615 7.477c-.195.088 0 .352.194.264Z"
      fill="#292524"
    />
    <Path
      d="M100.37 76.617c-.583-.748-1.362-1.54-2.335-2.375a20.05 20.05 0 0 0-3.405-2.375c-1.264-1.627-2.578-2.77-3.502-3.518-1.265-1.012-3.21-2.551-4.135-2.067-.632.352-.486 1.45-.438 1.627.146 1.32 1.411 1.979 1.557 2.067a22.927 22.927 0 0 0-.973 1.803c-.73 1.671-1.265 2.77-.778 3.87.146.308.486.968 1.556 1.54h2.724c.633 1.143 1.362 1.538 1.898 1.714.827.264 1.41 0 2.335.264.875.264 1.508.792 1.848 1.232a304.179 304.179 0 0 1 3.648-3.782Z"
      fill="#fff"
    />
    <Path
      d="M148.868 73.538c-1.021-.044-1.799.616-2.14.88-5.546 4.573-7.637 6.86-7.637 6.86-1.022 1.144-3.697 4.046-7.054 6.86-2.237 1.892-4.232 3.211-5.594 4.09a133.001 133.001 0 0 1-8.124-4.133c-6.81-3.738-12.501-7.74-17.317-11.522-.584.176-1.654.66-2.627 1.627-1.314 1.363-1.46 2.902-1.508 3.43.584.616 1.362 1.495 2.383 2.55 1.509 1.628 4.622 4.838 10.167 10.028 3.649 3.386 6.13 5.541 9.34 7.74 1.508 1.055 3.211 2.111 5.692 2.727.632.176 3.016.659 6.08.264 2.53-.352 4.33-1.188 5.984-2.023 5.983-3.035 8.999-4.574 11.918-7.565 2.383-2.462 5.837-5.98 6.08-11.17.292-5.805-3.6-9.851-4.718-10.379-.438-.22-.925-.264-.925-.264Z"
      fill="#FAE8FF"
    />
    <Path
      d="M147.701 73.845c-3.989 1.407-6.81 5.365-9.583 8.136-3.599 3.694-7.248 7.3-11.772 10.07-.194.132 0 .396.195.264 3.843-2.374 7.151-5.32 10.215-8.443 1.557-1.584 3.065-3.167 4.621-4.706 1.849-1.847 3.746-4.046 6.422-5.013.243-.044.146-.396-.098-.308ZM100.905 76.66a234.053 234.053 0 0 0 24.128 15.48c.194.132.389-.175.194-.263a225.294 225.294 0 0 1-24.03-15.437c-.195-.132-.487.088-.292.22ZM96.868 80.442a7.745 7.745 0 0 1 3.6-3.386c.194-.088.049-.396-.195-.264-1.654.748-2.967 1.98-3.745 3.474-.049.22.243.396.34.176Z"
      fill="#000"
    />
    <Path
      d="M96.82 81.714a184.713 184.713 0 0 0 13.133 13.15c3.892 3.518 8.027 7.696 13.28 9.587 4.865 1.759 9.729.439 14.107-1.759 5.108-2.551 9.924-5.718 13.864-9.632.146-.176-.097-.395-.243-.22-3.746 3.695-8.27 6.685-13.037 9.192-5.594 2.946-11.431 4.09-17.22.923-4.524-2.506-8.367-6.288-12.064-9.719-4.038-3.738-7.881-7.652-11.578-11.698-.145-.22-.389.044-.243.176ZM100.467 76.486c-1.265-1.452-2.627-2.77-4.184-3.958-.778-.572-1.605-1.188-2.627-.66-.73.396-1.654 1.495-1.313 2.287.438.88 1.41.396 2.189.264.243-.044.146-.352-.097-.308-.146.044-.779.22-.876.176l-.584-1.32c.195-.22.39-.44.535-.615.876-.396 1.849-.088 2.481.396.34.263.681.527 1.022.835 1.167.968 2.189 2.023 3.162 3.123.194.175.437-.044.292-.22ZM94.581 71.162a28.547 28.547 0 0 0-4.183-3.607c-.924-.66-2.238-1.67-3.503-1.495-2.675.308 1.265 4.222 1.898 4.926.145.176.389-.088.243-.22-.633-.704-1.752-1.671-2.092-2.55-.097-.133-.195-.309-.34-.44.632-1.056 1.264-1.364 1.945-.924.39.132.827.484 1.168.703 1.702 1.1 3.21 2.42 4.572 3.87.195.133.487-.087.292-.263ZM87.185 70.986c-.924 1.671.584 3.079 2.092 3.87.194.088.389-.175.194-.263-1.313-.66-2.821-1.892-1.946-3.387.098-.264-.243-.44-.34-.22Z"
      fill="#000"
    />
    <Path
      d="M86.554 73.097c0 1.496 1.119 2.815 2.724 3.21.243.045.34-.263.097-.307-1.459-.352-2.48-1.54-2.48-2.903.048-.22-.341-.22-.341 0ZM86.7 76.174c.535 1.055 1.848 1.583 3.064 1.231.243-.088.146-.395-.097-.308-1.07.308-2.19-.131-2.676-1.055-.097-.22-.389-.044-.291.132ZM90.495 77.23c.486.66 1.07 1.363 1.897 1.759.973.484 2.919-.044 3.454.88.097.175.438 0 .292-.177-.39-.615-1.07-.747-1.8-.703-1.703.044-2.627-.704-3.502-1.935-.146-.176-.487 0-.34.176Z"
      fill="#000"
    />
    <Path
      d="M148.868 73.537c-.924 1.319-2.14 3.298-2.87 5.937-.876 3.078-.73 5.54-.487 8.224.73 9.191 1.363 12.621 1.363 12.621 2.724 15.217 1.751 14.161 4.183 26.211.73 3.562 1.751 8.356 3.162 13.941a48.435 48.435 0 0 0 7.345 3.343c5.497 1.935 10.021 2.418 11.578 2.594 2.043.176 6.324.44 11.626-.44a47.017 47.017 0 0 0 8.61-2.242c-.195-2.375-.486-5.85-.876-10.071-.924-9.632-1.702-15.481-2.335-21.11-.681-5.849-1.459-14.117-2.091-24.364-.779-.88-1.606-1.803-2.384-2.682-2.043-1.452-4.913-3.255-8.561-5.014-1.411-.704-7.005-3.254-14.934-5.145-3.211-.704-7.686-1.54-13.329-1.803Z"
      fill="#FAE8FF"
    />
    <Path
      d="M148.87 73.714c13.183.704 25.879 4.75 36.63 11.654.194.132.389-.176.194-.264-10.848-6.948-23.593-10.95-36.824-11.698-.195-.044-.195.308 0 .308ZM145.27 86.73c1.508 17.02 4.183 33.951 7.929 50.707.049.22.389.132.34-.088-3.745-16.712-6.421-33.643-7.929-50.619 0-.22-.389-.22-.34 0ZM154.951 140.912c11.285 6.509 25.636 7.653 38.04 3.079.195-.088.097-.396-.097-.308-12.307 4.574-26.56 3.43-37.749-3.034-.194-.132-.389.131-.194.263ZM187.834 91.218c1.8 17.283 3.551 34.61 5.351 51.894 0 .22.389.22.389 0-1.8-17.284-3.551-34.611-5.351-51.894-.049-.22-.438-.22-.389 0Z"
      fill="#000"
    />
    <Path d="M191.676 141.527h-7.297l-3.161-14.997h12.063l-1.605 14.997Z" fill="#000" />
    <Path
      d="M187.3 128.907c3.277 0 5.934-.945 5.934-2.111 0-1.166-2.657-2.111-5.934-2.111-3.278 0-5.935.945-5.935 2.111 0 1.166 2.657 2.111 5.935 2.111Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      d="M193.82 129.211c-.195 0-.73 0-1.314.088-.827.132-1.459.22-1.897.572-.973.748-.632 2.287-.584 2.507-1.459.396-2.626.66-3.405.835-1.459.308-2.043.352-2.383.88-.244.352-.195.66-.146 2.155.048.879.048 1.627.097 2.067a6.936 6.936 0 0 0 2.481 1.979c1.897.879 3.697.66 4.329.572 2.238-.308 3.649-1.452 4.281-1.979.973-.792 2.578-2.111 2.724-4.134.292-2.859-2.432-5.498-4.183-5.542Z"
      fill="#fff"
    />
    <Path
      d="M187.252 85.895a9.003 9.003 0 0 0-3.599 1.803c-4.135 3.43-2.773 9.191-2.676 9.631.487.968 1.216 2.33 2.092 3.914 1.848 3.254 4.281 7.256 7.54 11.566a127.452 127.452 0 0 0 4.475 5.629 151.817 151.817 0 0 1-.486 4.398c-.292 2.243-.584 4.398-.973 6.509 1.265.132 1.994.572 2.384.792 1.167.835 1.556 2.111 1.945 3.298.195.704.292 1.319.341 1.759a44.453 44.453 0 0 0 2.432-3.518c.827-1.363 1.751-2.859 2.53-4.882.681-1.715 1.362-3.562 1.362-5.893 0-.484-.049-.967-1.168-5.541-.729-2.903-1.07-4.354-1.41-5.409-.39-1.32-1.217-3.694-2.481-6.509-1.8-3.958-3.697-6.992-5.011-8.884-2.432-3.43-4.962-6.288-7.297-8.663Z"
      fill="#FAE8FF"
    />
    <Path
      d="M187.107 86.023c8.61 8.62 14.253 19.307 16.344 30.785.049.22.39.132.341-.088-2.092-11.522-7.783-22.253-16.442-30.916-.146-.177-.389.087-.243.22ZM204.376 118.88c.389 6.069-2.675 11.346-6.275 16.271-.146.176.195.352.292.176 3.648-4.969 6.713-10.29 6.324-16.403 0-.22-.389-.22-.341-.044Z"
      fill="#000"
    />
    <Path
      d="M194.599 129.874c2.627.308 3.064 2.947 3.259 4.882 0 .22.389.22.389 0-.243-2.155-.778-4.882-3.648-5.19-.195-.044-.195.308 0 .308ZM194.013 129.212a37.747 37.747 0 0 1 1.216-8.356c.049-.219-.292-.307-.34-.088-.73 2.771-1.168 5.586-1.265 8.444 0 .22.389.22.389 0ZM180.784 97.416a111.732 111.732 0 0 0 14.108 21.109c.145.176.437 0 .291-.175-5.545-6.553-10.264-13.59-14.107-21.11-.048-.176-.389 0-.292.176Z"
      fill="#000"
    />
    <Path
      d="M193.624 129.212c-.973-.044-2.091-.132-2.918.44-.633.395-.925 1.011-.827 1.671.048.22.389.132.34-.088-.292-1.715 2.189-1.759 3.405-1.715.244 0 .244-.308 0-.308ZM190.61 132.116c-1.508.396-3.016.835-4.573 1.011-.729.088-1.264.176-1.702.748-.146.176.194.352.292.176.535-.66 2.335-.66 3.21-.88.973-.22 1.898-.483 2.87-.703.195-.088.098-.396-.097-.352ZM184.187 135.501c1.994 1.319 4.378 1.539 6.761 1.715.243 0 .243-.308 0-.352-2.335-.176-4.67-.352-6.615-1.671-.146-.088-.341.176-.146.308ZM184.14 136.69c1.167 1.363 3.794 2.067 5.594 2.199.243 0 .243-.308 0-.352-1.751-.132-4.232-.836-5.351-2.111-.146-.132-.389.088-.243.264Z"
      fill="#000"
    />
    <Path
      d="M184.042 138.404c2.627 3.518 8.367 3.43 11.432.616.146-.132-.097-.396-.243-.22-2.87 2.638-8.416 2.77-10.848-.528-.146-.22-.487-.044-.341.132Z"
      fill="#000"
    />
    <Path
      d="m164.679 77.41.146 1.055c-.438 0-.827.22-.973.528-.243.44.097.88.146.967.535.704 1.702.704 2.092.704 1.07 0 1.897-.44 2.675-.836.486-.264 1.022-.527 1.557-1.1a5.854 5.854 0 0 0 1.264-1.978c.195-1.363-.194-1.803-.437-2.023-1.022-.66-3.551.44-6.47 2.683Z"
      fill="#fff"
    />
    <Path
      d="M164.63 78.072c.097.396.146.748.243 1.144.049.22.389.132.341-.088-.098-.396-.146-.748-.244-1.144 0-.22-.389-.132-.34.088Z"
      fill="#000"
    />
    <Path
      d="M163.847 78.687c-.681 1.231.584 2.155 1.946 2.155 2.092 0 4.086-1.232 5.351-2.639.146-.176-.097-.396-.243-.22-.681.748-1.508 1.407-2.481 1.847-.486.22-1.022.44-1.605.528-.973.22-3.551.176-2.627-1.54.049-.175-.243-.307-.341-.131ZM185.061 65.096c-.146.572-1.508 5.057-5.4 6.992-6.664 3.299-17.609-2.286-18.874-8.487-.438-2.111.097-5.146 2.043-6.113 1.994-1.012 3.794 1.1 7.199.483 3.308-.615 3.308-2.946 6.227-3.386 3.357-.528 7.589 1.891 8.902 5.277.827 2.067.292 3.959-.097 5.234Z"
      fill="#000"
    />
    <Path
      d="M161.857 64.875c-.924.528-1.411 1.276-1.946 2.023-.389.572-1.264 1.803-1.41 3.65a6.426 6.426 0 0 0 .146 2.243c.535 2.067 2.383 4.134 5.01 4.926 1.849.572 3.454.308 4.232.132.535-.132 2.627-.66 4.184-2.463.389-.44 1.313-1.54 1.508-3.254.145-1.188-.195-2.111-.341-2.507-.876-2.463-3.405-4.794-6.616-5.365-.778-.088-2.918-.44-4.767.615Z"
      fill="#fff"
    />
    <Path
      d="M160.057 66.634c.341-.351.973-1.011 2.043-1.407.438-.176 1.751-.704 3.065-.352 1.362.352 2.043 1.408 2.724 2.42 1.411 2.11.924 3.121 2.384 4.265.291.22 1.021.748 2.14.924a4.266 4.266 0 0 0 2.238-.308c.048-.308.146-.748.146-1.276.048-1.407-.438-2.463-.681-2.99-.438-1.056-1.752-3.343-4.476-4.794-4.086-2.243-10.118-1.935-11.042 0-.243.528-.243 1.54 1.459 3.518ZM159.276 67.692c-1.946 3.475-.924 7.696 2.919 9.631 3.599 1.848 7.88.704 10.264-2.198.146-.176-.195-.352-.292-.176-5.546 6.64-16.783.351-12.599-7.125.146-.176-.195-.352-.292-.132Z"
      fill="#000"
    />
    <Path
      d="M159.57 67.911c1.07-1.45 2.919-3.738 5.351-2.946 1.411.484 2.432 1.495 3.113 2.683.681 1.143.925 2.506 1.8 3.562 1.07 1.363 2.822 2.067 4.427 1.011.194-.132 0-.395-.195-.264-3.891 2.595-5.399-5.76-8.318-7.036-2.821-1.231-5.059.88-6.47 2.814-.146.176.195.352.292.176Z"
      fill="#000"
    />
    <Path
      d="M159.57 67.91c1.508-3.034 4.573-5.409 8.464-4.969 4.622.528 6.616 4.794 6.373 8.62 0 .22.34.22.389 0 .243-3.826-1.702-8.18-6.324-8.928-4.135-.66-7.588 1.847-9.194 5.102-.097.22.195.396.292.176Z"
      fill="#000"
    />
    <Path
      d="M162.878 68.086c-1.265 1.54-3.308 4.046-1.8 5.98.146.177.438 0 .292-.175-1.411-1.76.632-4.178 1.799-5.63.146-.175-.145-.307-.291-.175ZM165.144 69.868c.183-.325.176-.66-.015-.747-.192-.089-.495.103-.678.428-.183.324-.176.659.015.747.192.088.495-.103.678-.428ZM164.873 67.385c.681-.176 1.362.175 1.459.835.049.22.389.22.389 0-.097-.835-.972-1.407-1.897-1.143-.292.044-.194.352.049.308ZM161.386 68.59c.183-.325.176-.66-.015-.748-.192-.088-.495.103-.678.428-.183.325-.176.66.015.748.192.088.495-.104.678-.429Z"
      fill="#000"
    />
    <Path
      d="M167.5 61.402a8.41 8.41 0 0 1 3.503.791c2.627 1.276 3.502 3.518 3.746 4.09.048-.44.097-2.11-1.265-3.518-1.362-1.451-3.746-1.98-5.984-1.363Z"
      fill="#fff"
    />
    <Path
      opacity={0.47}
      d="M98.57 115.228 41.802 86.335l-3.016-61.79 61.584 14.777-1.8 75.906Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      opacity={0.44}
      d="M60.19 84.532c-.243 2.199 3.55 59.986 3.55 59.986l68.055 43.362-3.016-66.011-68.59-37.337Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      d="m67.535 106.916 50.834 27.838M74.492 108.984l-.194 3.078M66.269 115.93l51.709 29.246M87.09 126.355v3.958M72.01 143.727c1.344 0 2.433-1.871 2.433-4.178 0-2.308-1.089-4.178-2.432-4.178s-2.432 1.87-2.432 4.178c0 2.307 1.089 4.178 2.432 4.178ZM84.659 151.952c1.343 0 2.432-1.871 2.432-4.178 0-2.308-1.089-4.178-2.432-4.178-1.344 0-2.432 1.87-2.432 4.178 0 2.307 1.088 4.178 2.432 4.178ZM98.229 159.6c1.343 0 2.432-1.87 2.432-4.177 0-2.308-1.089-4.178-2.432-4.178s-2.432 1.87-2.432 4.178c0 2.307 1.089 4.177 2.432 4.177ZM113.067 168.661c1.344 0 2.433-1.87 2.433-4.178 0-2.307-1.089-4.178-2.433-4.178-1.343 0-2.432 1.871-2.432 4.178 0 2.308 1.089 4.178 2.432 4.178ZM46.084 70.416 84.999 88.36M73.42 80.663l.096 3.87M46.423 62.632l37.31 15.48M58.097 65.622v3.122"
      stroke="#FBBF24"
      strokeMiterlimit={10}
    />
    <Path
      d="m95.068 63.861-39.402-13.59v-5.496l39.402 11.258v7.828Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path d="M83.743 59.478 55.658 49.89v-4.794l27.535 7.99.55 6.393Z" fill="#FBBF24" />
  </Svg>
);
