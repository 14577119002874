import React from 'react';
import { View } from 'react-native';
import { Button } from '../../old/Button';
import { CircleTick } from '../../old/icons/CircleTick';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { Props } from './NewPassword';

export const ResetComplete: React.FC<{ navigation: Props['navigation'] }> = ({ navigation }) => {
  const tailwind = useTailwind();
  return (
    <SafeAreaView>
      <ScreenSidePadding style={tailwind('pt-8')}>
        <Text style={tailwind('text-center text-2xl')}>Password reset</Text>
        <CircleTick style={tailwind('pt-11 h-16 w-16 self-center')} />
        <Text style={tailwind('text-center pt-11 pb-4 text-warmGray-500')}>
          You can now log in to the app with your new password
        </Text>
        <View style={tailwind('pt-4')}>
          <Button text={'Log In'} onPress={() => navigation.navigate('LogIn')} />
        </View>
      </ScreenSidePadding>
    </SafeAreaView>
  );
};
