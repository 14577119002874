import Svg, { Path, SvgProps } from 'react-native-svg';

export const Certificate = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 32 32" fill="none" {...props}>
    <Path
      d="M18.25 26.707H2.5V2.5h21.6v10.241"
      stroke="#0F766E"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <Path d="M27.25 22.865V29.5l-3.15-1.862-3.15 1.862v-6.635" stroke="#0F766E" strokeWidth={2} strokeMiterlimit={10} />
    <Path
      d="M24.1 23.914c2.982 0 5.4-2.501 5.4-5.586 0-3.085-2.418-5.586-5.4-5.586s-5.4 2.5-5.4 5.586c0 3.085 2.418 5.586 5.4 5.586ZM7 9.017h12.6M7 14.604h7.2M7 20.19h7.2"
      stroke="#0F766E"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);
