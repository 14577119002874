import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { RefreshControl, useWindowDimensions, View } from 'react-native';
import { STACK_FIELDS } from '../../fragments/stack';
import { useGetStackQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { InstrumentPriceRowSkeleton } from '../../old/InstrumentPriceRow';
import { StackBody } from '../../old/StackBody';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { formatDatoImageUrl } from '../../services/datoImage';
import { useTailwind } from '../../theme';
import { DESKTOP_MAX_WIDTH, SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { SkeletonView } from '../../ui/Skeleton';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'Stack'>;

export const GET_STACK = gql`
  ${STACK_FIELDS}
  query GetStack($stackId: String!) {
    stack(stackId: $stackId) {
      stack {
        ...StackFields
      }
    }
  }
`;

export const COVER_IMAGE_HEIGHT = 164;

export const Stack: FC<Props> = withReloadErrorBoundary(({ route: { params } }) => {
  const { width } = useWindowDimensions();
  const { data, refetch, loading: dataLoading, error } = useGetStackQuery({ variables: { stackId: params.id } });
  const { refreshing, onRefresh } = useRefresh(refetch);

  if (error) {
    throw error;
  }

  const loading = refreshing || dataLoading;
  const stack = data?.stack?.stack;

  const imageUrl = stack?.content?.coverImage?.url
    ? formatDatoImageUrl(stack.content.coverImage?.url ?? '', {
        width: Math.min(width, DESKTOP_MAX_WIDTH),
        height: COVER_IMAGE_HEIGHT,
      })
    : undefined;

  return (
    <SafeAreaView>
      <TitleBar />
      {loading ? (
        <StackSkeleton />
      ) : (
        <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
          <StackBody
            title={stack?.content?.name ?? ''}
            body={stack?.content?.description ?? ''}
            imageUrl={imageUrl ?? ''}
            components={stack?.components?.nodes ?? []}
            totalCount={stack?.components?.pageInfo.totalCount ?? 0}
          />
        </ScrollView>
      )}
    </SafeAreaView>
  );
});

export const StackSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView style={tailwind('h-full w-full')}>
      <ScreenSidePadding>
        {new Array(10).fill(null).map((_, i) => (
          <View key={i} style={tailwind('mt-4')}>
            <InstrumentPriceRowSkeleton />
          </View>
        ))}
      </ScreenSidePadding>
    </SkeletonView>
  );
};
