import React from 'react';

export function useFetchMore<TArgs, TRes>(
  fetchMore: (args: TArgs) => Promise<TRes>,
): { fetchingMore: boolean; onFetchMore: (args: TArgs) => Promise<void> } {
  const [fetchingMore, setFetchingMore] = React.useState(false);
  const onFetchMore = React.useCallback(
    async (args: TArgs) => {
      setFetchingMore(true);
      await fetchMore(args);
      setFetchingMore(false);
    },
    [fetchMore],
  );
  return { fetchingMore, onFetchMore };
}
