import { VFC } from 'react';
import { View } from 'react-native';
import { Instrument, Maybe, QuotePrice } from '../../generated/graphql';
import { shadowMd, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatPercent } from '../../util/number';
import { InstrumentLogo } from '../InstrumentLogo';

export type Props = Maybe<Pick<Instrument, 'ticker' | 'logoUrl'>> & {
  quotePrice?: Maybe<Pick<QuotePrice, 'pctChange1d'>>;
};

export const InstrumentSnippet: VFC<Props> = ({ ticker, logoUrl, quotePrice }) => {
  const tailwind = useTailwind();
  const percentageDelta = quotePrice?.pctChange1d ?? 0;
  const up = percentageDelta >= 0;

  return (
    <View style={[tailwind('rounded-lg px-4 py-3 items-center'), shadowMd]}>
      <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
      <Text numberOfLines={1} style={tailwind('text-xs font-medium text-warmGray-800 py-1 self-center')}>
        {ticker}
      </Text>
      <View style={tailwind(`${getDeltaBackground(up)} rounded-md px-1.5 py-1`)}>
        <Text style={tailwind(`text-xs font-semibold text-warmGray-100 self-center`)}>
          {formatPercent(percentageDelta)}
        </Text>
      </View>
    </View>
  );
};

function getDeltaBackground(up: boolean) {
  return up ? 'bg-green-600' : 'bg-red-500';
}
