import { FC, useEffect, useState } from 'react';
import { LayoutRectangle, View } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  Extrapolate,
  interpolate,
  interpolateColor,
  runOnJS,
  SharedValue,
  useAnimatedReaction,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
  WithSpringConfig,
  withTiming,
} from 'react-native-reanimated';
import { PORTFOLIO_WEIGHTINGS, RiskLevel } from '../../../constants/portfolioIntended';
import { shadowSm, textColors, useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';
import { SNAP_POINTS } from '../constants';
import { AnimatedPortfolioWeights } from './AnimatedPortfolioWeights';

const { low, medium, high } = SNAP_POINTS;
/**
 * Use custom color interpolation scale to avoid red which is hit in the default grey->green path.
 */
const RISK_TEXT_COLOR_SCALE = [textColors.grey, '#82C2BD', textColors.default];
const DEFAULT_SPRING_CONFIG: WithSpringConfig = {
  damping: 15,
  stiffness: 115,
};

type Props = {
  /**
   *  Risk levels map to snap points
   */
  initialValue: RiskLevel;
  /**
   * Called when nearest snap point changes. Also records the action that updated the value.
   */
  onValueChange: (value: RiskLevel, action: 'drag' | 'press') => void;
};

export const OnboardingSlider: FC<Props> = ({ onValueChange, initialValue }) => {
  const tailwind = useTailwind();
  const [trackLayout, setTrackLayout] = useState<LayoutRectangle | null>(null);

  // Central dragging state so different components can react differently (e.g text uses timing, cursor uses spring)
  const dragging = useSharedValue(false);
  const sliderValue = useSharedValue(SNAP_POINTS[initialValue] ?? medium);

  const nearestSnapPoint = useDerivedValue(() => {
    return [low, medium, high].sort((a, b) => Math.abs(a - sliderValue.value) - Math.abs(b - sliderValue.value))[0];
  }, [SNAP_POINTS]);

  // Call change event only when nearest snapPoint changes
  useAnimatedReaction(
    () => nearestSnapPoint.value,
    (curValue, prevValue) => {
      if (curValue !== prevValue) {
        runOnJS(onValueChange)(
          nearestSnapPoint.value === low ? 'low' : nearestSnapPoint.value === medium ? 'medium' : 'high',
          dragging.value ? 'drag' : 'press',
        );
      }
    },
    [onValueChange],
  );
  // Snap to nearest snap point when no longer dragging
  useAnimatedReaction(
    () => {
      return dragging.value;
    },
    (isTracking) => {
      if (!isTracking) {
        sliderValue.value = nearestSnapPoint.value;
      }
    },
  );

  // TouchesDown handler enables us to use one pan gesture for both pressing on, and dragging along the track
  const panGesture = Gesture.Pan()
    .hitSlop({ top: 24, bottom: 24, left: 24, right: 24 })
    .onBegin(() => {
      dragging.value = true;
    })
    .onTouchesDown((e) => {
      if (!trackLayout) return;
      sliderValue.value = e.allTouches[0].x / trackLayout.width;
    })
    .onUpdate((e) => {
      if (!trackLayout) return;
      sliderValue.value = e.x / trackLayout.width;
    })
    .onEnd((e) => {
      if (!trackLayout) return;
      sliderValue.value = e.x / trackLayout.width;
    })
    .onFinalize(() => {
      dragging.value = false;
    });

  const cursorStyle = useAnimatedStyle(() => {
    if (!trackLayout) return {};
    const xCoord = interpolate(sliderValue.value, [0, 1], [0, trackLayout.width], Extrapolate.CLAMP);

    return {
      // Spring animate cursor on release
      transform: [{ translateX: dragging.value ? xCoord : withSpring(xCoord, DEFAULT_SPRING_CONFIG) }],
    };
  });

  const lowTextStyle = useAnimatedStyle(() => {
    const newColor = interpolateColor(
      nearestSnapPoint.value === low ? 1 : 0,
      [low, medium, high],
      RISK_TEXT_COLOR_SCALE,
    );
    return {
      color: withTiming(newColor),
    };
  });
  const mediumTextStyle = useAnimatedStyle(() => {
    const newColor = interpolateColor(
      nearestSnapPoint.value === medium ? 1 : 0,
      [low, medium, high],
      RISK_TEXT_COLOR_SCALE,
    );
    return {
      color: withTiming(newColor),
    };
  });
  const highTextStyle = useAnimatedStyle(() => {
    const newColor = interpolateColor(
      nearestSnapPoint.value === high ? 1 : 0,
      [low, medium, high],
      RISK_TEXT_COLOR_SCALE,
    );
    return {
      color: withTiming(newColor),
    };
  });

  // Use timing animation for weights instead of spring
  const animatedBaseWeight = useDerivedValue(() => {
    const { low: lowRisk, medium: mediumRisk, high: highRisk } = PORTFOLIO_WEIGHTINGS;
    const interpolated = interpolate(
      sliderValue.value,
      [low, medium, high],
      [lowRisk.base, mediumRisk.base, highRisk.base],
      Extrapolate.CLAMP,
    );
    return dragging.value ? interpolated : withTiming(interpolated);
  });
  const animatedBundlesWeight = useDerivedValue(() => {
    const { low: lowRisk, medium: mediumRisk, high: highRisk } = PORTFOLIO_WEIGHTINGS;
    const interpolated = interpolate(
      sliderValue.value,
      [low, medium, high],
      [lowRisk.bundles, mediumRisk.bundles, highRisk.bundles],
      Extrapolate.CLAMP,
    );
    return dragging.value ? interpolated : withTiming(interpolated);
  });
  const animatedStocksWeight = useDerivedValue(() => {
    const { low: lowRisk, medium: mediumRisk, high: highRisk } = PORTFOLIO_WEIGHTINGS;
    const interpolated = interpolate(
      sliderValue.value,
      [low, medium, high],
      [lowRisk.stocks, mediumRisk.stocks, highRisk.stocks],
      Extrapolate.CLAMP,
    );
    return dragging.value ? interpolated : withTiming(interpolated);
  });

  return (
    <View>
      <AnimatedPortfolioWeights
        animatedWeights={{
          base: animatedBaseWeight,
          bundles: animatedBundlesWeight,
          stocks: animatedStocksWeight,
        }}
      />
      <Text style={tailwind('text-base text-default font-semibold pt-10 pb-5')}>Select your level of risk/reward</Text>
      <View style={tailwind('px-3')}>
        {/* Pressable Risk Level text */}
        <View style={tailwind('flex-row justify-between')}>
          <View>
            <Pressable
              style={tailwind('relative -left-1/2 py-6 px-4')}
              hitSlop={24}
              onPress={() => (sliderValue.value = low)}
              accessibilityRole="button"
              accessibilityLabel="Low"
            >
              <Animated.Text style={[tailwind('font-regular'), lowTextStyle]}>Low</Animated.Text>
            </Pressable>
          </View>
          <View>
            <Pressable
              style={tailwind('px-4 py-6')}
              hitSlop={24}
              onPress={() => (sliderValue.value = medium)}
              accessibilityRole="button"
              accessibilityLabel="Medium"
            >
              <Animated.Text style={[tailwind('font-regular'), mediumTextStyle]}>Medium</Animated.Text>
            </Pressable>
          </View>
          <View>
            <Pressable
              accessibilityRole="button"
              style={tailwind('relative -right-1/2 py-6 px-4')}
              hitSlop={24}
              onPress={() => (sliderValue.value = high)}
              accessibilityLabel="High"
            >
              <Animated.Text style={[tailwind('font-regular'), highTextStyle]}>High</Animated.Text>
            </Pressable>
          </View>
        </View>
        <View style={tailwind('mt-2')}>
          {/* GestureDetector encapsulates slider. Whole track listens for press and drag events, not just cursor. Cursor follows gesture coords. */}
          <GestureDetector gesture={panGesture}>
            <View style={tailwind('justify-center')}>
              <View
                style={tailwind('h-2 rounded-full bg-warmGray-100 flex-row')}
                onLayout={(e) => {
                  setTrackLayout(e.nativeEvent.layout);
                }}
              >
                {/* Slider marks. */}
                <View
                  style={[
                    tailwind('bg-primary-default h-1 w-1 mt-0.5 rounded-full absolute'),
                    trackLayout && { left: low * trackLayout.width + 2 },
                  ]}
                />
                <View
                  style={[
                    tailwind('bg-secondary-default h-1 w-1 mt-0.5 rounded-full absolute'),
                    trackLayout && { left: medium * trackLayout.width - 2 },
                  ]}
                />
                <View
                  style={[
                    tailwind('bg-tertiary-default h-1 w-1 mt-0.5 rounded-full absolute'),
                    trackLayout && { left: high * trackLayout.width - 6 },
                  ]}
                />
              </View>
              {/* CURSOR. Margin left is 50% of cursor width to center on edge of line */}
              <Animated.View
                style={[tailwind('absolute rounded-full h-7 w-7 bg-white'), shadowSm, { marginLeft: -14 }, cursorStyle]}
              />
            </View>
          </GestureDetector>
        </View>
      </View>
      <View style={tailwind('mt-10')}>
        <RiskText nearestSnapPoint={nearestSnapPoint} />
      </View>
    </View>
  );
};

const RiskText: FC<{ nearestSnapPoint: SharedValue<number> }> = ({ nearestSnapPoint }) => {
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<number>(medium);
  useAnimatedReaction(
    () => nearestSnapPoint.value,
    (value, prev) => {
      if (value !== prev) {
        if (value === low) {
          runOnJS(setSelectedRiskLevel)(low);
        }
        if (value === medium) {
          runOnJS(setSelectedRiskLevel)(medium);
        }
        if (value === high) {
          runOnJS(setSelectedRiskLevel)(high);
        }
      }
    },
  );

  switch (selectedRiskLevel) {
    case low:
      return <LowText />;
    case medium:
      return <MediumText />;
    case high:
      return <HighText />;
    default:
      return null;
  }
};

const LowText = () => (
  <FadeInText>
    Slow and steady. You have a risk averse attitude toward your investments. You don{"'"}t want to take on a lot of
    risk and you{"'"}re happy to sacrifice some returns to maintain low risk.
  </FadeInText>
);
const MediumText = () => (
  <FadeInText>
    Mild and moderate. You have a balanced attitude toward risk and return. You don{"'"}t mind taking on some risk to
    achieve returns, but you don{"'"}t want this to be excessive.
  </FadeInText>
);
const HighText = () => (
  <FadeInText>
    Adventurous. You have a more gutsy approach toward risk and return. You don{"'"}t mind taking on significant risk in
    seeking higher returns.
  </FadeInText>
);

const FadeInText: FC = ({ children }) => {
  const tailwind = useTailwind();
  const animate = useSharedValue(0);
  const animatedStyle = useAnimatedStyle(() => ({
    opacity: withTiming(animate.value),
  }));

  useEffect(() => {
    animate.value = 1;
    () => {
      animate.value = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Animated.View style={[animatedStyle, tailwind('h-44')]}>
      <Text style={tailwind('text-default text-base')}>{children}</Text>
    </Animated.View>
  );
};
