import { VFC } from 'react';
import { Maybe, ProfileRating, UserProfile } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { IconButton } from '../Button';
import { ProfileRating as ProfileRatingIcon, User } from '../icons';
import { Link } from '../Link';

export const InlineUserIcon: VFC<{
  userProfile?: Maybe<Pick<Partial<UserProfile>, 'id' | 'nickname' | 'profileRating'>>;
  onPressUser: () => void;
  /**
   Defaults to userProfile nickname.
   */
  text?: string;
}> = ({ userProfile, text, onPressUser }) => {
  const tailwind = useTailwind();
  return (
    <>
      <IconButton
        accessibilityLabel="User Icon"
        onPress={onPressUser}
        hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
        icon={<User />}
        style={tailwind('pr-1 justify-center')}
      />
      <Link redirectIfLoggedOut onPress={onPressUser}>
        {text ?? userProfile?.nickname ?? ''}
      </Link>
      {userProfile?.profileRating && (
        <IconButton
          onPress={onPressUser}
          hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
          accessibilityLabel={userProfile.profileRating}
          icon={
            <ProfileRatingIcon isGold={userProfile.profileRating === ProfileRating.Gold} style={tailwind('h-3 w-3')} />
          }
          style={tailwind('pl-0.5 justify-center')}
        />
      )}
    </>
  );
};
