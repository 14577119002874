/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Firm } from '../models/Firm';
import { request as __request } from '../core/request';

export class FirmService {

    /**
     * @returns Firm
     * @throws ApiError
     */
    public static async apiFirmList(): Promise<Array<Firm>> {
        const result = await __request({
            method: 'GET',
            path: `/api/firm/`,
        });
        return result.body;
    }

    /**
     * @returns Firm
     * @throws ApiError
     */
    public static async apiFirmCreate({
        data,
    }: {
        data: Firm,
    }): Promise<Firm> {
        const result = await __request({
            method: 'POST',
            path: `/api/firm/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Firm
     * @throws ApiError
     */
    public static async apiFirmRead({
        uuid,
    }: {
        /** A UUID string identifying this firm. **/
        uuid: string,
    }): Promise<Firm> {
        const result = await __request({
            method: 'GET',
            path: `/api/firm/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns Firm
     * @throws ApiError
     */
    public static async apiFirmUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this firm. **/
        uuid: string,
        data: Firm,
    }): Promise<Firm> {
        const result = await __request({
            method: 'PUT',
            path: `/api/firm/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Firm
     * @throws ApiError
     */
    public static async apiFirmPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this firm. **/
        uuid: string,
        data: Firm,
    }): Promise<Firm> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/firm/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiFirmDelete({
        uuid,
    }: {
        /** A UUID string identifying this firm. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/firm/${uuid}/`,
        });
        return result.body;
    }

}
