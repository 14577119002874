import { gql, useApolloClient } from '@apollo/client';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack';
import React, { useCallback } from 'react';
import { Platform, View } from 'react-native';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import {
  CoreInstrumentFieldsFragment,
  DiscoverCategory,
  MostWatchlistedFeedItem,
  useGetMostWatchlistedQuery,
} from '../../generated/graphql';
import { AllStackParamList } from '../../navigation';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { useTailwind } from '../../theme';
import { Fab } from '../../ui/Button';
import { PortfolioWeights } from '../../ui/PortfolioWeights';
import { Pressable } from '../../ui/Pressable';
import { ProgressBar } from '../../ui/ProgressBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { SearchBar } from '../../ui/SearchBar';
import { Text } from '../../ui/Text';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { isNotNull } from '../../util/typeGuards';
import { Unpack } from '../../util/types';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, usePortfolioIntendedPersistedStore } from '../../zustand/store';
import { SearchPage } from './SearchPage';
import { SelectedStocks } from './SelectedStocksBar';
import { StockList } from './StockList';
import { StockSelectionContext, useStockSelection } from './useStockSelection';

/* eslint-disable graphql/template-strings */
export const GET_MOST_WATCHLISTED = gql`
  ${CORE_INSTRUMENT_FIELDS}
  query GetMostWatchlisted($loggedIn: Boolean!) {
    discoverCategory(input: { categoryType: MOST_WATCHLISTED }) {
      id
      items(limit: 20) {
        ... on MostWatchlistedFeedItem {
          instrument {
            ...CoreInstrumentFields
          }
        }
      }
    }
  }
`;

export type StockSelectionScreenParams = {
  Main: undefined;
  Search: undefined;
};

const MIN_SELECTED_STOCKS = 1;

const Stack =
  Platform.OS === 'web'
    ? createStackNavigator<StockSelectionScreenParams>()
    : createNativeStackNavigator<StockSelectionScreenParams>();
type Props = StackScreenProps<AllStackParamList, 'OnboardingSelectStocks'>;

export const OnboardingSelectStocks: React.VFC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const apolloClient = useApolloClient();
  const { stockLayer, setStocks } = usePortfolioIntendedPersistedStore(({ stockLayer, setStocks }) => ({
    stockLayer,
    setStocks,
  }));
  const { data } = useGetMostWatchlistedQuery({ variables: { loggedIn } });
  const mostWatchlistedInstruments =
    data?.discoverCategory.items
      .filter(isMostWatchlistedFeedItem)
      .map(({ instrument }) => instrument)
      .filter(isNotNull) ?? [];

  const addInstrument = useCallback(
    (newInstrumentId: string) => {
      const currentInstrumentIds = stockLayer.constituentIds;
      if (currentInstrumentIds.length < 3 && currentInstrumentIds.findIndex((i) => i === newInstrumentId) === -1) {
        // Retrieve new instrument from apollo cache and add it to the layer
        const newInstrument = apolloClient.readFragment<CoreInstrumentFieldsFragment>({
          id: `Instrument:${newInstrumentId}`,
          fragment: CORE_INSTRUMENT_FIELDS,
          variables: { loggedIn },
        });
        if (newInstrument) {
          return setStocks([...stockLayer.constituents, newInstrument]);
        }
      }
    },
    [stockLayer, setStocks, apolloClient, loggedIn],
  );

  const removeInstrument = useCallback(
    (instrumentId: string) => {
      const currentInstruments = stockLayer.constituents;
      setStocks(currentInstruments.filter(({ id }) => id !== instrumentId));
    },
    [setStocks, stockLayer],
  );

  return (
    <StockSelectionContext.Provider
      value={{
        addInstrument,
        removeInstrument,
        selectedStocks: stockLayer.constituents ?? [],
        mostWatchlistedInstruments: mostWatchlistedInstruments ?? [],
      }}
    >
      <NavigationContainer independent={true}>
        <Stack.Navigator
          initialRouteName="Main"
          screenOptions={{
            headerShown: false,
            presentation: 'card',
            animation: 'slide_from_bottom',
            animationEnabled: true,
          }}
        >
          <Stack.Screen name="Main" component={MainPage} />
          <Stack.Screen name="Search" component={SearchPage} />
        </Stack.Navigator>
        {stockLayer.constituents?.length > 0 && <SelectedStocks />}
        {stockLayer.constituents?.length >= MIN_SELECTED_STOCKS && (
          <Fab onPress={() => navigation.navigate('OnboardingConfirmPortfolio')} />
        )}
      </NavigationContainer>
    </StockSelectionContext.Provider>
  );
});

const MainPage: React.VFC<NativeStackScreenProps<StockSelectionScreenParams, 'Main'>> = ({ navigation }) => {
  const tailwind = useTailwind();
  const { baseLayer, stackLayer, stockLayer } = usePortfolioIntendedPersistedStore(
    ({ baseLayer, stackLayer, stockLayer }) => ({
      baseLayer,
      stackLayer,
      stockLayer,
    }),
  );
  const { mostWatchlistedInstruments } = useStockSelection();

  return (
    <SafeAreaView>
      <ScreenSidePadding>
        <TopBar endAdornment={<ProgressBar width={42} progress={5 / 6} />} />
      </ScreenSidePadding>
      <ScrollView>
        <ScreenSidePadding>
          <TitleBar text="Choose your stocks" />
          <Text style={tailwind('text-grey text-sm pb-5 pt-8')}>Current layer</Text>
          <PortfolioWeights
            highlight="STOCKS"
            weights={{ base: baseLayer.weight, bundles: stackLayer.weight, stocks: stockLayer.weight }}
          />
          <View style={tailwind('flex-row justify-between mt-10 mb-5')}>
            <Text style={tailwind('text-lg font-semibold text-default')}>Select 3 stocks</Text>
            {/* <InfoLink text="Why 3 stocks?" onPress={noop} /> */}
          </View>
          <Pressable
            accessibilityRole="button"
            onPress={() => {
              navigation.navigate('Search');
            }}
          >
            <SearchBar enabled={false} />
          </Pressable>
        </ScreenSidePadding>
        <View style={tailwind('px-6 py-6')}>
          <StockList stocks={mostWatchlistedInstruments} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

function isMostWatchlistedFeedItem(
  item: Partial<Unpack<DiscoverCategory['items']>>,
): item is Pick<MostWatchlistedFeedItem, 'instrument' | '__typename'> {
  return item.__typename === 'MostWatchlistedFeedItem' && 'instrument' in item;
}
