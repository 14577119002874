import { StyleSheet, View } from 'react-native';
import Animated, { useAnimatedStyle, withSpring } from 'react-native-reanimated';
import { useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';
import { TimeInterval } from '../../../util/string';

type Props = {
  width: number | undefined;
  selectedInterval: Animated.SharedValue<number>;
  intervals: TimeInterval[];
  onSelect: (interval: TimeInterval) => void;
};
export const IntervalSelector: React.VFC<Props> = ({ intervals, selectedInterval, onSelect, width }) => {
  const tailwind = useTailwind();
  const selectStyles = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: width
          ? withSpring((selectedInterval.value * width) / intervals.length, {
              stiffness: 125,
              damping: 12,
            })
          : 0,
      },
    ],
  }));

  if (!width || intervals.length === 0) {
    return null;
  }
  return (
    <View style={tailwind('mt-3 mb-1')}>
      <View style={tailwind('flex-row justify-evenly items-center')}>
        {intervals.map((t) => (
          <Pressable
            key={t}
            style={tailwind('justify-end pb-2 pt-4 flex-1')}
            onPress={() => onSelect(t)}
            accessibilityLabel={t}
            accessibilityRole="button"
          >
            <Text style={tailwind('text-center')}>{t}</Text>
          </Pressable>
        ))}
      </View>
      <Animated.View style={[StyleSheet.absoluteFill, { top: 8, width: width / intervals.length }, selectStyles]}>
        <View style={[tailwind('h-1 rounded-full bg-teal-700 w-3/5 self-center')]} />
      </Animated.View>
    </View>
  );
};
