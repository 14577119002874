import { gql } from '@apollo/client';

export const CORE_USER_PROFILE_FIELDS = gql`
  fragment CoreUserProfileFields on UserProfile {
    id
    nickname
    profileRating
    media {
      id
      profileLg
      profileFull
      profileXl
    }
  }
`;
