import React from 'react';
import { View } from 'react-native';
import { FullLogo } from '../../old/icons';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

/** Header when external is true in Sign Up route */
export const Header: React.VFC = () => {
  const tailwind = useTailwind();
  return (
    <>
      <View style={tailwind('items-center pt-4')}>
        <FullLogo />
      </View>
      <Text style={tailwind('text-warmGray-800 text-2xl font-normal pt-8')}>Like what you see?</Text>
      <Text style={tailwind('text-warmGray-800 text-lg font-normal pt-4 pb-6')}>
        More insights await. Stop guessing, start investing with Upside.
      </Text>
    </>
  );
};
