import React from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { InstrumentLogo } from '../InstrumentLogo';

type Props = {
  name: string;
  ticker: string;
  logoUrl?: string;
  sector?: string;
  hideLogo?: boolean;
};

export const InstrumentHeader: React.VFC<Props> = ({ name, ticker, logoUrl, sector, hideLogo }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row py-2 px-6')}>
      <View style={tailwind('flex-1')}>
        <View style={tailwind('pb-1')}>
          <Text style={tailwind('text-2xl font-semibold text-warmGray-800')} numberOfLines={1}>
            {name}
          </Text>
        </View>
        <View>
          <Text style={tailwind('text-sm text-warmGray-500')} numberOfLines={1}>
            {sector}
          </Text>
        </View>
      </View>
      {!hideLogo && (
        <View style={tailwind('ml-1')}>
          <InstrumentLogo logoUrl={logoUrl} ticker={ticker} size="large" />
        </View>
      )}
    </View>
  );
};
