import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ViewStyle } from 'react-native';
import { LoggedInStackNavigationProps } from '../../navigation';
import { Pressable } from '../../ui/Pressable';

type Props = {
  instrumentId: string;
  style?: ViewStyle;
  /** true if in a modal and ios modal doesnt close modals on navigate so would appear behind without replace */
  replaceNavigation?: boolean;
  testID?: string;
};

/**
 * Wrapper for any instrument list items where we want navigation.
 * Allows us to define inline components with navigation in flatLists, without having to wrap them ourselves
 */
export const InstrumentNavigationWrapper: React.FC<Props> = ({
  instrumentId,
  style,
  children,
  replaceNavigation,
  testID,
}) => {
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <Pressable
      accessibilityRole="button"
      style={style}
      testID={testID}
      onPress={() => {
        if (replaceNavigation) {
          navigation.replace('Instrument', { instrumentId });
        } else {
          navigation.push('Instrument', { instrumentId });
        }
      }}
    >
      {children}
    </Pressable>
  );
};
