import React, { useMemo, useState } from 'react';
import { ActivityIndicator, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { colors, useTailwind } from '../../theme';
import { AnimatedPressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';

export type Props = {
  text: string;
  onPress: () => void;
  variant?: 'primary' | 'secondary' | 'alternative' | 'inverted' | 'inverted-bright' | 'primary-bright';
  size?: 'sm' | 'md' | 'lg';
  isDisabled?: boolean;
  loading?: boolean;
  StartIcon?: React.FC<Pick<SvgProps, 'stroke' | 'color' | 'height' | 'width'>>;
  EndIcon?: React.FC<Pick<SvgProps, 'stroke' | 'color' | 'height' | 'width'>>;
  redirectIfLoggedOut?: boolean;
  rounded?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  label?: string;
};

/**
 * Common Button
 *
 * @param param0
 * @param param0.text
 * @param param0.onPress
 * @param param0.variant default is primary
 * @param param0.size default is md
 * @param param0.isDisabled
 * @param param0.loading
 * @param param0.StartIcon
 * @param param0.RedirectIfLoggedOut default false
 */
export const Button: React.VFC<Props> = ({
  text,
  onPress,
  variant = 'primary',
  size = 'md',
  isDisabled = false,
  loading = false,
  StartIcon,
  EndIcon,
  redirectIfLoggedOut,
  rounded,
  style,
  textStyle,
  label,
}) => {
  const tailwind = useTailwind();
  const [isPressed, setIsPressed] = useState(false);

  // Note: Button track event done in Pressable
  return (
    <AnimatedPressable
      onPressIn={() => setIsPressed(true)}
      onPress={onPress}
      onPressOut={() => setIsPressed(false)}
      disabled={isDisabled}
      accessibilityLabel={label ?? text}
      accessibilityRole="button"
      redirectIfLoggedOut={redirectIfLoggedOut}
      style={[
        tailwind('flex-row items-center justify-center'),
        getButtonColor({ variant, pressed: isPressed, isDisabled }, tailwind),
        getButtonSize({ size, rounded }, tailwind),
        style,
      ]}
      animate={useMemo(
        () =>
          ({ pressed }) => {
            'worklet';
            return {
              scale: pressed ? 0.97 : 1,
              // TODO: perhaps animate an interpolated background color
            };
          },
        [],
      )}
    >
      {!loading && StartIcon && (
        <View style={getIconPadding({ size }, tailwind)}>
          <StartIcon
            color={getIconStrokeColor({ variant, isDisabled })}
            height={getIconSize({ size })}
            width={getIconSize({ size })}
          />
        </View>
      )}
      {loading && <ActivityIndicator style={tailwind('pr-2')} animating={true} />}
      <Text
        style={[
          tailwind('font-medium'),
          getTextColor({ variant, isDisabled }, tailwind),
          getTextSize({ size }, tailwind),
          textStyle,
        ]}
      >
        {text}
      </Text>
      {!loading && EndIcon && (
        <View style={getIconPadding({ size }, tailwind)}>
          <EndIcon
            color={getIconStrokeColor({ variant, isDisabled })}
            height={getIconSize({ size })}
            width={getIconSize({ size })}
          />
        </View>
      )}
    </AnimatedPressable>
  );
};

const getButtonColor = (
  args: {
    variant: NonNullable<Props['variant']>;
    pressed: boolean;
    isDisabled: boolean;
  },
  tailwind: ReturnType<typeof useTailwind>,
): StyleProp<ViewStyle> => {
  const { variant, pressed, isDisabled } = args;
  if (isDisabled) {
    return tailwind('bg-warmGray-200');
  }

  switch (variant) {
    case 'primary':
      return pressed ? tailwind('bg-teal-800') : tailwind('bg-teal-700');
    case 'primary-bright':
      return pressed ? tailwind('bg-leaf-600') : tailwind('bg-leaf-500');
    case 'secondary':
      return pressed ? tailwind('bg-warmGray-400') : tailwind('bg-warmGray-300');
    case 'alternative':
      return pressed ? tailwind('bg-blue-600') : tailwind('bg-blue-500');
    case 'inverted':
      return pressed ? tailwind('bg-teal-100') : tailwind('bg-white');
    case 'inverted-bright':
      return tailwind('bg-transparent');
  }
};

/**
 * padding, height and rounder corners
 *
 * @param args
 * @param args.size
 * @param args.rounded
 */
const getButtonSize = (
  args: { size: NonNullable<Props['size']>; rounded?: boolean },
  tailwind: ReturnType<typeof useTailwind>,
): StyleProp<ViewStyle> => {
  const { size, rounded } = args;
  switch (size) {
    case 'sm':
      return tailwind(`px-6 ${rounded ? 'h-11' : 'h-9'} ${rounded ? 'rounded-full' : 'rounded-lg'}`);
    case 'md':
      return tailwind(`${rounded ? 'px-8' : 'px-4'} h-11 ${rounded ? 'rounded-full' : 'rounded-xl'}`);
    case 'lg':
      return tailwind(`${rounded ? 'px-8' : 'px-5'} h-14 ${rounded ? 'rounded-full' : 'rounded-2xl'}`);
  }
};

const getTextColor = (
  args: {
    variant: NonNullable<Props['variant']>;
    isDisabled: boolean;
  },
  tailwind: ReturnType<typeof useTailwind>,
): StyleProp<ViewStyle> => {
  const { variant, isDisabled } = args;
  if (isDisabled) {
    return tailwind('text-warmGray-400');
  }

  switch (variant) {
    case 'primary':
    case 'primary-bright':
    case 'alternative':
      return tailwind('text-white');
    case 'secondary':
      return tailwind('text-warmGray-800');
    case 'inverted':
      return tailwind('text-teal-800');
    case 'inverted-bright':
      return tailwind('text-leaf-500');
  }
};

const getTextSize = (
  args: { size: NonNullable<Props['size']> },
  tailwind: ReturnType<typeof useTailwind>,
): StyleProp<ViewStyle> => {
  const { size } = args;
  switch (size) {
    case 'sm':
      return tailwind('text-sm');
    case 'md':
      return tailwind('text-base');
    case 'lg':
      return tailwind('text-lg');
  }
};

const getIconStrokeColor = (args: {
  variant: NonNullable<Props['variant']>;
  isDisabled: boolean;
}):
  | 'white'
  | typeof colors.warmGray['700']
  | typeof colors.warmGray['400']
  | typeof colors.teal['700']
  | typeof colors.leaf['500'] => {
  const { variant, isDisabled } = args;
  if (isDisabled) {
    return colors.warmGray[400];
  }

  switch (variant) {
    case 'primary':
    case 'primary-bright':
    case 'alternative':
      return 'white';
    case 'secondary':
      return colors.warmGray[700];
    case 'inverted':
      return colors.teal[700];
    case 'inverted-bright':
      return colors.leaf[500];
  }
};

const getIconSize = (args: { size: NonNullable<Props['size']> }): 16 | 24 => {
  const { size } = args;

  return size === 'lg' ? 24 : 16;
};

const getIconPadding = (
  args: { size: NonNullable<Props['size']> },
  tailwind: ReturnType<typeof useTailwind>,
): StyleProp<ViewStyle> => {
  const { size } = args;
  switch (size) {
    case 'sm':
      return tailwind('px-1');
    case 'md':
      return tailwind('px-2');
    case 'lg':
      return tailwind('px-3');
  }
};
