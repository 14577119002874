import { forwardRef } from 'react';
import { FlatList, FlatListProps, ListRenderItemInfo, View } from 'react-native';
import { FeedCardFieldsFragment } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { useTutorialCardPersistedStore } from '../../zustand/store';
import { CardSkeleton } from '../Card';
import { Divider } from '../Divider';
import { CorrelationCard } from '../feedCards/CorrelationCard';
import { CrossCard } from '../feedCards/CrossCard';
import { MacdCard } from '../feedCards/MacdCard';
import { NetShortDisclosureCard } from '../feedCards/NetShortDisclosureCard';
import { NewCommentCard } from '../feedCards/NewCommentCard';
import { NewIdeaCard } from '../feedCards/NewIdeaCard';
import { NewsCard } from '../feedCards/NewsCard';
import { ReportRatingCard } from '../feedCards/ReportRatingCard';
import { RSICard } from '../feedCards/RSICard';
import { SignificantDailyMoverCard } from '../feedCards/SignificantDailyMoverCard';
import { UpsideVoiceCard } from '../feedCards/UpsideVoiceCard';
import { Feed as FeedIcon, Laugh } from '../icons';
import { ScreenSidePadding } from '../StyledScreen';
import { isTutorialCardDummy, TutorialCard, TutorialCardDummy } from '../TutorialCard';

// Include tutorial card
export type FlatListFeedCardFields = FeedCardFieldsFragment | TutorialCardDummy;

export type Props = Omit<
  FlatListProps<FlatListFeedCardFields>,
  'renderItem' | 'keyExtractor' | 'contentContainerStyle' | 'ItemSeparatorComponent'
>;

export const Feed = forwardRef<FlatList, Props>((props, ref) => {
  const tailwind = useTailwind();
  return (
    <FlatList
      {...props}
      ref={ref}
      ItemSeparatorComponent={() => <Divider style={tailwind('mx-6')} />}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      contentContainerStyle={tailwind('flex-grow')}
    />
  );
});

const renderItem = ({ item }: ListRenderItemInfo<FlatListFeedCardFields>) => <CardSwitch card={item} />;

const keyExtractor = (item: FeedCardFieldsFragment | TutorialCardDummy, index: number) =>
  `${'id' in item ? item.id : index}`;

/** Render a feed card for the feed item. Also used in Instrument and FeedItem screens to render a shared feed item */
export const CardSwitch: React.FC<{
  card: FlatListFeedCardFields;
}> = ({ card }) => {
  const tailwind = useTailwind();
  if (!card) return null;
  if (isTutorialCardDummy(card)) {
    return (
      <View style={tailwind('py-2')}>
        <TutorialCard
          title="Welcome to the Insight Feed"
          onDismiss={() => useTutorialCardPersistedStore.setState({ feedTutorialDismissed: true })}
          Icon={FeedIcon}
          style={tailwind('px-6')}
        >
          Here you find a stream of insights on your watched stocks (news, market moves, technical signals, Ideas etc.)
          plus activity from across the platform.
          {'\n'}
          {'\n'}
          Select a card for a more detailed view.
          {'\n'}
          {'\n'}
          Tap the dots for more actions.
        </TutorialCard>
      </View>
    );
  }

  switch (card.__typename) {
    case 'ReportRatingFeedItem':
      return <ReportRatingCard {...card} />;
    case 'CrossFeedItem':
      return <CrossCard {...card} />;
    case 'RelativeStrengthIndexFeedItem':
      return <RSICard {...card} />;
    case 'CorrelationFeedItem':
      return <CorrelationCard {...card} />;
    case 'NewIdeaFeedItem':
      return <NewIdeaCard {...card} />;
    case 'NewsFeedItem':
      return <NewsCard {...card} />;
    case 'SignificantDailyMoverFeedItem':
      return <SignificantDailyMoverCard {...card} />;
    case 'NetShortDisclosureFeedItem':
      return <NetShortDisclosureCard {...card} />;
    case 'MacdFeedItem':
      return <MacdCard {...card} />;
    case 'NewCommentFeedItem':
      return <NewCommentCard {...card} />;
    case 'UpsideVoiceFeedItem':
      return <UpsideVoiceCard {...card} />;
  }
  // Unknown card type - don't render anything
  return null;
};

export const EndOfFeed: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('px-6 items-center')}>
      <Laugh height={48} width={48} style={tailwind('mb-4')} />
      <Text style={tailwind('text-center text-sm text-warmGray-400')}>
        Congrats, you&apos;ve reached the end of your feed! Come back tomorrow for new content.
      </Text>
    </View>
  );
};

export const FeedSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      <SkeletonView style={tailwind('my-8')}>
        {new Array(10).fill(null).map((_, key) => (
          <View key={key} style={tailwind('mb-8')}>
            <CardSkeleton />
          </View>
        ))}
      </SkeletonView>
    </ScreenSidePadding>
  );
};
