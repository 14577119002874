import { FC } from 'react';
import Svg, { ClipPath, Defs, G, LinearGradient, Path, Rect, Stop, SvgProps } from 'react-native-svg';

export const URound: FC<SvgProps> = (props) => (
  <Svg width={14} height={14} viewBox="0 0 15 15" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Rect x={0.947} width={13.536} height={14} rx={6.768} fill="#fff" />
      <Path fill="#fff" d="M.947 0h14v14h-14z" />
      <Path fill="#64B950" d="M.947 0h16.244v16.8H.947z" />
      <Path fill="url(#b)" fillOpacity={0.2} d="M.947 0h16.244v16.8H.947z" />
      <G>
        <Path
          d="m11.776 5.686-1.749 4.06c-.862 2.002-2.446 2.341-4.267 1.469-1.821-.873-2.62-2.353-1.758-4.356L5.751 2.8l2.365 1.133L6.47 7.756c-.231.537-.418 1.018.213 1.32.63.302.85-.163 1.082-.7l1.647-3.823 2.365 1.133Z"
          fill="#fff"
        />
      </G>
    </G>
    <Defs>
      <LinearGradient id="b" x1={9.069} y1={0} x2={9.069} y2={16.8} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
      <ClipPath id="a">
        <Rect x={0.947} width={13.536} height={14} rx={6.768} fill="#fff" />
      </ClipPath>
    </Defs>
  </Svg>
);
