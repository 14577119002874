import { ReactText, useMemo, VFC } from 'react';
import { StyleSheet } from 'react-native';
import { default as MarkdownDisplay, renderRules } from 'react-native-markdown-display';
import { visitLink } from '../../services/openLink';
import { appRules } from './rules';
import { useMarkdownStyle } from './useMarkdownStyle';
import { markdownItInstance, markdownToAST } from './util';

export const Markdown: VFC<{ children: ReactText }> = ({ children }) => {
  const markdownStyle = useMarkdownStyle();
  const ast = useMemo(() => {
    return markdownToAST(`${children}`, markdownItInstance);
  }, [children]);

  return (
    <MarkdownDisplay
      onLinkPress={(url) => {
        visitLink(url);
        return false;
      }}
      markdownit={markdownItInstance}
      rules={{ ...renderRules, ...appRules }}
      // Tailwind styles aren't typed as stylesheet styles so clash here.
      style={markdownStyle as unknown as StyleSheet.NamedStyles<unknown>}
    >
      {ast}
    </MarkdownDisplay>
  );
};
