import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Discover } from '../screens/Discover/Discover';
import { LoggedInStackParamList } from './RootStackNavigator';
import { TabsParamList } from './TabsNavigator';

export type DiscoverStackParamList = {
  Discover: undefined;
};

const Stack = createNativeStackNavigator<DiscoverStackParamList>();

type Props = CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'DiscoverTab'>,
  NativeStackScreenProps<LoggedInStackParamList>
>;

/**
 * Discover Stack
 */
export const DiscoverNavigator: React.FC<Props> = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Discover" component={Discover} />
    </Stack.Navigator>
  );
};
