import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Feed } from '../screens/Feed';
import { LoggedInStackParamList } from './RootStackNavigator';
import { TabsParamList } from './TabsNavigator';

export type FeedStackParamList = {
  Feed: undefined;
};

const Stack = createNativeStackNavigator<FeedStackParamList>();

type Props = CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'FeedTab'>,
  NativeStackScreenProps<LoggedInStackParamList>
>;

/**
 * Feed Stack
 */
export const FeedNavigator: React.FC<Props> = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Feed" component={Feed} />
    </Stack.Navigator>
  );
};
