
      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Comment",
      "Country",
      "Currency",
      "DatoBlog",
      "DatoAppContent",
      "DatoUpsideVoice",
      "DiscoverCategory",
      "UpsideVoiceFeedItem",
      "MacdFeedItem",
      "NetShortDisclosureFeedItem",
      "SignificantDailyMoverFeedItem",
      "MostWatchlistedFeedItem",
      "ReportRatingFeedItem",
      "CrossFeedItem",
      "RelativeStrengthIndexFeedItem",
      "NewIdeaFeedItem",
      "CorrelationFeedItem",
      "NewsFeedItem",
      "DiscoverCategoryFeedItem",
      "CurationsCategoryFeedItem",
      "SectorDiscoverCategoryFeedItem",
      "NewCommentFeedItem",
      "Idea",
      "IdeaInsight",
      "IdeaWatchlistItem",
      "Outlook",
      "Industry",
      "Instrument",
      "InstrumentWatchlistItem",
      "News",
      "Notification",
      "PrePortfolio",
      "PrePortfolioMember",
      "PrePortfolioSimulation",
      "PrePortfolioSimulationMember",
      "QuotePrice",
      "ClosePrice",
      "Sector",
      "Component",
      "StackCommentary"
    ],
    "FeedItemNode": [
      "UpsideVoiceFeedItem",
      "MacdFeedItem",
      "NetShortDisclosureFeedItem",
      "SignificantDailyMoverFeedItem",
      "MostWatchlistedFeedItem",
      "ReportRatingFeedItem",
      "CrossFeedItem",
      "RelativeStrengthIndexFeedItem",
      "NewIdeaFeedItem",
      "CorrelationFeedItem",
      "NewsFeedItem",
      "DiscoverCategoryFeedItem",
      "CurationsCategoryFeedItem",
      "SectorDiscoverCategoryFeedItem",
      "NewCommentFeedItem"
    ],
    "Connection": [
      "CommentConnection",
      "IdeaConnection",
      "InstrumentConnection",
      "NotificationConnection",
      "ComponentConnection",
      "UserIdeaConnection"
    ],
    "ValidationError": [
      "EmailInvalid",
      "NicknameInvalid",
      "PasswordInvalid",
      "AuthenticationFailed",
      "TokenNotProvided",
      "UserInvalid",
      "TokenInvalid",
      "CommentProfane",
      "CommentTooLong",
      "CommentEmpty",
      "IdeaAlreadyClosed",
      "PrePortfolioStatsInvalidDate",
      "PrePortfolioStatsNoMemberWeight",
      "PrePortfolioStatsNoMembers",
      "PrePortfolioSimulationNoMembers",
      "PrePortfolioImmutable",
      "PrePortfolioMissingField",
      "TradingValidationError",
      "EmptyFile",
      "FileSizeInvalid",
      "FileTypeInvalid",
      "FileNotFound"
    ],
    "SignupError": [
      "EmailInvalid",
      "NicknameInvalid",
      "PasswordInvalid"
    ],
    "LoginError": [
      "AuthenticationFailed"
    ],
    "RefreshError": [
      "AuthenticationFailed",
      "TokenNotProvided"
    ],
    "RequestPasswordResetError": [
      "EmailInvalid"
    ],
    "ConfirmPasswordResetError": [
      "UserInvalid",
      "TokenInvalid",
      "PasswordInvalid"
    ],
    "CommentServiceError": [
      "CommentProfane",
      "CommentTooLong",
      "CommentEmpty"
    ],
    "DatoConnection": [
      "DatoBlogConnection",
      "DatoAppContentConnection",
      "DatoStackCommentaryConnection"
    ],
    "FeedItem": [
      "ReportRatingFeedItem",
      "CrossFeedItem",
      "RelativeStrengthIndexFeedItem",
      "NewIdeaFeedItem",
      "CorrelationFeedItem",
      "MostWatchlistedFeedItem",
      "NewsFeedItem",
      "SignificantDailyMoverFeedItem",
      "DiscoverCategoryFeedItem",
      "SectorDiscoverCategoryFeedItem",
      "NetShortDisclosureFeedItem",
      "MacdFeedItem",
      "NewCommentFeedItem",
      "CurationsCategoryFeedItem",
      "UpsideVoiceFeedItem"
    ],
    "CloseIdeaError": [
      "IdeaAlreadyClosed"
    ],
    "PrePortfolioStatsError": [
      "PrePortfolioStatsInvalidDate",
      "PrePortfolioStatsNoMemberWeight",
      "PrePortfolioStatsNoMembers"
    ],
    "CreatePrePortfolioSimulationsError": [
      "PrePortfolioSimulationNoMembers"
    ],
    "UpdatePrePortfolioError": [
      "PrePortfolioImmutable",
      "PrePortfolioMissingField"
    ],
    "TradingError": [
      "TradingValidationError"
    ],
    "FileUploadError": [
      "EmptyFile",
      "FileSizeInvalid",
      "FileTypeInvalid"
    ],
    "FileDeleteError": [
      "FileNotFound"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Comment",
      "Country",
      "Currency",
      "DatoBlog",
      "DatoAppContent",
      "DatoUpsideVoice",
      "DiscoverCategory",
      "UpsideVoiceFeedItem",
      "MacdFeedItem",
      "NetShortDisclosureFeedItem",
      "SignificantDailyMoverFeedItem",
      "MostWatchlistedFeedItem",
      "ReportRatingFeedItem",
      "CrossFeedItem",
      "RelativeStrengthIndexFeedItem",
      "NewIdeaFeedItem",
      "CorrelationFeedItem",
      "NewsFeedItem",
      "DiscoverCategoryFeedItem",
      "CurationsCategoryFeedItem",
      "SectorDiscoverCategoryFeedItem",
      "NewCommentFeedItem",
      "Idea",
      "IdeaInsight",
      "IdeaWatchlistItem",
      "Outlook",
      "Industry",
      "Instrument",
      "InstrumentWatchlistItem",
      "News",
      "Notification",
      "PrePortfolio",
      "PrePortfolioMember",
      "PrePortfolioSimulation",
      "PrePortfolioSimulationMember",
      "QuotePrice",
      "ClosePrice",
      "Sector",
      "Component",
      "StackCommentary"
    ],
    "FeedItemNode": [
      "UpsideVoiceFeedItem",
      "MacdFeedItem",
      "NetShortDisclosureFeedItem",
      "SignificantDailyMoverFeedItem",
      "MostWatchlistedFeedItem",
      "ReportRatingFeedItem",
      "CrossFeedItem",
      "RelativeStrengthIndexFeedItem",
      "NewIdeaFeedItem",
      "CorrelationFeedItem",
      "NewsFeedItem",
      "DiscoverCategoryFeedItem",
      "CurationsCategoryFeedItem",
      "SectorDiscoverCategoryFeedItem",
      "NewCommentFeedItem"
    ],
    "Connection": [
      "CommentConnection",
      "IdeaConnection",
      "InstrumentConnection",
      "NotificationConnection",
      "ComponentConnection",
      "UserIdeaConnection"
    ],
    "ValidationError": [
      "EmailInvalid",
      "NicknameInvalid",
      "PasswordInvalid",
      "AuthenticationFailed",
      "TokenNotProvided",
      "UserInvalid",
      "TokenInvalid",
      "CommentProfane",
      "CommentTooLong",
      "CommentEmpty",
      "IdeaAlreadyClosed",
      "PrePortfolioStatsInvalidDate",
      "PrePortfolioStatsNoMemberWeight",
      "PrePortfolioStatsNoMembers",
      "PrePortfolioSimulationNoMembers",
      "PrePortfolioImmutable",
      "PrePortfolioMissingField",
      "TradingValidationError",
      "EmptyFile",
      "FileSizeInvalid",
      "FileTypeInvalid",
      "FileNotFound"
    ],
    "SignupError": [
      "EmailInvalid",
      "NicknameInvalid",
      "PasswordInvalid"
    ],
    "LoginError": [
      "AuthenticationFailed"
    ],
    "RefreshError": [
      "AuthenticationFailed",
      "TokenNotProvided"
    ],
    "RequestPasswordResetError": [
      "EmailInvalid"
    ],
    "ConfirmPasswordResetError": [
      "UserInvalid",
      "TokenInvalid",
      "PasswordInvalid"
    ],
    "CommentServiceError": [
      "CommentProfane",
      "CommentTooLong",
      "CommentEmpty"
    ],
    "DatoConnection": [
      "DatoBlogConnection",
      "DatoAppContentConnection",
      "DatoStackCommentaryConnection"
    ],
    "FeedItem": [
      "ReportRatingFeedItem",
      "CrossFeedItem",
      "RelativeStrengthIndexFeedItem",
      "NewIdeaFeedItem",
      "CorrelationFeedItem",
      "MostWatchlistedFeedItem",
      "NewsFeedItem",
      "SignificantDailyMoverFeedItem",
      "DiscoverCategoryFeedItem",
      "SectorDiscoverCategoryFeedItem",
      "NetShortDisclosureFeedItem",
      "MacdFeedItem",
      "NewCommentFeedItem",
      "CurationsCategoryFeedItem",
      "UpsideVoiceFeedItem"
    ],
    "CloseIdeaError": [
      "IdeaAlreadyClosed"
    ],
    "PrePortfolioStatsError": [
      "PrePortfolioStatsInvalidDate",
      "PrePortfolioStatsNoMemberWeight",
      "PrePortfolioStatsNoMembers"
    ],
    "CreatePrePortfolioSimulationsError": [
      "PrePortfolioSimulationNoMembers"
    ],
    "UpdatePrePortfolioError": [
      "PrePortfolioImmutable",
      "PrePortfolioMissingField"
    ],
    "TradingError": [
      "TradingValidationError"
    ],
    "FileUploadError": [
      "EmptyFile",
      "FileSizeInvalid",
      "FileTypeInvalid"
    ],
    "FileDeleteError": [
      "FileNotFound"
    ]
  }
};
      export default result;
    