import { gql, NetworkStatus } from '@apollo/client';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, useScrollToTop } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import dayjs from 'dayjs';
import { round } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FlatList, RefreshControl, View, ViewToken } from 'react-native';
import { FEED_ITEM_TYPES } from '../../constants/feed';
import { FEED_CARD_FIELDS } from '../../fragments/feed';
import { GetFeedQuery, useGetFeedQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { FeedStackParamList } from '../../navigation/FeedNavigator';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { EndOfFeed, FeedSkeleton, Feed as FeedView } from '../../old/Feed';
import { TitleBar } from '../../old/TitleBar';
import { TitleBarMainIcons } from '../../old/TitleBarMainIcons';
import { tutorialCardDummy } from '../../old/TutorialCard/TutorialCard';
import { analytics } from '../../services/analytics';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { EmptyFeed, EmptyFeedNoWatchlist } from './EmptyFeed';
import { FeedFilter, useFeedFilter } from './FeedFilter';

// This is typed as any by RN. See https://reactnative.dev/docs/flatlist
type ViewabilityConfig = {
  minimumViewTime: number;
  viewAreaCoveragePercentThreshold?: number;
  itemVisiblePercentThreshold?: number;
  waitForInteraction?: boolean;
};

const VIEWABILITY_CONFIG: ViewabilityConfig = {
  viewAreaCoveragePercentThreshold: 100,
  minimumViewTime: 1000,
};

/**
 * Feed query, a fragment for each possible FeedItem
 *
 * Note: FeedItems do not have an ID
 */
/* eslint-disable graphql/template-strings */
export const GET_FEED = gql`
  ${FEED_CARD_FIELDS}
  query getFeed($feedTypes: [FeedItemType!], $filterCategories: [FilterCategory!], $loggedIn: Boolean!) {
    feed(input: { feedTypes: $feedTypes, filterCategories: $filterCategories }) {
      ...FeedCardFields
    }
    instrumentWatchlistItems {
      id
      instrument {
        ...CoreInstrumentFields
      }
    }
  }
`;

/** derive the FeedItem type from the GQL query  */
type FeedItem = GetFeedQuery['feed'][0];

export type Props = CompositeScreenProps<
  NativeStackScreenProps<FeedStackParamList>,
  CompositeScreenProps<BottomTabScreenProps<TabsParamList, 'FeedTab'>, NativeStackScreenProps<LoggedInStackParamList>>
>;

/**
 * Feed screen on Feed stack
 *
 * @param root0
 */
export const Feed: React.FC<Props> = withReloadErrorBoundary(() => {
  const tailwind = useTailwind();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const [feedStartTime, setFeedStartTime] = useState<Date | undefined>();
  const [feedLength, setFeedLength] = useState<number | undefined>();
  const [scrollEndReached, setScrollEndReached] = useState(false);
  const { selectedFilterTags, debouncedSelectedFilterTags, onFilterChange } = useFeedFilter();
  const feedTutorialDismissed = useTutorialCardPersistedStore((state) => state.feedTutorialDismissed);
  const { data, error, refetch, networkStatus } = useGetFeedQuery({
    variables: {
      filterCategories: debouncedSelectedFilterTags ?? [],
      feedTypes: FEED_ITEM_TYPES,
      loggedIn,
    },
  });
  const { refreshing, onRefresh } = useRefresh(refetch);

  const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;
  // scroll to top if press tab
  const ref = React.useRef<FlatList | null>(null);
  useScrollToTop(ref);

  if (error) {
    throw error;
  }

  const hasWatchlistItems = !!data?.instrumentWatchlistItems?.length;

  // Store a ref to previous viewable feed items. We don't render anything using these values so refs are fine
  const prevViewableItems = useRef<FeedItem[]>([]);

  /**
   * This can't be put in a useCallback as we need to store prev viewable items. The dep causes a new reference to be created
   * See https://stackoverflow.com/questions/48045696/flatlist-scrollview-error-on-any-state-change-invariant-violation-changing-on/57502343#57502343
   */
  const onViewableItemsChangedRef = React.useRef(({ viewableItems }: { viewableItems: ViewToken[] }) => {
    const feedItems: FeedItem[] = viewableItems.map(({ item }) => {
      return item;
    });
    // Only send view events for items that weren't visible in the previous frame. This is O(n^2) but n is <= 2-3 here.
    const newlyVisibleItems: FeedItem[] = feedItems.filter(
      // Ensure id in feed item
      (i) => !prevViewableItems?.current.some((j) => 'id' in j && 'id' in i && j.id == i.id),
    );

    // Send view feed item events
    newlyVisibleItems?.forEach((item) => {
      analytics.track('Feed card viewed', {
        'Feed item id': 'id' in item ? item.id : '',
        'Feed card type': item.__typename,
        ['Minimum view time (s)']: `${round(VIEWABILITY_CONFIG.minimumViewTime / 1000, 2)}`,
      });
    });

    // Update previous viewable feed items
    prevViewableItems.current = feedItems;
  });

  // Track time to scroll end
  useEffect(() => {
    const feedItems = data?.feed;

    if (feedItems) {
      setFeedStartTime(new Date());
      setFeedLength(feedItems.length);
    }
  }, [data]);

  // On loading new data, reset scroll end event
  useEffect(() => {
    setFeedStartTime(new Date());
    setScrollEndReached(false);
  }, [data]);

  const onEndReached = () => {
    // Only fire this event if a user hasn't reached the end for this set of data.
    if (!scrollEndReached) {
      analytics.track('Completed scroll', {
        'Cards in feed': feedLength ?? 0,
        'Time to scroll end (s)': dayjs.duration(new Date().getTime() - (feedStartTime?.getTime() ?? 0)).asSeconds(),
      });
      setScrollEndReached(true);
    }
  };

  // prepend tutorial card (if not dismissed) to array
  const prependTutorialCard = !feedTutorialDismissed && hasWatchlistItems && (data?.feed?.length ?? 0) > 0;
  // if dont give them diff IDs, then react key doesnt know they are different Cards
  // Hence if you press dismiss on 1st tutorial card, it calls onDismiss on both!
  // So will never see the 2nd card
  const tutorialCardDummyId = feedTutorialDismissed ? `feed-tutorial-2` : `feed-tutorial-1`;

  const flatListData = prependTutorialCard
    ? [{ ...tutorialCardDummy, id: tutorialCardDummyId }, ...(data?.feed ?? [])]
    : data?.feed;

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar title="Feed" showLogo hideBackButton endAdornment={<TitleBarMainIcons />} />
      <View style={tailwind('pb-2')}>
        <FeedFilter value={selectedFilterTags} onChange={onFilterChange} />
      </View>
      {loading ? (
        <FeedSkeleton />
      ) : (
        <FeedView
          ref={ref}
          data={flatListData}
          viewabilityConfig={VIEWABILITY_CONFIG}
          onViewableItemsChanged={onViewableItemsChangedRef.current}
          ListFooterComponent={
            networkStatus === NetworkStatus.ready && data?.feed && data.feed.length > 0 ? EndOfFeed : null
          }
          ListEmptyComponent={hasWatchlistItems ? EmptyFeed : EmptyFeedNoWatchlist}
          onEndReached={onEndReached}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        />
      )}
    </SafeAreaView>
  );
});
