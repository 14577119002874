import { gql, NetworkStatus } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Platform, RefreshControl, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { FEED_ITEM_TYPES } from '../../constants/feed';
import { FEED_CARD_FIELDS } from '../../fragments/feed';
import { INSTRUMENT_ESG, INSTRUMENT_STATISTICS } from '../../fragments/instrument';
import {
  FilterCategory,
  GetInstrumentFeedQuery,
  GetInstrumentQuery,
  useGetInstrumentFeedQuery,
  useGetInstrumentQuery,
} from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { useRefresh } from '../../hooks/useRefresh';
import { AllStackParamList } from '../../navigation';
import { getInstrumentUrl } from '../../navigation/linking';
import { IconButton } from '../../old/Button/IconButton';
import { MAX_PRICE_CHART_TIME_INTERVAL } from '../../old/Charts/IntervalChart';
import { CardSwitch, Feed, FeedSkeleton } from '../../old/Feed';
import { MagnifyingGlass, Share as ShareIcon } from '../../old/icons';
import { SegmentedControl } from '../../old/SegmentedControl';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { TutorialCard } from '../../old/TutorialCard/TutorialCard';
import { analytics } from '../../services/analytics';
import { share } from '../../services/sharing';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { calculateTimeInterval, formatDate } from '../../util/date';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { TutorialCardPersistedStore, usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { FeedFilter, FilterCategoriesTags, useFeedFilter } from '../Feed/FeedFilter';
import { Ideas } from './Ideas';
import { Overview } from './Overview';

export type Props = NativeStackScreenProps<AllStackParamList, 'Instrument'>;
const segments = ['Overview', 'Ideas', 'Feed'] as const;
type Segment = typeof segments[number];

export const getInstrument = gql`
  ${INSTRUMENT_STATISTICS}
  ${INSTRUMENT_ESG}
  query getInstrument($id: ID!, $dateFrom: Date, $feedItemId: ID!, $includeFeedItem: Boolean!, $loggedIn: Boolean!) {
    instrument(id: $id) {
      id
      name
      companyInfo
      ticker
      logoUrl
      watchlistId @include(if: $loggedIn)
      type
      countryOfListing {
        id
        name
      }
      closePriceSeries(dateFrom: $dateFrom) {
        id
        price
        date
      }
      closePrice {
        id
        price
      }
      quotePrice {
        id
        midPrice
        previousClose
        lastUpdated
      }
      currency {
        id
        iso
      }
      sector {
        id
        name
      }
      reportRatingCurrent {
        id
        buyCount
        sellCount
        holdCount
        totalCount
        modeRating
      }
      industry {
        id
        name
      }
      ...InstrumentStatistics
      yearlyFinancials {
        date
        totalRevenue
        netIncome
        netMargin
        costOfRevenue
      }
      esgCurrent {
        ...InstrumentEsg
      }
    }

    ideasPaginated(filter: { instrument: $id, statuses: [ACTIVE] }) @include(if: $loggedIn) {
      nodes {
        id
      }
    }
    feedItem(id: $feedItemId) @include(if: $includeFeedItem) {
      ...FeedCardFields
    }
  }
`;
/* eslint-disable graphql/template-strings */
export const GET_INSTRUMENT_FEED = gql`
  ${FEED_CARD_FIELDS}
  query getInstrumentFeed(
    $id: ID!
    $filterCategories: [FilterCategory!]
    $feedTypes: [FeedItemType!]
    $loggedIn: Boolean!
  ) {
    instrumentFeed(
      input: { instruments: [$id], feedTypes: $feedTypes, filterCategories: $filterCategories, sortMethod: DATE_DESC }
    ) {
      feed {
        ...FeedCardFields
      }
    }
  }
`;
/* eslint-enable graphql/template-strings */

export const Instrument: React.FC<Props> = withReloadErrorBoundary(({ route, navigation }) => {
  const tailwind = useTailwind();
  const { present } = useBottomSheet();
  const { isUserLoggedIn: loggedIn, userId } = usePersistedStore(({ isUserLoggedIn, userId }) => ({
    isUserLoggedIn,
    userId,
  }));
  const { instrumentId, initialSelectedIndex = 0, feedItemId } = route.params;
  const instrumentTutorialCardDismissed = useTutorialCardPersistedStore(instrumentTutorialDismissedSelector);
  const toast = useToast();

  // date now to get all prices prior to this
  const [nowDate] = useState(new Date());
  // dateFrom to query all 5Y of prices
  const [dateFrom] = useState<string>(
    formatDate(calculateTimeInterval(MAX_PRICE_CHART_TIME_INTERVAL, nowDate), 'YYYY-MM-DD'),
  );

  const { data, refetch, loading, error } = useGetInstrumentQuery({
    variables: {
      id: instrumentId,
      // get all 5Y of prices
      dateFrom,
      includeFeedItem: !!feedItemId,
      feedItemId: feedItemId ?? '',
      loggedIn,
    },
  });

  const { debouncedSelectedFilterTags, selectedFilterTags, onFilterChange } = useFeedFilter();
  const {
    data: feedData,
    refetch: refetchFeed,
    networkStatus: feedNetworkStatus,
    error: feedError,
  } = useGetInstrumentFeedQuery({
    variables: {
      id: instrumentId,
      feedTypes: FEED_ITEM_TYPES,
      filterCategories: debouncedSelectedFilterTags ?? [],
      loggedIn,
    },
  });
  const feedLoading = feedNetworkStatus === NetworkStatus.loading || feedNetworkStatus === NetworkStatus.setVariables;

  const { onRefresh, refreshing } = useRefresh(refetch);
  const { onRefresh: onRefreshFeed, refreshing: refreshingFeed } = useRefresh(refetchFeed);

  if (error) {
    throw new Error(`Error fetching instrument with id ${instrumentId}`);
  }

  if (feedError) {
    throw new Error(`Error fetching instrument feed for id ${instrumentId}`);
  }

  // Present feed item in a bottom sheet if it's a param
  useEffect(() => {
    if (feedItemId && data?.feedItem) {
      present(
        <View style={tailwind('py-5')}>
          <CardSwitch card={data?.feedItem} />
        </View>,
      );
    }
  }, [feedItemId, data, present, tailwind]);

  // track view stock page
  const { name, ticker } = data?.instrument ?? {};
  const { name: sectorName } = data?.instrument.sector ?? {};
  useEffect(() => {
    if (name) {
      analytics.track('Viewed Stock Page', {
        instrumentId,
        instrumentName: name,
        ticker: ticker,
        sector: sectorName,
      });
    }
  }, [instrumentId, name, sectorName, ticker]);

  const onShare = async () => {
    try {
      const status = await share(
        {
          message: `Check out ${instrument?.name} on Upside`,
          url: getInstrumentUrl(instrument?.id ?? ''),
          title: `Check out ${instrument?.name}`,
        },
        userId,
      );
      if (status === 'CopiedToClipboard') {
        toast.show('Link copied to clipboard.');
      }
    } catch (e) {
      console.error(e);
      toast.show('Unable to share link. Are sharing or clipboard permissions enabled?');
    }
  };

  const instrument = data?.instrument;
  return (
    <SafeAreaView>
      {
        // Only insert meta tags if web
        Platform.OS === 'web' && (
          <Helmet>
            <meta property="og:title" content={`${instrument?.name}`} />
            <meta property="og:description" content={`Check out ${instrument?.name} on Upside.`} />
            <meta property="og:image" content="/favicon-32.png" />
            <meta property="og:url" content={getInstrumentUrl(instrument?.id ?? '')} />
          </Helmet>
        )
      }
      <TitleBar
        title={instrument?.ticker}
        redirectIfLoggedOut
        showLogo={!loggedIn}
        hideBackButton={!loggedIn}
        endAdornment={
          <IconButton accessibilityLabel="Share" icon={<ShareIcon height={24} width={24} />} onPress={onShare} />
        }
      />

      {/* TODO: better skeleton and show segment control with skeletons in each tab */}
      {loading ? (
        <InstrumentSkeleton />
      ) : (
        <>
          <ScreenSidePadding>
            {!instrumentTutorialCardDismissed && (
              <TutorialCard
                title="Welcome to the Company Screen"
                onDismiss={() => useTutorialCardPersistedStore.setState({ instrumentScreenTutorialDismissed: true })}
                Icon={MagnifyingGlass}
              >
                This detailed screen lets you delve into statistics and price activity, and delivers a stock-specific
                feed of diverse events and insights, allowing you to keep tabs on a company{"'"}s highs, lows, and all
                the bumps in between.
              </TutorialCard>
            )}
            <SegmentedControl
              style={tailwind('my-4')}
              values={segments.slice()}
              onChange={(e) => {
                navigation.setParams({ initialSelectedIndex: e.nativeEvent.selectedSegmentIndex });
              }}
              selectedIndex={initialSelectedIndex}
            />
          </ScreenSidePadding>

          <ContentBody
            segment={segments[initialSelectedIndex ?? 0]}
            data={data}
            onRefresh={onRefresh}
            refreshing={refreshing}
            nowDate={nowDate}
            feedData={feedData}
            feedLoading={feedLoading}
            onRefreshFeed={onRefreshFeed}
            refreshingFeed={refreshingFeed}
            onFeedFilterChange={onFilterChange}
            selectedFeedFilters={selectedFilterTags}
          />
        </>
      )}
    </SafeAreaView>
  );
});

const ContentBody: React.FC<{
  segment: Segment;
  nowDate: Date;
  data?: GetInstrumentQuery;
  refreshing: boolean;
  onRefresh: () => void;
  feedData?: GetInstrumentFeedQuery;
  feedLoading?: boolean;
  onRefreshFeed: () => void;
  refreshingFeed: boolean;
  onFeedFilterChange: (selected: FilterCategoriesTags) => void;
  selectedFeedFilters: FilterCategoriesTags;
}> = ({
  segment,
  nowDate,
  data,
  onRefresh,
  refreshing,
  feedData,
  feedLoading,
  onRefreshFeed,
  refreshingFeed,
  selectedFeedFilters,
  onFeedFilterChange,
}) => {
  const tailwind = useTailwind();
  switch (segment) {
    case 'Overview':
      if (!data?.instrument) return null;
      return (
        <Overview
          instrument={data?.instrument}
          ideas={data?.ideasPaginated}
          nowDate={nowDate}
          onRefresh={onRefresh}
          refreshing={refreshing}
        />
      );
    case 'Ideas':
      return <Ideas instrumentId={data?.instrument.id ?? ''} />;
    case 'Feed':
      return (
        <View>
          <View style={tailwind('py-1')}>
            <FeedFilter onChange={onFeedFilterChange} omitTags={[FilterCategory.Ideas]} value={selectedFeedFilters} />
          </View>
          {feedLoading ? (
            <FeedSkeleton />
          ) : (
            <Feed
              data={feedData?.instrumentFeed?.feed}
              refreshControl={<RefreshControl refreshing={refreshingFeed} onRefresh={onRefreshFeed} />}
            />
          )}
        </View>
      );
  }
};

const instrumentTutorialDismissedSelector = (state: TutorialCardPersistedStore) =>
  state.instrumentScreenTutorialDismissed;

export const InstrumentSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      <SkeletonView>
        <View style={tailwind('mb-2')}>
          <Skeleton style={tailwind('w-40 h-4')} />
        </View>
        <View style={tailwind('mb-4')}>
          <Skeleton style={tailwind('w-64 h-5')} />
        </View>
        <View>
          <Skeleton style={tailwind('w-full h-48')} />
        </View>
      </SkeletonView>
    </ScreenSidePadding>
  );
};
