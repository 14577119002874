import { gql } from '@apollo/client';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import constants from 'expo-constants';
import React, { useMemo, useState, VFC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { COMMIT_HASH, ENVIRONMENT, RUNTIME_VERSION, VERSION } from '../../constants/env';
import { CORE_USER_PROFILE_FIELDS } from '../../fragments/userProfile';
import {
  DeleteProfileImageMutation,
  GetUserQuery,
  Maybe,
  useDeleteProfileImageMutation,
  useGetUserQuery,
  UserMedia,
} from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { ChevronRight } from '../../old/icons/ChevronRight';
import { Logout } from '../../old/icons/Logout';
import { Persona, PersonaSkeleton } from '../../old/Persona';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { analytics } from '../../services/analytics';
import { logOut } from '../../services/logIn';
import { requestProfilePhoto } from '../../services/profilePhoto';
import { colors, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { isFiniteNumber, isNotNull } from '../../util/typeGuards';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';
import { Props as UpsideSharesProps } from '../UpsideShares';

import {
  academySection,
  accountSection,
  communitySection,
  helpSection,
  MenuItem,
  otherSection,
  staffSection,
  MenuSection as TMenuSection,
} from './menuSections';

export const GET_USER = gql`
  ${CORE_USER_PROFILE_FIELDS}
  query GetUser($id: Int!) {
    publicProfile(userId: $id) {
      ...CoreUserProfileFields
    }
    myUpsideShares {
      summary {
        totalValue
        firstIssueDate
        totalShares
      }
    }
  }
`;

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'Menu'>;
export type MenuNavigationProps = NativeStackNavigationProp<LoggedInStackParamList, 'Menu'>;

export const Menu: React.FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const { email, nickname, userId, isStaff } = usePersistedStore(({ email, nickname, userId, isStaff }) => ({
    email,
    nickname,
    userId,
    isStaff,
  }));
  const toast = useToast();
  const { present, dismiss } = useBottomSheet();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { data, loading: userProfileLoading, error } = useGetUserQuery({ variables: { id: userId ?? 0 } });

  const upsideShares = upsideSharesSummary(data?.myUpsideShares ?? {});
  const version = ENVIRONMENT === 'prod' ? VERSION : COMMIT_HASH;

  if (error) {
    throw error;
  }

  const menuSections = useMemo(
    () =>
      [
        upsideShares && upsideShares.totalShares > 0 ? accountSection(navigation, upsideShares) : null,
        academySection(navigation),
        communitySection(),
        helpSection(navigation),
        otherSection(navigation),
        isStaff ? staffSection(navigation) : null,
      ].filter(isNotNull),
    [navigation, isStaff, upsideShares],
  );

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await logOut();
    } catch (e) {
      console.error(`Error logging out`, e);
      toast.show('Something went wrong, please try again.');
    } finally {
      setIsLoggingOut(false);
    }
  };

  const onPressImage = () => {
    present(<ProfileImageActions media={data?.publicProfile.media} dismiss={dismiss} navigation={navigation} />);
  };

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar />
      {userProfileLoading ? (
        <MenuSkeleton />
      ) : (
        <ScrollView style={tailwind('pt-4')}>
          <ScreenSidePadding style={tailwind('flex-1')}>
            <View>
              <Persona
                showImageIcon
                onImagePress={onPressImage}
                onUserProfilePress={() => {
                  navigation.push('UserProfile', { userId: Number(userId) });
                }}
                nickname={nickname}
                subText={email ?? ''}
                {...data?.publicProfile}
              />
            </View>

            {menuSections.map((menuSection) => (
              <MenuSection key={menuSection.title} {...menuSection} />
            ))}
            <View style={tailwind('pt-8')}>
              <Button
                StartIcon={Logout}
                text={isLoggingOut ? 'Logging you out...' : 'Log Out'}
                size="md"
                variant="secondary"
                onPress={handleLogout}
                loading={isLoggingOut}
                isDisabled={isLoggingOut}
              />
            </View>
            <View style={tailwind('pt-8 pb-8')}>
              <Text style={tailwind('text-xs text-warmGray-500 text-center')}>Version: {version?.trim() || 'N/A'}</Text>
              {ENVIRONMENT !== 'prod' && (
                <>
                  <Text style={tailwind('text-xs text-warmGray-500 text-center')}>
                    Runtime version: {RUNTIME_VERSION || 'N/A'}
                  </Text>

                  <Text style={tailwind('text-xs text-warmGray-500 text-center')}>
                    Release channel: {constants.manifest?.releaseChannel || 'N/A'}
                  </Text>
                </>
              )}
            </View>
          </ScreenSidePadding>
        </ScrollView>
      )}
    </SafeAreaView>
  );
});

const ProfileImageActions: VFC<{
  media?: Maybe<Partial<UserMedia>>;
  /**
   * Dismiss must be passed from parent component initializing bottom sheet. Using context handler inside the bottom sheet component doesn't work.
   */
  dismiss: () => void;
  /**
   * Navigation passed in for push handler availability. Navigation.push not available inside bottom sheet useNavigation hook.
   */
  navigation: Props['navigation'];
}> = ({ media, dismiss, navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const userId = usePersistedStore((state) => state.userId);
  const [profilePhotoProcessing, setProfilePhotoProcessing] = useState(false);
  const [deleteProfileImage, { loading: deleteProfileImageLoading }] = useDeleteProfileImageMutation({
    onCompleted: (data) => {
      const validationError = data.deleteProfileImage.error;
      if (validationError) {
        console.error(validationError.message);
        toast.show(getValidationMessage(validationError));
      } else {
        analytics.identify(`${userId}`, { hasProfileImage: false });
        toast.show('Your profile photo has been deleted.');
      }
      dismiss();
    },
    onError: (e) => {
      console.error(e);
      toast.show('Something went wrong, please try again.');
      dismiss();
    },
  });

  const onUploadImage = async () => {
    setProfilePhotoProcessing(true);
    const imageUri = await requestProfilePhoto();
    setProfilePhotoProcessing(false);
    if (imageUri) {
      navigation.push('UploadProfileImage', { imageUri });
      dismiss();
    }
  };

  const onDeleteProfileImage = () => {
    deleteProfileImage({ variables: { mediaId: media?.id ?? '' } });
  };

  return (
    <ScreenSidePadding>
      {!!media?.id && (
        <View style={tailwind('py-1')}>
          <Button
            onPress={() => {
              navigation.push('ViewProfileImage', { imageUri: media?.profileFull ?? '' });
              dismiss();
            }}
            isDisabled={deleteProfileImageLoading}
            size="md"
            variant="secondary"
            text="View"
          />
        </View>
      )}
      <View style={tailwind('py-1')}>
        <Button
          onPress={onUploadImage}
          isDisabled={deleteProfileImageLoading || profilePhotoProcessing}
          loading={profilePhotoProcessing}
          size="md"
          variant="secondary"
          text="Choose from library"
        />
      </View>
      {!!media?.id && (
        <View style={tailwind('py-1')}>
          <Button
            onPress={onDeleteProfileImage}
            loading={deleteProfileImageLoading}
            isDisabled={deleteProfileImageLoading}
            size="md"
            variant="secondary"
            text="Delete"
          />
        </View>
      )}
    </ScreenSidePadding>
  );
};

const MenuSection: React.FC<TMenuSection> = ({ title, menuItems }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('pt-8')}>
      <Text style={tailwind('text-lg text-warmGray-800 font-semibold')}>{title}</Text>
      {menuItems.map((menuItem) => (
        <MenuItemRow key={menuItem.label} {...menuItem} />
      ))}
    </View>
  );
};

const MenuItemRow: React.FC<MenuItem> = ({ Icon, label, onPress }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      accessibilityRole="button"
      style={tailwind('flex-row py-5 items-center border-warmGray-200 border-b')}
      onPress={onPress}
    >
      <Icon height={24} width={24} />
      <Text style={tailwind('flex-1 text-warmGray-800 pl-4 pr-3')}>{label}</Text>
      <ChevronRight color={colors.trueGray[400]} />
    </Pressable>
  );
};

const upsideSharesSummary = ({
  summary,
}: GetUserQuery['myUpsideShares']): UpsideSharesProps['route']['params'] | undefined => {
  const date = summary?.firstIssueDate;
  const firstIssueDate = date ? formatDate(date, 'YYYY-MM-DD') : undefined;
  const totalShares = summary?.totalShares;
  const totalValue = summary?.totalValue;
  if (firstIssueDate && isFiniteNumber(totalShares) && isFiniteNumber(totalValue)) {
    return {
      totalShares,
      totalValue,
      firstIssueDate,
    };
  }
  return undefined;
};

const MenuSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      <SkeletonView>
        <PersonaSkeleton />
        <View style={tailwind('py-4')} />
        <Skeleton />
      </SkeletonView>
    </ScreenSidePadding>
  );
};

const getValidationMessage = (validationError: DeleteProfileImageMutation['deleteProfileImage']['error']) => {
  switch (validationError?.__typename) {
    case 'FileNotFound':
      return 'We were unable to delete your profile photo, please try again.';
    default:
      return 'Something went wrong, please try again.';
  }
};
