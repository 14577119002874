import { ParamListBase } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import * as Linking from 'expo-linking';
import { openBrowserAsync } from 'expo-web-browser';
import QueryString from 'qs';
import { Platform } from 'react-native';
import { UNIVERSAL_LINK_DOMAIN } from '../constants/env';
import { ExternalURIs } from './externalURIs';
/**
 * Opens deep link if in-app URL, else browser modal for external link.
 *
 * @param link
 */
export const visitLink = (link: string) => {
  const url = new URL(link);
  if (Platform.OS !== 'web' && url.host === UNIVERSAL_LINK_DOMAIN) {
    // Replace scheme with App Scheme as universal links on iOS don't open in app when pressed from within the App.
    // https://github.com/facebook/react-native/issues/26584
    const appSchemeLink = Linking.createURL(url.pathname, {
      queryParams: QueryString.parse(url.search, { ignoreQueryPrefix: true }),
    });
    const trimmedAppSchemeLink = trimReleaseChannelParam(appSchemeLink);

    console.log(`Opening external link: ${trimmedAppSchemeLink}`);
    Linking.openURL(trimmedAppSchemeLink);
  } else {
    openBrowserAsync(link);
  }
};

/**
 * Trims the 'release-channel' query parameter added by Linking.createURL()
 * This is necessary for deep links to work correctly when opened in a release channel Expo Go app (dev/preview).
 * There is currently a bug with react-navigation where links with the release-channel query parameter end up with a
 * different prefix to what is expected and therefore doesn't match any of the paths we specify in the config.
 *
 * See https://github.com/react-navigation/react-navigation/issues/10379.
 */
const trimReleaseChannelParam = (appSchemeLink: string) => {
  const url = new URL(appSchemeLink);
  url.searchParams.delete('release-channel');
  return url.href;
};

/**
 * @deprecated use visitLink instead
 *
 * If native and the @navigation parameter is passed, it will use the webview screen.
 * If native and the @navigation is not passed, it will open a web-browser modal (good for news articles).
 * Otherwise opens the link in a new tab on mobile web.
 *
 * TODO: Once we have a static notion site, navigate to webview on web as well.
 * Condition exists because Notion doesn't work in an iframe due to them setting an X-Frame header.
 */
export function visitExternalLink<T>(
  uri: string,
  navigation?: T extends NativeStackNavigationProp<ParamListBase, string> ? T : never,
  options: {
    /** true if in a modal and ios modal doesnt close modals on navigate so would appear behind without replace */
    replaceNavigation?: boolean;
  } = {},
) {
  if (Platform.OS === 'web') {
    window.open(uri, '_blank');
  } else if (navigation) {
    const externalUri = uri as ExternalURIs[keyof ExternalURIs];
    if (options.replaceNavigation) {
      navigation.replace('WebviewScreen', { uri: externalUri });
    } else {
      navigation.navigate('WebviewScreen', { uri: externalUri });
    }
  } else {
    openBrowserAsync(uri);
  }
}
