import create from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import {
  CoreInstrumentFieldsFragment,
  InitialLayerIntentionInput,
  InitialPortfolioIntentionInput,
  Instrument,
  PrePortfolioStats,
} from '../generated/graphql';
import { storage } from './storage';

export type PersistedZustandStore = {
  isUserLoggedIn: boolean;
  userId?: number;
  // Persisted localStorage uuid for anonymous segment identification
  anonymousId?: string;
  nickname?: string;
  email?: string;
  isStaff?: boolean;
  /** TODO: remove charlieMode entirely */
  charlieMode?: boolean;
  /** true if in iframe from marketing site so grab email not sign up at end of onboarding journey */
  isMarketing?: boolean;
};

export const usePersistedStore = create<PersistedZustandStore>(
  devtools<PersistedZustandStore>(
    persist<PersistedZustandStore>(
      () => ({
        isUserLoggedIn: false,
      }),
      { name: 'main-store', getStorage: () => storage },
    ),
  ),
);

/**
 * Intended Portfolio used in onboarding. To be cleared once a user Creates their trading account.
 */
export type PortfolioIntendedPersistedStore = Pick<InitialPortfolioIntentionInput, 'baseLayer' | 'stackLayer'> & {
  riskLevel?: 'low' | 'medium' | 'high';
  stockLayer: InitialPortfolioIntentionInput['stockLayer'] & { constituents: CoreInstrumentFieldsFragment[] };
  insights?: Omit<PrePortfolioStats, 'historicalPrices'> & {
    flattenedPortfolioComponents: { instrument: Pick<Instrument, 'id' | 'displayName' | 'ticker'>; weight: number }[];
  };
} & {
  /**
   * Set base constituentIds
   */
  setBase: (constituentIds: InitialLayerIntentionInput['constituentIds']) => void;
  /**
   * Set stack/bundle constituentIds
   */
  setStacks: (constituentIds: InitialLayerIntentionInput['constituentIds']) => void;
  /**
   * Full instrument reference needed for stocks.
   * This is because users can search stocks so they may not be in the cache after refreshing the browser.
   */
  setStocks: (constituents: CoreInstrumentFieldsFragment[]) => void;
  // Set weightings
  setWeights: (weights: { base: number; stack: number; stock: number }) => void;
  /**
   * Set portfolio insights
   */
  setInsights: (
    insights: Omit<PrePortfolioStats, 'historicalPrices'>,
    flattenedPortfolioComponents: { instrument: Pick<Instrument, 'id' | 'displayName' | 'ticker'>; weight: number }[],
  ) => void;
  /**
   * Function to check whether a user has completed their portfolio intended
   */
  hasPortfolioIntended: () => boolean;
};

export const usePortfolioIntendedPersistedStore = create<PortfolioIntendedPersistedStore>(
  devtools<PortfolioIntendedPersistedStore>(
    persist<PortfolioIntendedPersistedStore>(
      (set, get) => ({
        baseLayer: { weight: 0, constituentIds: [] },
        stackLayer: { weight: 0, constituentIds: [] },
        stockLayer: { weight: 0, constituents: [], constituentIds: [] },
        setBase: (constituentIds: string[]) =>
          set((state) => ({ ...state, baseLayer: { ...state.baseLayer, constituentIds } })),
        setStacks: (constituentIds: string[]) =>
          set((state) => ({ ...state, stackLayer: { ...state.stackLayer, constituentIds } })),
        setStocks: (constituents: CoreInstrumentFieldsFragment[]) =>
          set((state) => ({
            ...state,
            stockLayer: { ...state.stockLayer, constituents, constituentIds: constituents.map((i) => i.id) },
          })),
        setWeights: (weights: { base: number; stack: number; stock: number }) =>
          set((state) => ({
            ...state,
            baseLayer: { ...state.baseLayer, weight: weights.base },
            stackLayer: { ...state.stackLayer, weight: weights.stack },
            stockLayer: { ...state.stockLayer, weight: weights.stock },
          })),
        setInsights: (insights, flattenedPortfolioComponents) =>
          set((state) => ({ ...state, insights: { ...insights, flattenedPortfolioComponents } })),
        /**
         * Function to check whether a user has completed their portfolio intended
         */
        hasPortfolioIntended: () => {
          const state = get();
          const hasConstituents =
            !!state.baseLayer.constituentIds.length &&
            !!state.stackLayer.constituentIds.length &&
            !!state.stockLayer.constituentIds.length;
          const hasWeights = state.baseLayer.weight > 0 && state.stackLayer.weight > 0 && state.stockLayer.weight > 0;
          return hasConstituents && hasWeights;
        },
      }),
      { name: 'portfolio-intended-store', getStorage: () => storage },
    ),
  ),
);

export function hasPortfolioIntended(
  portfolio: Pick<PortfolioIntendedPersistedStore, 'baseLayer' | 'stackLayer' | 'stockLayer'>,
) {
  const hasConstituents =
    !!portfolio.baseLayer.constituentIds.length &&
    !!portfolio.stackLayer.constituentIds.length &&
    !!portfolio.stockLayer.constituentIds.length;
  const hasWeights =
    portfolio.baseLayer.weight > 0 && portfolio.stackLayer.weight > 0 && portfolio.stockLayer.weight > 0;
  return hasConstituents && hasWeights;
}

/** Non-persisted store */
export type ZustandStore = {
  /** store utm params arrived with whilst logged out to send as analytics identify when signed up */
  installUtmSource?: string;
  /** store utm params arrived with whilst logged out to send as analytics identify when signed up */
  installUtmMedium?: string;
  /** store utm params arrived with whilst logged out to send as analytics identify when signed up */
  installUtmCampaign?: string;
  /** listen to network state and update this */
  isConnected?: boolean | null;
  /** set to true in storybook decorator component (eg AppWithScreen) and use to skip app bootstrap bits like prime cache */
  isStorybook?: boolean;
};

export const useStore = create<ZustandStore>(devtools<ZustandStore>(() => ({})));

export type TutorialCardPersistedStore = {
  feedTutorialDismissed: boolean;
  discoverTutorialDismissed: boolean;
  watchlistTutorialDismissed: boolean;
  ideasTutorialDismissed: boolean;
  instrumentScreenTutorialDismissed: boolean;
  createIdeaTutorialDismissed: boolean;
  myIdeaTutorialDismissed: boolean;
  otherUserIdeaTutorialDismissed: boolean;
};

export const useTutorialCardPersistedStore = create<TutorialCardPersistedStore>(
  devtools<TutorialCardPersistedStore>(
    persist<TutorialCardPersistedStore>(
      () => ({
        feedTutorialDismissed: false,
        discoverTutorialDismissed: false,
        watchlistTutorialDismissed: false,
        instrumentScreenTutorialDismissed: false,
        ideasTutorialDismissed: false,
        createIdeaTutorialDismissed: false,
        myIdeaTutorialDismissed: false,
        otherUserIdeaTutorialDismissed: false,
      }),
      { name: 'tutorial-card-store', getStorage: () => storage },
    ),
  ),
);
