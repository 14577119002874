import { MotiPressableProps } from 'moti/interactions';
import React from 'react';
import { Pressable } from '../../ui/Pressable';

type Props = {
  icon: React.ReactNode;
  redirectIfLoggedOut?: boolean;
  accessibilityLabel: string;
} & Omit<MotiPressableProps, 'accessibilityLabel'>;

export const IconButton: React.FC<Props> = ({ icon, onPress, accessibilityLabel, ...props }) => {
  // Note: IconButton track event done in Pressable based on imagebutton accessibilityRole
  return (
    <Pressable accessibilityLabel={accessibilityLabel} accessibilityRole="imagebutton" onPress={onPress} {...props}>
      {icon}
    </Pressable>
  );
};
