import React from 'react';
import { View } from 'react-native';
import { SafeAreaView as NativeSafeAreaView, NativeSafeAreaViewProps } from 'react-native-safe-area-context';
import { useTailwind } from '../../theme';

type Props = NativeSafeAreaViewProps & { fullWidth?: boolean };
export const DESKTOP_MAX_WIDTH = 800;

/**
 * SafeAreaView with correct background (no padding)
 *
 * @param root0
 * @param root0.children
 * @param root0.style
 */
export const SafeAreaView: React.FC<Props> = ({ children, style, fullWidth, ...props }) => {
  const tailwind = useTailwind();
  return (
    <NativeSafeAreaView {...props} style={[tailwind('flex-1 items-center bg-white'), style]}>
      <View style={[tailwind('flex-1'), { maxWidth: fullWidth ? undefined : DESKTOP_MAX_WIDTH, width: '100%' }]}>
        {children}
      </View>
    </NativeSafeAreaView>
  );
};
