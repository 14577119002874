import { StyleSheet } from 'react-native';
import colors from './colors';

/**
 * our shadows
 * Usage: `<View style={[shadowMd, tailwind('rounded-lg px-3'),...]}>`
 */
export const { shadowSm, shadowMd, shadowL, shadowXl, shadow2xl, shadow3xl, shadowUpper } = StyleSheet.create({
  shadowSm: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 4,
    elevation: 4,
  },
  shadowMd: {
    shadowColor: colors.warmGray[900],
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.16,
    shadowRadius: 4,
    elevation: 2.5,
    backgroundColor: 'white',
  },
  shadowL: {
    shadowColor: colors.warmGray[900],
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.16,
    shadowRadius: 8,
    elevation: 3,
    backgroundColor: 'white',
  },
  shadowXl: {
    shadowColor: colors.warmGray[900],
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.16,
    shadowRadius: 16,
    elevation: 3.5,
    backgroundColor: 'white',
  },
  shadow2xl: {
    shadowColor: colors.warmGray[900],
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.16,
    shadowRadius: 24,
    elevation: 4,
    backgroundColor: 'white',
  },
  shadow3xl: {
    shadowColor: colors.warmGray[900],
    shadowOffset: {
      width: 0,
      height: 24,
    },
    shadowOpacity: 0.2,
    shadowRadius: 48,
    elevation: 4.5,
    backgroundColor: 'white',
  },
  shadowUpper: {
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: -4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6,
    elevation: 4,
  },
});
