/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PortfolioStats } from '../models/PortfolioStats';
import type { PrePortfolio } from '../models/PrePortfolio';
import type { PrePortfolioSimulation } from '../models/PrePortfolioSimulation';
import type { PrePortfolioSimulationCreate } from '../models/PrePortfolioSimulationCreate';
import { request as __request } from '../core/request';

export class PrePortfolioService {

    /**
     * @returns PrePortfolioSimulation
     * @throws ApiError
     */
    public static async apiPrePortfolioSimulationList({
        prePortfolioIds,
    }: {
        /** Multiple values may be separated by commas. **/
        prePortfolioIds?: string,
    }): Promise<Array<PrePortfolioSimulation>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pre-portfolio-simulation/`,
            query: {
                'pre_portfolio_ids': prePortfolioIds,
            },
        });
        return result.body;
    }

    /**
     * @returns PrePortfolioSimulationCreate
     * @throws ApiError
     */
    public static async apiPrePortfolioSimulationCreate({
        data,
    }: {
        data: PrePortfolioSimulationCreate,
    }): Promise<PrePortfolioSimulationCreate> {
        const result = await __request({
            method: 'POST',
            path: `/api/pre-portfolio-simulation/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns PrePortfolioSimulation
     * @throws ApiError
     */
    public static async apiPrePortfolioSimulationRead({
        prePortfolio,
    }: {
        prePortfolio: string,
    }): Promise<PrePortfolioSimulation> {
        const result = await __request({
            method: 'GET',
            path: `/api/pre-portfolio-simulation/${prePortfolio}/`,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiPrePortfolioSimulationDelete({
        prePortfolio,
    }: {
        prePortfolio: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/pre-portfolio-simulation/${prePortfolio}/`,
        });
        return result.body;
    }

    /**
     * @returns PortfolioStats
     * @throws ApiError
     */
    public static async apiPrePortfolioSimulationStatsRead({
        uuid,
        endDate,
        startDate,
    }: {
        /** A UUID string identifying this pre portfolio simulation. **/
        uuid: string,
        endDate?: string,
        startDate?: string,
    }): Promise<PortfolioStats> {
        const result = await __request({
            method: 'GET',
            path: `/api/pre-portfolio-simulation/${uuid}/stats/`,
            query: {
                'end_date': endDate,
                'start_date': startDate,
            },
        });
        return result.body;
    }

    /**
     * @returns PrePortfolio
     * @throws ApiError
     */
    public static async apiPrePortfolioList({
        isActive,
    }: {
        isActive?: string,
    }): Promise<Array<PrePortfolio>> {
        const result = await __request({
            method: 'GET',
            path: `/api/pre-portfolio/`,
            query: {
                'is_active': isActive,
            },
        });
        return result.body;
    }

    /**
     * @returns PrePortfolio
     * @throws ApiError
     */
    public static async apiPrePortfolioCreate({
        data,
    }: {
        data: PrePortfolio,
    }): Promise<PrePortfolio> {
        const result = await __request({
            method: 'POST',
            path: `/api/pre-portfolio/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns PrePortfolio
     * @throws ApiError
     */
    public static async apiPrePortfolioRead({
        uuid,
    }: {
        /** A UUID string identifying this pre portfolio. **/
        uuid: string,
    }): Promise<PrePortfolio> {
        const result = await __request({
            method: 'GET',
            path: `/api/pre-portfolio/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns PrePortfolio
     * @throws ApiError
     */
    public static async apiPrePortfolioPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this pre portfolio. **/
        uuid: string,
        data: PrePortfolio,
    }): Promise<PrePortfolio> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/pre-portfolio/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiPrePortfolioDelete({
        uuid,
    }: {
        /** A UUID string identifying this pre portfolio. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/pre-portfolio/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns PortfolioStats
     * @throws ApiError
     */
    public static async apiPrePortfolioStatsRead({
        uuid,
        endDate,
        startDate,
    }: {
        /** A UUID string identifying this pre portfolio. **/
        uuid: string,
        endDate?: string,
        startDate?: string,
    }): Promise<PortfolioStats> {
        const result = await __request({
            method: 'GET',
            path: `/api/pre-portfolio/${uuid}/stats/`,
            query: {
                'end_date': endDate,
                'start_date': startDate,
            },
        });
        return result.body;
    }

}
