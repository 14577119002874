import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Ideas } from '../screens/Ideas';
import { LoggedInStackParamList } from './RootStackNavigator';
import { TabsParamList } from './TabsNavigator';

export type IdeasStackParamList = {
  Ideas: { initialSelectedIndex: number } | undefined;
};

const Stack = createNativeStackNavigator<IdeasStackParamList>();

type Props = CompositeScreenProps<
  BottomTabScreenProps<TabsParamList, 'IdeasTab'>,
  NativeStackScreenProps<LoggedInStackParamList>
>;

/**
 * Ideas Stack
 */
export const IdeasNavigator: React.FC<Props> = () => {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Ideas" component={Ideas} />
    </Stack.Navigator>
  );
};
