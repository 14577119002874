import { FC } from 'react';
import { View } from 'react-native';
import { CheckIcon } from 'react-native-heroicons/outline';
import { CoreInstrumentFieldsFragment } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { StockCard } from '../../ui/StockCard';
import { useStockSelection } from './useStockSelection';

type Props = {
  stocks?: CoreInstrumentFieldsFragment[];
};

export const StockList: FC<Props> = ({ stocks }) => {
  const tailwind = useTailwind();
  const { selectedStocks, addInstrument, removeInstrument } = useStockSelection();

  if (!stocks || !stocks.length) {
    console.error(`Empty stocks list in StockList`);
  }

  return (
    <View style={tailwind('flex-row flex-wrap')}>
      {stocks?.map((instrument) => {
        const selected = selectedStocks.findIndex(({ id }) => id === instrument.id) >= 0;
        return (
          <View key={instrument.id} style={tailwind('basis-1/3 items-center px-0.5 py-1')}>
            <StockCard
              key={instrument.id}
              instrument={instrument}
              selected={selected}
              onPress={() => {
                if (selected) {
                  removeInstrument(instrument.id);
                } else {
                  addInstrument(instrument.id);
                }
              }}
              icon={CheckIcon}
            />
          </View>
        );
      })}
    </View>
  );
};
