import { noop } from 'lodash';
import { ViewStyle } from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { formatPrice } from '../../../util/number';
import { Ticks } from '../ticks';

type LabelProps = {
  onLayout?: (width: number) => void;
  transition: Animated.SharedValue<number>;
  value: number;
  currencyCode?: string;
  ticks: Ticks | undefined;
  prevTicks: Ticks | undefined;
  height: Animated.SharedValue<number>;
};

export const Label: React.VFC<LabelProps> = ({
  onLayout = noop,
  value,
  height,
  ticks,
  transition,
  prevTicks,
  currencyCode,
}) => {
  const tailwind = useTailwind();

  // The target is the height proportion of the tick relative to the target tick range
  const target = ticks ? 1 - (value - ticks.low) / (ticks.high - ticks.low) : 0;

  // The source is the height proportion of the tick relative to the source tick range
  const source = prevTicks ? 1 - (value - prevTicks.low) / (prevTicks.high - prevTicks.low) : target;

  // This animates the height of the label smoothly between the source and target tick ranges
  const animatedStyles = useAnimatedStyle<ViewStyle>(() => ({
    top: height.value * (transition.value * target + (1 - transition.value) * source) - 8,
  }));

  return (
    <Animated.View
      style={[{ position: 'absolute' }, animatedStyles]}
      onLayout={(e) => onLayout(e.nativeEvent.layout.width)}
    >
      <Text style={tailwind('text-xs text-warmGray-500 text-center px-2')}>
        {formatPrice(value, currencyCode, { optionalMantissa: true, mantissa: 2 })}
      </Text>
    </Animated.View>
  );
};
