import { FC, useState } from 'react';
import { TextStyle, View } from 'react-native';
import { runOnJS, SharedValue, useAnimatedReaction, useAnimatedStyle, useDerivedValue } from 'react-native-reanimated';
import { useBottomSheet } from '../../../hooks/useBottomSheet';
import { useTailwind } from '../../../theme';
import { Divider, LayerBottomSheet, WeightingSegment } from '../../../ui/PortfolioWeights';
import { Text } from '../../../ui/Text';
import { formatPercent } from '../../../util/number';

type Props = {
  /**
   * Shared values between 0 and 1
   */
  animatedWeights: { base: SharedValue<number>; bundles: SharedValue<number>; stocks: SharedValue<number> };
};

export const AnimatedPortfolioWeights: FC<Props> = ({ animatedWeights }) => {
  const tailwind = useTailwind();
  const { present, dismiss } = useBottomSheet();

  const baseViewStyle = useAnimatedStyle(() => {
    const animatedWeight = animatedWeights.base.value;
    return {
      flexGrow: animatedWeight,
      flexShrink: animatedWeight,
    };
  });
  const bundlesViewStyle = useAnimatedStyle(() => {
    const animatedWeight = animatedWeights.bundles.value;
    return {
      flexGrow: animatedWeight,
      flexShrink: animatedWeight,
    };
  });
  const stocksViewStyle = useAnimatedStyle(() => {
    const animatedWeight = animatedWeights.stocks.value;
    return {
      flexGrow: animatedWeight,
      flexShrink: animatedWeight,
    };
  });

  return (
    <View style={tailwind('flex-row')}>
      <WeightingSegment
        title="Base"
        onPress={() => present(<LayerBottomSheet variant="Base" onPressFinish={dismiss} />)}
        baseColor="primary"
        padding="right"
        animatedStyle={baseViewStyle}
      >
        <AnimatedWeightingText
          style={tailwind('text-lg text-primary-dark font-bold')}
          weighting={animatedWeights.base}
        />
      </WeightingSegment>
      <Divider />
      <WeightingSegment
        title="Bundles"
        onPress={() => present(<LayerBottomSheet variant="Bundles" onPressFinish={dismiss} />)}
        baseColor="secondary"
        padding="both"
        animatedStyle={bundlesViewStyle}
      >
        <AnimatedWeightingText
          style={tailwind('text-lg text-secondary-dark font-bold')}
          weighting={animatedWeights.bundles}
        />
      </WeightingSegment>
      <Divider />
      <WeightingSegment
        title="Stocks"
        onPress={() => present(<LayerBottomSheet variant="Stocks" onPressFinish={dismiss} />)}
        baseColor="tertiary"
        padding="left"
        animatedStyle={stocksViewStyle}
      >
        <AnimatedWeightingText
          style={tailwind('text-lg text-tertiary-dark font-bold')}
          weighting={animatedWeights.stocks}
        />
      </WeightingSegment>
    </View>
  );
};

const AnimatedWeightingText: FC<{
  weighting: SharedValue<number>;
  style: TextStyle;
}> = ({ weighting, style }) => {
  const tailwind = useTailwind();
  const [weight, setWeight] = useState(weighting.value);
  const animatedWeight = useDerivedValue(() => {
    return weighting.value;
  });
  useAnimatedReaction(
    () => {
      return animatedWeight.value;
    },
    (result) => {
      runOnJS(setWeight)(result);
    },
    [setWeight],
  );
  // Set fixed width minimize to minimize layout jank
  return <Text style={[style, tailwind('w-12')]}>{formatPercent(weight, 0, false)}</Text>;
};
