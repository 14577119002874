import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import * as Yup from 'yup';
import type { SchemaOf } from 'yup';
import { useSignUpMutation } from '../../generated/graphql';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { LoggedOutStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { CheckBox } from '../../old/CheckBox';
import { PasswordInput, TextInput } from '../../old/inputs';
import { Link } from '../../old/Link';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { analytics } from '../../services/analytics';
import { EXTERNAL_URIs } from '../../services/externalURIs';
import { setLoginState } from '../../services/logIn';
import { visitExternalLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { useStore } from '../../zustand/store';
import { Header } from './Header';

export type Props = NativeStackScreenProps<LoggedOutStackParamList, 'SignUp'>;

type SignUpFormSchema = {
  username: string;
  email: string;
  password: string;
  subscribe: boolean;
};

const signUpFormSchema: SchemaOf<SignUpFormSchema> = Yup.object().shape({
  username: Yup.string()
    .matches(/^[a-zA-Z\d\_\-]*$/, {
      message: 'Username must only contain letters, numbers, underscore (_) and dash (-) characters.',
    })
    .trim()
    .required('Username required'),
  email: Yup.string().email('Must be a valid email address').trim().required('Email required'),
  password: Yup.string()
    .min(8, 'Min 8 characters')
    .matches(/\D+/, 'Must contain at least one letter')
    .required('Password required'),
  subscribe: Yup.bool().required(),
});

const initialValues: SignUpFormSchema = {
  email: '',
  username: '',
  password: '',
  subscribe: false,
};

export const SIGN_UP = gql`
  mutation SignUp(
    $email: NonEmptyString!
    $password: NonEmptyString!
    $nickname: NonEmptyString!
    $mailingList: Boolean!
    $web: Boolean!
  ) {
    signup(email: $email, password: $password, nickname: $nickname, mailingList: $mailingList) {
      accessToken
      refreshToken @skip(if: $web)
      error {
        ... on EmailInvalid {
          message
        }
        ... on NicknameInvalid {
          message
        }
        ... on PasswordInvalid {
          message
        }
      }
      user {
        id
        email
        isStaff
        profile {
          id
          nickname
          profileRating
        }
      }
    }
  }
`;

export const SignUp: React.FC<Props> = ({ route }) => {
  const { external, hideBackButton } = route?.params ?? {};
  const tailwind = useTailwind();

  // will need to use logged in navigation too once signed up
  const navigation = useNavigation<AllRoutesStackNavigationProps>();

  const [globalError, setGlobalError] = useState('');

  const [signUp] = useSignUpMutation();

  const onSubmit = async (values: SignUpFormSchema, helpers: FormikHelpers<SignUpFormSchema>) => {
    setGlobalError(''); // Reset error to empty
    const response = await signUp({
      variables: {
        email: values.email,
        password: values.password,
        nickname: values.username,
        mailingList: values.subscribe,
        web: Platform.OS === 'web',
      },
    });
    const error = response.data?.signup.error;
    if (error) {
      console.log(`Sign up error, showing user error message for error typename ${error.__typename}`);
      switch (error.__typename) {
        case 'EmailInvalid':
          helpers.setFieldError('email', error.message);
          return;
        case 'NicknameInvalid':
          helpers.setFieldError('username', error.message);
          return;
        case 'PasswordInvalid':
          helpers.setFieldError('password', error.message);
          return;
        default:
          console.error(error);
          setGlobalError('An unknown error occurred');
          return;
      }
    }

    try {
      const signupData = response.data?.signup;
      const userId = `${signupData?.user?.id ?? ''}`;

      console.log(`Sign up success, identifying userId ${userId} for analytics`);
      // grab install utm params now if have been in store
      const { installUtmCampaign, installUtmMedium, installUtmSource } = useStore.getState();

      // this identify call will merge anon user into user in mixpanel
      // identify also already call on server just before this for nickname, email etc
      // so no overlap of properties
      analytics.identify(userId, {
        installUtmCampaign,
        installUtmMedium,
        installUtmSource,
      });

      await setLoginState({
        accessToken: signupData?.accessToken,
        refreshToken: signupData?.refreshToken,
        user: signupData?.user,
        profile: signupData?.user?.profile,
      });

      // now ask them if they want user profile image
      navigation.navigate('UploadProfileImageSignup', { nickname: values.username });
    } catch (e) {
      console.error(error);
      setGlobalError('An unknown error occurred');
    }
  };

  return (
    <SafeAreaView>
      <TitleBar title={external ? '' : 'Sign Up'} hideBackButton={hideBackButton} />

      <ScrollView>
        <ScreenSidePadding style={tailwind('flex-1')}>
          {external && <Header />}
          <Formik
            initialValues={initialValues}
            validationSchema={signUpFormSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({ values, errors, handleSubmit, handleChange, setFieldValue, isSubmitting }) => (
              <View style={tailwind('flex-grow')}>
                <View style={tailwind('py-2')}>
                  <TextInput
                    accessibilityLabel="Email"
                    style={tailwind('py-2')}
                    onChangeText={handleChange('email')}
                    placeholder="Email"
                    value={values.email}
                    keyboardType="email-address"
                    errorMessage={errors.email}
                    autoCapitalize="none"
                    textContentType="emailAddress"
                    autoComplete="email"
                  />
                </View>
                <View style={tailwind('py-2')}>
                  <PasswordInput
                    onChangeText={handleChange('password')}
                    placeholder="Password (8+ characters)"
                    value={values.password}
                    errorMessage={errors.password}
                    textContentType="newPassword"
                    autoComplete="password"
                    passwordRules="minlength: 8"
                  />
                </View>
                <View style={tailwind('py-2')}>
                  <TextInput
                    onChangeText={handleChange('username')}
                    accessibilityLabel="Username"
                    placeholder="Choose a public username"
                    value={values.username}
                    errorMessage={errors.username}
                    autoCapitalize="none"
                    textContentType="username"
                    autoComplete="username"
                  />
                </View>
                <View style={tailwind('py-2')}>
                  <CheckBox
                    onValueChange={(value) => setFieldValue('subscribe', value)}
                    value={values.subscribe}
                    labelOnRight="Join our mailing list. No spam just news, updates and insights"
                  />
                </View>
                <View style={tailwind('py-2')}>
                  <Text style={tailwind('text-red-500 pb-2 text-center')}>{globalError}</Text>
                  <Button
                    text={isSubmitting ? 'Signing you up...' : 'Sign up'}
                    size="sm"
                    loading={isSubmitting}
                    isDisabled={isSubmitting}
                    onPress={() => handleSubmit()}
                  />
                </View>
              </View>
            )}
          </Formik>
          {external && (
            <Text style={tailwind('text-center text-warmGray-500 text-base font-medium pt-2 pb-10')}>
              Already have an account? <Link onPress={() => navigation.navigate('LogIn')}>Log in</Link>
            </Text>
          )}
          <View style={tailwind('items-center justify-center')}>
            <Text style={tailwind('text-warmGray-400 text-center')}>
              By creating an account, you accept Upside&apos;s
            </Text>
            <View style={tailwind('items-center')}>
              <View style={tailwind('flex-row items-center')}>
                <Text style={tailwind('text-warmGray-400')}>
                  <Link onPress={() => visitExternalLink(EXTERNAL_URIs.TERMS_AND_CONDITIONS, navigation)}>
                    Terms & conditions
                  </Link>{' '}
                  and{' '}
                  <Link onPress={() => visitExternalLink(EXTERNAL_URIs.PRIVACY_POLICY, navigation)}>
                    Privacy policy
                  </Link>
                </Text>
              </View>
            </View>
          </View>
        </ScreenSidePadding>
      </ScrollView>
    </SafeAreaView>
  );
};
