import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TitleBar } from '../../old/TitleBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { WebView } from '../../ui/WebView';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'WebviewScreen'>;

export const WebviewScreen: React.FC<Props> = ({
  route: {
    params: { uri },
  },
}) => {
  return (
    <SafeAreaView>
      <TitleBar title="" />

      {/* If the link is opening in the device's browser instead, check EXTERNAL_ORIGIN_WHITELIST. The origin is probably not included */}
      <WebView uri={uri} />
    </SafeAreaView>
  );
};
