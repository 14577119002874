import currencyToSymbolMap from 'currency-symbol-map';
import React, { useState } from 'react';
import { View } from 'react-native';
import { Position } from '../../../generated/graphql';
import { ChooseAmountInput, CUSTOM_INPUT, CustomInput } from '../../../old/inputs';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { formatPercent, formatPrice, getPercentageDiff } from '../../../util/number';
import { isFiniteNumber } from '../../../util/typeGuards';

type Props = {
  position: Position;
  currentPrice: number;
  currency: string;
  target: number | null;
  /** Null returned when input is cleared or empty */
  onChangeTarget: (target: number | null) => void;
  errorMsg?: string;
};

export const PriceTarget: React.VFC<Props> = ({
  onChangeTarget,
  currentPrice,
  position,
  currency,
  target,
  errorMsg,
}) => {
  const tailwind = useTailwind();
  const [selected, setSelected] = useState<number | CustomInput>();

  const chipOptions = options(currentPrice)[position];
  return (
    <View style={tailwind('my-4')}>
      <ChooseAmountInput
        value={target}
        onChange={onChangeTarget}
        getChipText={(v) => formatPercent(getPercentageDiff(currentPrice, v), 0)}
        numColumns={4}
        options={chipOptions}
        inputOptions={{
          startAdornment: currencyToSymbolMap(currency),
          endAdornment: target ? formatPercent(getPercentageDiff(currentPrice, target)) : undefined,
        }}
        errorMsg={errorMsg}
        onChipSelect={(v) => setSelected(v)}
      />
      {selected !== CUSTOM_INPUT && (
        <Text style={tailwind('text-warmGray-800 text-3xl font-normal text-center')}>
          {!isFiniteNumber(target) ? `${currencyToSymbolMap(currency)} --.--` : formatPrice(target, currency)}
        </Text>
      )}
    </View>
  );
};

const options = (currentPrice: number) => ({
  [Position.Long]: [1.05, 1.1, 1.15, 1.25, 1.5, 1.75, 2.0].map((m) => currentPrice * m),
  [Position.Short]: [0.05, 0.1, 0.15, 0.25, 0.5, 0.75, 0.9].map((m) => currentPrice - currentPrice * m),
});
