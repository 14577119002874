import { PressableProps } from 'react-native';
import { Pressable } from '../Pressable';

type Props = {
  icon: React.ReactNode;
  accessibilityLabel: string;
  onPress: () => void;
} & Omit<PressableProps, 'accessibilityLabel' | 'onPress'>;

export const IconButton: React.FC<Props> = ({ icon, onPress, accessibilityLabel, ...props }) => {
  // Note: IconButton track event done in Pressable based on imagebutton accessibilityRole
  return (
    <Pressable
      hitSlop={8}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="imagebutton"
      onPress={onPress}
      {...props}
    >
      {icon}
    </Pressable>
  );
};
