import React from 'react';
import { View } from 'react-native';
import { CoreInstrumentFieldsFragment, SignificantDailyMoverFeedItem } from '../../../generated/graphql';
import { shadowMd, useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { getPercentageTextColor } from '../../../util/color';
import { formatDate } from '../../../util/date';
import { formatNumber, formatPercent, formatPrice } from '../../../util/number';
import { InstrumentLogo } from '../../InstrumentLogo';
import { InstrumentNavigationWrapper } from '../../InstrumentNavigationWrapper';

export type Props = CoreInstrumentFieldsFragment &
  Pick<SignificantDailyMoverFeedItem, 'zScore' | 'latestReturn' | 'date'>;

/**
 * Instrument, ticker, logo and price
 *
 * @param root0
 * @param root0.name
 * @param root0.ticker
 * @param root0.currency
 * @param root0.quotePrice
 * @param root0.closePrice
 * @param root0.latestReturn
 * @param root0.zScore
 */

export const SignificantMoverCard: React.FC<Props> = ({
  name,
  ticker,
  currency,
  quotePrice,
  closePrice,
  logoUrl,
  latestReturn,
  zScore,
  date,
  id,
}) => {
  const tailwind = useTailwind();
  let formattedPrice = '';
  let formattedPercentChange = '';
  const dailyPercentageChange = quotePrice?.pctChange1d;
  const percentTextColor = getPercentageTextColor(dailyPercentageChange);
  const returnTextColor = getPercentageTextColor(latestReturn);
  if ((closePrice?.price ?? quotePrice?.midPrice) && currency?.iso) {
    formattedPrice = formatPrice(quotePrice?.midPrice ?? closePrice?.price ?? 0, currency.iso);
    formattedPercentChange = dailyPercentageChange ? formatPercent(dailyPercentageChange) : '';
  }

  return (
    <InstrumentNavigationWrapper instrumentId={id}>
      <View style={[shadowMd, tailwind('rounded-lg px-3'), { width: 152 }]}>
        <View style={tailwind('mt-3')}>
          <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
        </View>
        <Text style={tailwind('text-sm font-medium text-warmGray-800 pt-2')} numberOfLines={1}>
          {name}
        </Text>
        <Text style={tailwind('text-xs text-warmGray-500')} numberOfLines={1}>
          {ticker}
        </Text>
        <View style={tailwind('flex-row pt-1 items-end pb-3')}>
          <View style={tailwind('pr-1')}>
            <Text style={tailwind('text-warmGray-800')} numberOfLines={1}>
              {formattedPrice}
            </Text>
          </View>
          <View>
            <Text style={tailwind(`text-xs ${percentTextColor}`)} numberOfLines={1}>
              {formattedPercentChange}
            </Text>
          </View>
        </View>
        <View style={tailwind('flex-row bg-warmGray-100 rounded-lg justify-between items-center p-2')}>
          <Text style={tailwind('text-xs text-warmGray-500')}>{formatDate(new Date(date ?? ''), 'D MMM')} </Text>
          <Text style={tailwind(`text-sm ${returnTextColor}`)}>{formatPercent(latestReturn ?? 0)}</Text>
        </View>
        {/* Spacer */}
        <View style={tailwind('py-1')} />

        <View style={tailwind('flex-row  justify-between items-center p-2')}>
          <Text style={tailwind('text-xs text-warmGray-500')}>z-score</Text>
          <Text style={tailwind('text-warmGray-800 text-sm')}>{formatNumber(zScore ?? 0)}</Text>
        </View>
        {/* Spacer */}
        <View style={tailwind('py-1')} />
      </View>
    </InstrumentNavigationWrapper>
  );
};
