// @ts-check
// TODO: Make this TypeScript one day
module.exports = {
  /** fontFamily strings */
  fontFamily: {
    regular: 'Poppins-Regular',
    medium: 'Poppins-Medium',
    semibold: 'Poppins-SemiBold',
    bold: 'Poppins-Bold',
    mono: 'IBMPlexMono-Regular',
  },
};
