import React from 'react';
import { View } from 'react-native';
import { Idea, Instrument, UserProfile } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { InstrumentLogo } from '../InstrumentLogo';
import { IdeaPositionBox } from './IdeaPositionBox';

export type Props = Pick<Idea, 'position'> &
  Pick<Instrument, 'name' | 'ticker' | 'logoUrl'> &
  Pick<UserProfile, 'nickname'>;

export const IdeaInstrumentSnapshot: React.FC<Props> = ({ position, name, ticker, nickname, logoUrl }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('py-3 px-3 flex-row')}>
      <View style={tailwind('mr-2')}>
        <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
      </View>
      <View style={tailwind('flex-1')}>
        <Text style={tailwind('text-sm font-medium text-warmGray-800')} ellipsizeMode="tail" numberOfLines={1}>
          {`${name ?? ''} (${ticker ?? ''})`}
        </Text>
        <Text style={tailwind('text-xs text-warmGray-400')}>{nickname}</Text>
      </View>
      <View>
        <IdeaPositionBox position={position} style={tailwind('h-8 w-24')} />
      </View>
    </View>
  );
};
