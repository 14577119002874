import React from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { InstrumentSquareCard } from '../../../old/discoverCards/InstrumentSquareCard/InstrumentSquareCard';
import { SectorCard } from '../../../old/discoverCards/SectorCard';
import { SignificantMoverCard } from '../../../old/discoverCards/SignificantMoverCard';
import { InstrumentNavigationWrapper } from '../../../old/InstrumentNavigationWrapper';
import { useTailwind } from '../../../theme';
import { DiscoverCategory, DiscoverFeedItem } from '../Discover';

type Props = {
  items: DiscoverCategory['items'];
  accessibilityLabel: string;
};

export const DiscoverSideScroll: React.FC<Props> = ({ items, accessibilityLabel }) => {
  const tailwind = useTailwind();
  return (
    <FlatList
      contentContainerStyle={tailwind('pt-2 pb-4 px-6')}
      horizontal
      data={items ?? []}
      showsHorizontalScrollIndicator={false}
      ItemSeparatorComponent={Separator}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      accessibilityLabel={accessibilityLabel}
    />
  );
};

export const Separator: React.FC = () => {
  const tailwind = useTailwind();
  return <View style={tailwind('pl-2')} />;
};

const keyExtractor = (item: DiscoverFeedItem, index: number) => `${'id' in item ? item.id : index}`;

const renderItem: ListRenderItem<DiscoverFeedItem> = ({ item }) => {
  switch (item.__typename) {
    case 'MostWatchlistedFeedItem':
    case 'DiscoverCategoryFeedItem':
      return (
        <InstrumentNavigationWrapper key={item.instrument?.id} instrumentId={item.instrument?.id ?? ''}>
          <InstrumentSquareCard {...item.instrument} />
        </InstrumentNavigationWrapper>
      );
    case 'SignificantDailyMoverFeedItem':
      return (
        <SignificantMoverCard
          key={item.id ?? ''}
          zScore={item.zScore}
          latestReturn={item.latestReturn}
          date={item.date ?? ''}
          {...item.instrument}
          id={item.instrument?.id ?? ''}
        />
      );
    case 'SectorDiscoverCategoryFeedItem':
      return <SectorCard key={item.id ?? ''} {...item} />;
    default:
      return null;
  }
};
