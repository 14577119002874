import { useNavigation } from '@react-navigation/core';
import { useCallback } from 'react';
import { View, ViewStyle } from 'react-native';
import { ArrowLeftIcon } from 'react-native-heroicons/outline';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { textColors, useTailwind } from '../../theme';
import { isWindowIframe } from '../../util/iframe';
import { usePersistedStore } from '../../zustand/store';
import { IconButton } from '../Button';

export type Props = {
  endAdornment?: React.ReactNode;
  style?: ViewStyle;
  hideBackButton?: boolean;
};

export const TopBar: React.FC<Props> = ({ endAdornment, hideBackButton, style }) => {
  const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();

  // possibly can deep link to this screen, so if cant go back, go to Home Tabs or Login
  const handleBack = useCallback(() => {
    navigation.canGoBack()
      ? navigation.pop()
      : isUserLoggedIn
      ? navigation.navigate('Tabs')
      : navigation.navigate('LogIn');
  }, [isUserLoggedIn, navigation]);

  return (
    <View
      style={[tailwind('flex-row pt-4 items-center justify-between pb-7'), isWindowIframe && tailwind('pt-12'), style]}
    >
      <View>
        {!hideBackButton && (
          <IconButton
            onPress={handleBack}
            accessibilityLabel="Back"
            icon={<ArrowLeftIcon color={textColors.default} size={24} />}
          />
        )}
      </View>
      {endAdornment}
    </View>
  );
};
