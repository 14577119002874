import Svg, { Path, SvgProps } from 'react-native-svg';

export const ArrowRight = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.293 18.293a1 1 0 0 0 1.414 1.414l7-7a1 1 0 0 0 0-1.414l-7-7a1 1 0 1 0-1.414 1.414L19.586 11H2a1 1 0 1 0 0 2h17.586l-5.293 5.293Z"
      fill={props.color ?? '#0F766E'}
    />
  </Svg>
);
