import React, { useCallback, useMemo } from 'react';
import { Image, View } from 'react-native';
import { ProfileRating, UserProfile } from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { useTailwind } from '../../theme';
import { AnimatedPressable, Pressable } from '../../ui/Pressable';
import { Skeleton } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { Camera } from '../icons';
import { ProfileRating as ProfileRatingIcon } from '../icons/ProfileRating';
import { RatingExplainerBottomSheet } from './RatingExplainerSheet';

type Size = 'sm' | 'md' | 'lg';

export type Props = {
  /** on own user page dont want to make pressable, but everywhere else specify this */
  onUserProfilePress?: () => void;
  /** subtext in grey below nickname */
  subText?: string;
  size?: Size;
  /** optionally add a flair chip eg IdeaFlairChip */
  flairChip?: React.ReactNode;
  /** Optional action on pressing image, default action is onUserProfilePress handler */
  onImagePress?: () => void | Promise<void>;
  showImageIcon?: boolean;
} & Pick<UserProfile, 'nickname' | 'profileRating' | 'media'>;

export const Persona: React.FC<Props> = ({
  onUserProfilePress,
  nickname,
  profileRating,
  subText,
  flairChip,
  media,
  onImagePress,
  showImageIcon,
  size = 'lg',
}) => {
  const tailwind = useTailwind();
  const { present, dismiss } = useBottomSheet();
  const onPressProfileRating = useCallback(() => {
    if (size !== 'lg') return;
    present(<RatingExplainerBottomSheet dismiss={dismiss} />);
  }, [present, size, dismiss]);
  const circleSize = getCircleSize(size);

  return (
    <View style={tailwind('flex-row items-center flex-shrink')}>
      <AnimatedPressable
        onPress={onImagePress ?? onUserProfilePress}
        animate={useMemo(
          () =>
            ({ pressed }) => {
              'worklet';
              return {
                scale: pressed && onUserProfilePress ? 0.97 : 1,
              };
            },
          [onUserProfilePress],
        )}
      >
        <View
          style={[
            tailwind(`bg-warmGray-500 rounded-full flex justify-center ${size === 'lg' ? 'mr-3' : 'mr-2'}`),
            getCircleSize(size),
          ]}
        >
          {media?.id ? (
            <Image
              accessibilityLabel="Profile Image"
              style={[tailwind('rounded-full'), circleSize]}
              accessibilityIgnoresInvertColors
              source={{ uri: media.profileLg ?? '' }}
            />
          ) : (
            <Text
              style={[
                tailwind('text-center text-warmGray-300 mb-0.5'),
                { fontFamily: 'IBMPlexMono-Regular' },
                tailwind(getProfileFont(size)),
              ]}
            >
              {(nickname ?? '')[0]}
            </Text>
          )}
        </View>
        {showImageIcon && (
          <View
            accessibilityLabel="Camera"
            style={tailwind('rounded-full border-white border-2 absolute bottom-0 right-1')}
          >
            <Camera />
          </View>
        )}
      </AnimatedPressable>
      <View style={tailwind('justify-center flex-1')}>
        <View style={tailwind('flex-row items-center flex-1')}>
          <Text
            style={[tailwind('text-warmGray-800'), tailwind(getTextFont(size))]}
            numberOfLines={1}
            onPress={onUserProfilePress}
          >
            {nickname}
          </Text>
          {profileRating && (
            <Pressable
              onPress={onPressProfileRating}
              accessibilityLabel={`rating-${profileRating}`}
              style={tailwind(`justify-center ${getProfileRatingPadding(size)}`)}
            >
              <ProfileRatingIcon
                style={tailwind(getProfileRatingSize(size))}
                isGold={profileRating === ProfileRating.Gold}
              />
            </Pressable>
          )}
          {flairChip && <View style={tailwind('pl-2')}>{flairChip}</View>}
        </View>
        {subText && (
          <Text numberOfLines={1} style={[tailwind('text-warmGray-500'), tailwind(getSubTextFont(size))]}>
            {subText}
          </Text>
        )}
      </View>
    </View>
  );
};

const getProfileRatingPadding = (size: Size) => {
  switch (size) {
    case 'lg':
      return 'pl-2';
    case 'md':
    case 'sm':
      return 'pl-1';
  }
};

const getProfileRatingSize = (size: Size) => {
  switch (size) {
    case 'lg':
      return 'h-6 w-6';
    case 'md':
      return 'h-4 w-4';
    case 'sm':
      return 'h-3 w-3';
  }
};

const getCircleSize = (size: Size): { height: number; width: number } => {
  switch (size) {
    case 'lg':
      return { height: 56, width: 56 };
    case 'md':
      return { height: 36, width: 36 };
    case 'sm':
      return { height: 24, width: 24 };
  }
};

const getTextFont = (size: Size): string => {
  switch (size) {
    case 'lg':
      return 'font-semibold text-2xl';
    case 'md':
      return 'text-sm font-medium';
    case 'sm':
      return 'text-xs font-medium';
  }
};

const getSubTextFont = (size: Size): string => {
  switch (size) {
    case 'lg':
      return 'text-sm pt-1';
    case 'md':
      return 'text-xs pt-px';
    case 'sm':
      return 'text-xs';
  }
};
const getProfileFont = (size: Size): string => {
  switch (size) {
    case 'lg':
      return 'font-medium text-2xl';
    case 'md':
      return 'font-medium text-sm';
    case 'sm':
      return 'font-medium text-sm';
  }
};

export const PersonaSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row items-center flex-shrink')}>
      <View style={tailwind('mr-3')}>
        <Skeleton style={tailwind('h-14 w-14 rounded-full')} />
      </View>
      <Skeleton style={tailwind('w-40 h-6 rounded')} />
    </View>
  );
};
