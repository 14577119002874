import { gql } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { useGetSearchLazyQuery } from '../../generated/graphql';
import { useDebounce } from '../../hooks/useDebounce';
import { usePrevious } from '../../hooks/usePrevious';
import { analytics } from '../../services/analytics';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { SearchBar } from '../../ui/SearchBar/SearchBar';
import { TopBar } from '../../ui/TopBar';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';
import { StockList } from './StockList';
import { useStockSelection } from './useStockSelection';

/* eslint-disable graphql/template-strings */
export const GET_STOCK_SEARCH = gql`
  ${CORE_INSTRUMENT_FIELDS}
  query getStockSearch($searchText: NonEmptyString!, $loggedIn: Boolean!) {
    search(searchText: $searchText) {
      instruments {
        nodes {
          ...CoreInstrumentFields
        }
      }
      error {
        message
      }
    }
  }
`;

/**
 * Onboarding stock selection
 * We present a list of popular stocks as a placeholder instead of a skeleton or empty state
 */
export const SearchPage: React.VFC = withReloadErrorBoundary(() => {
  const tailwind = useTailwind();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const [searchText, setSearchText] = useState('');
  const [getSearch, { data, networkStatus, error, refetch }] = useGetSearchLazyQuery();
  const { mostWatchlistedInstruments } = useStockSelection();

  const debouncedSearchText = useDebounce(searchText.trim(), 150);
  const prevDebouncedSearchText = usePrevious(debouncedSearchText);

  useEffect(() => {
    if (debouncedSearchText) {
      analytics.track('Search typed', { 'Search term': debouncedSearchText });
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    // Do not refetch after a user has cleared the search bar
    const isSameSearchText = prevDebouncedSearchText === debouncedSearchText;
    if (!debouncedSearchText || isSameSearchText) return;

    if (data) {
      refetch && refetch({ searchText: debouncedSearchText, loggedIn });
    } else {
      getSearch({ variables: { searchText: debouncedSearchText, loggedIn } });
    }
  }, [networkStatus, debouncedSearchText, data, getSearch, refetch, prevDebouncedSearchText, loggedIn]);

  if (error) {
    throw error;
  }

  const showSearchData = !!debouncedSearchText && !!searchText && !!data && prevDebouncedSearchText !== '';

  return (
    <SafeAreaView>
      <TopBar
        style={tailwind('pb-0 px-6')}
        endAdornment={
          <SearchBar value={searchText} onChangeText={setSearchText} containerStyles={tailwind('ml-6 flex-1')} />
        }
      />

      <ScrollView contentContainerStyle={tailwind('pt-6')}>
        {showSearchData ? (
          <StockList stocks={data.search.instruments?.nodes ?? []} />
        ) : (
          <StockList stocks={mostWatchlistedInstruments} />
        )}
      </ScrollView>
    </SafeAreaView>
  );
});
