import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC, useState } from 'react';
import { Image, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { UpdateProfileImageMutation } from '../../generated/graphql';
import { useUpdateProfileImage } from '../../hooks/mutations/profileImage';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { analytics } from '../../services/analytics';
import { createImageFile } from '../../services/createImageFile';
import { requestProfilePhoto } from '../../services/profilePhoto';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'UploadProfileImage'>;

export const UploadProfileImage: FC<Props> = withReloadErrorBoundary(
  ({
    navigation,
    route: {
      params: { imageUri: initialImageUri },
    },
  }) => {
    const tailwind = useTailwind();
    const toast = useToast();
    const userId = usePersistedStore((state) => state.userId);
    const [loadingImage, setLoadingImage] = useState(false);
    const [imageUri, setImageUri] = useState(initialImageUri);
    const [updateProfileImage, { loading: mutationLoading }] = useUpdateProfileImage({
      onError: (e) => {
        console.error(e);
        toast.show('Something went wrong, please try again.');
      },
      onCompleted: (data) => {
        const validationError = data?.updateProfileImage.error;
        if (validationError) {
          console.error(validationError);
          toast.show(getValidationMessage(validationError));
        } else {
          analytics.identify(`${userId}`, { hasProfileImage: true });
          toast.show('Your profile image has been updated.');
          navigation.pop();
        }
      },
    });

    const onUploadImage = async () => {
      setLoadingImage(true);
      const imageUri = await requestProfilePhoto();
      setLoadingImage(false);
      if (imageUri) {
        setImageUri(imageUri ?? '');
      }
    };

    const onSaveImage = async () => {
      const file = await createImageFile(imageUri);
      updateProfileImage({ variables: { image: file } });
    };

    const loading = loadingImage || mutationLoading;
    return (
      <SafeAreaView>
        <TitleBar />
        <ScreenSidePadding style={tailwind('flex-grow justify-between')}>
          <View style={tailwind('flex-1 justify-end')}>
            <Image
              style={[tailwind('self-center rounded-full'), { height: 200, width: 200 }]}
              accessibilityIgnoresInvertColors
              source={{ uri: imageUri }}
            />
            <View style={tailwind('py-6')} />
            <Button isDisabled={loading} onPress={onUploadImage} variant="inverted" text="Choose from library" />
          </View>
          <View style={tailwind('flex-1 justify-end py-2')}>
            <Button loading={loading} isDisabled={loading} onPress={onSaveImage} variant="primary" text="Save" />
          </View>
        </ScreenSidePadding>
      </SafeAreaView>
    );
  },
);

const getValidationMessage = (validationError: UpdateProfileImageMutation['updateProfileImage']['error']) => {
  switch (validationError?.__typename) {
    case 'EmptyFile':
      return 'This file is empty, please upload another.';
    case 'FileSizeInvalid':
      return 'This file is too large, please upload a smaller file.';
    case 'FileTypeInvalid':
      return 'This file is invalid, please upload an image file.';
    default:
      return 'Something went wrong, please try again.';
  }
};
