import React from 'react';
import { View } from 'react-native';
import { BaseInput, Props as BaseInputProps } from './BaseInput';

type Props = BaseInputProps;

export const TextInput: React.FC<Props> = ({ ...props }) => {
  return (
    <View>
      <BaseInput {...props} secureTextEntry={false} />
    </View>
  );
};
