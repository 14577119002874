import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Eye = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M1.373 13.183a2.064 2.064 0 010-2.366C2.946 8.59 6.819 4 12 4c5.181 0 9.054 4.59 10.627 6.817a2.064 2.064 0 010 2.366C21.054 15.41 17.181 20 12 20c-5.181 0-9.054-4.59-10.627-6.817z"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <Path
        d="M12 16a4 4 0 100-8 4 4 0 000 8z"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </Svg>
  );
};

export const EyeFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12c0 .423.13.836.373 1.183C2.946 15.41 6.819 20 12 20c5.181 0 9.054-4.59 10.627-6.817a2.064 2.064 0 000-2.366C21.054 8.59 17.181 4 12 4 6.819 4 2.946 8.59 1.373 10.817 1.13 11.164 1 11.577 1 12zm15 0a4 4 0 11-8 0 4 4 0 018 0z"
        fill="#CCFBF1"
      />
      <Path
        d="M1.373 13.183l-.82.573.003.004.817-.577zm21.254 0l.817.577.002-.004-.819-.573zm0-2.366l.82-.573-.003-.004-.817.577zm-21.254 0l-.817-.577-.002.004.819.573zm.82 1.793A1.064 1.064 0 012 12H0c0 .628.193 1.241.554 1.756l1.638-1.146zM12 19c-2.287 0-4.338-1.014-6.048-2.36-1.709-1.343-3.006-2.963-3.762-4.034L.556 13.76c.817 1.156 2.243 2.945 4.16 4.453C6.63 19.719 9.105 21 12 21v-2zm9.81-6.394c-.756 1.07-2.053 2.69-3.762 4.035C16.338 17.986 14.288 19 12 19v2c2.894 0 5.37-1.281 7.285-2.787 1.916-1.508 3.342-3.297 4.159-4.453l-1.634-1.154zM22 12c0 .218-.067.431-.192.61l1.638 1.146c.36-.515.554-1.128.554-1.756h-2zm-.192-.61c.125.179.192.392.192.61h2c0-.628-.194-1.241-.554-1.756l-1.638 1.146zM12 5c2.287 0 4.338 1.014 6.048 2.36 1.709 1.343 3.006 2.963 3.762 4.034l1.634-1.154c-.817-1.156-2.243-2.945-4.16-4.453C17.37 4.281 14.895 3 12 3v2zm-9.81 6.394c.756-1.07 2.053-2.69 3.762-4.035C7.662 6.014 9.712 5 12 5V3C9.106 3 6.63 4.281 4.715 5.787 2.8 7.295 1.373 9.084.556 10.24l1.634 1.154zM2 12c0-.218.067-.431.192-.61L.554 10.244A3.064 3.064 0 000 12h2zm10 5a5 5 0 005-5h-2a3 3 0 01-3 3v2zm-5-5a5 5 0 005 5v-2a3 3 0 01-3-3H7zm5-5a5 5 0 00-5 5h2a3 3 0 013-3V7zm5 5a5 5 0 00-5-5v2a3 3 0 013 3h2z"
        fill="#0F766E"
      />
    </Svg>
  );
};

export const EyeGrayscaleWithCorners = (props: SvgProps) => {
  return (
    <Svg width={96} height={96} viewBox="0 0 96 96" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 47.997c.003.898.277 1.774.788 2.513C9.8 57.722 25.868 78 48 78c21.932 0 38.11-20.248 43.188-27.474a4.43 4.43 0 00.012-5.06C86.16 38.23 70.106 18 48 18 25.694 18 9.75 38.26 4.776 45.48a4.438 4.438 0 00-.775 2.517zM66 48c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18z"
        fill="#E7E5E4"
      />
      <Path
        d="M4.788 50.51l-1.645 1.137.003.004 1.642-1.141zm-.787-2.513l-2 .005 2-.005zm87.187 2.529l1.636 1.15.003-.004-1.639-1.146zm.012-5.06l1.644-1.138-.003-.005-1.641 1.143zm-86.424.014l-1.647-1.135-.004.005 1.651 1.13zm1.657 3.893a2.438 2.438 0 01-.432-1.38l-4 .009a6.438 6.438 0 001.142 3.645l3.29-2.274zM48 76c-10.44 0-19.558-4.785-26.771-10.598-7.206-5.806-12.35-12.51-14.799-16.033L3.146 51.65c2.564 3.69 7.959 10.73 15.573 16.866C26.326 74.647 36.31 80 48 80v-4zm41.552-26.624c-2.482 3.532-7.672 10.235-14.89 16.038C67.432 71.224 58.337 76 48 76v4c11.594 0 21.554-5.348 29.167-11.469 7.623-6.127 13.061-13.161 15.657-16.855l-3.272-2.3zm.435-1.383a2.43 2.43 0 01-.438 1.387l3.278 2.292a6.43 6.43 0 001.16-3.67l-4-.009zm-.431-1.389c.282.408.433.893.431 1.39l4 .009a6.43 6.43 0 00-1.142-3.675l-3.29 2.276zM48 20c10.428 0 19.536 4.774 26.746 10.578 7.202 5.797 12.35 12.496 14.813 16.031l3.282-2.286c-2.577-3.7-7.976-10.735-15.587-16.861C69.65 21.34 59.678 16 48 16v4zM6.423 46.615c2.429-3.526 7.532-10.225 14.721-16.026C28.34 24.784 37.47 20 48 20v-4c-11.775 0-21.77 5.346-29.367 11.476C11.03 33.61 5.674 40.651 3.129 44.346l3.294 2.269zm-.422 1.377a2.438 2.438 0 01.426-1.383L3.125 44.35a6.438 6.438 0 00-1.124 3.652l4-.01zM48 68c11.046 0 20-8.954 20-20h-4c0 8.837-7.163 16-16 16v4zM28 48c0 11.046 8.954 20 20 20v-4c-8.837 0-16-7.163-16-16h-4zm20-20c-11.046 0-20 8.954-20 20h4c0-8.837 7.163-16 16-16v-4zm20 20c0-11.046-8.954-20-20-20v4c8.837 0 16 7.163 16 16h4z"
        fill="#A3A3A3"
      />
      <Path
        d="M92 20V4H76M4 20V4h16M92 76v16H76M4 76v16h16"
        stroke="#A3A3A3"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
