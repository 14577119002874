import { FC } from 'react';

/**
 * RNSVG translated Loader doesn't render well on Web so use raw svg instead
 */
export const Loader: FC<{ width: number; height: number }> = ({ width, height }) => {
  return (
    <svg width={width ?? '200'} height={height ?? 200} viewBox="0 0 200 200" color="#14B8A6" fill="none">
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth="8">
        <path stroke="url(#spinner-secondHalf)" d="M 4 100 A 96 96 0 0 1 196 100" />
        <path stroke="url(#spinner-firstHalf)" d="M 196 100 A 96 96 0 0 1 4 100" />
        <path stroke="currentColor" strokeLinecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
      </g>
    </svg>
  );
};
