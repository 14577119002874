import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { useIdeaFormQuery } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { MAX_PRICE_CHART_TIME_INTERVAL } from '../../old/Charts/IntervalChart';
import { TitleBar } from '../../old/TitleBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { calculateTimeInterval, formatDate } from '../../util/date';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { InstrumentSkeleton } from '../Instrument';
import { FormBody } from './FormBody';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'CreateIdea'>;

export const ideaForm = gql`
  query ideaForm($instrumentId: ID!, $dateFrom: Date) {
    instrument(id: $instrumentId) {
      id
      name
      ticker
      logoUrl
      watchlistId
      type
      countryOfListing {
        id
        name
      }
      closePriceSeries(dateFrom: $dateFrom) {
        id
        price
        date
      }
      closePrice {
        id
        price
      }
      quotePrice {
        id
        midPrice
        lastUpdated
      }
      currency {
        id
        iso
      }
      sector {
        id
        name
      }
      industry {
        id
        name
      }
    }
    ideaRationaleCategories {
      id
      name
      sortOrder
      tags {
        id
        name
        isActive
        sortOrder
      }
    }
  }
`;

export const CreateIdea: React.VFC<Props> = withReloadErrorBoundary(({ route }) => {
  const { instrumentId } = route.params;
  const now = new Date();
  const dateFrom = formatDate(calculateTimeInterval(MAX_PRICE_CHART_TIME_INTERVAL, now), 'YYYY-MM-DD');
  const { data } = useIdeaFormQuery({
    variables: {
      instrumentId,
      dateFrom: dateFrom,
    },
  });

  return (
    <SafeAreaView>
      <TitleBar title={`Create Idea: ${data?.instrument?.ticker ?? ''}`} />
      {data ? (
        <FormBody instrument={data.instrument} ideaRationaleCategories={data.ideaRationaleCategories} now={now} />
      ) : (
        <InstrumentSkeleton />
      )}
    </SafeAreaView>
  );
});
