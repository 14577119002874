import { FC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../Text';

export type Props = {
  text: string;
  endAdornment?: React.ReactNode;
  /**
   * Inverted changes text to white. To be used on coloured backgrounds.
   */
  inverted?: boolean;
};

export const TitleBar: FC<Props> = ({ text, endAdornment, inverted }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row justify-between items-center')}>
      <Text
        accessibilityRole="header"
        accessibilityLabel={text}
        style={[tailwind('text-3xl font-semibold text-default'), inverted && tailwind('text-white')]}
      >
        {text}
      </Text>
      {endAdornment}
    </View>
  );
};
