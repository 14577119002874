import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, View } from 'react-native';
import { LoggedInStackNavigationProps } from '../../navigation';
import { Button } from '../../old/Button';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const EmptyWatchlist: React.FC = () => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  return (
    <View style={tailwind('flex-1 justify-center items-center')}>
      <Image
        source={require('../../assets/images/instrument-stack.png')}
        accessibilityIgnoresInvertColors={false}
        style={tailwind('w-56 h-56')}
        resizeMode="contain"
      />
      <Text style={tailwind('mb-2 text-gray-800 text-center font-semibold text-2xl')}>Your watchlist is empty</Text>
      <Text style={tailwind('mb-4 text-gray-500 text-center font-normal text-base')}>
        Add instruments to your watchlist to unlock personalised content and discover new ideas.
      </Text>
      <Button
        text="Discover"
        onPress={() => {
          navigation.navigate('Tabs', { screen: 'DiscoverTab', params: { screen: 'Discover' } });
        }}
      />
      <View style={tailwind('h-20')} />
    </View>
  );
};
