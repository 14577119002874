import queryString from 'query-string';
import { BranchSubscriptionEvent } from 'react-native-branch';
import { analytics } from '../analytics';
import { visitLink } from '../openLink';
import { trackUTM } from './common';
import { Branch } from './types';

/** branch on web initialised in index.html */
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    branch: any;
  }
}

export const branch: Branch = {
  setupSubscriptions: () => {
    try {
      console.log(`Setting up branch subscriptions on web`);

      // on web can pass on utm params from query string if provided
      const { utm_source, utm_medium, utm_campaign } = queryString.parse(window.location.search ?? '');
      if (
        utm_source &&
        typeof utm_source === 'string' &&
        utm_medium &&
        typeof utm_medium === 'string' &&
        utm_campaign &&
        typeof utm_campaign === 'string'
      ) {
        console.log(
          `Analytics track UTM params from URL params: installUtmSource: ${utm_source}, installUtmMedium: ${utm_medium}, installUtmCampaign: ${utm_campaign}`,
        );
        trackUTM({
          installUtmSource: utm_source,
          installUtmMedium: utm_medium,
          installUtmCampaign: utm_campaign,
        });
      }

      // Listen for all events
      window.branch.addListener((event: string, data?: BranchSubscriptionEvent) => {
        try {
          console.log(`Web Branch listener: "${event}" event received: ${JSON.stringify(data)}`);
          if (!data?.params) return;
          const params = data.params;

          // identify user with any utm params
          const installUtmSource = params['~channel'];
          const installUtmMedium = params['~feature'];
          const installUtmCampaign = params['~campaign'];
          if (installUtmSource && installUtmMedium && installUtmCampaign) {
            console.log(
              `Analytics track UTM params from branch: installUtmSource: ${installUtmSource}, installUtmMedium: ${installUtmMedium}, installUtmCampaign: ${installUtmCampaign}`,
            );
            trackUTM({ installUtmSource, installUtmMedium, installUtmCampaign });
          }

          // navigate if branch payload has this magic param
          const deepLinkUrl = params.$deeplink_path;
          if (deepLinkUrl && typeof deepLinkUrl === 'string') {
            console.log(`Visiting branch $deeplink_path: ${deepLinkUrl}`);
            analytics.track('Branch Deeplink', {
              deepLinkUrl,
            });
            return visitLink(deepLinkUrl);
          }
        } catch (e) {
          console.error(`Error listening on a branch event`, e);
        }
      });
    } catch (e) {
      console.error(`Error setting up branch on web`, e);
    }
  },
  setIdentity: (userId: string) => {
    window.branch.setIdentity(userId);
  },
  logout: () => {
    window.branch.logout();
  },
};
