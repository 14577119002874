import React from 'react';
import { View } from 'react-native';
import { Chip } from '../../../old/Chip';
import { useTailwind } from '../../../theme';

type Props = {
  onSelected?: (conviction: ConvictionOption) => void;
  conviction: ConvictionOption;
};

export const Conviction: React.VFC<Props> = ({ onSelected, conviction }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('mt-4 flex-row flex-auto justify-between')}>
      {options.map((option) => (
        <Chip
          key={option}
          selected={option === conviction}
          text={`${option}`}
          size="md"
          rounded="md"
          textSize="base"
          onPress={() => {
            onSelected && onSelected(option);
          }}
          style={tailwind('mx-0.5')}
        />
      ))}
    </View>
  );
};

const options = [1, 2, 3, 4, 5] as const;

export type ConvictionOption = typeof options[number];
