import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Pencil: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={24} fill="none" {...props}>
      <Path
        d="M14 6l4 4M7 21l-5 1 1-5L18 2l4 4L7 21z"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
