import { NavigatorScreenParams } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';
import { DiscoverCategoryType } from '../generated/graphql';
import { AppContent } from '../screens/AppContent';
import { Blog } from '../screens/Blog/Blog';
import { CreateIdea } from '../screens/CreateIdea';
import { Deposit } from '../screens/Deposit/Deposit';
import { DiscoverCategory } from '../screens/DiscoverCategory';
import { FeedItem } from '../screens/FeedItem';
import { Idea } from '../screens/Idea';
import { Instrument } from '../screens/Instrument';
import { InstrumentFinancials } from '../screens/InstrumentFinancials';
import { InstrumentSummary } from '../screens/InstrumentSummary';
import { Landing } from '../screens/Landing';
import { LogIn } from '../screens/LogIn';
import { MarketInstrument } from '../screens/MarketInstrument';
import { Menu } from '../screens/Menu';
import { NewPassword } from '../screens/NewPassword';
import { News } from '../screens/News';
import { OnboardingAboutYou } from '../screens/OnboardingAboutYou';
import { OnboardingConfirmPortfolio } from '../screens/OnboardingConfirmPortfolio';
import { OnboardingCreatingPortfolio } from '../screens/OnboardingCreatingPortfolio';
import { OnboardingEnterEmail } from '../screens/OnboardingEnterEmail/OnboardingEnterEmail';
import { OnboardingGetStarted } from '../screens/OnboardingGetStarted';
import { OnboardingSelectBase } from '../screens/OnboardingSelectBase';
import { OnboardingSelectBundles } from '../screens/OnboardingSelectBundles';
import { OnboardingSelectStocks } from '../screens/OnboardingSelectStocks';
import { PrePortfolioSelectIdeas } from '../screens/PrePortfolioSelectIdeas';
import { PrePortfolioSelectRisk } from '../screens/PrePortfolioSelectRisk';
import { PrePortfolioSimulatorRunning } from '../screens/PrePortfolioSimulatorRunning';
import { RequestPasswordReset } from '../screens/ResetPassword/RequestPasswordReset';
import { SearchModal } from '../screens/SearchModal';
import { SignUp } from '../screens/SignUp';
import { Stack } from '../screens/Stack';
import { Stacks } from '../screens/Stacks';
import { TradingAccountAddressDetails } from '../screens/TradingAccountAddressDetails';
import { TradingAccountBankDetails } from '../screens/TradingAccountBankDetails';
import { TradingAccountPersonalDetails } from '../screens/TradingAccountPersonalDetails';
import { TradingAccountSubmit } from '../screens/TradingAccountSubmit';
import { UploadProfileImage } from '../screens/UploadProfileImage';
import { UploadProfileImageSignup } from '../screens/UploadProfileImageSignup';
import { UpsideShares } from '../screens/UpsideShares';
import { UpsideVoice } from '../screens/UpsideVoice/UpsideVoice';
import { UserProfile } from '../screens/UserProfile';
import { ViewProfileImage } from '../screens/ViewProfileImage';
import { WebviewScreen } from '../screens/WebviewScreen';
import { ExternalURIs, WebsiteBlog } from '../services/externalURIs';
import { PostNavigationProvider } from '../ui/AppProvider';
import { usePersistedStore } from '../zustand/store';
import { TabsNavigator, TabsParamList } from './TabsNavigator';

export type LoggedInStackParamList = {
  Tabs: NavigatorScreenParams<TabsParamList> | undefined;
  InstrumentSummary: { instrumentId: string };
  InstrumentFinancials: { instrumentId: string; selectedIndex?: number };
  CreateIdea: { instrumentId: string };
  /** categoryId must be supplied if categoryType is one of {CURATED, SECTOR} */
  DiscoverCategory: { categoryId?: string; categoryType: DiscoverCategoryType };
  UserProfile: { userId: number; initialSelectedIndex?: number };
  SearchModal: undefined;
  Menu: undefined;
  Blog: { blogId: string };
  AppContent: { appContentId: string };
  /** Image dataURi */
  UploadProfileImage: { imageUri: string };
  UploadProfileImageSignup: { nickname: string };
  ViewProfileImage: { imageUri: string };
  /** Edit param forces creation of new PrePortfolio, marking current one as inactive. */
  PrePortfolioSelectIdeas: undefined;
  PrePortfolioSimulatorRunning: { prePortfolioId: string };
  PrePortfolioSelectRisk: { prePortfolioId: string };
  UpsideShares: {
    totalShares: number;
    totalValue: number;
    firstIssueDate: string;
  };
  TradingAccountPersonalDetails: undefined;
  TradingAccountAddressDetails: undefined;
  TradingAccountBankDetails: undefined;
  TradingAccountSubmit: undefined;
  CashBalance: undefined;
  Deposit: undefined;
  Stack: { id: string };
  Stacks: undefined;
} & StackParamList;

/** all logged out stack screens */
export type LoggedOutStackParamList = {
  LogIn: undefined;
  Landing: undefined;
  /**
   * @property {boolean} external - If true, it means the user has arrived at the sign-up page from outside the app (show the extra header)
   */
  SignUp: { external?: boolean; hideBackButton?: boolean };
  RequestPasswordReset: undefined;
  NewPassword: { token: string; uid: string };
} & StackParamList;

/** Common Stacks for logged in and logged out */
export type StackParamList = {
  WebviewScreen: { uri: ExternalURIs[keyof ExternalURIs] | WebsiteBlog };
  /** FeedItemId used to render feed item in a bottom sheet when visiting a 'share feed item' link */
  Instrument: { instrumentId: string; feedItemId?: string; initialSelectedIndex?: number };
  MarketInstrument: { instrumentId: string };
  /** commentId used to show reply sheet for that comment */
  Idea: { ideaId: string; showComments?: boolean; commentId?: string; forceRefetch?: boolean };
  FeedItem: { feedItemId: string };
  News: { newsId: string };
  Voice: { voiceId: string };
  /** isMarketing true when directing here from www. Will show enter email not sign up screen at end of wizard */
  OnboardingGetStarted: { isMarketing?: boolean; showBackButton?: boolean };
  OnboardingAboutYou: undefined;
  OnboardingSelectBase: undefined;
  OnboardingSelectBundles: undefined;
  OnboardingSelectStocks: undefined;
  OnboardingConfirmPortfolio: undefined;
  OnboardingCreatingPortfolio: undefined;
  OnboardingEnterEmail: undefined;
};

export type RootStackParamList = LoggedInStackParamList & LoggedOutStackParamList;

/**
 *  union of logged in and logged out stacks so animation between screens happens when logging in or out
 *  Use JS stack navigator in Web to keep screen transition animations
 */
const RootStack =
  Platform.OS === 'web' ? createStackNavigator<RootStackParamList>() : createNativeStackNavigator<RootStackParamList>();
/**
 * Navigation for Main App
 *
 * Separate possible stacks: Logged in vs logged in.
 *
 * All under one stack so transition animation smooth when switching between login and logout.
 * As suggested by react navigation docs: https://reactnavigation.org/docs/auth-flow
 *
 * If want a full-screen logged in stack screen, place next to Tabs so screen will cover the tab bar and animation transition still smooth
 *
 * TODO: perhaps a Bootstrap loading screen here whilst we refresh JWT access token? or grab feed and check offline?
 *
 */
export const RootStackNavigator: React.FC = () => {
  const { isUserLoggedIn, nickname, isStaff } = usePersistedStore(({ nickname, isUserLoggedIn, isStaff }) => ({
    isUserLoggedIn,
    nickname,
    isStaff,
  }));

  /**
   * make header sticky on web using cardStyle flex 1. Bit less web-like as prevents chrome address bar hiding.
   * But we have tabs at bottom so need this. One day perhaps we have tabs at top on web-only like mobile.facebook.com
   * https://github.com/react-navigation/react-navigation/issues/9344
   *
   */
  const screenOptions = {
    headerShown: false,
    cardStyle: { flex: 1 },
    animationEnabled: true,
  };

  return (
    <PostNavigationProvider>
      <RootStack.Navigator screenOptions={screenOptions}>
        {isUserLoggedIn ? (
          <>
            <RootStack.Screen name="Tabs" component={TabsNavigator} />
            <RootStack.Screen name="InstrumentSummary" component={InstrumentSummary} />
            <RootStack.Screen name="InstrumentFinancials" component={InstrumentFinancials} />
            <RootStack.Screen name="UserProfile" component={UserProfile} />
            <RootStack.Screen name="DiscoverCategory" component={DiscoverCategory} />
            <RootStack.Screen name="CreateIdea" component={CreateIdea} />
            <RootStack.Screen name="AppContent" component={AppContent} />
            <RootStack.Screen name="Menu" component={Menu} />
            <RootStack.Screen name="UploadProfileImage" component={UploadProfileImage} />
            <RootStack.Screen name="ViewProfileImage" component={ViewProfileImage} />
            <RootStack.Screen name="UpsideShares" component={UpsideShares} />
            <RootStack.Screen name="Deposit" component={Deposit} />

            <RootStack.Screen
              name="UploadProfileImageSignup"
              initialParams={{ nickname }}
              component={UploadProfileImageSignup}
            />

            {/** Modal screens shown as icons in Tab TitleBars */}
            <RootStack.Group screenOptions={{ presentation: 'modal' }}>
              <RootStack.Screen name="SearchModal" component={SearchModal} />
            </RootStack.Group>

            {/** Pre portfolio flow */}
            <RootStack.Screen name="PrePortfolioSelectIdeas" component={PrePortfolioSelectIdeas} />
            <RootStack.Screen name="PrePortfolioSimulatorRunning" component={PrePortfolioSimulatorRunning} />
            <RootStack.Screen name="PrePortfolioSelectRisk" component={PrePortfolioSelectRisk} />
            {isStaff && (
              <RootStack.Group>
                <RootStack.Screen name="TradingAccountPersonalDetails" component={TradingAccountPersonalDetails} />
                <RootStack.Screen name="TradingAccountAddressDetails" component={TradingAccountAddressDetails} />
                <RootStack.Screen name="TradingAccountBankDetails" component={TradingAccountBankDetails} />
                <RootStack.Screen name="TradingAccountSubmit" component={TradingAccountSubmit} />
                <RootStack.Screen name="Stack" component={Stack} />
                <RootStack.Screen name="Stacks" component={Stacks} />
              </RootStack.Group>
            )}
          </>
        ) : (
          <>
            <RootStack.Screen name="Landing" component={Landing} />
            <RootStack.Screen name="SignUp" component={SignUp} />
            <RootStack.Screen name="LogIn" component={LogIn} />
            <RootStack.Screen name="RequestPasswordReset" component={RequestPasswordReset} />
            <RootStack.Screen name="NewPassword" component={NewPassword} />
          </>
        )}
        <RootStack.Screen name="Instrument" component={Instrument} />
        <RootStack.Screen name="MarketInstrument" component={MarketInstrument} />
        <RootStack.Screen name="Idea" component={Idea} />
        <RootStack.Screen name="FeedItem" component={FeedItem} />
        <RootStack.Screen name="News" component={News} />
        <RootStack.Screen name="Voice" component={UpsideVoice} />
        <RootStack.Screen name="Blog" component={Blog} />
        <RootStack.Screen name="WebviewScreen" component={WebviewScreen} />

        <RootStack.Group>
          <RootStack.Screen name="OnboardingGetStarted" component={OnboardingGetStarted} />
          <RootStack.Screen name="OnboardingAboutYou" component={OnboardingAboutYou} />
          <RootStack.Screen name="OnboardingSelectBase" component={OnboardingSelectBase} />
          <RootStack.Screen name="OnboardingSelectBundles" component={OnboardingSelectBundles} />
          <RootStack.Screen name="OnboardingSelectStocks" component={OnboardingSelectStocks} />
          <RootStack.Screen name="OnboardingConfirmPortfolio" component={OnboardingConfirmPortfolio} />
          <RootStack.Screen name="OnboardingCreatingPortfolio" component={OnboardingCreatingPortfolio} />
          <RootStack.Screen name="OnboardingEnterEmail" component={OnboardingEnterEmail} />
        </RootStack.Group>
      </RootStack.Navigator>
    </PostNavigationProvider>
  );
};
