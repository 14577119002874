import { gql, useApolloClient } from '@apollo/client';
import { LinearGradient } from 'expo-linear-gradient';
import { Formik, FormikHelpers } from 'formik';
import { orderBy } from 'lodash';
import { MotiView } from 'moti';
import { FC, useEffect, useMemo, useState } from 'react';
import { Linking, Platform, View } from 'react-native';
import { ArrowSmRightIcon } from 'react-native-heroicons/outline';
import { useToast } from 'react-native-toast-notifications';
import * as yup from 'yup';
import { STACK_FIELDS } from '../../fragments/stack';
import {
  OnboardingEmailTemplate,
  StackFieldsFragment,
  useRecordOnboardingPortfolioMutation,
} from '../../generated/graphql';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { Button } from '../../ui/Button';
import { PortfolioStatsPreview } from '../../ui/PortfolioStatsPreview/PortfolioStatsPreview';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { TextInput } from '../../ui/TextInput';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { Portfolio } from '../../ui/VectorImages';
import { isWindowIframe } from '../../util/iframe';
import { formatNumber, formatPercent } from '../../util/number';
import { isFiniteNumber, isNotNull } from '../../util/typeGuards';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, usePortfolioIntendedPersistedStore } from '../../zustand/store';

export const RECORD_ONBOARDING_PORTFOLIO_MUTATION = gql`
  mutation RecordOnboardingPortfolio(
    $email: NonEmptyString!
    $portfolio: OnboardingPortfolioInput!
    $anonymousId: NonEmptyString!
    $emailTemplate: OnboardingEmailTemplate!
  ) {
    recordOnboardingPortfolio(
      email: $email
      portfolio: $portfolio
      anonymousId: $anonymousId
      emailTemplate: $emailTemplate
    ) {
      error {
        message
      }
    }
  }
`;

const schema = yup.object({
  email: yup.string().trim().email('Must be a valid email').required('Required'),
});

type FormSchema = yup.InferType<typeof schema>;

export const OnboardingEnterEmail: FC = withReloadErrorBoundary(() => {
  const isMarketing = usePersistedStore((s) => s.isMarketing);
  const [emailSent, setEmailSent] = useState(false);
  const tailwind = useTailwind();
  const apolloClient = useApolloClient();
  const anonymousId = usePersistedStore(({ anonymousId }) => anonymousId);
  const { baseLayer, stackLayer, stockLayer, insights } = usePortfolioIntendedPersistedStore(
    ({ baseLayer, stackLayer, stockLayer, insights }) => ({
      baseLayer,
      stackLayer,
      stockLayer,
      insights,
    }),
  );
  const [mutate] = useRecordOnboardingPortfolioMutation();
  const toast = useToast();

  /**
   * Unset isMarketing in case next visit is not from marketing.
   */
  useEffect(() => {
    if (isMarketing) {
      usePersistedStore.setState({ isMarketing: false });
      console.log(
        `At OnboardingCreatingPortfolio with isMarketing ${isMarketing}. End of Onboarding => resetting persisted isMarketing`,
      );
    }
  }, [isMarketing]);

  /**
   * Read selected items directly from cache
   */
  const selectedBase = apolloClient.readFragment<StackFieldsFragment>({
    id: `Stack:${baseLayer.constituentIds[0]}`,
    fragment: STACK_FIELDS,
  });
  const selectedBundles = stackLayer.constituentIds
    .map((stackId) => {
      return apolloClient.readFragment<StackFieldsFragment>({
        id: `Stack:${stackId}`,
        fragment: STACK_FIELDS,
      });
    })
    .filter(isNotNull);

  /**
   * We want to filter iShares Bond ETF from user-facing 'top n stocks' list as it makes up 30% of the base, so would always be top of the portfolio
   */
  const iSharesTreasuryId = useMemo(
    () =>
      insights?.flattenedPortfolioComponents?.find(
        (i) => i.instrument.displayName === 'iShares 7-10 Year Treasury Bond ETF',
      )?.instrument.id ?? '',
    [insights],
  );

  const selectedStocks = stockLayer.constituents;

  const onSubmit = async (values: FormSchema, formikHelpers: FormikHelpers<FormSchema>) => {
    try {
      const [holding1, holding2, holding3, holding4, holding5] = orderBy(
        insights?.flattenedPortfolioComponents,
        'weight',
        'desc',
      )
        .slice(0, 6)
        .filter((i) => i.instrument.id !== iSharesTreasuryId);
      /**
       * Volatilities are retrieved as custom score metric accounting for weightings. Should be multiplied by 10_000 when presenting.
       */
      const riskiestHoldings = (orderBy(insights?.volatilities ?? [], 'volatility', 'desc') ?? []).filter(
        (i) => i.instrument?.id !== iSharesTreasuryId,
      );
      const topSectors = orderBy(insights?.sectorWeights ?? [], 'weight', 'desc') ?? [];
      const topCountries = orderBy(insights?.countryWeights ?? [], 'weight', 'desc') ?? [];
      const emailTemplate: OnboardingEmailTemplate = {
        PERFORMANCE: isFiniteNumber(insights?.annualisedReturn) ? formatPercent(insights?.annualisedReturn ?? 0) : '',
        TOP_HOLDINGS_1: holding1?.instrument?.displayName ?? '',
        TOP_HOLDINGS_WEIGHT_1: isFiniteNumber(holding1?.weight) ? formatPercent(holding1.weight, 2, false) : '',
        TOP_HOLDINGS_2: holding2?.instrument?.displayName ?? '',
        TOP_HOLDINGS_WEIGHT_2: isFiniteNumber(holding2?.weight) ? formatPercent(holding2.weight, 2, false) : '',
        TOP_HOLDINGS_3: holding3?.instrument?.displayName ?? '',
        TOP_HOLDINGS_WEIGHT_3: isFiniteNumber(holding3?.weight) ? formatPercent(holding3.weight, 2, false) : '',
        TOP_HOLDINGS_4: holding4?.instrument?.displayName ?? '',
        TOP_HOLDINGS_WEIGHT_4: isFiniteNumber(holding4?.weight) ? formatPercent(holding4.weight, 2, false) : '',
        TOP_HOLDINGS_5: holding5?.instrument?.displayName ?? '',
        TOP_HOLDINGS_WEIGHT_5: isFiniteNumber(holding5?.weight) ? formatPercent(holding5.weight, 2, false) : '',
        VOLATILITY: isFiniteNumber(insights?.annualisedVolatility)
          ? formatPercent(insights?.annualisedVolatility ?? 0, 0, false)
          : '',
        TOP_RISK_1: riskiestHoldings?.[0]?.instrument?.displayName ?? '',
        TOP_RISK_WEIGHT_1: formatRiskScore(riskiestHoldings?.[0]?.volatility),
        TOP_RISK_2: riskiestHoldings?.[1]?.instrument?.displayName ?? '',
        TOP_RISK_WEIGHT_2: formatRiskScore(riskiestHoldings?.[1]?.volatility),
        TOP_RISK_3: riskiestHoldings?.[2]?.instrument?.displayName ?? '',
        TOP_RISK_WEIGHT_3: formatRiskScore(riskiestHoldings?.[2]?.volatility),
        TOP_SECTOR_1: topSectors?.[0]?.sector?.name ?? '',
        TOP_SECTOR_WEIGHT_1: isFiniteNumber(topSectors?.[0]?.weight)
          ? formatPercent(topSectors[0].weight, 0, false)
          : '',
        TOP_SECTOR_2: topSectors?.[1]?.sector?.name ?? '',
        TOP_SECTOR_WEIGHT_2: isFiniteNumber(topSectors?.[1]?.weight)
          ? formatPercent(topSectors[1].weight, 0, false)
          : '',
        TOP_SECTOR_3: topSectors?.[2]?.sector?.name ?? '',
        TOP_SECTOR_WEIGHT_3: isFiniteNumber(topSectors?.[2]?.weight)
          ? formatPercent(topSectors[2].weight, 0, false)
          : '',
        COUNTRY_1: topCountries?.[0]?.country?.name ?? '',
        COUNTRY_WEIGHT_1: isFiniteNumber(topCountries?.[0]?.weight)
          ? formatPercent(topCountries[0].weight, 0, false)
          : '',
        COUNTRY_2: topCountries?.[1]?.country?.name ?? '',
        COUNTRY_WEIGHT_2: isFiniteNumber(topCountries?.[1]?.weight)
          ? formatPercent(topCountries[1].weight, 0, false)
          : '',
        COUNTRY_3: topCountries?.[2]?.country?.name ?? '',
        COUNTRY_WEIGHT_3: isFiniteNumber(topCountries?.[2]?.weight)
          ? formatPercent(topCountries[2].weight, 0, false)
          : '',
        OVERALL_ESG_SCORE: insights?.esg?.totalScore ? formatNumber(insights.esg.totalScore, 0) : '',
        ENVIRONMENT_ESG_SCORE: insights?.esg?.environmentScore ? formatNumber(insights.esg.environmentScore, 0) : '',
        SOCIAL_ESG_SCORE: insights?.esg?.socialScore ? formatNumber(insights.esg.socialScore, 0) : '',
        GOVERNANCE_ESG_SCORE: insights?.esg?.governanceScore ? formatNumber(insights.esg.governanceScore, 0) : '',
      };
      const result = await mutate({
        variables: {
          email: values.email,
          anonymousId: anonymousId ?? '',
          emailTemplate,
          portfolio: {
            baseLayer: {
              weight: baseLayer.weight,
              constituents: [
                {
                  id: selectedBase?.id ?? '',
                  name: selectedBase?.content?.name ?? 'N/A',
                },
              ],
            },
            stackLayer: {
              weight: stackLayer.weight,
              constituents: selectedBundles.map((stack) => ({
                id: stack.id,
                name: stack.content?.name ?? 'N/A',
              })),
            },

            stockLayer: {
              weight: stockLayer.weight,
              constituents: selectedStocks.map((stock) => ({
                id: stock.id,
                name: stock.displayName ?? 'N/A',
              })),
            },
          },
        },
      });

      const validationErrorResult = result.data?.recordOnboardingPortfolio.error;
      if (validationErrorResult) {
        throw createValidationError(validationErrorResult);
      } else {
        setEmailSent(true);
      }
    } catch (e) {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const onPressDone = () => {
    Linking.openURL('https://upsidetechnology.co');
  };

  /**
   * Ensure insights are available before rendering the stats, otherwise fallback to SVG placeholder.
   */
  const canRenderPortfolioStats = insights?.sectorWeights && insights.flattenedPortfolioComponents.length;

  return (
    <SafeAreaView>
      <ScreenSidePadding style={[!emailSent && tailwind('h-full overflow-hidden')]}>
        <TopBar hideBackButton />
        <TitleBar text={emailSent ? 'Thank you for signing up!' : 'Enter your email for personalised insights!'} />
        <View style={tailwind('pt-6')} />
        {emailSent ? (
          <>
            <Text style={tailwind('text-default ')}>
              Scroll down for more immediate insights. You{"'"}ll receive a breakdown of your portfolio in your email.
              {'\n'}
              {'\n'}
              We are launching soon.
            </Text>
            {canRenderPortfolioStats && <PortfolioStats />}
            {/* Only show Done button when not in iframe, else leave on 'Finished' screen */}
            {!isWindowIframe && (
              <View style={tailwind('py-6')}>
                <Button isFullWidth size="large" text="Done" onPress={onPressDone} />
              </View>
            )}
          </>
        ) : (
          <>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{ email: '' }}
              validationSchema={schema}
              onSubmit={onSubmit}
            >
              {({ values: { email }, handleSubmit, isSubmitting, errors, handleBlur, handleChange }) => (
                <>
                  <TextInput
                    value={email}
                    placeholder="Email"
                    errorMessage={errors.email}
                    accessibilityLabel="Text input field"
                    onChangeText={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                  <View style={tailwind('w-48 pt-4')}>
                    <Button
                      isFullWidth
                      size="large"
                      EndIcon={ArrowSmRightIcon}
                      isLoading={isSubmitting}
                      text="Unlock insights"
                      onPress={handleSubmit}
                    />
                  </View>
                </>
              )}
            </Formik>
            <View style={tailwind('flex-shrink')}>{canRenderPortfolioStats && <PortfolioPreview />}</View>
          </>
        )}
        {!canRenderPortfolioStats && (
          <View style={tailwind('pt-6 justify-center')}>
            <Portfolio />
          </View>
        )}
      </ScreenSidePadding>
    </SafeAreaView>
  );
});

const PortfolioStats: FC = () => {
  const tailwind = useTailwind();
  return (
    <MotiView
      from={{ opacity: 0.5, transform: [{ translateY: 16 }] }}
      animate={{ opacity: 1, transform: [{ translateY: 0 }] }}
      transition={{ type: 'timing' }}
      style={tailwind('pb-8')}
    >
      <PortfolioStatsPreview />
    </MotiView>
  );
};

const PortfolioPreview: FC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-shrink')}>
      <View style={tailwind('flex-shrink')}>
        <PortfolioStatsPreview />
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          colors={['rgba(255,255,255,0)', 'rgb(255,255,255)']}
          style={[
            tailwind('absolute flex-grow'),
            // Web doesn't support start and end points so use fixed dimensions
            Platform.OS === 'web' && tailwind('h-full w-full'),
          ]}
        />
      </View>
      <View style={tailwind('pt-8 pb-10 bg-white')}>
        <Text style={tailwind('text-center text-base text-default font-medium')}>
          Enter your email to unlock insights and analysis.
        </Text>
        <Text style={tailwind('pt-6 text-default text-center text-sm bg-white')}>
          No spam, only personalised insights.
        </Text>
      </View>
    </View>
  );
};

function formatRiskScore(score: number | undefined): string {
  if (!score) {
    return '';
  }
  return formatNumber(score * 10000, 0);
}
