import { intersection } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { Accordion } from '../../../old/Accordion/Accordion';
import { Chip } from '../../../old/Chip';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';

export type RationaleCategory = {
  id: string;
  name: string;
  tags: Tag[];
};

type Tag = {
  id: string;
  name: string;
};

type Props = {
  rationales: RationaleCategory[];
  onTagPress?: (categoryId: string, tagId: string) => void;
  selectedTagIds: string[];
  errorMsg?: string;
};

export const Rationale: React.VFC<Props> = ({ rationales, onTagPress, selectedTagIds, errorMsg }) => {
  const tailwind = useTailwind();
  const renderCategoryHeader = (category: RationaleCategory) => (
    <View style={tailwind('flex-row flex-grow justify-between items-center py-2')}>
      <Text style={tailwind('text-base text-warmGray-800 font-medium')}>{category.name}</Text>
      <Text style={tailwind('text-warmGray-500 font-normal text-sm')}>
        {
          intersection(
            category.tags.map((tag) => tag.id),
            selectedTagIds,
          ).length
        }{' '}
        selected
      </Text>
    </View>
  );

  const renderCategoryContents = (category: RationaleCategory) => {
    return (
      <View>
        <View style={tailwind('flex-row flex-wrap')}>
          {category.tags.map((tag) => (
            <View key={tag.id} style={tailwind('py-1.5 px-1')}>
              <Chip
                selected={selectedTagIds.includes(tag.id)}
                onPress={() => {
                  onTagPress && onTagPress(category.id, tag.id);
                }}
                text={tag.name}
              />
            </View>
          ))}
        </View>
      </View>
    );
  };

  return (
    <>
      <Text style={tailwind('mt-2 h-5 text-red-500')}>{errorMsg}</Text>
      <Accordion sections={rationales} renderHeader={renderCategoryHeader} renderContent={renderCategoryContents} />
    </>
  );
};
