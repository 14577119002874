import { Image, ImageSourcePropType, useWindowDimensions } from 'react-native';

export type Props = {
  /** use require eg: `require('../../assets/images/LayersAllColoured.png')` */
  imgSrc: ImageSourcePropType;
  /** width of png in px */
  imgWidth: number;
  /** height of png in px */
  imgHeight: number;
  /** Between 0 and 1. Will resize to this percentage width until reached max of imgWidth */
  percentageScreenWidth: number;
};

/**
 * Safari does not support some of the SVG properties, just use PNG for now
 *
 * So use this wrapper to resize it to scale based on device width
 */
export const WebImage: React.VFC<Props> = ({ imgSrc, imgHeight, imgWidth, percentageScreenWidth }) => {
  const { width } = useWindowDimensions();
  // maintain aspect ratio and set width and height of image to less than screen width
  const realWidth = Math.min(imgWidth, width * percentageScreenWidth);
  const realHeight = realWidth >= imgWidth ? imgHeight : (realWidth / imgWidth) * imgHeight;
  return (
    <Image
      source={imgSrc}
      accessibilityIgnoresInvertColors={false}
      resizeMode="contain"
      style={{ width: realWidth, height: realHeight }}
    />
  );
};
