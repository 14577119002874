import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { Image, View } from 'react-native';
import { layout } from '../../constants/layout';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'ViewProfileImage'>;

export const ViewProfileImage: FC<Props> = withReloadErrorBoundary(
  ({
    route: {
      params: { imageUri: initialImageUri },
    },
  }) => {
    const tailwind = useTailwind();
    return (
      <SafeAreaView>
        <TitleBar />
        <View style={tailwind('flex-grow items-center justify-center')}>
          <Image
            accessibilityIgnoresInvertColors
            style={{ width: layout.window.width, height: layout.window.width }}
            source={{ uri: initialImageUri }}
          />
        </View>
      </SafeAreaView>
    );
  },
);
