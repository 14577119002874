import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Voice = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M8 16a.324.324 0 0 1-.15-.037.356.356 0 0 1-.122-.106L5.05 12.191H1.74c-.462-.001-.904-.202-1.23-.56A2.003 2.003 0 0 1 0 10.287V1.905C0 1.4.184.915.51.559A1.668 1.668 0 0 1 1.74 0h12.52c.462 0 .904.201 1.23.559.326.357.51.84.51 1.346v8.38c0 .506-.184.99-.51 1.347a1.668 1.668 0 0 1-1.23.559h-3.31L8.27 15.857a.355.355 0 0 1-.12.105A.323.323 0 0 1 8 16Z"
      fill="#A3A3A3"
    />
    <Path
      d="M5.565 9.524a.333.333 0 0 1-.246-.112.4.4 0 0 1-.102-.27V3.049a.4.4 0 0 1 .102-.27.333.333 0 0 1 .246-.111c.092 0 .18.04.246.111a.4.4 0 0 1 .102.27v6.095a.4.4 0 0 1-.102.27.333.333 0 0 1-.246.11ZM8 7.238a.333.333 0 0 1-.246-.111.4.4 0 0 1-.102-.27V5.333a.4.4 0 0 1 .102-.269A.333.333 0 0 1 8 4.952c.092 0 .18.04.246.112a.4.4 0 0 1 .102.27v1.523a.4.4 0 0 1-.102.27.333.333 0 0 1-.246.111ZM3.13 8a.333.333 0 0 1-.245-.112.4.4 0 0 1-.102-.269V4.571a.4.4 0 0 1 .102-.269.333.333 0 0 1 .246-.112c.092 0 .18.04.245.112a.4.4 0 0 1 .102.27v3.047a.4.4 0 0 1-.102.27.333.333 0 0 1-.245.11ZM10.435 8.762a.333.333 0 0 1-.246-.112.4.4 0 0 1-.102-.27V3.81a.4.4 0 0 1 .102-.27.333.333 0 0 1 .246-.112c.092 0 .18.04.246.112a.4.4 0 0 1 .102.27v4.57a.4.4 0 0 1-.102.27.333.333 0 0 1-.246.112ZM12.87 7.238a.333.333 0 0 1-.246-.111.4.4 0 0 1-.102-.27V5.333a.4.4 0 0 1 .102-.269.333.333 0 0 1 .246-.112c.092 0 .18.04.246.112a.4.4 0 0 1 .101.27v1.523a.4.4 0 0 1-.101.27.333.333 0 0 1-.246.111Z"
      fill="#fff"
    />
  </Svg>
);
