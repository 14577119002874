import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { View } from 'react-native';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { Divider } from '../../old/Divider';
import { TitleBar } from '../../old/TitleBar';
import { visitLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { formatPrice } from '../../util/number';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';
import { Confetti } from './Confetti';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'UpsideShares'>;

export const UpsideShares: React.VFC<Props> = withReloadErrorBoundary(({ route }) => {
  const tailwind = useTailwind();
  const { nickname } = usePersistedStore(({ nickname }) => ({
    nickname,
  }));
  const { firstIssueDate, totalShares, totalValue } = route.params;

  return (
    <SafeAreaView>
      <TitleBar />
      <ScrollView contentContainerStyle={tailwind('px-6')}>
        <Text style={tailwind('text-center text-warmGray-800 text-xl font-semibold py-1')}>Upside shareholder</Text>
        <Text style={tailwind('text-center text-warmGray-800 text-lg font-semibold py-1')}>{nickname}</Text>
        <Text style={tailwind('text-center text-warmGray-500 text-base font-medium py-1')}>
          Shareholder since {formatDate(firstIssueDate, 'DD MMM YYYY')}
        </Text>
        <Confetti style={[tailwind('self-center')]} width={'100%'} />
        <Divider style={tailwind('my-2')} />
        <View style={tailwind('flex-row justify-between py-1')}>
          <Text style={tailwind('text-warmGray-800 text-base')}>Total shares</Text>
          <Text style={tailwind('text-warmGray-800 text-base')}>{totalShares}</Text>
        </View>
        <View style={tailwind('flex-row justify-between py-1')}>
          <Text style={tailwind('text-warmGray-800 text-base font-normal')}>Total share value</Text>
          <Text style={tailwind('text-warmGray-800 text-base font-normal')}>{formatPrice(totalValue, 'GBP')}</Text>
        </View>
        <Divider style={tailwind('my-2')} />
        <Text style={tailwind('text-center text-warmGray-800 text-sm font-light pt-4')}>
          As an Upside shareholder, you{"'"}ve joined us on a journey to revolutionise the world of investing for
          everyone, everywhere.
        </Text>
        <Button
          style={tailwind('my-4')}
          text="Learn more"
          variant="primary-bright"
          onPress={() => {
            visitLink('https://help.upsidetechnology.co/free-shares-faq');
          }}
        />
      </ScrollView>
    </SafeAreaView>
  );
});
