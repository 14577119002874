import React from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

type Props = { title: string; content: string };

export const IdeaDetailBox: React.FC<Props> = ({ title, content }) => {
  const tailwind = useTailwind();
  return (
    <View>
      <Text style={tailwind('text-xs text-warmGray-500')}>{title}</Text>
      <Text style={tailwind('text-sm text-warmGray-800')}>{content}</Text>
    </View>
  );
};
