import React from 'react';
import { StyleProp, View, ViewProps } from 'react-native';
import { Idea } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { ArrowDown, ArrowUp } from '../icons';

export type Props = Pick<Idea, 'position'> & {
  style?: StyleProp<ViewProps>;
  iconWidth?: number;
  iconHeight?: number;
  textStyle?: StyleProp<ViewProps>;
};

export const IdeaPositionBox: React.FC<Props> = ({ position, style, iconHeight = 12, iconWidth = 12, textStyle }) => {
  const tailwind = useTailwind();
  return (
    <View
      style={[
        tailwind('flex-row justify-center items-center'),
        style,
        tailwind(iconWidth === 12 ? 'rounded-xl' : 'rounded-md'),
        tailwind(position === 'LONG' ? 'bg-blue-100' : 'bg-fuchsia-200'),
      ]}
    >
      <View style={tailwind(iconWidth === 12 ? 'pr-2' : 'pr-1')}>
        {position === 'LONG' ? (
          <ArrowUp width={iconWidth} height={iconHeight} />
        ) : (
          <ArrowDown width={iconWidth} height={iconHeight} />
        )}
      </View>
      <Text
        style={[
          tailwind(`${position === 'LONG' ? 'text-blue-700' : 'text-fuchsia-600'} font-medium`),
          textStyle ?? tailwind('text-base'),
        ]}
      >
        {position === 'LONG' ? 'Bullish' : 'Bearish'}
      </Text>
    </View>
  );
};
