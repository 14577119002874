import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Platform, View } from 'react-native';
import { Idea, Instrument, Maybe, Topic, UserProfile } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Skeleton } from '../../ui/Skeleton';
import { OutlineInnerCard } from '../Card';
import { Chip } from '../Chip';
import { ViewCommentLink } from '../ViewCommentLink';
import { IdeaInstrumentSnapshot } from './IdeaInstrumentSnapshot';
import { IdeaSnapshot } from './IdeaSnapshot';
import { InstrumentSnapshot } from './InstrumentSnapshot';
import { Timeline } from './Timeline';

/*
 * This component is used in both feed and the idea screen.
 * the argument isFeed indicate if the card is in the feed or not
 */

export type Props = { ideaId: string } & Pick<
  Idea,
  | 'position'
  | 'targetDate'
  | 'targetPrice'
  | 'referenceDate'
  | 'referencePrice'
  | 'status'
  | 'rationaleTags'
  | 'performance'
  | 'closeTime'
> & { topic?: Maybe<Pick<Topic, 'commentCount'>> } & Pick<Instrument, 'name' | 'ticker' | 'logoUrl'> & {
    instrumentId?: string;
  } & Pick<UserProfile, 'nickname'> & {
    snapshot: 'ideaInstrument' | 'instrument';
    onPress?: () => void;
    /**
     * Override View Comments link. (e,g to trigger sheet instead of navigation if pressed in detail screen)
     */
    onPressViewComments?: () => void;
  };

export const IdeaCard: React.FC<Props> = ({
  position,
  status,
  targetDate,
  targetPrice,
  referenceDate,
  referencePrice,
  rationaleTags,
  logoUrl,
  name,
  ticker,
  nickname,
  snapshot,
  instrumentId,
  topic,
  ideaId,
  performance,
  closeTime,
  onPress,
  onPressViewComments,
}) => {
  const tailwind = useTailwind();
  const [now] = React.useState(new Date());
  return (
    <OutlineInnerCard onPress={onPress} style={tailwind('bg-white')} innerStyle={tailwind('px-0 py-0')}>
      <Snapshot
        position={position}
        snapshot={snapshot}
        ticker={ticker}
        nickname={nickname}
        logoUrl={logoUrl}
        name={name}
        instrumentId={instrumentId}
      />
      <IdeaSnapshot
        referenceDate={referenceDate}
        referencePrice={referencePrice}
        targetDate={targetDate}
        targetPrice={targetPrice}
        performance={performance}
      />
      <Timeline
        referenceDate={referenceDate}
        targetDate={targetDate}
        position={position}
        status={status}
        closeTime={closeTime}
        today={now}
      />
      {rationaleTags && (
        <View style={tailwind('pr-2')}>
          <View style={tailwind('flex-row py-1 pl-2 overflow-hidden')}>
            {rationaleTags.slice(0, 5).map((tag) => (
              <View style={tailwind('p-1')} key={tag?.id}>
                <Chip lowContrast textSize="xs" size="xs" text={tag?.name ?? ''} />
              </View>
            ))}
          </View>
          <LinearGradient
            start={{ x: 0.9, y: 0 }}
            end={{ x: 1, y: 0 }}
            colors={['rgba(255,255,255,0)', 'rgb(255,255,255)']}
            style={[
              tailwind('absolute h-full w-full top-0 pr-1 flex-grow'),
              // Web doesn't support start and end points so use fixed dimensions
              Platform.OS === 'web' && tailwind('w-16 self-end'),
            ]}
          />
        </View>
      )}
      <ViewCommentLink
        onPressViewCommentLink={onPressViewComments}
        commentCount={topic?.commentCount}
        ideaId={ideaId}
        style={tailwind('pt-1 pb-3')}
      />
    </OutlineInnerCard>
  );
};

type SnapshotProps = Pick<
  Props,
  'position' | 'targetDate' | 'snapshot' | 'ticker' | 'nickname' | 'logoUrl' | 'name' | 'instrumentId' | 'status'
>;

const Snapshot: React.FC<SnapshotProps> = ({ snapshot, position, name, nickname, logoUrl, ticker, instrumentId }) => {
  switch (snapshot) {
    case 'ideaInstrument':
      return (
        <IdeaInstrumentSnapshot position={position} name={name} ticker={ticker} nickname={nickname} logoUrl={logoUrl} />
      );
    case 'instrument':
      return (
        <InstrumentSnapshot
          position={position}
          name={name}
          ticker={ticker}
          logoUrl={logoUrl}
          instrumentId={instrumentId}
        />
      );
  }
};

export const IdeaCardSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return <Skeleton style={tailwind('w-full h-32')} />;
};
