import { FC } from 'react';
import { formatPercent } from '../../util/number';
import { BadgeColor, PortfolioStatistic } from '../PortfolioStatistic';

type Props = {
  volatility: number;
};

export const Volatility: FC<Props> = ({ volatility }) => {
  const badgeProps = getBadgeProperties(volatility);
  return (
    <PortfolioStatistic
      valueText={formatPercent(volatility, 0, false)}
      descriptionText="The up-and-down change in the value of your portfolio during a given period. The lower a portfolio's volatility, the lower the risk. With higher volatility comes higher risk  but also offers a potentially greater reward."
      {...badgeProps}
    />
  );
};

function getBadgeProperties(volatility: number): { badgeText: 'LOW' | 'MEDIUM' | 'HIGH'; badgeColor: BadgeColor } {
  if (volatility < 0.2) {
    return { badgeText: 'LOW', badgeColor: 'GREEN' };
  } else if (volatility < 0.3) {
    return { badgeText: 'MEDIUM', badgeColor: 'YELLOW' };
  } else {
    return { badgeText: 'HIGH', badgeColor: 'RED' };
  }
}
