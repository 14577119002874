import React from 'react';
import { ClosePrice, Maybe } from '../../generated/graphql';
import { parseUTCDateFromString } from '../../util/date';
import { TimeInterval } from '../../util/string';
import { isNotNullKeys } from '../../util/typeGuards';
import { IntervalChart } from '../Charts';

type Props = {
  closePriceSeries: Maybe<Pick<ClosePrice, 'price' | 'date'>>[];
  currentPrice?: { price: number; date: Date };
  currencyIso?: string;
  now: Date;
  initialInterval?: TimeInterval;
};

export const InstrumentChart: React.VFC<Props> = ({ closePriceSeries, currentPrice, currencyIso, initialInterval }) => {
  // filter nulls and convert from YYYY-MM-DD to date object
  const prices = React.useMemo(() => {
    const series = closePriceSeries
      ?.filter(isNotNullKeys)
      .map((p) => ({ price: p.price, date: parseUTCDateFromString(p.date) }));
    const lastClosePrice = series.length > 0 ? series[series.length - 1] : undefined;
    if (
      lastClosePrice &&
      currentPrice &&
      currentPrice?.date > lastClosePrice?.date &&
      currentPrice.date.getUTCDate() !== lastClosePrice.date.getUTCDate()
    ) {
      series.push(currentPrice);
    }
    return series.map((p): [number, number] => [p.date.getTime(), p.price]);
  }, [closePriceSeries, currentPrice]);

  return prices.length > 0 ? (
    <IntervalChart
      prices={prices}
      currencyIso={currencyIso}
      currentPrice={currentPrice}
      initialInterval={initialInterval}
    />
  ) : null;
};
