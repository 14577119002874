import { View } from 'react-native';
import { RectButton } from 'react-native-gesture-handler';
import { useRedirectPress } from '../../hooks/useRedirectPress';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { ArrowLeft } from '../icons';

export type Props = {
  text: string;
  hideArrow?: boolean;
  onPress?: () => void;
};

export const SideScrollHeader: React.FC<Props> = ({ text, hideArrow, onPress }) => {
  const tailwind = useTailwind();
  const { handlePress } = useRedirectPress({
    trackingType: 'button',
    redirectIfLoggedOut: false,
    onPress,
    accessibilityLabel: text,
  });

  return (
    <RectButton
      rippleColor={colors.teal['100']}
      underlayColor={colors.teal['700']}
      onPress={handlePress}
      enabled={!hideArrow}
    >
      <View style={tailwind('flex-row justify-between items-center px-6 py-2')} accessibilityRole="button">
        <Text style={tailwind('text-lg font-semibold text-warmGray-800 ')}>{text}</Text>
        {!hideArrow && <ArrowLeft style={{ transform: [{ rotate: '180deg' }] }} focusable={false} />}
      </View>
    </RectButton>
  );
};
