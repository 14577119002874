export type PortfolioWeighting = { base: number; bundles: number; stocks: number };
export type PortfolioWeightings = {
  lowRisk: PortfolioWeighting;
  mediumRisk: PortfolioWeighting;
  highRisk: PortfolioWeighting;
};

/**
 * Risk level used to map weightings and SNAP_POINTS for the slider
 */
export type RiskLevel = 'low' | 'medium' | 'high';

/**
 * Portfolio risk level weightings hardcoded on the FE
 */
export const PORTFOLIO_WEIGHTINGS: Record<RiskLevel, PortfolioWeighting> = {
  low: { base: 0.7, bundles: 0.2, stocks: 0.1 },
  medium: { base: 0.55, bundles: 0.3, stocks: 0.15 },
  high: { base: 0.4, bundles: 0.4, stocks: 0.2 },
} as const;

export const STOCK_SLOTS = 3;
export const BUNDLE_SLOTS = 2;
