import { FC } from 'react';
import { TextProps } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const WizardHeadingText: FC<{ style?: TextProps['style'] }> = ({ children, style }) => {
  const tailwind = useTailwind();
  return <Text style={[tailwind('text-warmGray-800 text-lg pb-2'), style]}>{children}</Text>;
};

export const WizardBodyText: FC<{ style?: TextProps['style'] }> = ({ children, style }) => {
  const tailwind = useTailwind();
  return <Text style={[tailwind('text-warmGray-700 text-sm'), style]}>{children}</Text>;
};
