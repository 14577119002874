import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export const Camera = ({ hideCircle, ...props }: SvgProps & { hideCircle?: boolean }) => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    {!hideCircle && <Circle cx={10} cy={10} r={10} fill="#D6D3D1" />}
    <Path
      d="M14.8 15H5.2c-.318 0-.623-.124-.849-.345A1.165 1.165 0 0 1 4 13.823V7.941c0-.312.126-.611.351-.832.226-.22.53-.344.849-.344H7L7.9 5h4.2l.9 1.765h1.8c.318 0 .623.124.848.344.226.22.352.52.352.832v5.882c0 .313-.126.612-.351.832-.225.221-.53.345-.849.345Z"
      fill="#44403C"
      stroke="#44403C"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <Path
      d="M10 12.647c1.16 0 2.1-.922 2.1-2.059S11.16 8.53 10 8.53c-1.16 0-2.1.921-2.1 2.058 0 1.137.94 2.06 2.1 2.06Z"
      fill="#44403C"
      stroke="#D6D3D1"
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);
