import { gql, NetworkStatus } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { capitalize } from 'lodash';
import { useCallback, useState, VFC } from 'react';
import { FlatList, ListRenderItemInfo, View } from 'react-native';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import { CORE_PAGINATION_FIELDS } from '../../fragments/pageInfo';
import { CoreIdeaFieldsFragment, useGetUserIdeasQuery } from '../../generated/graphql';
import { useFetchMore } from '../../hooks/useFetchMore';
import { useRefresh } from '../../hooks/useRefresh';
import { FooterSpinner } from '../../old/FooterSpinner';
import { FilterValue, filterValueToInputMap, IdeaFilters } from '../../old/IdeaFilters';
import { IdeaFlatListItem } from '../../old/IdeaFlatListItem';
import { IdeasSkeleton } from '../../old/IdeasSkeleton';
import { Link } from '../../old/Link';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { IdeasNavigationProps } from './Ideas';

export const USER_IDEAS = gql`
  ${CORE_PAGINATION_FIELDS}
  ${CORE_IDEA_FIELDS}
  query getUserIdeas($statuses: [IdeaStatus!], $cursor: NonEmptyString) {
    ideasPaginated(filter: { statuses: $statuses }, pagination: { cursor: $cursor, limit: 15 }) {
      nodes {
        ...CoreIdeaFields
      }
      pageInfo {
        ...CorePaginationFields
      }
    }
  }
`;

export const MyIdeas: VFC = () => {
  const tailwind = useTailwind();
  const [selectedFilter, setSelectedFilter] = useState<FilterValue>('Active');
  const selectedStatuses = filterValueToInputMap[selectedFilter];

  const { data, fetchMore, refetch, networkStatus, error } = useGetUserIdeasQuery({
    variables: { statuses: selectedStatuses },
  });

  if (error) {
    throw error;
  }

  const ideas = data?.ideasPaginated.nodes ?? [];
  const nextCursor = data?.ideasPaginated?.pageInfo?.nextCursor;
  const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;

  const { refreshing, onRefresh } = useRefresh(refetch);
  const { fetchingMore, onFetchMore } = useFetchMore(fetchMore);

  const onEndReached = useCallback(() => {
    if (!nextCursor) return;
    onFetchMore({ variables: { cursor: nextCursor } });
  }, [onFetchMore, nextCursor]);

  return (
    <>
      <View style={tailwind('pt-4 pb-2')}>
        <IdeaFilters value={selectedFilter} onChange={(v) => setSelectedFilter(v)} />
      </View>
      <FlatList
        ListEmptyComponent={loading ? <IdeasSkeleton /> : <EmptyView selectedFilter={selectedFilter} />}
        contentContainerStyle={[
          tailwind('px-6 pb-4'),
          ideas.length === 0 && tailwind('flex-grow items-center justify-center'),
        ]}
        refreshing={refreshing}
        data={ideas}
        renderItem={renderItem}
        onRefresh={() => onRefresh({ statuses: selectedStatuses })}
        keyExtractor={keyExtractor}
        onEndReached={onEndReached}
        ListFooterComponent={fetchingMore ? <FooterSpinner /> : undefined}
      />
    </>
  );
};

const renderItem = ({ item }: ListRenderItemInfo<CoreIdeaFieldsFragment>) => <IdeaFlatListItem {...item} />;
const keyExtractor = (item: CoreIdeaFieldsFragment) => item.id;

const EmptyView: React.VFC<{ selectedFilter: FilterValue }> = ({ selectedFilter }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<IdeasNavigationProps>();
  return (
    <View style={tailwind('flex-1 items-center justify-center')}>
      <Text style={tailwind('text-warmGray-500 text-lg pb-2')}>
        You currently have no {capitalize(selectedFilter)} Ideas.
      </Text>
      {selectedFilter === 'Active' && (
        <Link style={tailwind('text-sm')} onPress={() => navigation.navigate('WatchlistTab', { screen: 'Watchlist' })}>
          Create one from your Watchlist
        </Link>
      )}
    </View>
  );
};
