import { ListRenderItem } from 'react-native';
import { Comment as CommentData } from '../../generated/graphql';
import { Comment } from '../../old/Comment';

export type CommentItem = Pick<CommentData, 'id' | 'text' | 'user' | 'replyCount' | 'created'> & {
  onUserProfilePress: (userId: number) => void;
  onReplyPress?: (commentId: string) => void;
  onFlairPress: (ideaId: string) => void;
  showRepliesLink: boolean;
};

export const renderComment: ListRenderItem<CommentItem> = ({
  item: { text, user, replyCount, created, id, showRepliesLink, onReplyPress, onUserProfilePress, onFlairPress },
}) => {
  const activeIdea = user?.ideas?.connection?.nodes?.[0];

  return (
    <Comment
      text={text}
      user={user}
      replyCount={replyCount}
      created={created}
      showRepliesLink={showRepliesLink}
      onUserProfilePress={() => onUserProfilePress(user?.id ?? 0)}
      onReplyPress={() => onReplyPress?.(id)}
      activeIdea={activeIdea}
      onFlairPress={() => onFlairPress?.(activeIdea?.id ?? '')}
    />
  );
};
