import { WebImage } from './WebImage';

/** Safari does not support some of the SVG properties, just use PNG for now */
export const LayersBundlesColoured = () => (
  <WebImage
    imgSrc={require('../../assets/images/LayersBundlesColoured.png')}
    imgWidth={484}
    imgHeight={227}
    percentageScreenWidth={0.6}
  />
);
