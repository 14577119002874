export type Ticks = {
  low: number;
  high: number;
  step: number;
};
export const getTicks = (lowest: number, highest: number, steps: number): Ticks => {
  steps = Math.max(1, Math.round(steps));
  if (highest <= lowest) {
    throw new Error(`highest ${highest} must be greater than lowest ${lowest}`);
  }
  let powerOf10 = 0;

  // Divide the steps by 10 until the tick range does not cover the lowest and highest numbers
  while (true) {
    const step = STEPS[0] * Math.pow(10, powerOf10);
    const floor = floorToInterval(lowest, step);
    if (floor + step * (steps - 1) >= highest) {
      powerOf10--;
    } else {
      break;
    }
  }

  // Multiply the steps by 10 until the tick range covers the lowest and highest numbers
  while (true) {
    const step = STEPS[STEPS.length - 1] * Math.pow(10, powerOf10);
    const floor = floorToInterval(lowest, step);
    if (floor + step * (steps - 1) < highest) {
      powerOf10++;
    } else {
      break;
    }
  }

  let lo = 0;
  let hi = STEPS.length - 1;
  let floorTick = 0;
  let intervalIndex = 0;
  while (lo <= hi) {
    const mid = Math.floor((lo + hi) / 2);
    const step = STEPS[mid] * Math.pow(10, powerOf10);
    const floor = floorToInterval(lowest, step);
    if (floor + step * (steps - 1) >= highest) {
      intervalIndex = mid;
      floorTick = floor;
      hi = mid - 1;
    } else {
      lo = mid + 1;
    }
  }

  const step = STEPS[intervalIndex] * Math.pow(10, powerOf10);
  return {
    low: floorTick,
    high: floorTick + step * (steps - 1),
    step: step,
  };
};

const STEPS = [1, 2, 2.5, 5, 10];

/**
 * Multiplying and dividing by 1000 are necessary here because the interval may be a floating point number which can lead to arithmetic errors.
 * Since the interval is a most 3 decimal places, multiplying by 1000 ensures that the arithmetic is done with integers
 */
const floorToInterval = (x: number, interval: number): number => (1000 * interval * Math.floor(x / interval)) / 1000;
