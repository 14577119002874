import { FC } from 'react';
import { View } from 'react-native';
import { ArrowRight } from '../../../old/icons';
import { colors, useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';

type Props = {
  title: string;
  body: string;
  color: string;
  onPress: () => void;
  width: number;
  label?: string;
};

export const OnboardingCard: FC<Props> = ({ body, onPress, title, label, color, width }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      style={[
        tailwind('py-3 px-3 rounded-lg items-center flex-wrap flex-row'),
        { backgroundColor: color, width: width },
      ]}
    >
      <View style={tailwind('px-2 flex-1')}>
        <Text style={tailwind('pb-1 font-semibold text-white text-sm')}>{title}</Text>
        <Text style={tailwind('text-xs  text-white')}>{body}</Text>
        {label && (
          <Text style={{ ...tailwind('text-xs font-light self-end bg-white p-2 rounded-full'), color }}>
            {label.toUpperCase()}
          </Text>
        )}
      </View>

      <ArrowRight color={colors.white} height={24} width={24} />
    </Pressable>
  );
};

export const OnboardingBodyText: FC = ({ children }) => {
  const tailwind = useTailwind();
  return <Text style={tailwind('pb-6 text-white text-xs text-center')}>{children}</Text>;
};
