import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useEffect, useState, VFC } from 'react';
import { Image, RefreshControl, ScrollView, useWindowDimensions, View } from 'react-native';
import { layout } from '../../constants/layout';
import { useGetAppContentQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { ArrowRight } from '../../old/icons';
import { NewsSkeleton } from '../../old/NewsSkeleton';
import { TitleBar } from '../../old/TitleBar';
import { visitLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { Markdown } from '../../ui/Markdown';
import { useMarkdownStyle } from '../../ui/Markdown/useMarkdownStyle';
import { DESKTOP_MAX_WIDTH, SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export const GET_APP_CONTENT = gql`
  query getAppContent($id: ID!, $imgixWidth: Int) {
    datoAppContent(id: $id, markdown: false, imgixParams: { width: $imgixWidth }) {
      appContent {
        id
        title
        description
        actionButtonText
        actionButtonDeepLink
        publishedDate
        coverImage {
          url
        }
      }
    }
  }
`;

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'AppContent'>;

export const AppContent: VFC<Props> = withReloadErrorBoundary(
  ({
    route: {
      params: { appContentId },
    },
  }) => {
    const markdownStyle = useMarkdownStyle();
    const tailwind = useTailwind();
    const { width } = useWindowDimensions();
    const {
      data,
      loading: dataLoading,
      refetch,
      error,
    } = useGetAppContentQuery({
      variables: {
        id: appContentId,
        imgixWidth: Math.floor(Math.min(width, DESKTOP_MAX_WIDTH) * layout.pixelRatio),
      },
    });

    const { refreshing, onRefresh } = useRefresh(refetch);
    const [imageDimensions, setImageDimensions] = useState({
      width: Math.min(width, DESKTOP_MAX_WIDTH),
      height: 0,
    });
    const [imageLoaded, setImageLoaded] = useState(false);

    if (error) {
      throw error;
    }

    // Image renders with height 0 unless height specified. Set height style to height of image received once loaded.
    useEffect(() => {
      if (data) {
        Image.getSize(data?.datoAppContent?.appContent?.coverImage?.url ?? '', (width, height) => {
          setImageDimensions({ width: width / layout.pixelRatio, height: height / layout.pixelRatio });
          setImageLoaded(true);
        });
      }
    }, [data]);

    const loading = dataLoading || !imageLoaded;

    return (
      <SafeAreaView>
        <TitleBar />
        {loading ? (
          <NewsSkeleton />
        ) : (
          <ScrollView
            contentContainerStyle={tailwind('pt-4 pb-6')}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          >
            <Text style={{ ...tailwind('px-6 pb-4 text-center'), ...markdownStyle.heading1 }}>
              {data?.datoAppContent?.appContent?.title}
            </Text>
            <View style={tailwind('pt-2 pb-4')}>
              <Image
                accessibilityIgnoresInvertColors
                source={{ uri: data?.datoAppContent?.appContent?.coverImage?.url ?? '' }}
                style={imageDimensions}
                resizeMode="contain"
              />
            </View>

            <View style={tailwind('px-6 pt-2')}>
              <Markdown>{data?.datoAppContent?.appContent?.description ?? ''}</Markdown>
              <View style={tailwind('py-2')} />
              <Button
                onPress={() => visitLink(data?.datoAppContent?.appContent?.actionButtonDeepLink ?? '')}
                text={data?.datoAppContent?.appContent?.actionButtonText ?? ''}
                EndIcon={ArrowRight}
              />
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  },
);
