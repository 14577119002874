import AsyncStorage from '@react-native-async-storage/async-storage';
import { StateStorage } from 'zustand/middleware';

export const storage: StateStorage = {
  getItem: async (key: string): Promise<string | null> => {
    const data = await AsyncStorage.getItem(key);
    if (!data) return null;
    return await JSON.parse(data);
  },
  setItem: async (key: string, value: string) => {
    const data = await JSON.stringify(value);
    return await AsyncStorage.setItem(key, data);
  },
};
