import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const VerticalDots = (props: SvgProps) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M8 9a1 1 0 100-2 1 1 0 000 2zM8 4a1 1 0 100-2 1 1 0 000 2zM8 14a1 1 0 100-2 1 1 0 000 2z"
        fill="#A3A3A3"
      />
    </Svg>
  );
};
