import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { CoreIdeaFieldsFragment } from '../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { IdeaCard } from '../IdeaCard';

export const IdeaFlatListItem: React.FC<CoreIdeaFieldsFragment> = (item) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  return (
    <View accessibilityLabel={`${item.status} Idea on ${item.instrument?.ticker ?? ''}`} style={tailwind('my-2 px-2')}>
      <IdeaCard
        position={item.position}
        status={item.status}
        closeTime={item.closeTime}
        referenceDate={item?.referenceDate}
        targetDate={item?.targetDate}
        referencePrice={item?.referencePrice}
        targetPrice={item?.targetPrice}
        rationaleTags={item?.rationaleTags}
        name={item?.instrument?.name}
        ticker={item?.instrument?.ticker}
        nickname={item?.user?.nickname}
        logoUrl={item?.instrument?.logoUrl}
        topic={item.topic}
        performance={item.performance}
        snapshot="ideaInstrument"
        onPress={() => {
          navigation.push('Idea', { ideaId: item.id });
        }}
        ideaId={item.id}
      />
    </View>
  );
};
