import { FC } from 'react';
import { formatNumber } from '../../util/number';
import { BadgeColor, PortfolioStatistic } from '../PortfolioStatistic';

type Props = {
  correlation: number;
};

const descriptionText =
  'A measure of how your stocks move in relation to one another. A low correlation means your stocks generally move in different directions, indicating a diversified portfolio and mitigating risk.';

export const Correlation: FC<Props> = ({ correlation }) => {
  const badgeProps = getBadgeProperties(correlation);
  return (
    <PortfolioStatistic valueText={formatNumber(correlation, 1)} descriptionText={descriptionText} {...badgeProps} />
  );
};

function getBadgeProperties(correlation: number): { badgeText: 'LOW' | 'MEDIUM' | 'HIGH'; badgeColor: BadgeColor } {
  if (correlation <= 0.1) {
    return { badgeText: 'LOW', badgeColor: 'GREEN' };
  } else if (correlation <= 0.4) {
    return { badgeText: 'MEDIUM', badgeColor: 'YELLOW' };
  } else {
    return { badgeText: 'HIGH', badgeColor: 'RED' };
  }
}
