import React from 'react';
import { StyleProp, View, ViewProps } from 'react-native';
import { Idea, IdeaPerformance, Instrument, Maybe, QuotePrice } from '../../generated/graphql';
import { colors, shadowMd, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { formatNumber, getPercentageDiff } from '../../util/number';
import { isFiniteNumber } from '../../util/typeGuards';
import { Tick } from '../icons';
import { IdeaPriceDelta } from '../IdeaPriceDelta';
import { InstrumentLogo } from '../InstrumentLogo';

export type Props = {
  selected?: boolean;
  onPress?: (ids: { ideaId: string; instrumentId: string }) => void;
  bottomRightView?: 'conviction' | 'actualReturn';
  style?: StyleProp<ViewProps>;
} & Pick<Idea, 'status' | 'position' | 'referencePrice' | 'referenceDate' | 'targetPrice' | 'conviction' | 'id'> & {
    instrument?: Maybe<
      Pick<Instrument, 'id' | 'name' | 'ticker' | 'logoUrl'> & {
        quotePrice?: Maybe<
          { __typename?: 'QuotePrice' } & Pick<QuotePrice, 'midPrice' | 'previousClose' | 'pctChange1d'>
        >;
      }
    >;
    performance?: Maybe<Pick<IdeaPerformance, 'priceChangeSincePosted'>>;
  };

/**
 * nice idea snippet row. Pressable to navigate to idea.
 *
 * Will redirect if logged out
 */
export const IdeaSnippetRow: React.VFC<Props> = ({
  id,
  performance,
  referenceDate,
  referencePrice,
  targetPrice,
  conviction,
  instrument,
  selected,
  onPress,
  bottomRightView = 'conviction',
  style,
}) => {
  const tailwind = useTailwind();
  const predictedChange = getPercentageDiff(referencePrice ?? 0, targetPrice ?? 0);
  const convictionText = isFiniteNumber(conviction) ? `${formatNumber(deserializeConviction(conviction), 0)}/5` : 'N/A';

  return (
    <View style={[tailwind('px-1'), style]}>
      <Pressable
        redirectIfLoggedOut
        onPress={onPress ? () => onPress({ ideaId: id ?? '', instrumentId: instrument?.id ?? '' }) : undefined}
        accessibilityRole="checkbox"
        accessibilityState={{ selected }}
        accessibilityLabel={instrument?.name ?? ''}
        style={[
          tailwind('flex-row justify-between p-4 rounded-lg'),
          // Apply 1px margin when not selected to avoid content jump once selected border applied
          { margin: selected ? 0 : 1 },
          shadowMd,
          selected && tailwind('bg-amber-50 border border-amber-400'),
        ]}
      >
        <View style={tailwind('flex-row flex-shrink')}>
          {selected ? (
            <IdeaSelectedTick />
          ) : (
            <InstrumentLogo logoUrl={instrument?.logoUrl ?? undefined} ticker={instrument?.ticker ?? ''} size="small" />
          )}
          <View style={tailwind('flex-shrink ml-2')}>
            <View>
              <Text style={tailwind('text-sm font-medium text-warmGray-800')} numberOfLines={1}>
                {instrument?.name}
              </Text>
            </View>
            <View style={tailwind('flex-row items-center')}>
              <Text style={tailwind('text-xs text-warmGray-500')} numberOfLines={1}>
                {referenceDate ? formatDate(referenceDate, 'DD MMM YYYY') : ''}
              </Text>
            </View>
          </View>
        </View>
        <View style={tailwind('items-end justify-center ml-2')}>
          <View accessibilityLabel={'Predicted change'} style={tailwind('flex-row pt-1')}>
            <Text style={tailwind('text-xs text-warmGray-500 w-16')}>Predicted</Text>
            <View style={tailwind('w-16')}>
              <IdeaPriceDelta delta={predictedChange} size="sm" />
            </View>
          </View>
          <View
            accessibilityLabel={bottomRightView === 'actualReturn' ? 'Actual change' : 'Conviction'}
            style={tailwind('flex-row')}
          >
            <Text style={tailwind('text-xs text-warmGray-500 w-16')}>
              {bottomRightView === 'conviction' ? 'Conviction' : 'Actual'}
            </Text>
            <View style={tailwind('w-16')}>
              {bottomRightView === 'conviction' ? (
                <Text style={tailwind('text-xs text-warmGray-500 text-center')}>{convictionText}</Text>
              ) : (
                <IdeaPriceDelta delta={performance?.priceChangeSincePosted ?? 0} size="sm" />
              )}
            </View>
          </View>
        </View>
      </Pressable>
    </View>
  );
};

/** Converts conviction from a value out of 100 to a value out of 5 */
const deserializeConviction = (conviction: number) => (conviction / 100) * 5;

const IdeaSelectedTick: React.VFC = () => {
  const tailwind = useTailwind();
  return (
    <View accessibilityLabel="Tick" style={tailwind('h-9 w-9 rounded-xl bg-teal-700 items-center justify-center')}>
      <Tick fill={colors.white} />
    </View>
  );
};
