import React from 'react';
import { colors, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { ArrowDown, ArrowUp } from '../icons';

type Sentiment = 'bullish' | 'bearish';

export type Props = {
  sentiment: Sentiment;
  selected: boolean;
  onPress?: () => void;
};

export const SentimentChip: React.VFC<Props> = ({ selected, sentiment, onPress }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      accessibilityLabel={sentiment}
      style={tailwind(
        `h-14 rounded-2xl flex-row justify-center items-center border border-solid ${getContainerStyles(
          sentiment,
          selected,
        )}`,
      )}
      onPress={onPress}
    >
      <Arrow selected={selected} sentiment={sentiment} />
      <Text style={[tailwind('ml-3 font-medium text-lg'), tailwind(getTextStyles(sentiment, selected))]}>
        {sentimentText(sentiment)}
      </Text>
    </Pressable>
  );
};

const sentimentText = (sentiment: Sentiment): string => {
  switch (sentiment) {
    case 'bullish':
      return 'Bullish';
    case 'bearish':
      return 'Bearish';
  }
};

const getTextStyles = (sentiment: Sentiment, selected: boolean): string => {
  if (selected) {
    return 'text-white';
  }
  switch (sentiment) {
    case 'bullish':
      return 'text-blue-500';
    case 'bearish':
      return 'text-fuchsia-600';
  }
};

const getContainerStyles = (sentiment: Sentiment, selected: boolean): string => {
  switch (sentiment) {
    case 'bullish':
      return `border-blue-500 ${selected ? 'bg-blue-500' : ''}`;
    case 'bearish':
      return `border-fuchsia-600 ${selected ? 'bg-fuchsia-600' : ''}`;
  }
};

const Arrow: React.VFC<{ sentiment: Sentiment; selected: boolean }> = ({ sentiment, selected }) => {
  switch (sentiment) {
    case 'bullish':
      return <ArrowUp width={18} height={18} color={selected ? colors.white : colors.blue['500']} />;
    case 'bearish':
      return <ArrowDown width={18} height={18} color={selected ? colors.white : colors.fuchsia['600']} />;
  }
};
