import { GetOnboardingSummaryQuery, TradingPortfolioStatus } from '../../generated/graphql';
import { isNullish } from '../../util/typeGuards';

export type TradingOnboardingState =
  | 'NEEDS_PARTY'
  | 'NEEDS_BANK_ACCOUNT'
  | 'NEEDS_ADDRESS'
  | 'NEEDS_SUBMISSION'
  | 'KYC_PENDING'
  | 'KYC_FAILED'
  | 'COMPLETE';

/**
 * Util to determine the user's Trading Onboarding state
 *
 * @param party Trading party with properties from which to infer what stage in onboarding the user is at
 * @param myPortfolios Portfolio with status from which we determine KYC result
 * @returns Onboarding state
 */
export function getTradingOnboardingState(
  party: GetOnboardingSummaryQuery['myTradingParty']['party'],
  myPortfolios: GetOnboardingSummaryQuery['myPortfolios']['portfolios'],
): TradingOnboardingState {
  const myPortfolio = myPortfolios?.[0];
  const portfolioStatus = myPortfolio?.status;
  switch (true) {
    case !party?.id:
      return 'NEEDS_PARTY';
    case !party?.addresses?.length:
      return 'NEEDS_ADDRESS';
    case !party?.bankAccounts?.length:
      return 'NEEDS_BANK_ACCOUNT';
    case isNullish(myPortfolio):
      return 'NEEDS_SUBMISSION';
    case portfolioStatus === TradingPortfolioStatus.Pending:
      return 'KYC_PENDING';
    case portfolioStatus === TradingPortfolioStatus.Suspended:
      return 'KYC_FAILED';
    default:
      return 'COMPLETE';
  }
}
