import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const ChevronRight: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M5 1l7 7-7 7"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
