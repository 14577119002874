/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdeaWatchlistItem } from '../models/IdeaWatchlistItem';
import type { IdeaWatchlistItemCount } from '../models/IdeaWatchlistItemCount';
import { request as __request } from '../core/request';

export class IdeaWatchlistService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiIdeaWatchlistList({
        ideaIds,
        cursor,
        pageSize,
    }: {
        /** Multiple values may be separated by commas. **/
        ideaIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<IdeaWatchlistItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-watchlist/`,
            query: {
                'idea_ids': ideaIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * Set the user of the watchlist item to the current user and checks that the idea doesn't already have
     * a watchlist item.
     * @returns IdeaWatchlistItem
     * @throws ApiError
     */
    public static async apiIdeaWatchlistCreate({
        data,
    }: {
        data: IdeaWatchlistItem,
    }): Promise<IdeaWatchlistItem> {
        const result = await __request({
            method: 'POST',
            path: `/api/idea-watchlist/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaWatchlistItemCount
     * @throws ApiError
     */
    public static async apiIdeaWatchlistCount({
        ideaIds,
        cursor,
        pageSize,
    }: {
        /** Multiple values may be separated by commas. **/
        ideaIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<IdeaWatchlistItemCount> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-watchlist/count/`,
            query: {
                'idea_ids': ideaIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns IdeaWatchlistItem
     * @throws ApiError
     */
    public static async apiIdeaWatchlistRead({
        uuid,
    }: {
        /** A UUID string identifying this idea watchlist item. **/
        uuid: string,
    }): Promise<IdeaWatchlistItem> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-watchlist/${uuid}/`,
        });
        return result.body;
    }

    /**
     * Deactivates the watchlist item.
     * @returns void
     * @throws ApiError
     */
    public static async apiIdeaWatchlistDelete({
        uuid,
    }: {
        /** A UUID string identifying this idea watchlist item. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/idea-watchlist/${uuid}/`,
        });
        return result.body;
    }

}
