import { gql } from '@apollo/client';

export const STACK_FIELDS = gql`
  fragment StackFields on Stack {
    id
    content {
      name
      description
      coverImage {
        url
      }
    }
    components {
      pageInfo {
        totalCount
      }
      nodes {
        id
        weight
        instrument {
          id
          ticker
          displayName
          logoUrl
        }
      }
    }
  }
`;
