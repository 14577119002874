import { Formik, FormikHelpers } from 'formik';
import React from 'react';
import { ActivityIndicator, Keyboard, Platform, StyleProp, StyleSheet, TextInput, View, ViewStyle } from 'react-native';
import * as yup from 'yup';
import { AppEntityType, PostCommentPayload } from '../../generated/graphql';
import { usePostIdeaComment } from '../../hooks/mutations/usePostIdeaComment';
import { IconButton } from '../../old/Button/IconButton';
import { SendArrow } from '../../old/icons';
import { colors, useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';

type Props = {
  parentCommentId?: string;
  entityType: AppEntityType;
  entityId: string;
  dismissHandler: () => void;
  onPostSuccessHandler: (id: string | undefined) => void;
  style?: StyleProp<ViewStyle>;
  visible: boolean;
};

export const Input: React.VFC<Props> = ({
  style,
  entityId,
  entityType,
  parentCommentId,
  dismissHandler,
  onPostSuccessHandler,
  visible,
}) => {
  const tailwind = useTailwind();
  const commentType = parentCommentId ? 'reply' : 'comment';

  const [postIdeaComment] = usePostIdeaComment(entityId);

  const onSubmit = async (
    { text }: yup.InferType<typeof commentSchema>,
    helpers: FormikHelpers<yup.InferType<typeof commentSchema>>,
  ) => {
    await postIdeaComment({
      variables: {
        ideaId: entityId, // assuming for now all comment bottom sheets are on an Idea screen, hence entityId is ideaId for GQL query
        appEntityType: entityType,
        parent: parentCommentId,
        text,
      },
      onCompleted: (data) => {
        const error = data.postComment?.error;
        if (error) {
          helpers.setErrors({ text: errorMessage(error) });
        } else {
          Keyboard.dismiss();
          onPostSuccessHandler(data.postComment?.comment?.id);
        }
      },
    });
  };
  return visible ? (
    <View style={[StyleSheet.absoluteFill, tailwind('flex-1 justify-between')]}>
      <KeyboardAvoidingView keyboardVerticalOffset={0}>
        <Pressable
          accessibilityRole="button"
          style={[tailwind('bg-warmGray-900 opacity-60 flex-grow')]}
          onPress={dismissHandler}
        />
        <View style={[tailwind('bg-white'), style]}>
          <Formik validationSchema={commentSchema} onSubmit={onSubmit} initialValues={{ text: '' }}>
            {({ values, isSubmitting, isValid, handleSubmit, handleChange, errors }) => (
              <>
                <View style={[tailwind('flex-row items-center')]}>
                  <TextInput
                    accessibilityLabel={`Add a ${commentType}`}
                    multiline
                    placeholder={`Add a ${commentType}`}
                    underlineColorAndroid="transparent"
                    style={[
                      tailwind(inputStyles),
                      {
                        fontSize: 16,
                        paddingVertical: 4,
                      },
                    ]}
                    value={values.text}
                    onChangeText={handleChange('text')}
                    autoFocus={true}
                  />
                  {isSubmitting ? (
                    <ActivityIndicator
                      style={tailwind('justify-center mx-2')}
                      color={colors.teal['700']}
                      size={Platform.OS === 'android' ? 32 : undefined}
                      animating={true}
                    />
                  ) : (
                    <IconButton
                      accessibilityLabel={`post ${commentType}`}
                      style={tailwind('justify-center m-3')}
                      onPress={handleSubmit}
                      disabled={!values.text}
                      icon={
                        <SendArrow color={isValid && !!values.text ? colors.teal['700'] : colors.warmGray['300']} />
                      }
                    />
                  )}
                </View>
                {!!values.text && errors.text && (
                  <Text style={tailwind('text-sm text-red-500 font-normal px-2 pb-3')}>{errors.text}</Text>
                )}
              </>
            )}
          </Formik>
        </View>
      </KeyboardAvoidingView>
    </View>
  ) : null;
};

const commentSchema = yup.object({
  text: yup.string().trim().required().min(1, 'Comment cannot be empty').max(10000, 'Maximum 10000 characters'),
});

export const InputArea: React.VFC<{ onPress: () => void; type: 'comment' | 'reply' }> = ({ onPress, type }) => {
  const tailwind = useTailwind();
  return (
    <Pressable accessibilityRole="button" onPress={onPress}>
      <TextInput
        accessibilityLabel="Text input field"
        focusable={false}
        pointerEvents="none"
        placeholder={`Add a ${type === 'comment' ? 'comment' : 'reply'}`}
        placeholderTextColor={colors.warmGray['500']}
        underlineColorAndroid="transparent"
        style={[
          tailwind('mr-2 h-9'),
          tailwind(inputStyles),
          {
            fontSize: 16,
            paddingVertical: 4,
          },
        ]}
        editable={false}
      />
    </Pressable>
  );
};

export const errorMessage = (error: PostCommentPayload['error']): string => {
  if (!error?.__typename) {
    return 'Unknown Error';
  }
  switch (error?.__typename) {
    case 'CommentEmpty':
      return 'Cannot post an empty comment';
    case 'CommentProfane':
      return 'We picked up some profane language in that comment. Please modify 🙂';
    case 'CommentTooLong':
      return 'Comment is too long';
  }
};

const inputStyles =
  'text-warmGray-800 font-normal flex-grow self-stretch ml-2 px-2 my-3 max-h-36 border border-warmGray-200 rounded-lg flex-auto bg-warmGray-100';
