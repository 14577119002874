import { VFC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatPercent } from '../../util/number';
import { ArrowDown, ArrowUp } from '../icons';

export const IdeaPriceDelta: VFC<{ delta: number; size?: 'md' | 'sm' }> = ({ delta, size = 'md' }) => {
  const tailwind = useTailwind();
  const arrowProps = size === 'sm' ? { height: 8, width: 8 } : undefined;
  const absoluteDelta = Math.abs(delta);

  return (
    <View style={tailwind('flex-row')}>
      <View style={tailwind('flex-col justify-center pr-1')}>
        {delta >= 0 ? (
          <ArrowUp {...arrowProps} accessibilityLabel="up" />
        ) : (
          <ArrowDown {...arrowProps} accessibilityLabel="down" />
        )}
      </View>
      {/** Present absolute since we present arrow */}
      <Text style={tailwind(`${size === 'sm' ? 'text-xs' : 'text-lg'} text-warmGray-800`)}>
        {formatPercent(absoluteDelta, 2, false)}
      </Text>
    </View>
  );
};
