import { useNavigation } from '@react-navigation/core';
import React, { ReactNode, useContext } from 'react';
import { StyleProp, StyleSheet, TextProps, View, ViewProps, ViewStyle } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { FEED_CARD_HELP_LINKS } from '../../constants/feed';
import { FeedCardFieldsFragment, FeedItem, GetFeedQuery } from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { BottomSheetRenderContext } from '../../providers/BottomSheetProvider';
import { share } from '../../services/sharing';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Skeleton } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { feedItemRelativeTime } from '../../util/date';
import { Unpack } from '../../util/types';
import { usePersistedStore } from '../../zustand/store';
import { IconButton } from '../Button/IconButton';
import { getFeedItemShareContent } from '../Feed/sharing';
import { VerticalDots } from '../icons/VerticalDots';
import { ScreenSidePadding } from '../StyledScreen';
import { FeedCardMenu } from './FeedCardMenu';

export type Props = {
  children: ReactNode;
  onPress?: () => void;
};

/**
 * Non-outline main card for Feed, includes screen side padding
 *
 * @param root0
 * @param root0.children
 * @param root0.onPress
 */
export const Card: React.FC<Props> = ({ children }) => {
  const tailwind = useTailwind();
  // adding flex-shrink-0 to card or FlatList renders the items all squashed on native, probably because not setting height
  return (
    <ScreenSidePadding>
      <View style={tailwind('my-6 flex-row flex-1 items-center flex-shrink-0')}>
        <View style={tailwind('flex-1')}>{children}</View>
      </View>
    </ScreenSidePadding>
  );
};

export type MenuFeedItem = Omit<Unpack<GetFeedQuery['feed']>, 'id' | '__typename'> &
  Pick<FeedItem, 'id' | '__typename'>;

/**
 * icon (at top left of card) and type of card
 *
 * Usage:
 ```
<CardTitle icon={<Gauge />}>From your watchlist</CardTitle>
 ```
 *
 * @param root0
 * @param root0.icon
 * @param root0.children
 * @param root0.title
 * @param root0.eventIsoDateTime
 * @param root0.eventTime
 */
export const CardTitle: React.FC<{
  icon: React.ReactNode;
  /** iso date time, will write 5h ago for example */
  eventTime?: Date;
  children: React.ReactNode;
  feedItem: Partial<FeedCardFieldsFragment> & { __typename: FeedItem['__typename']; id: FeedItem['id'] };
}> = ({ feedItem: { ...feedItem }, icon, children, eventTime }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();
  const userId = usePersistedStore((state) => state.userId);
  const toast = useToast();
  const { isInBottomSheet } = useContext(BottomSheetRenderContext);
  const { present, dismiss } = useBottomSheet();

  const helpLink = feedItem.__typename ? FEED_CARD_HELP_LINKS[feedItem.__typename] : undefined;

  const onShare = async () => {
    try {
      const shareContent = getFeedItemShareContent(feedItem, userId ?? 0);
      const status = await share(shareContent, userId);
      if (status === 'CopiedToClipboard') {
        toast.show('Link copied to clipboard.');
      }
    } catch (e) {
      console.error(e);
      toast.show('Unable to share link. Are sharing or clipboard permissions enabled?');
    }
  };

  const onOpenMenu = () => {
    present(
      <FeedCardMenu
        // Key ensures modal updated
        helpLink={helpLink}
        navigation={navigation}
        onPressMenuItem={() => dismiss()}
        feedItem={feedItem}
        onShare={onShare}
      />,
    );
  };

  return (
    <View style={tailwind('flex-row flex-1 mb-3')}>
      <View style={tailwind('mr-2')}>{icon}</View>
      <Text style={tailwind('text-warmGray-500 font-medium text-xs')} numberOfLines={1}>
        {children}
      </Text>
      {eventTime && (
        <Text style={tailwind('text-warmGray-400 font-medium text-xs ml-2')}>{feedItemRelativeTime(eventTime)}</Text>
      )}

      {/** Hide menu if card rendered in bottomSheet */}
      {!isInBottomSheet && (
        <View style={tailwind('flex-1 items-end')}>
          <IconButton
            accessibilityLabel="Vertical Dots"
            hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
            onPress={onOpenMenu}
            icon={<VerticalDots />}
          />
        </View>
      )}
    </View>
  );
};

/**
 * Main headline of card
 *
 * @param root0
 * @param root0.children
 * @param root0.icon
 */
export const CardHeadline: React.FC<{
  children: React.ReactNode;
  icon?: React.ReactNode;
  style?: ViewStyle;
}> = ({ children, icon, style }) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('flex-row items-center mb-3'), style]}>
      {icon && <View style={tailwind('mr-2')}>{icon}</View>}
      {children}
    </View>
  );
};

/**
 * Feed card headline text. Use inside CardHeadline
 *
 * @param param0
 */
export const CardHeadlineText: React.FC<TextProps> = ({ children, ...props }) => {
  const tailwind = useTailwind();
  return (
    <Text {...props} style={[tailwind('text-warmGray-800 text-sm flex-shrink'), props.style]}>
      {children}
    </Text>
  );
};

/**
 * outline inner card for the insight data
 *
 * Will redirect if logged out
 *
 * @param root0
 * @param root0.children
 * @param root0.onPress
 * @param root0.style
 * @param root0.innerStyle
 */
export const OutlineInnerCard: React.FC<
  Props & { style?: StyleProp<ViewProps>; innerStyle?: StyleProp<ViewProps> }
> = ({ children, onPress, style, innerStyle }) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('rounded-lg flex-row items-center bg-white'), styles.innerCard, style]}>
      <View style={[innerStyle ?? tailwind('px-3 py-3'), tailwind('flex-1')]}>
        <Pressable accessibilityRole="button" redirectIfLoggedOut onPress={onPress}>
          {children}
        </Pressable>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  innerCard: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.3,
    shadowRadius: 2.65,
    backgroundColor: 'white',
    elevation: 3,
  },
});

export const CardSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View>
      <Skeleton style={tailwind('h-4 w-40 mb-2')} />
      <Skeleton style={tailwind('h-3 w-60 mb-4')} />
      <Skeleton style={tailwind('h-36 w-full')} />
    </View>
  );
};
