// eslint-disable-next-line no-restricted-imports
import { MotiPressable, MotiPressableProps } from 'moti/interactions';
import React, { useMemo } from 'react';
import { useRedirectPress, UseRedirectPressArgs } from '../../hooks/useRedirectPress';
import { CommonPressableProps } from './types';

export type AnimatedPressableProps = CommonPressableProps & Omit<MotiPressableProps, 'onPress'>;

/**
 * MotiPressable with an optional redirectIfLoggedOut prop to navigate user to SignUp instead of executing onPress if logged out
 *
 * Note: track events done here
 *
 * @param props MotiPressable props
 * @param props.redirectIfLoggedOut send user to to SignUp screen instead of executing onPress if logged out
 */
export const AnimatedPressable: React.FC<AnimatedPressableProps> = ({
  redirectIfLoggedOut,
  accessibilityRole,
  accessibilityLabel,
  children,
  onPress,
  ...pressableProps
}) => {
  const trackingType: UseRedirectPressArgs['trackingType'] = useMemo(
    () => (accessibilityRole === 'button' ? 'button' : 'icon'),
    [accessibilityRole],
  );

  const { handlePress } = useRedirectPress({
    trackingType,
    redirectIfLoggedOut,
    onPress,
    accessibilityLabel,
  });

  return (
    <MotiPressable
      onPress={handlePress}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      {...pressableProps}
    >
      {children}
    </MotiPressable>
  );
};
