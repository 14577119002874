import { BottomSheetTextInput } from '@gorhom/bottom-sheet';
import React, { useContext, useMemo } from 'react';
import { TextInput, TextInputProps, View } from 'react-native';
import { BottomSheetRenderContext } from '../../providers/BottomSheetProvider';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export type Props = TextInputProps & { errorMessage?: string; label?: string };
/**
 * Context aware TextInput that renders a BottomSheetTextInput when inside a BottomSheet.
 */
export const BaseInput: React.FC<Props> = ({ style, errorMessage, label, ...props }) => {
  const tailwind = useTailwind();
  const { isInBottomSheet } = useContext(BottomSheetRenderContext);

  const styles = useMemo(
    () => [
      tailwind(`${errorMessage ? 'border-red-600' : 'border-gray-300'} rounded-lg px-2 text-black border flex-grow`),
      { minHeight: 40 },
      style,
    ],
    [style, errorMessage, tailwind],
  );
  return (
    <View style={tailwind('flex-shrink-0 flex-grow')}>
      {!!label && (
        <Text style={tailwind(`pb-2 font-medium ${errorMessage ? 'text-red-500' : `text-warmGray-600`}`)}>{label}</Text>
      )}
      {isInBottomSheet ? (
        <BottomSheetTextInput
          {...props}
          textAlignVertical={props.multiline ? 'top' : undefined}
          placeholderTextColor={colors.warmGray[400]}
          style={styles}
        />
      ) : (
        <TextInput
          {...props}
          textAlignVertical={props.multiline ? 'top' : undefined}
          placeholderTextColor={colors.warmGray[400]}
          style={styles}
        />
      )}
      {errorMessage && <Text style={tailwind('text-red-600 pl-2 pt-2 pb-1')}>{errorMessage}</Text>}
    </View>
  );
};
