import { CaptureConsole } from '@sentry/integrations';
import { Platform } from 'react-native';
import * as SentryExpo from 'sentry-expo';
import { API_BASE_URL, ENVIRONMENT, GRAPHQL_API_URL, SENTRY_DSN, VERSION } from '../constants/env';

const Sentry = Platform.OS === 'web' ? SentryExpo.Browser : SentryExpo.Native;

type SentryUserInfo = {
  isStaff: boolean;
};

const defaultTags = {
  platform: Platform.OS,
  env: ENVIRONMENT,
  appVersion: VERSION,
};

export function initSentry() {
  // sentry-expo doesn't export BrowserTracing() from @sentry/browser so only enable in Native
  const tracing =
    Platform.OS !== 'web'
      ? [
          new SentryExpo.Native.ReactNativeTracing({
            tracingOrigins: [API_BASE_URL, GRAPHQL_API_URL],
          }),
        ]
      : [];

  SentryExpo.init({
    dsn: SENTRY_DSN,
    enabled: process.env.NODE_ENV === 'production', // don't enable on local
    environment: ENVIRONMENT,
    release: VERSION,
    tracesSampleRate: 1,
    integrations: [new CaptureConsole({ levels: ['error'] }), ...tracing],
  });

  Sentry.setTags(defaultTags);
}

export function setSentryUser(userId: string, info: SentryUserInfo) {
  console.log(`Setting Sentry User`);
  Sentry.setUser({ id: userId, ...info });
}

export function clearSentryUser() {
  console.log(`Clearing Sentry User`);
  Sentry.configureScope((scope) => scope.setUser(null));
}

/** useful from menu for staff, can see console logs in sentry then */
export function forceTestException() {
  console.log(`Sending Sentry a Test Error`);
  Sentry.captureException(new Error('Test Error'));
}
