import { gql } from '@apollo/client';
import { CORE_COMMENT_FIELDS_WITHOUT_ACTIVE_IDEA } from './comments';
import { CORE_IDEA_FIELDS } from './idea';
import { CORE_INSTRUMENT_FIELDS } from './instrument';

/**
 * Note: relies on `CoreInstrumentFields`, `CoreIdeaFields` and `CoreCommentFieldsWithoutActiveIdea`
 *
 * so they are already imported by importing this
 */
export const FEED_CARD_FIELDS = gql`
  ${CORE_INSTRUMENT_FIELDS}
  ${CORE_IDEA_FIELDS}
  ${CORE_COMMENT_FIELDS_WITHOUT_ACTIVE_IDEA}
  fragment FeedCardFields on FeedItem {
    ... on ReportRatingFeedItem {
      id
      buyCount
      sellCount
      holdCount
      averageRating
      latestRating
      latestPublishDate
      instrument {
        ...CoreInstrumentFields
      }
    }
    ... on CrossFeedItem {
      id
      crossDate
      crossDirection
      instrument {
        ...CoreInstrumentFields
      }
    }
    ... on RelativeStrengthIndexFeedItem {
      id
      eventDate
      value
      rsiStartDate
      isOverbought
      instrument {
        ...CoreInstrumentFields
      }
    }
    ... on CorrelationFeedItem {
      id
      created
      correlation
      windowSizeDays
      subjectInstrument {
        ...CoreInstrumentFields
      }
      objectInstrument {
        ...CoreInstrumentFields
      }
    }
    ... on NewIdeaFeedItem {
      id
      created
      idea {
        ...CoreIdeaFields
      }
    }
    ... on NewsFeedItem {
      id
      news {
        id
        title
        storyText
        source
        publishDate
        imageUrl
        instruments {
          ...CoreInstrumentFields
        }
      }
    }
    ... on SignificantDailyMoverFeedItem {
      id
      date
      latestReturn
      zScore
      instrument {
        ...CoreInstrumentFields
      }
    }
    ... on NetShortDisclosureFeedItem {
      id
      totalReportedPosition
      reportDate
      positions {
        holder
        netPosition
        date
      }
      instrument {
        ...CoreInstrumentFields
      }
    }
    ... on MacdFeedItem {
      id
      created
      direction
      instrument {
        ...CoreInstrumentFields
      }
    }
    ... on NewCommentFeedItem {
      id
      created
      comment {
        ...CoreCommentFieldsWithoutActiveIdea
      }
      idea {
        ...CoreIdeaFields
      }
    }
    ... on UpsideVoiceFeedItem {
      id
      created
      voice {
        id
        title
        excerpt
        body
        publishDate
        heroImage {
          url
        }
        instruments {
          ...CoreInstrumentFields
        }
        ideas {
          id
        }
      }
    }
  }
`;
