import React from 'react';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { PercentageTextColor } from '../../util/color';

export type Props = {
  formattedPrice: string;
  formattedPercentColor: PercentageTextColor;
  formattedPercentChange: string;
  variant?: 'sm' | 'md';
};

/**
 * To be used in instrument price row. No container so the parent can dictate whether flex-row or flex-col
 */

export const Price: React.VFC<Props> = ({ formattedPercentChange, formattedPercentColor, formattedPrice }) => {
  const tailwind = useTailwind();
  return (
    <>
      <Text style={tailwind(`text-warmGray-800 text-xs`)} numberOfLines={1}>
        {formattedPrice}
      </Text>
      <Text style={tailwind(`pl-0.5 text-xs ${formattedPercentColor}`)} numberOfLines={1}>
        {formattedPercentChange}
      </Text>
    </>
  );
};
