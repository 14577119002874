import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { Image, View } from 'react-native';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { useGetNewsQuery } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { InstrumentPriceRow } from '../../old/InstrumentPriceRow';
import { NewsSkeleton } from '../../old/NewsSkeleton';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { visitExternalLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'News'>;

/* eslint-disable graphql/template-strings */
export const getNews = gql`
  ${CORE_INSTRUMENT_FIELDS}
  query getNews($id: ID!, $loggedIn: Boolean!) {
    news(id: $id) {
      id
      title
      source
      publishDate
      storyText
      fullTextUrl
      imageUrl
      instruments {
        ...CoreInstrumentFields
      }
    }
  }
`;

export const News: React.FC<Props> = withReloadErrorBoundary(({ route }) => {
  const tailwind = useTailwind();
  const { newsId } = route.params;
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const { data, loading, error } = useGetNewsQuery({
    variables: { id: newsId, loggedIn },
  });

  if (error) {
    throw error;
  }

  const news = data?.news;

  return (
    <SafeAreaView>
      <TitleBar showLogo={!loggedIn} hideBackButton={!loggedIn} title="" />

      {loading ? (
        <NewsSkeleton />
      ) : (
        <ScrollView>
          <ScreenSidePadding>
            <Text style={tailwind('mt-2 text-gray-800 text-xl font-semibold')}>{data?.news?.title}</Text>
            <Text style={tailwind('mt-3 text-gray-500 text-sm font-normal')}>
              {news?.source} · {news?.publishDate ? formatDate(news?.publishDate, 'D MMM YYYY, hh:mmA') : ''}
            </Text>
            {data?.news.imageUrl && (
              <View style={tailwind('pt-8 pb-4')}>
                <Image
                  source={{ uri: data?.news.imageUrl ?? '' }}
                  style={tailwind('h-44 rounded-lg')}
                  resizeMode="cover"
                  accessibilityIgnoresInvertColors={true}
                />
              </View>
            )}
            <Text style={tailwind('my-4 text-gray-700 text-base font-medium')}>{news?.storyText}</Text>
            <Button
              text="Read full article"
              size="md"
              variant="secondary"
              onPress={() => visitExternalLink(news?.fullTextUrl ?? '')}
              redirectIfLoggedOut
              style={tailwind('my-2')}
            />
            {news?.instruments.map((instrument) => (
              <InstrumentPriceRow {...instrument} key={instrument?.id} showWatchlistToggle isPressableCard />
            ))}
          </ScreenSidePadding>
        </ScrollView>
      )}
    </SafeAreaView>
  );
});
