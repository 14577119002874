import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { TimeInterval } from './string';

dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(advancedFormat);

const MINUTE_MS = 1000 * 60;
const HOUR_MS = MINUTE_MS * 60;
const DAY_MS = HOUR_MS * 24;

type DateFormat =
  | 'DD MMM YYYY'
  | 'D MMM YYYY, hh:mmA'
  | 'YYYY-MM-DD'
  | 'DD MMM YY'
  | 'Do MMMM YYYY'
  | 'Do MMM YYYY'
  | 'D MMM'
  | 'Do'
  | 'MMMM'
  | 'D MMMM';

/**
 * Parses date as UTC and returns formatted local date
 *
 * @param date UTC dateString, timestamp or Date/Dayjs instance
 * @param dateFormat  Date format
 * @returns Formatted local date
 */
export const formatDate = (date: string | number | Date | Dayjs, dateFormat: DateFormat) => {
  return dayjs.utc(date).local().format(dateFormat);
};

export const daysLeftTillDate = (date: Date | string): number => {
  return dayjs(date).diff(dayjs(), 'd');
};

export const parseUTCDateFromString = (isoDate: string | number | Date): Date => {
  return dayjs.utc(isoDate).toDate();
};

/**
 * use DayJS to add days/months etc to a date. Uses UTC
 */
export function add(date: Date, number: number, unit: OpUnitType): Date {
  return dayjs(date).utc().add(number, unit).toDate();
}

/**
 * use DayJS to subtract days/months etc to a date. Uses UTC
 */
export function subtract(date: Date, number: number, unit: OpUnitType): Date {
  return add(date, -number, unit);
}

export function addDay(date: Date, numberOfDays: number): Date {
  return add(date, numberOfDays, 'day');
}

export function subtractDay(date: Date, numberOfDays: number): Date {
  return addDay(date, -numberOfDays);
}

/**
 * https://day.js.org/docs/en/plugin/relative-time
 *
 * @param isoDate
 * @param withoutSuffix
 * @returns example "31 years ago"
 */
export const relativeTimeTo = (isoDate: string | number | Date, withoutSuffix = false): string => {
  return dayjs().to(parseUTCDateFromString(isoDate), withoutSuffix);
};

export function isYesterday(date: Date): boolean {
  const yesterday = addDay(new Date(), -1);
  const formattedDate = dayjs(date).format('YYYY-MM-DD');

  return dayjs(yesterday).format('YYYY-MM-DD') === formattedDate;
}

/**
 * Returns a relative time string for feed items.
 *  If < 1m show seconds, if < 1h show mins, if < 1 day show hrs, if yesterday show yesterday.
 *  Else show abbreviated date
 *
 * @param itemDate Feed item date
 * @returns Relative time string for feed item as specified in feed design handoff.
 */
export function feedItemRelativeTime(itemDate: Date): string {
  const deltaMs = Math.max(dayjs().diff(dayjs(itemDate), 'millisecond'), 0);

  const displaySeconds = deltaMs < MINUTE_MS;
  const displayMinutes = deltaMs < HOUR_MS;
  const displayHours = deltaMs < DAY_MS;
  const displayYesterday = isYesterday(itemDate);

  switch (true) {
    case displaySeconds:
      return `${dayjs.duration(deltaMs).seconds()}s`;

    case displayMinutes:
      return `${dayjs.duration(deltaMs).minutes()}m`;

    // Order is important here. Yesterday can be anywhere between 1-48 hours depending on time hence this statement precedes displayHours
    case displayYesterday:
      return 'Yesterday';

    case displayHours:
      return `${dayjs.duration(deltaMs).hours()}h`;

    default:
      return dayjs(itemDate).format('DD MMM');
  }
}

export const getRoundedTimeframe = (
  startingDate: Date | string | number,
  targetDate: Date | string | number,
  compact = false,
): string => {
  const months = dayjs(targetDate).diff(dayjs(startingDate), 'M', true);

  // 0.8 is chosen as the cut-off point so that if it's 3 weeks long, 3w is better to show than 1m, and if it's 3 weeks and 4 days, 1m is better to show.
  if (months > 0.8) {
    const roundedMonths = Math.round(months);
    const postfix = compact ? 'm' : roundedMonths === 1 ? ' month' : ' months';
    return `${roundedMonths}${postfix}`;
  }
  const weeks = dayjs(targetDate).diff(dayjs(startingDate), 'w');
  const postfix = compact ? 'w' : weeks === 1 ? ' week' : ' weeks';
  return `${weeks}${postfix}`;
};

/**
 * convert time interval to date from now
 *
 * @param startDate optional different start date if not now
 */
export const calculateTimeInterval = (timeInterval: TimeInterval, startDate: Date = new Date()): Date => {
  switch (timeInterval) {
    case '7D':
      return subtract(startDate, 7, 'days');
    case '1M':
      return subtract(startDate, 1, 'months');
    case '3M':
      return subtract(startDate, 3, 'months');
    case '1Y':
      return subtract(startDate, 1, 'years');
    case '5Y':
      return subtract(startDate, 5, 'years');
  }
};

/** For price chart, nice text of what the graph is showing */
export const getTimeIntervalText = (timeInterval: TimeInterval): string => {
  switch (timeInterval) {
    case '7D':
      return 'Last week';
    case '1M':
      return 'Last month';
    case '3M':
      return 'Last 3 months';
    case '1Y':
      return 'Last year';
    case '5Y':
      return 'Last 5 years';
  }
};

export const effectiveBusinessDate = (date: Date): Date => {
  if (date.getDay() === 0) {
    date = subtractDay(date, 1);
  }
  if (date.getDay() === 6) {
    date = subtractDay(date, 1);
  }
  return date;
};

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
