import { MotiText, MotiView } from 'moti';
import { FC } from 'react';
import { View, ViewStyle } from 'react-native';
import { InformationCircleIcon } from 'react-native-heroicons/outline';
import Animated, { AnimatedStyleProp } from 'react-native-reanimated';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { colors, useTailwind } from '../../theme';
import { formatPercent } from '../../util/number';
import { Pressable } from '../Pressable';
import { LayerBottomSheet } from './LayerBottomSheet';

export type Props = {
  /**
   * Values between 0 and 1
   */
  weights: {
    base: number;
    bundles: number;
    stocks: number;
  };
  /**
   * Which weight to highlight. If provided, other weights will be faded out.
   */
  highlight?: 'BASE' | 'BUNDLES' | 'STOCKS';
};

/**
 * Portfolio Weights breakdown to be used in onboarding. Exposes weighting segments to be used in the animated instance of this component
 */
export const PortfolioWeights: FC<Props> = ({ weights, highlight }) => {
  const tailwind = useTailwind();
  const { present, dismiss } = useBottomSheet();
  const baseVariant = highlight === 'BASE' ? 'HIGHLIGHTED' : !!highlight ? 'FADED' : 'DEFAULT';
  const bundlesVariant = highlight === 'BUNDLES' ? 'HIGHLIGHTED' : !!highlight ? 'FADED' : 'DEFAULT';
  const stocksVariant = highlight === 'STOCKS' ? 'HIGHLIGHTED' : !!highlight ? 'FADED' : 'DEFAULT';

  return (
    <View style={tailwind('flex-row')}>
      <WeightingSegment
        title="Base"
        onPress={() => present(<LayerBottomSheet variant="Base" onPressFinish={dismiss} />)}
        baseColor="primary"
        variant={baseVariant}
        padding="right"
        weight={weights.base}
      />
      <Divider />
      <WeightingSegment
        title="Bundles"
        onPress={() => present(<LayerBottomSheet variant="Bundles" onPressFinish={dismiss} />)}
        baseColor="secondary"
        variant={bundlesVariant}
        weight={weights.bundles}
        padding="both"
      />
      <Divider />
      <WeightingSegment
        title="Stocks"
        onPress={() => present(<LayerBottomSheet variant="Stocks" onPressFinish={dismiss} />)}
        baseColor="tertiary"
        variant={stocksVariant}
        weight={weights.stocks}
        padding="left"
      />
    </View>
  );
};

type PortfolioWeightingProps = {
  title: string;
  baseColor: 'primary' | 'secondary' | 'tertiary';
  variant?: 'DEFAULT' | 'HIGHLIGHTED' | 'FADED';
  padding: 'left' | 'right' | 'both';
  onPress?: () => void;
  /**
   * Animated segment style to be used if this component is animated
   */
  animatedStyle?: AnimatedStyleProp<ViewStyle>;
  weight?: number;
};

/**
 * Portfolio weighting segment. If children are provided, they will override the text element presenting the weight.
 * Override is used in the animated-text onboarding with the Risk Slider.
 */
export const WeightingSegment: FC<PortfolioWeightingProps> = ({
  title,
  baseColor,
  variant = 'DEFAULT',
  padding,
  animatedStyle,
  weight,
  onPress,
  children,
}) => {
  const tailwind = useTailwind();
  const { textColor, bgColor, infoColor } = weightingSegmentColorMap[baseColor][variant];
  const segmentPadding =
    padding === 'left' ? tailwind('pl-2') : padding === 'right' ? tailwind('pr-2') : tailwind('px-2');

  return (
    <Animated.View
      accessibilityLabel={`${title} Weight`}
      style={[tailwind('h-20 flex'), animatedStyle ?? { flexGrow: weight ?? 0, flexShrink: weight ?? 0 }]}
    >
      <Pressable accessibilityRole="button" style={tailwind('flex-grow')} onPress={onPress}>
        <View style={[tailwind('h-full w-full '), segmentPadding]}>
          <MotiView
            animate={{ backgroundColor: bgColor }}
            style={tailwind('pl-3 rounded-2xl flex-grow justify-center')}
          >
            <View style={tailwind('absolute items-end top-1.5 right-1.5')}>
              <InformationCircleIcon size={12} color={infoColor} />
            </View>
            {/* @ts-ignore - typescript doesn't see color as a valid prop for motiText but value animates successfully  */}
            <MotiText animate={{ color: textColor }} style={tailwind('text-sm font-regular')}>
              {title}
            </MotiText>
            {children ? (
              children
            ) : (
              <MotiText
                accessibilityLabel={`${title} Weight`}
                style={tailwind('font-bold text-lg w-12')}
                // @ts-ignore - typescript doesn't see color as a valid prop for motiText but value animates successfully
                animate={{ color: textColor }}
              >
                {formatPercent(weight ?? 0, 0, false)}
              </MotiText>
            )}
          </MotiView>
        </View>
      </Pressable>
    </Animated.View>
  );
};

export const Divider: FC = () => {
  const tailwind = useTailwind();
  return <View style={[tailwind('bg-gray-200 self-center'), { width: 1, height: '50%' }]} />;
};

const weightingSegmentColorMap: Record<
  PortfolioWeightingProps['baseColor'],
  Record<Required<PortfolioWeightingProps>['variant'], { textColor: string; bgColor: string; infoColor: string }>
> = {
  primary: {
    DEFAULT: {
      textColor: colors.primary.dark,
      bgColor: colors.primary.light,
      infoColor: colors.primary.default,
    },
    HIGHLIGHTED: {
      textColor: colors.white,
      bgColor: colors.primary.default,
      infoColor: colors.white,
    },
    FADED: {
      textColor: colors.primary.light,
      bgColor: colors.primary.lightest,
      infoColor: colors.primary.light,
    },
  },
  secondary: {
    DEFAULT: {
      textColor: colors.secondary.dark,
      bgColor: colors.secondary.light,
      infoColor: colors.secondary.default,
    },
    HIGHLIGHTED: {
      textColor: colors.white,
      bgColor: colors.secondary.default,
      infoColor: colors.white,
    },
    FADED: {
      textColor: colors.secondary.light,
      bgColor: colors.secondary.lightest,
      infoColor: colors.secondary.light,
    },
  },
  tertiary: {
    DEFAULT: {
      textColor: colors.tertiary.dark,
      bgColor: colors.tertiary.light,
      infoColor: colors.tertiary.default,
    },
    HIGHLIGHTED: {
      textColor: colors.white,
      bgColor: colors.tertiary.default,
      infoColor: colors.white,
    },
    FADED: {
      textColor: colors.tertiary.light,
      bgColor: colors.tertiary.lightest,
      infoColor: colors.tertiary.light,
    },
  },
};
