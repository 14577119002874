import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { INSTRUMENT_STATISTICS } from '../../fragments/instrument';
import { useGetInstrumentSummaryQuery } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { InstrumentSummaryBody, InstrumentSummaryBodySkeleton } from '../../old/InstrumentSummary';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export const getInstrumentSummary = gql`
  ${INSTRUMENT_STATISTICS}
  query getInstrumentSummary($id: ID!) {
    instrument(id: $id) {
      id
      ticker
      companyInfo
      ...InstrumentStatistics
      quotePrice {
        id
        midPrice
        previousClose
      }
      currency {
        id
        iso
      }
      sector {
        id
        name
      }
      industry {
        id
        name
      }
      countryOfListing {
        id
        name
      }
    }
  }
`;

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'InstrumentSummary'>;

export const InstrumentSummary: React.FC<Props> = withReloadErrorBoundary(({ route: { params } }) => {
  const instrumentId = params.instrumentId;
  const { data, loading, error } = useGetInstrumentSummaryQuery({
    variables: {
      id: instrumentId,
    },
  });

  if (error) {
    throw error;
  }

  return (
    <SafeAreaView>
      <TitleBar title={data?.instrument?.ticker ?? ''} />
      <ScrollView>
        <ScreenSidePadding>
          {loading ? (
            <InstrumentSummaryBodySkeleton />
          ) : data?.instrument ? (
            <InstrumentSummaryBody instrument={data?.instrument} />
          ) : null}
        </ScreenSidePadding>
      </ScrollView>
    </SafeAreaView>
  );
});
