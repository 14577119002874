import { gql, NetworkStatus } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Linking, SectionList, SectionListRenderItem, TextInput, View } from 'react-native';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { CORE_USER_PROFILE_FIELDS } from '../../fragments/userProfile';
import { GetSearchQuery, useGetSearchLazyQuery, UserProfile } from '../../generated/graphql';
import { useDebounce } from '../../hooks/useDebounce';
import { usePrevious } from '../../hooks/usePrevious';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { InstrumentPriceRow, InstrumentPriceRowSkeleton } from '../../old/InstrumentPriceRow';
import { Link } from '../../old/Link';
import { Persona, Props as PersonaProps } from '../../old/Persona';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { analytics } from '../../services/analytics';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { Pressable } from '../../ui/Pressable';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { Unpack } from '../../util/types';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';
import { SearchBar } from './SearchBar';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'SearchModal'>;
type NavigationProps = NativeStackNavigationProp<LoggedInStackParamList, 'SearchModal'>;

/* eslint-disable graphql/template-strings */
export const getSearch = gql`
  ${CORE_INSTRUMENT_FIELDS}
  ${CORE_USER_PROFILE_FIELDS}
  query getSearch($searchText: NonEmptyString!, $loggedIn: Boolean!) {
    search(searchText: $searchText) {
      instruments {
        nodes {
          ...CoreInstrumentFields
        }
      }
      users {
        nodes {
          ...CoreUserProfileFields
        }
      }
      error {
        message
      }
    }
  }
`;

type Users = Required<NonNullable<GetSearchQuery['search']['users']>>;
type Instruments = Required<NonNullable<GetSearchQuery['search']['instruments']>>;
// TypeScript won't let us index nodes unless types above made required and non-nullable
type User = Unpack<Users['nodes']>;
type Instrument = Unpack<Instruments['nodes']>;

export const SearchModal: React.FC<Props> = withReloadErrorBoundary(() => {
  const tailwind = useTailwind();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const [searchText, setSearchText] = useState('');
  const [getSearch, { data, networkStatus, error, refetch }] = useGetSearchLazyQuery();
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const debouncedSearchText = useDebounce(searchText.trim(), 250);
  const prevDebouncedSearchText = usePrevious(debouncedSearchText);

  const searchBarRef = React.createRef<TextInput>();

  // If search has been cleared or is empty, set refetch false to use fresh query instead of showing prev, unrelated data when user starts typing again
  useEffect(() => {
    if (!debouncedSearchText) {
      setShouldRefetch(false);
    } else {
      analytics.track('Search typed', { 'Search term': debouncedSearchText });
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    const isSameSearchText = prevDebouncedSearchText === debouncedSearchText;
    if (!debouncedSearchText || isSameSearchText) return;

    // Refetch instead if user has already entered characters to update the results without showing skeleton loaders
    if (shouldRefetch) {
      // Refetch can be undefined according to TS
      refetch && refetch({ searchText: debouncedSearchText, loggedIn });
    } else {
      getSearch({ variables: { searchText: debouncedSearchText, loggedIn } });
      setShouldRefetch(true);
    }
  }, [networkStatus, debouncedSearchText, refetch, shouldRefetch, getSearch, prevDebouncedSearchText, loggedIn]);

  if (error) {
    throw error;
  }

  // If we omit shouldRefresh from this check, cached data is available when a user clears the search bar.
  // This means when they start typing again, stale data flashes before skeleton loaders since (!!data == true).
  // shouldRefetch check avoids this by hiding the flatlist till the first query load has finished when a
  // user starts typing again.
  const showFlatList = shouldRefetch && data && !!searchText && !!debouncedSearchText;
  const isLoading = networkStatus === NetworkStatus.loading;
  const instruments: Array<Instrument | User> = data?.search.instruments?.nodes.slice(0, 5) ?? [];
  const users: Array<Instrument | User> = data?.search.users?.nodes.slice(0, 5) ?? [];
  const isEmpty = instruments.length === 0 && users.length === 0;

  // One render Item needed to play nice with SectionList's generic types
  const renderItem: SectionListRenderItem<User | Instrument> = useCallback(
    ({ item }) => {
      switch (item.__typename) {
        case 'Instrument':
          return <InstrumentRow searchText={debouncedSearchText} instrument={item} />;
        case 'UserProfile':
          return <UserProfileWrapped searchText={debouncedSearchText} {...item} />;
        default:
          return null;
      }
    },
    [debouncedSearchText],
  );

  return (
    <SafeAreaView>
      <ScreenSidePadding style={tailwind('pt-1')}>
        <SearchBar ref={searchBarRef} onChangeText={setSearchText} value={searchText} />
      </ScreenSidePadding>
      <KeyboardAvoidingView>
        {isLoading ? (
          <SearchSkeleton />
        ) : showFlatList ? (
          <SectionList
            testID="SearchResults"
            contentContainerStyle={[tailwind('px-6'), isEmpty && tailwind('flex-grow items-center')]}
            ItemSeparatorComponent={() => <View style={tailwind('py-3')} />}
            SectionSeparatorComponent={() => <View style={tailwind('pt-4')} />}
            sections={
              // Sections only render Empty component when whole section array is empty so empty check is done manually
              !isEmpty
                ? [
                    { title: 'Stocks', data: instruments, renderItem, keyExtractor },
                    { title: 'Users', data: users, renderItem, keyExtractor },
                  ]
                : []
            }
            renderSectionHeader={({ section: { title, data } }) =>
              // Hide section header if empty
              data.length ? <Text style={tailwind('bg-white text-warmGray-500 font-medium')}>{title}</Text> : null
            }
            ListEmptyComponent={EmptyView}
            ListFooterComponent={!isEmpty ? CantFindInstrumentText : undefined}
            keyboardDismissMode="on-drag"
          />
        ) : (
          <InitialView />
        )}
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
});

const keyExtractor = (listItem: Instrument | User) => {
  return `${listItem.id}`;
};

const UserProfileWrapped: FC<PersonaProps & { id?: UserProfile['id']; searchText?: string }> = ({
  searchText,
  ...props
}) => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <Pressable
      pointerEvents="box-only"
      accessibilityLabel={`User ${props.nickname}`}
      onPress={() => {
        analytics.track('Search item pressed', {
          'Search term': searchText ?? '',
          id: `${props.id}`,
          name: props.nickname ?? '',
          type: 'User',
        });
        navigation.replace('UserProfile', { userId: props.id ?? 0 });
      }}
    >
      <Persona size="md" {...props} />
    </Pressable>
  );
};

const InstrumentRow: FC<{ instrument: Instrument; searchText?: string }> = ({ instrument, searchText }) => {
  const navigation = useNavigation<NavigationProps>();
  return (
    <Pressable
      accessibilityRole="button"
      onPress={() => {
        analytics.track('Search item pressed', {
          'Search term': searchText ?? '',
          id: instrument.id ?? '',
          name: instrument.name ?? '',
          type: 'Instrument',
        });
        navigation.replace('Instrument', { instrumentId: instrument.id });
      }}
      key={instrument.id}
      testID="InstrumentSearchResult"
    >
      <InstrumentPriceRow {...instrument} showWatchlistToggle />
    </Pressable>
  );
};

const InitialView: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-col flex-1 items-center justify-center')}>
      <View>
        <Text style={tailwind('font-semibold mb-1 text-center')}>Discover new investing opportunities</Text>
        <Text style={tailwind('text-sm text-center text-warmGray-500')}>Search companies, tickers or users</Text>
      </View>
    </View>
  );
};

const CantFindInstrumentText: React.FC = () => {
  const tailwind = useTailwind();
  // This won't work in your IOS simulator. Must be tested on android, web or a real device.
  const onPressHelp = () => {
    Linking.openURL(encodeURI('mailto: help@upsidetechnology.co?subject=Stock Request'));
  };

  return (
    <>
      <Text style={tailwind('text-sm text-warmGray-500 text-center')}>
        Can&apos;t find what you{"'"}re looking for?
      </Text>
      <Link style={tailwind('text-center')} onPress={() => onPressHelp()}>
        Tell us.
      </Link>
    </>
  );
};

const EmptyView: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-col flex-1 items-center justify-center')}>
      <Text style={tailwind('font-semibold mb-1 text-center')}>No results found</Text>
      <CantFindInstrumentText />
    </View>
  );
};

const SearchSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      <SkeletonView>
        {new Array(20).fill(10).map((_, i) => (
          <View key={i} style={tailwind('mb-4')}>
            <InstrumentPriceRowSkeleton />
          </View>
        ))}
      </SkeletonView>
    </ScreenSidePadding>
  );
};
