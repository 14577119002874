import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, View } from 'react-native';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardTitle } from '../../Card';
import { Bolt } from '../../icons';
import { TickerRow } from '../../TickerRow';

export type Props = Pick<NarrowByTypename<FeedCardFieldsFragment, 'NewsFeedItem'>, 'id' | '__typename' | 'news'>;

export const NewsCard: React.FC<Props> = ({ news, __typename, id }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  if (!news) {
    return null;
  }
  const { source, title, storyText, publishDate, instruments, imageUrl } = news;

  return (
    <Pressable
      accessibilityRole="button"
      redirectIfLoggedOut
      onPress={() => {
        navigation.push('News', { newsId: news.id });
        analytics.track('Feed card pressed', {
          'Destination screen': 'News',
          'Feed card type': __typename,
          'Feed item id': id,
          'Instrument ticker': instruments.map((i) => i?.id).join(' '),
        });
      }}
    >
      <Card>
        <View>
          <CardTitle
            feedItem={{ id, __typename, news }}
            icon={<Bolt />}
            eventTime={publishDate ? new Date(publishDate) : undefined}
          >
            News
          </CardTitle>
          <TickerRow instruments={instruments} />
          <View style={tailwind('flex-row')}>
            <View style={tailwind('flex-1')}>
              <Text style={tailwind('text-warmGray-800 text-sm mt-2')} numberOfLines={4} ellipsizeMode="tail">
                <Text style={tailwind('font-bold')}>{title}:</Text> {storyText}
              </Text>
            </View>
            {imageUrl && (
              <View style={tailwind('justify-center px-2')}>
                <Image
                  source={{ uri: imageUrl ?? '' }}
                  style={[tailwind('rounded-lg'), { height: 76, width: 76 }]}
                  resizeMode="cover"
                  accessibilityIgnoresInvertColors={true}
                />
              </View>
            )}
          </View>
          <Text style={tailwind('text-warmGray-400 text-xs mt-2')} numberOfLines={4} ellipsizeMode="tail">
            Source: {source}
          </Text>
        </View>
      </Card>
    </Pressable>
  );
};
