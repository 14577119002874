import { forwardRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { Text as RNText, TextProps } from 'react-native';
import { useTailwind } from '../../theme';

/**
 * Text component with default font set to Poppins
 */
export const Text = forwardRef<RNText, TextProps>((props, ref) => {
  const tailwind = useTailwind();
  return <RNText {...props} style={[tailwind('font-regular'), props.style]} ref={ref} />;
});
