import { FC } from 'react';
import { ArrowRightIcon } from 'react-native-heroicons/outline';
import { colors, useTailwind } from '../../theme';
import { Pressable } from '../Pressable';

export type Props = {
  onPress: () => void;
};

export const Fab: FC<Props> = ({ onPress }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      onPress={onPress}
      accessibilityLabel="Fab"
      hitSlop={8}
      accessibilityRole="button"
      style={tailwind(
        'absolute h-14 w-14 rounded-full bg-primary-default bottom-8 right-6 items-center justify-center',
      )}
    >
      <ArrowRightIcon strokeWidth={3} size={16} color={colors.white} />
    </Pressable>
  );
};
