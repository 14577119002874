import * as React from 'react';
import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

export const Warning = (props: SvgProps) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0)">
        <Path
          d="M15.8 12.526L9.483.88A1.668 1.668 0 008.8.2a1.693 1.693 0 00-2.284.68L.2 12.526A1.678 1.678 0 001.686 15h12.628a1.7 1.7 0 001.308-.615 1.673 1.673 0 00.179-1.859H15.8zM8 13a1 1 0 110-2 1 1 0 010 2zm1-3.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5v-4a.5.5 0 01.5-.5h1a.5.5 0 01.5.5v4z"
          fill={props.color ?? '#A3A3A3'}
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0">
          <Path fill="#fff" d="M0 0h16v16H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
