// eslint-disable-next-line no-restricted-imports
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

type Round = 'md' | 'full';
type TextSize = 'xs' | 'sm' | 'base';
type Size = 'xs' | 'sm' | 'md';

export type Props = {
  text: string;
  rounded?: Round;
  textSize?: TextSize;
  selected?: boolean;
  size?: Size;
  onPress?: () => void;
  style?: StyleProp<ViewStyle>;
  /**
   * Used when lower prominence is needed in chips. (e.g Idea card)
   */
  lowContrast?: boolean;
};

/**
 * A selection chip
 * Note: using React Native Pressable not MotiPressable (via our ./Pressable) as styles we're applied correctly to MotiPressable.
 * The chips were not flex growing.
 * MotiPressable also has a containerStyle prop which didnt work, but probably would with more thought.
 */
export const Chip: React.VFC<Props> = ({
  text,
  onPress,
  selected = false,
  rounded = 'full',
  textSize = 'sm',
  size = 'sm',
  style,
  lowContrast,
}) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      style={[
        {
          alignSelf: 'flex-start',
        },
        tailwind(`flex-auto justify-center rounded-full text-sm text-center
        ${roundedStyles(rounded)}
        ${sizeStyles(size)}
        ${containerStyles({ selected, lowContrast })}`),
        style,
      ]}
      // Ensures cursor is not pointer when read-only in web
      disabled={onPress === undefined}
      onPress={onPress}
      accessibilityLabel={text}
    >
      <Text style={tailwind(`text-center ${textColour({ selected, lowContrast })} ${textStyles(textSize)}`)}>
        {text}
      </Text>
    </Pressable>
  );
};

const roundedStyles = (rounded: Round): string => {
  switch (rounded) {
    case 'md':
      return 'rounded-xl px-1.5';
    case 'full':
      return 'rounded-full px-4';
  }
};

const textStyles = (size: TextSize): string => {
  switch (size) {
    case 'xs':
      return 'text-xs';
    case 'sm':
      return 'text-sm';
    case 'base':
      return 'text-base';
  }
};

const sizeStyles = (size: Size) => {
  switch (size) {
    case 'xs':
      return 'h-7';
    case 'sm':
      return 'h-9';
    case 'md':
      return 'h-11';
  }
};

const containerStyles = ({ selected, lowContrast }: { selected?: boolean; lowContrast?: boolean }) => {
  switch (selected) {
    case true:
      return ' bg-teal-100';
    case false:
      return `bg-warmGray-${lowContrast ? '100' : '200'}`;
  }
};

const textColour = ({ selected, lowContrast }: { selected?: boolean; lowContrast?: boolean }) => {
  switch (selected) {
    case true:
      return 'text-teal-800';
    case false:
      return lowContrast ? 'text-warmGray-500' : 'text-warmGray-700';
  }
};
