import React, { useState } from 'react';
import { Collapsable } from './Collapsable';

export type Props<T extends { id: string }> = {
  sections: T[];
  renderHeader: (section: T) => React.ReactElement | null;
  renderContent: (section: T) => React.ReactElement | null;
};

export const Accordion = <T extends { id: string }>({ sections, renderHeader, renderContent }: Props<T>) => {
  const [openSections, setOpenSections] = useState<string[]>([]);
  return (
    <>
      {sections.map((section) => (
        <Collapsable
          collapsed={!openSections.includes(section.id)}
          key={section.id}
          header={renderHeader(section)}
          onPress={() =>
            setOpenSections(
              openSections.includes(section.id)
                ? openSections.filter((openSection) => openSection !== section.id)
                : [...openSections, section.id],
            )
          }
        >
          {renderContent(section)}
        </Collapsable>
      ))}
    </>
  );
};
