import { MotiView } from 'moti';
import { FC } from 'react';
import { View } from 'react-native';
import { DatabaseIcon, LibraryIcon, ShieldCheckIcon, UserCircleIcon } from 'react-native-heroicons/outline';
import { textColors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const InitialBodyText: FC = () => {
  const tailwind = useTailwind();
  return (
    <>
      <Text style={tailwind('pt-9 text-default font-medium text-sm pb-8')}>Why it{"'"}s unique?</Text>
      <View style={tailwind('flex-row items-center pb-6')}>
        <DatabaseIcon color={textColors.light} height={20} width={20} />
        <Text style={tailwind('pl-4 font-medium text-default text-base')}>A simple, personalised portfolio</Text>
      </View>
      <View style={tailwind('flex-row items-center pb-6')}>
        <LibraryIcon color={textColors.light} height={20} width={20} />
        <Text style={tailwind('pl-4 font-medium text-default text-base')}>Build wealth at your own pace</Text>
      </View>
      <View style={tailwind('flex-row items-center pb-6')}>
        <UserCircleIcon color={textColors.light} height={20} width={20} />
        <Text style={tailwind('pl-4 font-medium text-default text-base')}>No hidden fees</Text>
      </View>
      <View style={tailwind('flex-row items-center')}>
        <ShieldCheckIcon color={textColors.light} height={20} width={20} />
        <Text style={tailwind('pl-4 font-medium text-default text-base')}>FCA authorised and regulated</Text>
      </View>
      {/* Icon placeholder to keep all aligned */}
      {/* <View style={tailwind('flex-row')}>
        <View style={tailwind('h-5 w-5 mr-4')} />
        <InfoLink onPress={() => undefined} text="What is this?" />
      </View> */}
    </>
  );
};

export const BaseBodyText: FC = () => {
  const tailwind = useTailwind();

  return (
    <MotiView from={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Text style={tailwind('pt-9 text-default font-medium text-sm pb-8')}>What{"'"}s my Base?</Text>
      <Text style={tailwind('text-default text-base')}>
        Your base is your portfolio{"'"}s stable foundation.
        {'\n'}
        {'\n'}
        Managed by Upside, your Base is a diverse group of stocks that aims to mitigate some of the risks normally
        associated with investing in stocks.
      </Text>
    </MotiView>
  );
};

export const BundlesBodyText: FC = () => {
  const tailwind = useTailwind();

  return (
    <MotiView from={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Text style={tailwind('pt-9 text-default font-medium text-sm pb-8')}>What are Bundles?</Text>
      <Text style={tailwind('text-default text-base')}>
        Bundles allow you to invest in what you believe in.
        {'\n'}
        {'\n'}
        Managed by Upside, Bundles are groups of stocks that sit within themes such as environmental, fashion or food
        and drink.
      </Text>
    </MotiView>
  );
};

export const StocksBodyText: FC = () => {
  const tailwind = useTailwind();

  return (
    <MotiView from={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Text style={tailwind('pt-9 text-default font-medium text-sm pb-8')}>What are single stocks?</Text>
      <Text style={tailwind('text-default text-base')}>
        Make your portfolio as unique as you are by adding up to three single stocks to your portfolio.
        {'\n'}
        {'\n'}
        By limiting this layer, we minimise the risk you{"'"}re exposed to should the market take a downturn.
      </Text>
    </MotiView>
  );
};
