import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { RefreshControl } from 'react-native';
import { useGetStacksQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { StackCard } from '../../old/StackCard';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { isNotNull } from '../../util/typeGuards';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

type Props = NativeStackScreenProps<LoggedInStackParamList, 'Stacks'>;

export const Stacks: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const { data, refetch, loading: dataLoading, error } = useGetStacksQuery();
  const { refreshing, onRefresh } = useRefresh(refetch);

  if (error) {
    throw error;
  }

  const loading = dataLoading || refreshing;
  const stacks = data?.stacks.stacks;

  return (
    <SafeAreaView>
      <TitleBar title="Stacks" />
      {loading ? (
        <StacksSkeleton />
      ) : (
        <ScrollView refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
          <ScreenSidePadding>
            {stacks?.map((stack) => (
              <Pressable
                onPress={() => navigation.push('Stack', { id: stack?.id ?? '' })}
                accessibilityRole="button"
                style={tailwind('py-2')}
                key={stack?.id}
              >
                <StackCard
                  instruments={stack?.components?.nodes.map((i) => i.instrument).filter(isNotNull) ?? []}
                  totalCount={stack?.components?.pageInfo.totalCount ?? 0}
                  name={stack?.content?.name}
                  description={stack?.content?.description}
                />
              </Pressable>
            ))}
          </ScreenSidePadding>
        </ScrollView>
      )}
    </SafeAreaView>
  );
});

const StacksSkeleton: FC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView>
      <ScreenSidePadding>
        {new Array(15).fill(null).map((_, i) => (
          <Skeleton key={i} style={tailwind('my-2 rounded h-24')} />
        ))}
      </ScreenSidePadding>
    </SkeletonView>
  );
};
