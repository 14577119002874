import { sortBy } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { runOnJS, useAnimatedReaction, useDerivedValue, useSharedValue, withSpring } from 'react-native-reanimated';
import { useTailwind } from '../../../theme';
import { calculateTimeInterval } from '../../../util/date';
import { TimeInterval, timeIntervals } from '../../../util/string';
import { YAxis } from '../axis';
import { TICK_COUNT } from '../axis/YAxis';
import { ChartCanvas } from '../ChartCanvas';
import { Empty } from '../ChartCanvas/Empty';
import { chartColor } from '../color';
import { ChartContext } from '../context/ChartContext';
import { Cursor } from '../Cursor';
import { Header } from '../Header';
import { getTicks } from '../ticks';
import { IntervalSelector } from './IntervalSelector';

export type Props = {
  currencyIso?: string;
  initialInterval?: TimeInterval;
  currentPrice?: { price: number; date: Date };
  prices: [number, number][];
};

/** If showTimeInterval on use MAX_TIME_INTERVAL to calculate from date and pass in all prices. Will filter client side  */
export const MAX_PRICE_CHART_TIME_INTERVAL: TimeInterval = '5Y';

const CHART_HEIGHT = 256;

export const IntervalChart: React.VFC<Props> = ({ prices, currencyIso, currentPrice, initialInterval }) => {
  const tailwind = useTailwind();
  const [width, setWidth] = React.useState<number | undefined>();
  const yAxisWidth = useSharedValue(0);
  const chartWidth = useDerivedValue(() => (width !== undefined ? width - yAxisWidth.value : 0));
  const [enabled, setEnabled] = React.useState(true);
  const today = new Date();
  const height = useSharedValue(CHART_HEIGHT);
  const sortedPrices = sortBy(prices, (x) => x[0]);
  const chartData = timeIntervals.map((t) => {
    const filteredPrices = filterPrices(sortedPrices, t, today);
    const boundaries = filteredPrices.length > 0 ? getPriceBoundaries(filteredPrices) : undefined;
    const ticks = boundaries ? getTicks(boundaries.lowest, boundaries.highest, TICK_COUNT) : undefined;
    const color = chartColor(filteredPrices);
    return {
      ticks,
      prices: filteredPrices,
      color,
    };
  });
  const currentInterval = useSharedValue(initialInterval ? timeIntervals.indexOf(initialInterval) : 2);
  const basePrice = useDerivedValue(() => chartData[currentInterval.value].prices[0]);
  const dragging = useSharedValue(false);
  const transition = useSharedValue(0);
  React.useEffect(() => {
    transition.value = withSpring(1, {
      stiffness: 50,
      damping: 20,
      overshootClamping: true,
    });
  }, [transition]);
  useAnimatedReaction(
    () => chartData[currentInterval.value].prices.length > 0,
    (hasPrices) => {
      runOnJS(setEnabled)(hasPrices);
    },
  );
  const latest: [number, number] = currentPrice
    ? [currentPrice.date.getTime(), currentPrice.price]
    : sortedPrices[sortedPrices.length - 1];
  const displayPrice = useSharedValue(latest);
  const onTimeIntervalChange = (interval: TimeInterval) => {
    currentInterval.value = timeIntervals.indexOf(interval);
    transition.value = 0;
    transition.value = withSpring(1, {
      stiffness: 50,
      damping: 20,
      overshootClamping: true,
    });
  };

  const data = useDerivedValue(
    () => ({
      prices: chartData[currentInterval.value].prices,
      ticks: chartData[currentInterval.value].ticks,
      colors: chartData[currentInterval.value].color,
    }),
    [chartData],
  );

  return (
    <ChartContext.Provider value={{ chartWidth, height, data: data }}>
      <View>
        <Header
          price={displayPrice}
          basePrice={basePrice}
          currencyCode={currencyIso}
          selectedInterval={currentInterval}
          dragging={dragging}
          style={tailwind('px-6 py-2')}
        />
        <View
          style={{ height: height.value }}
          onLayout={(e) => {
            setWidth(e.nativeEvent.layout.width);
          }}
        >
          {enabled ? (
            width && (
              <>
                <ChartCanvas transition={transition} />
                <Cursor dragging={dragging} displayPrice={displayPrice} currentPrice={latest} />
              </>
            )
          ) : (
            <Empty />
          )}
          <YAxis
            currencyCode={currencyIso}
            widthHandler={(width) => {
              yAxisWidth.value = width;
            }}
          />
        </View>
        <IntervalSelector
          width={width}
          selectedInterval={currentInterval}
          intervals={timeIntervals.slice()}
          onSelect={onTimeIntervalChange}
        />
      </View>
    </ChartContext.Provider>
  );
};

const getPriceBoundaries = (prices: [number, number][]) => ({
  earliest: Math.min(...prices.map((d) => d[0])),
  latest: Math.max(...prices.map((d) => d[0])),
  lowest: Math.min(...prices.map((d) => d[1])),
  highest: Math.max(...prices.map((d) => d[1])),
});

const filterPrices = (prices: [number, number][], timeInterval: TimeInterval, nowDate: Date): [number, number][] => {
  const startDate = calculateTimeInterval(timeInterval, nowDate);
  return prices.filter((p) => p[0] >= startDate.getTime());
};
