import { ApolloClient, ApolloLink, FieldPolicy, InMemoryCache } from '@apollo/client';
import { defaultRefetchQueries } from '../../constants/defaultRefetchQueries';
import { ENVIRONMENT } from '../../constants/env';
import { Query } from '../../generated/graphql';
import possibleTypesResult from '../../generated/graphql/possibleTypes';
import { additivePagination } from '../pagination';
import { getLinks } from './links';

const fieldPolicyKeys = ['publicIdeasPaginated', 'ideasPaginated'] as const;

const fieldPolices: {
  [Key in typeof fieldPolicyKeys[number]]: FieldPolicy<Query[Key], Query[Key], Query[Key]>;
} = {
  publicIdeasPaginated: {
    keyArgs: ['filter', 'pagination', ['limit']],
    merge: additivePagination,
  },
  ideasPaginated: {
    keyArgs: ['filter', 'pagination', ['limit']],
    merge: additivePagination,
  },
};

const makeGraphQLClient = () => {
  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: possibleTypesResult.possibleTypes,
      typePolicies: {
        Query: {
          fields: fieldPolices,
        },
        /**
         * De-normalize stack components so they don't override weightings for other stacks
         * See https://www.apollographql.com/docs/react/caching/cache-configuration/#disabling-normalization
         */
        Component: {
          keyFields: false,
        },
      },
    }),
    defaultOptions: {
      mutate: {
        // Refetch onboardingStatus every mutation
        refetchQueries: defaultRefetchQueries,
      },
    },
    link: ApolloLink.from(getLinks()),
    connectToDevTools: ENVIRONMENT !== 'prod',
  });
};

/**
 * Singleton graphql client
 */
let graphqlClient: ReturnType<typeof makeGraphQLClient> | undefined;

/** return singleton graphql client, or create it if its undefined */
export const getGraphqlClient = () => {
  if (graphqlClient) return graphqlClient;
  graphqlClient = makeGraphQLClient();
  return graphqlClient;
};

export const resetGraphQLClient = () => {
  if (graphqlClient) {
    graphqlClient.stop();
  }
  graphqlClient = undefined;
};
