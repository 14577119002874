import { MotiPressable } from 'moti/interactions';
import React from 'react';
import { StyleProp, TextInput, TextStyle, View } from 'react-native';
import { XIcon } from 'react-native-heroicons/outline';
import { colors, useTailwind } from '../../theme';

type Props = {
  value?: string;
  onChangeText?: (text: string) => void;
  onClear?: () => void;
  enabled?: boolean;
  styles?: StyleProp<TextStyle>;
  containerStyles?: StyleProp<TextStyle>;
};

export const SearchBar = React.forwardRef<TextInput, Props>(
  ({ value, onChangeText, onClear, enabled = true, containerStyles, styles }, ref) => {
    const tailwind = useTailwind();
    // const navigation = useNavigation<LoggedInStackNavigationProps>();

    const onPressClear = () => {
      onChangeText && onChangeText('');
      if (onClear) {
        onClear();
      }
    };

    return (
      <View style={[tailwind('border border-greys-500 rounded-xl items-center flex-row'), containerStyles]}>
        <TextInput
          autoFocus
          accessibilityLabel="Search"
          ref={ref}
          style={[tailwind('flex-1 text-sm py-4 px-3'), styles]}
          value={value}
          onChangeText={onChangeText}
          selectionColor={colors.amber['400']}
          placeholder="Search"
          editable={enabled}
        />
        {value ? (
          <MotiPressable
            accessibilityLabel="Clear Search Text"
            style={tailwind('items-center justify-center pl-2')}
            onPress={onPressClear}
          >
            <XIcon width={16} height={16} color={colors.greys['500']} />
          </MotiPressable>
        ) : null}
      </View>
    );
  },
);
