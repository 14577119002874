import { PORTFOLIO_WEIGHTINGS, PortfolioWeighting, RiskLevel } from '../constants/portfolioIntended';

/**
 * Returns the risk level for the given portfolio weightings if one exists.
 *
 * @param weighting Set of portfolio weights
 * @returns Matching risk level weights
 */
export function portfolioWeightingToRiskLevel(weighting: PortfolioWeighting): RiskLevel | undefined {
  const { low, medium, high } = PORTFOLIO_WEIGHTINGS;
  if (areWeightingsEqual(weighting, low)) {
    return 'low';
  }
  if (areWeightingsEqual(weighting, medium)) {
    return 'medium';
  }
  if (areWeightingsEqual(weighting, high)) {
    return 'high';
  }
}

function areWeightingsEqual(a: PortfolioWeighting, b: PortfolioWeighting) {
  return a.base === b.base && a.bundles === b.bundles && a.stocks === b.stocks;
}
