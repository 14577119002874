import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import type { SchemaOf } from 'yup';
import { AuthService } from '../../generated/open-api';
import { LoggedOutStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { PasswordInput } from '../../old/inputs';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { ResetComplete } from './ResetComplete';

export type Props = NativeStackScreenProps<LoggedOutStackParamList, 'NewPassword'>;

type ResetPasswordFormSchema = {
  password: string;
  passwordConfirmation: string;
};

const resetPasswordFormSchema: SchemaOf<ResetPasswordFormSchema> = Yup.object().shape({
  password: Yup.string().required('Password required').min(8, 'Minimum 8 characters'),
  passwordConfirmation: Yup.string()
    .required('Required')
    .equals([Yup.ref('password'), null], 'Passwords must match'),
});

const initialValues: ResetPasswordFormSchema = { password: '', passwordConfirmation: '' };

export const NewPassword: React.FC<Props> = ({ navigation, route: { params } }) => {
  const tailwind = useTailwind();
  const { uid, token } = params;
  const toast = useToast();

  const { mutateAsync, isSuccess, isError, error } = useMutation(
    (data: Parameters<typeof AuthService.authUsersResetPasswordConfirm>[0]) =>
      AuthService.authUsersResetPasswordConfirm(data),
  );

  const onSubmit = async ({ password }: ResetPasswordFormSchema, formik: FormikHelpers<ResetPasswordFormSchema>) => {
    await mutateAsync({ data: { new_password: password, token, uid } });
    formik.setSubmitting(false);
  };

  useEffect(() => {
    if (isError) {
      toast.show('Password reset failed. Please request a new link and try again.', { type: 'danger' });
      if (error && error instanceof Error) {
        console.error(error);
      }
    }
  }, [isError, error, toast]);

  if (isSuccess) {
    return <ResetComplete navigation={navigation} />;
  }

  return (
    <SafeAreaView>
      <TitleBar title="Reset Password" />

      <ScrollView>
        <ScreenSidePadding style={tailwind('flex-1')}>
          <View style={tailwind('flex-1')}>
            <Formik
              initialValues={initialValues}
              validationSchema={resetPasswordFormSchema}
              validateOnChange={false}
              onSubmit={onSubmit}
            >
              {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
                <View>
                  <View style={tailwind('py-2')}>
                    <PasswordInput
                      placeholder="Enter new password"
                      onChangeText={handleChange('password')}
                      value={values.password}
                      errorMessage={errors.password}
                    />
                  </View>
                  <View style={tailwind('py-2')}>
                    <PasswordInput
                      placeholder="Confirm new password"
                      onChangeText={handleChange('passwordConfirmation')}
                      value={values.passwordConfirmation}
                      errorMessage={errors.passwordConfirmation}
                    />
                  </View>
                  <View style={tailwind('py-2')}>
                    <Button
                      text={isSubmitting ? 'Loading...' : 'Reset'}
                      size="sm"
                      loading={isSubmitting}
                      isDisabled={isSubmitting}
                      onPress={() => handleSubmit()}
                    />
                  </View>
                </View>
              )}
            </Formik>
          </View>
        </ScreenSidePadding>
      </ScrollView>
    </SafeAreaView>
  );
};
