import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC, useCallback } from 'react';
import { View } from 'react-native';
import { STACK_FIELDS } from '../../fragments/stack';
import { useGetStacksQuery } from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { AllStackParamList } from '../../navigation';
import { StackBody } from '../../old/StackBody';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { useTailwind } from '../../theme';
import { Fab } from '../../ui/Button';
import { CollectionCard } from '../../ui/CollectionCard';
import { PortfolioWeights } from '../../ui/PortfolioWeights';
import { ProgressBar } from '../../ui/ProgressBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePortfolioIntendedPersistedStore } from '../../zustand/store';

export const GET_STACKS = gql`
  ${STACK_FIELDS}
  query GetStacks {
    stacks {
      stacks {
        ...StackFields
      }
    }
  }
`;

export type Props = NativeStackScreenProps<AllStackParamList, 'OnboardingSelectBundles'>;

export const OnboardingSelectBundles: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const { present } = useBottomSheet();
  const { data, error } = useGetStacksQuery();
  const { baseLayer, stackLayer, setStacks, stockLayer } = usePortfolioIntendedPersistedStore(
    ({ baseLayer, stackLayer, setStacks, stockLayer }) => ({ baseLayer, stackLayer, setStacks, stockLayer }),
  );

  if (error) {
    throw error;
  }

  const selectedBundles = stackLayer.constituentIds;

  const onPressSelect = useCallback(
    (bundleId: string) => {
      if (selectedBundles.includes(bundleId)) {
        setStacks(selectedBundles.filter((id) => id !== bundleId));
      } else if (selectedBundles.length < 2) {
        setStacks([...selectedBundles, bundleId]);
      }
    },
    [setStacks, selectedBundles],
  );

  return (
    <SafeAreaView>
      <ScreenSidePadding>
        <TopBar endAdornment={<ProgressBar width={42} progress={4 / 6} />} />
      </ScreenSidePadding>
      <ScrollView contentContainerStyle={tailwind('px-6 pb-24')} stickyHeaderIndices={[3]}>
        <TitleBar text="Pick your bundles" />
        <Text style={tailwind('text-grey text-sm pb-5 pt-8')}>Current layer</Text>
        <View style={tailwind('pb-10')}>
          <PortfolioWeights
            weights={{ base: baseLayer.weight, bundles: stackLayer.weight, stocks: stockLayer.weight }}
            highlight="BUNDLES"
          />
        </View>

        <View style={tailwind('bg-white')}>
          <View style={tailwind('flex-row justify-between items-center')}>
            <Text style={tailwind('text-base text-default font-semibold')}>Select 2 bundles</Text>
            <Text style={tailwind('text-grey text-sm')}>{selectedBundles.length} of 2 Selected</Text>
          </View>
        </View>
        <View style={tailwind('py-4')} />
        {data &&
          data.stacks.stacks?.map((stack) => (
            <View style={tailwind('pb-5')} key={stack?.id}>
              <CollectionCard
                id={stack?.id ?? ''}
                name={stack?.content?.name ?? ''}
                description={stack?.content?.description ?? ''}
                components={stack?.components?.nodes ?? []}
                componentCount={stack?.components?.pageInfo.totalCount ?? 0}
                onPressSelect={onPressSelect}
                onPressOverview={() =>
                  present(
                    <StackBody
                      body={stack?.content?.description ?? ''}
                      components={stack?.components?.nodes ?? []}
                      title={stack?.content?.name ?? ''}
                      totalCount={stack?.components?.pageInfo.totalCount ?? 0}
                      imageUrl={stack?.content?.coverImage?.url ?? ''}
                    />,
                    {
                      snapPoint: '90%',
                      scrollView: true,
                    },
                  )
                }
                collectionType="bundle"
                selected={selectedBundles.includes(stack?.id ?? '')}
              />
            </View>
          ))}
      </ScrollView>
      {selectedBundles.length === 2 && <Fab onPress={() => navigation.navigate('OnboardingSelectStocks')} />}
    </SafeAreaView>
  );
});
