import { VFC } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { GetHomeScreenDataQuery, InstrumentWatchlistItem } from '../../../generated/graphql';
import { InstrumentNavigationWrapper } from '../../../old/InstrumentNavigationWrapper';
import { InstrumentSnippet } from '../../../old/InstrumentSnippet';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';

type Props = {
  title: string;
  data: GetHomeScreenDataQuery['instrumentWatchlistItems'];
  emptyText: string;
};
export const WatchlistSideScroll: VFC<Props> = ({ title, data, emptyText }) => {
  const tailwind = useTailwind();
  return (
    <>
      <Text style={[tailwind('text-sm font-medium px-6 pb-2'), !data.length && tailwind('flex-grow')]}>
        {title}
        {!!data.length && (
          <Text style={tailwind('text-warmGray-500')}>
            {'  '}
            {data.length}
          </Text>
        )}
      </Text>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={tailwind('px-6 py-2')}
        ItemSeparatorComponent={() => <View style={tailwind('px-1')} />}
        data={data}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={() => <EmptyComponent text={emptyText} />}
      />
    </>
  );
};

const keyExtractor = (i: InstrumentWatchlistItem) => i.id;

const renderItem: ListRenderItem<InstrumentWatchlistItem> = ({ item }) => (
  <InstrumentNavigationWrapper instrumentId={item.instrument?.id ?? ''}>
    <InstrumentSnippet {...item.instrument} />
  </InstrumentNavigationWrapper>
);

const EmptyComponent: VFC<{ text: string }> = ({ text }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('items-center justify-center')}>
      <Text style={tailwind('text-warmGray-400 text-sm text-center')}>{text}</Text>
    </View>
  );
};
