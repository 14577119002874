import { BottomTabNavigationProp, BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, CompositeScreenProps, useScrollToTop } from '@react-navigation/native';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { ScrollView as NativeScrollView, View } from 'react-native';
import { IdeasStackParamList } from '../../navigation/IdeasNavigator';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { LightBulb } from '../../old/icons';
import { SegmentedControl } from '../../old/SegmentedControl';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { TitleBarMainIcons } from '../../old/TitleBarMainIcons';
import { TutorialCard } from '../../old/TutorialCard';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { TutorialCardPersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { AllIdeas } from './AllIdeas';
import { MyIdeas } from './MyIdeas';
import { TrackedIdeas } from './TrackedIdeas';

export type Props = CompositeScreenProps<
  NativeStackScreenProps<IdeasStackParamList, 'Ideas'>,
  CompositeScreenProps<BottomTabScreenProps<TabsParamList, 'IdeasTab'>, NativeStackScreenProps<LoggedInStackParamList>>
>;

export type IdeasNavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<IdeasStackParamList, 'Ideas'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<TabsParamList, 'IdeasTab'>,
    NativeStackNavigationProp<LoggedInStackParamList>
  >
>;

const segments = ['My Ideas', 'Tracked Ideas', 'All Ideas'] as const;
type Segment = typeof segments[number];

export const Ideas: React.FC<Props> = withReloadErrorBoundary(({ route, navigation }) => {
  const tailwind = useTailwind();
  const initialSelectedIndex = route.params?.initialSelectedIndex ?? 0;
  const ideasTutorialDismissed = useTutorialCardPersistedStore(ideasTutorialDismissedSelector);
  // scroll to top if press tab
  const ref = React.useRef<NativeScrollView | null>(null);
  useScrollToTop(ref);

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar title="Ideas" hideBackButton showLogo endAdornment={<TitleBarMainIcons />} />

      {!ideasTutorialDismissed && (
        <ScreenSidePadding>
          <TutorialCard
            title="Welcome to Ideas"
            Icon={LightBulb}
            onDismiss={() => useTutorialCardPersistedStore.setState({ ideasTutorialDismissed: true })}
          >
            Ideas are the heart of your Upside journey. Turn those views, beliefs or hunches into fully fledged Ideas.
            {'\n\n'}
            Upside will track them and rate them, and other users can save and respond.
          </TutorialCard>
        </ScreenSidePadding>
      )}

      <View style={tailwind('pt-6 px-6')}>
        <SegmentedControl
          values={segments.slice()}
          onChange={(e) => {
            navigation.setParams({ initialSelectedIndex: e.nativeEvent.selectedSegmentIndex });
          }}
          selectedIndex={initialSelectedIndex}
        />
      </View>
      <ContentBody segment={segments[initialSelectedIndex]} />
    </SafeAreaView>
  );
});

const ContentBody: React.FC<{ segment: Segment }> = ({ segment }) => {
  switch (segment) {
    case 'My Ideas':
      return <MyIdeas />;
    case 'Tracked Ideas':
      return <TrackedIdeas />;
    case 'All Ideas':
      return <AllIdeas />;
  }
};

const ideasTutorialDismissedSelector = (state: TutorialCardPersistedStore) => state.ideasTutorialDismissed;
