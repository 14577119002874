import { gql, MutationHookOptions } from '@apollo/client';
import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as yup from 'yup';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import {
  AppEntityType,
  CloseIdeaMutation,
  CloseIdeaMutationVariables,
  namedOperations,
  Query,
  useCloseIdeaMutation,
} from '../../generated/graphql';
import { usePostIdeaComment } from '../../hooks/mutations/usePostIdeaComment';
import { Button } from '../../old/Button';
import { TextInput } from '../../old/inputs';
import { errorMessage } from '../../sheets/CommentSheet/Input';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { Text } from '../../ui/Text';

export const closeIdea = gql`
  ${CORE_IDEA_FIELDS}
  mutation closeIdea($id: ID!) {
    closeIdea(id: $id) {
      idea {
        ...CoreIdeaFields
      }
      error {
        ... on IdeaAlreadyClosed {
          message
        }
      }
    }
  }
`;

export const commentSchema = yup.object({
  text: yup.string().trim().max(1000, 'Maximum 1000 characters'),
});

export const CloseIdea: React.VFC<{
  dismiss: () => void;
  ideaId: string;
}> = ({ dismiss, ideaId }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  // If comment fails, bottom sheet content changes.
  const [commentState, setCommentState] = useState<'COMMENT_FAILED' | null>(null);
  const [closeIdea] = useCloseIdea({
    variables: { id: ideaId },
  });
  const [postComment] = usePostIdeaComment(ideaId);

  const onSubmit = useCallback(
    async (values: { text: string }, formikHelpers: FormikHelpers<{ text: string }>) => {
      try {
        // Skip the close Idea mutation on subsequent calls after comment failure
        if (commentState !== 'COMMENT_FAILED') {
          // ApolloClient mutations throw if GraphQL error in response so must wrap in separate try catch
          const result = await closeIdea();

          if (result.data?.closeIdea.error?.message) {
            const closeIdeaValidationError = result.data.closeIdea.error.message;
            throw new Error(closeIdeaValidationError);
          }
        }
      } catch (e) {
        toast.show('Something went wrong, please try again.');
        console.error(e);
        return;
      }

      if (values.text) {
        try {
          const commentResult = await postComment({
            variables: { appEntityType: AppEntityType.Idea, ideaId, text: values.text },
          });

          const commentValidationError = commentResult.data?.postComment?.error;
          if (commentValidationError) {
            setCommentState('COMMENT_FAILED');
            formikHelpers.setFieldError('text', errorMessage(commentValidationError));
            return;
          }
        } catch (e) {
          setCommentState('COMMENT_FAILED');
          toast.show('Unable to create closing comment. Please try again.');
          console.error(e);
          return;
        }
      }

      dismiss();
    },
    [closeIdea, commentState, postComment, toast, dismiss, ideaId],
  );

  return (
    <KeyboardAvoidingView style={tailwind('px-6 py-2')}>
      <Text style={tailwind('text-warmGray-800 text-base font-medium text-center pb-4')}>
        {commentState === 'COMMENT_FAILED' ? 'Update your closing comment' : 'Close this Idea now?'}
      </Text>
      {commentState !== 'COMMENT_FAILED' ? (
        <Text style={tailwind('text-warmGray-500 font-normal pb-4')}>
          Don{"'"}t worry, you{"'"}ll be able to create a new one!
        </Text>
      ) : (
        <Text style={tailwind('text-warmGray-500 font-normal pb-4')}>
          Your Idea has been closed but we need you to update your closing comment.
        </Text>
      )}
      <Text style={tailwind('text-warmGray-500 font-medium  text-sm pb-2')}>Closing comment:</Text>
      <Formik validationSchema={commentSchema} initialValues={{ text: '' }} onSubmit={onSubmit}>
        {({ values: { text }, handleChange, errors, submitForm, isSubmitting, isValid }) => (
          <>
            <TextInput
              accessibilityLabel="Comment input"
              value={text}
              placeholder="Why close now? Reached your target? Changed your mind? Let your followers know."
              errorMessage={errors.text}
              multiline
              style={tailwind('py-2 h-28')}
              onChangeText={handleChange('text')}
            />
            <View style={tailwind('py-2')} />
            <Button
              variant="primary"
              text={commentState === 'COMMENT_FAILED' ? 'Update closing comment' : 'Close Idea'}
              loading={isSubmitting}
              isDisabled={isSubmitting || !isValid || (commentState === 'COMMENT_FAILED' && !text)}
              onPress={submitForm}
              label="Confirm close Idea"
            />
            <Button
              variant="inverted"
              text={commentState === 'COMMENT_FAILED' ? 'Close without comment' : 'Go Back'}
              isDisabled={isSubmitting}
              onPress={dismiss}
              style={tailwind('my-1')}
            />
          </>
        )}
      </Formik>
    </KeyboardAvoidingView>
  );
};

const ideasPaginated: keyof Query = 'ideasPaginated';
const publicIdeasPaginated: keyof Query = 'publicIdeasPaginated';
const ideasForPortfolio: keyof Query = 'ideasForPortfolio';

const useCloseIdea = (args?: MutationHookOptions<CloseIdeaMutation, CloseIdeaMutationVariables>) => {
  return useCloseIdeaMutation({
    ...args,
    refetchQueries: [namedOperations.Query.getIdea],
    update: (cache, { data }) => {
      // Evict closed Idea from cache to invalidate Idea detail query and refetch list of other active Ideas on this Instrument
      cache.evict({ id: `Idea:${data?.closeIdea.idea?.id}` });
      // Evict all fields that include a list of Ideas so Closed Idea isn't included in their result sets
      cache.evict({ fieldName: ideasPaginated });
      cache.evict({ fieldName: publicIdeasPaginated });
      cache.evict({ fieldName: ideasForPortfolio });
      cache.gc();
    },
  });
};
