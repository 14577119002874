// @ts-check
// TODO: Make this TypeScript one day
/**
 * export text colours separately to colors.js to only apply to `text-*` tailwind classes
 *
 * Otherwise would generate `text-text-dark` utility class which is a bit confusing
 *
 * can also import this object directly and reference:
 * `import { textColors } from '../../theme';`
 * `const color = textColors.white;`
 *
 */
module.exports = {
  textColors: {
    dark: '#212121',
    default: '#134e4a',
    grey: '#9e9e9e',
    light: '#14b8a6',
    white: '#ffffff',
  },
};
