import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

type CommentType = 'comments' | 'replies';

type Props = {
  commentType: CommentType;
  style?: StyleProp<ViewStyle>;
};

export const EmptyComments: React.VFC<Props> = ({ commentType, style }) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('flex content-center px-6 py-8'), style]}>
      <Text style={tailwind('text-center')}>{text(commentType)}</Text>
      <Text style={tailwind('text-center')}> Start the conversation!</Text>
    </View>
  );
};

const text = (commentType: CommentType): string => {
  switch (commentType) {
    case 'comments':
      return 'No comments on this idea yet.';
    case 'replies':
      return 'No replies on this comment yet.';
  }
};
