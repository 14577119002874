import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { RefreshControl, View } from 'react-native';
import { GetMarketInstrumentQuery, useGetMarketInstrumentQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { AllStackParamList } from '../../navigation';
import { MAX_PRICE_CHART_TIME_INTERVAL } from '../../old/Charts/IntervalChart';
import { InstrumentChart } from '../../old/InstrumentChart';
import { InstrumentHeader } from '../../old/InstrumentHeader';
import { InstrumentDescription } from '../../old/InstrumentSummary/InstrumentDescription';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { calculateTimeInterval, formatDate, parseUTCDateFromString } from '../../util/date';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export type Props = NativeStackScreenProps<AllStackParamList, 'MarketInstrument'>;

export const getMarketInstrument = gql`
  query getMarketInstrument($id: ID!, $dateFrom: Date) {
    instrument(id: $id) {
      id
      displayName
      ticker
      logoUrl
      companyInfo
      closePriceSeries(dateFrom: $dateFrom) {
        id
        price
        date
      }
      quotePrice {
        id
        midPrice
        previousClose
        lastUpdated
      }
      currency {
        id
        iso
      }
    }
  }
`;

export const MarketInstrument: React.FC<Props> = withReloadErrorBoundary(({ route }) => {
  const tailwind = useTailwind();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const { instrumentId } = route.params;
  // date now to get all prices prior to this
  const [nowDate] = useState(new Date());
  // dateFrom to query all 5Y of prices
  const [dateFrom] = useState<string>(
    formatDate(calculateTimeInterval(MAX_PRICE_CHART_TIME_INTERVAL, nowDate), 'YYYY-MM-DD'),
  );

  const { data, refetch, loading, error } = useGetMarketInstrumentQuery({
    variables: {
      id: instrumentId,
      // get all 5Y of prices
      dateFrom,
    },
  });

  const { onRefresh, refreshing } = useRefresh(refetch);

  if (error) {
    throw new Error(`Error fetching instrument with id ${instrumentId}`);
  }

  const instrument = data?.instrument;

  return (
    <SafeAreaView>
      <TitleBar redirectIfLoggedOut showLogo={!loggedIn} hideBackButton={!loggedIn} />

      {loading ? (
        <InstrumentSkeleton />
      ) : (
        <ScrollView refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}>
          <InstrumentHeader
            name={instrument?.displayName ?? ''}
            ticker={instrument?.ticker ?? ''}
            logoUrl={instrument?.logoUrl ?? undefined}
            hideLogo
          />
          <InstrumentChart
            closePriceSeries={instrument?.closePriceSeries ?? []}
            now={nowDate}
            currencyIso={instrument?.currency?.iso ?? undefined}
            currentPrice={getCurrentPrice(instrument?.quotePrice)}
          />
          <ScreenSidePadding style={tailwind('py-4')}>
            <Text style={tailwind('text-xl font-semibold text-warmGray-800 py-4')}>Summary</Text>
            {instrument?.companyInfo && <InstrumentDescription>{instrument?.companyInfo}</InstrumentDescription>}
          </ScreenSidePadding>
        </ScrollView>
      )}
    </SafeAreaView>
  );
});

const getCurrentPrice = (
  quotePrice: GetMarketInstrumentQuery['instrument']['quotePrice'] | undefined,
): { price: number; date: Date } | undefined =>
  !isNil(quotePrice?.midPrice) && quotePrice?.lastUpdated
    ? {
        price: quotePrice.midPrice,
        date: parseUTCDateFromString(quotePrice.lastUpdated),
      }
    : undefined;

export const InstrumentSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      <SkeletonView>
        <View style={tailwind('mb-2')}>
          <Skeleton style={tailwind('w-40 h-4')} />
        </View>
        <View style={tailwind('mb-4')}>
          <Skeleton style={tailwind('w-64 h-5')} />
        </View>
        <View>
          <Skeleton style={tailwind('w-full h-48')} />
        </View>
      </SkeletonView>
    </ScreenSidePadding>
  );
};
