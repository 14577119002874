import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export function CorrelationNegativeFilled(props: SvgProps) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Circle cx={18} cy={18} r={18} fill="#0F766E" />
      <Path d="M11 11l13 13" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
      <Path d="M11 25l13-13" stroke="#0F766E" strokeWidth={6} strokeLinecap="round" />
      <Path d="M11 25l13-13" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
      <Path d="M25 15v-4h-4M25 21v4h-4" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
}
