import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { TextInput } from 'react-native';
import Animated, { useAnimatedReaction } from 'react-native-reanimated';
import { green, red } from './colors';
import { formatPercentageDiff, formatPriceDiff } from './format';
import { Props } from './props';

Animated.addWhitelistedNativeProps({ text: true });
const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

const formatDiff = (price: number, basePrice: number, currencySymbol?: string): string => {
  'worklet';
  const diffFormatted = formatPriceDiff(price, basePrice, currencySymbol);
  const percentage = formatPercentageDiff(price, basePrice);
  const text = `${diffFormatted} ${percentage}`;
  return text;
};

export const PriceDifference: React.VFC<Props> = ({ basePrice, price, currencyIso }) => {
  const inputRef = React.useRef<TextInput>(null);
  const currencySymbol = currencyIso ? getSymbolFromCurrency(currencyIso) : '';

  useAnimatedReaction(
    () => basePrice.value?.toString() + price.value.toString(),
    () => {
      if (!basePrice.value) {
        inputRef?.current?.setNativeProps({
          value: '',
        });
      } else {
        const text = formatDiff(price.value[1], basePrice.value[1], currencySymbol);
        inputRef?.current?.setNativeProps({
          value: text,
          style: {
            color: price.value[1] - basePrice.value[1] >= 0 ? green : red,
          },
        });
      }
    },
  );
  return <AnimatedTextInput ref={inputRef} underlineColorAndroid="transparent" editable={false} />;
};
