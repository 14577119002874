import { NativeStackScreenProps } from '@react-navigation/native-stack';
import getSymbolFromCurrency from 'currency-symbol-map';
import * as ClipBoard from 'expo-clipboard';
import { Formik } from 'formik';
import React from 'react';
import { View } from 'react-native';
import { BorderlessButton } from 'react-native-gesture-handler';
import { useToast } from 'react-native-toast-notifications';
import * as Yup from 'yup';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { Copy, SendArrow } from '../../old/icons';
import { PriceInput } from '../../old/inputs';
import { TitleBar } from '../../old/TitleBar';
import { colors, useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { formatPrice } from '../../util/number';
import { isFiniteNumber } from '../../util/typeGuards';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'Deposit'>;
export const Deposit: React.VFC<Props> = withReloadErrorBoundary(() => {
  const tailwind = useTailwind();
  const [price, setPrice] = React.useState<number | null>(null);

  return (
    <SafeAreaView>
      <TitleBar />
      <ScrollView>
        <View style={tailwind('px-6 flex justify-between')}>
          {isFiniteNumber(price) ? (
            <View style={tailwind('my-1 py-1 px-6 bg-teal-100 rounded')}>
              <Text style={tailwind('text-warmGray-700 text-lg font-normal py-1')}>
                We are anticipating your deposit of {formatPrice(price ?? 0, 'GBP')}
              </Text>
              <Text style={tailwind('text-warmGray-800 text-base font-normal py-1')}>
                We{"'"}ll keep an eye out for your deposit and let you know as soon as we receive it
              </Text>
              <Text style={tailwind('text-warmGray-800 text-base font-normal py-1')}>
                Soon you{"'"}ll be able to use these funds to invest!
              </Text>
            </View>
          ) : (
            <View>
              <Text style={tailwind('text-xl text-warmGray-700 font-normal py-2')}>Deposit value</Text>
              <Text style={tailwind('text-base text-warmGray-800 font-normal py-2 ')}>
                How much do you want to deposit?
              </Text>
              <View style={tailwind('my-2')}>
                <Text style={tailwind('text-lg text-warmGray-700 font-normal py-1')}>Deposit amount</Text>
                <Formik
                  isInitialValid={false}
                  validationSchema={depositSchema}
                  validateOnChange={true}
                  initialValues={{ amount: null }}
                  onSubmit={({ amount }) => {
                    setPrice(amount);
                  }}
                >
                  {({ values, isValid, handleSubmit, setFieldValue, errors }) => (
                    <>
                      <View style={tailwind('flex-row justify-between items-center')}>
                        <PriceInput
                          value={values.amount}
                          placeholder="Deposit amount"
                          onChange={(newPrice) => {
                            setFieldValue('amount', newPrice, true);
                          }}
                          containerStyles={tailwind('flex-grow')}
                          startAdornment={getSymbolFromCurrency('GBP')}
                        />
                        <BorderlessButton
                          enabled={isValid}
                          onPress={() => {
                            handleSubmit();
                          }}
                          style={tailwind('ml-4')}
                        >
                          <SendArrow
                            color={isValid ? colors.teal['700'] : colors.warmGray['300']}
                            style={{ transform: [{ rotate: '90deg' }] }}
                          />
                        </BorderlessButton>
                      </View>
                      {Boolean(errors.amount) && <Text style={tailwind('text-red-600 my-2')}>{errors.amount}</Text>}
                    </>
                  )}
                </Formik>
              </View>
            </View>
          )}
        </View>
        {isFiniteNumber(price) && (
          <View style={tailwind('px-6')}>
            <AccountField label="Unique payment reference" value="QUINN2205" />
            <AccountField label="Bank account number" value="1033223" />
            <AccountField label="Sort code" value="04-00-04" />
            <AccountField label="Bank account holder name" value="Wealth Kernel Ltd" />
            <AccountField label="Bank address" value={'5 Berwick Street\n London, EC1 4DE\n United Kingdom '} />
            <Button
              style={tailwind('mx-6 my-2')}
              text="Cancel"
              onPress={() => {
                console.log('Cancel Deposit');
              }}
            />
          </View>
        )}
      </ScrollView>
    </SafeAreaView>
  );
});

const AccountField: React.VFC<{ label: string; value: string }> = ({ label, value }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  return (
    <View style={tailwind('py-1')}>
      <Text style={tailwind('text-warmGray-700 text-base font-light py-1')}>{label}</Text>
      <View style={tailwind('rounded-md bg-warmGray-100 flex-row justify-between items-center p-2')}>
        <Text style={tailwind('text-warmGray-800 text-lg font-medium')}>{value}</Text>
        <BorderlessButton
          onPress={async () => {
            const success = await copyToClipboard(value);
            if (!success) {
              toast.show('Failed to copy text to clipboard');
            }
          }}
        >
          <Copy stroke={colors.warmGray['700']} />
        </BorderlessButton>
      </View>
    </View>
  );
};

const copyToClipboard = async (text: string): Promise<{ success: boolean }> => {
  const success = await ClipBoard.setStringAsync(text);
  return {
    success,
  };
};

type DepositSchema = {
  amount: number | undefined;
};

const ERROR_MESSAGE = 'Amount must be between £1 and £10,000';

const depositSchema: Yup.SchemaOf<DepositSchema> = Yup.object().shape({
  amount: Yup.number().min(1, ERROR_MESSAGE).max(10000, ERROR_MESSAGE).defined(ERROR_MESSAGE).typeError(ERROR_MESSAGE),
});
