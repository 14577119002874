import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export function Laugh(props: SvgProps) {
  return (
    <Svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <Path
        d="M36 27a12 12 0 01-24 0h24zM36 21a4 4 0 10-8 0M20 21a4 4 0 10-8 0"
        stroke="#A3A3A3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M24 45c11.598 0 21-9.402 21-21S35.598 3 24 3 3 12.402 3 24s9.402 21 21 21z"
        stroke="#A3A3A3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
