import { useStore } from '../../zustand/store';
import { analytics } from '../analytics';

type TrackUTMArgs = { installUtmSource: string; installUtmMedium: string; installUtmCampaign: string };

/** common method to track utm params and store in zustand to use in sign up later */
export const trackUTM = ({ installUtmSource, installUtmMedium, installUtmCampaign }: TrackUTMArgs) => {
  analytics.track('Logged out UTM params', { installUtmSource, installUtmMedium, installUtmCampaign });
  useStore.setState({ installUtmSource, installUtmMedium, installUtmCampaign });
};
