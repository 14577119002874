import React, { useState } from 'react';

import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { BaseInput, Props as BaseInputProps } from './BaseInput';

type Props = Omit<BaseInputProps, 'secureTextEntry' | 'autoCapitalize'>;

export const PasswordInput: React.FC<Props> = (props) => {
  const tailwind = useTailwind();
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  return (
    <View style={tailwind('flex-row')}>
      <BaseInput {...props} autoCapitalize="none" secureTextEntry={hidePassword} />
      <Text onPress={() => setHidePassword(!hidePassword)} style={tailwind('absolute top-3 right-2 text-teal-800')}>
        {hidePassword ? 'Show' : 'Hide'}
      </Text>
    </View>
  );
};
