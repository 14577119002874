import React from 'react';
import Animated from 'react-native-reanimated';
import { Ticks } from '../ticks';

export type ChartContextVariables = {
  height: Animated.SharedValue<number>;
  chartWidth: Animated.SharedValue<number>;
  data: Animated.SharedValue<{
    prices: [number, number][];
    ticks: Ticks | undefined;
    colors: { path: string; dot: string; gradient: string } | undefined;
  }>;
};

export const ChartContext = React.createContext<ChartContextVariables | null>(null);
