import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { formatPercent } from '../../../util/number';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { TrendDown } from '../../icons';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';

export type Props = NarrowByTypename<FeedCardFieldsFragment, 'NetShortDisclosureFeedItem'>;

export const NetShortDisclosureCard: React.FC<Props> = ({
  id,
  totalReportedPosition,
  reportDate,
  positions,
  instrument,
  __typename,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, instrument }}
        icon={<TrendDown />}
        eventTime={reportDate ? new Date(reportDate) : undefined}
      >
        Net Short Position Update
      </CardTitle>
      <CardHeadline>
        <CardHeadlineText>
          Significant Net Short position reported on{' '}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Destination screen': 'Instrument',
                'Feed card type': __typename,
                'Feed item id': id,
                'Instrument ticker': instrument?.ticker ?? '',
              });
            }}
          >
            {instrument?.name}
          </Link>
          .
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Destination screen': 'Instrument',
            'Feed card type': __typename,
            'Feed item id': id,
            'Instrument ticker': instrument?.ticker ?? '',
          });
        }}
      >
        <View>
          <InstrumentPriceRow {...instrument} />
        </View>
        <View style={tailwind('flex-row mt-3')}>
          <View style={tailwind('flex-1 bg-warmGray-100 mr-1 rounded p-0.5')}>
            <Text style={tailwind('font-light text-xs text-center text-warmGray-500')}>Market cap short</Text>
            <Text style={tailwind('font-normal text-sm text-center text-warmGray-700')}>
              {totalReportedPosition && formatPercent(totalReportedPosition, 2, false)}
            </Text>
          </View>
          <View style={tailwind('flex-1 bg-warmGray-100 ml-1 rounded p-0.5')}>
            <Text style={tailwind('font-light text-xs text-center text-warmGray-500')}>Significant positions</Text>
            <Text style={tailwind('font-normal text-sm text-center text-warmGray-700')}>{positions?.length ?? 0}</Text>
          </View>
        </View>
      </OutlineInnerCard>
    </Card>
  );
};
