import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { noop } from 'lodash';
import { StyleProp, View, ViewStyle } from 'react-native';
import { AccountStackParamList } from '../../navigation/AccountNavigator';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { Button } from '../../old/Button';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { formatPrice } from '../../util/number';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

const data = [
  { title: 'Available to invest', value: 120 },
  { title: 'Withdrawable cash', value: 120 },
  { title: 'Reserved cash', value: 28 },
];

// export type Props = NativeStackScreenProps<LoggedInStackParamList, 'CashBalance'>;
export type Props = CompositeScreenProps<
  NativeStackScreenProps<AccountStackParamList>,
  CompositeScreenProps<
    BottomTabScreenProps<TabsParamList, 'AccountTab'>,
    NativeStackScreenProps<LoggedInStackParamList>
  >
>;

export const Account: React.VFC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  return (
    <SafeAreaView>
      <TitleBar hideBackButton />
      <ScrollView>
        <Text style={tailwind('text-warmGray-800 text-xl font-normal px-6')}>Account</Text>
        <View style={tailwind('py-6')}>
          {data.map((item) => (
            <Row key={item.title} title={item.title} value={item.value} currencyIso="GBP" style={tailwind('px-6')} />
          ))}
        </View>
        <View style={tailwind('flex-row justify-center px-3')}>
          <View style={tailwind('flex-1 px-3')}>
            <Button text="Withdraw" size="md" onPress={noop} />
          </View>
          <View style={tailwind('flex-1 px-3')}>
            <Button
              text="Deposit"
              size="md"
              onPress={() => {
                navigation.navigate('Deposit', undefined);
              }}
            />
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
});

const Row: React.VFC<{ title: string; value: number; currencyIso: string; style?: StyleProp<ViewStyle> }> = ({
  title,
  value,
  currencyIso,
  style,
}) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('flex-row justify-between items-center py-1'), style]}>
      <Text style={tailwind('text-warmGray-700 text-base font-light')}>{title}</Text>
      <Text style={tailwind('text-warmGray-800 text-base font-normal')}>{formatPrice(value, currencyIso)}</Text>
    </View>
  );
};
