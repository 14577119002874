import { isNumber } from 'lodash';
import { Money, NoUndefinedField } from './types';

/**
 * Typesafe way of removing null/undef values. Usage: `array.filter(isNotNull)`
 */
export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null && it != undefined;
}

/**
 * Typesafe way of any object which has any property value as null/undef or is null/undef itself. Usage: `array.filter(isNotNullKeys)`
 */
export function isNotNullKeys<T>(it: T): it is NonNullable<NoUndefinedField<T>> {
  if (it != null) {
    const values = Object.values(it);
    for (let i = 0; i < values.length; i++) {
      if (values[i] == null) return false;
    }
    return true;
  }
  return false;
}

/**
 * Returns true if value is null or undefined
 */
export function isNullish(it: unknown): it is null | undefined {
  return it === null || it === undefined;
}

/**
 *  Typeguard to ensure value is of the Money type
 *
 */
export function isMoney(value?: string | number | Record<string, unknown>): value is Money {
  if (!value || typeof value !== 'object') return false;
  return 'amount' in value && 'currencyIso' in value;
}

/**
 * Assert the value is a finite number. -Infinity < value < Infinity. Also returns false value === NaN
 */
export const isFiniteNumber = (value: unknown): value is number => isNumber(value) && isFinite(value);

export const isNotNullOrUndefined = <T>(value: T): value is NonNullable<T> => value !== null && value !== undefined;
