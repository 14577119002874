import React from 'react';
import { View } from 'react-native';
import { Idea, Instrument } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { InstrumentLogo } from '../InstrumentLogo';
import { InstrumentNavigationWrapper } from '../InstrumentNavigationWrapper';
import { IdeaPositionBox } from './IdeaPositionBox';

export type Props = Pick<Idea, 'position'> &
  Pick<Instrument, 'name' | 'ticker' | 'logoUrl'> & { instrumentId?: string };

export const InstrumentSnapshot: React.FC<Props> = ({ position, name, ticker, logoUrl, instrumentId }) => {
  const tailwind = useTailwind();
  return (
    <InstrumentNavigationWrapper instrumentId={instrumentId ?? ''}>
      <View style={tailwind('py-3 px-3 flex-row items-center')}>
        <View style={tailwind('mr-2')}>
          <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
        </View>
        <View style={tailwind('flex-1')}>
          <Text style={tailwind('text-sm font-medium text-warmGray-800')} ellipsizeMode="tail" numberOfLines={1}>
            {name ?? ''}
          </Text>
          <Text style={tailwind('text-xs text-warmGray-400')}>{ticker ?? ''}</Text>
        </View>
        <View>
          <IdeaPositionBox
            position={position}
            style={tailwind('h-6 w-20')}
            iconHeight={9}
            iconWidth={9}
            textStyle={tailwind('text-sm')}
          />
        </View>
      </View>
    </InstrumentNavigationWrapper>
  );
};
