import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Placeholder, PlaceholderMedia, ShineOverlay } from 'rn-placeholder';
import { PlaceholderProps } from 'rn-placeholder/lib/Placeholder';
import { PlaceholderMediaProps } from 'rn-placeholder/lib/PlaceholderMedia';

/**
 * Provides animation to child skeletons. A container for skeleton content.
 *
 * @param param0 children
 */
export const SkeletonView: React.FC<PlaceholderProps> = ({ children, ...rest }) => {
  return (
    <Placeholder accessibilityRole="progressbar" Animation={ShineOverlay} {...rest}>
      {children}
    </Placeholder>
  );
};

// Skeleton component. Must be wrapped in SkeletonView for animation to play.
export const Skeleton: React.FC<PlaceholderMediaProps> = (props) => (
  <PlaceholderMedia {...props} style={[{ width: undefined }, props.style]} />
);
