import Svg, { Mask, Path, SvgProps } from 'react-native-svg';

export const Torch = (props: SvgProps) => (
  <Svg width={360} height={360} viewBox="0 0 256 256" fill="none" {...props}>
    <Path
      d="M13.339 107.312a96.334 96.334 0 0 1 8.76-27.164l3.805-7.667c13.372-26.947 38.634-46.29 68.632-52.55L142.52 9.916c18.214-3.801 37.274-1.772 54.387 5.79 21.428 9.468 38.207 26.899 46.587 48.395l2.25 5.772c6.155 15.788 8.119 32.797 5.707 49.419l-7.929 54.657c-7.004 44.394-46.854 76.198-92.794 74.06l-80.525-3.749c-27.32-1.272-51.564-18.098-61.955-42.998-4.872-11.674-6.372-24.395-4.335-36.758l9.425-57.192Z"
      fill="#FEF2F2"
    />
    <Path
      d="M39.646 227.473a2921.432 2921.432 0 0 1 135.318-2.66c12.616.038 25.194.19 37.81.38.19 0 .19-.266 0-.304-45.106-.76-90.25-.456-135.356.912-12.616.38-25.194.836-37.772 1.368-.19.038-.19.304 0 .304Z"
      fill="#202124"
    />
    <Path
      d="M159.08 78.285c-.152.342-.19.76-.228 1.33a5.219 5.219 0 0 0 .19 2.014c.114.456.456 1.596 1.52 2.508.152.114 1.558 1.33 3.534 1.102.532-.076 1.368-.152 1.976-.798.532-.57.608-1.216.722-2.318.076-.76.152-1.368-.076-2.128-.038-.114-.342-1.14-1.178-1.9-1.976-1.748-5.7-1.292-6.46.19Z"
      fill="#fff"
    />
    <Path
      d="M158.89 78.551c-.456 1.9-.114 4.028 1.292 5.472a4.687 4.687 0 0 0 5.092 1.026c.152-.076.076-.342-.076-.266-3.99 1.672-6.878-2.546-6.042-6.156.038-.19-.228-.266-.266-.076Z"
      fill="#202124"
    />
    <Path
      d="M159.422 79.425c-.076.266-.114.57-.076.836 0 .19.304.19.304 0 0-.266.038-.494.076-.76 0-.19-.266-.266-.304-.076Z"
      fill="#202124"
    />
    <Path
      d="M159.042 78.665c.646-.76 1.558 1.178 1.748 1.444.304.418.76.874 1.254 1.026.228.076.494.152.76.114.114-.038.228-.076.38-.152.076-.076.19-.114.266-.19.76-.722 1.558-.304 2.394 1.216 0 .19.304.19.304 0-.038-.722-.266-1.444-.95-1.786-.836-.418-1.178.114-1.862.342-1.368.456-2.09-.418-2.736-1.444-.19-.304-.304-.722-.646-.912-.38-.266-.798-.19-1.102.152-.114.152.076.342.19.19Z"
      fill="#202124"
    />
    <Path
      d="M159.156 78.551c.836-4.598 7.106-5.624 8.778-.988.076.19.342.114.266-.076-1.748-4.864-8.436-3.952-9.31.988-.038.19.266.266.266.076Z"
      fill="#202124"
    />
    <Path
      d="M176.826 74.827c1.254 3.002.874 7.106-1.026 8.132-.76.38-1.14 0-3.382-.228-4.408-.532-5.282.646-7.41-.266-1.786-.76-3.268-2.508-3.686-4.522-.418-2.014.152-4.712 1.672-5.396 1.026-.456 1.71.342 2.926-.152 1.558-.646 1.368-2.28 2.508-2.964 1.976-1.102 6.726 1.406 8.398 5.396Z"
      fill="#202124"
    />
    <Path
      d="M159.004 78.59c.19-.077.266-.267.494-.267.342 0 .57.418.95 1.026.608.988 1.026 1.558 1.634 1.71.38.076.912 0 1.292-.228.228-.114.19-.19.38-.304.418-.228 1.102-.228 1.596.114.608.418.684 1.178.684 1.558.038.342-.038.646-.076.836.38-.19 1.368-.722 1.976-1.9.152-.266.798-1.52.456-3.078-.152-.76-.494-1.292-.646-1.558-.684-.988-1.596-1.444-2.242-1.748-.646-.304-2.698-1.216-5.13-.57-.456.114-3.382.912-3.42 2.394 0 1.064 1.406 2.204 2.052 2.014Z"
      fill="#202124"
    />
    <Path
      d="M176.484 141.213c-.608.114-.874.418-2.204 1.748-3.268 3.306-3.496 3.42-4.674 4.826a78.833 78.833 0 0 0-1.672 2.09c.646.342 1.672.836 3.04 1.026 1.216.152 2.204.076 2.888-.076 1.406-.684 2.356-1.482 2.926-2.09 1.406-1.368 2.052-2.812 2.432-3.648.418-.912.646-1.71.798-2.318-.646-.646-2.052-1.862-3.534-1.558Z"
      fill="#fff"
    />
    <Path
      d="M177.396 96.677a6.775 6.775 0 0 1 1.938-6.536c1.786-1.672 4.37-2.28 6.726-1.52.95 1.254 2.28 3.268 3.496 5.928.912 2.014 2.09 5.7 3.116 15.998.342 3.382.608 7.866.456 13.186-1.102 1.672-2.926 4.37-4.028 6.08-3.04 4.598-6.118 9.234-9.196 13.832a9.824 9.824 0 0 1-1.596-.874 8.795 8.795 0 0 1-1.824-1.596c1.216-3.952 2.508-8.018 3.952-12.198 1.102-3.192 2.242-6.27 3.382-9.31a165.555 165.555 0 0 0-3.382-13.642 159.088 159.088 0 0 0-3.04-9.348Z"
      fill="#fff"
    />
    <Path
      d="M188.568 92.421c1.558 9.5 3.154 18.962 4.712 28.462.038.19.304.114.266-.076-1.558-9.5-3.154-18.962-4.712-28.462 0-.19-.304-.114-.266.076ZM177.244 96.715a208.818 208.818 0 0 1 6.346 22.914c.038.19.304.114.266-.076-1.672-7.752-3.762-15.39-6.346-22.914-.038-.152-.304-.076-.266.076Z"
      fill="#202124"
    />
    <Path
      d="M183.514 119.591a310.337 310.337 0 0 0-6.992 21.052c-.038.19.228.266.266.076 2.09-7.106 4.446-14.098 6.992-21.052.076-.19-.19-.266-.266-.076ZM192.938 123.695l-12.54 18.924c-.114.152.152.304.228.152l12.54-18.924c.152-.152-.114-.304-.228-.152ZM176.37 141.289a11.087 11.087 0 0 0 2.774 2.204c.152.076.304-.152.152-.228a11.51 11.51 0 0 1-2.736-2.128c-.114-.19-.304.038-.19.152ZM176.18 143.417c.266.988-.912 1.786-1.52 2.356-.57.57-1.14 1.102-1.71 1.672-.114.114.076.342.19.19.874-.836 1.748-1.672 2.584-2.508.494-.494.874-1.064.684-1.786 0-.152-.304-.076-.228.076ZM174.584 142.505c-2.432 2.052-4.598 4.408-6.498 6.992-.114.152.152.304.228.152a38.452 38.452 0 0 1 6.422-6.954c.19-.114 0-.304-.152-.19ZM169.454 150.295l2.85-1.596c.152-.076 0-.342-.152-.228l-2.85 1.596c-.152.076 0 .342.152.228ZM171.088 150.637c1.52-.038 2.926-.494 4.142-1.368.152-.114 0-.342-.152-.228-1.178.874-2.546 1.292-3.99 1.33-.19 0-.19.304 0 .266ZM173.938 150.941a9.777 9.777 0 0 0 4.37-3.952c.076-.152-.152-.304-.228-.152-.988 1.71-2.47 3.002-4.256 3.838-.228.114-.076.342.114.266Z"
      fill="#202124"
    />
    <Path
      d="M181.22 240.547v-.001a199.204 199.204 0 0 1 4.757-1.985l1.053-.429 3.209-.225 3.043-.214c.306 1.15.621 2.301.935 3.45.305 1.115.61 2.228.907 3.342-8.347.254-12.8.171-15.174-.071-1.207-.124-1.848-.286-2.187-.45a.727.727 0 0 1-.283-.209.343.343 0 0 1-.06-.177l-.001-.009-.001-.008a2.529 2.529 0 0 0-.045-.201.806.806 0 0 1-.019-.174c.003-.109.039-.264.219-.484.375-.461 1.33-1.149 3.647-2.155Z"
      fill="#fff"
      stroke="#292524"
      strokeWidth={0.5}
    />
    <Path
      d="M183.666 145.507c.228 1.444.532 3.572.912 6.118.114.836 1.33 9.158 2.508 18.696 2.47 19.95 4.294 40.812 4.636 44.612.494 5.776 1.14 13.414 1.786 22.458a6.097 6.097 0 0 1-2.964 1.026 6.258 6.258 0 0 1-4.104-1.178c-2.318-6.232-6.118-16.758-9.69-28.196-1.71-5.434-3.154-10.412-3.344-11.134-1.102-3.838-2.242-7.752-3.534-12.882-2.166-8.55-3.268-12.806-3.344-13.376-.19-1.33-.57-4.56-2.546-8.132-.608-1.102-.874-1.368-1.216-1.976-2.128-3.762-1.52-11.21 3.344-15.58 4.636-4.142 12.046-4.446 17.556-.456Z"
      fill="#115E59"
    />
    <Path
      d="M184.16 149.649c4.104 28.576 7.106 57.304 8.93 86.146 0 .19.304.19.304 0a1075.788 1075.788 0 0 0-8.93-86.222c-.076-.19-.342-.114-.304.076ZM163.83 164.317c2.508 5.662 3.572 11.97 5.092 17.936a520.119 520.119 0 0 0 5.13 18.658c3.61 12.274 7.714 24.396 12.198 36.366.076.152.342.114.266-.076a550.986 550.986 0 0 1-12.388-37.088 522.105 522.105 0 0 1-5.13-18.696c-1.482-5.776-2.508-11.818-4.94-17.29-.076-.114-.304.038-.228.19ZM187.58 238.189c2.014.722 4.142.494 5.966-.646.152-.114 0-.342-.152-.228-1.748 1.102-3.8 1.33-5.738.608-.19-.076-.228.19-.076.266Z"
      fill="#202124"
    />
    <Path
      d="M182.678 239.595a2.246 2.246 0 0 0-.038 1.786c.076.152.342.114.266-.076a2.111 2.111 0 0 1 .038-1.596c.076-.152-.19-.266-.266-.114ZM184.008 239.063a2.013 2.013 0 0 0-.038 1.862c.076.152.342.038.228-.152-.266-.494-.228-1.064.038-1.558.114-.152-.152-.304-.228-.152ZM185.034 238.607a1.628 1.628 0 0 0 .228 1.444c.114.152.342 0 .228-.152-.228-.38-.304-.798-.19-1.216.038-.19-.228-.266-.266-.076Z"
      fill="#292524"
    />
    <Path
      d="M185.11 238.455c-.304-.228-.874-.76-.798-1.178.57-.608.836-.57.722.114 0 .342-.076.646-.19.95.076-.038.152-.038.228-.076-.76-.646-2.28 1.9-1.786 2.622.912 1.368 1.862-1.938 1.938-2.356.038-.19-.228-.266-.266-.076-.076.418-.19 1.672-.608 1.862-.266-.038-.57-.076-.836-.114a4.53 4.53 0 0 1 .304-.76c.114-.19.836-1.178 1.064-.988.076.114.19.076.228 0 .114-.494.456-2.66-.684-2.204-1.102.418-.038 2.014.494 2.432.152.114.342-.114.19-.228Z"
      fill="#292524"
    />
    <Path
      d="m163.602 242.076.001-.001c1.835-.587 3.423-1.068 4.66-1.443l.1-.03c1.591-.145 3.218-.29 4.846-.434l.75 3.466.001.004c.244 1.124.488 2.247.732 3.378-8.375-.134-12.515-.401-14.619-.635-1.065-.118-1.602-.227-1.9-.304a4.392 4.392 0 0 1-.269-.078l-.049-.015a1.31 1.31 0 0 0-.134-.035l-.009-.007c-.037-.031-.084-.062-.124-.089l-.017-.011c-.083-.056-.172-.115-.254-.186a.643.643 0 0 1-.18-.228.323.323 0 0 1 .007-.255c.047-.117.157-.276.393-.477.233-.199.579-.43 1.075-.692.993-.524 2.564-1.161 4.99-1.928Z"
      fill="#fff"
      stroke="#292524"
      strokeWidth={0.5}
    />
    <Path
      d="M151.556 146.495c.19.912.494 2.28.76 3.99.722 4.598.76 7.98.836 9.918.076 1.938.494 5.624 1.406 12.996 2.318 19.266 5.966 37.658 5.966 37.658a469.602 469.602 0 0 0 7.068 30.362c.494.19 1.786.646 3.344.342 1.178-.228 1.976-.798 2.394-1.102.038-2.242.114-5.548.228-9.614.304-12.958.684-27.512 1.026-36.746a715.113 715.113 0 0 1 2.66-39.862l.456-17.328c-8.702 3.116-17.404 6.27-26.144 9.386Z"
      fill="#115E59"
    />
    <Path
      d="M152.012 148.129c1.71 24.244 5.13 48.374 10.298 72.162a525.188 525.188 0 0 0 4.864 20.292c.038.19.304.114.266-.076a503.284 503.284 0 0 1-15.124-92.378c-.038-.19-.304-.19-.304 0ZM176.56 159.605c-1.976 26.562-3.04 53.2-3.154 79.838 0 .19.266.19.304 0a1142.266 1142.266 0 0 1 3.154-79.838c0-.19-.304-.19-.304 0ZM167.554 241.533c1.976.798 4.18.494 5.89-.798.152-.114 0-.342-.152-.228-1.672 1.254-3.724 1.52-5.662.76-.152-.076-.228.228-.076.266Z"
      fill="#202124"
    />
    <Path
      d="M163.184 241.761a3.279 3.279 0 0 0-.836 2.47c0 .19.304.19.304 0a2.987 2.987 0 0 1 .76-2.28c.114-.114-.114-.342-.228-.19ZM164.362 241.457a3.016 3.016 0 0 0-.532 2.204c.038.19.304.114.266-.076a2.604 2.604 0 0 1 .494-1.976c.114-.152-.152-.266-.228-.152ZM165.768 241.001c-.228.266-.342.608-.38.95-.038.342-.076.874.114 1.178.038.076.114.076.19.038h.038a.288.288 0 0 0 .076-.152v-.038c-.038-.19-.342-.114-.266.076v.038c.038-.038.038-.114.076-.152h-.038c.076 0 .114.038.19.038-.114-.228-.076-.608-.076-.836 0-.342.114-.684.342-.95.038-.152-.152-.342-.266-.19Z"
      fill="#292524"
    />
    <Path
      d="M165.502 241.077c-.304-.038-1.748-.342-1.482-.95.228-.19.494-.418.722-.608.114.152.19.304.266.494.304.722.038 1.14-.342 1.71-.266.418-.836 1.254-.456 1.786.266.342.646.266.912 0 .532-.532.646-1.52.608-2.204 0-.19-.304-.19-.304 0 0 .456.038 1.254-.304 1.596-.038.304-.19.342-.418.114-.38-.038-.418-.228-.152-.57.266-.532.836-.912.95-1.368.19-.608-.418-2.052-1.064-2.204-.57-.114-.798.646-.722 1.102.114.912.95 1.368 1.786 1.406.19-.038.19-.304 0-.304ZM158.74 245.457c4.788.304 9.576.494 14.364.608.19 0 .19-.266 0-.304a362.982 362.982 0 0 1-14.364-.608c-.19 0-.19.266 0 .304ZM179.838 244.17c4.797.037 9.588-.038 14.376-.187.19-.01.177-.27-.014-.297-4.788.149-9.58.223-14.376.186-.19.011-.177.271.014.298Z"
      fill="#292524"
    />
    <Path
      d="M149.108 110.699v.001c-.037 13.222 1.09 25.204 2.673 35.609a75.247 75.247 0 0 0 6.863 2.033l-9.536-37.643Zm0 0c0-7.616.375-14.783 1.012-21.388a52.271 52.271 0 0 1 6.915-3.038c4.647-1.587 8.388-2.04 9.332-2.153 2.69-.303 6.986-.717 12.185.564 3.465.866 5.145 1.954 5.689 2.353h.001c2.136 1.548 3.437 3.35 4.117 4.542.076 2.241.186 5.592.036 9.687l-.001.003c-.075 3.025-.301 6.129-2.24 21.178v.001c-.25 1.977-.469 3.667-.665 5.181-.607 4.678-.994 7.671-1.426 12.269v.001a353.872 353.872 0 0 0-.56 6.861c-4.058 2.21-7.589 2.951-9.863 3.249-5.233.668-9.624-.382-14.781-1.616l-.213-.051-9.538-37.643Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.5}
    />
    <Mask id="a" fill="#fff">
      <Path d="M149.96 89.267c6.954-3.496 14.896-5.852 22.724-5.624 6.08.19 13.68 2.432 15.884 8.778.076.19.342.114.266-.076-2.128-6.118-9.158-8.55-15.124-8.93-8.208-.532-16.644 1.938-23.902 5.624-.152.038 0 .304.152.228Z" />
    </Mask>
    <Path
      d="M149.96 89.267c6.954-3.496 14.896-5.852 22.724-5.624 6.08.19 13.68 2.432 15.884 8.778.076.19.342.114.266-.076-2.128-6.118-9.158-8.55-15.124-8.93-8.208-.532-16.644 1.938-23.902 5.624-.152.038 0 .304.152.228Z"
      fill="#fff"
    />
    <Path
      d="m149.96 89.267.224.448.001-.001-.225-.447Zm22.724-5.624.016-.5h-.001l-.015.5Zm15.884 8.778-.472.164.004.011.004.011.464-.186Zm.266-.076-.472.165.004.01.004.011.464-.186Zm-15.124-8.93-.032.5h.001l.031-.5Zm-23.902 5.624.122.485.054-.013.051-.026-.227-.446Zm.377.675c6.904-3.47 14.763-5.796 22.485-5.571l.029-1c-7.934-.23-15.959 2.156-22.963 5.677l.449.894Zm22.484-5.571c2.992.094 6.341.692 9.199 2.033 2.853 1.34 5.183 3.4 6.228 6.41l.945-.329c-1.159-3.337-3.731-5.57-6.749-6.986-3.013-1.414-6.504-2.031-9.592-2.127l-.031.999Zm15.435 8.464a.658.658 0 0 0 .792.4.624.624 0 0 0 .403-.847l-.929.371a.378.378 0 0 1 .251-.486.344.344 0 0 1 .412.19l-.929.372Zm1.203-.426c-1.118-3.212-3.517-5.436-6.367-6.897-2.846-1.46-6.166-2.175-9.198-2.368l-.063.998c2.934.187 6.112.878 8.804 2.26 2.689 1.378 4.868 3.43 5.879 6.335l.945-.328Zm-15.564-9.265c-8.325-.54-16.851 1.965-24.161 5.678l.453.891c7.206-3.66 15.552-6.095 23.643-5.57l.065-.999Zm-24.056 5.638a.58.58 0 0 0-.434.467.642.642 0 0 0 .092.437.684.684 0 0 0 .302.268.625.625 0 0 0 .537-.011l-.447-.895a.376.376 0 0 1 .318-.007.312.312 0 0 1 .141.121.357.357 0 0 1 .044.248.423.423 0 0 1-.31.342l-.243-.97Z"
      fill="#000"
      mask="url(#a)"
    />
    <Mask id="b" fill="#fff">
      <Path d="M188.53 91.547c.57 9.044-.646 18.012-1.748 26.942-1.064 9.006-2.166 18.012-3.23 27.018-.038.19.266.19.304 0 1.064-9.006 2.166-18.012 3.23-27.018 1.064-8.93 2.318-17.936 1.748-26.942-.038-.19-.342-.19-.304 0Z" />
    </Mask>
    <Path
      d="M188.53 91.547c.57 9.044-.646 18.012-1.748 26.942-1.064 9.006-2.166 18.012-3.23 27.018-.038.19.266.19.304 0 1.064-9.006 2.166-18.012 3.23-27.018 1.064-8.93 2.318-17.936 1.748-26.942-.038-.19-.342-.19-.304 0Z"
      fill="#fff"
    />
    <Path
      d="m188.53 91.547.499-.031-.002-.034-.006-.033-.491.098Zm-1.748 26.942-.496-.061v.003l.496.058Zm-3.23 27.018.491.098.004-.019.002-.02-.497-.059Zm.304 0 .491.098.004-.019.002-.02-.497-.059Zm3.23-27.018-.496-.059v.001l.496.058Zm1.748-26.942.499-.031-.002-.034-.006-.033-.491.098Zm-.803.032c.567 8.988-.641 17.905-1.745 26.849l.993.123c1.1-8.917 2.324-17.935 1.75-27.035l-.998.063Zm-1.745 26.852c-.532 4.502-1.074 9.004-1.615 13.508-.542 4.502-1.083 9.006-1.615 13.51l.993.117c.532-4.502 1.073-9.005 1.615-13.508.541-4.503 1.083-9.006 1.615-13.51l-.993-.117Zm-3.224 26.978a.614.614 0 0 0 .163.559.636.636 0 0 0 .451.182.681.681 0 0 0 .671-.545l-.981-.196a.32.32 0 0 1 .31-.259c.056 0 .162.017.256.111a.388.388 0 0 1 .111.344l-.981-.196Zm1.291.157c.532-4.502 1.073-9.005 1.615-13.508.541-4.503 1.083-9.006 1.615-13.51l-.993-.117c-.532 4.502-1.074 9.004-1.615 13.508-.542 4.502-1.083 9.006-1.615 13.51l.993.117Zm3.23-27.018c1.062-8.911 2.324-17.97 1.75-27.032l-.998.063c.567 8.95-.679 17.903-1.745 26.851l.993.118Zm1.742-27.099a.681.681 0 0 0-.671-.544.613.613 0 0 0-.614.74l.981-.196a.39.39 0 0 1-.367.456.32.32 0 0 1-.31-.26l.981-.196Z"
      fill="#000"
      mask="url(#b)"
    />
    <Mask id="c" fill="#fff">
      <Path d="M148.972 89.61c-1.216 18.543.608 37.049 2.47 55.479 0 .19.304.19.304 0-1.862-18.43-3.724-36.936-2.47-55.48 0-.19-.304-.19-.304 0Z" />
    </Mask>
    <Path
      d="M148.972 89.61c-1.216 18.543.608 37.049 2.47 55.479 0 .19.304.19.304 0-1.862-18.43-3.724-36.936-2.47-55.48 0-.19-.304-.19-.304 0Z"
      fill="#fff"
    />
    <Path
      d="m148.972 89.61.499.032.001-.016v-.017h-.5Zm2.47 55.479h.5v-.025l-.002-.025-.498.05Zm.304 0h.5v-.025l-.002-.025-.498.05Zm-2.47-55.48.499.034.001-.017v-.017h-.5Zm-.803-.032c-1.219 18.592.611 37.138 2.472 55.563l.995-.101c-1.863-18.436-3.682-36.901-2.469-55.397l-.998-.065Zm2.469 55.512c0 .195.085.372.228.491.132.11.288.152.424.152a.663.663 0 0 0 .425-.152.633.633 0 0 0 .227-.491h-1c0-.1.047-.205.133-.277a.337.337 0 0 1 .215-.08c.061 0 .141.018.216.08a.367.367 0 0 1 .132.277h-1Zm1.302-.05c-1.863-18.437-3.719-36.901-2.469-55.396l-.997-.067c-1.258 18.593.61 37.14 2.471 55.564l.995-.101Zm-2.468-55.43a.634.634 0 0 0-.227-.49.664.664 0 0 0-.425-.152.663.663 0 0 0-.424.151.635.635 0 0 0-.228.491h1c0 .1-.046.206-.132.278a.336.336 0 0 1-.216.08.336.336 0 0 1-.215-.08.368.368 0 0 1-.133-.278h1Z"
      fill="#000"
      mask="url(#c)"
    />
    <Mask id="d" fill="#fff">
      <Path d="M152.088 146.647c9.842 3.534 20.976 5.966 30.742.798.152-.076 0-.342-.152-.228-9.69 5.092-20.748 2.66-30.514-.836-.19-.076-.266.19-.076.266Z" />
    </Mask>
    <Path
      d="M152.088 146.647c9.842 3.534 20.976 5.966 30.742.798.152-.076 0-.342-.152-.228-9.69 5.092-20.748 2.66-30.514-.836-.19-.076-.266.19-.076.266Z"
      fill="#fff"
    />
    <Path
      d="m152.088 146.647-.185.464.008.004.008.003.169-.471Zm30.742.798-.223-.447-.01.005.233.442Zm-.152-.228.233.443.035-.019.032-.024-.3-.4Zm-30.514-.836-.185.464.008.004.009.003.168-.471Zm-.245.737c9.857 3.539 21.176 6.045 31.145.769l-.467-.884c-9.563 5.061-20.512 2.703-30.34-.826l-.338.941Zm31.135.774a.604.604 0 0 0 .333-.461.651.651 0 0 0-.093-.433.64.64 0 0 0-.361-.282.618.618 0 0 0-.555.101l.6.8a.381.381 0 0 1-.336.055.345.345 0 0 1-.199-.15.343.343 0 0 1-.046-.23.392.392 0 0 1 .21-.294l.447.894Zm-.608-1.117c-9.487 4.985-20.36 2.627-30.113-.865l-.337.942c9.779 3.501 21.022 6.007 30.915.808l-.465-.885Zm-30.096-.858a.622.622 0 0 0-.847.403.657.657 0 0 0 .4.791l.371-.928a.343.343 0 0 1 .191.412.38.38 0 0 1-.486.25l.371-.928Z"
      fill="#000"
      mask="url(#d)"
    />
    <Path
      d="m110.364 122.555-8.664-5.016a4.907 4.907 0 0 0-1.786-4.256c-.912.646-2.204 1.786-3.382 3.496a14.918 14.918 0 0 0-2.09 4.446c1.444.874 3.192.874 4.636.152l8.968 5.168c.608.342 1.368.152 1.71-.456l1.064-1.862c.342-.57.152-1.33-.456-1.672Z"
      fill="#fff"
      stroke="#202124"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      d="m99.079 113.435-30.932-64.6M92.922 121.681 37.67 136.387"
      stroke="#202124"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      d="M66.22 93.154c7.093-26.517 4.495-50.247-5.804-53.002-10.299-2.755-24.398 16.508-31.491 43.026-7.094 26.517-4.495 50.247 5.804 53.002 10.299 2.755 24.398-16.508 31.491-43.026Z"
      fill="#FFFBEB"
      stroke="#202124"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
    <Path
      d="M108.73 117.615a7.73 7.73 0 0 0-1.102-1.368c-.646-.646-1.178-.912-1.938-1.33-.684-.38-.912-.418-1.102-.304-.304.152-.304.646-.342.874a1.971 1.971 0 0 0 .418 1.33 21.87 21.87 0 0 0-1.444 1.596c-.76.95-1.824 2.28-2.47 4.218-.266.76-.38 1.406-.456 1.862.19.114.456.304.836.532 1.064.608 2.204 1.292 3.61 1.178 1.026-.076 1.786-.494 2.394-.874.494-.304 1.254-.722 1.862-1.634.798-1.178.836-2.394.874-2.774-.038-1.672-.874-2.888-1.14-3.306Z"
      fill="#fff"
    />
    <Path
      d="M149.124 89.61a119.76 119.76 0 0 0-6.916 9.157 120.544 120.544 0 0 0-9.652 16.834c-3.496.798-7.6 1.52-12.198 1.9a85.917 85.917 0 0 1-11.59.152 6.598 6.598 0 0 0-1.102 3.344c-.038 1.33.342 2.356.57 2.926 1.52.874 3.572 2.014 6.004 3.23 2.014.988 6.308 2.964 12.008 4.826 2.698.874 6.042 1.824 9.956 2.66.456.038 1.178 0 1.938-.304.57-.228.95-.532 1.406-.912 1.558-1.254 2.318-1.9 2.47-2.052a43.481 43.481 0 0 0 2.926-2.774c.076-.114 1.368-1.406 2.698-2.964 3.268-3.838 6.422-8.816 8.474-12.16 1.9-3.154 2.85-4.712 3.61-6.536 1.672-3.914 2.926-6.764 2.356-9.348-.988-4.104-6.346-7.068-12.958-7.98Z"
      fill="#fff"
    />
    <Path
      d="M149.01 89.495a128.627 128.627 0 0 0-16.568 25.802c-.076.152.152.304.228.152a130.709 130.709 0 0 1 16.53-25.726c.152-.152-.076-.38-.19-.228Z"
      fill="#202124"
    />
    <Path
      d="M132.48 115.449c-7.6 1.938-15.39 2.128-23.18 2.318-.19 0-.19.304 0 .304 7.79-.152 15.656-.38 23.256-2.318.19-.076.114-.342-.076-.304ZM108.388 118.223c-.874 1.178-1.102 2.66-.722 4.066.038.19.342.114.266-.076-.38-1.368-.114-2.698.684-3.838.152-.152-.114-.304-.228-.152ZM108.35 124.189c5.244 2.926 10.678 5.396 16.34 7.334a98.816 98.816 0 0 0 9.234 2.736c2.432.57 3.914.608 5.928-.95 8.854-6.802 14.82-16.568 19.988-26.296.076-.152-.152-.304-.228-.152-4.864 9.158-10.374 18.316-18.43 25.042-1.216 1.026-2.546 2.28-4.18 2.508-1.482.19-3.23-.456-4.636-.836-2.66-.684-5.282-1.52-7.904-2.432a101.74 101.74 0 0 1-15.922-7.22c-.228-.038-.38.19-.19.266ZM108.807 117.501a37.095 37.095 0 0 0-2.204-2.052c-.494-.418-1.064-.95-1.748-.988-1.292-.076-.646 1.786-.342 2.432.076.152.342.038.228-.152a6.85 6.85 0 0 1-.304-.798c-.076-.342-.114-1.026.266-1.216.152-.114.76.266.95.38.304.19.57.456.836.684.722.608 1.406 1.292 2.09 1.938.152.114.38-.076.228-.228ZM103.296 117.995c-1.216 1.634-2.546 3.572-3.154 5.51-.417 1.33.57 1.482 1.596 1.9.152.076.228-.19.076-.266-1.064-.418-1.672-.95-1.14-2.166.228-.532.532-1.064.798-1.596a25.803 25.803 0 0 1 2.052-3.23c.152-.152-.114-.304-.228-.152ZM103.03 125.747c1.558 1.178 3.382.456 4.674-.722.152-.114-.076-.342-.19-.19-1.178 1.064-2.85 1.824-4.256.722-.19-.114-.38.076-.228.19Z"
      fill="#202124"
    />
    <Path
      d="M54.42 90.063c0-3.467-2.79-6.269-6.208-6.269-3.419 0-6.208 2.802-6.208 6.269 0 2.857 1.89 5.27 4.468 6.019v2.635h3.36V96.11a6.246 6.246 0 0 0 4.587-6.047Z"
      fill="#FFFBEB"
    />
    <Path
      d="M46.472 96.082h.375V95.8l-.27-.078-.105.36Zm0 2.635h-.375v.375h.375v-.375Zm3.36 0v.375h.374v-.375h-.375Zm0-2.607-.1-.362-.276.075v.287h.375Zm4.962-6.047c0-3.671-2.954-6.644-6.582-6.644v.75c3.208 0 5.832 2.631 5.832 5.894h.75Zm-6.582-6.644c-3.629 0-6.583 2.973-6.583 6.644h.75c0-3.263 2.624-5.894 5.833-5.894v-.75Zm-6.583 6.644c0 3.025 2.001 5.584 4.739 6.38l.21-.721c-2.42-.703-4.199-2.97-4.199-5.659h-.75Zm4.468 6.019v2.635h.75v-2.635h-.75Zm.375 3.01h3.36v-.75h-3.36v.75Zm3.734-.375V96.11h-.75v2.607h.75Zm-.276-2.246a6.62 6.62 0 0 0 4.864-6.408h-.75a5.87 5.87 0 0 1-4.312 5.685l.198.723Z"
      fill="#0C0C0C"
    />
    <Path d="M50.851 98.412h-5.278v2.469h5.278v-2.469Z" fill="#FFFBEB" />
    <Path
      d="M45.543 99.189h5.338M45.573 100.159h5.158M48.212 98.19v-8.793M46.023 88.981l2.13 3.468M50.401 88.926l-2.129 3.467M35.107 85.459l4.409.804M39.276 80.41l2.189 2.386M44.584 77.554l.9 3.495M49.651 80.854l.72-2.607M52.71 83.073l3.269-3.522M55.11 85.57l5.097-2.08M55.979 88.316l3.089-.194M56.429 90.674l1.949.832M36.217 91.283l3.688-1.303M40.775 92.532l-2.04 2.108"
      stroke="#0C0C0C"
      strokeWidth={0.75}
      strokeMiterlimit={10}
    />
  </Svg>
);
