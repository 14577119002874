/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserReferralCreate } from '../models/UserReferralCreate';
import type { UserReferralLeaderboard } from '../models/UserReferralLeaderboard';
import { request as __request } from '../core/request';

export class UserReferralService {

    /**
     * @returns UserReferralLeaderboard
     * @throws ApiError
     */
    public static async apiUserReferralLeaderboardList({
        campaignName,
        limit = 10,
        userIds,
    }: {
        campaignName: string,
        limit?: number,
        userIds?: string,
    }): Promise<Array<UserReferralLeaderboard>> {
        const result = await __request({
            method: 'GET',
            path: `/api/user-referral-leaderboard/`,
            query: {
                'campaign_name': campaignName,
                'limit': limit,
                'user_ids': userIds,
            },
        });
        return result.body;
    }

    /**
     * @returns UserReferralCreate
     * @throws ApiError
     */
    public static async apiUserReferralCreate({
        data,
    }: {
        data: UserReferralCreate,
    }): Promise<UserReferralCreate> {
        const result = await __request({
            method: 'POST',
            path: `/api/user-referral/`,
            body: data,
        });
        return result.body;
    }

}
