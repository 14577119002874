import { gql, NetworkStatus } from '@apollo/client';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useState, VFC } from 'react';
import { FlatList, ListRenderItemInfo, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import { CORE_PAGINATION_FIELDS } from '../../fragments/pageInfo';
import { CoreIdeaFieldsFragment, useGetInstrumentIdeasQuery } from '../../generated/graphql';
import { useFetchMore } from '../../hooks/useFetchMore';
import { useRefresh } from '../../hooks/useRefresh';
import { FooterSpinner } from '../../old/FooterSpinner';
import { FilterValue, filterValueToInputMap, IdeaFilters } from '../../old/IdeaFilters';
import { IdeaFlatListItem } from '../../old/IdeaFlatListItem';
import { IdeasSkeleton } from '../../old/IdeasSkeleton';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const INSTRUMENT_IDEAS = gql`
  ${CORE_PAGINATION_FIELDS}
  ${CORE_IDEA_FIELDS}
  query getInstrumentIdeas($statuses: [IdeaStatus!], $instrumentId: ID!, $cursor: NonEmptyString) {
    publicIdeasPaginated(
      filter: { statuses: $statuses, instrumentFields: { id: $instrumentId } }
      pagination: { cursor: $cursor, limit: 15 }
    ) {
      nodes {
        ...CoreIdeaFields
      }
      pageInfo {
        ...CorePaginationFields
      }
    }
  }
`;

export const Ideas: VFC<{ instrumentId: string }> = ({ instrumentId }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const [selectedFilter, setSelectedFilter] = useState<FilterValue>('Active');
  const selectedStatuses = filterValueToInputMap[selectedFilter];

  const { data, fetchMore, error, refetch, networkStatus } = useGetInstrumentIdeasQuery({
    variables: { statuses: selectedStatuses, instrumentId },
  });

  useEffect(() => {
    if (error) {
      console.error(error);
      toast.show('Something went wrong, please try again.');
    }
  }, [error, toast]);

  const ideas = data?.publicIdeasPaginated.nodes ?? [];
  const nextCursor = data?.publicIdeasPaginated?.pageInfo?.nextCursor;
  const loading = networkStatus === NetworkStatus.loading || networkStatus === NetworkStatus.setVariables;

  const { refreshing, onRefresh } = useRefresh(refetch);
  const { fetchingMore, onFetchMore } = useFetchMore(fetchMore);

  const onEndReached = useCallback(() => {
    if (!nextCursor) return;
    onFetchMore({ variables: { cursor: nextCursor } });
  }, [onFetchMore, nextCursor]);

  return (
    <>
      <View style={tailwind('pt-4 pb-2')}>
        <IdeaFilters value={selectedFilter} onChange={(v) => setSelectedFilter(v)} />
      </View>
      {loading ? (
        <IdeasSkeleton />
      ) : (
        <FlatList
          ListEmptyComponent={<EmptyView selectedFilter={selectedFilter} />}
          contentContainerStyle={[
            tailwind('px-6 pb-4'),
            ideas.length === 0 && tailwind('flex-grow items-center justify-center'),
          ]}
          refreshing={refreshing}
          data={ideas}
          renderItem={renderItem}
          onRefresh={() => onRefresh({ statuses: selectedStatuses })}
          keyExtractor={keyExtractor}
          onEndReached={onEndReached}
          ListFooterComponent={fetchingMore ? <FooterSpinner /> : undefined}
        />
      )}
    </>
  );
};

const renderItem = ({ item }: ListRenderItemInfo<CoreIdeaFieldsFragment>) => <IdeaFlatListItem {...item} />;
const keyExtractor = (item: CoreIdeaFieldsFragment) => item.id;

const EmptyView: React.VFC<{ selectedFilter: FilterValue }> = ({ selectedFilter }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-1 items-center justify-center')}>
      <Text style={tailwind('text-warmGray-500 text-lg pb-2')}>
        No {capitalize(selectedFilter)} Ideas on this instrument.
      </Text>
    </View>
  );
};
