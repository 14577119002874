import { View as MotiView } from 'moti';
import React from 'react';
import { TextStyle, View, ViewProps } from 'react-native';
import { Esg } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { ProgressBar } from '../ProgressBar';
import { Text } from '../Text';

type Props = {
  /**
   * Bars and values hidden until set to true
   */
  viewed?: boolean;
} & Pick<
  Esg,
  | 'totalScore'
  | 'totalGrade'
  | 'environmentScore'
  | 'environmentGrade'
  | 'socialScore'
  | 'socialGrade'
  | 'governanceScore'
  | 'governanceGrade'
>;

export const EsgScores = React.forwardRef<View, Props>(
  (
    {
      viewed,
      totalScore,
      totalGrade,
      environmentScore,
      environmentGrade,
      socialScore,
      socialGrade,
      governanceScore,
      governanceGrade,
    },
    ref,
  ) => {
    const tailwind = useTailwind();
    return (
      /**
       * Must set otherwise can't detect if container is in view on android
       */
      <View style={tailwind('pt-2')} collapsable={false} ref={ref}>
        <Text style={tailwind('text-primary-dark text-sm font-bold')}>Overall</Text>
        <View style={tailwind('flex-row items-center border-b border-greys-200 pb-4')}>
          <View style={tailwind('flex-grow')}>
            <ProgressBar
              animate={viewed}
              enableAnimation={true}
              progress={getPercentageScore(totalScore ?? 0, 3000)}
              color={colors.primary.dark}
            />
          </View>
          <ScoreCell viewed={viewed} textStyle={tailwind('text-primary-dark font-bold')} accessibilityLabel="Esg score">
            {totalScore}
          </ScoreCell>
          <RatingCell
            viewed={viewed}
            textStyle={tailwind('text-primary-dark font-bold')}
            accessibilityLabel="Esg grade"
          >
            {totalGrade}
          </RatingCell>
        </View>

        <Text style={tailwind('text-primary-default text-sm pt-4 -mb-1')}>Environmental</Text>
        <View style={tailwind('flex-row items-center pb-2')}>
          <View style={tailwind('flex-grow')}>
            <ProgressBar
              animate={viewed}
              enableAnimation={true}
              progress={getPercentageScore(environmentScore ?? 0, 1000)}
              color={colors.primary.default}
            />
          </View>
          <ScoreCell
            viewed={viewed}
            textStyle={tailwind('text-primary-default')}
            accessibilityLabel="Environmental score"
          >
            {environmentScore}
          </ScoreCell>
          <RatingCell
            viewed={viewed}
            textStyle={tailwind('text-primary-default')}
            accessibilityLabel="Environmental grade"
          >
            {environmentGrade}
          </RatingCell>
        </View>
        <Text style={tailwind('text-secondary-default text-sm -mb-1')}>Social</Text>
        <View style={tailwind('flex-row items-center pb-2')}>
          <View style={tailwind('flex-grow')}>
            <ProgressBar
              animate={viewed}
              enableAnimation={true}
              color={colors.secondary.default}
              progress={getPercentageScore(socialScore ?? 0, 1000)}
            />
          </View>
          <ScoreCell viewed={viewed} textStyle={tailwind('text-secondary-default')} accessibilityLabel="Social score">
            {socialScore}
          </ScoreCell>
          <RatingCell viewed={viewed} textStyle={tailwind('text-secondary-default')} accessibilityLabel="Social grade">
            {socialGrade}
          </RatingCell>
        </View>
        <Text style={tailwind('text-tertiary-default text-sm -mb-1')}>Governance</Text>
        <View style={tailwind('flex-row items-center pb-2')}>
          <View style={tailwind('flex-grow')}>
            <ProgressBar
              color={colors.tertiary.default}
              animate={viewed}
              enableAnimation={true}
              progress={getPercentageScore(governanceScore ?? 0, 1000)}
            />
          </View>
          <ScoreCell
            viewed={viewed}
            textStyle={tailwind('text-tertiary-default')}
            accessibilityLabel="Governance score"
          >
            {governanceScore}
          </ScoreCell>
          <RatingCell
            viewed={viewed}
            textStyle={tailwind('text-tertiary-default')}
            accessibilityLabel="Governance grade"
          >
            {governanceGrade}
          </RatingCell>
        </View>
      </View>
    );
  },
);

const ScoreCell: React.FC<ViewProps & { viewed?: boolean; textStyle?: TextStyle }> = ({
  viewed,
  children,
  textStyle,
  ...props
}) => {
  const tailwind = useTailwind();
  return (
    <MotiView delay={150} animate={{ opacity: viewed ? 1 : 0 }} {...props}>
      <Text style={[tailwind('pl-2 w-12 text-center'), textStyle]}>{children}</Text>
    </MotiView>
  );
};
const RatingCell: React.FC<ViewProps & { viewed?: boolean; textStyle?: TextStyle }> = ({
  viewed,
  children,
  textStyle,
  ...props
}) => {
  const tailwind = useTailwind();
  return (
    <MotiView delay={150} animate={{ opacity: viewed ? 1 : 0 }} {...props}>
      <Text style={[tailwind('pl-2 w-12 text-warmGray-500 text-sm text-center'), textStyle]}>{children}</Text>
    </MotiView>
  );
};

const getPercentageScore = (score: number, max: number) => score / max;
