import React from 'react';
import { View } from 'react-native';
import { Chip } from '../../../old/Chip';
import { useTailwind } from '../../../theme';

type Props = {
  onSelected?: (timeframe: TimeframeOption) => void;
  selected: TimeframeOption;
};

export const TimeFrame: React.VFC<Props> = ({ onSelected, selected }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('mt-4 flex-row flex-auto justify-between')}>
      {timeframes.map((timeframe) => (
        <Chip
          key={timeframe}
          selected={timeframe === selected}
          text={`${timeframe}m`}
          size="md"
          rounded="md"
          textSize="base"
          onPress={() => {
            onSelected && onSelected(timeframe);
          }}
          style={tailwind('mx-0.5')}
        />
      ))}
    </View>
  );
};

const timeframes = [1, 3, 6, 9, 12] as const;

export type TimeframeOption = typeof timeframes[number];
