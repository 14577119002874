import React, { useMemo } from 'react';
import { Idea, Instrument, Maybe, Position } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { AnimatedPressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';

export type Props = {
  activeIdea: {
    position?: Idea['position'];
    instrument?: Maybe<{
      ticker?: Instrument['ticker'];
    }>;
  };
  onPress: () => void;
};

/**
 * custom chip to show "Bullish on AAPL" type of flair on a comment persona
 *
 * animated pressable to navigate to idea
 */
export const IdeaFlairChip: React.VFC<Props> = ({ activeIdea, onPress }) => {
  const tailwind = useTailwind();
  if (!activeIdea.position) {
    console.error('trying to render an IdeaFlairChip with a null active idea position');
  }

  const text = `${activeIdea.position === Position.Long ? 'Bullish' : 'Bearish'} on ${activeIdea.instrument?.ticker}`;

  return (
    <AnimatedPressable
      accessibilityLabel={text}
      style={[
        tailwind('h-5 rounded-md flex-row justify-center items-center px-1.5'),
        tailwind(getContainerStyle(activeIdea.position)),
      ]}
      animate={useMemo(
        () =>
          ({ pressed }) => {
            'worklet';
            return {
              scale: pressed ? 0.97 : 1,
            };
          },
        [],
      )}
      onPress={onPress}
    >
      <Text style={[tailwind('text-xs'), tailwind(getTextStyle(activeIdea.position))]}>{text}</Text>
    </AnimatedPressable>
  );
};

const getTextStyle = (position: Idea['position']) => {
  switch (position) {
    case Position.Long:
      return 'text-blue-600';
    case Position.Short:
      return 'text-fuchsia-600';
    default:
      return '';
  }
};

const getContainerStyle = (position: Idea['position']) => {
  switch (position) {
    case Position.Long:
      return 'border-blue-100 bg-blue-100';
    case Position.Short:
      return 'border-fuchsia-100 bg-fuchsia-100';
    default:
      return '';
  }
};
