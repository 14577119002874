import { ComponentProps, useState } from 'react';
import { LayoutRectangle, View } from 'react-native';

/**
 * Hook to measure view layout, useful in animations for layout events.
 * Source: https://moti.fyi/examples/auto-height
 *
 * @returns Layout values and update function to be supplied as an `onLayout` prop to <View/> elements
 */
export function useLayout() {
  const [layout, setLayout] = useState<Partial<LayoutRectangle>>({});
  const onLayout: ComponentProps<typeof View>['onLayout'] = ({ nativeEvent }) => {
    setLayout(nativeEvent.layout);
  };

  return [layout, onLayout] as const;
}
