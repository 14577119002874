import { useEffect } from 'react';
import { useToast } from 'react-native-toast-notifications';
import { Maybe } from '../generated/graphql';

/**
 * Logs the error and presents a toast notifying the user something went wrong
 */
export function useLogAndToastError(error?: Maybe<Error>) {
  const toast = useToast();
  useEffect(() => {
    if (error) {
      console.error(error);
      toast.show('Something went wrong, please try again.');
    }
  }, [error, toast]);
}
