import { gql } from '@apollo/client';

export const CORE_IDEA_FIELDS = gql`
  fragment CoreIdeaFields on Idea {
    id
    targetDate
    targetPrice
    referenceDate
    referencePrice
    position
    conviction
    status
    closeTime
    rationaleTags {
      id
      name
      sortOrder
    }
    instrument {
      id
      name
      ticker
      logoUrl
      quotePrice {
        id
        midPrice
      }
      currency {
        id
        iso
      }
    }
    user {
      id
      nickname
      profileRating
    }
    topic {
      commentCount
    }
    performance {
      id
      actualReturn
      actualPrice
      actualPriceMovement
      priceChangeSincePosted
    }
  }
`;
