import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Share: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
      <Path
        d="M3.375 9.114l.02 11.614 14.624-.072-.008-6.108L18 9.146M10.667 14.826V2.618M6.688 5.478l4.082-4.125 4.125 4.085M7.336 9.034l-3.638-.001M17.639 9.045L14 9.043"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
