import { gql } from '@apollo/client';

/**
 * This GraphQL Fragment is used to fetch data used in the InstrumentPriceRow component
 *
 * make sure have a variable called `$loggedIn` for this fragment to work
 */
export const CORE_INSTRUMENT_FIELDS = gql`
  fragment CoreInstrumentFields on Instrument {
    id
    name
    ticker
    logoUrl
    displayName
    watchlistId @include(if: $loggedIn)
    quotePrice {
      id
      midPrice
      pctChange1d
      lastUpdated
    }
    closePrice {
      id
      price
    }
    currency {
      id
      iso
    }
  }
`;

/**
 * Fetch instrument financials
 */
export const INSTRUMENT_STATISTICS = gql`
  fragment InstrumentStatistics on Instrument {
    currentFinancials {
      averageVolume
      dayLow
      dayHigh
      dividendYield
      eps
      fiftyTwoWeekLow
      fiftyTwoWeekHigh
      marketCap
      marketOpen
      nextEarningsDateStart
      nextEarningsDateEnd
      peRatio
      pegRatio
      primaryExchange
    }
  }
`;

export const INSTRUMENT_FINANCIALS = gql`
  fragment InstrumentFinancials on Financials {
    __typename
    date
    totalRevenue
    costOfRevenue
    grossProfit
    operatingExpenses
    operatingIncome
    nonOperatingIncome
    preTaxIncome
    taxAndOtherDeductions
    netIncome
    netMargin
  }
`;

export const INSTRUMENT_ESG = gql`
  fragment InstrumentEsg on ESG {
    environmentGrade
    environmentLevel
    environmentScore
    socialGrade
    socialLevel
    socialScore
    governanceGrade
    governanceLevel
    governanceScore
    totalGrade
    totalLevel
    totalScore
    publishDate
  }
`;
