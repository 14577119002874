import React, { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { PressableProps, Pressable as RNPressable } from 'react-native';
import { useRedirectPress, UseRedirectPressArgs } from '../../hooks/useRedirectPress';
import { CommonPressableProps } from './types';

export type Props = CommonPressableProps & Omit<PressableProps, 'onPress'>;

/**
 * Non-animated normal React Native Pressable
 *
 * Note: track events done here
 *
 * @param props Pressable props
 * @param props.redirectIfLoggedOut send user to to SignUp screen instead of executing onPress if logged out
 */
export const Pressable: React.FC<Props> = ({
  redirectIfLoggedOut,
  accessibilityRole,
  accessibilityLabel,
  children,
  onPress,
  ...pressableProps
}) => {
  const trackingType: UseRedirectPressArgs['trackingType'] = useMemo(
    () => (accessibilityRole === 'button' ? 'button' : 'icon'),
    [accessibilityRole],
  );

  const { handlePress } = useRedirectPress({
    trackingType,
    redirectIfLoggedOut,
    onPress,
    accessibilityLabel,
  });

  return (
    <RNPressable
      onPress={handlePress}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole={accessibilityRole}
      {...pressableProps}
    >
      {children}
    </RNPressable>
  );
};
