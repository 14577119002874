import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider, SafeAreaProviderProps } from 'react-native-safe-area-context';
import { ToastProvider } from 'react-native-toast-notifications';
import { QueryClientProvider, QueryClientProviderProps } from 'react-query';
import { ErrorBoundary } from '../../old/ErrorBoundary';
import { BottomSheetProvider } from '../../providers/BottomSheetProvider';
import { fontWeights, TailwindProvider, utilities } from '../../theme';

export type Props = {
  children: React.ReactNode;
  /** pass in real client or a test client for jest */
  graphqlClient: ApolloClient<NormalizedCacheObject>;
  /** pass in real client or a test client for jest */
  queryClient: QueryClientProviderProps['client'];
  /** for jest can pass in initialMetrics */
  initialMetrics?: SafeAreaProviderProps['initialMetrics'];
};

/**
 *  Common App providers to use in real App component and wrap jest/storybook tests in
 *
 * @param root0
 * @param root0.children
 * @param root0.graphqlClient
 * @param root0.queryClient
 */
export const RootAppProvider: React.FC<Props> = ({ children, graphqlClient, queryClient, initialMetrics }) => (
  <GestureHandlerRootView style={{ flex: 1 }}>
    <TailwindProvider utilities={{ ...utilities, ...fontWeights }}>
      <SafeAreaProvider initialMetrics={initialMetrics}>
        <ErrorBoundary>
          <ApolloProvider client={graphqlClient}>
            <QueryClientProvider client={queryClient}>
              {children}
              <StatusBar style="dark" />
            </QueryClientProvider>
          </ApolloProvider>
        </ErrorBoundary>
      </SafeAreaProvider>
    </TailwindProvider>
  </GestureHandlerRootView>
);

/**
 *  Provider to be mounted inside navigation container. Contains providers that require access to navigation events,
 */
export const PostNavigationProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider duration={3000} offset={50} animationType="slide-in">
      <BottomSheetProvider>{children}</BottomSheetProvider>
    </ToastProvider>
  );
};
