import hexRgb from 'hex-rgb';
import { colors } from '../../theme';

export const chartColor = (prices: [number, number][]): { path: string; dot: string; gradient: string } | undefined => {
  if (prices.length === 0) {
    return undefined;
  }
  const latestPrice = prices[prices.length - 1][1];
  const basePrice = prices[0][1];
  const color = latestPrice - basePrice >= 0 ? colors.green['500'] : colors.red['500'];
  const gradientColor = latestPrice - basePrice >= 0 ? colors.green['400'] : colors.red['400'];
  return { gradient: rgb(gradientColor), path: rgb(color), dot: rgb(color) };
};

const rgb = (color: string): string => {
  const obj = hexRgb(color);
  return `rgb(${obj.red}, ${obj.green}, ${obj.blue})`;
};
