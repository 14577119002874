import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment, Maybe, UserProfile } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';
import { NarrowByTypename } from '../../../util/types';
import { usePersistedStore } from '../../../zustand/store';
import { Card, CardHeadline, CardHeadlineText, CardTitle } from '../../Card';
import { Chat } from '../../icons';
import { IdeaSnippetRow } from '../../IdeaSnippetRow';
import { InlineUserIcon } from '../../InlineUserIcon';
import { VerticalDivider } from '../../VerticalDivider';
import { ViewCommentLink } from '../../ViewCommentLink';

export type Props = NarrowByTypename<FeedCardFieldsFragment, 'NewCommentFeedItem'>;

export const NewCommentCard: React.FC<Props> = ({ comment, idea, __typename, created, id }) => {
  const tailwind = useTailwind();
  const userId = usePersistedStore((state) => state.userId);
  const isMyIdea = userId === idea?.user?.id;
  const isOtherUsersOwnIdea = idea?.user?.id === comment?.user?.id;
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  const onPressUser = useCallback(
    (userProfile?: Maybe<Partial<UserProfile>>) => {
      navigation.push('UserProfile', { userId: userProfile?.id ?? 0 });
      analytics.track('Feed card pressed', {
        'Feed card type': __typename,
        'Destination screen': 'UserProfile',
        'Feed item id': id,
        'Instrument ticker': idea?.instrument?.ticker ?? '',
      });
    },
    [__typename, id, idea?.instrument?.ticker, navigation],
  );

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, idea }}
        eventTime={created ? new Date(created) : undefined}
        icon={<Chat height={14} width={14} style={tailwind('mt-0.5')} />}
      >
        User activity
      </CardTitle>
      <CardHeadline>
        <CardHeadlineText>
          <InlineUserIcon userProfile={comment?.user} onPressUser={() => onPressUser(comment?.user)} /> commented on{' '}
          {isMyIdea ? (
            'your'
          ) : isOtherUsersOwnIdea ? (
            'their'
          ) : (
            <InlineUserIcon
              userProfile={idea?.user}
              text={`${idea?.user?.nickname}'s`}
              onPressUser={() => onPressUser(idea?.user)}
            />
          )}{' '}
          Idea
        </CardHeadlineText>
      </CardHeadline>

      <Pressable accessibilityRole="button" onPress={() => navigation.push('Idea', { ideaId: idea?.id ?? '' })}>
        <View style={tailwind('flex-row mr-2')}>
          <VerticalDivider style={tailwind('ml-4 mr-2')} />
          <Text numberOfLines={4}>{comment?.text}</Text>
        </View>
      </Pressable>

      <IdeaSnippetRow
        onPress={({ ideaId }) => navigation.push('Idea', { ideaId })}
        bottomRightView="actualReturn"
        style={tailwind('mt-4')}
        id={idea?.id ?? ''}
        {...idea}
      />
      <ViewCommentLink commentCount={idea?.topic?.commentCount} ideaId={idea?.id ?? ''} style={tailwind('pt-2')} />
    </Card>
  );
};
