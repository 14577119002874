import { WebImage } from './WebImage';

/** Safari does not support some of the SVG properties, just use PNG for now */
export const LayersAllColoured = () => (
  <WebImage
    imgSrc={require('../../assets/images/LayersAllColoured.png')}
    imgWidth={484}
    imgHeight={223}
    percentageScreenWidth={0.6}
  />
);
