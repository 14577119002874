import { View } from 'react-native';
import { Instrument, Maybe } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../Text';
import { Badge } from './Badge';
import { StockIcon } from './StockIcon';

export type Component = {
  instrument?: Maybe<Pick<Instrument, 'id' | 'logoUrl'>>;
};

type Props = {
  components?: Component[];
  componentCount: number;
  badgeSize?: 'sm' | 'md';
  cutOff: number;
};

export const CollectionComponents: React.VFC<Props> = ({ components, componentCount, badgeSize, cutOff }) => {
  const tailwind = useTailwind();
  const logos = (components ?? []).map((c) => c.instrument).filter(hasLogo);

  return (
    <View style={tailwind('flex-row shrink-0 pl-2')}>
      {logos?.slice(0, cutOff - 1).map((holding) => (
        <Badge size={badgeSize} key={holding?.id} style={tailwind('-ml-2')}>
          <StockIcon logoUrl={holding?.logoUrl} />
        </Badge>
      ))}
      {componentCount === cutOff && (
        <Badge size={badgeSize} style={tailwind('-ml-2')}>
          <StockIcon logoUrl={logos?.[cutOff - 1]?.logoUrl ?? ''} />
        </Badge>
      )}
      {componentCount > cutOff && (
        <Badge size={badgeSize} style={tailwind('-ml-2')}>
          <Text style={(tailwind('text-greys-900 font-light'), { fontSize: 8 })}>+{componentCount - cutOff + 1}</Text>
        </Badge>
      )}
    </View>
  );
};

const hasLogo = (
  instrument?: Maybe<Pick<Instrument, 'id' | 'logoUrl'>>,
): instrument is { id: string; logoUrl: string } => {
  if (!instrument?.logoUrl) {
    console.warn(`Missing logoUrl for instrument: ${instrument?.id}`);
    return false;
  }
  return !!instrument.id;
};
