import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { uncrunch } from 'graphql-crunch';
import { sha256 } from 'js-sha256';
import { compact } from 'lodash';
import { Platform } from 'react-native';
import { GRAPHQL_API_URL } from '../../constants/env';
import { namedOperations } from '../../generated/graphql';
import { usePersistedStore } from '../../zustand/store';
import { getAccessToken } from '../jwt';
import { logOut } from '../logIn';

// Perhaps turn crunch on when this crunch PR merged and upgraded crunch plugin on our apollo server https://github.com/banterfm/graphql-crunch/pull/42
const CRUNCH_ENABLED = false;

// one day might enable this again if we're more confident it speeds up client and want to use a GraphQL CDN perhaps
const PERSISTED_QUERIES_ENABLED = false;

// Set GraphQL uri
const GRAPHQL_FULL_URI = `${GRAPHQL_API_URL}/?crunch=${CRUNCH_ENABLED}`;

/**
 * Build Apollo Client links
 */
export const getLinks = (): ApolloLink[] => {
  // Creates the APQ (Persisted Queries) link using SHA256.
  const persistedQueriesLink = PERSISTED_QUERIES_ENABLED ? createPersistedQueryLink({ sha256 }) : null;

  // Create the uncrunch link to reduce payload size.
  const uncrunchLink = CRUNCH_ENABLED
    ? new ApolloLink((operation, forward) =>
        forward(operation).map((response) => {
          response.data = uncrunch(response.data);
          return response;
        }),
      )
    : null;

  // TODO:  Configure Sentry link

  // Inject the bearer token into requests.
  const authLink = setContext(async ({ operationName }, ctx) => {
    const isUserLoggedIn = usePersistedStore.getState().isUserLoggedIn;
    const headers = { ...ctx.headers };

    // dont need bearer token (aka access token) on any logged out queries or mutations and not on refresh JWT access token mutation
    const needsBearerToken = isUserLoggedIn && operationName !== namedOperations.Mutation.refreshJwtAccessToken;

    if (needsBearerToken) {
      const token = await getAccessToken();
      headers.Authorization = token ? `Bearer ${token}` : '';
    }

    return {
      headers,
    };
  });

  // Log graphql errors
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const isUserLoggedIn = usePersistedStore.getState().isUserLoggedIn;
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, path, extensions } = err;
        const code: string = extensions?.code || '';
        switch (code) {
          case 'UNAUTHENTICATED':
            console.log(`[GraphQL error]: UNAUTHENTICATED Message: ${message}, Path: ${path}`, err);
            if (isUserLoggedIn) {
              console.log('GraphQL: logging user out...');
              logOut();
            }
            break;
          default:
            // stringify error object so prints nicely in sentry
            console.error(`[GraphQL error]: Message: ${message}, Path: ${path}, Code: '${code}`, JSON.stringify(err));
        }
      }
    }
    if (networkError) {
      console.error(`[GraphQL Network error]`, networkError);
    }
  });

  // Upload link is a terminating link that wraps HTTP link to support multi-part requests and file uploads.
  const uploadLink = createUploadLink({
    uri: ({ operationName }) => {
      // add operationName so easier to see what query was called in sentry and network tools
      return `${GRAPHQL_FULL_URI}&op=${operationName}`;
    },
    // pass on the refresh token cookie for web: https://www.apollographql.com/docs/react/networking/authentication/#cookie
    credentials: Platform.OS === 'web' ? 'include' : undefined,
    // Type casting applied due to import style used in lib
  }) as unknown as ApolloLink;

  // Retry on failures. Defaults to 5 retries on Network Errors only with exponential back off. NOT graphql errors in response.
  const retryLink = new RetryLink();

  // Aggregate links (order matters) and use compact remove null ones.
  // HttpLink must be the last one in the list as it's the terminating link
  const links = compact([errorLink, uncrunchLink, persistedQueriesLink, authLink, retryLink, uploadLink]);
  return links;
};
