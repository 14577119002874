import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export const TrendDownFilled = (props: SvgProps) => {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Circle cx={18} cy={18} r={18} fill="#EF4444" />
      <Path d="M11 15l4 4 3-3 6 6" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M19 23h6v-6" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};

export const TrendDown: React.VFC = (props: SvgProps) => {
  return (
    <Svg width={16} height={16} fill="none" {...props}>
      <Path
        d="M1 5l4 4 4-4 5 5m-4 1h5V6"
        stroke="#A3A3A3"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
