import { useApolloClient } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { FC } from 'react';
import { View } from 'react-native';
import { BUNDLE_SLOTS, STOCK_SLOTS } from '../../constants/portfolioIntended';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { STACK_FIELDS } from '../../fragments/stack';
import { CoreInstrumentFieldsFragment, StackFieldsFragment } from '../../generated/graphql';
import { AllStackParamList } from '../../navigation';
import { useTailwind } from '../../theme';
import { Button, Fab } from '../../ui/Button';
import {
  PortfolioBaseCard,
  PortfolioBundleCard,
  PortfolioCashCard,
  PortfolioStockCard,
} from '../../ui/PortfolioCollectionCard';
import { ProgressBar } from '../../ui/ProgressBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { formatPercent } from '../../util/number';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, usePortfolioIntendedPersistedStore } from '../../zustand/store';

export type Props = NativeStackScreenProps<AllStackParamList, 'OnboardingConfirmPortfolio'>;

export const OnboardingConfirmPortfolio: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const apolloClient = useApolloClient();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const { baseLayer, stackLayer, stockLayer } = usePortfolioIntendedPersistedStore(
    ({ baseLayer, stackLayer, stockLayer }) => ({ baseLayer, stackLayer, stockLayer }),
  );
  /**
   * Read selected items directly from cache
   */
  const selectedBase = apolloClient.readFragment<StackFieldsFragment>({
    id: `Stack:${baseLayer.constituentIds[0]}`,
    fragment: STACK_FIELDS,
  });
  const selectedBundles = stackLayer.constituentIds.map((stackId) => {
    return apolloClient.readFragment<StackFieldsFragment>({
      id: `Stack:${stackId}`,
      fragment: STACK_FIELDS,
    });
  });
  const selectedStocks = stockLayer.constituentIds.map((instrumentId) => {
    return apolloClient.readFragment<CoreInstrumentFieldsFragment>({
      id: `Instrument:${instrumentId}`,
      fragment: CORE_INSTRUMENT_FIELDS,
      variables: { loggedIn },
    });
  });

  const baseItemWeight = baseLayer.weight;
  const bundleItemWeight = stackLayer.weight / BUNDLE_SLOTS;
  const stockItemWeight = stockLayer.weight / STOCK_SLOTS;
  const cashSlots = STOCK_SLOTS - selectedStocks.length;
  // Total cash weight divided by number of cash slots
  const cashItemWeight = (stockLayer.weight - stockItemWeight * selectedStocks.length) / cashSlots;

  return (
    <SafeAreaView>
      <ScrollView stickyHeaderIndices={[0]} contentContainerStyle={tailwind('px-6 pb-24')}>
        <TopBar endAdornment={<ProgressBar width={42} progress={6 / 6} />} />
        <TitleBar text="Confirm your portfolio" />

        <View style={tailwind('flex-row items-center pb-3 pt-9')}>
          <Text style={tailwind('text-default text-sm font-semibold pr-1.5')}>Base</Text>
          <ProgressBar type="primary" progress={0.7} width={42} />
          <Text style={tailwind('ml-3 bg-gray-50 rounded p-1 text-xs')}>
            {formatPercent(baseLayer.weight, 1, false, true)}
          </Text>
        </View>
        <PortfolioBaseCard
          componentCount={selectedBase?.components?.pageInfo.totalCount ?? 0}
          components={selectedBase?.components?.nodes ?? []}
          title={selectedBase?.content?.name ?? ''}
          weight={baseItemWeight}
          subText="By Upside"
        />

        <View style={tailwind('flex-row justify-between pb-3 pt-6')}>
          <View style={tailwind('flex-row items-center')}>
            <Text style={tailwind('text-default text-sm font-semibold pr-1.5')}>Bundle</Text>
            <ProgressBar type="secondary" progress={0.2} width={42} />
            <Text style={tailwind('ml-3 bg-gray-50 rounded p-1 text-xs')}>
              {formatPercent(stackLayer.weight, 1, false, true)}
            </Text>
          </View>
          <Button variant="text" text="Edit" onPress={() => navigation.navigate('OnboardingSelectBundles')} />
        </View>
        <View style={tailwind('flex-row')}>
          {selectedBundles.map((bundle, index) => (
            <React.Fragment key={bundle?.id ?? index}>
              {index > 0 && <View style={tailwind('w-0.5')} />}
              <PortfolioBundleCard
                key={bundle?.id}
                title={bundle?.content?.name ?? ''}
                components={bundle?.components?.nodes ?? []}
                subText="By Upside"
                weight={bundleItemWeight}
                componentCount={bundle?.components?.pageInfo?.totalCount ?? 0}
              />
            </React.Fragment>
          ))}
        </View>

        <View style={tailwind('flex-row justify-between pt-6')}>
          <View style={tailwind('flex-row items-center')}>
            <Text style={tailwind('text-default text-sm font-semibold pr-1.5')}>Stocks</Text>
            <ProgressBar type="tertiary" progress={0.1} width={42} />
            <Text style={tailwind('ml-3 bg-gray-50 rounded p-1 text-xs')}>
              {formatPercent(stockLayer.weight, 1, false, true)}
            </Text>
          </View>
          <Button variant="text" text="Edit" onPress={() => navigation.navigate('OnboardingSelectStocks')} />
        </View>
        <View style={tailwind('flex-row')}>
          {selectedStocks.map((instrument, index) => (
            <React.Fragment key={instrument?.id ?? index}>
              {index > 0 && <View style={tailwind('w-0.5')} />}
              <PortfolioStockCard
                componentCount={1}
                components={[{ instrument }]}
                key={instrument?.id ?? ''}
                title={instrument?.ticker ?? ''}
                subText={instrument?.displayName ?? ''}
                weight={stockItemWeight}
              />
            </React.Fragment>
          ))}
          {cashSlots > 0 &&
            new Array(cashSlots).fill(null).map((_, i) => (
              <React.Fragment key={i}>
                {i > 0 && <View style={tailwind('w-0.5')} />}
                <PortfolioCashCard weight={cashItemWeight} />
              </React.Fragment>
            ))}
        </View>
        {cashSlots > 0 && (
          <>
            <Text style={tailwind('text-grey text-sm pt-4 pb-5')}>
              You have an empty <Text style={tailwind('text-tertiary-dark text-sm')}>Stock Slot</Text>. We{"'"}ll set
              this aside as cash until you add another stock.
            </Text>
            {/* <View style={tailwind('items-center')}>
                <InfoLink onPress={() => undefined} text="What does this mean?" />
              </View> */}
          </>
        )}
      </ScrollView>

      <Fab onPress={() => navigation.navigate('OnboardingCreatingPortfolio')} />
    </SafeAreaView>
  );
});
