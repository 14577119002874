import { gql, MutationHookOptions, QueryOptions } from '@apollo/client';
import { useToast } from 'react-native-toast-notifications';
import { CORE_COMMENT_FIELDS } from '../../fragments/comments';
import {
  AppEntityType,
  GetCommentsDocument,
  GetCommentsQueryVariables,
  GetIdeaDocument,
  GetIdeaQueryVariables,
  PostIdeaCommentMutation,
  PostIdeaCommentMutationVariables,
  usePostIdeaCommentMutation,
} from '../../generated/graphql';
import { usePersistedStore } from '../../zustand/store';

export const postIdeaComment = gql`
  ${CORE_COMMENT_FIELDS}
  mutation postIdeaComment($ideaId: ID!, $appEntityType: AppEntityType!, $text: String!, $parent: ID) {
    postComment(
      input: { appEntityId: $ideaId, appEntityType: $appEntityType, commentText: $text, parentCommentId: $parent }
    ) {
      comment {
        ...CoreCommentFields
      }
      error {
        ... on CommentProfane {
          message
        }
        ... on CommentTooLong {
          message
        }
        ... on CommentEmpty {
          message
        }
      }
    }
  }
`;

/**
 * post an idea comment and refetch things to do with that idea
 *
 * Also shows on fatal error shows a toaster. Provide your own onError to override this.
 *
 * Do also check for data.error for non-fatal errors
 */
export const usePostIdeaComment = (
  ideaId?: string,
  args?: MutationHookOptions<PostIdeaCommentMutation, PostIdeaCommentMutationVariables>,
) => {
  const toast = useToast();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);

  return usePostIdeaCommentMutation({
    // onError specified first so can override if you want
    onError: (e) => {
      console.error(`Error in usePostIdeaCommentMutation: ${e.message}`, e);
      toast.show('Something went wrong');
    },
    refetchQueries: [
      {
        query: GetCommentsDocument,
        variables: { ideaId, entityType: AppEntityType.Idea },
      } as QueryOptions<GetCommentsQueryVariables>,
      {
        query: GetIdeaDocument,
        variables: { ideaId, loggedIn: loggedIn },
      } as QueryOptions<GetIdeaQueryVariables>,
    ],
    ...args,
  });
};
