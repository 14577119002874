import { capitalize } from 'lodash';
import { FC } from 'react';
import { View } from 'react-native';
import { RiskLevel } from '../../constants/portfolioIntended';
import { colors, useTailwind } from '../../theme';
import { formatPercent } from '../../util/number';
import { CircularProgress } from '../CircularProgress';
import { Text } from '../Text';

type Props = {
  annualisedReturn: number;
  riskLevel: RiskLevel | undefined;
};

export const Performance: FC<Props> = ({ annualisedReturn, riskLevel }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row pt-2')}>
      <PortfolioPerformanceFigure title="1 year performance">
        <Text style={[tailwind('text-xl font-medium'), getPerformanceTextStyle(annualisedReturn ?? 0, tailwind)]}>
          {formatPercent(annualisedReturn ?? 0)}
        </Text>
      </PortfolioPerformanceFigure>
      <View style={tailwind('w-0.5')} />
      <PortfolioPerformanceFigure title="Risk level">
        <Text style={[tailwind('text-xl font-medium pr-3'), getRiskLevelTextStyle(riskLevel, tailwind)]}>
          {capitalize(riskLevel)}
        </Text>
        <View style={tailwind('flex-1 items-center')}>
          <CircularProgress progress={getRiskLevelProgress(riskLevel)} color={getRiskLevelColor(riskLevel)} />
        </View>
      </PortfolioPerformanceFigure>
    </View>
  );
};

const PortfolioPerformanceFigure: FC<{ title: string }> = ({ title, children }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-1 bg-greys-50 pb-5 pt-4 px-4 rounded-3xl')}>
      <Text style={tailwind('text-greys-900 text-xs pb-1')}>{title}</Text>
      <View style={tailwind('h-8 flex-row items-end')}>{children}</View>
    </View>
  );
};

function getPerformanceTextStyle(performance: number, tailwind: ReturnType<typeof useTailwind>) {
  if (performance > 0) {
    return tailwind('text-primary-default');
  } else return tailwind('text-tertiary-default');
}

function getRiskLevelTextStyle(riskLevel: RiskLevel | undefined, tailwind: ReturnType<typeof useTailwind>) {
  switch (riskLevel) {
    case 'low':
      return tailwind('text-primary-default');
    case 'medium':
      return tailwind('text-secondary-default');
    case 'high':
      return tailwind('text-tertiary-default');
  }
}

function getRiskLevelColor(riskLevel: RiskLevel | undefined) {
  switch (riskLevel) {
    case 'low':
      return colors.primary.default;
    case 'medium':
      return colors.secondary.default;
    case 'high':
      return colors.tertiary.default;
    default:
      return colors.secondary.default;
  }
}

function getRiskLevelProgress(riskLevel: RiskLevel | undefined) {
  switch (riskLevel) {
    case 'low':
      return 0.25;
    case 'medium':
      return 0.5;
    case 'high':
      return 0.75;
    default:
      return 0.5;
  }
}
