import { gql } from '@apollo/client';
import * as Device from 'expo-device';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import { v4 as uuidv4 } from 'uuid';
import { CORE_USER_PROFILE_FIELDS } from '../fragments/userProfile';
import { GetMeQuery } from '../generated/graphql';
import { analytics, initAnalytics } from '../services/analytics';
import { branch } from '../services/branch';
import { getGraphqlClient } from '../services/graphql';
import { onesignal } from '../services/onesignal';
import { fontFamily } from '../theme/fonts';
import { shallowEqualObjects } from '../util/object';
import { PersistedZustandStore, usePersistedStore, useStore } from '../zustand/store';

const GET_ME = gql`
  ${CORE_USER_PROFILE_FIELDS}
  query GetMe {
    me {
      user {
        id
        email
        isStaff
        tradingOnboardingFinished
        profile {
          ...CoreUserProfileFields
        }
      }
    }
  }
`;

/**
 * show splash screen whilst do a few short async side effect things: load fonts and set logged in state
 */
export const useSplashScreenEffects = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // no dependencies on useEffect so only runs once
  useEffect(() => {
    (async () => {
      try {
        console.log(`useSplashScreenEffects running. isUserLoggedIn: ${usePersistedStore.getState().isUserLoggedIn}`);

        try {
          await SplashScreen.preventAutoHideAsync();
        } catch (e) {
          console.log(`Could not prevent splash screen hide`, e);
        }

        // Load fonts
        await Font.loadAsync({
          [fontFamily.regular]: require('../assets/fonts/Poppins-Regular.ttf'),
          [fontFamily.medium]: require('../assets/fonts/Poppins-Medium.ttf'),
          [fontFamily.semibold]: require('../assets/fonts/Poppins-SemiBold.ttf'),
          [fontFamily.bold]: require('../assets/fonts/Poppins-Bold.ttf'),
          [fontFamily.mono]: require('../assets/fonts/IBMPlexMono-Regular.ttf'),
        });

        // setup segment first
        await initAnalytics();
        onesignal.init();

        // setup branch listening last as relies on analytics
        branch.setupSubscriptions();

        // Set and persist user's anonymousId if they do not already have one set on their device
        if (!usePersistedStore.getState().anonymousId) {
          usePersistedStore.setState({ anonymousId: uuidv4() });
        }

        // do things for logged in or logged out user now
        // dont use zustand hook, but access store direct as only need to run this effect once on app bootstrap with no dependencies
        if (usePersistedStore.getState().isUserLoggedIn) {
          // Background check of user metadata in case it's been updated on another device.
          // We should NOT await this as it would slow down first load
          syncUserMetadata();
        } else {
          // fire logged out mixpanel event (not identify as can't use identify in mixpanel for anon user as breaks merging)
          analytics.track('Logged out user', {
            deviceOsName: Device.osName,
            deviceOsVersion: Device.osVersion,
            deviceBrand: Device.brand,
            deviceManufacturer: Device.manufacturer,
            deviceModelName: Device.modelName,
            devicePlatformApiLevel: Device.platformApiLevel,
            deviceWindowWidth: Math.round(Dimensions.get('window').width),
            deviceWindowHeight: Math.round(Dimensions.get('window').height),
            deviceScreenWidth: Math.round(Dimensions.get('screen').width),
            deviceScreenHeight: Math.round(Dimensions.get('screen').height),
          });
        }
      } catch (e) {
        console.error(`Error in useSplashScreenEffect`, e);
      } finally {
        setLoadingComplete(true);
        await SplashScreen.hideAsync();
        console.log(`useSplashScreenEffects finished`);
      }
    })();
  }, []);

  return { isLoadingComplete };
};

/**
 * Ensure user metadata is up to date in the store.
 * This should not be awaited as we already have persisted user data in our store on bootstrap.
 * Should be called as a non-blocking, background check.
 *
 * The `useIdentify` hook should then kick in to tell segment etc new values
 */
async function syncUserMetadata() {
  if (useStore.getState().isStorybook) return;

  console.log(`useSplashScreenEffects syncUserMetadata`);
  const {
    data: {
      me: { user },
    },
  } = await getGraphqlClient().query<GetMeQuery>({ query: GET_ME });

  const currentState = usePersistedStore.getState();

  const freshUserData: Partial<PersistedZustandStore> = {
    userId: user?.id ?? 0,
    nickname: user?.profile?.nickname ?? '',
    email: user?.email ?? '',
    isStaff: !!user?.isStaff,
  };
  const currentUserData = pick(currentState, 'userId', 'nickname', 'email', 'isStaff');

  // Shallow compare user properties to check whether we should update our store
  const areObjectEqual = shallowEqualObjects(freshUserData, currentUserData);

  if (!areObjectEqual) {
    // Update zustand store and analytics with fresh user metadata
    console.log(`useSplashScreenEffects syncUserMetadata updating persisted store and analytics identify`);
    usePersistedStore.setState({
      userId: freshUserData.userId,
      nickname: freshUserData.nickname,
      email: freshUserData.email,
      isStaff: freshUserData.isStaff,
    });
    analytics.identify(`${freshUserData.userId}`, {
      nickname: freshUserData.nickname,
      firstName: freshUserData.nickname,
      email: freshUserData.email,
      isStaff: freshUserData.isStaff,
    });
  }
  console.log(`useSplashScreenEffects syncUserMetadata done`);
}
