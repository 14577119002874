import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Home: React.VFC<SvgProps> = (props) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="m12.357 2-10 8v13h7v-7h6v7h7V10l-10-8Z"
      stroke={props.color ?? '#A3A3A3'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const HomeFilled: React.VFC<SvgProps> = (props) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="m12.357 2-10 8v13h7v-7h6v7h7V10l-10-8Z"
      stroke="#0F766E"
      fill="#CCFBF1"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
