import React from 'react';
import { View, ViewProps } from 'react-native';
import { useTailwind } from '../../theme';

type Props = ViewProps;

/**
 * Common Screen side padding same padding used in ScrollView
 *
 * @param param0
 * @param param0.children
 * @param param0.style
 */
export const ScreenSidePadding: React.FC<Props> = ({ children, style, ...props }) => {
  const tailwind = useTailwind();
  return (
    <View {...props} style={[tailwind('px-6'), style]}>
      {children}
    </View>
  );
};
