// eslint-disable-next-line no-restricted-imports
import { MotiPressable } from 'moti/interactions';
import React, { useMemo, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
export type Props = {
  variant: 'primary' | 'secondary';
  text: string;
  onPress: () => void;
  StartIcon?: React.FC<Pick<SvgProps, 'stroke' | 'color' | 'height' | 'width'>>;
  loading?: boolean;
};

/**
 * A super simple Button with no dependencies (like navigation provider) to use on high up Error Boundary
 *
 * Lots of code/styles very similar to Button and copied from there
 *
 */
export const SimpleButton: React.VFC<Props> = ({ variant, text, onPress, StartIcon, loading }) => {
  const tailwind = useTailwind();
  const [isPressed, setIsPressed] = useState(false);

  return (
    <MotiPressable
      onPressIn={() => setIsPressed(true)}
      onPress={onPress}
      onPressOut={() => setIsPressed(false)}
      accessibilityLabel={text}
      accessibilityRole="button"
      style={tailwind(
        `flex-row items-center justify-center px-5 h-14 rounded-2xl ${getButtonColor({ variant, pressed: isPressed })}`,
      )}
      animate={useMemo(
        () =>
          ({ pressed }) => {
            'worklet';
            return {
              scale: pressed ? 0.97 : 1,
            };
          },
        [],
      )}
    >
      {!loading && StartIcon && (
        <View style={tailwind('px-3')}>
          <StartIcon color={getIconStrokeColor({ variant })} height={getIconSize()} width={getIconSize()} />
        </View>
      )}
      {loading && <ActivityIndicator style={tailwind('pr-2')} animating={true} />}
      <Text style={[tailwind('font-medium text-lg'), tailwind(getTextColor({ variant }))]}>{text}</Text>
    </MotiPressable>
  );
};

const getButtonColor = (args: { variant: NonNullable<Props['variant']>; pressed: boolean }): string => {
  const { variant, pressed } = args;
  switch (variant) {
    case 'primary':
      return pressed ? 'bg-teal-800' : 'bg-teal-700';
    case 'secondary':
      return pressed ? 'bg-warmGray-400' : 'bg-warmGray-300';
  }
};

const getTextColor = (args: { variant: NonNullable<Props['variant']> }): string => {
  const { variant } = args;
  switch (variant) {
    case 'primary':
      return 'text-white';
    case 'secondary':
      return 'text-warmGray-800';
  }
};

const getIconStrokeColor = (args: { variant: NonNullable<Props['variant']> }): string => {
  const { variant } = args;
  switch (variant) {
    case 'primary':
      return 'white';
    case 'secondary':
      return colors.warmGray[700];
  }
};

const getIconSize = (): number => {
  return 24;
};
