import { gql, MutationHookOptions } from '@apollo/client';
import { CORE_PRE_PORTFOLIO_FIELDS } from '../../fragments/prePortfolio';
import {
  CreatePrePortfolioMutation,
  CreatePrePortfolioMutationVariables,
  GetPrePortfolioQuery,
  GetPrePortfolioQueryVariables,
  Query,
  UpdatePrePortfolioMutation,
  UpdatePrePortfolioMutationVariables,
  useCreatePrePortfolioMutation,
  useUpdatePrePortfolioMutation,
} from '../../generated/graphql';

// Used to write query
export const GET_PREPORTFOLIO = gql`
  ${CORE_PRE_PORTFOLIO_FIELDS}
  query getPrePortfolio {
    prePortfolio {
      prePortfolio {
        ...CorePrePortfolioFields
      }
    }
  }
`;

export const createPrePortfolio = gql`
  ${CORE_PRE_PORTFOLIO_FIELDS}
  mutation createPrePortfolio($referenceId: ID) {
    createPrePortfolio(referenceId: $referenceId) {
      prePortfolio {
        ...CorePrePortfolioFields
      }
    }
  }
`;

export const updatePrePortfolio = gql`
  ${CORE_PRE_PORTFOLIO_FIELDS}
  mutation updatePrePortfolio(
    $prePortfolioId: ID!
    $currencyId: ID
    $notional: Float
    $cashProportion: Float
    $selectedRiskLevel: Int
    $members: [UpdatePrePortfolioMemberInput!]
    $built: Boolean
  ) {
    updatePrePortfolio(
      prePortfolioId: $prePortfolioId
      currencyId: $currencyId
      notional: $notional
      cashProportion: $cashProportion
      selectedRiskLevel: $selectedRiskLevel
      members: $members
      built: $built
    ) {
      prePortfolio {
        ...CorePrePortfolioFields
      }
      error {
        ... on PrePortfolioImmutable {
          message
        }
        ... on PrePortfolioMissingField {
          message
          missingField
        }
      }
    }
  }
`;

export const CREATE_PREPORTFOLIO_SIMULATIONS = gql`
  mutation createPrePortfolioSimulations($prePortfolioId: ID!) {
    createPrePortfolioSimulations(prePortfolioId: $prePortfolioId) {
      error {
        ... on PrePortfolioSimulationNoMembers {
          message
        }
      }
    }
  }
`;

const simulationsField: keyof Query = 'prePortfolioSimulations';

export const useCreatePrePortfolio = (
  args?: MutationHookOptions<CreatePrePortfolioMutation, CreatePrePortfolioMutationVariables>,
) => {
  return useCreatePrePortfolioMutation({
    ...args,
    update: (cache, { data }) => {
      cache.writeQuery<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>({
        query: GET_PREPORTFOLIO,
        data: { prePortfolio: { prePortfolio: data?.createPrePortfolio.prePortfolio } },
      });
      cache.evict({ fieldName: simulationsField });
      cache.gc();
    },
  });
};

export const useUpdatePrePortfolio = (
  args?: MutationHookOptions<UpdatePrePortfolioMutation, UpdatePrePortfolioMutationVariables>,
) => {
  return useUpdatePrePortfolioMutation({
    ...args,
    update: (cache, { data }) => {
      cache.writeQuery<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>({
        query: GET_PREPORTFOLIO,
        data: { prePortfolio: { prePortfolio: data?.updatePrePortfolio.prePortfolio } },
      });
      cache.evict({ fieldName: simulationsField });
      cache.gc();
    },
  });
};
