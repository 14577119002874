import { clamp } from 'lodash';
import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import Animated, { useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTailwind } from '../../theme';

export type Props = {
  lineWidth?: number;
  barColour?: string;
  /**
   * Should be a value between 0 and 100
   */
  progress: number;
  style?: ViewStyle;
  color?: string;

  /**
   * default: false
   *
   * When `animate` is set to false, the progress bar will not be filled
   * When `animate` is set to `true`, this will animate from 0 to the `progress` value
   *
   * enableAnimation must be set to `true` for the animate prop to have any effect
   */
  animate?: boolean;

  /**
   * default: false
   *
   *
   * If true, this would enable the use of the animate prop,
   * If false, there is no animation and the progress bar would be filled by the amount
   * specified by the progress prop
   *
   */
  enableAnimation?: boolean;
};

export const ProgressBar: React.VFC<Props> = ({ lineWidth, color, style, progress, enableAnimation, animate }) => {
  const tailwind = useTailwind();
  progress = clamp(progress, 0, 100);
  const fill = useDerivedValue(() => {
    if (enableAnimation) {
      if (animate) {
        return withSpring(progress, {
          stiffness: 120,
          damping: 12,
        });
      } else {
        return 0;
      }
    } else {
      return progress;
    }
  }, [progress, animate]);
  const barStyles = useAnimatedStyle<ViewStyle>(() => ({ width: `${fill.value}%` }));

  return (
    <View style={[tailwind('rounded-full'), { height: lineWidth ?? 16, overflow: 'hidden' }, { ...style }]}>
      <View style={[StyleSheet.absoluteFill, tailwind('bg-warmGray-200')]} />
      <Animated.View style={[StyleSheet.absoluteFill, barStyles, { backgroundColor: color ?? 'blue' }]} />
    </View>
  );
};
