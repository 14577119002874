import { ApolloClient, gql, MutationHookOptions, useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { useToast } from 'react-native-toast-notifications';
import {
  AddInstrumentToWatchlistMutation,
  AddInstrumentToWatchlistMutationVariables,
  Instrument,
  Query,
  RemoveInstrumentFromWatchlistMutation,
  RemoveInstrumentFromWatchlistMutationVariables,
  useAddInstrumentToWatchlistMutation,
  useRemoveInstrumentFromWatchlistMutation,
} from '../../generated/graphql';
import { analytics } from '../../services/analytics';

let optimisticId = 0;

/** get unique key each time in case clashes eg press star too fast so 2 in-flight adds */
const getPlaceholderId = () => `WATCHLIST-TOGGLE-PLACEHOLDER-${optimisticId++}`;

/**  makes sure a valid field as out the box apollo cache modify not typed correctly */
const feedField: keyof Query = 'feed';

/**  makes sure a valid field as out the box apollo cache modify not typed correctly */
const instrumentWatchlistItemsField: keyof Query = 'instrumentWatchlistItems';

export const addInstrumentToWatchlist = gql`
  mutation addInstrumentToWatchlist($instrumentId: ID!) {
    addInstrumentToWatchlist(input: { instrumentId: $instrumentId }) {
      id
      instrument {
        id
        watchlistId
      }
    }
  }
`;

export const removeInstrumentFromWatchlist = gql`
  mutation removeInstrumentFromWatchlist($watchlistId: ID!) {
    removeInstrumentFromWatchlist(input: { id: $watchlistId }) {
      id
      instrument {
        id
        watchlistId
      }
    }
  }
`;

/**
 * Watchlist toggle hook. To be used for adding and removing from watchlist
 *
 * Returns `localWatchlistId` which is optimistic and reflects final value
 *
 * Not using apollo cache as causes too many re-renders on slow devices.
 * So locally optimistic boolean means only re-renders the one row
 *
 * @param instrumentId
 * @param watchlistId If watchlistId is falsy, this means the instrument is not watchlisted
 * @param ticker Instrument ticker (for mixpanel event)
 * @returns
 */

export function useInstrumentWatchlistToggle(
  { id, name, ticker, watchlistId }: Pick<Instrument, 'name' | 'ticker' | 'watchlistId' | 'id'>,
  postUpdateOptions: { showToasterOnMutation?: boolean } = {},
) {
  const toast = useToast();
  const { showToasterOnMutation } = postUpdateOptions;

  // local optimistic value, then reflects final value once mutation complete
  const [localWatchlistId, setLocalWatchlistId] = useState(watchlistId);

  const [addToWatchlist, { loading: addLoading }] = useAddToWatchlist({
    onCompleted: ({ addInstrumentToWatchlist }) => {
      // now set local optimistic value to final persisted value
      setLocalWatchlistId(addInstrumentToWatchlist?.instrument?.watchlistId);
      if (showToasterOnMutation) {
        toast.hideAll();
        toast.show(`${name} (${ticker}) has been added to your watchlist`);
      }
      analytics.track('Watchlist changed', {
        action: 'add',
        'Instrument ticker': ticker ?? '',
        'Instrument id': id,
      });
    },
    onError: (error) => {
      // error - reset local state
      setLocalWatchlistId(null);
      console.error(`Error adding to watchlist`, { id, error, instrumentName: name });
      toast.show('Something went wrong. Try again');
    },
    variables: { instrumentId: id },
  });

  const [removeFromWatchlist, { loading: removeLoading }] = useRemoveFromWatchlist(
    { instrumentId: id },
    {
      onCompleted: ({ removeInstrumentFromWatchlist }) => {
        // now set local optimistic value to final persisted value
        setLocalWatchlistId(removeInstrumentFromWatchlist?.instrument?.watchlistId);
        if (showToasterOnMutation) {
          toast.hideAll();
          toast.show(`${name} (${ticker}) has been removed from your watchlist`);
        }
        analytics.track('Watchlist changed', {
          action: 'remove',
          'Instrument ticker': ticker ?? '',
          'Instrument id': id,
        });
      },
      onError: (error: unknown) => {
        // error - reset local state
        setLocalWatchlistId(watchlistId);
        console.error(`Error removing from watchlist`, { id, error, instrumentName: name });
        toast.show('Something went wrong. Try again');
      },
      variables: { watchlistId: watchlistId ?? '' },
    },
  );

  const loading = addLoading || removeLoading;

  const toggleWatchlist = () => {
    // Prevent spam
    if (loading) return;

    if (watchlistId) {
      // optimistic local update first
      setLocalWatchlistId(null);
      removeFromWatchlist();
    } else {
      // optimistic local update first
      setLocalWatchlistId(getPlaceholderId());
      addToWatchlist();
    }
  };

  return { toggleWatchlist, loading, localWatchlistId };
}

/** force feed and watchlist re-fetch behind the scenes */
const evictCache = (client: ApolloClient<object>) => {
  client.cache.evict({ fieldName: instrumentWatchlistItemsField });
  client.cache.evict({ fieldName: feedField });
  client.cache.gc();
};

/** add instrument to watchlist and force refetch of feed by evicting feed cache entry */
const useAddToWatchlist = (
  args?: Omit<
    MutationHookOptions<AddInstrumentToWatchlistMutation, AddInstrumentToWatchlistMutationVariables>,
    'optimisticResponse' | 'update'
  >,
) => {
  const client = useApolloClient();
  return useAddInstrumentToWatchlistMutation({
    ...args,
    // TODO: add optimisticResponse and update back then remove use of localWatchlistId when figure out JS slowness
    // optimisticResponse: ({ instrumentId }) => {
    //   const uuid = getPlaceholderId();
    //   return {
    //     addInstrumentToWatchlist: {
    //       id: uuid,
    //       __typename: 'InstrumentWatchlistItem',
    //       instrument: {
    //         id: instrumentId,
    //         __typename: 'Instrument',
    //         watchlistId: uuid,
    //       },
    //     },
    //   };
    // },
    // update: (cache, { data }) => {
    //   cache.modify({
    //     fields: {
    //       [instrumentWatchlistItemsField](existingItems: InstrumentWatchlistItem[] = []) {
    //         const newItemRef = cache.writeFragment({
    //           id: `${data?.addInstrumentToWatchlist?.__typename}:${data?.addInstrumentToWatchlist?.id}`,
    //           data: data?.addInstrumentToWatchlist,
    //           fragment: gql`
    //             fragment NewWatchlistItem on InstrumentWatchlistItem {
    //               id
    //               instrument {
    //                 id
    //                 watchlistId
    //               }
    //             }
    //           `,
    //         });
    //         // newest items are added to beginning
    //         const updatedItems = [newItemRef, ...existingItems];
    //         return updatedItems;
    //       },
    //     },
    //   });
    // },
    onCompleted: (completedArgs) => {
      evictCache(client);
      args?.onCompleted && args.onCompleted(completedArgs);
    },
  });
};

/** remove instrument from watchlist and force refetch of feed by evicting feed cache entry */
const useRemoveFromWatchlist = (
  input: { instrumentId?: string },
  args?: Omit<
    MutationHookOptions<RemoveInstrumentFromWatchlistMutation, RemoveInstrumentFromWatchlistMutationVariables>,
    'optimisticResponse' | 'update'
  >,
) => {
  const client = useApolloClient();
  return useRemoveInstrumentFromWatchlistMutation({
    ...args,
    // TODO: add optimisticResponse and update back then remove use of localWatchlistId when figure out JS slowness
    // optimisticResponse: () => {
    //   return {
    //     removeInstrumentFromWatchlist: {
    //       id: args?.variables?.watchlistId ?? '',
    //       __typename: 'InstrumentWatchlistItem',
    //       instrument: {
    //         id: input.instrumentId ?? '',
    //         watchlistId: null,
    //         __typename: 'Instrument',
    //       },
    //     },
    //   };
    // },
    // update: (cache, { data }) => {
    //   cache.modify({
    //     fields: {
    //       [instrumentWatchlistItemsField](existingItems: InstrumentWatchlistItem[] = [], { readField }) {
    //         const updatedItems = existingItems.filter(
    //           (item) => readField('id', item) !== data?.removeInstrumentFromWatchlist?.id,
    //         );
    //         return updatedItems;
    //       },
    //     },
    //   });
    // },
    onCompleted: (completedArgs) => {
      evictCache(client);
      args?.onCompleted && args.onCompleted(completedArgs);
    },
  });
};
