/* eslint-disable no-restricted-imports */
import { getPathFromState as getPathFromStateDefault, LinkingOptions, PathConfigMap } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { CLIENT_URL } from '../constants/env';
import { AllStackParamList } from '.';

/**
 * Use typescript to make sure every main screen has an entry key in linking.
 *
 * The string value is the URL path and probably should be the screen name again
 */
const mainScreens: Required<PathConfigMap<Omit<AllStackParamList, 'Tabs'>>> = {
  Landing: 'Landing',
  UpsideShares: 'UpsideShares',
  CashBalance: 'CashBalance',
  Deposit: 'Deposit',
  LogIn: 'LogIn',
  SignUp: {
    path: 'SignUp',
    parse: {
      external: (external: string) => external === 'true',
    },
  },
  Menu: 'Menu',
  Instrument: 'Instrument/:instrumentId',
  MarketInstrument: 'MarketInstrument/:instrumentId',
  InstrumentSummary: 'Instrument/:instrumentId/summary',
  InstrumentFinancials: 'Instrument/:instrumentId/financials',
  DiscoverCategory: 'DiscoverCategory', // categoryId, categoryType are query params
  Idea: 'Idea/:ideaId',
  Blog: 'Blog/:blogId',
  AppContent: 'AppContent/:appContentId',
  News: 'News/:newsId',
  Voice: 'Voice/:voiceId',
  UserProfile: {
    path: 'UserProfile/:userId',
    parse: {
      userId: (userId: string) => parseInt(userId),
    },
  },
  WebviewScreen: 'WebviewScreen',
  RequestPasswordReset: 'RequestPasswordReset',
  NewPassword: 'NewPassword', // uid, token are query params
  CreateIdea: 'CreateIdea/:instrumentId',
  FeedItem: {
    path: '/FeedItem/:feedItemId',
  },
  PrePortfolioSelectIdeas: {
    path: '/Portfolio/ChooseIdeas',
  },
  PrePortfolioSelectRisk: {
    path: '/Portfolio/SelectRisk',
  },
  PrePortfolioSimulatorRunning: {
    path: '/Portfolio/SimulatorRunning',
  },
  SearchModal: { path: 'SearchModal' },
  UploadProfileImage: { path: 'UploadProfileImage' },
  UploadProfileImageSignup: { path: 'UploadProfileImageSignup' },
  ViewProfileImage: { path: 'ViewProfileImage' },
  TradingAccountPersonalDetails: '/TradingAccountPersonalDetails',
  TradingAccountAddressDetails: '/TradingAccountAddressDetails',
  TradingAccountBankDetails: '/TradingAccountBankDetails',
  TradingAccountSubmit: '/TradingAccountSubmit',
  Stack: '/Stack/:id',
  Stacks: '/Stacks',
  OnboardingGetStarted: '/OnboardingGetStarted',
  OnboardingAboutYou: '/OnboardingAboutYou',
  OnboardingSelectBase: '/OnboardingSelectBase',
  OnboardingSelectBundles: '/OnboardingSelectBundles',
  OnboardingSelectStocks: '/OnboardingSelectStocks',
  OnboardingConfirmPortfolio: 'OnboardingConfirmPortfolio',
  OnboardingCreatingPortfolio: '/OnboardingCreatingPortfolio',
  OnboardingEnterEmail: 'OnboardingEnterEmail',
};

/**
 * AWS Amplify does not support passing on query params (!)
 * https://github.com/aws-amplify/amplify-console/issues/97
 *
 * So make sure all paths end in a '/'
 * eg /foo/bar?baz=1 => /foo/bar/?baz=1
 */
const getPathFromState: typeof getPathFromStateDefault = (...args) => {
  const path = getPathFromStateDefault(...args);
  if (path.indexOf('/?') >= 0 || path.endsWith('/')) return path; // already fine

  const questionMark = path.lastIndexOf('?');
  if (questionMark >= 0) {
    return `${path.slice(0, questionMark)}/${path.slice(questionMark)}`;
  }
  return `${path}/`;
};

/**
 * setup URLs for web and deep linking
 *
 * Make sure every screen you need to deep link in correct place here with necessary state stack so back button works
 *
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
export const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  getPathFromState,
  // Ensure app scheme always available https://reactnavigation.org/docs/deep-linking/#setup-with-expo-projects
  prefixes: [Linking.createURL('/'), 'https://*.upsidetechnology.co', 'https://upsidetechnology.co'],
  config: {
    screens: {
      // all tab screens here
      Tabs: {
        path: 'Home',
        screens: {
          HomeTab: {
            screens: {
              Home: 'Home',
            },
          },
          DiscoverTab: {
            screens: {
              Discover: 'Discover', // `/Home/Discover` is nicer URL than `/Main/Tabs/Discover/Discover`
            },
          },
          WatchlistTab: {
            screens: {
              Watchlist: 'Watchlist', // `/Home/Watchlist` is nicer URL than `/Main/Tabs/WatchlistTab/Watchlist`
            },
          },
          FeedTab: {
            screens: {
              Feed: 'Feed', // `/Home/Feed` is nicer URL than `/Main/Tabs/Feed/Feed`
            },
          },
          IdeasTab: {
            screens: {
              Ideas: {
                path: 'Ideas', // `/Home/Ideas` is nicer URL than `/Main/Tabs/Ideas/Ideas`
                parse: {
                  initialSelectedIndex: (selectedIndex: string) => parseInt(selectedIndex),
                },
              },
            },
          },
          BlueprintTab: {
            screens: {
              Blueprint: 'Portfolio',
            },
          },
        },
      },
      // add all mainScreens
      ...mainScreens,
    },
  },
};

// TODO: Write a url generator using our linking config
/** get full instrument web url to share including domain */
export const getInstrumentUrl = (instrumentId: string, feedItemId?: string) =>
  `${CLIENT_URL}/Instrument/${instrumentId}${feedItemId ? `/${feedItemId}` : ''}`;
