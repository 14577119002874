import React, { ReactNode } from 'react';
import { DefaultFallback } from './DefaultFallback';

interface Props {
  children: ReactNode;
  /** Optionally use your own fallback component on error. Else will use DefaultFallback */
  FallbackComponent?: React.ComponentType;
}
interface State {
  error: Error | null;
}

/**
 * Root Error Boundary in AppProvider.
 * High up so above navigator.
 * Hopefully rarely see this as should be use `withReloadErrorBoundary` in all our screens
 */
export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    console.error('ErrorBoundary showing Fallback.', error);
  }

  render() {
    const { FallbackComponent, children } = this.props;
    const { error } = this.state;

    if (error) {
      if (FallbackComponent) {
        return <FallbackComponent />;
      }
      return <DefaultFallback />;
    }

    return children;
  }
}
