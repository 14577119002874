import React, { useMemo } from 'react';
import { TextInput as RNTextInput, StyleProp, TextInputProps, View, ViewStyle } from 'react-native';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export type Props = Omit<TextInputProps, 'style'> & {
  style?: StyleProp<ViewStyle>;
  errorMessage?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
};
export const TextInput: React.FC<Props> = ({ style, errorMessage, disabled, endAdornment, ...props }) => {
  const tailwind = useTailwind();

  const containerStyles = useMemo(
    () => [
      tailwind('w-full flex-row border-gray-300 border rounded-xl px-3 py-4 bg-white'),
      !!errorMessage && tailwind('bg-red-100 border-error-dark'),
      style,
    ],
    [style, errorMessage, tailwind],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const inputStyles = useMemo(
    () => [tailwind('text-sm flex-grow text-gray-900'), disabled && tailwind('text-gray')],
    [tailwind, disabled],
  );
  return (
    <View>
      <View style={containerStyles}>
        <RNTextInput
          {...props}
          editable={!disabled}
          textAlignVertical={props.multiline ? 'top' : undefined}
          placeholderTextColor={colors.gray[500]}
          style={inputStyles}
        />
        {endAdornment && endAdornment}
      </View>
      {errorMessage && <Text style={tailwind('text-error-default text-xs pt-3')}>{errorMessage}</Text>}
    </View>
  );
};
