/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents a numerical long value. */
  Long: number;
  /** Represents the epoch time in milliseconds. */
  Timestamp: number;
  /** Represents an ISO-8601 UTC date and time (e.g. 2007-12-03T10:15:30Z). This can be converted from an epoch number or from an ISO string. */
  DateTime: string | number | Date;
  /** Represents an ISO-8601 UTC date (e.g. 2007-12-03). This can be converted from an ISO string only. */
  Date: string | Date;
  /** Represents a string that cannot be empty or blank. */
  NonEmptyString: string;
  /** Represents a JSON object according to ECMA-404. */
  JSONObject: object;
  /** Represents a file object to upload. */
  Upload: Blob | import('apollo-upload-client').ReactNativeFile;
};






/** Node interface implemented by all types to allow for refetching and caching. */
export type Node = {
  /** The node identifier. This can be the same as another field that identifies the entity. */
  id: Scalars['ID'];
};

/** Node interface for all feed items. */
export type FeedItemNode = {
  /** The ID of the feed item. */
  id: Scalars['ID'];
  /** The type of the feed item */
  type: FeedItemType;
  /** When this feed item was created. */
  created: Scalars['DateTime'];
  /** The saved feed item id, if this feed item is saved, otherwise null. */
  savedFeedItemId?: Maybe<Scalars['ID']>;
};

/** Connection interface implemented for pagination. */
export type Connection = {
  pageInfo: PageInfo;
  nodes: Array<Node>;
};

/** Error interface for validation errors. */
export type ValidationError = {
  message: Scalars['String'];
};

/** The main query type. */
export type Query = {
  __typename?: 'Query';
  baseStack: StackPayload;
  /** @deprecated Use `getComment` with payload response instead. */
  comment?: Maybe<Comment>;
  /** Fetches a list of countries where instruments, that have public ideas, are exchange listed. */
  countriesWithInstrumentIdeas: CountriesWithInstrumentIdeasPayload;
  datoAppContent: DatoAppContentPayload;
  datoAppContents: DatoAppContentsPayload;
  datoBlog: DatoBlogPayload;
  datoBlogs: DatoBlogsPayload;
  discover: Array<DiscoverCategory>;
  discoverCategory: DiscoverCategory;
  feed: Array<FeedItem>;
  feedItem?: Maybe<FeedItem>;
  getComment?: Maybe<CommentPayload>;
  getTopic?: Maybe<TopicPayload>;
  /** Retrieves the idea rationale categories and their sub-tags */
  ideaRationaleCategories: Array<IdeaRationaleCategory>;
  /** Retrieve ideas that a user has added to their watchlist */
  ideaWatchlistItems: Array<IdeaWatchlistItem>;
  /**
   * Retrieve ideas created by the signed in user
   * @deprecated Use `ideasPaginated` instead.
   */
  ideas: Array<Idea>;
  /** Retrieve ideas that are valid for use in a Portfolio. */
  ideasForPortfolio?: Maybe<IdeasForPortfolioPayload>;
  ideasPaginated: IdeaConnection;
  /** Retrieve an instrument by its ID. */
  instrument: Instrument;
  instrumentFeed?: Maybe<InstrumentFeedPayload>;
  instrumentSearch: Array<Instrument>;
  instrumentWatchlistItems: Array<InstrumentWatchlistItem>;
  journeySummary?: Maybe<JourneySummaryPayload>;
  me: MePayload;
  /** Gets the instruments that power the market data card at the top of the feed. E.g. S&P 500, FTSE 100, Oil etc. */
  myMarketData: MyMarketDataPayload;
  /** @deprecated Using another push provider */
  myNotifications: NotificationPayload;
  myPortfolios: MyTradingPortfoliosPayload;
  myReferralStats: MyReferralStatsPayload;
  myTradingParty: MyTradingPartyPayload;
  myUpsideShares: MyUpsideSharesPayload;
  news: News;
  popularInstruments: Array<Instrument>;
  /** Retrieve hacked together weighted insights for portfolio from user sign up */
  portfolioInsights?: Maybe<PortfolioInsightsPayload>;
  /** Retrieves the active PrePortfolio for the signed in user. */
  prePortfolio: PrePortfolioPayload;
  /** Retrieve all simulations for the given PrePortfolio id. */
  prePortfolioSimulations: PrePortfolioSimulationPayload;
  /** Public idea only. */
  publicIdea: Idea;
  /**
   * Retrieve all ideas that are marked public for a provided user
   * @deprecated Use `publicIdeasPaginated` instead.
   */
  publicIdeas: Array<Idea>;
  publicIdeasPaginated: IdeaConnection;
  publicInstrumentWatchlist: Array<InstrumentWatchlistItem>;
  publicProfile: UserProfile;
  /** Retrieve the latest price for the given list of symbols. */
  quotePrices: Array<Maybe<QuotePrice>>;
  /**
   * Gets the referral leaderboard sorted in descending order by number of referrals.
   * limit sets the max number of referral stats to be returned.
   */
  referralStatsLeaderboard: ReferralStatsLeaderboardPayload;
  savedFeedItems: Array<FeedItem>;
  /** Search for instruments and users. */
  search: SearchPayload;
  /** Fetches a list of sectors where instruments, that have public ideas, are exchange listed. */
  sectorsWithInstrumentIdeas: SectorsWithInstrumentIdeasPayload;
  stack: StackPayload;
  stacks: StacksPayload;
  /** @deprecated Use `getTopic` with payload response instead. */
  topic?: Maybe<Topic>;
  /** @deprecated Using another push provider */
  userNotifications: NotificationPayload;
  voice: DatoUpsideVoice;
};


/** The main query type. */
export type QueryCommentArgs = {
  commentInput: CommentInput;
};


/** The main query type. */
export type QueryDatoAppContentArgs = {
  id: Scalars['ID'];
  markdown?: Maybe<Scalars['Boolean']>;
  imgixParams?: Maybe<ImgixInput>;
};


/** The main query type. */
export type QueryDatoAppContentsArgs = {
  pagination?: Maybe<DatoPageInput>;
  filter?: Maybe<DatoAppContentFilter>;
  orderBy?: Maybe<Array<DatoAppContentOrderBy>>;
  markdown?: Maybe<Scalars['Boolean']>;
  imgixParams?: Maybe<ImgixInput>;
};


/** The main query type. */
export type QueryDatoBlogArgs = {
  id: Scalars['ID'];
  markdown?: Maybe<Scalars['Boolean']>;
  imgixParams?: Maybe<ImgixInput>;
};


/** The main query type. */
export type QueryDatoBlogsArgs = {
  pagination?: Maybe<DatoPageInput>;
  filter?: Maybe<DatoBlogFilter>;
  orderBy?: Maybe<Array<DatoBlogOrderBy>>;
  markdown?: Maybe<Scalars['Boolean']>;
  imgixParams?: Maybe<ImgixInput>;
};


/** The main query type. */
export type QueryDiscoverCategoryArgs = {
  input: DiscoverCategoryInput;
};


/** The main query type. */
export type QueryFeedArgs = {
  input?: Maybe<FeedInput>;
};


/** The main query type. */
export type QueryFeedItemArgs = {
  id: Scalars['ID'];
};


/** The main query type. */
export type QueryGetCommentArgs = {
  commentInput: CommentInput;
};


/** The main query type. */
export type QueryGetTopicArgs = {
  topicInput: TopicInput;
  pagination?: Maybe<PageInput>;
};


/** The main query type. */
export type QueryIdeasArgs = {
  input?: Maybe<IdeasInput>;
};


/** The main query type. */
export type QueryIdeasForPortfolioArgs = {
  pagination?: Maybe<PageInput>;
};


/** The main query type. */
export type QueryIdeasPaginatedArgs = {
  filter?: Maybe<IdeasFilter>;
  pagination?: Maybe<PageInput>;
};


/** The main query type. */
export type QueryInstrumentArgs = {
  id: Scalars['ID'];
};


/** The main query type. */
export type QueryInstrumentFeedArgs = {
  input?: Maybe<InstrumentFeedInput>;
};


/** The main query type. */
export type QueryInstrumentSearchArgs = {
  searchText: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  instrumentTypes?: Maybe<Array<InstrumentType>>;
};


/** The main query type. */
export type QueryInstrumentWatchlistItemsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


/** The main query type. */
export type QueryNewsArgs = {
  id: Scalars['ID'];
};


/** The main query type. */
export type QueryPopularInstrumentsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


/** The main query type. */
export type QueryPortfolioInsightsArgs = {
  cashProportion: Scalars['Float'];
  components: Array<ComponentInput>;
};


/** The main query type. */
export type QueryPrePortfolioSimulationsArgs = {
  prePortfolioId: Scalars['ID'];
};


/** The main query type. */
export type QueryPublicIdeaArgs = {
  id: Scalars['ID'];
};


/** The main query type. */
export type QueryPublicIdeasArgs = {
  userId: Scalars['Int'];
  active?: Maybe<Scalars['Boolean']>;
};


/** The main query type. */
export type QueryPublicIdeasPaginatedArgs = {
  filter?: Maybe<PublicIdeasFilter>;
  pagination?: Maybe<PageInput>;
};


/** The main query type. */
export type QueryPublicInstrumentWatchlistArgs = {
  userId: Scalars['Int'];
};


/** The main query type. */
export type QueryPublicProfileArgs = {
  userId: Scalars['Int'];
};


/** The main query type. */
export type QueryQuotePricesArgs = {
  symbols: Array<Scalars['NonEmptyString']>;
};


/** The main query type. */
export type QueryReferralStatsLeaderboardArgs = {
  limit: Scalars['Int'];
};


/** The main query type. */
export type QuerySavedFeedItemsArgs = {
  input: SavedFeedItemsInput;
};


/** The main query type. */
export type QuerySearchArgs = {
  searchText: Scalars['NonEmptyString'];
};


/** The main query type. */
export type QueryStackArgs = {
  stackId: Scalars['String'];
};


/** The main query type. */
export type QueryTopicArgs = {
  topicInput: TopicInput;
  pagination?: Maybe<PageInput>;
};


/** The main query type. */
export type QueryUserNotificationsArgs = {
  userId: Scalars['Int'];
};


/** The main query type. */
export type QueryVoiceArgs = {
  id: Scalars['ID'];
};

/** The main mutation type. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Adds an idea to a user's watchlist. */
  addIdeaToWatchlist?: Maybe<IdeaWatchlistItem>;
  /**
   * Adds an instrument to the user's watchlist.
   * @deprecated Use new `addInstrumentsToWatchlist` endpoint capable of adding multiple instruments and with better payload response.
   */
  addInstrumentToWatchlist?: Maybe<InstrumentWatchlistItem>;
  /** Adds multiple instruments to the user's watchlist. */
  addInstrumentsToWatchlist?: Maybe<AddInstrumentsToWatchlistPayload>;
  /** Cancel a deposit expectation. */
  cancelTradingDepositExpectation: CancelTradingDepositExpectationPayload;
  /** Close an idea. */
  closeIdea: CloseIdeaPayload;
  /** Finish the password reset process. */
  confirmPasswordReset: ConfirmPasswordResetPayload;
  /** @deprecated Use new `postComment` endpoint with payload response instead. */
  createComment?: Maybe<Comment>;
  /** Creates a new idea. */
  createIdea?: Maybe<Idea>;
  /** Created a link between an insight (FeedItem) and Idea. */
  createIdeaInsight?: Maybe<IdeaInsight>;
  /** Creates a PrePortfolio for a user. */
  createPrePortfolio: CreatePrePortfolioPayload;
  /** Create simulations for a pre-portfolio, asynchronously. This will delete any existing simulations prior to creating the new ones. */
  createPrePortfolioSimulations: CreatePrePortfolioSimulationsPayload;
  /** Create a trading account and portfolio. */
  createTradingAccountAndPortfolio: CreateTradingAccountAndPortfolioPayload;
  /** Add a new address for trading. */
  createTradingAddress: CreateTradingAddressPayload;
  /** Add a new bank account for trading. */
  createTradingBankAccount: CreateTradingBankAccountPayload;
  /** Create a new deposit expectation. */
  createTradingDepositExpectation: CreateTradingDepositExpectationPayload;
  /**
   * Deactivate expo push token for authenticated user
   * @deprecated Using another push provider
   */
  deactivateExpoPushToken?: Maybe<ExpoPushTokenPayload>;
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** A mutation to delete one's onw profile picture. */
  deleteProfileImage: DeleteProfileImagePayload;
  editComment?: Maybe<EditCommentPayload>;
  flagComment?: Maybe<FlagCommentPayload>;
  /** Initialises a new portfolio for the user. */
  initialiseTradingPortfolio: InitialiseTradingPortfolioPayload;
  /** Login a user, returning a refresh token. The refresh token is also provided as a secure http-only cookie. */
  login: LoginPayload;
  logout: LogoutPayload;
  postComment?: Maybe<PostCommentPayload>;
  /** Record an onboarding portfolio for an email in segment. */
  recordOnboardingPortfolio: RecordOnboardingPortfolioPayload;
  /**
   * Obtain an access token from a refresh token. The refresh token can be provided in the request or as a cookie.
   * If both forms are provided, the token from the request takes precedence.
   */
  refresh: RefreshPayload;
  /** Removes an idea from a user's watchlist. */
  removeIdeaFromWatchlist?: Maybe<IdeaWatchlistItem>;
  /** Removes an instrument from the user's watchlist. */
  removeInstrumentFromWatchlist?: Maybe<InstrumentWatchlistItem>;
  /** Send an email to a user with a password reset link. */
  requestPasswordReset: RequestPasswordResetPayload;
  /**
   * Save expo push token for user
   * @deprecated Using another push provider
   */
  saveExpoPushToken?: Maybe<ExpoPushTokenPayload>;
  /** Save a feed item. */
  saveFeedItem?: Maybe<FeedItem>;
  saveReferral?: Maybe<SaveReferralPayload>;
  /** Signup a new user and log them in. */
  signup: SignupPayload;
  unflagComment?: Maybe<UnflagCommentPayload>;
  /** Unsave a feed item. */
  unsaveFeedItem?: Maybe<FeedItem>;
  /** Updates a user's PrePortfolio, this can be used to change the currency, notional or cash proportion. */
  updatePrePortfolio: UpdatePrePortfolioPayload;
  /** An idempotent mutation to upload a new profile picture, the old one (if exists) is deleted automatically. */
  updateProfileImage: UpdateProfileImagePayload;
  /** Create or update a trading party. */
  upsertTradingParty: UpsertTradingPartyPayload;
};


/** The main mutation type. */
export type MutationAddIdeaToWatchlistArgs = {
  input: AddIdeaToWatchlistInput;
};


/** The main mutation type. */
export type MutationAddInstrumentToWatchlistArgs = {
  input: AddInstrumentToWatchlistInput;
};


/** The main mutation type. */
export type MutationAddInstrumentsToWatchlistArgs = {
  input: AddInstrumentsToWatchlistInput;
};


/** The main mutation type. */
export type MutationCancelTradingDepositExpectationArgs = {
  depositId: Scalars['String'];
};


/** The main mutation type. */
export type MutationCloseIdeaArgs = {
  id: Scalars['ID'];
};


/** The main mutation type. */
export type MutationConfirmPasswordResetArgs = {
  uid: Scalars['NonEmptyString'];
  token: Scalars['NonEmptyString'];
  newPassword: Scalars['NonEmptyString'];
};


/** The main mutation type. */
export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};


/** The main mutation type. */
export type MutationCreateIdeaArgs = {
  input: CreateIdeaInput;
};


/** The main mutation type. */
export type MutationCreateIdeaInsightArgs = {
  input: CreateIdeaInsightInput;
};


/** The main mutation type. */
export type MutationCreatePrePortfolioArgs = {
  referenceId?: Maybe<Scalars['ID']>;
};


/** The main mutation type. */
export type MutationCreatePrePortfolioSimulationsArgs = {
  prePortfolioId: Scalars['ID'];
};


/** The main mutation type. */
export type MutationCreateTradingAddressArgs = {
  address: TradingAddressInput;
};


/** The main mutation type. */
export type MutationCreateTradingBankAccountArgs = {
  bankAccount: TradingBankAccountInput;
};


/** The main mutation type. */
export type MutationCreateTradingDepositExpectationArgs = {
  portfolioId: Scalars['String'];
  amount: Scalars['String'];
  currency: Scalars['String'];
};


/** The main mutation type. */
export type MutationDeactivateExpoPushTokenArgs = {
  token: Scalars['String'];
};


/** The main mutation type. */
export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


/** The main mutation type. */
export type MutationDeleteProfileImageArgs = {
  mediaId: Scalars['ID'];
};


/** The main mutation type. */
export type MutationEditCommentArgs = {
  input: EditCommentInput;
};


/** The main mutation type. */
export type MutationFlagCommentArgs = {
  input: FlagCommentInput;
};


/** The main mutation type. */
export type MutationInitialiseTradingPortfolioArgs = {
  initialPortfolioIntention: InitialPortfolioIntentionInput;
};


/** The main mutation type. */
export type MutationLoginArgs = {
  email: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
};


/** The main mutation type. */
export type MutationPostCommentArgs = {
  input: PostCommentInput;
};


/** The main mutation type. */
export type MutationRecordOnboardingPortfolioArgs = {
  email: Scalars['NonEmptyString'];
  portfolio: OnboardingPortfolioInput;
  anonymousId?: Maybe<Scalars['NonEmptyString']>;
  emailTemplate: OnboardingEmailTemplate;
};


/** The main mutation type. */
export type MutationRefreshArgs = {
  refreshToken?: Maybe<Scalars['NonEmptyString']>;
};


/** The main mutation type. */
export type MutationRemoveIdeaFromWatchlistArgs = {
  input: RemoveIdeaFromWatchlistInput;
};


/** The main mutation type. */
export type MutationRemoveInstrumentFromWatchlistArgs = {
  input: RemoveInstrumentFromWatchlistInput;
};


/** The main mutation type. */
export type MutationRequestPasswordResetArgs = {
  email: Scalars['NonEmptyString'];
};


/** The main mutation type. */
export type MutationSaveExpoPushTokenArgs = {
  token: Scalars['String'];
};


/** The main mutation type. */
export type MutationSaveFeedItemArgs = {
  input: SaveFeedItemInput;
};


/** The main mutation type. */
export type MutationSaveReferralArgs = {
  referrerNickname: Scalars['String'];
};


/** The main mutation type. */
export type MutationSignupArgs = {
  email: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  nickname: Scalars['NonEmptyString'];
  mailingList: Scalars['Boolean'];
};


/** The main mutation type. */
export type MutationUnflagCommentArgs = {
  input: UnflagCommentInput;
};


/** The main mutation type. */
export type MutationUnsaveFeedItemArgs = {
  input: UnsaveFeedItemInput;
};


/** The main mutation type. */
export type MutationUpdatePrePortfolioArgs = {
  prePortfolioId: Scalars['ID'];
  currencyId?: Maybe<Scalars['ID']>;
  notional?: Maybe<Scalars['Float']>;
  cashProportion?: Maybe<Scalars['Float']>;
  selectedRiskLevel?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<UpdatePrePortfolioMemberInput>>;
  built?: Maybe<Scalars['Boolean']>;
};


/** The main mutation type. */
export type MutationUpdateProfileImageArgs = {
  image: Scalars['Upload'];
};


/** The main mutation type. */
export type MutationUpsertTradingPartyArgs = {
  party: TradingPartyInput;
};

export enum AppEntityType {
  Idea = 'IDEA',
  UserGroup = 'USER_GROUP',
  User = 'USER'
}

export type SignupError = EmailInvalid | NicknameInvalid | PasswordInvalid;

export type EmailInvalid = ValidationError & {
  __typename?: 'EmailInvalid';
  message: Scalars['String'];
};

export type NicknameInvalid = ValidationError & {
  __typename?: 'NicknameInvalid';
  message: Scalars['String'];
};

export type PasswordInvalid = ValidationError & {
  __typename?: 'PasswordInvalid';
  message: Scalars['String'];
};

export type LoginError = AuthenticationFailed;

export type RefreshError = AuthenticationFailed | TokenNotProvided;

export type AuthenticationFailed = ValidationError & {
  __typename?: 'AuthenticationFailed';
  message: Scalars['String'];
};

export type TokenNotProvided = ValidationError & {
  __typename?: 'TokenNotProvided';
  message: Scalars['String'];
};

export type UserInvalid = ValidationError & {
  __typename?: 'UserInvalid';
  message: Scalars['String'];
};

export type TokenInvalid = ValidationError & {
  __typename?: 'TokenInvalid';
  message: Scalars['String'];
};

export type RequestPasswordResetError = EmailInvalid;

export type ConfirmPasswordResetError = UserInvalid | TokenInvalid | PasswordInvalid;

export type SignupPayload = {
  __typename?: 'SignupPayload';
  error?: Maybe<SignupError>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  error?: Maybe<LoginError>;
  accessToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type RefreshPayload = {
  __typename?: 'RefreshPayload';
  error?: Maybe<RefreshError>;
  accessToken?: Maybe<Scalars['String']>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  error?: Maybe<ValidationError>;
};

export type RequestPasswordResetPayload = {
  __typename?: 'RequestPasswordResetPayload';
  error?: Maybe<RequestPasswordResetError>;
};

export type ConfirmPasswordResetPayload = {
  __typename?: 'ConfirmPasswordResetPayload';
  error?: Maybe<ValidationError>;
};

export type CommentServiceError = CommentProfane | CommentTooLong | CommentEmpty;

export type CommentProfane = ValidationError & {
  __typename?: 'CommentProfane';
  message: Scalars['String'];
};

export type CommentTooLong = ValidationError & {
  __typename?: 'CommentTooLong';
  message: Scalars['String'];
};

export type CommentEmpty = ValidationError & {
  __typename?: 'CommentEmpty';
  message: Scalars['String'];
};

export type CommentInput = {
  commentId: Scalars['ID'];
};

export type TopicInput = {
  appEntityId: Scalars['ID'];
  appEntityType: AppEntityType;
  /** If not provided, default is 50 comments. DEPRECATED: use pagination instead. */
  commentLimit?: Maybe<Scalars['Int']>;
};

export type PostCommentInput = {
  appEntityId: Scalars['ID'];
  appEntityType: AppEntityType;
  /** Only supply for posting a reply */
  parentCommentId?: Maybe<Scalars['ID']>;
  commentText: Scalars['String'];
};

export type CreateCommentInput = {
  appEntityId: Scalars['ID'];
  appEntityType: AppEntityType;
  parentCommentId?: Maybe<Scalars['ID']>;
  commentText: Scalars['String'];
};

export type EditCommentInput = {
  commentId: Scalars['ID'];
  commentText: Scalars['String'];
};

export type DeleteCommentInput = {
  commentId: Scalars['ID'];
};

export type FlagCommentInput = {
  commentId: Scalars['ID'];
};

export type UnflagCommentInput = {
  commentId: Scalars['ID'];
};

export enum CommentStatus {
  Active = 'ACTIVE',
  Edited = 'EDITED',
  Deleted = 'DELETED'
}

export type CommentConnection = Connection & {
  __typename?: 'CommentConnection';
  pageInfo: PageInfo;
  nodes: Array<Comment>;
};

export type Topic = {
  __typename?: 'Topic';
  comments?: Maybe<CommentConnection>;
  commentCount: Scalars['Int'];
};

export type Comment = Node & {
  __typename?: 'Comment';
  id: Scalars['ID'];
  text: Scalars['String'];
  created: Scalars['DateTime'];
  updated: Scalars['DateTime'];
  status: CommentStatus;
  user?: Maybe<UserProfile>;
  /** @deprecated Use `repliesPaginated` instead. */
  replies: Array<Comment>;
  repliesPaginated?: Maybe<CommentConnection>;
  replyCount: Scalars['Int'];
  userFlag?: Maybe<Flag>;
};


export type CommentRepliesPaginatedArgs = {
  pagination?: Maybe<PageInput>;
};

export type Flag = {
  __typename?: 'Flag';
  commentId?: Maybe<Scalars['String']>;
  user?: Maybe<UserProfile>;
  created?: Maybe<Scalars['DateTime']>;
};

export type TopicPayload = {
  __typename?: 'TopicPayload';
  topic?: Maybe<Topic>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  comment?: Maybe<Comment>;
};

export type PostCommentPayload = {
  __typename?: 'PostCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<CommentServiceError>;
};

export type EditCommentPayload = {
  __typename?: 'EditCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<CommentServiceError>;
};

export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<CommentServiceError>;
};

export type FlagCommentPayload = {
  __typename?: 'FlagCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<CommentServiceError>;
};

export type UnflagCommentPayload = {
  __typename?: 'UnflagCommentPayload';
  comment?: Maybe<Comment>;
  error?: Maybe<CommentServiceError>;
};

export type CountryFilter = {
  isoCodes?: Maybe<Array<Scalars['String']>>;
};

export type Country = Node & {
  __typename?: 'Country';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  iso2Code?: Maybe<Scalars['String']>;
};

export type CountriesWithInstrumentIdeasPayload = {
  __typename?: 'CountriesWithInstrumentIdeasPayload';
  countries?: Maybe<Array<Country>>;
};

export type Currency = Node & {
  __typename?: 'Currency';
  id: Scalars['ID'];
  /** Represents an ISO-4217 currency code (e.g. GBP). */
  iso?: Maybe<Scalars['String']>;
  /** Name of a currency (e.g. British Pound). */
  name?: Maybe<Scalars['String']>;
};

/** Connection interface implemented for DatoCMS specific pagination. */
export type DatoConnection = {
  pageInfo: DatoPageInfo;
  nodes: Array<Node>;
};

/** Enums are copies of those found in Dato's 'BlogModelOrderBy' */
export enum DatoBlogOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PublishedDateAsc = 'PUBLISHED_DATE_ASC',
  PublishedDateDesc = 'PUBLISHED_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

/** Enums are copies of those found in Dato's 'AppcontentModelOrderBy' */
export enum DatoAppContentOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PublishedDateAsc = 'PUBLISHED_DATE_ASC',
  PublishedDateDesc = 'PUBLISHED_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

/** Enums are copies of those found in Dato's 'StackCommentaryModelOrderBy' */
export enum DatoStackCommentaryOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PublishedDateAsc = 'PUBLISHED_DATE_ASC',
  PublishedDateDesc = 'PUBLISHED_DATE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

/** Enums are copies of those found in Dato's 'ImgixParamsFit' */
export enum ImgixParamsFit {
  Clamp = 'CLAMP',
  Clip = 'CLIP',
  Crop = 'CROP',
  Facearea = 'FACEAREA',
  Fill = 'FILL',
  Fillmax = 'FILLMAX',
  Max = 'MAX',
  Min = 'MIN',
  Scale = 'SCALE'
}

export enum DatoBlogDestination {
  Web = 'WEB',
  App = 'APP',
  All = 'ALL'
}

export type DatoPageInput = {
  /** Grab the first n items */
  first?: Maybe<Scalars['Int']>;
  /** Skip the first n items */
  skip?: Maybe<Scalars['Int']>;
};

export type ImgixInput = {
  /** The desired width of the image. */
  width?: Maybe<Scalars['Int']>;
  /** The desired height of the image. */
  height?: Maybe<Scalars['Int']>;
  /** How the image should be fit into the frame. */
  fit?: Maybe<ImgixParamsFit>;
};

/** A selection of filterable fields for Blogs based on our own needs, not an exhaustive list of what's possible in Dato */
export type DatoBlogFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  destination?: Maybe<DestinationFilter>;
  publishedDate?: Maybe<DateFilter>;
};

/** A selection of filterable fields for AppContents based on our own needs, not an exhaustive list of what's possible in Dato */
export type DatoAppContentFilter = {
  id?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  publishedDate?: Maybe<DateFilter>;
};

/** A selection of filterable fields for StackContents based on our own needs, not an exhaustive list of what's possible in Dato */
export type DatoStackContentFilter = {
  id?: Maybe<StringFilter>;
};

/** A selection of filterable fields for StackCommentaries based on our own needs, not an exhaustive list of what's possible in Dato */
export type DatoStackCommentaryFilter = {
  stackContent?: Maybe<StringFilter>;
};

/** A selection of filterable inequalities based on our own needs, not an exhaustive list of what's possible in Dato */
export type DestinationFilter = {
  eq?: Maybe<DatoBlogDestination>;
  neq?: Maybe<DatoBlogDestination>;
  in?: Maybe<Array<Maybe<DatoBlogDestination>>>;
  notIn?: Maybe<Array<Maybe<DatoBlogDestination>>>;
};

/** A selection of filterable inequalities based on our own needs, not an exhaustive list of what's possible in Dato */
export type StringFilter = {
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  notIn?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A selection of filterable inequalities based on our own needs, not an exhaustive list of what's possible in Dato */
export type DateFilter = {
  gt?: Maybe<Scalars['Date']>;
  lt?: Maybe<Scalars['Date']>;
  gte?: Maybe<Scalars['Date']>;
  lte?: Maybe<Scalars['Date']>;
  eq?: Maybe<Scalars['Date']>;
  neq?: Maybe<Scalars['Date']>;
};

/** A selection of Dato blog fields based on our needs, not an exhaustive list of the Dato blog model */
export type DatoBlog = Node & {
  __typename?: 'DatoBlog';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  destination?: Maybe<DatoBlogDestination>;
  coverImage?: Maybe<DatoImageFile>;
  publishedDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/**
 * Not following our own typical Connection model for pagination as Dato use offset-based pagination, so we use a rough
 * template of offset-pagination here instead.
 */
export type DatoBlogConnection = DatoConnection & {
  __typename?: 'DatoBlogConnection';
  pageInfo: DatoPageInfo;
  nodes: Array<DatoBlog>;
};

/** A selection of Dato AppContent fields based on our needs, not an exhaustive list of the Dato AppContent model */
export type DatoAppContent = Node & {
  __typename?: 'DatoAppContent';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  actionButtonText?: Maybe<Scalars['String']>;
  actionButtonDeepLink?: Maybe<Scalars['String']>;
  coverImage?: Maybe<DatoImageFile>;
  publishedDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/**
 * Not following our own typical Connection model for pagination as Dato use offset-based pagination, so we use a rough
 * template of offset-pagination here instead.
 */
export type DatoAppContentConnection = DatoConnection & {
  __typename?: 'DatoAppContentConnection';
  pageInfo: DatoPageInfo;
  nodes: Array<DatoAppContent>;
};

/**
 * Not following our own typical Connection model for pagination as Dato use offset-based pagination, so we use a rough
 * template of offset-pagination here instead.
 */
export type DatoStackCommentaryConnection = DatoConnection & {
  __typename?: 'DatoStackCommentaryConnection';
  pageInfo: DatoPageInfo;
  nodes: Array<StackCommentary>;
};

/** The model for the UpsideVoice snippets from Dato. */
export type DatoUpsideVoice = Node & {
  __typename?: 'DatoUpsideVoice';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  heroImage?: Maybe<DatoImageFile>;
  instruments: Array<Maybe<Instrument>>;
  ideas: Array<Maybe<Idea>>;
  publishDate?: Maybe<Scalars['DateTime']>;
};


/** The model for the UpsideVoice snippets from Dato. */
export type DatoUpsideVoiceHeroImageArgs = {
  imgixParams?: Maybe<ImgixInput>;
};

/** A selection of Dato file fields based on our needs, not an exhaustive list of the Dato file model */
export type DatoImageFile = {
  __typename?: 'DatoImageFile';
  url?: Maybe<Scalars['String']>;
};

export type DatoPageInfo = {
  __typename?: 'DatoPageInfo';
  /** Boolean to indicate whether there is a next page. */
  next?: Maybe<Scalars['Boolean']>;
  /** Boolean to indicate whether there is a previous page. */
  previous?: Maybe<Scalars['Boolean']>;
  /** Total count for all results in query. */
  totalCount?: Maybe<Scalars['Int']>;
};

export type DatoBlogsPayload = {
  __typename?: 'DatoBlogsPayload';
  connection: DatoBlogConnection;
};

export type DatoBlogPayload = {
  __typename?: 'DatoBlogPayload';
  blog?: Maybe<DatoBlog>;
};

export type DatoAppContentsPayload = {
  __typename?: 'DatoAppContentsPayload';
  connection: DatoAppContentConnection;
};

export type DatoAppContentPayload = {
  __typename?: 'DatoAppContentPayload';
  appContent?: Maybe<DatoAppContent>;
};

/** Enum used for the @cacheControl directive. */
export enum CacheControlScope {
  /** Cache value is for all users. */
  Public = 'PUBLIC',
  /** Cached value is for a specific user. */
  Private = 'PRIVATE'
}

/** For discover items */
export enum DiscoverCategoryType {
  MostWatchlisted = 'MOST_WATCHLISTED',
  StrongestBuy = 'STRONGEST_BUY',
  StrongestSell = 'STRONGEST_SELL',
  Curated = 'CURATED',
  Curations = 'CURATIONS',
  InstrumentRecommendation = 'INSTRUMENT_RECOMMENDATION',
  Sector = 'SECTOR',
  Sectors = 'SECTORS',
  SignificantMovers = 'SIGNIFICANT_MOVERS'
}

export type DiscoverCategoryInput = {
  /** The category type you wish to receive. */
  categoryType: DiscoverCategoryType;
  /** id must be supplied if categoryType is one of {CURATED, SECTOR} */
  id?: Maybe<Scalars['ID']>;
};

export type DiscoverCategory = Node & {
  __typename?: 'DiscoverCategory';
  id: Scalars['ID'];
  itemType?: Maybe<FeedItemType>;
  categoryType?: Maybe<DiscoverCategoryType>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** Expected to be a Dato imigix url. You can resize the image using the appropriate query params. */
  imageUrl?: Maybe<Scalars['String']>;
  items: Array<FeedItem>;
};


export type DiscoverCategoryItemsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export enum FeedItemType {
  Correlation = 'CORRELATION',
  Cross = 'CROSS',
  CurationsCategory = 'CURATIONS_CATEGORY',
  DiscoverCategory = 'DISCOVER_CATEGORY',
  MostWatchlisted = 'MOST_WATCHLISTED',
  News = 'NEWS',
  NewComment = 'NEW_COMMENT',
  NewIdea = 'NEW_IDEA',
  RelativeStrengthIndex = 'RELATIVE_STRENGTH_INDEX',
  ReportRating = 'REPORT_RATING',
  SectorDiscoverCategory = 'SECTOR_DISCOVER_CATEGORY',
  SignificantDailyMover = 'SIGNIFICANT_DAILY_MOVER',
  NetShortDisclosure = 'NET_SHORT_DISCLOSURE',
  Macd = 'MACD',
  UpsideVoice = 'UPSIDE_VOICE'
}

/** The order of this dictates the order the pills show on the Feed! */
export enum FilterCategory {
  News = 'NEWS',
  Voice = 'VOICE',
  Ideas = 'IDEAS',
  Technical = 'TECHNICAL',
  Alerts = 'ALERTS',
  Rating = 'RATING'
}

export enum SortMethod {
  Default = 'DEFAULT',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC'
}

export enum ReportRating {
  Buy = 'BUY',
  Sell = 'SELL',
  Hold = 'HOLD'
}

/** For Cross Technical Indicator Card (ie is it a Golden or Death cross) */
export enum CrossDirection {
  Golden = 'GOLDEN',
  Death = 'DEATH'
}

/** For MACD Technical Indicator Card - which type of signal it is */
export enum MacdSignalType {
  SignalCross = 'SIGNAL_CROSS',
  ZeroCross = 'ZERO_CROSS',
  EarlyIndicator = 'EARLY_INDICATOR'
}

/** For MACD Technical Indicator Card - which direction is it Long/Short */
export enum MacdDirection {
  Long = 'LONG',
  Short = 'SHORT'
}

/** Request attributes to obtain a feed list. */
export type FeedInput = {
  /** A list of the feed types you are able to render. */
  feedTypes?: Maybe<Array<FeedItemType>>;
  /** The max limit for the number of feed items to receive. */
  limit?: Maybe<Scalars['Int']>;
  /** The id of a user for whom you would like to receive a feed for. */
  userId?: Maybe<Scalars['Int']>;
  /** A method of sorting your feed. */
  sortMethod?: Maybe<SortMethod>;
  /**
   * Used to filter your feed to feed types that fall within the provided categories.
   * This or the feedTypes parameter must be provided or else you will receive an empty feed.
   */
  filterCategories?: Maybe<Array<FilterCategory>>;
};

export type InstrumentFeedInput = {
  /** A list of instrument ids to provide feed items for. */
  instruments: Array<Scalars['ID']>;
  /** A list of the feed types you are able to render. */
  feedTypes?: Maybe<Array<FeedItemType>>;
  /** A method of sorting your feed. */
  sortMethod?: Maybe<SortMethod>;
  /** Used to filter your feed to feed types that fall within the provided categories. */
  filterCategories?: Maybe<Array<FilterCategory>>;
};

export type SavedFeedItemsInput = {
  instruments?: Maybe<Array<Scalars['String']>>;
};

/** Inputs to save a feed item. */
export type SaveFeedItemInput = {
  /** The feed item ID. */
  feedItemID: Scalars['ID'];
};

/** Inputs to unsave a feed item. Either the original or the saved feed item ID should be provided. */
export type UnsaveFeedItemInput = {
  /** The saved feed item ID. */
  savedFeedItemId: Scalars['ID'];
};

/**
 * Union type of all possible feed item types
 * The GraphQL union type FeedItem is always the same as the enum FeedType, but ending in "FeedItem".
 */
export type FeedItem = ReportRatingFeedItem | CrossFeedItem | RelativeStrengthIndexFeedItem | NewIdeaFeedItem | CorrelationFeedItem | MostWatchlistedFeedItem | NewsFeedItem | SignificantDailyMoverFeedItem | DiscoverCategoryFeedItem | SectorDiscoverCategoryFeedItem | NetShortDisclosureFeedItem | MacdFeedItem | NewCommentFeedItem | CurationsCategoryFeedItem | UpsideVoiceFeedItem;

export type UpsideVoiceFeedItem = Node & FeedItemNode & {
  __typename?: 'UpsideVoiceFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  voice?: Maybe<DatoUpsideVoice>;
};

export type MacdFeedItem = Node & FeedItemNode & {
  __typename?: 'MacdFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  instrument?: Maybe<Instrument>;
  signalType?: Maybe<MacdSignalType>;
  direction?: Maybe<MacdDirection>;
  macd?: Maybe<Scalars['Float']>;
  signal?: Maybe<Scalars['Float']>;
};

export type NetShortDisclosureFeedItem = Node & FeedItemNode & {
  __typename?: 'NetShortDisclosureFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  instrument?: Maybe<Instrument>;
  totalReportedPosition?: Maybe<Scalars['Float']>;
  reportDate?: Maybe<Scalars['DateTime']>;
  positions?: Maybe<Array<Maybe<ShortPosition>>>;
};

export type ShortPosition = {
  __typename?: 'ShortPosition';
  holder?: Maybe<Scalars['String']>;
  netPosition?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['DateTime']>;
};

export type SignificantDailyMoverFeedItem = Node & FeedItemNode & {
  __typename?: 'SignificantDailyMoverFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  instrument?: Maybe<Instrument>;
  date?: Maybe<Scalars['DateTime']>;
  meanReturn?: Maybe<Scalars['Float']>;
  standardDeviation?: Maybe<Scalars['Float']>;
  latestReturn?: Maybe<Scalars['Float']>;
  zScore?: Maybe<Scalars['Float']>;
  normalDistribution?: Maybe<Scalars['Float']>;
  significantByOneSigma?: Maybe<Scalars['Boolean']>;
  significantByTwoSigma?: Maybe<Scalars['Boolean']>;
  significantByThreeSigma?: Maybe<Scalars['Boolean']>;
};

export type MostWatchlistedFeedItem = Node & FeedItemNode & {
  __typename?: 'MostWatchlistedFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  instrument?: Maybe<Instrument>;
};

export type ReportRatingFeedItem = Node & FeedItemNode & {
  __typename?: 'ReportRatingFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  buyCount?: Maybe<Scalars['Int']>;
  sellCount?: Maybe<Scalars['Int']>;
  holdCount?: Maybe<Scalars['Int']>;
  averageRating?: Maybe<ReportRating>;
  latestRating?: Maybe<ReportRating>;
  latestPublishDate?: Maybe<Scalars['DateTime']>;
  instrument?: Maybe<Instrument>;
};

/** Cross Technical Indicator (aka Death Cross or Golden Cross event) */
export type CrossFeedItem = Node & FeedItemNode & {
  __typename?: 'CrossFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  crossDirection?: Maybe<CrossDirection>;
  crossDate?: Maybe<Scalars['DateTime']>;
  optimalPricePctChange?: Maybe<Scalars['Float']>;
  optimalPricePeriodDays?: Maybe<Scalars['Int']>;
  instrument?: Maybe<Instrument>;
};

export type RelativeStrengthIndexFeedItem = Node & FeedItemNode & {
  __typename?: 'RelativeStrengthIndexFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['Float']>;
  isOverbought?: Maybe<Scalars['Boolean']>;
  eventDate?: Maybe<Scalars['DateTime']>;
  rsiStartDate?: Maybe<Scalars['DateTime']>;
  instrument?: Maybe<Instrument>;
};

export type NewIdeaFeedItem = Node & FeedItemNode & {
  __typename?: 'NewIdeaFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  idea?: Maybe<Idea>;
};

export type CorrelationFeedItem = Node & FeedItemNode & {
  __typename?: 'CorrelationFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  subjectInstrument?: Maybe<Instrument>;
  objectInstrument?: Maybe<Instrument>;
  correlation?: Maybe<Scalars['Float']>;
  windowSizeDays?: Maybe<Scalars['Int']>;
};

export type NewsFeedItem = Node & FeedItemNode & {
  __typename?: 'NewsFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  news?: Maybe<News>;
};

export type DiscoverCategoryFeedItem = Node & FeedItemNode & {
  __typename?: 'DiscoverCategoryFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  instrument?: Maybe<Instrument>;
};

export type CurationsCategoryFeedItem = Node & FeedItemNode & {
  __typename?: 'CurationsCategoryFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  curationId: Scalars['String'];
  name: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
};

export type SectorDiscoverCategoryFeedItem = Node & FeedItemNode & {
  __typename?: 'SectorDiscoverCategoryFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  sector?: Maybe<Sector>;
};

export type NewCommentFeedItem = Node & FeedItemNode & {
  __typename?: 'NewCommentFeedItem';
  id: Scalars['ID'];
  type: FeedItemType;
  created: Scalars['DateTime'];
  savedFeedItemId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Comment>;
  idea?: Maybe<Idea>;
};

export type InstrumentFeedPayload = {
  __typename?: 'InstrumentFeedPayload';
  feed: Array<FeedItem>;
};

export type JourneySummary = {
  __typename?: 'JourneySummary';
  instrumentWatchlist?: Maybe<InstrumentWatchlistJourneySummary>;
  idea?: Maybe<IdeaJourneySummary>;
  blueprint?: Maybe<BlueprintJourneySummary>;
};

export type InstrumentWatchlistJourneySummary = {
  __typename?: 'InstrumentWatchlistJourneySummary';
  hasWatchlisted?: Maybe<Scalars['Boolean']>;
  watchlistAdditionsBeforeComplete?: Maybe<Scalars['Int']>;
};

export type IdeaJourneySummary = {
  __typename?: 'IdeaJourneySummary';
  hasCreatedIdea?: Maybe<Scalars['Boolean']>;
  hasCreatedBullishIdea?: Maybe<Scalars['Boolean']>;
  hasCreatedBearishIdea?: Maybe<Scalars['Boolean']>;
  ideaCreationsBeforeComplete?: Maybe<Scalars['Int']>;
};

export type BlueprintJourneySummary = {
  __typename?: 'BlueprintJourneySummary';
  hasStartedBlueprint?: Maybe<Scalars['Boolean']>;
  hasFinishedBlueprint?: Maybe<Scalars['Boolean']>;
  daysStale?: Maybe<Scalars['Int']>;
};

export type JourneySummaryPayload = {
  __typename?: 'JourneySummaryPayload';
  summary?: Maybe<JourneySummary>;
};

export enum IdeaStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Evaluated = 'EVALUATED',
  Expired = 'EXPIRED'
}

export enum PriceMovement {
  Up = 'UP',
  Down = 'DOWN'
}

export enum Position {
  Long = 'LONG',
  Short = 'SHORT'
}

export enum IdeaPerformanceBand {
  Miss = 'MISS',
  Good = 'GOOD',
  Great = 'GREAT',
  Excellent = 'EXCELLENT'
}

export type CloseIdeaError = IdeaAlreadyClosed;

export type IdeaAlreadyClosed = ValidationError & {
  __typename?: 'IdeaAlreadyClosed';
  message: Scalars['String'];
};

export type IdeasInput = {
  instrument?: Maybe<Scalars['ID']>;
  statuses?: Maybe<Array<IdeaStatus>>;
};

export type IdeasFilter = {
  /** Deprecated - use instrumentFields and pass id in there */
  instrument?: Maybe<Scalars['ID']>;
  instrumentFields?: Maybe<InstrumentFilter>;
  statuses?: Maybe<Array<IdeaStatus>>;
};

export type PublicIdeasFilter = {
  userId?: Maybe<Scalars['Int']>;
  /** Deprecated - use instrumentFields and pass id in there */
  instrument?: Maybe<Scalars['ID']>;
  instrumentFields?: Maybe<InstrumentFilter>;
  statuses?: Maybe<Array<IdeaStatus>>;
};

/** Request attributes to add an idea to the watchlist. */
export type AddIdeaToWatchlistInput = {
  /** The idea ID to add to the watchlist. */
  ideaId: Scalars['ID'];
};

/** Request attributes to remove an idea from the watchlist. */
export type RemoveIdeaFromWatchlistInput = {
  /** The watchlist entity ID. */
  id: Scalars['ID'];
};

/** Request attributes to create a new idea. */
export type CreateIdeaInput = {
  /** The instrument this idea is for. */
  instrumentId: Scalars['ID'];
  /** The position of the idea. */
  position: Position;
  /** The status of the idea. */
  status: IdeaStatus;
  /** The conviction of the idea. */
  conviction: Scalars['Int'];
  /** The reference price of the idea. */
  referencePrice: Scalars['Float'];
  /** The reference date of the idea. */
  referenceDate: Scalars['Date'];
  /** The target price of the idea. */
  targetPrice: Scalars['Float'];
  /** The target date of the idea. */
  targetDate: Scalars['Date'];
  /** The headline of the idea. */
  headline?: Maybe<Scalars['String']>;
  /** The description of the idea, as markdown. */
  description?: Maybe<Scalars['JSONObject']>;
  /** The description of the idea, as text. */
  descriptionText?: Maybe<Scalars['String']>;
  /** Rationale tag ids to add to this idea. */
  rationaleTagIds: Array<Scalars['String']>;
};

/** Input to create a record of linking a saved feed item to your idea. */
export type CreateIdeaInsightInput = {
  ideaId: Scalars['ID'];
  clonedFeedItemId: Scalars['ID'];
};

/** The idea entity. */
export type Idea = Node & {
  __typename?: 'Idea';
  id: Scalars['ID'];
  /** The user who created this idea. */
  user?: Maybe<UserProfile>;
  /** The instrument this idea is for. */
  instrument?: Maybe<Instrument>;
  /** The position of the idea. */
  position?: Maybe<Position>;
  /** The status of the idea. */
  status?: Maybe<IdeaStatus>;
  /** The conviction of the idea. */
  conviction?: Maybe<Scalars['Int']>;
  /** The reference price of the idea. */
  referencePrice?: Maybe<Scalars['Float']>;
  /** The reference date of the idea. */
  referenceDate?: Maybe<Scalars['Date']>;
  /** The target price of the idea. */
  targetPrice?: Maybe<Scalars['Float']>;
  /** The target date of the idea. */
  targetDate?: Maybe<Scalars['Date']>;
  /** The headline of the idea. */
  headline?: Maybe<Scalars['String']>;
  /** The description of the idea, as markdown. */
  description?: Maybe<Scalars['JSONObject']>;
  /** The description of the idea, as text. */
  descriptionText?: Maybe<Scalars['String']>;
  /** Whether the idea is public or private. */
  isPublic?: Maybe<Scalars['Boolean']>;
  /** The rationale tags for this idea. */
  rationaleTags?: Maybe<Array<Maybe<IdeaRationaleTag>>>;
  /** The current performance for this idea. */
  performance?: Maybe<IdeaPerformance>;
  /** A list of feed items a user has saved/attached to their idea. */
  savedInsights?: Maybe<Array<FeedItem>>;
  topic?: Maybe<Topic>;
  /** The watchlist ID of the idea if in users watchlist. If returns null means idea not in users watchlist */
  watchlistId?: Maybe<Scalars['ID']>;
  /** The number of users tracking this idea. */
  trackCount?: Maybe<Scalars['Int']>;
  /** The price when the idea was closed. */
  priceWhenClosed?: Maybe<Scalars['Float']>;
  /** The time when the idea was closed. */
  closeTime?: Maybe<Scalars['DateTime']>;
  /** The outlook this idea belongs to. */
  outlook?: Maybe<Outlook>;
};


/** The idea entity. */
export type IdeaTopicArgs = {
  commentLimit?: Maybe<Scalars['Int']>;
  pagination?: Maybe<PageInput>;
};

export type IdeaConnection = Connection & {
  __typename?: 'IdeaConnection';
  pageInfo: PageInfo;
  nodes: Array<Idea>;
};

export type IdeaRationaleCategory = {
  __typename?: 'IdeaRationaleCategory';
  id: Scalars['ID'];
  /** The name of the rationale category */
  name?: Maybe<Scalars['NonEmptyString']>;
  /** Whether this rationale category is active. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The order priority of category, lower implies higher priority */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The rationale tags that belong to this category. */
  tags?: Maybe<Array<IdeaRationaleTag>>;
};

export type IdeaRationaleTag = {
  __typename?: 'IdeaRationaleTag';
  id: Scalars['ID'];
  /** The name of the rationale tag. */
  name?: Maybe<Scalars['NonEmptyString']>;
  /** Whether this rationale tag is active. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The order priority of tag within a category, lower implies higher priority */
  sortOrder?: Maybe<Scalars['Int']>;
};

export type IdeaPerformance = {
  __typename?: 'IdeaPerformance';
  id: Scalars['ID'];
  /** The expected return of the idea. This value is adjusted for idea position. */
  expectedReturn?: Maybe<Scalars['Float']>;
  /**
   * The actual return of the idea.
   *
   * This value is adjusted for idea position, and will use a live price if the the idea is still active, or otherwise the end price of the idea.
   */
  actualReturn?: Maybe<Scalars['Float']>;
  /**
   * The price change since the idea was posted.
   *
   * This value is not adjusted for idea position, and will use a live price if the idea is still active, or otherwise the end price of the idea.
   */
  priceChangeSincePosted?: Maybe<Scalars['Float']>;
  /** The expected price of the idea. */
  expectedPrice?: Maybe<Scalars['Float']>;
  /** The actual price of the idea. */
  actualPrice?: Maybe<Scalars['Float']>;
  /** The current price movement direction compared to the expected price. */
  expectedPriceMovement?: Maybe<PriceMovement>;
  /** The current price movement direction compared to the reference price. */
  actualPriceMovement?: Maybe<PriceMovement>;
  /** Whether the expected price movement is within the tolerance for this idea. */
  tolerantPriceMovement?: Maybe<Scalars['Boolean']>;
  priceAccuracyScore?: Maybe<Scalars['Float']>;
  timeAccuracyScore?: Maybe<Scalars['Float']>;
  forecastabilityScore?: Maybe<Scalars['Float']>;
  /** The performance band to indicate how well an idea is doing. */
  band?: Maybe<IdeaPerformanceBand>;
};

export type IdeaInsight = Node & {
  __typename?: 'IdeaInsight';
  id: Scalars['ID'];
  /** The id of the idea */
  ideaId: Scalars['ID'];
  /** The id of the cloned feed item */
  clonedFeedItemId: Scalars['ID'];
};

export type IdeaWatchlistItem = Node & {
  __typename?: 'IdeaWatchlistItem';
  id: Scalars['ID'];
  /** The idea that is watched. */
  idea?: Maybe<Idea>;
};

export type Outlook = Node & {
  __typename?: 'Outlook';
  id: Scalars['ID'];
  user?: Maybe<UserProfile>;
  instrument?: Maybe<Instrument>;
  ideas: Array<Idea>;
};

export type IdeasForPortfolioPayload = {
  __typename?: 'IdeasForPortfolioPayload';
  connection?: Maybe<IdeaConnection>;
};

export type CloseIdeaPayload = {
  __typename?: 'CloseIdeaPayload';
  idea?: Maybe<Idea>;
  error?: Maybe<CloseIdeaError>;
};

export type Industry = Node & {
  __typename?: 'Industry';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type ReportRatingInsight = {
  __typename?: 'ReportRatingInsight';
  id: Scalars['ID'];
  buyCount?: Maybe<Scalars['Int']>;
  sellCount?: Maybe<Scalars['Int']>;
  holdCount?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  latestRating?: Maybe<ReportRating>;
  modeRating?: Maybe<ReportRating>;
  latestPublishDate?: Maybe<Scalars['DateTime']>;
};

export enum InstrumentType {
  Equity = 'EQUITY',
  Index = 'INDEX'
}

export enum EsgGrade {
  A = 'A',
  Aa = 'AA',
  Aaa = 'AAA',
  B = 'B',
  Bb = 'BB',
  Bbb = 'BBB',
  C = 'C',
  Cc = 'CC',
  Ccc = 'CCC',
  D = 'D',
  Dd = 'DD',
  Ddd = 'DDD'
}

export enum EsgLevel {
  Low = 'LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Excellent = 'EXCELLENT'
}

/** Request attributes to add an instrument to the watchlist. */
export type AddInstrumentToWatchlistInput = {
  /** The instrument ID to add to the watchlist. */
  instrumentId: Scalars['ID'];
};

/** Request attributes to add multiple instruments to the watchlist. */
export type AddInstrumentsToWatchlistInput = {
  /** The instrument ID to add to the watchlist. */
  instrumentIds?: Maybe<Array<Scalars['ID']>>;
};

/** Request attributes to remove an instrument from the watchlist. */
export type RemoveInstrumentFromWatchlistInput = {
  /** The watchlist entity ID. */
  id: Scalars['ID'];
};

export type InstrumentFilter = {
  id?: Maybe<Scalars['ID']>;
  sector?: Maybe<SectorFilter>;
  country?: Maybe<CountryFilter>;
};

/** The instrument entity. */
export type Instrument = Node & {
  __typename?: 'Instrument';
  id: Scalars['ID'];
  /** The BBG ticker. */
  ticker?: Maybe<Scalars['String']>;
  /** The name of the instrument. */
  name?: Maybe<Scalars['String']>;
  /**
   * A name for the instrument that may be better to use on the FE depending on the screen. This field may be a more common
   * version of the instrument name or even an abbreviated version.
   */
  displayName?: Maybe<Scalars['String']>;
  /** Instrument description. */
  companyInfo?: Maybe<Scalars['String']>;
  /** The logo URL of the instrument. */
  logoUrl?: Maybe<Scalars['String']>;
  /** The latest close price. */
  closePrice?: Maybe<ClosePrice>;
  /** Close price series. */
  closePriceSeries?: Maybe<Array<Maybe<ClosePrice>>>;
  /** The live quote price. */
  quotePrice?: Maybe<QuotePrice>;
  /** The type of the instrument. */
  type?: Maybe<InstrumentType>;
  /** The ISIN of the instrument. */
  isin?: Maybe<Scalars['String']>;
  /** The currency of the instrument. */
  currency?: Maybe<Currency>;
  /** Whether the instrument is active or not. */
  isActive?: Maybe<Scalars['Boolean']>;
  industry?: Maybe<Industry>;
  sector?: Maybe<Sector>;
  countryOfListing?: Maybe<Country>;
  /** The watchlist ID of the instrument if in users watchlist. If returns null means instrument not in users watchlist */
  watchlistId?: Maybe<Scalars['ID']>;
  currentFinancials?: Maybe<CurrentFinancials>;
  yearlyFinancials?: Maybe<Array<Maybe<Financials>>>;
  quarterlyFinancials?: Maybe<Array<Maybe<Financials>>>;
  esgCurrent?: Maybe<Esg>;
  esgHistorical?: Maybe<Array<Maybe<Esg>>>;
  /** The current stats of report ratings for said instrument */
  reportRatingCurrent?: Maybe<ReportRatingInsight>;
  /** A complete history of how report ratings have changed, including the current report stats, ordered by most recent first */
  reportRatingHistory?: Maybe<Array<Maybe<ReportRatingInsight>>>;
};


/** The instrument entity. */
export type InstrumentClosePriceSeriesArgs = {
  dateFrom?: Maybe<Scalars['Date']>;
  dateTo?: Maybe<Scalars['Date']>;
};

export type InstrumentConnection = Connection & {
  __typename?: 'InstrumentConnection';
  pageInfo: PageInfo;
  nodes: Array<Instrument>;
};

export type Esg = {
  __typename?: 'ESG';
  /** The letter grade based on the environment score */
  environmentGrade?: Maybe<EsgGrade>;
  /** The aggregated level based on the environment score */
  environmentLevel?: Maybe<EsgLevel>;
  /** The score related to a company's behaviour concerning environmental issues */
  environmentScore?: Maybe<Scalars['Int']>;
  /** The letter grade based on the social score */
  socialGrade?: Maybe<EsgGrade>;
  /** The aggregated level based on the social score */
  socialLevel?: Maybe<EsgLevel>;
  /** The score related to a company's behaviour concerning social issues */
  socialScore?: Maybe<Scalars['Int']>;
  /** The letter grade based on the governance score */
  governanceGrade?: Maybe<EsgGrade>;
  /** The aggregated level based on the governance score */
  governanceLevel?: Maybe<EsgLevel>;
  /** The score related to a company's behaviour concerning governance issues */
  governanceScore?: Maybe<Scalars['Int']>;
  /** The letter grade based on the total score */
  totalGrade?: Maybe<EsgGrade>;
  /** The aggregated level based on the total score */
  totalLevel?: Maybe<EsgLevel>;
  /** The total of the environmental, social and governance scores */
  totalScore?: Maybe<Scalars['Int']>;
  /** The date when these scores were published */
  publishDate?: Maybe<Scalars['Date']>;
};

export type CurrentFinancials = {
  __typename?: 'CurrentFinancials';
  /** The average number of shares bought and sold. */
  averageVolume?: Maybe<Scalars['Float']>;
  /** The lowest value traded at for the day. */
  dayLow?: Maybe<Scalars['Float']>;
  /** The highest value traded at for the day. */
  dayHigh?: Maybe<Scalars['Float']>;
  /** The dividend yield, expressed as a percentage of the current share price. */
  dividendYield?: Maybe<Scalars['Float']>;
  /** The earnings per share over the the last twelve months. */
  eps?: Maybe<Scalars['Float']>;
  /** The lowest value traded at over the last 52 weeks. */
  fiftyTwoWeekLow?: Maybe<Scalars['Float']>;
  /** The highest value traded at over the last 52 weeks. */
  fiftyTwoWeekHigh?: Maybe<Scalars['Float']>;
  /** The market cap, expressed in the listed currency. */
  marketCap?: Maybe<Scalars['Float']>;
  /** The price on market open. */
  marketOpen?: Maybe<Scalars['Float']>;
  /** The earliest estimated date for the next earnings date. */
  nextEarningsDateStart?: Maybe<Scalars['Date']>;
  /** The latest estimated date for the next earnings date. */
  nextEarningsDateEnd?: Maybe<Scalars['Date']>;
  /** The price to earnings ratio. */
  peRatio?: Maybe<Scalars['Float']>;
  /** The price/earnings to growth ratio. */
  pegRatio?: Maybe<Scalars['Float']>;
  /** The primary exchange for the instrument. */
  primaryExchange?: Maybe<Scalars['String']>;
};

export type Financials = {
  __typename?: 'Financials';
  /** The date on which the financials were reported */
  date?: Maybe<Scalars['Date']>;
  /** The total revenue expressed in thousands of the instrument financial currency */
  totalRevenue?: Maybe<Scalars['Float']>;
  /** The cost of revenue expressed in thousands of the instrument financial currency */
  costOfRevenue?: Maybe<Scalars['Float']>;
  /** The gross profit expressed in thousands of the instrument financial currency */
  grossProfit?: Maybe<Scalars['Float']>;
  /** The operating expenses expressed in thousands of the instrument financial currency */
  operatingExpenses?: Maybe<Scalars['Float']>;
  /** The operating income expressed in thousands of the instrument financial currency */
  operatingIncome?: Maybe<Scalars['Float']>;
  /** The non-opeating income expressed in thousands of the instrument financial currency */
  nonOperatingIncome?: Maybe<Scalars['Float']>;
  /** The pre-tax income expressed in thousands of the instrument financial currency */
  preTaxIncome?: Maybe<Scalars['Float']>;
  /** The tax (and other deductions) expressed in thousands of the instrument financial currency */
  taxAndOtherDeductions?: Maybe<Scalars['Float']>;
  /** The net income expressed in thousands of the instrument financial currency */
  netIncome?: Maybe<Scalars['Float']>;
  /** The income generated as a percentage of the revenue */
  netMargin?: Maybe<Scalars['Float']>;
};

/** The watchlist item for an instrument. */
export type InstrumentWatchlistItem = Node & {
  __typename?: 'InstrumentWatchlistItem';
  id: Scalars['ID'];
  /** The instrument that is watched. */
  instrument?: Maybe<Instrument>;
};

export type MyMarketDataPayload = {
  __typename?: 'MyMarketDataPayload';
  instruments: Array<Instrument>;
};

export type AddInstrumentsToWatchlistPayload = {
  __typename?: 'AddInstrumentsToWatchlistPayload';
  addedWatchlistItems?: Maybe<Array<InstrumentWatchlistItem>>;
  instrumentsFailedToAdd: Array<Instrument>;
  allSuccessfullyAdded: Scalars['Boolean'];
};

export type News = Node & {
  __typename?: 'News';
  id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  storyText?: Maybe<Scalars['String']>;
  subjects?: Maybe<Array<Maybe<Scalars['String']>>>;
  publishDate?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Scalars['String']>;
  fullTextUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  sentiment?: Maybe<Scalars['String']>;
  instruments: Array<Maybe<Instrument>>;
};

/**
 * context is JSON string to parse into an object by the client.
 * Same as `data.context` as passed in an Expo Push Notification
 *
 * Guaranteed a `notificationType` (eg "NewComment" or "Reply" ) field in it.
 * Switch on notificationType to decide action of the notification (probably to navigate somewhere based on other ID fields in context eg ideaId)
 */
export type Notification = Node & {
  __typename?: 'Notification';
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  context?: Maybe<Scalars['String']>;
};

export type NotificationConnection = Connection & {
  __typename?: 'NotificationConnection';
  pageInfo: PageInfo;
  nodes: Array<Notification>;
};

export type NotificationPayload = {
  __typename?: 'NotificationPayload';
  connection?: Maybe<NotificationConnection>;
};

export type ExpoPushTokenPayload = {
  __typename?: 'ExpoPushTokenPayload';
  success: Scalars['Boolean'];
};

export type OnboardingPortfolioInput = {
  baseLayer?: Maybe<OnboardingPortfolioLayer>;
  stackLayer?: Maybe<OnboardingPortfolioLayer>;
  stockLayer?: Maybe<OnboardingPortfolioLayer>;
};

export type OnboardingPortfolioLayer = {
  weight: Scalars['Float'];
  constituents: Array<OnboardingPortfolioConstituent>;
};

export type OnboardingPortfolioConstituent = {
  id: Scalars['ID'];
  name: Scalars['NonEmptyString'];
};

/**
 * Merge Vars for Mandrill (Mailchimp) Email Template "onboarding-portfolio-insights"
 * Keys in ALL__CAPS to make easy copy/paste from Mailchimp Merge Template eg *|PEFORMANCE|*
 *
 * Fill in in client before mutation call.
 * TODO: Don't need this input var. Instead push calcs and look through to graphql layer not client and just need portfolio input to mutation.
 * ie graphql can make grpc/rest calls to loads stack contents, do look through and call calcs.
 */
export type OnboardingEmailTemplate = {
  PERFORMANCE: Scalars['String'];
  TOP_HOLDINGS_1: Scalars['String'];
  TOP_HOLDINGS_WEIGHT_1: Scalars['String'];
  TOP_HOLDINGS_2: Scalars['String'];
  TOP_HOLDINGS_WEIGHT_2: Scalars['String'];
  TOP_HOLDINGS_3: Scalars['String'];
  TOP_HOLDINGS_WEIGHT_3: Scalars['String'];
  TOP_HOLDINGS_4: Scalars['String'];
  TOP_HOLDINGS_WEIGHT_4: Scalars['String'];
  TOP_HOLDINGS_5: Scalars['String'];
  TOP_HOLDINGS_WEIGHT_5: Scalars['String'];
  VOLATILITY: Scalars['String'];
  TOP_RISK_1: Scalars['String'];
  TOP_RISK_WEIGHT_1: Scalars['String'];
  TOP_RISK_2: Scalars['String'];
  TOP_RISK_WEIGHT_2: Scalars['String'];
  TOP_RISK_3: Scalars['String'];
  TOP_RISK_WEIGHT_3: Scalars['String'];
  TOP_SECTOR_1: Scalars['String'];
  TOP_SECTOR_WEIGHT_1: Scalars['String'];
  TOP_SECTOR_2: Scalars['String'];
  TOP_SECTOR_WEIGHT_2: Scalars['String'];
  TOP_SECTOR_3: Scalars['String'];
  TOP_SECTOR_WEIGHT_3: Scalars['String'];
  COUNTRY_1: Scalars['String'];
  COUNTRY_WEIGHT_1: Scalars['String'];
  COUNTRY_2: Scalars['String'];
  COUNTRY_WEIGHT_2: Scalars['String'];
  COUNTRY_3: Scalars['String'];
  COUNTRY_WEIGHT_3: Scalars['String'];
  OVERALL_ESG_SCORE: Scalars['String'];
  ENVIRONMENT_ESG_SCORE: Scalars['String'];
  SOCIAL_ESG_SCORE: Scalars['String'];
  GOVERNANCE_ESG_SCORE: Scalars['String'];
};

export type RecordOnboardingPortfolioPayload = {
  __typename?: 'RecordOnboardingPortfolioPayload';
  error?: Maybe<ValidationError>;
};

export type PageInput = {
  cursor?: Maybe<Scalars['NonEmptyString']>;
  limit?: Maybe<Scalars['Int']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  nextCursor?: Maybe<Scalars['NonEmptyString']>;
  totalCount: Scalars['Int'];
};

export type PrePortfolioStatsError = PrePortfolioStatsInvalidDate | PrePortfolioStatsNoMemberWeight | PrePortfolioStatsNoMembers;

export type CreatePrePortfolioSimulationsError = PrePortfolioSimulationNoMembers;

export type UpdatePrePortfolioError = PrePortfolioImmutable | PrePortfolioMissingField;

/** Error returned when the given start/end dates are invalid. */
export type PrePortfolioStatsInvalidDate = ValidationError & {
  __typename?: 'PrePortfolioStatsInvalidDate';
  message: Scalars['String'];
};

/** Error returned when a pre-portfolio member doesn't have a weight set. */
export type PrePortfolioStatsNoMemberWeight = ValidationError & {
  __typename?: 'PrePortfolioStatsNoMemberWeight';
  message: Scalars['String'];
};

/** Error returned when a pre-portfolio doesn't contain any members. */
export type PrePortfolioStatsNoMembers = ValidationError & {
  __typename?: 'PrePortfolioStatsNoMembers';
  message: Scalars['String'];
};

/** Error returned when no members exist for the given pre-portfolio. */
export type PrePortfolioSimulationNoMembers = ValidationError & {
  __typename?: 'PrePortfolioSimulationNoMembers';
  message: Scalars['String'];
};

export type PrePortfolioImmutable = ValidationError & {
  __typename?: 'PrePortfolioImmutable';
  message: Scalars['String'];
};

export type PrePortfolioMissingField = ValidationError & {
  __typename?: 'PrePortfolioMissingField';
  message: Scalars['String'];
  missingField: Scalars['String'];
};

export type UpdatePrePortfolioMemberInput = {
  /** The id of the instrument within the PrePortfolio that needs updating. */
  instrumentId: Scalars['ID'];
  /** The weight to be set or reset for the provided instrument; should be a decimal between 0-1. */
  weight?: Maybe<Scalars['Float']>;
};

export type ComponentInput = {
  instrument: Scalars['ID'];
  weight: Scalars['Float'];
};

export type PrePortfolio = Node & {
  __typename?: 'PrePortfolio';
  id: Scalars['ID'];
  currency?: Maybe<Currency>;
  notional?: Maybe<Scalars['Float']>;
  cashProportion?: Maybe<Scalars['Float']>;
  selectedRiskLevel?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<PrePortfolioMember>>;
  stats: PrePortfolioStatsPayload;
  lastBuiltDate?: Maybe<Scalars['Date']>;
};


export type PrePortfolioStatsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type PrePortfolioMember = Node & {
  __typename?: 'PrePortfolioMember';
  id: Scalars['ID'];
  instrument?: Maybe<Instrument>;
  weight?: Maybe<Scalars['Float']>;
};

export type PrePortfolioSimulation = Node & {
  __typename?: 'PrePortfolioSimulation';
  id: Scalars['ID'];
  riskLevel?: Maybe<Scalars['Int']>;
  members: Array<PrePortfolioSimulationMember>;
  stats: PrePortfolioStatsPayload;
};


export type PrePortfolioSimulationStatsArgs = {
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

export type PrePortfolioSimulationMember = Node & {
  __typename?: 'PrePortfolioSimulationMember';
  id: Scalars['ID'];
  instrument?: Maybe<Instrument>;
  weight?: Maybe<Scalars['Float']>;
};

export type PrePortfolioStats = {
  __typename?: 'PrePortfolioStats';
  inceptionToDateReturn?: Maybe<Scalars['Float']>;
  annualisedReturn: Scalars['Float'];
  annualisedVolatility: Scalars['Float'];
  volatilities?: Maybe<Array<InstrumentVolatility>>;
  sharpeRatio: Scalars['Float'];
  maximumDrawdown: Scalars['Float'];
  correlation: Scalars['Float'];
  esg?: Maybe<Esg>;
  countryCashWeight: Scalars['Float'];
  countryWeights: Array<CountryWeight>;
  sectorCashWeight: Scalars['Float'];
  sectorWeights: Array<SectorWeight>;
  marketCapCashWeight: Scalars['Float'];
  marketCapWeights: Array<MarketCapWeight>;
  historicalPrices: Array<HistoricalPrice>;
};

export type InstrumentVolatility = {
  __typename?: 'InstrumentVolatility';
  instrument?: Maybe<Instrument>;
  volatility: Scalars['Float'];
};

export type CountryWeight = {
  __typename?: 'CountryWeight';
  country?: Maybe<Country>;
  weight?: Maybe<Scalars['Float']>;
};

export type SectorWeight = {
  __typename?: 'SectorWeight';
  sector?: Maybe<Sector>;
  weight?: Maybe<Scalars['Float']>;
};

export type MarketCapWeight = {
  __typename?: 'MarketCapWeight';
  marketCap?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
};

export type HistoricalPrice = {
  __typename?: 'HistoricalPrice';
  /** The historical price, in the currency of the pre-portfolio/simulation this was generated for. */
  price?: Maybe<Scalars['Float']>;
  /** The date of the historical price. */
  date?: Maybe<Scalars['Date']>;
};

export type CreatePrePortfolioSimulationsPayload = {
  __typename?: 'CreatePrePortfolioSimulationsPayload';
  error?: Maybe<CreatePrePortfolioSimulationsError>;
};

export type PrePortfolioSimulationPayload = {
  __typename?: 'PrePortfolioSimulationPayload';
  simulations: Array<PrePortfolioSimulation>;
};

export type PrePortfolioStatsPayload = {
  __typename?: 'PrePortfolioStatsPayload';
  data?: Maybe<PrePortfolioStats>;
  error?: Maybe<PrePortfolioStatsError>;
};

export type CreatePrePortfolioPayload = {
  __typename?: 'CreatePrePortfolioPayload';
  prePortfolio?: Maybe<PrePortfolio>;
};

export type UpdatePrePortfolioPayload = {
  __typename?: 'UpdatePrePortfolioPayload';
  prePortfolio?: Maybe<PrePortfolio>;
  error?: Maybe<UpdatePrePortfolioError>;
};

export type PrePortfolioPayload = {
  __typename?: 'PrePortfolioPayload';
  prePortfolio?: Maybe<PrePortfolio>;
};

export type PortfolioInsightsPayload = {
  __typename?: 'PortfolioInsightsPayload';
  insights?: Maybe<PrePortfolioStats>;
};

/** The quote price for a symbol. */
export type QuotePrice = Node & {
  __typename?: 'QuotePrice';
  id: Scalars['ID'];
  /** The symbol/ticker. */
  symbol: Scalars['NonEmptyString'];
  /** The ask price of the symbol. */
  askPrice?: Maybe<Scalars['Float']>;
  /** The ask size of the symbol. */
  askSize?: Maybe<Scalars['Int']>;
  /** The bid price of the symbol. */
  bidPrice?: Maybe<Scalars['Float']>;
  /** The bid size of the symbol. */
  bidSize?: Maybe<Scalars['Int']>;
  /** The average between the ask and bid price of the symbol. */
  midPrice?: Maybe<Scalars['Float']>;
  /**
   * The ISO-4217 currency code of the returned prices.
   * @deprecated
   * TODO Add instrument field.
   */
  currency?: Maybe<Scalars['String']>;
  /** The date and time when the price was last updated. */
  lastUpdated?: Maybe<Scalars['DateTime']>;
  /** The 1-day price movement percentage represented as a decimal value. */
  pctChange1d?: Maybe<Scalars['Float']>;
  /** The previous close price. */
  previousClose?: Maybe<Scalars['Float']>;
};

export type ClosePrice = Node & {
  __typename?: 'ClosePrice';
  id: Scalars['ID'];
  /** The close price. */
  price?: Maybe<Scalars['Float']>;
  /** The open price. */
  open?: Maybe<Scalars['Float']>;
  /** The highest price reached during the trading day. */
  high?: Maybe<Scalars['Float']>;
  /** The lowest price reached during the trading day. */
  low?: Maybe<Scalars['Float']>;
  /** The date of the trading day. */
  date?: Maybe<Scalars['Date']>;
};

export type ReferralStats = {
  __typename?: 'ReferralStats';
  user?: Maybe<UserProfile>;
  position: Scalars['Int'];
  referrals: Scalars['Int'];
};

export type SaveReferralPayload = {
  __typename?: 'SaveReferralPayload';
  success: Scalars['Boolean'];
};

export type MyReferralStatsPayload = {
  __typename?: 'MyReferralStatsPayload';
  stats?: Maybe<ReferralStats>;
};

export type ReferralStatsLeaderboardPayload = {
  __typename?: 'ReferralStatsLeaderboardPayload';
  stats: Array<ReferralStats>;
};








export type SearchPayload = {
  __typename?: 'SearchPayload';
  instruments?: Maybe<InstrumentConnection>;
  users?: Maybe<UserProfileConnection>;
  error?: Maybe<ValidationError>;
};

export type SectorFilter = {
  names?: Maybe<Array<Scalars['String']>>;
};

export type Sector = Node & {
  __typename?: 'Sector';
  id: Scalars['ID'];
  /** The name of the sector. */
  name?: Maybe<Scalars['String']>;
  /** The logo URL of the sector. */
  logoUrl?: Maybe<Scalars['String']>;
};

export type SectorsWithInstrumentIdeasPayload = {
  __typename?: 'SectorsWithInstrumentIdeasPayload';
  sectors?: Maybe<Array<Sector>>;
};

export type UpsideSharesSummary = {
  __typename?: 'UpsideSharesSummary';
  totalShares?: Maybe<Scalars['Int']>;
  totalValue?: Maybe<Scalars['Float']>;
  firstIssueDate?: Maybe<Scalars['Date']>;
};

export type MyUpsideSharesPayload = {
  __typename?: 'MyUpsideSharesPayload';
  summary?: Maybe<UpsideSharesSummary>;
};

export enum TradingIdentifierType {
  /** A national insurance number. */
  Nino = 'NINO',
  /** A general passport number, as an ISO3166 two-letter country code. */
  Gpn = 'GPN'
}

export enum TradingAccountType {
  /** A general investment account. */
  Gia = 'GIA'
}

export enum TradingPortfolioStatus {
  /** A very short lived state, before the portfolio has checked whether all the relevant checks for parties have passed, such as KYC. */
  Pending = 'PENDING',
  /** All checks have passed and the portfolio is available to be used. */
  Active = 'ACTIVE',
  /** One or more checks on the parties have failed and the portfolio is suspended. Once checks have been fixed the portfolio will move back to ACTIVE. */
  Suspended = 'SUSPENDED',
  /** Portfolio is in the process of closing but is not closed yet. This may be because there are still holdings currently in the process of selling down. */
  Closing = 'CLOSING',
  /** Portfolio is closed. This is the terminal state for a portfolio. */
  Closed = 'CLOSED'
}

export type TradingError = TradingValidationError;

export type TradingValidationError = ValidationError & {
  __typename?: 'TradingValidationError';
  message: Scalars['String'];
  /** A mapping of field name to the validation error for that field. */
  errors: Scalars['JSONObject'];
};

export type TradingPartyInput = {
  title?: Maybe<Scalars['String']>;
  forename: Scalars['String'];
  surname: Scalars['String'];
  telephoneNumber: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  taxResidencies?: Maybe<Array<Scalars['String']>>;
  nationalities?: Maybe<Array<Scalars['String']>>;
  identifiers?: Maybe<Array<TradingIdentifierInput>>;
};

export type TradingIdentifierInput = {
  type: TradingIdentifierType;
  value: Scalars['String'];
  issuer: Scalars['String'];
};

export type TradingAddressInput = {
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
};

export type TradingBankAccountInput = {
  name: Scalars['String'];
  accountNumber: Scalars['String'];
  sortCode: Scalars['String'];
  currency: Scalars['String'];
  countryCode: Scalars['String'];
};

export type InitialPortfolioIntentionInput = {
  stockLayer: InitialLayerIntentionInput;
  stackLayer: InitialLayerIntentionInput;
  baseLayer: InitialLayerIntentionInput;
};

export type InitialLayerIntentionInput = {
  weight: Scalars['Float'];
  constituentIds: Array<Scalars['ID']>;
};

export type TradingParty = {
  __typename?: 'TradingParty';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  forename: Scalars['String'];
  surname: Scalars['String'];
  telephoneNumber: Scalars['String'];
  dateOfBirth: Scalars['Date'];
  taxResidencies: Array<Scalars['String']>;
  nationalities: Array<Scalars['String']>;
  identifiers: Array<TradingIdentifier>;
  addresses: Array<TradingAddress>;
  bankAccounts: Array<TradingBankAccount>;
};

export type TradingIdentifier = {
  __typename?: 'TradingIdentifier';
  id: Scalars['ID'];
  type: TradingIdentifierType;
  value: Scalars['String'];
  issuer: Scalars['String'];
};

export type TradingAddress = {
  __typename?: 'TradingAddress';
  id: Scalars['ID'];
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  line3?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  postalCode: Scalars['String'];
};

export type TradingPortfolio = {
  __typename?: 'TradingPortfolio';
  id: Scalars['ID'];
  activeDepositExpectation?: Maybe<TradingDepositExpectation>;
  status?: Maybe<TradingPortfolioStatus>;
};

export type TradingBankAccount = {
  __typename?: 'TradingBankAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
  accountNumber: Scalars['String'];
  sortCode: Scalars['String'];
  currency: Scalars['String'];
  countryCode: Scalars['String'];
};

export type Stack = {
  __typename?: 'Stack';
  id: Scalars['ID'];
  components?: Maybe<ComponentConnection>;
  content?: Maybe<StackContent>;
  commentary?: Maybe<DatoStackCommentaryConnection>;
};


export type StackContentArgs = {
  markdown?: Maybe<Scalars['Boolean']>;
  imgixParams?: Maybe<ImgixInput>;
};


export type StackCommentaryArgs = {
  pagination?: Maybe<DatoPageInput>;
  orderBy?: Maybe<Array<DatoStackCommentaryOrderBy>>;
  markdown?: Maybe<Scalars['Boolean']>;
};

export type Component = Node & {
  __typename?: 'Component';
  id: Scalars['ID'];
  instrument?: Maybe<Instrument>;
  weight: Scalars['Float'];
};

export type ComponentConnection = Connection & {
  __typename?: 'ComponentConnection';
  pageInfo: PageInfo;
  nodes: Array<Component>;
};

export type StackContent = {
  __typename?: 'StackContent';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  coverImage?: Maybe<DatoImageFile>;
};

export type StackCommentary = Node & {
  __typename?: 'StackCommentary';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  excerpt?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['DateTime']>;
};

export type TradingDepositExpectation = {
  __typename?: 'TradingDepositExpectation';
  id: Scalars['ID'];
  amount: Scalars['String'];
  currency: Scalars['String'];
  reference: Scalars['String'];
  depositBankName: Scalars['String'];
  depositBankAccountNumber: Scalars['String'];
  depositBankSortCode: Scalars['String'];
};

export type UpsertTradingPartyPayload = {
  __typename?: 'UpsertTradingPartyPayload';
  error?: Maybe<TradingError>;
};

export type CreateTradingAddressPayload = {
  __typename?: 'CreateTradingAddressPayload';
  error?: Maybe<TradingError>;
};

export type CreateTradingBankAccountPayload = {
  __typename?: 'CreateTradingBankAccountPayload';
  error?: Maybe<TradingError>;
};

export type CreateTradingAccountAndPortfolioPayload = {
  __typename?: 'CreateTradingAccountAndPortfolioPayload';
  error?: Maybe<TradingError>;
};

export type MyTradingPartyPayload = {
  __typename?: 'MyTradingPartyPayload';
  party?: Maybe<TradingParty>;
};

export type StacksPayload = {
  __typename?: 'StacksPayload';
  stacks: Array<Maybe<Stack>>;
};

export type StackPayload = {
  __typename?: 'StackPayload';
  stack: Stack;
};

export type MyTradingPortfoliosPayload = {
  __typename?: 'MyTradingPortfoliosPayload';
  portfolios: Array<TradingPortfolio>;
};

export type InitialiseTradingPortfolioPayload = {
  __typename?: 'InitialiseTradingPortfolioPayload';
  portfolio?: Maybe<TradingPortfolio>;
  error?: Maybe<TradingError>;
};

export type CreateTradingDepositExpectationPayload = {
  __typename?: 'CreateTradingDepositExpectationPayload';
  depositExpectation?: Maybe<TradingDepositExpectation>;
  error?: Maybe<TradingError>;
};

export type CancelTradingDepositExpectationPayload = {
  __typename?: 'CancelTradingDepositExpectationPayload';
  error?: Maybe<TradingError>;
};

export type FileUploadError = EmptyFile | FileSizeInvalid | FileTypeInvalid;

export type EmptyFile = ValidationError & {
  __typename?: 'EmptyFile';
  message: Scalars['String'];
};

export type FileSizeInvalid = ValidationError & {
  __typename?: 'FileSizeInvalid';
  message: Scalars['String'];
};

export type FileTypeInvalid = ValidationError & {
  __typename?: 'FileTypeInvalid';
  message: Scalars['String'];
};

export type FileDeleteError = FileNotFound;

export type FileNotFound = ValidationError & {
  __typename?: 'FileNotFound';
  message: Scalars['String'];
};

/** Provide an instrumentIds OR ideaId to identify the instrument in question. */
export type InstrumentFindBy = {
  /** Resolve which instruments you are interested in by providing their ids. */
  instrumentIds?: Maybe<Array<Scalars['ID']>>;
  /** Resolve which instruments you are interested in by deriving them from ideas. */
  ideaIds?: Maybe<Array<Scalars['ID']>>;
};

export type UserIdeaFilters = {
  instrument?: Maybe<InstrumentFindBy>;
  statuses?: Maybe<Array<IdeaStatus>>;
};

export enum ProfileRating {
  Green = 'GREEN',
  Gold = 'GOLD'
}

/** A public profile for a user. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** The ID of the user. */
  id?: Maybe<Scalars['Int']>;
  /** The nickname for the user. */
  nickname?: Maybe<Scalars['String']>;
  /** The profile rating for the user. */
  profileRating?: Maybe<ProfileRating>;
  /** A user's ideas, filterable on a particular instruments and status of ideas. */
  ideas?: Maybe<UserProfileIdeasPayload>;
  /** An object to store all user media such as profile pictures. */
  media?: Maybe<UserMedia>;
};


/** A public profile for a user. */
export type UserProfileIdeasArgs = {
  filter?: Maybe<UserIdeaFilters>;
  pagination?: Maybe<PageInput>;
};

export type UserMedia = {
  __typename?: 'UserMedia';
  /** Uploading and re-uploading keeps id the same, deleting will produce a new id on upload. */
  id: Scalars['ID'];
  /** An S3 url of the profile image with dimensions equal to the min between 1000x1000 and the min(height, width) of uploaded image. */
  profileFull?: Maybe<Scalars['String']>;
  /** An S3 url of the profile image with dimensions equal to the min between 200x200 and the min(height, width) of uploaded image. */
  profileXl?: Maybe<Scalars['String']>;
  /** An S3 url of the profile image with dimensions equal to the min between 56x56 and the min(height, width) of uploaded image. */
  profileLg?: Maybe<Scalars['String']>;
};

export type UserProfileIdeasPayload = {
  __typename?: 'UserProfileIdeasPayload';
  connection?: Maybe<UserIdeaConnection>;
};

export type UserProfileConnection = {
  __typename?: 'UserProfileConnection';
  pageInfo: PageInfo;
  nodes: Array<UserProfile>;
};

/** The user entity. */
export type User = {
  __typename?: 'User';
  /** The ID of the user. */
  id?: Maybe<Scalars['Int']>;
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  /** Whether the user is a member of Upside staff. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /**
   * Whether the user has finished onboarding their trading accounts.
   *
   * Trading onboarding is finished when a user has a party, address, bank account, account and portfolio.
   */
  tradingOnboardingFinished: Scalars['Boolean'];
  /** The public user profile. */
  profile?: Maybe<UserProfile>;
};

export type UserIdeaConnection = Connection & {
  __typename?: 'UserIdeaConnection';
  pageInfo: PageInfo;
  nodes: Array<Idea>;
};

export type UpdateProfileImagePayload = {
  __typename?: 'UpdateProfileImagePayload';
  error?: Maybe<FileUploadError>;
  user?: Maybe<UserProfile>;
};

export type DeleteProfileImagePayload = {
  __typename?: 'DeleteProfileImagePayload';
  error?: Maybe<FileDeleteError>;
  user?: Maybe<UserProfile>;
};

export type MePayload = {
  __typename?: 'MePayload';
  user?: Maybe<User>;
};

export type CoreCommentFieldsFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'replyCount' | 'text' | 'status' | 'created'>
  & { user?: Maybe<(
    { __typename?: 'UserProfile' }
    & { ideas?: Maybe<(
      { __typename?: 'UserProfileIdeasPayload' }
      & { connection?: Maybe<(
        { __typename?: 'UserIdeaConnection' }
        & { nodes: Array<(
          { __typename?: 'Idea' }
          & Pick<Idea, 'id' | 'position'>
          & { instrument?: Maybe<(
            { __typename?: 'Instrument' }
            & Pick<Instrument, 'id' | 'ticker'>
          )> }
        )> }
      )> }
    )> }
    & CoreUserProfileFieldsFragment
  )> }
);

export type CoreCommentFieldsWithoutActiveIdeaFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'replyCount' | 'text' | 'status' | 'created'>
  & { user?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'nickname' | 'profileRating'>
  )> }
);

type FeedCardFields_ReportRatingFeedItem_Fragment = (
  { __typename?: 'ReportRatingFeedItem' }
  & Pick<ReportRatingFeedItem, 'id' | 'buyCount' | 'sellCount' | 'holdCount' | 'averageRating' | 'latestRating' | 'latestPublishDate'>
  & { instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_CrossFeedItem_Fragment = (
  { __typename?: 'CrossFeedItem' }
  & Pick<CrossFeedItem, 'id' | 'crossDate' | 'crossDirection'>
  & { instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_RelativeStrengthIndexFeedItem_Fragment = (
  { __typename?: 'RelativeStrengthIndexFeedItem' }
  & Pick<RelativeStrengthIndexFeedItem, 'id' | 'eventDate' | 'value' | 'rsiStartDate' | 'isOverbought'>
  & { instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_NewIdeaFeedItem_Fragment = (
  { __typename?: 'NewIdeaFeedItem' }
  & Pick<NewIdeaFeedItem, 'id' | 'created'>
  & { idea?: Maybe<(
    { __typename?: 'Idea' }
    & CoreIdeaFieldsFragment
  )> }
);

type FeedCardFields_CorrelationFeedItem_Fragment = (
  { __typename?: 'CorrelationFeedItem' }
  & Pick<CorrelationFeedItem, 'id' | 'created' | 'correlation' | 'windowSizeDays'>
  & { subjectInstrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )>, objectInstrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_MostWatchlistedFeedItem_Fragment = { __typename?: 'MostWatchlistedFeedItem' };

type FeedCardFields_NewsFeedItem_Fragment = (
  { __typename?: 'NewsFeedItem' }
  & Pick<NewsFeedItem, 'id'>
  & { news?: Maybe<(
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'storyText' | 'source' | 'publishDate' | 'imageUrl'>
    & { instruments: Array<Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )>> }
  )> }
);

type FeedCardFields_SignificantDailyMoverFeedItem_Fragment = (
  { __typename?: 'SignificantDailyMoverFeedItem' }
  & Pick<SignificantDailyMoverFeedItem, 'id' | 'date' | 'latestReturn' | 'zScore'>
  & { instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_DiscoverCategoryFeedItem_Fragment = { __typename?: 'DiscoverCategoryFeedItem' };

type FeedCardFields_SectorDiscoverCategoryFeedItem_Fragment = { __typename?: 'SectorDiscoverCategoryFeedItem' };

type FeedCardFields_NetShortDisclosureFeedItem_Fragment = (
  { __typename?: 'NetShortDisclosureFeedItem' }
  & Pick<NetShortDisclosureFeedItem, 'id' | 'totalReportedPosition' | 'reportDate'>
  & { positions?: Maybe<Array<Maybe<(
    { __typename?: 'ShortPosition' }
    & Pick<ShortPosition, 'holder' | 'netPosition' | 'date'>
  )>>>, instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_MacdFeedItem_Fragment = (
  { __typename?: 'MacdFeedItem' }
  & Pick<MacdFeedItem, 'id' | 'created' | 'direction'>
  & { instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & CoreInstrumentFieldsFragment
  )> }
);

type FeedCardFields_NewCommentFeedItem_Fragment = (
  { __typename?: 'NewCommentFeedItem' }
  & Pick<NewCommentFeedItem, 'id' | 'created'>
  & { comment?: Maybe<(
    { __typename?: 'Comment' }
    & CoreCommentFieldsWithoutActiveIdeaFragment
  )>, idea?: Maybe<(
    { __typename?: 'Idea' }
    & CoreIdeaFieldsFragment
  )> }
);

type FeedCardFields_CurationsCategoryFeedItem_Fragment = { __typename?: 'CurationsCategoryFeedItem' };

type FeedCardFields_UpsideVoiceFeedItem_Fragment = (
  { __typename?: 'UpsideVoiceFeedItem' }
  & Pick<UpsideVoiceFeedItem, 'id' | 'created'>
  & { voice?: Maybe<(
    { __typename?: 'DatoUpsideVoice' }
    & Pick<DatoUpsideVoice, 'id' | 'title' | 'excerpt' | 'body' | 'publishDate'>
    & { heroImage?: Maybe<(
      { __typename?: 'DatoImageFile' }
      & Pick<DatoImageFile, 'url'>
    )>, instruments: Array<Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )>>, ideas: Array<Maybe<(
      { __typename?: 'Idea' }
      & Pick<Idea, 'id'>
    )>> }
  )> }
);

export type FeedCardFieldsFragment = FeedCardFields_ReportRatingFeedItem_Fragment | FeedCardFields_CrossFeedItem_Fragment | FeedCardFields_RelativeStrengthIndexFeedItem_Fragment | FeedCardFields_NewIdeaFeedItem_Fragment | FeedCardFields_CorrelationFeedItem_Fragment | FeedCardFields_MostWatchlistedFeedItem_Fragment | FeedCardFields_NewsFeedItem_Fragment | FeedCardFields_SignificantDailyMoverFeedItem_Fragment | FeedCardFields_DiscoverCategoryFeedItem_Fragment | FeedCardFields_SectorDiscoverCategoryFeedItem_Fragment | FeedCardFields_NetShortDisclosureFeedItem_Fragment | FeedCardFields_MacdFeedItem_Fragment | FeedCardFields_NewCommentFeedItem_Fragment | FeedCardFields_CurationsCategoryFeedItem_Fragment | FeedCardFields_UpsideVoiceFeedItem_Fragment;

export type CoreIdeaFieldsFragment = (
  { __typename?: 'Idea' }
  & Pick<Idea, 'id' | 'targetDate' | 'targetPrice' | 'referenceDate' | 'referencePrice' | 'position' | 'conviction' | 'status' | 'closeTime'>
  & { rationaleTags?: Maybe<Array<Maybe<(
    { __typename?: 'IdeaRationaleTag' }
    & Pick<IdeaRationaleTag, 'id' | 'name' | 'sortOrder'>
  )>>>, instrument?: Maybe<(
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id' | 'name' | 'ticker' | 'logoUrl'>
    & { quotePrice?: Maybe<(
      { __typename?: 'QuotePrice' }
      & Pick<QuotePrice, 'id' | 'midPrice'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'iso'>
    )> }
  )>, user?: Maybe<(
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'id' | 'nickname' | 'profileRating'>
  )>, topic?: Maybe<(
    { __typename?: 'Topic' }
    & Pick<Topic, 'commentCount'>
  )>, performance?: Maybe<(
    { __typename?: 'IdeaPerformance' }
    & Pick<IdeaPerformance, 'id' | 'actualReturn' | 'actualPrice' | 'actualPriceMovement' | 'priceChangeSincePosted'>
  )> }
);

export type CoreInstrumentFieldsFragment = (
  { __typename?: 'Instrument' }
  & MakeOptional<Pick<Instrument, 'id' | 'name' | 'ticker' | 'logoUrl' | 'displayName' | 'watchlistId'>, 'watchlistId'>
  & { quotePrice?: Maybe<(
    { __typename?: 'QuotePrice' }
    & Pick<QuotePrice, 'id' | 'midPrice' | 'pctChange1d' | 'lastUpdated'>
  )>, closePrice?: Maybe<(
    { __typename?: 'ClosePrice' }
    & Pick<ClosePrice, 'id' | 'price'>
  )>, currency?: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'iso'>
  )> }
);

export type InstrumentStatisticsFragment = (
  { __typename?: 'Instrument' }
  & { currentFinancials?: Maybe<(
    { __typename?: 'CurrentFinancials' }
    & Pick<CurrentFinancials, 'averageVolume' | 'dayLow' | 'dayHigh' | 'dividendYield' | 'eps' | 'fiftyTwoWeekLow' | 'fiftyTwoWeekHigh' | 'marketCap' | 'marketOpen' | 'nextEarningsDateStart' | 'nextEarningsDateEnd' | 'peRatio' | 'pegRatio' | 'primaryExchange'>
  )> }
);

export type InstrumentFinancialsFragment = (
  { __typename: 'Financials' }
  & Pick<Financials, 'date' | 'totalRevenue' | 'costOfRevenue' | 'grossProfit' | 'operatingExpenses' | 'operatingIncome' | 'nonOperatingIncome' | 'preTaxIncome' | 'taxAndOtherDeductions' | 'netIncome' | 'netMargin'>
);

export type InstrumentEsgFragment = (
  { __typename?: 'ESG' }
  & Pick<Esg, 'environmentGrade' | 'environmentLevel' | 'environmentScore' | 'socialGrade' | 'socialLevel' | 'socialScore' | 'governanceGrade' | 'governanceLevel' | 'governanceScore' | 'totalGrade' | 'totalLevel' | 'totalScore' | 'publishDate'>
);

export type CorePaginationFieldsFragment = (
  { __typename?: 'PageInfo' }
  & Pick<PageInfo, 'nextCursor' | 'totalCount'>
);

export type PrePortfolioStatsFragment = (
  { __typename?: 'PrePortfolioStatsPayload' }
  & { error?: Maybe<(
    { __typename?: 'PrePortfolioStatsInvalidDate' }
    & Pick<PrePortfolioStatsInvalidDate, 'message'>
  ) | (
    { __typename?: 'PrePortfolioStatsNoMemberWeight' }
    & Pick<PrePortfolioStatsNoMemberWeight, 'message'>
  ) | (
    { __typename?: 'PrePortfolioStatsNoMembers' }
    & Pick<PrePortfolioStatsNoMembers, 'message'>
  )>, data?: Maybe<(
    { __typename?: 'PrePortfolioStats' }
    & Pick<PrePortfolioStats, 'annualisedReturn' | 'annualisedVolatility' | 'sharpeRatio' | 'maximumDrawdown' | 'correlation' | 'countryCashWeight' | 'sectorCashWeight' | 'marketCapCashWeight'>
    & { esg?: Maybe<(
      { __typename?: 'ESG' }
      & InstrumentEsgFragment
    )>, countryWeights: Array<(
      { __typename?: 'CountryWeight' }
      & Pick<CountryWeight, 'weight'>
      & { country?: Maybe<(
        { __typename?: 'Country' }
        & Pick<Country, 'id' | 'name'>
      )> }
    )>, sectorWeights: Array<(
      { __typename?: 'SectorWeight' }
      & Pick<SectorWeight, 'weight'>
      & { sector?: Maybe<(
        { __typename?: 'Sector' }
        & Pick<Sector, 'id' | 'name'>
      )> }
    )>, marketCapWeights: Array<(
      { __typename?: 'MarketCapWeight' }
      & Pick<MarketCapWeight, 'marketCap' | 'weight'>
    )>, historicalPrices: Array<(
      { __typename?: 'HistoricalPrice' }
      & Pick<HistoricalPrice, 'price' | 'date'>
    )> }
  )> }
);

export type CorePrePortfolioFieldsFragment = (
  { __typename?: 'PrePortfolio' }
  & Pick<PrePortfolio, 'id' | 'notional' | 'cashProportion' | 'selectedRiskLevel' | 'lastBuiltDate'>
  & { currency?: Maybe<(
    { __typename?: 'Currency' }
    & Pick<Currency, 'id' | 'iso'>
  )>, members?: Maybe<Array<(
    { __typename?: 'PrePortfolioMember' }
    & Pick<PrePortfolioMember, 'id' | 'weight'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & Pick<Instrument, 'id' | 'name' | 'ticker' | 'logoUrl'>
    )> }
  )>>, stats: (
    { __typename?: 'PrePortfolioStatsPayload' }
    & PrePortfolioStatsFragment
  ) }
);

export type CorePrePortfolioSimulationFieldsFragment = (
  { __typename?: 'PrePortfolioSimulation' }
  & Pick<PrePortfolioSimulation, 'id' | 'riskLevel'>
  & { members: Array<(
    { __typename?: 'PrePortfolioSimulationMember' }
    & Pick<PrePortfolioSimulationMember, 'id' | 'weight'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & Pick<Instrument, 'id' | 'name' | 'ticker' | 'logoUrl'>
    )> }
  )>, stats: (
    { __typename?: 'PrePortfolioStatsPayload' }
    & PrePortfolioStatsFragment
  ) }
);

export type StackFieldsFragment = (
  { __typename?: 'Stack' }
  & Pick<Stack, 'id'>
  & { content?: Maybe<(
    { __typename?: 'StackContent' }
    & Pick<StackContent, 'name' | 'description'>
    & { coverImage?: Maybe<(
      { __typename?: 'DatoImageFile' }
      & Pick<DatoImageFile, 'url'>
    )> }
  )>, components?: Maybe<(
    { __typename?: 'ComponentConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'totalCount'>
    ), nodes: Array<(
      { __typename?: 'Component' }
      & Pick<Component, 'id' | 'weight'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & Pick<Instrument, 'id' | 'ticker' | 'displayName' | 'logoUrl'>
      )> }
    )> }
  )> }
);

export type CoreUserProfileFieldsFragment = (
  { __typename?: 'UserProfile' }
  & Pick<UserProfile, 'id' | 'nickname' | 'profileRating'>
  & { media?: Maybe<(
    { __typename?: 'UserMedia' }
    & Pick<UserMedia, 'id' | 'profileLg' | 'profileFull' | 'profileXl'>
  )> }
);

export type CreateIdeaMutationVariables = Exact<{
  idea: CreateIdeaInput;
}>;


export type CreateIdeaMutation = (
  { __typename?: 'Mutation' }
  & { createIdea?: Maybe<(
    { __typename?: 'Idea' }
    & CoreIdeaFieldsFragment
  )> }
);

export type GetPrePortfolioQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrePortfolioQuery = (
  { __typename?: 'Query' }
  & { prePortfolio: (
    { __typename?: 'PrePortfolioPayload' }
    & { prePortfolio?: Maybe<(
      { __typename?: 'PrePortfolio' }
      & CorePrePortfolioFieldsFragment
    )> }
  ) }
);

export type CreatePrePortfolioMutationVariables = Exact<{
  referenceId?: Maybe<Scalars['ID']>;
}>;


export type CreatePrePortfolioMutation = (
  { __typename?: 'Mutation' }
  & { createPrePortfolio: (
    { __typename?: 'CreatePrePortfolioPayload' }
    & { prePortfolio?: Maybe<(
      { __typename?: 'PrePortfolio' }
      & CorePrePortfolioFieldsFragment
    )> }
  ) }
);

export type UpdatePrePortfolioMutationVariables = Exact<{
  prePortfolioId: Scalars['ID'];
  currencyId?: Maybe<Scalars['ID']>;
  notional?: Maybe<Scalars['Float']>;
  cashProportion?: Maybe<Scalars['Float']>;
  selectedRiskLevel?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<UpdatePrePortfolioMemberInput> | UpdatePrePortfolioMemberInput>;
  built?: Maybe<Scalars['Boolean']>;
}>;


export type UpdatePrePortfolioMutation = (
  { __typename?: 'Mutation' }
  & { updatePrePortfolio: (
    { __typename?: 'UpdatePrePortfolioPayload' }
    & { prePortfolio?: Maybe<(
      { __typename?: 'PrePortfolio' }
      & CorePrePortfolioFieldsFragment
    )>, error?: Maybe<(
      { __typename?: 'PrePortfolioImmutable' }
      & Pick<PrePortfolioImmutable, 'message'>
    ) | (
      { __typename?: 'PrePortfolioMissingField' }
      & Pick<PrePortfolioMissingField, 'message' | 'missingField'>
    )> }
  ) }
);

export type CreatePrePortfolioSimulationsMutationVariables = Exact<{
  prePortfolioId: Scalars['ID'];
}>;


export type CreatePrePortfolioSimulationsMutation = (
  { __typename?: 'Mutation' }
  & { createPrePortfolioSimulations: (
    { __typename?: 'CreatePrePortfolioSimulationsPayload' }
    & { error?: Maybe<(
      { __typename?: 'PrePortfolioSimulationNoMembers' }
      & Pick<PrePortfolioSimulationNoMembers, 'message'>
    )> }
  ) }
);

export type UpdateProfileImageMutationVariables = Exact<{
  image: Scalars['Upload'];
}>;


export type UpdateProfileImageMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileImage: (
    { __typename?: 'UpdateProfileImagePayload' }
    & { error?: Maybe<(
      { __typename?: 'EmptyFile' }
      & Pick<EmptyFile, 'message'>
    ) | (
      { __typename?: 'FileSizeInvalid' }
      & Pick<FileSizeInvalid, 'message'>
    ) | (
      { __typename?: 'FileTypeInvalid' }
      & Pick<FileTypeInvalid, 'message'>
    )>, user?: Maybe<(
      { __typename?: 'UserProfile' }
      & CoreUserProfileFieldsFragment
    )> }
  ) }
);

export type DeleteProfileImageMutationVariables = Exact<{
  mediaId: Scalars['ID'];
}>;


export type DeleteProfileImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteProfileImage: (
    { __typename?: 'DeleteProfileImagePayload' }
    & { error?: Maybe<(
      { __typename?: 'FileNotFound' }
      & Pick<FileNotFound, 'message'>
    )>, user?: Maybe<(
      { __typename?: 'UserProfile' }
      & CoreUserProfileFieldsFragment
    )> }
  ) }
);

export type AddIdeaToWatchlistMutationVariables = Exact<{
  ideaId: Scalars['ID'];
}>;


export type AddIdeaToWatchlistMutation = (
  { __typename?: 'Mutation' }
  & { addIdeaToWatchlist?: Maybe<(
    { __typename?: 'IdeaWatchlistItem' }
    & Pick<IdeaWatchlistItem, 'id'>
    & { idea?: Maybe<(
      { __typename?: 'Idea' }
      & Pick<Idea, 'id' | 'watchlistId'>
    )> }
  )> }
);

export type RemoveIdeaFromWatchlistMutationVariables = Exact<{
  watchlistId: Scalars['ID'];
}>;


export type RemoveIdeaFromWatchlistMutation = (
  { __typename?: 'Mutation' }
  & { removeIdeaFromWatchlist?: Maybe<(
    { __typename?: 'IdeaWatchlistItem' }
    & Pick<IdeaWatchlistItem, 'id'>
    & { idea?: Maybe<(
      { __typename?: 'Idea' }
      & Pick<Idea, 'id' | 'watchlistId'>
    )> }
  )> }
);

export type AddInstrumentToWatchlistMutationVariables = Exact<{
  instrumentId: Scalars['ID'];
}>;


export type AddInstrumentToWatchlistMutation = (
  { __typename?: 'Mutation' }
  & { addInstrumentToWatchlist?: Maybe<(
    { __typename?: 'InstrumentWatchlistItem' }
    & Pick<InstrumentWatchlistItem, 'id'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & Pick<Instrument, 'id' | 'watchlistId'>
    )> }
  )> }
);

export type RemoveInstrumentFromWatchlistMutationVariables = Exact<{
  watchlistId: Scalars['ID'];
}>;


export type RemoveInstrumentFromWatchlistMutation = (
  { __typename?: 'Mutation' }
  & { removeInstrumentFromWatchlist?: Maybe<(
    { __typename?: 'InstrumentWatchlistItem' }
    & Pick<InstrumentWatchlistItem, 'id'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & Pick<Instrument, 'id' | 'watchlistId'>
    )> }
  )> }
);

export type PostIdeaCommentMutationVariables = Exact<{
  ideaId: Scalars['ID'];
  appEntityType: AppEntityType;
  text: Scalars['String'];
  parent?: Maybe<Scalars['ID']>;
}>;


export type PostIdeaCommentMutation = (
  { __typename?: 'Mutation' }
  & { postComment?: Maybe<(
    { __typename?: 'PostCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & CoreCommentFieldsFragment
    )>, error?: Maybe<(
      { __typename?: 'CommentProfane' }
      & Pick<CommentProfane, 'message'>
    ) | (
      { __typename?: 'CommentTooLong' }
      & Pick<CommentTooLong, 'message'>
    ) | (
      { __typename?: 'CommentEmpty' }
      & Pick<CommentEmpty, 'message'>
    )> }
  )> }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'MePayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'isStaff' | 'tradingOnboardingFinished'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfile' }
        & CoreUserProfileFieldsFragment
      )> }
    )> }
  ) }
);

export type GetAppContentQueryVariables = Exact<{
  id: Scalars['ID'];
  imgixWidth?: Maybe<Scalars['Int']>;
}>;


export type GetAppContentQuery = (
  { __typename?: 'Query' }
  & { datoAppContent: (
    { __typename?: 'DatoAppContentPayload' }
    & { appContent?: Maybe<(
      { __typename?: 'DatoAppContent' }
      & Pick<DatoAppContent, 'id' | 'title' | 'description' | 'actionButtonText' | 'actionButtonDeepLink' | 'publishedDate'>
      & { coverImage?: Maybe<(
        { __typename?: 'DatoImageFile' }
        & Pick<DatoImageFile, 'url'>
      )> }
    )> }
  ) }
);

export type GetBlogQueryVariables = Exact<{
  id: Scalars['ID'];
  imgixWidth?: Maybe<Scalars['Int']>;
}>;


export type GetBlogQuery = (
  { __typename?: 'Query' }
  & { datoBlog: (
    { __typename?: 'DatoBlogPayload' }
    & { blog?: Maybe<(
      { __typename?: 'DatoBlog' }
      & Pick<DatoBlog, 'id' | 'title' | 'slug' | 'excerpt' | 'description' | 'destination' | 'publishedDate'>
      & { coverImage?: Maybe<(
        { __typename?: 'DatoImageFile' }
        & Pick<DatoImageFile, 'url'>
      )> }
    )> }
  ) }
);

export type GetPrePortfolioAndIdeasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPrePortfolioAndIdeasQuery = (
  { __typename?: 'Query' }
  & { prePortfolio: (
    { __typename?: 'PrePortfolioPayload' }
    & { prePortfolio?: Maybe<(
      { __typename?: 'PrePortfolio' }
      & CorePrePortfolioFieldsFragment
    )> }
  ), ideasForPortfolio?: Maybe<(
    { __typename?: 'IdeasForPortfolioPayload' }
    & { connection?: Maybe<(
      { __typename?: 'IdeaConnection' }
      & { nodes: Array<(
        { __typename?: 'Idea' }
        & CoreIdeaFieldsFragment
      )> }
    )> }
  )> }
);

export type IdeaFormQueryVariables = Exact<{
  instrumentId: Scalars['ID'];
  dateFrom?: Maybe<Scalars['Date']>;
}>;


export type IdeaFormQuery = (
  { __typename?: 'Query' }
  & { instrument: (
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id' | 'name' | 'ticker' | 'logoUrl' | 'watchlistId' | 'type'>
    & { countryOfListing?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, closePriceSeries?: Maybe<Array<Maybe<(
      { __typename?: 'ClosePrice' }
      & Pick<ClosePrice, 'id' | 'price' | 'date'>
    )>>>, closePrice?: Maybe<(
      { __typename?: 'ClosePrice' }
      & Pick<ClosePrice, 'id' | 'price'>
    )>, quotePrice?: Maybe<(
      { __typename?: 'QuotePrice' }
      & Pick<QuotePrice, 'id' | 'midPrice' | 'lastUpdated'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'iso'>
    )>, sector?: Maybe<(
      { __typename?: 'Sector' }
      & Pick<Sector, 'id' | 'name'>
    )>, industry?: Maybe<(
      { __typename?: 'Industry' }
      & Pick<Industry, 'id' | 'name'>
    )> }
  ), ideaRationaleCategories: Array<(
    { __typename?: 'IdeaRationaleCategory' }
    & Pick<IdeaRationaleCategory, 'id' | 'name' | 'sortOrder'>
    & { tags?: Maybe<Array<(
      { __typename?: 'IdeaRationaleTag' }
      & Pick<IdeaRationaleTag, 'id' | 'name' | 'isActive' | 'sortOrder'>
    )>> }
  )> }
);

export type GetDiscoverQueryVariables = Exact<{
  loggedIn: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
}>;


export type GetDiscoverQuery = (
  { __typename?: 'Query' }
  & { stacks?: Maybe<(
    { __typename?: 'StacksPayload' }
    & { stacks: Array<Maybe<(
      { __typename?: 'Stack' }
      & StackFieldsFragment
    )>> }
  )>, discover: Array<(
    { __typename?: 'DiscoverCategory' }
    & Pick<DiscoverCategory, 'id' | 'itemType' | 'categoryType' | 'title'>
    & { items: Array<{ __typename?: 'ReportRatingFeedItem' } | { __typename?: 'CrossFeedItem' } | { __typename?: 'RelativeStrengthIndexFeedItem' } | { __typename?: 'NewIdeaFeedItem' } | { __typename?: 'CorrelationFeedItem' } | (
      { __typename?: 'MostWatchlistedFeedItem' }
      & Pick<MostWatchlistedFeedItem, 'id'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | { __typename?: 'NewsFeedItem' } | (
      { __typename?: 'SignificantDailyMoverFeedItem' }
      & Pick<SignificantDailyMoverFeedItem, 'id' | 'latestReturn' | 'zScore' | 'date'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | (
      { __typename?: 'DiscoverCategoryFeedItem' }
      & Pick<DiscoverCategoryFeedItem, 'id'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | (
      { __typename?: 'SectorDiscoverCategoryFeedItem' }
      & Pick<SectorDiscoverCategoryFeedItem, 'id' | 'type'>
      & { sector?: Maybe<(
        { __typename?: 'Sector' }
        & Pick<Sector, 'id' | 'name' | 'logoUrl'>
      )> }
    ) | { __typename?: 'NetShortDisclosureFeedItem' } | { __typename?: 'MacdFeedItem' } | { __typename?: 'NewCommentFeedItem' } | (
      { __typename?: 'CurationsCategoryFeedItem' }
      & Pick<CurationsCategoryFeedItem, 'id' | 'type' | 'created' | 'name' | 'imageUrl'>
    ) | { __typename?: 'UpsideVoiceFeedItem' }> }
  )> }
);

export type GetDiscoverCategoryQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  categoryType: DiscoverCategoryType;
  loggedIn: Scalars['Boolean'];
}>;


export type GetDiscoverCategoryQuery = (
  { __typename?: 'Query' }
  & { discoverCategory: (
    { __typename?: 'DiscoverCategory' }
    & Pick<DiscoverCategory, 'id' | 'title' | 'description' | 'imageUrl'>
    & { items: Array<{ __typename?: 'ReportRatingFeedItem' } | { __typename?: 'CrossFeedItem' } | { __typename?: 'RelativeStrengthIndexFeedItem' } | { __typename?: 'NewIdeaFeedItem' } | { __typename?: 'CorrelationFeedItem' } | (
      { __typename?: 'MostWatchlistedFeedItem' }
      & Pick<MostWatchlistedFeedItem, 'id'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | { __typename?: 'NewsFeedItem' } | (
      { __typename?: 'SignificantDailyMoverFeedItem' }
      & Pick<SignificantDailyMoverFeedItem, 'id'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | (
      { __typename?: 'DiscoverCategoryFeedItem' }
      & Pick<DiscoverCategoryFeedItem, 'id'>
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | { __typename?: 'SectorDiscoverCategoryFeedItem' } | { __typename?: 'NetShortDisclosureFeedItem' } | { __typename?: 'MacdFeedItem' } | { __typename?: 'NewCommentFeedItem' } | { __typename?: 'CurationsCategoryFeedItem' } | { __typename?: 'UpsideVoiceFeedItem' }> }
  ) }
);

export type GetFeedQueryVariables = Exact<{
  feedTypes?: Maybe<Array<FeedItemType> | FeedItemType>;
  filterCategories?: Maybe<Array<FilterCategory> | FilterCategory>;
  loggedIn: Scalars['Boolean'];
}>;


export type GetFeedQuery = (
  { __typename?: 'Query' }
  & { feed: Array<(
    { __typename?: 'ReportRatingFeedItem' }
    & FeedCardFields_ReportRatingFeedItem_Fragment
  ) | (
    { __typename?: 'CrossFeedItem' }
    & FeedCardFields_CrossFeedItem_Fragment
  ) | (
    { __typename?: 'RelativeStrengthIndexFeedItem' }
    & FeedCardFields_RelativeStrengthIndexFeedItem_Fragment
  ) | (
    { __typename?: 'NewIdeaFeedItem' }
    & FeedCardFields_NewIdeaFeedItem_Fragment
  ) | (
    { __typename?: 'CorrelationFeedItem' }
    & FeedCardFields_CorrelationFeedItem_Fragment
  ) | (
    { __typename?: 'MostWatchlistedFeedItem' }
    & FeedCardFields_MostWatchlistedFeedItem_Fragment
  ) | (
    { __typename?: 'NewsFeedItem' }
    & FeedCardFields_NewsFeedItem_Fragment
  ) | (
    { __typename?: 'SignificantDailyMoverFeedItem' }
    & FeedCardFields_SignificantDailyMoverFeedItem_Fragment
  ) | (
    { __typename?: 'DiscoverCategoryFeedItem' }
    & FeedCardFields_DiscoverCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'SectorDiscoverCategoryFeedItem' }
    & FeedCardFields_SectorDiscoverCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'NetShortDisclosureFeedItem' }
    & FeedCardFields_NetShortDisclosureFeedItem_Fragment
  ) | (
    { __typename?: 'MacdFeedItem' }
    & FeedCardFields_MacdFeedItem_Fragment
  ) | (
    { __typename?: 'NewCommentFeedItem' }
    & FeedCardFields_NewCommentFeedItem_Fragment
  ) | (
    { __typename?: 'CurationsCategoryFeedItem' }
    & FeedCardFields_CurationsCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'UpsideVoiceFeedItem' }
    & FeedCardFields_UpsideVoiceFeedItem_Fragment
  )>, instrumentWatchlistItems: Array<(
    { __typename?: 'InstrumentWatchlistItem' }
    & Pick<InstrumentWatchlistItem, 'id'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )> }
  )> }
);

export type GetFeedItemQueryVariables = Exact<{
  id: Scalars['ID'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetFeedItemQuery = (
  { __typename?: 'Query' }
  & { feedItem?: Maybe<(
    { __typename?: 'ReportRatingFeedItem' }
    & FeedCardFields_ReportRatingFeedItem_Fragment
  ) | (
    { __typename?: 'CrossFeedItem' }
    & FeedCardFields_CrossFeedItem_Fragment
  ) | (
    { __typename?: 'RelativeStrengthIndexFeedItem' }
    & FeedCardFields_RelativeStrengthIndexFeedItem_Fragment
  ) | (
    { __typename?: 'NewIdeaFeedItem' }
    & FeedCardFields_NewIdeaFeedItem_Fragment
  ) | (
    { __typename?: 'CorrelationFeedItem' }
    & FeedCardFields_CorrelationFeedItem_Fragment
  ) | (
    { __typename?: 'MostWatchlistedFeedItem' }
    & FeedCardFields_MostWatchlistedFeedItem_Fragment
  ) | (
    { __typename?: 'NewsFeedItem' }
    & FeedCardFields_NewsFeedItem_Fragment
  ) | (
    { __typename?: 'SignificantDailyMoverFeedItem' }
    & FeedCardFields_SignificantDailyMoverFeedItem_Fragment
  ) | (
    { __typename?: 'DiscoverCategoryFeedItem' }
    & FeedCardFields_DiscoverCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'SectorDiscoverCategoryFeedItem' }
    & FeedCardFields_SectorDiscoverCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'NetShortDisclosureFeedItem' }
    & FeedCardFields_NetShortDisclosureFeedItem_Fragment
  ) | (
    { __typename?: 'MacdFeedItem' }
    & FeedCardFields_MacdFeedItem_Fragment
  ) | (
    { __typename?: 'NewCommentFeedItem' }
    & FeedCardFields_NewCommentFeedItem_Fragment
  ) | (
    { __typename?: 'CurationsCategoryFeedItem' }
    & FeedCardFields_CurationsCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'UpsideVoiceFeedItem' }
    & FeedCardFields_UpsideVoiceFeedItem_Fragment
  )> }
);

export type GetOnboardingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOnboardingSummaryQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'MePayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'tradingOnboardingFinished'>
    )> }
  ), myTradingParty: (
    { __typename?: 'MyTradingPartyPayload' }
    & { party?: Maybe<(
      { __typename?: 'TradingParty' }
      & Pick<TradingParty, 'id'>
      & { addresses: Array<(
        { __typename?: 'TradingAddress' }
        & Pick<TradingAddress, 'id'>
      )>, bankAccounts: Array<(
        { __typename?: 'TradingBankAccount' }
        & Pick<TradingBankAccount, 'id'>
      )> }
    )> }
  ), myPortfolios: (
    { __typename?: 'MyTradingPortfoliosPayload' }
    & { portfolios: Array<(
      { __typename?: 'TradingPortfolio' }
      & Pick<TradingPortfolio, 'id' | 'status'>
    )> }
  ), journeySummary?: Maybe<(
    { __typename?: 'JourneySummaryPayload' }
    & { summary?: Maybe<(
      { __typename?: 'JourneySummary' }
      & { instrumentWatchlist?: Maybe<(
        { __typename?: 'InstrumentWatchlistJourneySummary' }
        & Pick<InstrumentWatchlistJourneySummary, 'hasWatchlisted' | 'watchlistAdditionsBeforeComplete'>
      )>, idea?: Maybe<(
        { __typename?: 'IdeaJourneySummary' }
        & Pick<IdeaJourneySummary, 'hasCreatedIdea' | 'ideaCreationsBeforeComplete'>
      )>, blueprint?: Maybe<(
        { __typename?: 'BlueprintJourneySummary' }
        & Pick<BlueprintJourneySummary, 'hasStartedBlueprint' | 'hasFinishedBlueprint' | 'daysStale'>
      )> }
    )> }
  )> }
);

export type GetHomeScreenDataQueryVariables = Exact<{
  blogImgixWidth?: Maybe<Scalars['Int']>;
  appContentImgixWidth?: Maybe<Scalars['Int']>;
  appContentImgixHeight?: Maybe<Scalars['Int']>;
}>;


export type GetHomeScreenDataQuery = (
  { __typename?: 'Query' }
  & { myMarketData: (
    { __typename?: 'MyMarketDataPayload' }
    & { instruments: Array<(
      { __typename?: 'Instrument' }
      & Pick<Instrument, 'id' | 'ticker' | 'displayName' | 'name'>
      & { quotePrice?: Maybe<(
        { __typename?: 'QuotePrice' }
        & Pick<QuotePrice, 'id' | 'midPrice' | 'previousClose' | 'pctChange1d'>
      )> }
    )> }
  ), instrumentWatchlistItems: Array<(
    { __typename?: 'InstrumentWatchlistItem' }
    & Pick<InstrumentWatchlistItem, 'id'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & Pick<Instrument, 'id' | 'ticker' | 'logoUrl'>
      & { quotePrice?: Maybe<(
        { __typename?: 'QuotePrice' }
        & Pick<QuotePrice, 'id' | 'pctChange1d'>
      )> }
    )> }
  )>, ideasPaginated: (
    { __typename?: 'IdeaConnection' }
    & { nodes: Array<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )> }
  ), datoAppContents: (
    { __typename?: 'DatoAppContentsPayload' }
    & { connection: (
      { __typename?: 'DatoAppContentConnection' }
      & { nodes: Array<(
        { __typename?: 'DatoAppContent' }
        & Pick<DatoAppContent, 'id' | 'title' | 'excerpt'>
        & { coverImage?: Maybe<(
          { __typename?: 'DatoImageFile' }
          & Pick<DatoImageFile, 'url'>
        )> }
      )> }
    ) }
  ), datoBlogs: (
    { __typename?: 'DatoBlogsPayload' }
    & { connection: (
      { __typename?: 'DatoBlogConnection' }
      & { nodes: Array<(
        { __typename?: 'DatoBlog' }
        & Pick<DatoBlog, 'id' | 'title' | 'slug' | 'excerpt' | 'publishedDate'>
        & { coverImage?: Maybe<(
          { __typename?: 'DatoImageFile' }
          & Pick<DatoImageFile, 'url'>
        )> }
      )> }
    ) }
  ) }
);

export type CloseIdeaMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CloseIdeaMutation = (
  { __typename?: 'Mutation' }
  & { closeIdea: (
    { __typename?: 'CloseIdeaPayload' }
    & { idea?: Maybe<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )>, error?: Maybe<(
      { __typename?: 'IdeaAlreadyClosed' }
      & Pick<IdeaAlreadyClosed, 'message'>
    )> }
  ) }
);

export type GetIdeaQueryVariables = Exact<{
  ideaId: Scalars['ID'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetIdeaQuery = (
  { __typename?: 'Query' }
  & { publicIdea: (
    { __typename?: 'Idea' }
    & MakeOptional<Pick<Idea, 'id' | 'conviction' | 'status' | 'position' | 'referenceDate' | 'referencePrice' | 'targetDate' | 'targetPrice' | 'headline' | 'description' | 'closeTime' | 'watchlistId'>, 'watchlistId'>
    & { user?: Maybe<(
      { __typename?: 'UserProfile' }
      & { ideas?: Maybe<(
        { __typename?: 'UserProfileIdeasPayload' }
        & { connection?: Maybe<(
          { __typename?: 'UserIdeaConnection' }
          & { nodes: Array<(
            { __typename?: 'Idea' }
            & Pick<Idea, 'id'>
          )> }
        )> }
      )> }
      & CoreUserProfileFieldsFragment
    )>, instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )>, rationaleTags?: Maybe<Array<Maybe<(
      { __typename?: 'IdeaRationaleTag' }
      & Pick<IdeaRationaleTag, 'id' | 'name' | 'sortOrder'>
    )>>>, performance?: Maybe<(
      { __typename?: 'IdeaPerformance' }
      & Pick<IdeaPerformance, 'id' | 'actualReturn' | 'actualPrice' | 'actualPriceMovement' | 'priceChangeSincePosted'>
    )>, topic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'commentCount'>
      & { comments?: Maybe<(
        { __typename?: 'CommentConnection' }
        & { nodes: Array<(
          { __typename?: 'Comment' }
          & CoreCommentFieldsFragment
        )> }
      )> }
    )> }
  ) }
);

export type GetInPlayPricesQueryVariables = Exact<{
  instrumentId: Scalars['ID'];
  dateFrom: Scalars['Date'];
  dateTo: Scalars['Date'];
}>;


export type GetInPlayPricesQuery = (
  { __typename?: 'Query' }
  & { instrument: (
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id'>
    & { closePriceSeries?: Maybe<Array<Maybe<(
      { __typename?: 'ClosePrice' }
      & Pick<ClosePrice, 'id' | 'price' | 'date'>
    )>>> }
  ) }
);

export type GetAllIdeasQueryVariables = Exact<{
  statuses?: Maybe<Array<IdeaStatus> | IdeaStatus>;
  cursor?: Maybe<Scalars['NonEmptyString']>;
}>;


export type GetAllIdeasQuery = (
  { __typename?: 'Query' }
  & { publicIdeasPaginated: (
    { __typename?: 'IdeaConnection' }
    & { nodes: Array<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CorePaginationFieldsFragment
    ) }
  ) }
);

export type GetUserIdeasQueryVariables = Exact<{
  statuses?: Maybe<Array<IdeaStatus> | IdeaStatus>;
  cursor?: Maybe<Scalars['NonEmptyString']>;
}>;


export type GetUserIdeasQuery = (
  { __typename?: 'Query' }
  & { ideasPaginated: (
    { __typename?: 'IdeaConnection' }
    & { nodes: Array<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CorePaginationFieldsFragment
    ) }
  ) }
);

export type GetTrackedIdeasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTrackedIdeasQuery = (
  { __typename?: 'Query' }
  & { ideaWatchlistItems: Array<(
    { __typename?: 'IdeaWatchlistItem' }
    & Pick<IdeaWatchlistItem, 'id'>
    & { idea?: Maybe<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )> }
  )> }
);

export type GetInstrumentIdeasQueryVariables = Exact<{
  statuses?: Maybe<Array<IdeaStatus> | IdeaStatus>;
  instrumentId: Scalars['ID'];
  cursor?: Maybe<Scalars['NonEmptyString']>;
}>;


export type GetInstrumentIdeasQuery = (
  { __typename?: 'Query' }
  & { publicIdeasPaginated: (
    { __typename?: 'IdeaConnection' }
    & { nodes: Array<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CorePaginationFieldsFragment
    ) }
  ) }
);

export type GetInstrumentQueryVariables = Exact<{
  id: Scalars['ID'];
  dateFrom?: Maybe<Scalars['Date']>;
  feedItemId: Scalars['ID'];
  includeFeedItem: Scalars['Boolean'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetInstrumentQuery = (
  { __typename?: 'Query' }
  & { instrument: (
    { __typename?: 'Instrument' }
    & MakeOptional<Pick<Instrument, 'id' | 'name' | 'companyInfo' | 'ticker' | 'logoUrl' | 'watchlistId' | 'type'>, 'watchlistId'>
    & { countryOfListing?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )>, closePriceSeries?: Maybe<Array<Maybe<(
      { __typename?: 'ClosePrice' }
      & Pick<ClosePrice, 'id' | 'price' | 'date'>
    )>>>, closePrice?: Maybe<(
      { __typename?: 'ClosePrice' }
      & Pick<ClosePrice, 'id' | 'price'>
    )>, quotePrice?: Maybe<(
      { __typename?: 'QuotePrice' }
      & Pick<QuotePrice, 'id' | 'midPrice' | 'previousClose' | 'lastUpdated'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'iso'>
    )>, sector?: Maybe<(
      { __typename?: 'Sector' }
      & Pick<Sector, 'id' | 'name'>
    )>, reportRatingCurrent?: Maybe<(
      { __typename?: 'ReportRatingInsight' }
      & Pick<ReportRatingInsight, 'id' | 'buyCount' | 'sellCount' | 'holdCount' | 'totalCount' | 'modeRating'>
    )>, industry?: Maybe<(
      { __typename?: 'Industry' }
      & Pick<Industry, 'id' | 'name'>
    )>, yearlyFinancials?: Maybe<Array<Maybe<(
      { __typename?: 'Financials' }
      & Pick<Financials, 'date' | 'totalRevenue' | 'netIncome' | 'netMargin' | 'costOfRevenue'>
    )>>>, esgCurrent?: Maybe<(
      { __typename?: 'ESG' }
      & InstrumentEsgFragment
    )> }
    & InstrumentStatisticsFragment
  ), ideasPaginated?: Maybe<(
    { __typename?: 'IdeaConnection' }
    & { nodes: Array<(
      { __typename?: 'Idea' }
      & Pick<Idea, 'id'>
    )> }
  )>, feedItem?: Maybe<(
    { __typename?: 'ReportRatingFeedItem' }
    & FeedCardFields_ReportRatingFeedItem_Fragment
  ) | (
    { __typename?: 'CrossFeedItem' }
    & FeedCardFields_CrossFeedItem_Fragment
  ) | (
    { __typename?: 'RelativeStrengthIndexFeedItem' }
    & FeedCardFields_RelativeStrengthIndexFeedItem_Fragment
  ) | (
    { __typename?: 'NewIdeaFeedItem' }
    & FeedCardFields_NewIdeaFeedItem_Fragment
  ) | (
    { __typename?: 'CorrelationFeedItem' }
    & FeedCardFields_CorrelationFeedItem_Fragment
  ) | (
    { __typename?: 'MostWatchlistedFeedItem' }
    & FeedCardFields_MostWatchlistedFeedItem_Fragment
  ) | (
    { __typename?: 'NewsFeedItem' }
    & FeedCardFields_NewsFeedItem_Fragment
  ) | (
    { __typename?: 'SignificantDailyMoverFeedItem' }
    & FeedCardFields_SignificantDailyMoverFeedItem_Fragment
  ) | (
    { __typename?: 'DiscoverCategoryFeedItem' }
    & FeedCardFields_DiscoverCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'SectorDiscoverCategoryFeedItem' }
    & FeedCardFields_SectorDiscoverCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'NetShortDisclosureFeedItem' }
    & FeedCardFields_NetShortDisclosureFeedItem_Fragment
  ) | (
    { __typename?: 'MacdFeedItem' }
    & FeedCardFields_MacdFeedItem_Fragment
  ) | (
    { __typename?: 'NewCommentFeedItem' }
    & FeedCardFields_NewCommentFeedItem_Fragment
  ) | (
    { __typename?: 'CurationsCategoryFeedItem' }
    & FeedCardFields_CurationsCategoryFeedItem_Fragment
  ) | (
    { __typename?: 'UpsideVoiceFeedItem' }
    & FeedCardFields_UpsideVoiceFeedItem_Fragment
  )> }
);

export type GetInstrumentFeedQueryVariables = Exact<{
  id: Scalars['ID'];
  filterCategories?: Maybe<Array<FilterCategory> | FilterCategory>;
  feedTypes?: Maybe<Array<FeedItemType> | FeedItemType>;
  loggedIn: Scalars['Boolean'];
}>;


export type GetInstrumentFeedQuery = (
  { __typename?: 'Query' }
  & { instrumentFeed?: Maybe<(
    { __typename?: 'InstrumentFeedPayload' }
    & { feed: Array<(
      { __typename?: 'ReportRatingFeedItem' }
      & FeedCardFields_ReportRatingFeedItem_Fragment
    ) | (
      { __typename?: 'CrossFeedItem' }
      & FeedCardFields_CrossFeedItem_Fragment
    ) | (
      { __typename?: 'RelativeStrengthIndexFeedItem' }
      & FeedCardFields_RelativeStrengthIndexFeedItem_Fragment
    ) | (
      { __typename?: 'NewIdeaFeedItem' }
      & FeedCardFields_NewIdeaFeedItem_Fragment
    ) | (
      { __typename?: 'CorrelationFeedItem' }
      & FeedCardFields_CorrelationFeedItem_Fragment
    ) | (
      { __typename?: 'MostWatchlistedFeedItem' }
      & FeedCardFields_MostWatchlistedFeedItem_Fragment
    ) | (
      { __typename?: 'NewsFeedItem' }
      & FeedCardFields_NewsFeedItem_Fragment
    ) | (
      { __typename?: 'SignificantDailyMoverFeedItem' }
      & FeedCardFields_SignificantDailyMoverFeedItem_Fragment
    ) | (
      { __typename?: 'DiscoverCategoryFeedItem' }
      & FeedCardFields_DiscoverCategoryFeedItem_Fragment
    ) | (
      { __typename?: 'SectorDiscoverCategoryFeedItem' }
      & FeedCardFields_SectorDiscoverCategoryFeedItem_Fragment
    ) | (
      { __typename?: 'NetShortDisclosureFeedItem' }
      & FeedCardFields_NetShortDisclosureFeedItem_Fragment
    ) | (
      { __typename?: 'MacdFeedItem' }
      & FeedCardFields_MacdFeedItem_Fragment
    ) | (
      { __typename?: 'NewCommentFeedItem' }
      & FeedCardFields_NewCommentFeedItem_Fragment
    ) | (
      { __typename?: 'CurationsCategoryFeedItem' }
      & FeedCardFields_CurationsCategoryFeedItem_Fragment
    ) | (
      { __typename?: 'UpsideVoiceFeedItem' }
      & FeedCardFields_UpsideVoiceFeedItem_Fragment
    )> }
  )> }
);

export type GetInstrumentFinancialsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInstrumentFinancialsQuery = (
  { __typename?: 'Query' }
  & { instrument: (
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id' | 'ticker' | 'companyInfo'>
    & { yearlyFinancials?: Maybe<Array<Maybe<(
      { __typename?: 'Financials' }
      & InstrumentFinancialsFragment
    )>>>, quarterlyFinancials?: Maybe<Array<Maybe<(
      { __typename?: 'Financials' }
      & InstrumentFinancialsFragment
    )>>> }
  ) }
);

export type GetInstrumentSummaryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInstrumentSummaryQuery = (
  { __typename?: 'Query' }
  & { instrument: (
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id' | 'ticker' | 'companyInfo'>
    & { quotePrice?: Maybe<(
      { __typename?: 'QuotePrice' }
      & Pick<QuotePrice, 'id' | 'midPrice' | 'previousClose'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'iso'>
    )>, sector?: Maybe<(
      { __typename?: 'Sector' }
      & Pick<Sector, 'id' | 'name'>
    )>, industry?: Maybe<(
      { __typename?: 'Industry' }
      & Pick<Industry, 'id' | 'name'>
    )>, countryOfListing?: Maybe<(
      { __typename?: 'Country' }
      & Pick<Country, 'id' | 'name'>
    )> }
    & InstrumentStatisticsFragment
  ) }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  web: Scalars['Boolean'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginPayload' }
    & MakeOptional<Pick<LoginPayload, 'accessToken' | 'refreshToken'>, 'refreshToken'>
    & { error?: Maybe<(
      { __typename?: 'AuthenticationFailed' }
      & Pick<AuthenticationFailed, 'message'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'isStaff'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'nickname' | 'profileRating'>
      )> }
    )> }
  ) }
);

export type GetMarketInstrumentQueryVariables = Exact<{
  id: Scalars['ID'];
  dateFrom?: Maybe<Scalars['Date']>;
}>;


export type GetMarketInstrumentQuery = (
  { __typename?: 'Query' }
  & { instrument: (
    { __typename?: 'Instrument' }
    & Pick<Instrument, 'id' | 'displayName' | 'ticker' | 'logoUrl' | 'companyInfo'>
    & { closePriceSeries?: Maybe<Array<Maybe<(
      { __typename?: 'ClosePrice' }
      & Pick<ClosePrice, 'id' | 'price' | 'date'>
    )>>>, quotePrice?: Maybe<(
      { __typename?: 'QuotePrice' }
      & Pick<QuotePrice, 'id' | 'midPrice' | 'previousClose' | 'lastUpdated'>
    )>, currency?: Maybe<(
      { __typename?: 'Currency' }
      & Pick<Currency, 'id' | 'iso'>
    )> }
  ) }
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { publicProfile: (
    { __typename?: 'UserProfile' }
    & CoreUserProfileFieldsFragment
  ), myUpsideShares: (
    { __typename?: 'MyUpsideSharesPayload' }
    & { summary?: Maybe<(
      { __typename?: 'UpsideSharesSummary' }
      & Pick<UpsideSharesSummary, 'totalValue' | 'firstIssueDate' | 'totalShares'>
    )> }
  ) }
);

export type GetNewsQueryVariables = Exact<{
  id: Scalars['ID'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetNewsQuery = (
  { __typename?: 'Query' }
  & { news: (
    { __typename?: 'News' }
    & Pick<News, 'id' | 'title' | 'source' | 'publishDate' | 'storyText' | 'fullTextUrl' | 'imageUrl'>
    & { instruments: Array<Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )>> }
  ) }
);

export type GetPortfolioInsightsQueryVariables = Exact<{
  cashProportion: Scalars['Float'];
  components: Array<ComponentInput> | ComponentInput;
}>;


export type GetPortfolioInsightsQuery = (
  { __typename?: 'Query' }
  & { portfolioInsights?: Maybe<(
    { __typename?: 'PortfolioInsightsPayload' }
    & { insights?: Maybe<(
      { __typename?: 'PrePortfolioStats' }
      & Pick<PrePortfolioStats, 'inceptionToDateReturn' | 'annualisedReturn' | 'annualisedVolatility' | 'sharpeRatio' | 'maximumDrawdown' | 'correlation' | 'countryCashWeight' | 'sectorCashWeight' | 'marketCapCashWeight'>
      & { volatilities?: Maybe<Array<(
        { __typename?: 'InstrumentVolatility' }
        & Pick<InstrumentVolatility, 'volatility'>
        & { instrument?: Maybe<(
          { __typename?: 'Instrument' }
          & Pick<Instrument, 'id' | 'displayName'>
        )> }
      )>>, esg?: Maybe<(
        { __typename?: 'ESG' }
        & InstrumentEsgFragment
      )>, countryWeights: Array<(
        { __typename?: 'CountryWeight' }
        & Pick<CountryWeight, 'weight'>
        & { country?: Maybe<(
          { __typename?: 'Country' }
          & Pick<Country, 'id' | 'name'>
        )> }
      )>, sectorWeights: Array<(
        { __typename?: 'SectorWeight' }
        & Pick<SectorWeight, 'weight'>
        & { sector?: Maybe<(
          { __typename?: 'Sector' }
          & Pick<Sector, 'id' | 'name'>
        )> }
      )>, marketCapWeights: Array<(
        { __typename?: 'MarketCapWeight' }
        & Pick<MarketCapWeight, 'weight' | 'marketCap'>
      )> }
    )> }
  )> }
);

export type RecordOnboardingPortfolioMutationVariables = Exact<{
  email: Scalars['NonEmptyString'];
  portfolio: OnboardingPortfolioInput;
  anonymousId: Scalars['NonEmptyString'];
  emailTemplate: OnboardingEmailTemplate;
}>;


export type RecordOnboardingPortfolioMutation = (
  { __typename?: 'Mutation' }
  & { recordOnboardingPortfolio: (
    { __typename?: 'RecordOnboardingPortfolioPayload' }
    & { error?: Maybe<(
      { __typename?: 'EmailInvalid' }
      & Pick<EmailInvalid, 'message'>
    ) | (
      { __typename?: 'NicknameInvalid' }
      & Pick<NicknameInvalid, 'message'>
    ) | (
      { __typename?: 'PasswordInvalid' }
      & Pick<PasswordInvalid, 'message'>
    ) | (
      { __typename?: 'AuthenticationFailed' }
      & Pick<AuthenticationFailed, 'message'>
    ) | (
      { __typename?: 'TokenNotProvided' }
      & Pick<TokenNotProvided, 'message'>
    ) | (
      { __typename?: 'UserInvalid' }
      & Pick<UserInvalid, 'message'>
    ) | (
      { __typename?: 'TokenInvalid' }
      & Pick<TokenInvalid, 'message'>
    ) | (
      { __typename?: 'CommentProfane' }
      & Pick<CommentProfane, 'message'>
    ) | (
      { __typename?: 'CommentTooLong' }
      & Pick<CommentTooLong, 'message'>
    ) | (
      { __typename?: 'CommentEmpty' }
      & Pick<CommentEmpty, 'message'>
    ) | (
      { __typename?: 'IdeaAlreadyClosed' }
      & Pick<IdeaAlreadyClosed, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsInvalidDate' }
      & Pick<PrePortfolioStatsInvalidDate, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMemberWeight' }
      & Pick<PrePortfolioStatsNoMemberWeight, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMembers' }
      & Pick<PrePortfolioStatsNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioSimulationNoMembers' }
      & Pick<PrePortfolioSimulationNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioImmutable' }
      & Pick<PrePortfolioImmutable, 'message'>
    ) | (
      { __typename?: 'PrePortfolioMissingField' }
      & Pick<PrePortfolioMissingField, 'message'>
    ) | (
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message'>
    ) | (
      { __typename?: 'EmptyFile' }
      & Pick<EmptyFile, 'message'>
    ) | (
      { __typename?: 'FileSizeInvalid' }
      & Pick<FileSizeInvalid, 'message'>
    ) | (
      { __typename?: 'FileTypeInvalid' }
      & Pick<FileTypeInvalid, 'message'>
    ) | (
      { __typename?: 'FileNotFound' }
      & Pick<FileNotFound, 'message'>
    )> }
  ) }
);

export type GetBaseStackQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBaseStackQuery = (
  { __typename?: 'Query' }
  & { baseStack: (
    { __typename?: 'StackPayload' }
    & { stack: (
      { __typename?: 'Stack' }
      & StackFieldsFragment
    ) }
  ) }
);

export type GetStacksQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStacksQuery = (
  { __typename?: 'Query' }
  & { stacks: (
    { __typename?: 'StacksPayload' }
    & { stacks: Array<Maybe<(
      { __typename?: 'Stack' }
      & StackFieldsFragment
    )>> }
  ) }
);

export type GetMostWatchlistedQueryVariables = Exact<{
  loggedIn: Scalars['Boolean'];
}>;


export type GetMostWatchlistedQuery = (
  { __typename?: 'Query' }
  & { discoverCategory: (
    { __typename?: 'DiscoverCategory' }
    & Pick<DiscoverCategory, 'id'>
    & { items: Array<{ __typename?: 'ReportRatingFeedItem' } | { __typename?: 'CrossFeedItem' } | { __typename?: 'RelativeStrengthIndexFeedItem' } | { __typename?: 'NewIdeaFeedItem' } | { __typename?: 'CorrelationFeedItem' } | (
      { __typename?: 'MostWatchlistedFeedItem' }
      & { instrument?: Maybe<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    ) | { __typename?: 'NewsFeedItem' } | { __typename?: 'SignificantDailyMoverFeedItem' } | { __typename?: 'DiscoverCategoryFeedItem' } | { __typename?: 'SectorDiscoverCategoryFeedItem' } | { __typename?: 'NetShortDisclosureFeedItem' } | { __typename?: 'MacdFeedItem' } | { __typename?: 'NewCommentFeedItem' } | { __typename?: 'CurationsCategoryFeedItem' } | { __typename?: 'UpsideVoiceFeedItem' }> }
  ) }
);

export type GetStockSearchQueryVariables = Exact<{
  searchText: Scalars['NonEmptyString'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetStockSearchQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename?: 'SearchPayload' }
    & { instruments?: Maybe<(
      { __typename?: 'InstrumentConnection' }
      & { nodes: Array<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    )>, error?: Maybe<(
      { __typename?: 'EmailInvalid' }
      & Pick<EmailInvalid, 'message'>
    ) | (
      { __typename?: 'NicknameInvalid' }
      & Pick<NicknameInvalid, 'message'>
    ) | (
      { __typename?: 'PasswordInvalid' }
      & Pick<PasswordInvalid, 'message'>
    ) | (
      { __typename?: 'AuthenticationFailed' }
      & Pick<AuthenticationFailed, 'message'>
    ) | (
      { __typename?: 'TokenNotProvided' }
      & Pick<TokenNotProvided, 'message'>
    ) | (
      { __typename?: 'UserInvalid' }
      & Pick<UserInvalid, 'message'>
    ) | (
      { __typename?: 'TokenInvalid' }
      & Pick<TokenInvalid, 'message'>
    ) | (
      { __typename?: 'CommentProfane' }
      & Pick<CommentProfane, 'message'>
    ) | (
      { __typename?: 'CommentTooLong' }
      & Pick<CommentTooLong, 'message'>
    ) | (
      { __typename?: 'CommentEmpty' }
      & Pick<CommentEmpty, 'message'>
    ) | (
      { __typename?: 'IdeaAlreadyClosed' }
      & Pick<IdeaAlreadyClosed, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsInvalidDate' }
      & Pick<PrePortfolioStatsInvalidDate, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMemberWeight' }
      & Pick<PrePortfolioStatsNoMemberWeight, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMembers' }
      & Pick<PrePortfolioStatsNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioSimulationNoMembers' }
      & Pick<PrePortfolioSimulationNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioImmutable' }
      & Pick<PrePortfolioImmutable, 'message'>
    ) | (
      { __typename?: 'PrePortfolioMissingField' }
      & Pick<PrePortfolioMissingField, 'message'>
    ) | (
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message'>
    ) | (
      { __typename?: 'EmptyFile' }
      & Pick<EmptyFile, 'message'>
    ) | (
      { __typename?: 'FileSizeInvalid' }
      & Pick<FileSizeInvalid, 'message'>
    ) | (
      { __typename?: 'FileTypeInvalid' }
      & Pick<FileTypeInvalid, 'message'>
    ) | (
      { __typename?: 'FileNotFound' }
      & Pick<FileNotFound, 'message'>
    )> }
  ) }
);

export type GetSimulationsAndPrePortfolioQueryVariables = Exact<{
  prePortfolioId: Scalars['ID'];
}>;


export type GetSimulationsAndPrePortfolioQuery = (
  { __typename?: 'Query' }
  & { prePortfolio: (
    { __typename?: 'PrePortfolioPayload' }
    & { prePortfolio?: Maybe<(
      { __typename?: 'PrePortfolio' }
      & CorePrePortfolioFieldsFragment
    )> }
  ), prePortfolioSimulations: (
    { __typename?: 'PrePortfolioSimulationPayload' }
    & { simulations: Array<(
      { __typename?: 'PrePortfolioSimulation' }
      & CorePrePortfolioSimulationFieldsFragment
    )> }
  ) }
);

export type GetPrePortfolioSimulationsQueryVariables = Exact<{
  prePortfolioId: Scalars['ID'];
}>;


export type GetPrePortfolioSimulationsQuery = (
  { __typename?: 'Query' }
  & { prePortfolioSimulations: (
    { __typename?: 'PrePortfolioSimulationPayload' }
    & { simulations: Array<(
      { __typename?: 'PrePortfolioSimulation' }
      & CorePrePortfolioSimulationFieldsFragment
    )> }
  ) }
);

export type GetSearchQueryVariables = Exact<{
  searchText: Scalars['NonEmptyString'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetSearchQuery = (
  { __typename?: 'Query' }
  & { search: (
    { __typename?: 'SearchPayload' }
    & { instruments?: Maybe<(
      { __typename?: 'InstrumentConnection' }
      & { nodes: Array<(
        { __typename?: 'Instrument' }
        & CoreInstrumentFieldsFragment
      )> }
    )>, users?: Maybe<(
      { __typename?: 'UserProfileConnection' }
      & { nodes: Array<(
        { __typename?: 'UserProfile' }
        & CoreUserProfileFieldsFragment
      )> }
    )>, error?: Maybe<(
      { __typename?: 'EmailInvalid' }
      & Pick<EmailInvalid, 'message'>
    ) | (
      { __typename?: 'NicknameInvalid' }
      & Pick<NicknameInvalid, 'message'>
    ) | (
      { __typename?: 'PasswordInvalid' }
      & Pick<PasswordInvalid, 'message'>
    ) | (
      { __typename?: 'AuthenticationFailed' }
      & Pick<AuthenticationFailed, 'message'>
    ) | (
      { __typename?: 'TokenNotProvided' }
      & Pick<TokenNotProvided, 'message'>
    ) | (
      { __typename?: 'UserInvalid' }
      & Pick<UserInvalid, 'message'>
    ) | (
      { __typename?: 'TokenInvalid' }
      & Pick<TokenInvalid, 'message'>
    ) | (
      { __typename?: 'CommentProfane' }
      & Pick<CommentProfane, 'message'>
    ) | (
      { __typename?: 'CommentTooLong' }
      & Pick<CommentTooLong, 'message'>
    ) | (
      { __typename?: 'CommentEmpty' }
      & Pick<CommentEmpty, 'message'>
    ) | (
      { __typename?: 'IdeaAlreadyClosed' }
      & Pick<IdeaAlreadyClosed, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsInvalidDate' }
      & Pick<PrePortfolioStatsInvalidDate, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMemberWeight' }
      & Pick<PrePortfolioStatsNoMemberWeight, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMembers' }
      & Pick<PrePortfolioStatsNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioSimulationNoMembers' }
      & Pick<PrePortfolioSimulationNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioImmutable' }
      & Pick<PrePortfolioImmutable, 'message'>
    ) | (
      { __typename?: 'PrePortfolioMissingField' }
      & Pick<PrePortfolioMissingField, 'message'>
    ) | (
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message'>
    ) | (
      { __typename?: 'EmptyFile' }
      & Pick<EmptyFile, 'message'>
    ) | (
      { __typename?: 'FileSizeInvalid' }
      & Pick<FileSizeInvalid, 'message'>
    ) | (
      { __typename?: 'FileTypeInvalid' }
      & Pick<FileTypeInvalid, 'message'>
    ) | (
      { __typename?: 'FileNotFound' }
      & Pick<FileNotFound, 'message'>
    )> }
  ) }
);

export type SignUpMutationVariables = Exact<{
  email: Scalars['NonEmptyString'];
  password: Scalars['NonEmptyString'];
  nickname: Scalars['NonEmptyString'];
  mailingList: Scalars['Boolean'];
  web: Scalars['Boolean'];
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'SignupPayload' }
    & MakeOptional<Pick<SignupPayload, 'accessToken' | 'refreshToken'>, 'refreshToken'>
    & { error?: Maybe<(
      { __typename?: 'EmailInvalid' }
      & Pick<EmailInvalid, 'message'>
    ) | (
      { __typename?: 'NicknameInvalid' }
      & Pick<NicknameInvalid, 'message'>
    ) | (
      { __typename?: 'PasswordInvalid' }
      & Pick<PasswordInvalid, 'message'>
    )>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'isStaff'>
      & { profile?: Maybe<(
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'id' | 'nickname' | 'profileRating'>
      )> }
    )> }
  ) }
);

export type GetStackQueryVariables = Exact<{
  stackId: Scalars['String'];
}>;


export type GetStackQuery = (
  { __typename?: 'Query' }
  & { stack: (
    { __typename?: 'StackPayload' }
    & { stack: (
      { __typename?: 'Stack' }
      & StackFieldsFragment
    ) }
  ) }
);

export type CreateTradingAddressMutationVariables = Exact<{
  address: TradingAddressInput;
}>;


export type CreateTradingAddressMutation = (
  { __typename?: 'Mutation' }
  & { createTradingAddress: (
    { __typename?: 'CreateTradingAddressPayload' }
    & { error?: Maybe<(
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message' | 'errors'>
    )> }
  ) }
);

export type CreateTradingBankAccountMutationVariables = Exact<{
  bankAccount: TradingBankAccountInput;
}>;


export type CreateTradingBankAccountMutation = (
  { __typename?: 'Mutation' }
  & { createTradingBankAccount: (
    { __typename?: 'CreateTradingBankAccountPayload' }
    & { error?: Maybe<(
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message' | 'errors'>
    )> }
  ) }
);

export type UpsertTradingPartyMutationVariables = Exact<{
  party: TradingPartyInput;
}>;


export type UpsertTradingPartyMutation = (
  { __typename?: 'Mutation' }
  & { upsertTradingParty: (
    { __typename?: 'UpsertTradingPartyPayload' }
    & { error?: Maybe<(
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message' | 'errors'>
    )> }
  ) }
);

export type InitialiseTradingPortfolioMutationVariables = Exact<{
  initialPortfolioIntention: InitialPortfolioIntentionInput;
}>;


export type InitialiseTradingPortfolioMutation = (
  { __typename?: 'Mutation' }
  & { initialiseTradingPortfolio: (
    { __typename?: 'InitialiseTradingPortfolioPayload' }
    & { portfolio?: Maybe<(
      { __typename?: 'TradingPortfolio' }
      & Pick<TradingPortfolio, 'id' | 'status'>
    )>, error?: Maybe<(
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message' | 'errors'>
    )> }
  ) }
);

export type GetVoiceQueryVariables = Exact<{
  id: Scalars['ID'];
  loggedIn: Scalars['Boolean'];
  imgixParams?: Maybe<ImgixInput>;
}>;


export type GetVoiceQuery = (
  { __typename?: 'Query' }
  & { voice: (
    { __typename?: 'DatoUpsideVoice' }
    & Pick<DatoUpsideVoice, 'id' | 'title' | 'excerpt' | 'body' | 'publishDate'>
    & { heroImage?: Maybe<(
      { __typename?: 'DatoImageFile' }
      & Pick<DatoImageFile, 'url'>
    )>, instruments: Array<Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )>>, ideas: Array<Maybe<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )>> }
  ) }
);

export type GetUserPublicIdeasQueryVariables = Exact<{
  statuses?: Maybe<Array<IdeaStatus> | IdeaStatus>;
  userId?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['NonEmptyString']>;
}>;


export type GetUserPublicIdeasQuery = (
  { __typename?: 'Query' }
  & { publicIdeasPaginated: (
    { __typename?: 'IdeaConnection' }
    & { nodes: Array<(
      { __typename?: 'Idea' }
      & CoreIdeaFieldsFragment
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & CorePaginationFieldsFragment
    ) }
  ) }
);

export type GetUserProfileQueryVariables = Exact<{
  userId: Scalars['Int'];
  loggedIn: Scalars['Boolean'];
}>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { publicProfile: (
    { __typename?: 'UserProfile' }
    & CoreUserProfileFieldsFragment
  ), publicInstrumentWatchlist: Array<(
    { __typename?: 'InstrumentWatchlistItem' }
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )> }
  )> }
);

export type GetWatchlistQueryVariables = Exact<{
  loggedIn: Scalars['Boolean'];
}>;


export type GetWatchlistQuery = (
  { __typename?: 'Query' }
  & { instrumentWatchlistItems: Array<(
    { __typename?: 'InstrumentWatchlistItem' }
    & Pick<InstrumentWatchlistItem, 'id'>
    & { instrument?: Maybe<(
      { __typename?: 'Instrument' }
      & CoreInstrumentFieldsFragment
    )> }
  )> }
);

export type RefreshJwtAccessTokenMutationVariables = Exact<{
  refreshToken?: Maybe<Scalars['NonEmptyString']>;
}>;


export type RefreshJwtAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & { refresh: (
    { __typename?: 'RefreshPayload' }
    & Pick<RefreshPayload, 'accessToken'>
    & { error?: Maybe<(
      { __typename?: 'AuthenticationFailed' }
      & Pick<AuthenticationFailed, 'message'>
    ) | (
      { __typename?: 'TokenNotProvided' }
      & Pick<TokenNotProvided, 'message'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutPayload' }
    & { error?: Maybe<(
      { __typename?: 'EmailInvalid' }
      & Pick<EmailInvalid, 'message'>
    ) | (
      { __typename?: 'NicknameInvalid' }
      & Pick<NicknameInvalid, 'message'>
    ) | (
      { __typename?: 'PasswordInvalid' }
      & Pick<PasswordInvalid, 'message'>
    ) | (
      { __typename?: 'AuthenticationFailed' }
      & Pick<AuthenticationFailed, 'message'>
    ) | (
      { __typename?: 'TokenNotProvided' }
      & Pick<TokenNotProvided, 'message'>
    ) | (
      { __typename?: 'UserInvalid' }
      & Pick<UserInvalid, 'message'>
    ) | (
      { __typename?: 'TokenInvalid' }
      & Pick<TokenInvalid, 'message'>
    ) | (
      { __typename?: 'CommentProfane' }
      & Pick<CommentProfane, 'message'>
    ) | (
      { __typename?: 'CommentTooLong' }
      & Pick<CommentTooLong, 'message'>
    ) | (
      { __typename?: 'CommentEmpty' }
      & Pick<CommentEmpty, 'message'>
    ) | (
      { __typename?: 'IdeaAlreadyClosed' }
      & Pick<IdeaAlreadyClosed, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsInvalidDate' }
      & Pick<PrePortfolioStatsInvalidDate, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMemberWeight' }
      & Pick<PrePortfolioStatsNoMemberWeight, 'message'>
    ) | (
      { __typename?: 'PrePortfolioStatsNoMembers' }
      & Pick<PrePortfolioStatsNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioSimulationNoMembers' }
      & Pick<PrePortfolioSimulationNoMembers, 'message'>
    ) | (
      { __typename?: 'PrePortfolioImmutable' }
      & Pick<PrePortfolioImmutable, 'message'>
    ) | (
      { __typename?: 'PrePortfolioMissingField' }
      & Pick<PrePortfolioMissingField, 'message'>
    ) | (
      { __typename?: 'TradingValidationError' }
      & Pick<TradingValidationError, 'message'>
    ) | (
      { __typename?: 'EmptyFile' }
      & Pick<EmptyFile, 'message'>
    ) | (
      { __typename?: 'FileSizeInvalid' }
      & Pick<FileSizeInvalid, 'message'>
    ) | (
      { __typename?: 'FileTypeInvalid' }
      & Pick<FileTypeInvalid, 'message'>
    ) | (
      { __typename?: 'FileNotFound' }
      & Pick<FileNotFound, 'message'>
    )> }
  ) }
);

export type GetCommentsQueryVariables = Exact<{
  ideaId: Scalars['ID'];
  entityType: AppEntityType;
}>;


export type GetCommentsQuery = (
  { __typename?: 'Query' }
  & { getTopic?: Maybe<(
    { __typename?: 'TopicPayload' }
    & { topic?: Maybe<(
      { __typename?: 'Topic' }
      & Pick<Topic, 'commentCount'>
      & { comments?: Maybe<(
        { __typename?: 'CommentConnection' }
        & { nodes: Array<(
          { __typename?: 'Comment' }
          & CoreCommentFieldsFragment
        )>, pageInfo: (
          { __typename?: 'PageInfo' }
          & CorePaginationFieldsFragment
        ) }
      )> }
    )> }
  )> }
);

export type GetRepliesQueryVariables = Exact<{
  parentCommentId: Scalars['ID'];
  ideaId: Scalars['ID'];
}>;


export type GetRepliesQuery = (
  { __typename?: 'Query' }
  & { getComment?: Maybe<(
    { __typename?: 'CommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & { repliesPaginated?: Maybe<(
        { __typename?: 'CommentConnection' }
        & { nodes: Array<(
          { __typename?: 'Comment' }
          & CoreCommentFieldsFragment
        )> }
      )> }
      & CoreCommentFieldsFragment
    )> }
  )> }
);

export const CoreUserProfileFieldsFragmentDoc = gql`
    fragment CoreUserProfileFields on UserProfile {
  id
  nickname
  profileRating
  media {
    id
    profileLg
    profileFull
    profileXl
  }
}
    `;
export const CoreCommentFieldsFragmentDoc = gql`
    fragment CoreCommentFields on Comment {
  id
  replyCount
  user {
    ...CoreUserProfileFields
    ideas(filter: {instrument: {ideaIds: [$ideaId]}}, pagination: {limit: 1}) {
      connection {
        nodes {
          id
          position
          instrument {
            id
            ticker
          }
        }
      }
    }
  }
  text
  status
  created
}
    ${CoreUserProfileFieldsFragmentDoc}`;
export const CoreInstrumentFieldsFragmentDoc = gql`
    fragment CoreInstrumentFields on Instrument {
  id
  name
  ticker
  logoUrl
  displayName
  watchlistId @include(if: $loggedIn)
  quotePrice {
    id
    midPrice
    pctChange1d
    lastUpdated
  }
  closePrice {
    id
    price
  }
  currency {
    id
    iso
  }
}
    `;
export const CoreIdeaFieldsFragmentDoc = gql`
    fragment CoreIdeaFields on Idea {
  id
  targetDate
  targetPrice
  referenceDate
  referencePrice
  position
  conviction
  status
  closeTime
  rationaleTags {
    id
    name
    sortOrder
  }
  instrument {
    id
    name
    ticker
    logoUrl
    quotePrice {
      id
      midPrice
    }
    currency {
      id
      iso
    }
  }
  user {
    id
    nickname
    profileRating
  }
  topic {
    commentCount
  }
  performance {
    id
    actualReturn
    actualPrice
    actualPriceMovement
    priceChangeSincePosted
  }
}
    `;
export const CoreCommentFieldsWithoutActiveIdeaFragmentDoc = gql`
    fragment CoreCommentFieldsWithoutActiveIdea on Comment {
  id
  replyCount
  user {
    id
    nickname
    profileRating
  }
  text
  status
  created
}
    `;
export const FeedCardFieldsFragmentDoc = gql`
    fragment FeedCardFields on FeedItem {
  ... on ReportRatingFeedItem {
    id
    buyCount
    sellCount
    holdCount
    averageRating
    latestRating
    latestPublishDate
    instrument {
      ...CoreInstrumentFields
    }
  }
  ... on CrossFeedItem {
    id
    crossDate
    crossDirection
    instrument {
      ...CoreInstrumentFields
    }
  }
  ... on RelativeStrengthIndexFeedItem {
    id
    eventDate
    value
    rsiStartDate
    isOverbought
    instrument {
      ...CoreInstrumentFields
    }
  }
  ... on CorrelationFeedItem {
    id
    created
    correlation
    windowSizeDays
    subjectInstrument {
      ...CoreInstrumentFields
    }
    objectInstrument {
      ...CoreInstrumentFields
    }
  }
  ... on NewIdeaFeedItem {
    id
    created
    idea {
      ...CoreIdeaFields
    }
  }
  ... on NewsFeedItem {
    id
    news {
      id
      title
      storyText
      source
      publishDate
      imageUrl
      instruments {
        ...CoreInstrumentFields
      }
    }
  }
  ... on SignificantDailyMoverFeedItem {
    id
    date
    latestReturn
    zScore
    instrument {
      ...CoreInstrumentFields
    }
  }
  ... on NetShortDisclosureFeedItem {
    id
    totalReportedPosition
    reportDate
    positions {
      holder
      netPosition
      date
    }
    instrument {
      ...CoreInstrumentFields
    }
  }
  ... on MacdFeedItem {
    id
    created
    direction
    instrument {
      ...CoreInstrumentFields
    }
  }
  ... on NewCommentFeedItem {
    id
    created
    comment {
      ...CoreCommentFieldsWithoutActiveIdea
    }
    idea {
      ...CoreIdeaFields
    }
  }
  ... on UpsideVoiceFeedItem {
    id
    created
    voice {
      id
      title
      excerpt
      body
      publishDate
      heroImage {
        url
      }
      instruments {
        ...CoreInstrumentFields
      }
      ideas {
        id
      }
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}
${CoreIdeaFieldsFragmentDoc}
${CoreCommentFieldsWithoutActiveIdeaFragmentDoc}`;
export const InstrumentStatisticsFragmentDoc = gql`
    fragment InstrumentStatistics on Instrument {
  currentFinancials {
    averageVolume
    dayLow
    dayHigh
    dividendYield
    eps
    fiftyTwoWeekLow
    fiftyTwoWeekHigh
    marketCap
    marketOpen
    nextEarningsDateStart
    nextEarningsDateEnd
    peRatio
    pegRatio
    primaryExchange
  }
}
    `;
export const InstrumentFinancialsFragmentDoc = gql`
    fragment InstrumentFinancials on Financials {
  __typename
  date
  totalRevenue
  costOfRevenue
  grossProfit
  operatingExpenses
  operatingIncome
  nonOperatingIncome
  preTaxIncome
  taxAndOtherDeductions
  netIncome
  netMargin
}
    `;
export const CorePaginationFieldsFragmentDoc = gql`
    fragment CorePaginationFields on PageInfo {
  nextCursor
  totalCount
}
    `;
export const InstrumentEsgFragmentDoc = gql`
    fragment InstrumentEsg on ESG {
  environmentGrade
  environmentLevel
  environmentScore
  socialGrade
  socialLevel
  socialScore
  governanceGrade
  governanceLevel
  governanceScore
  totalGrade
  totalLevel
  totalScore
  publishDate
}
    `;
export const PrePortfolioStatsFragmentDoc = gql`
    fragment PrePortfolioStats on PrePortfolioStatsPayload {
  error {
    ... on PrePortfolioStatsInvalidDate {
      message
    }
    ... on ValidationError {
      message
    }
  }
  data {
    annualisedReturn
    annualisedVolatility
    sharpeRatio
    maximumDrawdown
    correlation
    esg {
      ...InstrumentEsg
    }
    countryCashWeight
    countryWeights {
      country {
        id
        name
      }
      weight
    }
    sectorCashWeight
    sectorWeights {
      sector {
        id
        name
      }
      weight
    }
    marketCapCashWeight
    marketCapWeights {
      marketCap
      weight
    }
    historicalPrices {
      price
      date
    }
  }
}
    ${InstrumentEsgFragmentDoc}`;
export const CorePrePortfolioFieldsFragmentDoc = gql`
    fragment CorePrePortfolioFields on PrePortfolio {
  id
  currency {
    id
    iso
  }
  notional
  cashProportion
  selectedRiskLevel
  lastBuiltDate
  members {
    id
    weight
    instrument {
      id
      name
      ticker
      logoUrl
    }
  }
  stats {
    ...PrePortfolioStats
  }
}
    ${PrePortfolioStatsFragmentDoc}`;
export const CorePrePortfolioSimulationFieldsFragmentDoc = gql`
    fragment CorePrePortfolioSimulationFields on PrePortfolioSimulation {
  id
  riskLevel
  members {
    id
    weight
    instrument {
      id
      name
      ticker
      logoUrl
    }
  }
  stats {
    ...PrePortfolioStats
  }
}
    ${PrePortfolioStatsFragmentDoc}`;
export const StackFieldsFragmentDoc = gql`
    fragment StackFields on Stack {
  id
  content {
    name
    description
    coverImage {
      url
    }
  }
  components {
    pageInfo {
      totalCount
    }
    nodes {
      id
      weight
      instrument {
        id
        ticker
        displayName
        logoUrl
      }
    }
  }
}
    `;
export const CreateIdeaDocument = gql`
    mutation createIdea($idea: CreateIdeaInput!) {
  createIdea(input: $idea) {
    ...CoreIdeaFields
  }
}
    ${CoreIdeaFieldsFragmentDoc}`;
export type CreateIdeaMutationFn = Apollo.MutationFunction<CreateIdeaMutation, CreateIdeaMutationVariables>;

/**
 * __useCreateIdeaMutation__
 *
 * To run a mutation, you first call `useCreateIdeaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdeaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdeaMutation, { data, loading, error }] = useCreateIdeaMutation({
 *   variables: {
 *      idea: // value for 'idea'
 *   },
 * });
 */
export function useCreateIdeaMutation(baseOptions?: Apollo.MutationHookOptions<CreateIdeaMutation, CreateIdeaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIdeaMutation, CreateIdeaMutationVariables>(CreateIdeaDocument, options);
      }
export type CreateIdeaMutationHookResult = ReturnType<typeof useCreateIdeaMutation>;
export type CreateIdeaMutationResult = Apollo.MutationResult<CreateIdeaMutation>;
export type CreateIdeaMutationOptions = Apollo.BaseMutationOptions<CreateIdeaMutation, CreateIdeaMutationVariables>;
export const GetPrePortfolioDocument = gql`
    query getPrePortfolio {
  prePortfolio {
    prePortfolio {
      ...CorePrePortfolioFields
    }
  }
}
    ${CorePrePortfolioFieldsFragmentDoc}`;

/**
 * __useGetPrePortfolioQuery__
 *
 * To run a query within a React component, call `useGetPrePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrePortfolioQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrePortfolioQuery(baseOptions?: Apollo.QueryHookOptions<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>(GetPrePortfolioDocument, options);
      }
export function useGetPrePortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>(GetPrePortfolioDocument, options);
        }
export type GetPrePortfolioQueryHookResult = ReturnType<typeof useGetPrePortfolioQuery>;
export type GetPrePortfolioLazyQueryHookResult = ReturnType<typeof useGetPrePortfolioLazyQuery>;
export type GetPrePortfolioQueryResult = Apollo.QueryResult<GetPrePortfolioQuery, GetPrePortfolioQueryVariables>;
export const CreatePrePortfolioDocument = gql`
    mutation createPrePortfolio($referenceId: ID) {
  createPrePortfolio(referenceId: $referenceId) {
    prePortfolio {
      ...CorePrePortfolioFields
    }
  }
}
    ${CorePrePortfolioFieldsFragmentDoc}`;
export type CreatePrePortfolioMutationFn = Apollo.MutationFunction<CreatePrePortfolioMutation, CreatePrePortfolioMutationVariables>;

/**
 * __useCreatePrePortfolioMutation__
 *
 * To run a mutation, you first call `useCreatePrePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrePortfolioMutation, { data, loading, error }] = useCreatePrePortfolioMutation({
 *   variables: {
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useCreatePrePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrePortfolioMutation, CreatePrePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrePortfolioMutation, CreatePrePortfolioMutationVariables>(CreatePrePortfolioDocument, options);
      }
export type CreatePrePortfolioMutationHookResult = ReturnType<typeof useCreatePrePortfolioMutation>;
export type CreatePrePortfolioMutationResult = Apollo.MutationResult<CreatePrePortfolioMutation>;
export type CreatePrePortfolioMutationOptions = Apollo.BaseMutationOptions<CreatePrePortfolioMutation, CreatePrePortfolioMutationVariables>;
export const UpdatePrePortfolioDocument = gql`
    mutation updatePrePortfolio($prePortfolioId: ID!, $currencyId: ID, $notional: Float, $cashProportion: Float, $selectedRiskLevel: Int, $members: [UpdatePrePortfolioMemberInput!], $built: Boolean) {
  updatePrePortfolio(
    prePortfolioId: $prePortfolioId
    currencyId: $currencyId
    notional: $notional
    cashProportion: $cashProportion
    selectedRiskLevel: $selectedRiskLevel
    members: $members
    built: $built
  ) {
    prePortfolio {
      ...CorePrePortfolioFields
    }
    error {
      ... on PrePortfolioImmutable {
        message
      }
      ... on PrePortfolioMissingField {
        message
        missingField
      }
    }
  }
}
    ${CorePrePortfolioFieldsFragmentDoc}`;
export type UpdatePrePortfolioMutationFn = Apollo.MutationFunction<UpdatePrePortfolioMutation, UpdatePrePortfolioMutationVariables>;

/**
 * __useUpdatePrePortfolioMutation__
 *
 * To run a mutation, you first call `useUpdatePrePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrePortfolioMutation, { data, loading, error }] = useUpdatePrePortfolioMutation({
 *   variables: {
 *      prePortfolioId: // value for 'prePortfolioId'
 *      currencyId: // value for 'currencyId'
 *      notional: // value for 'notional'
 *      cashProportion: // value for 'cashProportion'
 *      selectedRiskLevel: // value for 'selectedRiskLevel'
 *      members: // value for 'members'
 *      built: // value for 'built'
 *   },
 * });
 */
export function useUpdatePrePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrePortfolioMutation, UpdatePrePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrePortfolioMutation, UpdatePrePortfolioMutationVariables>(UpdatePrePortfolioDocument, options);
      }
export type UpdatePrePortfolioMutationHookResult = ReturnType<typeof useUpdatePrePortfolioMutation>;
export type UpdatePrePortfolioMutationResult = Apollo.MutationResult<UpdatePrePortfolioMutation>;
export type UpdatePrePortfolioMutationOptions = Apollo.BaseMutationOptions<UpdatePrePortfolioMutation, UpdatePrePortfolioMutationVariables>;
export const CreatePrePortfolioSimulationsDocument = gql`
    mutation createPrePortfolioSimulations($prePortfolioId: ID!) {
  createPrePortfolioSimulations(prePortfolioId: $prePortfolioId) {
    error {
      ... on PrePortfolioSimulationNoMembers {
        message
      }
    }
  }
}
    `;
export type CreatePrePortfolioSimulationsMutationFn = Apollo.MutationFunction<CreatePrePortfolioSimulationsMutation, CreatePrePortfolioSimulationsMutationVariables>;

/**
 * __useCreatePrePortfolioSimulationsMutation__
 *
 * To run a mutation, you first call `useCreatePrePortfolioSimulationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrePortfolioSimulationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrePortfolioSimulationsMutation, { data, loading, error }] = useCreatePrePortfolioSimulationsMutation({
 *   variables: {
 *      prePortfolioId: // value for 'prePortfolioId'
 *   },
 * });
 */
export function useCreatePrePortfolioSimulationsMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrePortfolioSimulationsMutation, CreatePrePortfolioSimulationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrePortfolioSimulationsMutation, CreatePrePortfolioSimulationsMutationVariables>(CreatePrePortfolioSimulationsDocument, options);
      }
export type CreatePrePortfolioSimulationsMutationHookResult = ReturnType<typeof useCreatePrePortfolioSimulationsMutation>;
export type CreatePrePortfolioSimulationsMutationResult = Apollo.MutationResult<CreatePrePortfolioSimulationsMutation>;
export type CreatePrePortfolioSimulationsMutationOptions = Apollo.BaseMutationOptions<CreatePrePortfolioSimulationsMutation, CreatePrePortfolioSimulationsMutationVariables>;
export const UpdateProfileImageDocument = gql`
    mutation updateProfileImage($image: Upload!) {
  updateProfileImage(image: $image) {
    error {
      ... on EmptyFile {
        message
      }
      ... on FileSizeInvalid {
        message
      }
      ... on FileTypeInvalid {
        message
      }
    }
    user {
      ...CoreUserProfileFields
    }
  }
}
    ${CoreUserProfileFieldsFragmentDoc}`;
export type UpdateProfileImageMutationFn = Apollo.MutationFunction<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>;

/**
 * __useUpdateProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImageMutation, { data, loading, error }] = useUpdateProfileImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>(UpdateProfileImageDocument, options);
      }
export type UpdateProfileImageMutationHookResult = ReturnType<typeof useUpdateProfileImageMutation>;
export type UpdateProfileImageMutationResult = Apollo.MutationResult<UpdateProfileImageMutation>;
export type UpdateProfileImageMutationOptions = Apollo.BaseMutationOptions<UpdateProfileImageMutation, UpdateProfileImageMutationVariables>;
export const DeleteProfileImageDocument = gql`
    mutation deleteProfileImage($mediaId: ID!) {
  deleteProfileImage(mediaId: $mediaId) {
    error {
      ... on FileNotFound {
        message
      }
    }
    user {
      ...CoreUserProfileFields
    }
  }
}
    ${CoreUserProfileFieldsFragmentDoc}`;
export type DeleteProfileImageMutationFn = Apollo.MutationFunction<DeleteProfileImageMutation, DeleteProfileImageMutationVariables>;

/**
 * __useDeleteProfileImageMutation__
 *
 * To run a mutation, you first call `useDeleteProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProfileImageMutation, { data, loading, error }] = useDeleteProfileImageMutation({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useDeleteProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProfileImageMutation, DeleteProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProfileImageMutation, DeleteProfileImageMutationVariables>(DeleteProfileImageDocument, options);
      }
export type DeleteProfileImageMutationHookResult = ReturnType<typeof useDeleteProfileImageMutation>;
export type DeleteProfileImageMutationResult = Apollo.MutationResult<DeleteProfileImageMutation>;
export type DeleteProfileImageMutationOptions = Apollo.BaseMutationOptions<DeleteProfileImageMutation, DeleteProfileImageMutationVariables>;
export const AddIdeaToWatchlistDocument = gql`
    mutation addIdeaToWatchlist($ideaId: ID!) {
  addIdeaToWatchlist(input: {ideaId: $ideaId}) {
    id
    idea {
      id
      watchlistId
    }
  }
}
    `;
export type AddIdeaToWatchlistMutationFn = Apollo.MutationFunction<AddIdeaToWatchlistMutation, AddIdeaToWatchlistMutationVariables>;

/**
 * __useAddIdeaToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddIdeaToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIdeaToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIdeaToWatchlistMutation, { data, loading, error }] = useAddIdeaToWatchlistMutation({
 *   variables: {
 *      ideaId: // value for 'ideaId'
 *   },
 * });
 */
export function useAddIdeaToWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<AddIdeaToWatchlistMutation, AddIdeaToWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIdeaToWatchlistMutation, AddIdeaToWatchlistMutationVariables>(AddIdeaToWatchlistDocument, options);
      }
export type AddIdeaToWatchlistMutationHookResult = ReturnType<typeof useAddIdeaToWatchlistMutation>;
export type AddIdeaToWatchlistMutationResult = Apollo.MutationResult<AddIdeaToWatchlistMutation>;
export type AddIdeaToWatchlistMutationOptions = Apollo.BaseMutationOptions<AddIdeaToWatchlistMutation, AddIdeaToWatchlistMutationVariables>;
export const RemoveIdeaFromWatchlistDocument = gql`
    mutation removeIdeaFromWatchlist($watchlistId: ID!) {
  removeIdeaFromWatchlist(input: {id: $watchlistId}) {
    id
    idea {
      id
      watchlistId
    }
  }
}
    `;
export type RemoveIdeaFromWatchlistMutationFn = Apollo.MutationFunction<RemoveIdeaFromWatchlistMutation, RemoveIdeaFromWatchlistMutationVariables>;

/**
 * __useRemoveIdeaFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveIdeaFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveIdeaFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeIdeaFromWatchlistMutation, { data, loading, error }] = useRemoveIdeaFromWatchlistMutation({
 *   variables: {
 *      watchlistId: // value for 'watchlistId'
 *   },
 * });
 */
export function useRemoveIdeaFromWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveIdeaFromWatchlistMutation, RemoveIdeaFromWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveIdeaFromWatchlistMutation, RemoveIdeaFromWatchlistMutationVariables>(RemoveIdeaFromWatchlistDocument, options);
      }
export type RemoveIdeaFromWatchlistMutationHookResult = ReturnType<typeof useRemoveIdeaFromWatchlistMutation>;
export type RemoveIdeaFromWatchlistMutationResult = Apollo.MutationResult<RemoveIdeaFromWatchlistMutation>;
export type RemoveIdeaFromWatchlistMutationOptions = Apollo.BaseMutationOptions<RemoveIdeaFromWatchlistMutation, RemoveIdeaFromWatchlistMutationVariables>;
export const AddInstrumentToWatchlistDocument = gql`
    mutation addInstrumentToWatchlist($instrumentId: ID!) {
  addInstrumentToWatchlist(input: {instrumentId: $instrumentId}) {
    id
    instrument {
      id
      watchlistId
    }
  }
}
    `;
export type AddInstrumentToWatchlistMutationFn = Apollo.MutationFunction<AddInstrumentToWatchlistMutation, AddInstrumentToWatchlistMutationVariables>;

/**
 * __useAddInstrumentToWatchlistMutation__
 *
 * To run a mutation, you first call `useAddInstrumentToWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInstrumentToWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInstrumentToWatchlistMutation, { data, loading, error }] = useAddInstrumentToWatchlistMutation({
 *   variables: {
 *      instrumentId: // value for 'instrumentId'
 *   },
 * });
 */
export function useAddInstrumentToWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<AddInstrumentToWatchlistMutation, AddInstrumentToWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInstrumentToWatchlistMutation, AddInstrumentToWatchlistMutationVariables>(AddInstrumentToWatchlistDocument, options);
      }
export type AddInstrumentToWatchlistMutationHookResult = ReturnType<typeof useAddInstrumentToWatchlistMutation>;
export type AddInstrumentToWatchlistMutationResult = Apollo.MutationResult<AddInstrumentToWatchlistMutation>;
export type AddInstrumentToWatchlistMutationOptions = Apollo.BaseMutationOptions<AddInstrumentToWatchlistMutation, AddInstrumentToWatchlistMutationVariables>;
export const RemoveInstrumentFromWatchlistDocument = gql`
    mutation removeInstrumentFromWatchlist($watchlistId: ID!) {
  removeInstrumentFromWatchlist(input: {id: $watchlistId}) {
    id
    instrument {
      id
      watchlistId
    }
  }
}
    `;
export type RemoveInstrumentFromWatchlistMutationFn = Apollo.MutationFunction<RemoveInstrumentFromWatchlistMutation, RemoveInstrumentFromWatchlistMutationVariables>;

/**
 * __useRemoveInstrumentFromWatchlistMutation__
 *
 * To run a mutation, you first call `useRemoveInstrumentFromWatchlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveInstrumentFromWatchlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeInstrumentFromWatchlistMutation, { data, loading, error }] = useRemoveInstrumentFromWatchlistMutation({
 *   variables: {
 *      watchlistId: // value for 'watchlistId'
 *   },
 * });
 */
export function useRemoveInstrumentFromWatchlistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveInstrumentFromWatchlistMutation, RemoveInstrumentFromWatchlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveInstrumentFromWatchlistMutation, RemoveInstrumentFromWatchlistMutationVariables>(RemoveInstrumentFromWatchlistDocument, options);
      }
export type RemoveInstrumentFromWatchlistMutationHookResult = ReturnType<typeof useRemoveInstrumentFromWatchlistMutation>;
export type RemoveInstrumentFromWatchlistMutationResult = Apollo.MutationResult<RemoveInstrumentFromWatchlistMutation>;
export type RemoveInstrumentFromWatchlistMutationOptions = Apollo.BaseMutationOptions<RemoveInstrumentFromWatchlistMutation, RemoveInstrumentFromWatchlistMutationVariables>;
export const PostIdeaCommentDocument = gql`
    mutation postIdeaComment($ideaId: ID!, $appEntityType: AppEntityType!, $text: String!, $parent: ID) {
  postComment(
    input: {appEntityId: $ideaId, appEntityType: $appEntityType, commentText: $text, parentCommentId: $parent}
  ) {
    comment {
      ...CoreCommentFields
    }
    error {
      ... on CommentProfane {
        message
      }
      ... on CommentTooLong {
        message
      }
      ... on CommentEmpty {
        message
      }
    }
  }
}
    ${CoreCommentFieldsFragmentDoc}`;
export type PostIdeaCommentMutationFn = Apollo.MutationFunction<PostIdeaCommentMutation, PostIdeaCommentMutationVariables>;

/**
 * __usePostIdeaCommentMutation__
 *
 * To run a mutation, you first call `usePostIdeaCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostIdeaCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postIdeaCommentMutation, { data, loading, error }] = usePostIdeaCommentMutation({
 *   variables: {
 *      ideaId: // value for 'ideaId'
 *      appEntityType: // value for 'appEntityType'
 *      text: // value for 'text'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function usePostIdeaCommentMutation(baseOptions?: Apollo.MutationHookOptions<PostIdeaCommentMutation, PostIdeaCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostIdeaCommentMutation, PostIdeaCommentMutationVariables>(PostIdeaCommentDocument, options);
      }
export type PostIdeaCommentMutationHookResult = ReturnType<typeof usePostIdeaCommentMutation>;
export type PostIdeaCommentMutationResult = Apollo.MutationResult<PostIdeaCommentMutation>;
export type PostIdeaCommentMutationOptions = Apollo.BaseMutationOptions<PostIdeaCommentMutation, PostIdeaCommentMutationVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    user {
      id
      email
      isStaff
      tradingOnboardingFinished
      profile {
        ...CoreUserProfileFields
      }
    }
  }
}
    ${CoreUserProfileFieldsFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetAppContentDocument = gql`
    query getAppContent($id: ID!, $imgixWidth: Int) {
  datoAppContent(id: $id, markdown: false, imgixParams: {width: $imgixWidth}) {
    appContent {
      id
      title
      description
      actionButtonText
      actionButtonDeepLink
      publishedDate
      coverImage {
        url
      }
    }
  }
}
    `;

/**
 * __useGetAppContentQuery__
 *
 * To run a query within a React component, call `useGetAppContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      imgixWidth: // value for 'imgixWidth'
 *   },
 * });
 */
export function useGetAppContentQuery(baseOptions: Apollo.QueryHookOptions<GetAppContentQuery, GetAppContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAppContentQuery, GetAppContentQueryVariables>(GetAppContentDocument, options);
      }
export function useGetAppContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAppContentQuery, GetAppContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAppContentQuery, GetAppContentQueryVariables>(GetAppContentDocument, options);
        }
export type GetAppContentQueryHookResult = ReturnType<typeof useGetAppContentQuery>;
export type GetAppContentLazyQueryHookResult = ReturnType<typeof useGetAppContentLazyQuery>;
export type GetAppContentQueryResult = Apollo.QueryResult<GetAppContentQuery, GetAppContentQueryVariables>;
export const GetBlogDocument = gql`
    query getBlog($id: ID!, $imgixWidth: Int) {
  datoBlog(id: $id, markdown: false, imgixParams: {width: $imgixWidth}) {
    blog {
      id
      title
      slug
      excerpt
      description
      destination
      publishedDate
      coverImage {
        url
      }
    }
  }
}
    `;

/**
 * __useGetBlogQuery__
 *
 * To run a query within a React component, call `useGetBlogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogQuery({
 *   variables: {
 *      id: // value for 'id'
 *      imgixWidth: // value for 'imgixWidth'
 *   },
 * });
 */
export function useGetBlogQuery(baseOptions: Apollo.QueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options);
      }
export function useGetBlogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogQuery, GetBlogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogQuery, GetBlogQueryVariables>(GetBlogDocument, options);
        }
export type GetBlogQueryHookResult = ReturnType<typeof useGetBlogQuery>;
export type GetBlogLazyQueryHookResult = ReturnType<typeof useGetBlogLazyQuery>;
export type GetBlogQueryResult = Apollo.QueryResult<GetBlogQuery, GetBlogQueryVariables>;
export const GetPrePortfolioAndIdeasDocument = gql`
    query getPrePortfolioAndIdeas {
  prePortfolio {
    prePortfolio {
      ...CorePrePortfolioFields
    }
  }
  ideasForPortfolio {
    connection {
      nodes {
        ...CoreIdeaFields
      }
    }
  }
}
    ${CorePrePortfolioFieldsFragmentDoc}
${CoreIdeaFieldsFragmentDoc}`;

/**
 * __useGetPrePortfolioAndIdeasQuery__
 *
 * To run a query within a React component, call `useGetPrePortfolioAndIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrePortfolioAndIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrePortfolioAndIdeasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPrePortfolioAndIdeasQuery(baseOptions?: Apollo.QueryHookOptions<GetPrePortfolioAndIdeasQuery, GetPrePortfolioAndIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrePortfolioAndIdeasQuery, GetPrePortfolioAndIdeasQueryVariables>(GetPrePortfolioAndIdeasDocument, options);
      }
export function useGetPrePortfolioAndIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrePortfolioAndIdeasQuery, GetPrePortfolioAndIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrePortfolioAndIdeasQuery, GetPrePortfolioAndIdeasQueryVariables>(GetPrePortfolioAndIdeasDocument, options);
        }
export type GetPrePortfolioAndIdeasQueryHookResult = ReturnType<typeof useGetPrePortfolioAndIdeasQuery>;
export type GetPrePortfolioAndIdeasLazyQueryHookResult = ReturnType<typeof useGetPrePortfolioAndIdeasLazyQuery>;
export type GetPrePortfolioAndIdeasQueryResult = Apollo.QueryResult<GetPrePortfolioAndIdeasQuery, GetPrePortfolioAndIdeasQueryVariables>;
export const IdeaFormDocument = gql`
    query ideaForm($instrumentId: ID!, $dateFrom: Date) {
  instrument(id: $instrumentId) {
    id
    name
    ticker
    logoUrl
    watchlistId
    type
    countryOfListing {
      id
      name
    }
    closePriceSeries(dateFrom: $dateFrom) {
      id
      price
      date
    }
    closePrice {
      id
      price
    }
    quotePrice {
      id
      midPrice
      lastUpdated
    }
    currency {
      id
      iso
    }
    sector {
      id
      name
    }
    industry {
      id
      name
    }
  }
  ideaRationaleCategories {
    id
    name
    sortOrder
    tags {
      id
      name
      isActive
      sortOrder
    }
  }
}
    `;

/**
 * __useIdeaFormQuery__
 *
 * To run a query within a React component, call `useIdeaFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdeaFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdeaFormQuery({
 *   variables: {
 *      instrumentId: // value for 'instrumentId'
 *      dateFrom: // value for 'dateFrom'
 *   },
 * });
 */
export function useIdeaFormQuery(baseOptions: Apollo.QueryHookOptions<IdeaFormQuery, IdeaFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IdeaFormQuery, IdeaFormQueryVariables>(IdeaFormDocument, options);
      }
export function useIdeaFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdeaFormQuery, IdeaFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IdeaFormQuery, IdeaFormQueryVariables>(IdeaFormDocument, options);
        }
export type IdeaFormQueryHookResult = ReturnType<typeof useIdeaFormQuery>;
export type IdeaFormLazyQueryHookResult = ReturnType<typeof useIdeaFormLazyQuery>;
export type IdeaFormQueryResult = Apollo.QueryResult<IdeaFormQuery, IdeaFormQueryVariables>;
export const GetDiscoverDocument = gql`
    query GetDiscover($loggedIn: Boolean!, $isStaff: Boolean!) {
  stacks @include(if: $isStaff) {
    stacks {
      ...StackFields
    }
  }
  discover {
    id
    itemType
    categoryType
    title
    items(limit: 10) {
      ... on MostWatchlistedFeedItem {
        id
        instrument {
          ...CoreInstrumentFields
        }
      }
      ... on DiscoverCategoryFeedItem {
        id
        instrument {
          ...CoreInstrumentFields
        }
      }
      ... on SectorDiscoverCategoryFeedItem {
        id
        type
        sector {
          id
          name
          logoUrl
        }
      }
      ... on SignificantDailyMoverFeedItem {
        id
        latestReturn
        zScore
        date
        instrument {
          ...CoreInstrumentFields
        }
      }
      ... on CurationsCategoryFeedItem {
        id
        type
        created
        name
        imageUrl
      }
    }
  }
}
    ${StackFieldsFragmentDoc}
${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetDiscoverQuery__
 *
 * To run a query within a React component, call `useGetDiscoverQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoverQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoverQuery({
 *   variables: {
 *      loggedIn: // value for 'loggedIn'
 *      isStaff: // value for 'isStaff'
 *   },
 * });
 */
export function useGetDiscoverQuery(baseOptions: Apollo.QueryHookOptions<GetDiscoverQuery, GetDiscoverQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscoverQuery, GetDiscoverQueryVariables>(GetDiscoverDocument, options);
      }
export function useGetDiscoverLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscoverQuery, GetDiscoverQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscoverQuery, GetDiscoverQueryVariables>(GetDiscoverDocument, options);
        }
export type GetDiscoverQueryHookResult = ReturnType<typeof useGetDiscoverQuery>;
export type GetDiscoverLazyQueryHookResult = ReturnType<typeof useGetDiscoverLazyQuery>;
export type GetDiscoverQueryResult = Apollo.QueryResult<GetDiscoverQuery, GetDiscoverQueryVariables>;
export const GetDiscoverCategoryDocument = gql`
    query getDiscoverCategory($id: ID, $categoryType: DiscoverCategoryType!, $loggedIn: Boolean!) {
  discoverCategory(input: {id: $id, categoryType: $categoryType}) {
    id
    title
    description
    imageUrl
    items {
      ... on MostWatchlistedFeedItem {
        id
        instrument {
          ...CoreInstrumentFields
        }
      }
      ... on DiscoverCategoryFeedItem {
        id
        instrument {
          ...CoreInstrumentFields
        }
      }
      ... on SignificantDailyMoverFeedItem {
        id
        instrument {
          ...CoreInstrumentFields
        }
      }
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetDiscoverCategoryQuery__
 *
 * To run a query within a React component, call `useGetDiscoverCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscoverCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscoverCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      categoryType: // value for 'categoryType'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetDiscoverCategoryQuery(baseOptions: Apollo.QueryHookOptions<GetDiscoverCategoryQuery, GetDiscoverCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDiscoverCategoryQuery, GetDiscoverCategoryQueryVariables>(GetDiscoverCategoryDocument, options);
      }
export function useGetDiscoverCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDiscoverCategoryQuery, GetDiscoverCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDiscoverCategoryQuery, GetDiscoverCategoryQueryVariables>(GetDiscoverCategoryDocument, options);
        }
export type GetDiscoverCategoryQueryHookResult = ReturnType<typeof useGetDiscoverCategoryQuery>;
export type GetDiscoverCategoryLazyQueryHookResult = ReturnType<typeof useGetDiscoverCategoryLazyQuery>;
export type GetDiscoverCategoryQueryResult = Apollo.QueryResult<GetDiscoverCategoryQuery, GetDiscoverCategoryQueryVariables>;
export const GetFeedDocument = gql`
    query getFeed($feedTypes: [FeedItemType!], $filterCategories: [FilterCategory!], $loggedIn: Boolean!) {
  feed(input: {feedTypes: $feedTypes, filterCategories: $filterCategories}) {
    ...FeedCardFields
  }
  instrumentWatchlistItems {
    id
    instrument {
      ...CoreInstrumentFields
    }
  }
}
    ${FeedCardFieldsFragmentDoc}
${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetFeedQuery__
 *
 * To run a query within a React component, call `useGetFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedQuery({
 *   variables: {
 *      feedTypes: // value for 'feedTypes'
 *      filterCategories: // value for 'filterCategories'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetFeedQuery(baseOptions: Apollo.QueryHookOptions<GetFeedQuery, GetFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedQuery, GetFeedQueryVariables>(GetFeedDocument, options);
      }
export function useGetFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedQuery, GetFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedQuery, GetFeedQueryVariables>(GetFeedDocument, options);
        }
export type GetFeedQueryHookResult = ReturnType<typeof useGetFeedQuery>;
export type GetFeedLazyQueryHookResult = ReturnType<typeof useGetFeedLazyQuery>;
export type GetFeedQueryResult = Apollo.QueryResult<GetFeedQuery, GetFeedQueryVariables>;
export const GetFeedItemDocument = gql`
    query getFeedItem($id: ID!, $loggedIn: Boolean!) {
  feedItem(id: $id) {
    ...FeedCardFields
  }
}
    ${FeedCardFieldsFragmentDoc}`;

/**
 * __useGetFeedItemQuery__
 *
 * To run a query within a React component, call `useGetFeedItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetFeedItemQuery(baseOptions: Apollo.QueryHookOptions<GetFeedItemQuery, GetFeedItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeedItemQuery, GetFeedItemQueryVariables>(GetFeedItemDocument, options);
      }
export function useGetFeedItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedItemQuery, GetFeedItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeedItemQuery, GetFeedItemQueryVariables>(GetFeedItemDocument, options);
        }
export type GetFeedItemQueryHookResult = ReturnType<typeof useGetFeedItemQuery>;
export type GetFeedItemLazyQueryHookResult = ReturnType<typeof useGetFeedItemLazyQuery>;
export type GetFeedItemQueryResult = Apollo.QueryResult<GetFeedItemQuery, GetFeedItemQueryVariables>;
export const GetOnboardingSummaryDocument = gql`
    query getOnboardingSummary {
  me {
    user {
      tradingOnboardingFinished
    }
  }
  myTradingParty {
    party {
      id
      addresses {
        id
      }
      bankAccounts {
        id
      }
    }
  }
  myPortfolios {
    portfolios {
      id
      status
    }
  }
  journeySummary {
    summary {
      instrumentWatchlist {
        hasWatchlisted
        watchlistAdditionsBeforeComplete
      }
      idea {
        hasCreatedIdea
        ideaCreationsBeforeComplete
      }
      blueprint {
        hasStartedBlueprint
        hasFinishedBlueprint
        daysStale
      }
    }
  }
}
    `;

/**
 * __useGetOnboardingSummaryQuery__
 *
 * To run a query within a React component, call `useGetOnboardingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOnboardingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOnboardingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOnboardingSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetOnboardingSummaryQuery, GetOnboardingSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOnboardingSummaryQuery, GetOnboardingSummaryQueryVariables>(GetOnboardingSummaryDocument, options);
      }
export function useGetOnboardingSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOnboardingSummaryQuery, GetOnboardingSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOnboardingSummaryQuery, GetOnboardingSummaryQueryVariables>(GetOnboardingSummaryDocument, options);
        }
export type GetOnboardingSummaryQueryHookResult = ReturnType<typeof useGetOnboardingSummaryQuery>;
export type GetOnboardingSummaryLazyQueryHookResult = ReturnType<typeof useGetOnboardingSummaryLazyQuery>;
export type GetOnboardingSummaryQueryResult = Apollo.QueryResult<GetOnboardingSummaryQuery, GetOnboardingSummaryQueryVariables>;
export const GetHomeScreenDataDocument = gql`
    query getHomeScreenData($blogImgixWidth: Int, $appContentImgixWidth: Int, $appContentImgixHeight: Int) {
  myMarketData {
    instruments {
      id
      ticker
      displayName
      name
      quotePrice {
        id
        midPrice
        previousClose
        pctChange1d
      }
    }
  }
  instrumentWatchlistItems {
    id
    instrument {
      id
      ticker
      logoUrl
      quotePrice {
        id
        pctChange1d
      }
    }
  }
  ideasPaginated(filter: {statuses: [ACTIVE]}) {
    nodes {
      ...CoreIdeaFields
    }
  }
  datoAppContents(
    orderBy: [PUBLISHED_DATE_DESC]
    imgixParams: {width: $appContentImgixWidth, height: $appContentImgixHeight}
    pagination: {first: 6}
  ) {
    connection {
      nodes {
        id
        title
        excerpt
        coverImage {
          url
        }
      }
    }
  }
  datoBlogs(
    orderBy: [PUBLISHED_DATE_DESC]
    pagination: {first: 20}
    imgixParams: {width: $blogImgixWidth, height: $blogImgixWidth}
    filter: {destination: {in: [APP, ALL]}}
  ) {
    connection {
      nodes {
        id
        title
        slug
        excerpt
        publishedDate
        coverImage {
          url
        }
      }
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}`;

/**
 * __useGetHomeScreenDataQuery__
 *
 * To run a query within a React component, call `useGetHomeScreenDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeScreenDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeScreenDataQuery({
 *   variables: {
 *      blogImgixWidth: // value for 'blogImgixWidth'
 *      appContentImgixWidth: // value for 'appContentImgixWidth'
 *      appContentImgixHeight: // value for 'appContentImgixHeight'
 *   },
 * });
 */
export function useGetHomeScreenDataQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeScreenDataQuery, GetHomeScreenDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeScreenDataQuery, GetHomeScreenDataQueryVariables>(GetHomeScreenDataDocument, options);
      }
export function useGetHomeScreenDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeScreenDataQuery, GetHomeScreenDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeScreenDataQuery, GetHomeScreenDataQueryVariables>(GetHomeScreenDataDocument, options);
        }
export type GetHomeScreenDataQueryHookResult = ReturnType<typeof useGetHomeScreenDataQuery>;
export type GetHomeScreenDataLazyQueryHookResult = ReturnType<typeof useGetHomeScreenDataLazyQuery>;
export type GetHomeScreenDataQueryResult = Apollo.QueryResult<GetHomeScreenDataQuery, GetHomeScreenDataQueryVariables>;
export const CloseIdeaDocument = gql`
    mutation closeIdea($id: ID!) {
  closeIdea(id: $id) {
    idea {
      ...CoreIdeaFields
    }
    error {
      ... on IdeaAlreadyClosed {
        message
      }
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}`;
export type CloseIdeaMutationFn = Apollo.MutationFunction<CloseIdeaMutation, CloseIdeaMutationVariables>;

/**
 * __useCloseIdeaMutation__
 *
 * To run a mutation, you first call `useCloseIdeaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseIdeaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeIdeaMutation, { data, loading, error }] = useCloseIdeaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseIdeaMutation(baseOptions?: Apollo.MutationHookOptions<CloseIdeaMutation, CloseIdeaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseIdeaMutation, CloseIdeaMutationVariables>(CloseIdeaDocument, options);
      }
export type CloseIdeaMutationHookResult = ReturnType<typeof useCloseIdeaMutation>;
export type CloseIdeaMutationResult = Apollo.MutationResult<CloseIdeaMutation>;
export type CloseIdeaMutationOptions = Apollo.BaseMutationOptions<CloseIdeaMutation, CloseIdeaMutationVariables>;
export const GetIdeaDocument = gql`
    query getIdea($ideaId: ID!, $loggedIn: Boolean!) {
  publicIdea(id: $ideaId) {
    id
    conviction
    status
    user {
      ...CoreUserProfileFields
      ideas(
        filter: {instrument: {ideaIds: [$ideaId]}, statuses: [ACTIVE]}
        pagination: {limit: 1}
      ) {
        connection {
          nodes {
            id
          }
        }
      }
    }
    instrument {
      ...CoreInstrumentFields
    }
    rationaleTags {
      id
      name
      sortOrder
    }
    position
    referenceDate
    referencePrice
    targetDate
    targetPrice
    headline
    description
    closeTime
    performance {
      id
      actualReturn
      actualPrice
      actualPriceMovement
      priceChangeSincePosted
    }
    topic(pagination: {limit: 1}) {
      commentCount
      comments {
        nodes {
          ...CoreCommentFields
        }
      }
    }
    watchlistId @include(if: $loggedIn)
  }
}
    ${CoreUserProfileFieldsFragmentDoc}
${CoreInstrumentFieldsFragmentDoc}
${CoreCommentFieldsFragmentDoc}`;

/**
 * __useGetIdeaQuery__
 *
 * To run a query within a React component, call `useGetIdeaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIdeaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIdeaQuery({
 *   variables: {
 *      ideaId: // value for 'ideaId'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetIdeaQuery(baseOptions: Apollo.QueryHookOptions<GetIdeaQuery, GetIdeaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIdeaQuery, GetIdeaQueryVariables>(GetIdeaDocument, options);
      }
export function useGetIdeaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIdeaQuery, GetIdeaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIdeaQuery, GetIdeaQueryVariables>(GetIdeaDocument, options);
        }
export type GetIdeaQueryHookResult = ReturnType<typeof useGetIdeaQuery>;
export type GetIdeaLazyQueryHookResult = ReturnType<typeof useGetIdeaLazyQuery>;
export type GetIdeaQueryResult = Apollo.QueryResult<GetIdeaQuery, GetIdeaQueryVariables>;
export const GetInPlayPricesDocument = gql`
    query getInPlayPrices($instrumentId: ID!, $dateFrom: Date!, $dateTo: Date!) {
  instrument(id: $instrumentId) {
    id
    closePriceSeries(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      price
      date
    }
  }
}
    `;

/**
 * __useGetInPlayPricesQuery__
 *
 * To run a query within a React component, call `useGetInPlayPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInPlayPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInPlayPricesQuery({
 *   variables: {
 *      instrumentId: // value for 'instrumentId'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useGetInPlayPricesQuery(baseOptions: Apollo.QueryHookOptions<GetInPlayPricesQuery, GetInPlayPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInPlayPricesQuery, GetInPlayPricesQueryVariables>(GetInPlayPricesDocument, options);
      }
export function useGetInPlayPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInPlayPricesQuery, GetInPlayPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInPlayPricesQuery, GetInPlayPricesQueryVariables>(GetInPlayPricesDocument, options);
        }
export type GetInPlayPricesQueryHookResult = ReturnType<typeof useGetInPlayPricesQuery>;
export type GetInPlayPricesLazyQueryHookResult = ReturnType<typeof useGetInPlayPricesLazyQuery>;
export type GetInPlayPricesQueryResult = Apollo.QueryResult<GetInPlayPricesQuery, GetInPlayPricesQueryVariables>;
export const GetAllIdeasDocument = gql`
    query getAllIdeas($statuses: [IdeaStatus!], $cursor: NonEmptyString) {
  publicIdeasPaginated(
    filter: {statuses: $statuses}
    pagination: {cursor: $cursor, limit: 15}
  ) {
    nodes {
      ...CoreIdeaFields
    }
    pageInfo {
      ...CorePaginationFields
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}
${CorePaginationFieldsFragmentDoc}`;

/**
 * __useGetAllIdeasQuery__
 *
 * To run a query within a React component, call `useGetAllIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIdeasQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetAllIdeasQuery(baseOptions?: Apollo.QueryHookOptions<GetAllIdeasQuery, GetAllIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllIdeasQuery, GetAllIdeasQueryVariables>(GetAllIdeasDocument, options);
      }
export function useGetAllIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllIdeasQuery, GetAllIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllIdeasQuery, GetAllIdeasQueryVariables>(GetAllIdeasDocument, options);
        }
export type GetAllIdeasQueryHookResult = ReturnType<typeof useGetAllIdeasQuery>;
export type GetAllIdeasLazyQueryHookResult = ReturnType<typeof useGetAllIdeasLazyQuery>;
export type GetAllIdeasQueryResult = Apollo.QueryResult<GetAllIdeasQuery, GetAllIdeasQueryVariables>;
export const GetUserIdeasDocument = gql`
    query getUserIdeas($statuses: [IdeaStatus!], $cursor: NonEmptyString) {
  ideasPaginated(
    filter: {statuses: $statuses}
    pagination: {cursor: $cursor, limit: 15}
  ) {
    nodes {
      ...CoreIdeaFields
    }
    pageInfo {
      ...CorePaginationFields
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}
${CorePaginationFieldsFragmentDoc}`;

/**
 * __useGetUserIdeasQuery__
 *
 * To run a query within a React component, call `useGetUserIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserIdeasQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserIdeasQuery(baseOptions?: Apollo.QueryHookOptions<GetUserIdeasQuery, GetUserIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserIdeasQuery, GetUserIdeasQueryVariables>(GetUserIdeasDocument, options);
      }
export function useGetUserIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserIdeasQuery, GetUserIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserIdeasQuery, GetUserIdeasQueryVariables>(GetUserIdeasDocument, options);
        }
export type GetUserIdeasQueryHookResult = ReturnType<typeof useGetUserIdeasQuery>;
export type GetUserIdeasLazyQueryHookResult = ReturnType<typeof useGetUserIdeasLazyQuery>;
export type GetUserIdeasQueryResult = Apollo.QueryResult<GetUserIdeasQuery, GetUserIdeasQueryVariables>;
export const GetTrackedIdeasDocument = gql`
    query getTrackedIdeas {
  ideaWatchlistItems {
    id
    idea {
      ...CoreIdeaFields
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}`;

/**
 * __useGetTrackedIdeasQuery__
 *
 * To run a query within a React component, call `useGetTrackedIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrackedIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrackedIdeasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTrackedIdeasQuery(baseOptions?: Apollo.QueryHookOptions<GetTrackedIdeasQuery, GetTrackedIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrackedIdeasQuery, GetTrackedIdeasQueryVariables>(GetTrackedIdeasDocument, options);
      }
export function useGetTrackedIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrackedIdeasQuery, GetTrackedIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrackedIdeasQuery, GetTrackedIdeasQueryVariables>(GetTrackedIdeasDocument, options);
        }
export type GetTrackedIdeasQueryHookResult = ReturnType<typeof useGetTrackedIdeasQuery>;
export type GetTrackedIdeasLazyQueryHookResult = ReturnType<typeof useGetTrackedIdeasLazyQuery>;
export type GetTrackedIdeasQueryResult = Apollo.QueryResult<GetTrackedIdeasQuery, GetTrackedIdeasQueryVariables>;
export const GetInstrumentIdeasDocument = gql`
    query getInstrumentIdeas($statuses: [IdeaStatus!], $instrumentId: ID!, $cursor: NonEmptyString) {
  publicIdeasPaginated(
    filter: {statuses: $statuses, instrumentFields: {id: $instrumentId}}
    pagination: {cursor: $cursor, limit: 15}
  ) {
    nodes {
      ...CoreIdeaFields
    }
    pageInfo {
      ...CorePaginationFields
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}
${CorePaginationFieldsFragmentDoc}`;

/**
 * __useGetInstrumentIdeasQuery__
 *
 * To run a query within a React component, call `useGetInstrumentIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentIdeasQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      instrumentId: // value for 'instrumentId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetInstrumentIdeasQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentIdeasQuery, GetInstrumentIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentIdeasQuery, GetInstrumentIdeasQueryVariables>(GetInstrumentIdeasDocument, options);
      }
export function useGetInstrumentIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentIdeasQuery, GetInstrumentIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentIdeasQuery, GetInstrumentIdeasQueryVariables>(GetInstrumentIdeasDocument, options);
        }
export type GetInstrumentIdeasQueryHookResult = ReturnType<typeof useGetInstrumentIdeasQuery>;
export type GetInstrumentIdeasLazyQueryHookResult = ReturnType<typeof useGetInstrumentIdeasLazyQuery>;
export type GetInstrumentIdeasQueryResult = Apollo.QueryResult<GetInstrumentIdeasQuery, GetInstrumentIdeasQueryVariables>;
export const GetInstrumentDocument = gql`
    query getInstrument($id: ID!, $dateFrom: Date, $feedItemId: ID!, $includeFeedItem: Boolean!, $loggedIn: Boolean!) {
  instrument(id: $id) {
    id
    name
    companyInfo
    ticker
    logoUrl
    watchlistId @include(if: $loggedIn)
    type
    countryOfListing {
      id
      name
    }
    closePriceSeries(dateFrom: $dateFrom) {
      id
      price
      date
    }
    closePrice {
      id
      price
    }
    quotePrice {
      id
      midPrice
      previousClose
      lastUpdated
    }
    currency {
      id
      iso
    }
    sector {
      id
      name
    }
    reportRatingCurrent {
      id
      buyCount
      sellCount
      holdCount
      totalCount
      modeRating
    }
    industry {
      id
      name
    }
    ...InstrumentStatistics
    yearlyFinancials {
      date
      totalRevenue
      netIncome
      netMargin
      costOfRevenue
    }
    esgCurrent {
      ...InstrumentEsg
    }
  }
  ideasPaginated(filter: {instrument: $id, statuses: [ACTIVE]}) @include(if: $loggedIn) {
    nodes {
      id
    }
  }
  feedItem(id: $feedItemId) @include(if: $includeFeedItem) {
    ...FeedCardFields
  }
}
    ${InstrumentStatisticsFragmentDoc}
${InstrumentEsgFragmentDoc}
${FeedCardFieldsFragmentDoc}`;

/**
 * __useGetInstrumentQuery__
 *
 * To run a query within a React component, call `useGetInstrumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateFrom: // value for 'dateFrom'
 *      feedItemId: // value for 'feedItemId'
 *      includeFeedItem: // value for 'includeFeedItem'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetInstrumentQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentQuery, GetInstrumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentQuery, GetInstrumentQueryVariables>(GetInstrumentDocument, options);
      }
export function useGetInstrumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentQuery, GetInstrumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentQuery, GetInstrumentQueryVariables>(GetInstrumentDocument, options);
        }
export type GetInstrumentQueryHookResult = ReturnType<typeof useGetInstrumentQuery>;
export type GetInstrumentLazyQueryHookResult = ReturnType<typeof useGetInstrumentLazyQuery>;
export type GetInstrumentQueryResult = Apollo.QueryResult<GetInstrumentQuery, GetInstrumentQueryVariables>;
export const GetInstrumentFeedDocument = gql`
    query getInstrumentFeed($id: ID!, $filterCategories: [FilterCategory!], $feedTypes: [FeedItemType!], $loggedIn: Boolean!) {
  instrumentFeed(
    input: {instruments: [$id], feedTypes: $feedTypes, filterCategories: $filterCategories, sortMethod: DATE_DESC}
  ) {
    feed {
      ...FeedCardFields
    }
  }
}
    ${FeedCardFieldsFragmentDoc}`;

/**
 * __useGetInstrumentFeedQuery__
 *
 * To run a query within a React component, call `useGetInstrumentFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentFeedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filterCategories: // value for 'filterCategories'
 *      feedTypes: // value for 'feedTypes'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetInstrumentFeedQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentFeedQuery, GetInstrumentFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentFeedQuery, GetInstrumentFeedQueryVariables>(GetInstrumentFeedDocument, options);
      }
export function useGetInstrumentFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentFeedQuery, GetInstrumentFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentFeedQuery, GetInstrumentFeedQueryVariables>(GetInstrumentFeedDocument, options);
        }
export type GetInstrumentFeedQueryHookResult = ReturnType<typeof useGetInstrumentFeedQuery>;
export type GetInstrumentFeedLazyQueryHookResult = ReturnType<typeof useGetInstrumentFeedLazyQuery>;
export type GetInstrumentFeedQueryResult = Apollo.QueryResult<GetInstrumentFeedQuery, GetInstrumentFeedQueryVariables>;
export const GetInstrumentFinancialsDocument = gql`
    query getInstrumentFinancials($id: ID!) {
  instrument(id: $id) {
    id
    ticker
    companyInfo
    yearlyFinancials {
      ...InstrumentFinancials
    }
    quarterlyFinancials {
      ...InstrumentFinancials
    }
  }
}
    ${InstrumentFinancialsFragmentDoc}`;

/**
 * __useGetInstrumentFinancialsQuery__
 *
 * To run a query within a React component, call `useGetInstrumentFinancialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentFinancialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentFinancialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstrumentFinancialsQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentFinancialsQuery, GetInstrumentFinancialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentFinancialsQuery, GetInstrumentFinancialsQueryVariables>(GetInstrumentFinancialsDocument, options);
      }
export function useGetInstrumentFinancialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentFinancialsQuery, GetInstrumentFinancialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentFinancialsQuery, GetInstrumentFinancialsQueryVariables>(GetInstrumentFinancialsDocument, options);
        }
export type GetInstrumentFinancialsQueryHookResult = ReturnType<typeof useGetInstrumentFinancialsQuery>;
export type GetInstrumentFinancialsLazyQueryHookResult = ReturnType<typeof useGetInstrumentFinancialsLazyQuery>;
export type GetInstrumentFinancialsQueryResult = Apollo.QueryResult<GetInstrumentFinancialsQuery, GetInstrumentFinancialsQueryVariables>;
export const GetInstrumentSummaryDocument = gql`
    query getInstrumentSummary($id: ID!) {
  instrument(id: $id) {
    id
    ticker
    companyInfo
    ...InstrumentStatistics
    quotePrice {
      id
      midPrice
      previousClose
    }
    currency {
      id
      iso
    }
    sector {
      id
      name
    }
    industry {
      id
      name
    }
    countryOfListing {
      id
      name
    }
  }
}
    ${InstrumentStatisticsFragmentDoc}`;

/**
 * __useGetInstrumentSummaryQuery__
 *
 * To run a query within a React component, call `useGetInstrumentSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstrumentSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstrumentSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInstrumentSummaryQuery(baseOptions: Apollo.QueryHookOptions<GetInstrumentSummaryQuery, GetInstrumentSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstrumentSummaryQuery, GetInstrumentSummaryQueryVariables>(GetInstrumentSummaryDocument, options);
      }
export function useGetInstrumentSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstrumentSummaryQuery, GetInstrumentSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstrumentSummaryQuery, GetInstrumentSummaryQueryVariables>(GetInstrumentSummaryDocument, options);
        }
export type GetInstrumentSummaryQueryHookResult = ReturnType<typeof useGetInstrumentSummaryQuery>;
export type GetInstrumentSummaryLazyQueryHookResult = ReturnType<typeof useGetInstrumentSummaryLazyQuery>;
export type GetInstrumentSummaryQueryResult = Apollo.QueryResult<GetInstrumentSummaryQuery, GetInstrumentSummaryQueryVariables>;
export const LoginDocument = gql`
    mutation login($email: NonEmptyString!, $password: NonEmptyString!, $web: Boolean!) {
  login(email: $email, password: $password) {
    error {
      ... on AuthenticationFailed {
        message
      }
    }
    accessToken
    refreshToken @skip(if: $web)
    user {
      id
      email
      isStaff
      profile {
        id
        nickname
        profileRating
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      web: // value for 'web'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetMarketInstrumentDocument = gql`
    query getMarketInstrument($id: ID!, $dateFrom: Date) {
  instrument(id: $id) {
    id
    displayName
    ticker
    logoUrl
    companyInfo
    closePriceSeries(dateFrom: $dateFrom) {
      id
      price
      date
    }
    quotePrice {
      id
      midPrice
      previousClose
      lastUpdated
    }
    currency {
      id
      iso
    }
  }
}
    `;

/**
 * __useGetMarketInstrumentQuery__
 *
 * To run a query within a React component, call `useGetMarketInstrumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketInstrumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketInstrumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dateFrom: // value for 'dateFrom'
 *   },
 * });
 */
export function useGetMarketInstrumentQuery(baseOptions: Apollo.QueryHookOptions<GetMarketInstrumentQuery, GetMarketInstrumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketInstrumentQuery, GetMarketInstrumentQueryVariables>(GetMarketInstrumentDocument, options);
      }
export function useGetMarketInstrumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketInstrumentQuery, GetMarketInstrumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketInstrumentQuery, GetMarketInstrumentQueryVariables>(GetMarketInstrumentDocument, options);
        }
export type GetMarketInstrumentQueryHookResult = ReturnType<typeof useGetMarketInstrumentQuery>;
export type GetMarketInstrumentLazyQueryHookResult = ReturnType<typeof useGetMarketInstrumentLazyQuery>;
export type GetMarketInstrumentQueryResult = Apollo.QueryResult<GetMarketInstrumentQuery, GetMarketInstrumentQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: Int!) {
  publicProfile(userId: $id) {
    ...CoreUserProfileFields
  }
  myUpsideShares {
    summary {
      totalValue
      firstIssueDate
      totalShares
    }
  }
}
    ${CoreUserProfileFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetNewsDocument = gql`
    query getNews($id: ID!, $loggedIn: Boolean!) {
  news(id: $id) {
    id
    title
    source
    publishDate
    storyText
    fullTextUrl
    imageUrl
    instruments {
      ...CoreInstrumentFields
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetNewsQuery__
 *
 * To run a query within a React component, call `useGetNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetNewsQuery(baseOptions: Apollo.QueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
      }
export function useGetNewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNewsQuery, GetNewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNewsQuery, GetNewsQueryVariables>(GetNewsDocument, options);
        }
export type GetNewsQueryHookResult = ReturnType<typeof useGetNewsQuery>;
export type GetNewsLazyQueryHookResult = ReturnType<typeof useGetNewsLazyQuery>;
export type GetNewsQueryResult = Apollo.QueryResult<GetNewsQuery, GetNewsQueryVariables>;
export const GetPortfolioInsightsDocument = gql`
    query GetPortfolioInsights($cashProportion: Float!, $components: [ComponentInput!]!) {
  portfolioInsights(cashProportion: $cashProportion, components: $components) {
    insights {
      inceptionToDateReturn
      annualisedReturn
      annualisedVolatility
      volatilities {
        volatility
        instrument {
          id
          displayName
        }
      }
      sharpeRatio
      maximumDrawdown
      correlation
      esg {
        ...InstrumentEsg
      }
      countryCashWeight
      countryWeights {
        country {
          id
          name
        }
        weight
      }
      sectorCashWeight
      sectorWeights {
        sector {
          id
          name
        }
        weight
      }
      marketCapCashWeight
      marketCapWeights {
        weight
        marketCap
      }
    }
  }
}
    ${InstrumentEsgFragmentDoc}`;

/**
 * __useGetPortfolioInsightsQuery__
 *
 * To run a query within a React component, call `useGetPortfolioInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortfolioInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortfolioInsightsQuery({
 *   variables: {
 *      cashProportion: // value for 'cashProportion'
 *      components: // value for 'components'
 *   },
 * });
 */
export function useGetPortfolioInsightsQuery(baseOptions: Apollo.QueryHookOptions<GetPortfolioInsightsQuery, GetPortfolioInsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPortfolioInsightsQuery, GetPortfolioInsightsQueryVariables>(GetPortfolioInsightsDocument, options);
      }
export function useGetPortfolioInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPortfolioInsightsQuery, GetPortfolioInsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPortfolioInsightsQuery, GetPortfolioInsightsQueryVariables>(GetPortfolioInsightsDocument, options);
        }
export type GetPortfolioInsightsQueryHookResult = ReturnType<typeof useGetPortfolioInsightsQuery>;
export type GetPortfolioInsightsLazyQueryHookResult = ReturnType<typeof useGetPortfolioInsightsLazyQuery>;
export type GetPortfolioInsightsQueryResult = Apollo.QueryResult<GetPortfolioInsightsQuery, GetPortfolioInsightsQueryVariables>;
export const RecordOnboardingPortfolioDocument = gql`
    mutation RecordOnboardingPortfolio($email: NonEmptyString!, $portfolio: OnboardingPortfolioInput!, $anonymousId: NonEmptyString!, $emailTemplate: OnboardingEmailTemplate!) {
  recordOnboardingPortfolio(
    email: $email
    portfolio: $portfolio
    anonymousId: $anonymousId
    emailTemplate: $emailTemplate
  ) {
    error {
      message
    }
  }
}
    `;
export type RecordOnboardingPortfolioMutationFn = Apollo.MutationFunction<RecordOnboardingPortfolioMutation, RecordOnboardingPortfolioMutationVariables>;

/**
 * __useRecordOnboardingPortfolioMutation__
 *
 * To run a mutation, you first call `useRecordOnboardingPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordOnboardingPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordOnboardingPortfolioMutation, { data, loading, error }] = useRecordOnboardingPortfolioMutation({
 *   variables: {
 *      email: // value for 'email'
 *      portfolio: // value for 'portfolio'
 *      anonymousId: // value for 'anonymousId'
 *      emailTemplate: // value for 'emailTemplate'
 *   },
 * });
 */
export function useRecordOnboardingPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<RecordOnboardingPortfolioMutation, RecordOnboardingPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecordOnboardingPortfolioMutation, RecordOnboardingPortfolioMutationVariables>(RecordOnboardingPortfolioDocument, options);
      }
export type RecordOnboardingPortfolioMutationHookResult = ReturnType<typeof useRecordOnboardingPortfolioMutation>;
export type RecordOnboardingPortfolioMutationResult = Apollo.MutationResult<RecordOnboardingPortfolioMutation>;
export type RecordOnboardingPortfolioMutationOptions = Apollo.BaseMutationOptions<RecordOnboardingPortfolioMutation, RecordOnboardingPortfolioMutationVariables>;
export const GetBaseStackDocument = gql`
    query GetBaseStack {
  baseStack {
    stack {
      ...StackFields
    }
  }
}
    ${StackFieldsFragmentDoc}`;

/**
 * __useGetBaseStackQuery__
 *
 * To run a query within a React component, call `useGetBaseStackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseStackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseStackQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseStackQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseStackQuery, GetBaseStackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseStackQuery, GetBaseStackQueryVariables>(GetBaseStackDocument, options);
      }
export function useGetBaseStackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseStackQuery, GetBaseStackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseStackQuery, GetBaseStackQueryVariables>(GetBaseStackDocument, options);
        }
export type GetBaseStackQueryHookResult = ReturnType<typeof useGetBaseStackQuery>;
export type GetBaseStackLazyQueryHookResult = ReturnType<typeof useGetBaseStackLazyQuery>;
export type GetBaseStackQueryResult = Apollo.QueryResult<GetBaseStackQuery, GetBaseStackQueryVariables>;
export const GetStacksDocument = gql`
    query GetStacks {
  stacks {
    stacks {
      ...StackFields
    }
  }
}
    ${StackFieldsFragmentDoc}`;

/**
 * __useGetStacksQuery__
 *
 * To run a query within a React component, call `useGetStacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStacksQuery(baseOptions?: Apollo.QueryHookOptions<GetStacksQuery, GetStacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStacksQuery, GetStacksQueryVariables>(GetStacksDocument, options);
      }
export function useGetStacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStacksQuery, GetStacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStacksQuery, GetStacksQueryVariables>(GetStacksDocument, options);
        }
export type GetStacksQueryHookResult = ReturnType<typeof useGetStacksQuery>;
export type GetStacksLazyQueryHookResult = ReturnType<typeof useGetStacksLazyQuery>;
export type GetStacksQueryResult = Apollo.QueryResult<GetStacksQuery, GetStacksQueryVariables>;
export const GetMostWatchlistedDocument = gql`
    query GetMostWatchlisted($loggedIn: Boolean!) {
  discoverCategory(input: {categoryType: MOST_WATCHLISTED}) {
    id
    items(limit: 20) {
      ... on MostWatchlistedFeedItem {
        instrument {
          ...CoreInstrumentFields
        }
      }
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetMostWatchlistedQuery__
 *
 * To run a query within a React component, call `useGetMostWatchlistedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostWatchlistedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostWatchlistedQuery({
 *   variables: {
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetMostWatchlistedQuery(baseOptions: Apollo.QueryHookOptions<GetMostWatchlistedQuery, GetMostWatchlistedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMostWatchlistedQuery, GetMostWatchlistedQueryVariables>(GetMostWatchlistedDocument, options);
      }
export function useGetMostWatchlistedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostWatchlistedQuery, GetMostWatchlistedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMostWatchlistedQuery, GetMostWatchlistedQueryVariables>(GetMostWatchlistedDocument, options);
        }
export type GetMostWatchlistedQueryHookResult = ReturnType<typeof useGetMostWatchlistedQuery>;
export type GetMostWatchlistedLazyQueryHookResult = ReturnType<typeof useGetMostWatchlistedLazyQuery>;
export type GetMostWatchlistedQueryResult = Apollo.QueryResult<GetMostWatchlistedQuery, GetMostWatchlistedQueryVariables>;
export const GetStockSearchDocument = gql`
    query getStockSearch($searchText: NonEmptyString!, $loggedIn: Boolean!) {
  search(searchText: $searchText) {
    instruments {
      nodes {
        ...CoreInstrumentFields
      }
    }
    error {
      message
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetStockSearchQuery__
 *
 * To run a query within a React component, call `useGetStockSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetStockSearchQuery(baseOptions: Apollo.QueryHookOptions<GetStockSearchQuery, GetStockSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockSearchQuery, GetStockSearchQueryVariables>(GetStockSearchDocument, options);
      }
export function useGetStockSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockSearchQuery, GetStockSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockSearchQuery, GetStockSearchQueryVariables>(GetStockSearchDocument, options);
        }
export type GetStockSearchQueryHookResult = ReturnType<typeof useGetStockSearchQuery>;
export type GetStockSearchLazyQueryHookResult = ReturnType<typeof useGetStockSearchLazyQuery>;
export type GetStockSearchQueryResult = Apollo.QueryResult<GetStockSearchQuery, GetStockSearchQueryVariables>;
export const GetSimulationsAndPrePortfolioDocument = gql`
    query getSimulationsAndPrePortfolio($prePortfolioId: ID!) {
  prePortfolio {
    prePortfolio {
      ...CorePrePortfolioFields
    }
  }
  prePortfolioSimulations(prePortfolioId: $prePortfolioId) {
    simulations {
      ...CorePrePortfolioSimulationFields
    }
  }
}
    ${CorePrePortfolioFieldsFragmentDoc}
${CorePrePortfolioSimulationFieldsFragmentDoc}`;

/**
 * __useGetSimulationsAndPrePortfolioQuery__
 *
 * To run a query within a React component, call `useGetSimulationsAndPrePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSimulationsAndPrePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSimulationsAndPrePortfolioQuery({
 *   variables: {
 *      prePortfolioId: // value for 'prePortfolioId'
 *   },
 * });
 */
export function useGetSimulationsAndPrePortfolioQuery(baseOptions: Apollo.QueryHookOptions<GetSimulationsAndPrePortfolioQuery, GetSimulationsAndPrePortfolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSimulationsAndPrePortfolioQuery, GetSimulationsAndPrePortfolioQueryVariables>(GetSimulationsAndPrePortfolioDocument, options);
      }
export function useGetSimulationsAndPrePortfolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSimulationsAndPrePortfolioQuery, GetSimulationsAndPrePortfolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSimulationsAndPrePortfolioQuery, GetSimulationsAndPrePortfolioQueryVariables>(GetSimulationsAndPrePortfolioDocument, options);
        }
export type GetSimulationsAndPrePortfolioQueryHookResult = ReturnType<typeof useGetSimulationsAndPrePortfolioQuery>;
export type GetSimulationsAndPrePortfolioLazyQueryHookResult = ReturnType<typeof useGetSimulationsAndPrePortfolioLazyQuery>;
export type GetSimulationsAndPrePortfolioQueryResult = Apollo.QueryResult<GetSimulationsAndPrePortfolioQuery, GetSimulationsAndPrePortfolioQueryVariables>;
export const GetPrePortfolioSimulationsDocument = gql`
    query getPrePortfolioSimulations($prePortfolioId: ID!) {
  prePortfolioSimulations(prePortfolioId: $prePortfolioId) {
    simulations {
      ...CorePrePortfolioSimulationFields
    }
  }
}
    ${CorePrePortfolioSimulationFieldsFragmentDoc}`;

/**
 * __useGetPrePortfolioSimulationsQuery__
 *
 * To run a query within a React component, call `useGetPrePortfolioSimulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrePortfolioSimulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrePortfolioSimulationsQuery({
 *   variables: {
 *      prePortfolioId: // value for 'prePortfolioId'
 *   },
 * });
 */
export function useGetPrePortfolioSimulationsQuery(baseOptions: Apollo.QueryHookOptions<GetPrePortfolioSimulationsQuery, GetPrePortfolioSimulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrePortfolioSimulationsQuery, GetPrePortfolioSimulationsQueryVariables>(GetPrePortfolioSimulationsDocument, options);
      }
export function useGetPrePortfolioSimulationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrePortfolioSimulationsQuery, GetPrePortfolioSimulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrePortfolioSimulationsQuery, GetPrePortfolioSimulationsQueryVariables>(GetPrePortfolioSimulationsDocument, options);
        }
export type GetPrePortfolioSimulationsQueryHookResult = ReturnType<typeof useGetPrePortfolioSimulationsQuery>;
export type GetPrePortfolioSimulationsLazyQueryHookResult = ReturnType<typeof useGetPrePortfolioSimulationsLazyQuery>;
export type GetPrePortfolioSimulationsQueryResult = Apollo.QueryResult<GetPrePortfolioSimulationsQuery, GetPrePortfolioSimulationsQueryVariables>;
export const GetSearchDocument = gql`
    query getSearch($searchText: NonEmptyString!, $loggedIn: Boolean!) {
  search(searchText: $searchText) {
    instruments {
      nodes {
        ...CoreInstrumentFields
      }
    }
    users {
      nodes {
        ...CoreUserProfileFields
      }
    }
    error {
      message
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}
${CoreUserProfileFieldsFragmentDoc}`;

/**
 * __useGetSearchQuery__
 *
 * To run a query within a React component, call `useGetSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetSearchQuery(baseOptions: Apollo.QueryHookOptions<GetSearchQuery, GetSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchQuery, GetSearchQueryVariables>(GetSearchDocument, options);
      }
export function useGetSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchQuery, GetSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchQuery, GetSearchQueryVariables>(GetSearchDocument, options);
        }
export type GetSearchQueryHookResult = ReturnType<typeof useGetSearchQuery>;
export type GetSearchLazyQueryHookResult = ReturnType<typeof useGetSearchLazyQuery>;
export type GetSearchQueryResult = Apollo.QueryResult<GetSearchQuery, GetSearchQueryVariables>;
export const SignUpDocument = gql`
    mutation SignUp($email: NonEmptyString!, $password: NonEmptyString!, $nickname: NonEmptyString!, $mailingList: Boolean!, $web: Boolean!) {
  signup(
    email: $email
    password: $password
    nickname: $nickname
    mailingList: $mailingList
  ) {
    accessToken
    refreshToken @skip(if: $web)
    error {
      ... on EmailInvalid {
        message
      }
      ... on NicknameInvalid {
        message
      }
      ... on PasswordInvalid {
        message
      }
    }
    user {
      id
      email
      isStaff
      profile {
        id
        nickname
        profileRating
      }
    }
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      nickname: // value for 'nickname'
 *      mailingList: // value for 'mailingList'
 *      web: // value for 'web'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const GetStackDocument = gql`
    query GetStack($stackId: String!) {
  stack(stackId: $stackId) {
    stack {
      ...StackFields
    }
  }
}
    ${StackFieldsFragmentDoc}`;

/**
 * __useGetStackQuery__
 *
 * To run a query within a React component, call `useGetStackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStackQuery({
 *   variables: {
 *      stackId: // value for 'stackId'
 *   },
 * });
 */
export function useGetStackQuery(baseOptions: Apollo.QueryHookOptions<GetStackQuery, GetStackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStackQuery, GetStackQueryVariables>(GetStackDocument, options);
      }
export function useGetStackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStackQuery, GetStackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStackQuery, GetStackQueryVariables>(GetStackDocument, options);
        }
export type GetStackQueryHookResult = ReturnType<typeof useGetStackQuery>;
export type GetStackLazyQueryHookResult = ReturnType<typeof useGetStackLazyQuery>;
export type GetStackQueryResult = Apollo.QueryResult<GetStackQuery, GetStackQueryVariables>;
export const CreateTradingAddressDocument = gql`
    mutation CreateTradingAddress($address: TradingAddressInput!) {
  createTradingAddress(address: $address) {
    error {
      ... on TradingValidationError {
        message
        errors
      }
    }
  }
}
    `;
export type CreateTradingAddressMutationFn = Apollo.MutationFunction<CreateTradingAddressMutation, CreateTradingAddressMutationVariables>;

/**
 * __useCreateTradingAddressMutation__
 *
 * To run a mutation, you first call `useCreateTradingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTradingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTradingAddressMutation, { data, loading, error }] = useCreateTradingAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateTradingAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateTradingAddressMutation, CreateTradingAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTradingAddressMutation, CreateTradingAddressMutationVariables>(CreateTradingAddressDocument, options);
      }
export type CreateTradingAddressMutationHookResult = ReturnType<typeof useCreateTradingAddressMutation>;
export type CreateTradingAddressMutationResult = Apollo.MutationResult<CreateTradingAddressMutation>;
export type CreateTradingAddressMutationOptions = Apollo.BaseMutationOptions<CreateTradingAddressMutation, CreateTradingAddressMutationVariables>;
export const CreateTradingBankAccountDocument = gql`
    mutation CreateTradingBankAccount($bankAccount: TradingBankAccountInput!) {
  createTradingBankAccount(bankAccount: $bankAccount) {
    error {
      ... on TradingValidationError {
        message
        errors
      }
    }
  }
}
    `;
export type CreateTradingBankAccountMutationFn = Apollo.MutationFunction<CreateTradingBankAccountMutation, CreateTradingBankAccountMutationVariables>;

/**
 * __useCreateTradingBankAccountMutation__
 *
 * To run a mutation, you first call `useCreateTradingBankAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTradingBankAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTradingBankAccountMutation, { data, loading, error }] = useCreateTradingBankAccountMutation({
 *   variables: {
 *      bankAccount: // value for 'bankAccount'
 *   },
 * });
 */
export function useCreateTradingBankAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateTradingBankAccountMutation, CreateTradingBankAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTradingBankAccountMutation, CreateTradingBankAccountMutationVariables>(CreateTradingBankAccountDocument, options);
      }
export type CreateTradingBankAccountMutationHookResult = ReturnType<typeof useCreateTradingBankAccountMutation>;
export type CreateTradingBankAccountMutationResult = Apollo.MutationResult<CreateTradingBankAccountMutation>;
export type CreateTradingBankAccountMutationOptions = Apollo.BaseMutationOptions<CreateTradingBankAccountMutation, CreateTradingBankAccountMutationVariables>;
export const UpsertTradingPartyDocument = gql`
    mutation UpsertTradingParty($party: TradingPartyInput!) {
  upsertTradingParty(party: $party) {
    error {
      ... on TradingValidationError {
        message
        errors
      }
    }
  }
}
    `;
export type UpsertTradingPartyMutationFn = Apollo.MutationFunction<UpsertTradingPartyMutation, UpsertTradingPartyMutationVariables>;

/**
 * __useUpsertTradingPartyMutation__
 *
 * To run a mutation, you first call `useUpsertTradingPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTradingPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTradingPartyMutation, { data, loading, error }] = useUpsertTradingPartyMutation({
 *   variables: {
 *      party: // value for 'party'
 *   },
 * });
 */
export function useUpsertTradingPartyMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTradingPartyMutation, UpsertTradingPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTradingPartyMutation, UpsertTradingPartyMutationVariables>(UpsertTradingPartyDocument, options);
      }
export type UpsertTradingPartyMutationHookResult = ReturnType<typeof useUpsertTradingPartyMutation>;
export type UpsertTradingPartyMutationResult = Apollo.MutationResult<UpsertTradingPartyMutation>;
export type UpsertTradingPartyMutationOptions = Apollo.BaseMutationOptions<UpsertTradingPartyMutation, UpsertTradingPartyMutationVariables>;
export const InitialiseTradingPortfolioDocument = gql`
    mutation InitialiseTradingPortfolio($initialPortfolioIntention: InitialPortfolioIntentionInput!) {
  initialiseTradingPortfolio(
    initialPortfolioIntention: $initialPortfolioIntention
  ) {
    portfolio {
      id
      status
    }
    error {
      ... on TradingValidationError {
        message
        errors
      }
    }
  }
}
    `;
export type InitialiseTradingPortfolioMutationFn = Apollo.MutationFunction<InitialiseTradingPortfolioMutation, InitialiseTradingPortfolioMutationVariables>;

/**
 * __useInitialiseTradingPortfolioMutation__
 *
 * To run a mutation, you first call `useInitialiseTradingPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialiseTradingPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialiseTradingPortfolioMutation, { data, loading, error }] = useInitialiseTradingPortfolioMutation({
 *   variables: {
 *      initialPortfolioIntention: // value for 'initialPortfolioIntention'
 *   },
 * });
 */
export function useInitialiseTradingPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<InitialiseTradingPortfolioMutation, InitialiseTradingPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitialiseTradingPortfolioMutation, InitialiseTradingPortfolioMutationVariables>(InitialiseTradingPortfolioDocument, options);
      }
export type InitialiseTradingPortfolioMutationHookResult = ReturnType<typeof useInitialiseTradingPortfolioMutation>;
export type InitialiseTradingPortfolioMutationResult = Apollo.MutationResult<InitialiseTradingPortfolioMutation>;
export type InitialiseTradingPortfolioMutationOptions = Apollo.BaseMutationOptions<InitialiseTradingPortfolioMutation, InitialiseTradingPortfolioMutationVariables>;
export const GetVoiceDocument = gql`
    query getVoice($id: ID!, $loggedIn: Boolean!, $imgixParams: ImgixInput) {
  voice(id: $id) {
    id
    title
    excerpt
    body
    publishDate
    heroImage(imgixParams: $imgixParams) {
      url
    }
    instruments {
      ...CoreInstrumentFields
    }
    ideas {
      ...CoreIdeaFields
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}
${CoreIdeaFieldsFragmentDoc}`;

/**
 * __useGetVoiceQuery__
 *
 * To run a query within a React component, call `useGetVoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      loggedIn: // value for 'loggedIn'
 *      imgixParams: // value for 'imgixParams'
 *   },
 * });
 */
export function useGetVoiceQuery(baseOptions: Apollo.QueryHookOptions<GetVoiceQuery, GetVoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVoiceQuery, GetVoiceQueryVariables>(GetVoiceDocument, options);
      }
export function useGetVoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVoiceQuery, GetVoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVoiceQuery, GetVoiceQueryVariables>(GetVoiceDocument, options);
        }
export type GetVoiceQueryHookResult = ReturnType<typeof useGetVoiceQuery>;
export type GetVoiceLazyQueryHookResult = ReturnType<typeof useGetVoiceLazyQuery>;
export type GetVoiceQueryResult = Apollo.QueryResult<GetVoiceQuery, GetVoiceQueryVariables>;
export const GetUserPublicIdeasDocument = gql`
    query getUserPublicIdeas($statuses: [IdeaStatus!], $userId: Int, $cursor: NonEmptyString) {
  publicIdeasPaginated(
    filter: {userId: $userId, statuses: $statuses}
    pagination: {cursor: $cursor, limit: 15}
  ) {
    nodes {
      ...CoreIdeaFields
    }
    pageInfo {
      ...CorePaginationFields
    }
  }
}
    ${CoreIdeaFieldsFragmentDoc}
${CorePaginationFieldsFragmentDoc}`;

/**
 * __useGetUserPublicIdeasQuery__
 *
 * To run a query within a React component, call `useGetUserPublicIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPublicIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPublicIdeasQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      userId: // value for 'userId'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetUserPublicIdeasQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPublicIdeasQuery, GetUserPublicIdeasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPublicIdeasQuery, GetUserPublicIdeasQueryVariables>(GetUserPublicIdeasDocument, options);
      }
export function useGetUserPublicIdeasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPublicIdeasQuery, GetUserPublicIdeasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPublicIdeasQuery, GetUserPublicIdeasQueryVariables>(GetUserPublicIdeasDocument, options);
        }
export type GetUserPublicIdeasQueryHookResult = ReturnType<typeof useGetUserPublicIdeasQuery>;
export type GetUserPublicIdeasLazyQueryHookResult = ReturnType<typeof useGetUserPublicIdeasLazyQuery>;
export type GetUserPublicIdeasQueryResult = Apollo.QueryResult<GetUserPublicIdeasQuery, GetUserPublicIdeasQueryVariables>;
export const GetUserProfileDocument = gql`
    query getUserProfile($userId: Int!, $loggedIn: Boolean!) {
  publicProfile(userId: $userId) {
    ...CoreUserProfileFields
  }
  publicInstrumentWatchlist(userId: $userId) {
    instrument {
      ...CoreInstrumentFields
    }
  }
}
    ${CoreUserProfileFieldsFragmentDoc}
${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const GetWatchlistDocument = gql`
    query GetWatchlist($loggedIn: Boolean!) {
  instrumentWatchlistItems {
    id
    instrument {
      ...CoreInstrumentFields
    }
  }
}
    ${CoreInstrumentFieldsFragmentDoc}`;

/**
 * __useGetWatchlistQuery__
 *
 * To run a query within a React component, call `useGetWatchlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWatchlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWatchlistQuery({
 *   variables: {
 *      loggedIn: // value for 'loggedIn'
 *   },
 * });
 */
export function useGetWatchlistQuery(baseOptions: Apollo.QueryHookOptions<GetWatchlistQuery, GetWatchlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWatchlistQuery, GetWatchlistQueryVariables>(GetWatchlistDocument, options);
      }
export function useGetWatchlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWatchlistQuery, GetWatchlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWatchlistQuery, GetWatchlistQueryVariables>(GetWatchlistDocument, options);
        }
export type GetWatchlistQueryHookResult = ReturnType<typeof useGetWatchlistQuery>;
export type GetWatchlistLazyQueryHookResult = ReturnType<typeof useGetWatchlistLazyQuery>;
export type GetWatchlistQueryResult = Apollo.QueryResult<GetWatchlistQuery, GetWatchlistQueryVariables>;
export const RefreshJwtAccessTokenDocument = gql`
    mutation refreshJwtAccessToken($refreshToken: NonEmptyString) {
  refresh(refreshToken: $refreshToken) {
    accessToken
    error {
      ... on AuthenticationFailed {
        message
      }
      ... on TokenNotProvided {
        message
      }
    }
  }
}
    `;
export type RefreshJwtAccessTokenMutationFn = Apollo.MutationFunction<RefreshJwtAccessTokenMutation, RefreshJwtAccessTokenMutationVariables>;

/**
 * __useRefreshJwtAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshJwtAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshJwtAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshJwtAccessTokenMutation, { data, loading, error }] = useRefreshJwtAccessTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshJwtAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshJwtAccessTokenMutation, RefreshJwtAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshJwtAccessTokenMutation, RefreshJwtAccessTokenMutationVariables>(RefreshJwtAccessTokenDocument, options);
      }
export type RefreshJwtAccessTokenMutationHookResult = ReturnType<typeof useRefreshJwtAccessTokenMutation>;
export type RefreshJwtAccessTokenMutationResult = Apollo.MutationResult<RefreshJwtAccessTokenMutation>;
export type RefreshJwtAccessTokenMutationOptions = Apollo.BaseMutationOptions<RefreshJwtAccessTokenMutation, RefreshJwtAccessTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout {
    error {
      message
    }
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const GetCommentsDocument = gql`
    query getComments($ideaId: ID!, $entityType: AppEntityType!) {
  getTopic(topicInput: {appEntityType: $entityType, appEntityId: $ideaId}) {
    topic {
      commentCount
      comments {
        nodes {
          ...CoreCommentFields
        }
        pageInfo {
          ...CorePaginationFields
        }
      }
    }
  }
}
    ${CoreCommentFieldsFragmentDoc}
${CorePaginationFieldsFragmentDoc}`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      ideaId: // value for 'ideaId'
 *      entityType: // value for 'entityType'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const GetRepliesDocument = gql`
    query getReplies($parentCommentId: ID!, $ideaId: ID!) {
  getComment(commentInput: {commentId: $parentCommentId}) {
    comment {
      ...CoreCommentFields
      repliesPaginated {
        nodes {
          ...CoreCommentFields
        }
      }
    }
  }
}
    ${CoreCommentFieldsFragmentDoc}`;

/**
 * __useGetRepliesQuery__
 *
 * To run a query within a React component, call `useGetRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepliesQuery({
 *   variables: {
 *      parentCommentId: // value for 'parentCommentId'
 *      ideaId: // value for 'ideaId'
 *   },
 * });
 */
export function useGetRepliesQuery(baseOptions: Apollo.QueryHookOptions<GetRepliesQuery, GetRepliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRepliesQuery, GetRepliesQueryVariables>(GetRepliesDocument, options);
      }
export function useGetRepliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepliesQuery, GetRepliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRepliesQuery, GetRepliesQueryVariables>(GetRepliesDocument, options);
        }
export type GetRepliesQueryHookResult = ReturnType<typeof useGetRepliesQuery>;
export type GetRepliesLazyQueryHookResult = ReturnType<typeof useGetRepliesLazyQuery>;
export type GetRepliesQueryResult = Apollo.QueryResult<GetRepliesQuery, GetRepliesQueryVariables>;
export const namedOperations = {
  Query: {
    getPrePortfolio: 'getPrePortfolio',
    GetMe: 'GetMe',
    getAppContent: 'getAppContent',
    getBlog: 'getBlog',
    getPrePortfolioAndIdeas: 'getPrePortfolioAndIdeas',
    ideaForm: 'ideaForm',
    GetDiscover: 'GetDiscover',
    getDiscoverCategory: 'getDiscoverCategory',
    getFeed: 'getFeed',
    getFeedItem: 'getFeedItem',
    getOnboardingSummary: 'getOnboardingSummary',
    getHomeScreenData: 'getHomeScreenData',
    getIdea: 'getIdea',
    getInPlayPrices: 'getInPlayPrices',
    getAllIdeas: 'getAllIdeas',
    getUserIdeas: 'getUserIdeas',
    getTrackedIdeas: 'getTrackedIdeas',
    getInstrumentIdeas: 'getInstrumentIdeas',
    getInstrument: 'getInstrument',
    getInstrumentFeed: 'getInstrumentFeed',
    getInstrumentFinancials: 'getInstrumentFinancials',
    getInstrumentSummary: 'getInstrumentSummary',
    getMarketInstrument: 'getMarketInstrument',
    GetUser: 'GetUser',
    getNews: 'getNews',
    GetPortfolioInsights: 'GetPortfolioInsights',
    GetBaseStack: 'GetBaseStack',
    GetStacks: 'GetStacks',
    GetMostWatchlisted: 'GetMostWatchlisted',
    getStockSearch: 'getStockSearch',
    getSimulationsAndPrePortfolio: 'getSimulationsAndPrePortfolio',
    getPrePortfolioSimulations: 'getPrePortfolioSimulations',
    getSearch: 'getSearch',
    GetStack: 'GetStack',
    getVoice: 'getVoice',
    getUserPublicIdeas: 'getUserPublicIdeas',
    getUserProfile: 'getUserProfile',
    GetWatchlist: 'GetWatchlist',
    getComments: 'getComments',
    getReplies: 'getReplies'
  },
  Mutation: {
    createIdea: 'createIdea',
    createPrePortfolio: 'createPrePortfolio',
    updatePrePortfolio: 'updatePrePortfolio',
    createPrePortfolioSimulations: 'createPrePortfolioSimulations',
    updateProfileImage: 'updateProfileImage',
    deleteProfileImage: 'deleteProfileImage',
    addIdeaToWatchlist: 'addIdeaToWatchlist',
    removeIdeaFromWatchlist: 'removeIdeaFromWatchlist',
    addInstrumentToWatchlist: 'addInstrumentToWatchlist',
    removeInstrumentFromWatchlist: 'removeInstrumentFromWatchlist',
    postIdeaComment: 'postIdeaComment',
    closeIdea: 'closeIdea',
    login: 'login',
    RecordOnboardingPortfolio: 'RecordOnboardingPortfolio',
    SignUp: 'SignUp',
    CreateTradingAddress: 'CreateTradingAddress',
    CreateTradingBankAccount: 'CreateTradingBankAccount',
    UpsertTradingParty: 'UpsertTradingParty',
    InitialiseTradingPortfolio: 'InitialiseTradingPortfolio',
    refreshJwtAccessToken: 'refreshJwtAccessToken',
    logout: 'logout'
  },
  Fragment: {
    CoreCommentFields: 'CoreCommentFields',
    CoreCommentFieldsWithoutActiveIdea: 'CoreCommentFieldsWithoutActiveIdea',
    FeedCardFields: 'FeedCardFields',
    CoreIdeaFields: 'CoreIdeaFields',
    CoreInstrumentFields: 'CoreInstrumentFields',
    InstrumentStatistics: 'InstrumentStatistics',
    InstrumentFinancials: 'InstrumentFinancials',
    InstrumentEsg: 'InstrumentEsg',
    CorePaginationFields: 'CorePaginationFields',
    PrePortfolioStats: 'PrePortfolioStats',
    CorePrePortfolioFields: 'CorePrePortfolioFields',
    CorePrePortfolioSimulationFields: 'CorePrePortfolioSimulationFields',
    StackFields: 'StackFields',
    CoreUserProfileFields: 'CoreUserProfileFields'
  }
}