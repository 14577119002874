import { VFC } from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Portfolio: VFC<SvgProps> = (props) => {
  return (
    <Svg width={25} height={24} viewBox="0 0 25 25" fill="none" {...props}>
      <Path
        d="M12.571 23c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
        stroke={props.color ?? '#A3A3A3'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.571 17a5 5 0 100-10 5 5 0 000 10zM17.571 12h6M12.571 1v6M8.871 15.4l-4.4 4.1"
        stroke={props.color ?? '#A3A3A3'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export const PortfolioFilled: VFC<SvgProps> = (props) => {
  return (
    <Svg width={25} height={24} viewBox="0 0 25 25" fill="none" {...props}>
      <Path
        d="M12.571 23c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11z"
        fill="#CCFBF1"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.571 17a5 5 0 100-10 5 5 0 000 10z"
        fill="#fff"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M17.571 12h6z" fill="#CCFBF1" />
      <Path d="M17.571 12h6" stroke="#0F766E" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M12.571 1v6z" fill="#CCFBF1" />
      <Path d="M12.571 1v6" stroke="#0F766E" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M8.871 15.4l-4.4 4.1z" fill="#CCFBF1" />
      <Path d="M8.871 15.4l-4.4 4.1" stroke="#0F766E" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};
