import { BottomTabScreenProps, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigatorScreenParams } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import {
  Bulb,
  BulbFilled,
  Discover,
  DiscoverFilled,
  Eye,
  EyeFilled,
  Feed,
  FeedFilled,
  Home,
  HomeFilled,
  Portfolio,
  PortfolioFilled,
  Wallet,
  WalletFilled,
} from '../old/icons';
import { colors, useTailwind } from '../theme';
import { Text } from '../ui/Text';
import { usePersistedStore } from '../zustand/store';
import { AccountNavigator, AccountStackParamList } from './AccountNavigator';
import { BlueprintNavigator, PortfolioStackParamList } from './BlueprintNavigator';
import { DiscoverNavigator, DiscoverStackParamList } from './DiscoverNavigator';
import { FeedNavigator, FeedStackParamList } from './FeedNavigator';
import { HomeNavigator, HomeStackParamList } from './HomeNavigator';
import { IdeasNavigator, IdeasStackParamList } from './IdeasNavigator';
import { LoggedInStackParamList } from './RootStackNavigator';
import { WatchlistNavigator, WatchlistStackParamList } from './WatchlistNavigator';

export type TabsParamList = {
  HomeTab: NavigatorScreenParams<HomeStackParamList>;
  FeedTab: NavigatorScreenParams<FeedStackParamList>;
  DiscoverTab: NavigatorScreenParams<DiscoverStackParamList>;
  WatchlistTab: NavigatorScreenParams<WatchlistStackParamList>;
  IdeasTab: NavigatorScreenParams<IdeasStackParamList>;
  BlueprintTab: NavigatorScreenParams<PortfolioStackParamList>;
  AccountTab: NavigatorScreenParams<AccountStackParamList>;
};

const BottomTab = createBottomTabNavigator<TabsParamList>();

type Props = BottomTabScreenProps<LoggedInStackParamList>;

/**
 * Logged in tabs
 */
export const TabsNavigator: React.FC<Props> = () => {
  const tailwind = useTailwind();
  const { charlieMode } = usePersistedStore(({ charlieMode }) => ({ charlieMode }));

  return (
    <View style={tailwind('flex-grow items-center bg-white')}>
      <View style={[tailwind('flex-grow'), { maxWidth: 800, width: '100%' }]}>
        <BottomTab.Navigator screenOptions={{ headerShown: false, tabBarLabelPosition: 'below-icon' }}>
          <BottomTab.Screen
            name="HomeTab"
            component={HomeNavigator}
            options={{
              tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Home" />,
              tabBarIcon: ({ focused }) =>
                focused ? <HomeFilled height={24} width={24} /> : <Home height={24} width={24} />,
            }}
          />
          <BottomTab.Screen
            name="FeedTab"
            component={FeedNavigator}
            options={{
              tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Feed" />,
              tabBarIcon: ({ focused }) =>
                focused ? (
                  <FeedFilled height={24} width={24} />
                ) : (
                  <Feed height={24} width={24} color={colors.trueGray[400]} />
                ),
            }}
          />
          <BottomTab.Screen
            name="DiscoverTab"
            component={DiscoverNavigator}
            options={{
              tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Discover" />,
              tabBarIcon: ({ focused }) =>
                focused ? (
                  <DiscoverFilled height={24} width={24} />
                ) : (
                  <Discover height={24} width={24} color={colors.trueGray[400]} />
                ),
            }}
          />
          <BottomTab.Screen
            name="WatchlistTab"
            component={WatchlistNavigator}
            options={{
              tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Watchlist" />,
              tabBarIcon: ({ focused }) =>
                focused ? (
                  <EyeFilled height={24} width={24} />
                ) : (
                  <Eye height={24} width={24} color={colors.trueGray[400]} />
                ),
            }}
          />
          {!charlieMode && (
            <BottomTab.Screen
              name="IdeasTab"
              component={IdeasNavigator}
              options={{
                tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Ideas" />,
                tabBarIcon: ({ focused }) =>
                  focused ? (
                    <BulbFilled height={24} width={24} />
                  ) : (
                    <Bulb height={24} width={24} color={colors.trueGray[400]} />
                  ),
              }}
            />
          )}
          {!charlieMode && (
            <BottomTab.Screen
              name="BlueprintTab"
              component={BlueprintNavigator}
              options={{
                tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Portfolio" />,
                tabBarIcon: ({ focused }) =>
                  focused ? <PortfolioFilled height={24} width={24} /> : <Portfolio height={24} width={24} />,
              }}
            />
          )}
          {charlieMode && (
            <BottomTab.Screen
              name="AccountTab"
              component={AccountNavigator}
              options={{
                tabBarLabel: ({ focused }) => <TabBarLabel focused={focused} label="Account" />,
                tabBarIcon: ({ focused }) =>
                  focused ? <WalletFilled height={24} width={24} /> : <Wallet height={24} width={24} />,
              }}
            />
          )}
        </BottomTab.Navigator>
      </View>
    </View>
  );
};

const TabBarLabel: React.FC<{ focused?: boolean; label: string }> = ({ focused, label }) => {
  const tailwind = useTailwind();
  return (
    <Text style={[tailwind(`text-warmGray-500 ${focused ? 'text-teal-800' : 'text-warmGray-500'}`), { fontSize: 10 }]}>
      {label}
    </Text>
  );
};
