import { gql } from '@apollo/client';
import { BottomTabNavigationProp, BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, CompositeScreenProps, useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { useRef, useState, VFC } from 'react';
import { RefreshControl, View, ViewStyle } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import { CORE_PRE_PORTFOLIO_FIELDS } from '../../fragments/prePortfolio';
import { useGetPrePortfolioAndIdeasQuery } from '../../generated/graphql';
import { useCreatePrePortfolio } from '../../hooks/mutations/preportfolio';
import { useRefresh } from '../../hooks/useRefresh';
import { PortfolioStackParamList } from '../../navigation/BlueprintNavigator';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { ActionCard } from '../../old/ActionCard/ActionCard';
import { isItemInView } from '../../old/EsgScores/util';
import { PortfolioBody } from '../../old/PortfolioBody';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { TitleBarMainIcons } from '../../old/TitleBarMainIcons';
import { EXTERNAL_URIs } from '../../services/externalURIs';
import { visitExternalLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { formatPrice } from '../../util/number';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export const GET_PREPORTFOLIO_AND_IDEAS = gql`
  ${CORE_IDEA_FIELDS}
  ${CORE_PRE_PORTFOLIO_FIELDS}
  query getPrePortfolioAndIdeas {
    prePortfolio {
      prePortfolio {
        ...CorePrePortfolioFields
      }
    }
    ideasForPortfolio {
      connection {
        nodes {
          ...CoreIdeaFields
        }
      }
    }
  }
`;

export type Props = CompositeScreenProps<
  NativeStackScreenProps<PortfolioStackParamList>,
  CompositeScreenProps<
    BottomTabScreenProps<TabsParamList, 'BlueprintTab'>,
    NativeStackScreenProps<LoggedInStackParamList>
  >
>;

type PortfolioNavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<PortfolioStackParamList>,
  CompositeNavigationProp<
    BottomTabNavigationProp<TabsParamList, 'BlueprintTab'>,
    NativeStackNavigationProp<LoggedInStackParamList>
  >
>;

export const Blueprint: VFC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const { data, refetch, loading: queryLoading, error } = useGetPrePortfolioAndIdeasQuery();
  const [esgViewed, setEsgViewed] = useState(false);
  const esgRef = useRef<View>(null);
  const [createPrePortfolio, { loading: createLoading }] = useCreatePrePortfolio({
    onCompleted: () => {
      navigation.navigate('PrePortfolioSelectIdeas');
    },
    onError: (e) => {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    },
  });

  if (error) {
    throw error;
  }

  const loading = createLoading || queryLoading;

  const ideasForPortfolio = data?.ideasForPortfolio?.connection?.nodes;
  const hasIdeasForPortfolio = ideasForPortfolio && ideasForPortfolio.length > 0;
  const prePortfolio = data?.prePortfolio.prePortfolio;

  const onEdit = () => {
    if (!prePortfolio) {
      console.error('Tried to edit portfolio without portfolio id');
      return;
    }

    createPrePortfolio({ variables: { referenceId: prePortfolio.id } });
  };

  const onBuildPortfolio = () => {
    createPrePortfolio();
  };

  const { refreshing, onRefresh } = useRefresh(refetch);

  return (
    <SafeAreaView edges={['top', 'left', 'right']} style={tailwind('flex-grow')}>
      <TitleBar title="Portfolio" showLogo hideBackButton endAdornment={<TitleBarMainIcons />} />
      <ScrollView
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        scrollEventThrottle={500}
        onScroll={() => {
          isItemInView(esgRef, 0.3, () => {
            if (!esgViewed) {
              setEsgViewed(true);
            }
          });
        }}
      >
        {loading ? (
          <ScreenSidePadding style={tailwind('flex-grow pt-2')}>
            <PortfolioSkeleton />
          </ScreenSidePadding>
        ) : prePortfolio?.lastBuiltDate ? (
          <>
            <ScreenSidePadding>
              <Text style={tailwind('font-semibold text-lg pt-2')}>Blueprint</Text>
              <Text style={tailwind('pt-2 pb-6 text-sm')}>
                Built on {formatDate(prePortfolio.lastBuiltDate ?? '', 'Do MMMM YYYY')} based on a{' '}
                {formatPrice(prePortfolio.notional ?? 0, prePortfolio.currency?.iso ?? '', { mantissa: 0 })} investment.
              </Text>
            </ScreenSidePadding>
            <PortfolioBody
              notional={prePortfolio.notional ?? null}
              onEdit={onEdit}
              prePortfolio={prePortfolio}
              members={prePortfolio.members ?? []}
              statsPayload={prePortfolio.stats}
              esgViewed={esgViewed}
              ref={esgRef}
              isPortfolioScreen
            />
            <ScreenSidePadding style={tailwind('pb-6 pt-4')}>
              <TradingSurveyActionCard style={tailwind('bg-teal-500')} />
            </ScreenSidePadding>
          </>
        ) : prePortfolio ? (
          <ScreenSidePadding style={tailwind('flex-grow pt-2')}>
            <ResumePrePortfolioActionCard />
          </ScreenSidePadding>
        ) : !hasIdeasForPortfolio ? (
          <ScreenSidePadding style={tailwind('flex-grow pt-2')}>
            <NoBullishIdeasActionCard />
          </ScreenSidePadding>
        ) : (
          <ScreenSidePadding style={tailwind('flex-grow pt-2')}>
            <BuildPortfolioActionCard onPressAction={onBuildPortfolio} actionLoading={createLoading} />
          </ScreenSidePadding>
        )}
      </ScrollView>
    </SafeAreaView>
  );
});

const BuildPortfolioActionCard: React.VFC<{ onPressAction: () => void; actionLoading?: boolean }> = ({
  onPressAction,
  actionLoading,
}) => {
  return (
    <ActionCard
      title="Build a better Portfolio"
      actionText="Let's do it"
      actionLoading={actionLoading}
      onPressAction={onPressAction}
    >
      Use our Portfolio builder to create a Portfolio Blueprint based on your Upside Ideas.
    </ActionCard>
  );
};

const ResumePrePortfolioActionCard: React.VFC = () => {
  const navigation = useNavigation<PortfolioNavigationProps>();
  return (
    <ActionCard
      title="Resume building your Portfolio blueprint"
      actionText="Let's do it"
      onPressAction={() => navigation.navigate('PrePortfolioSelectIdeas')}
    >
      Pick up where you left off and we&apos;ll help you build your Portfolio Blueprint.
    </ActionCard>
  );
};

const NoBullishIdeasActionCard: React.VFC = () => {
  const navigation = useNavigation<PortfolioNavigationProps>();

  return (
    <ActionCard
      title="Initiate an Idea"
      actionText="Go to Watchlist"
      onPressAction={() => navigation.navigate('WatchlistTab', { screen: 'Watchlist' })}
    >
      We{"'"}ll help you build a portfolio based on your Upside bullish Ideas. Create one from your Watchlist first, and
      come back to build your Portfolio Blueprint
    </ActionCard>
  );
};

// TODO: Implement link to survey
const TradingSurveyActionCard: React.VFC<{ style?: ViewStyle }> = ({ style }) => {
  return (
    <ActionCard
      title="We're building trading"
      actionText="Register for trading"
      onPressAction={() => visitExternalLink(EXTERNAL_URIs.TRADING_SURVEY)}
      style={style}
    >
      Interested in trading this Portfolio? Help us shape trading at Upside.
    </ActionCard>
  );
};

const PortfolioSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView>
      <Skeleton style={tailwind('h-32')} />
    </SkeletonView>
  );
};
