/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Currency } from '../models/Currency';
import type { FxRate } from '../models/FxRate';
import { request as __request } from '../core/request';

export class CurrencyService {

    /**
     * @returns Currency
     * @throws ApiError
     */
    public static async apiCurrencyList({
        uuids,
        iso3Code,
        isActive,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
        iso3Code?: string,
        isActive?: string,
    }): Promise<Array<Currency>> {
        const result = await __request({
            method: 'GET',
            path: `/api/currency/`,
            query: {
                'uuids': uuids,
                'iso3_code': iso3Code,
                'is_active': isActive,
            },
        });
        return result.body;
    }

    /**
     * @returns Currency
     * @throws ApiError
     */
    public static async apiCurrencyRead({
        uuid,
    }: {
        uuid: string,
    }): Promise<Currency> {
        const result = await __request({
            method: 'GET',
            path: `/api/currency/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns FxRate
     * @throws ApiError
     */
    public static async apiFxRateRead({
        fromIso3,
        toIso3,
    }: {
        fromIso3: string,
        toIso3: string,
    }): Promise<FxRate> {
        const result = await __request({
            method: 'GET',
            path: `/api/fx-rate/`,
            query: {
                'from_iso3': fromIso3,
                'to_iso3': toIso3,
            },
        });
        return result.body;
    }

}
