import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Faq: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path d="M12 19a1 1 0 100-2 1 1 0 000 2z" fill="#0F766E" />
      <Path
        d="M9.853 6.56c1.98-.875 4.542-.771 5.417.645s.271 3.063-1.229 4.334C12.541 12.81 12 13.5 12 14.5"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
