import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

export const CircleTick: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#prefix__clip0_280_8101)">
        <Path
          d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22a10 10 0 1010 10A10.011 10.011 0 0012 2z"
          fill="#0F766E"
        />
        <Path
          clipPath="url(#tickMask)"
          d="M16.725 7.725L9.75 14.7l-2.475-2.475c-.3-.3-.75-.3-1.05 0-.3.3-.3.75 0 1.05l3 3c.15.15.3.225.525.225a.68.68 0 00.525-.225l7.5-7.5c.3-.3.3-.75 0-1.05-.3-.3-.75-.3-1.05 0z"
          fill="#0F766E"
        />
      </G>
    </Svg>
  );
};
