import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { Comment as CommentData } from '../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { Divider } from '../Divider';
import { ChevronRight } from '../icons/ChevronRight';
import { Comment } from './Comment';
import { EmptyComments } from './EmptyComments';

export type Props = {
  commentCount: number;
  comment: Pick<CommentData, 'id' | 'text' | 'user' | 'replyCount' | 'created'> | undefined;
  onPress: () => void;
  /** which idea id this summary is shown on so dont navigate to same idea if press on idea flair chip */
  ideaId: string;
};

export const CommentsSummary: React.VFC<Props> = ({ commentCount, comment, onPress, ideaId }) => {
  const tailwind = useTailwind();
  const activeIdea = comment?.user?.ideas?.connection?.nodes?.[0];
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <>
      <Pressable accessibilityRole="button" onPress={onPress} pointerEvents="box-only">
        <Divider />
        <View style={tailwind('flex-row justify-between items-center px-6 pt-3')}>
          <View style={tailwind('flex-row items-center')}>
            <Text style={tailwind('text-lg font-semibold text-warmGray-800 pr-3')}>Comments</Text>
            <Text style={tailwind('text-lg font-normal text-warmGray-500')}>{commentCount}</Text>
          </View>
          <View>
            <ChevronRight height={12} width={12} style={{ transform: [{ rotate: '270deg' }] }} />
            <ChevronRight height={12} width={12} style={{ transform: [{ rotate: '90deg' }] }} />
          </View>
        </View>
      </Pressable>
      {comment ? (
        <Comment
          key={comment.id}
          created={comment.created}
          text={comment.text}
          user={comment.user}
          replyCount={comment.replyCount}
          activeIdea={activeIdea}
          showRepliesLink={false}
          onCommentTextPress={onPress}
          onUserProfilePress={() => navigation.push('UserProfile', { userId: comment.user?.id ?? 0 })}
          onFlairPress={() => {
            // don't navigate anywhere if press on idea flair chip on current idea screen you're on
            if (ideaId !== activeIdea?.id) {
              navigation.push('Idea', { ideaId: activeIdea?.id ?? '' });
            }
          }}
        />
      ) : (
        <EmptyComments commentType="comments" style={tailwind('py-4')} />
      )}
      <Divider />
    </>
  );
};
