/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserProfileSerializer } from '../models/UserProfileSerializer';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @returns UserProfileSerializer
     * @throws ApiError
     */
    public static async apiUserList({
        userIds,
        ideaId,
    }: {
        /** Multiple values may be separated by commas. **/
        userIds?: number,
        ideaId?: string,
    }): Promise<Array<UserProfileSerializer>> {
        const result = await __request({
            method: 'GET',
            path: `/api/user/`,
            query: {
                'user_ids': userIds,
                'idea_id': ideaId,
            },
        });
        return result.body;
    }

    /**
     * @returns UserProfileSerializer
     * @throws ApiError
     */
    public static async apiUserSearchList({
        nickname,
        maxResults,
    }: {
        nickname: string,
        /** Max search results **/
        maxResults?: number,
    }): Promise<Array<UserProfileSerializer>> {
        const result = await __request({
            method: 'GET',
            path: `/api/user/search/`,
            query: {
                'nickname': nickname,
                'max_results': maxResults,
            },
        });
        return result.body;
    }

    /**
     * @returns UserProfileSerializer
     * @throws ApiError
     */
    public static async apiUserRead({
        id,
    }: {
        /** A unique integer value identifying this user. **/
        id: number,
    }): Promise<UserProfileSerializer> {
        const result = await __request({
            method: 'GET',
            path: `/api/user/${id}/`,
        });
        return result.body;
    }

}
