import { MotiView, OnDidAnimate, StyleValueWithReplacedTransforms } from 'moti';
import React, { useCallback, useState } from 'react';
import { ImageStyle, StyleProp, TextStyle, View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useLayout } from '../../hooks/useLayout';
import { colors, shadowL, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { Button } from '../Button';

/**
 * Dummy object used to prepend the tutorial card inside flatlists
 */
export const tutorialCardDummy = { isTutorialCard: true, id: `tutorial` };
export type TutorialCardDummy = typeof tutorialCardDummy;

/**
 * Ensure a flatList item is a tutorial card
 */
export const isTutorialCardDummy = (it: Record<string, unknown>): it is TutorialCardDummy => 'isTutorialCard' in it;

type Props = {
  onDismiss: () => void;
  title: string;
  /** optional action text to show action button */
  actionText?: string;
  onPressAction?: () => void;
  style?: StyleProp<ViewStyle>;
  Icon?: React.FC<SvgProps>;
};

/**
 * Tutorial card component.
 * Animation isn't done as Moti exit animation as it's not a direct descendant of AnimatedPresence when used in Flatlists.
 * dismissPressed triggers two consecutive animations, fade-out-up, and then a height change to 0. Height cleanup animation is needed for when the tutorial is displayed in flatlists.
 *
 * OnDismiss prop is called after animation completes, so tutorial card can be unmounted when the animation is finished instead of interrupting the transition
 *
 * @returns Tutorial Card Component
 */
export const TutorialCard: React.FC<Props> = ({
  onDismiss,
  title,
  style,
  children,
  Icon,
  onPressAction,
  actionText,
}) => {
  const tailwind = useTailwind();
  const [dismissPressed, setDismissPressed] = useState(false);
  const [{ height }, onLayout] = useLayout();

  const onDidAnimate: OnDidAnimate<StyleValueWithReplacedTransforms<ViewStyle | ImageStyle | TextStyle>> = (
    property,
    finished,
    value,
    attemptedValue,
  ) => {
    const exitAnimationFinished =
      finished && property === 'height' && 'attemptedValue' in attemptedValue && attemptedValue.attemptedValue == 0;

    if (exitAnimationFinished && dismissPressed) {
      onDismiss();
    }
  };

  const onPress = useCallback(() => {
    setDismissPressed(true);
    if (onPressAction) {
      onPressAction();
    }
  }, [setDismissPressed, onPressAction]);

  return (
    <MotiView
      style={style}
      transition={{
        type: 'timing',
      }}
      delay={300}
      animate={{ height: dismissPressed ? 0 : height !== 0 ? height : undefined }}
      onDidAnimate={onDidAnimate}
      onLayout={onLayout}
    >
      <MotiView
        animate={{
          opacity: dismissPressed ? 0 : 1,
          translateY: dismissPressed ? -50 : 0,
        }}
        transition={{
          type: 'timing',
        }}
        style={[shadowL, { backgroundColor: colors.deepGreen }, tailwind('rounded-lg')]}
      >
        <View style={tailwind('py-3 px-6')}>
          {Icon && (
            <View style={tailwind('items-center pt-1')}>
              <Icon height={24} width={24} color={colors.white} />
            </View>
          )}
          <Text style={tailwind('text-white text-center text-base font-bold py-3')}>{title}</Text>
          <Text style={tailwind('text-white text-center text-sm')}>{children}</Text>
          <View style={tailwind('py-3')}>
            <Button text={actionText ?? 'Got it'} size="md" variant="inverted" onPress={onPress} />
          </View>
        </View>
      </MotiView>
    </MotiView>
  );
};
