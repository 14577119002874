/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Curation } from '../models/Curation';
import type { CurationDetail } from '../models/CurationDetail';
import type { CurationDiscoverCategory } from '../models/CurationDiscoverCategory';
import type { CurationIdea } from '../models/CurationIdea';
import type { CurationIdeaActivity } from '../models/CurationIdeaActivity';
import type { CurationIdeaActivityList } from '../models/CurationIdeaActivityList';
import type { CurationIdeaList } from '../models/CurationIdeaList';
import type { CurationList } from '../models/CurationList';
import type { CurationPerformance } from '../models/CurationPerformance';
import type { CurationPurchase } from '../models/CurationPurchase';
import type { CurationPurchaseCreate } from '../models/CurationPurchaseCreate';
import type { CurationsDiscoverCategory } from '../models/CurationsDiscoverCategory';
import type { CurationValue } from '../models/CurationValue';
import { request as __request } from '../core/request';

export class CurationService {

    /**
     * @returns CurationDiscoverCategory
     * @throws ApiError
     */
    public static async apiCurationDiscoverCategoryList(): Promise<Array<CurationDiscoverCategory>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-discover-category/`,
        });
        return result.body;
    }

    /**
     * @returns CurationDiscoverCategory
     * @throws ApiError
     */
    public static async apiCurationDiscoverCategoryRead({
        curationId,
    }: {
        curationId: string,
    }): Promise<CurationDiscoverCategory> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-discover-category/${curationId}/`,
        });
        return result.body;
    }

    /**
     * @returns CurationIdeaActivityList
     * @throws ApiError
     */
    public static async apiCurationIdeaActivityList(): Promise<Array<CurationIdeaActivityList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-idea-activity/`,
        });
        return result.body;
    }

    /**
     * @returns CurationIdeaActivity
     * @throws ApiError
     */
    public static async apiCurationIdeaActivityCreate({
        data,
    }: {
        data: CurationIdeaActivity,
    }): Promise<CurationIdeaActivity> {
        const result = await __request({
            method: 'POST',
            path: `/api/curation-idea-activity/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns CurationIdeaActivity
     * @throws ApiError
     */
    public static async apiCurationIdeaActivityRead({
        uuid,
    }: {
        /** A UUID string identifying this curation idea activity. **/
        uuid: string,
    }): Promise<CurationIdeaActivity> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-idea-activity/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns CurationIdeaList
     * @throws ApiError
     */
    public static async apiCurationIdeaList({
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<CurationIdeaList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-idea/`,
            query: {
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * @returns CurationIdea
     * @throws ApiError
     */
    public static async apiCurationIdeaCreate({
        data,
    }: {
        data: CurationIdea,
    }): Promise<CurationIdea> {
        const result = await __request({
            method: 'POST',
            path: `/api/curation-idea/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns CurationIdea
     * @throws ApiError
     */
    public static async apiCurationIdeaRead({
        uuid,
    }: {
        /** A UUID string identifying this curation idea. **/
        uuid: string,
    }): Promise<CurationIdea> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-idea/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns CurationPerformance
     * @throws ApiError
     */
    public static async apiCurationPerformanceList(): Promise<Array<CurationPerformance>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-performance/`,
        });
        return result.body;
    }

    /**
     * @returns CurationPerformance
     * @throws ApiError
     */
    public static async apiCurationPerformanceRead({
        uuid,
    }: {
        /** A UUID string identifying this curation performance. **/
        uuid: string,
    }): Promise<CurationPerformance> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-performance/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns CurationPurchase
     * @throws ApiError
     */
    public static async apiCurationPurchaseList(): Promise<Array<CurationPurchase>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-purchase/`,
        });
        return result.body;
    }

    /**
     * @returns CurationPurchaseCreate
     * @throws ApiError
     */
    public static async apiCurationPurchaseCreate({
        data,
    }: {
        data: CurationPurchaseCreate,
    }): Promise<CurationPurchaseCreate> {
        const result = await __request({
            method: 'POST',
            path: `/api/curation-purchase/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns CurationPurchase
     * @throws ApiError
     */
    public static async apiCurationPurchaseRead({
        uuid,
    }: {
        /** A UUID string identifying this curation purchase. **/
        uuid: string,
    }): Promise<CurationPurchase> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-purchase/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns CurationValue
     * @throws ApiError
     */
    public static async apiCurationValueList(): Promise<Array<CurationValue>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-value/`,
        });
        return result.body;
    }

    /**
     * @returns CurationValue
     * @throws ApiError
     */
    public static async apiCurationValueRead({
        uuid,
    }: {
        /** A UUID string identifying this curation value. **/
        uuid: string,
    }): Promise<CurationValue> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-value/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns CurationList
     * @throws ApiError
     */
    public static async apiCurationList({
        creatorId,
    }: {
        creatorId?: string,
    }): Promise<Array<CurationList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation/`,
            query: {
                'creator_id': creatorId,
            },
        });
        return result.body;
    }

    /**
     * @returns Curation
     * @throws ApiError
     */
    public static async apiCurationCreate({
        data,
    }: {
        data: Curation,
    }): Promise<Curation> {
        const result = await __request({
            method: 'POST',
            path: `/api/curation/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns CurationDetail
     * @throws ApiError
     */
    public static async apiCurationRead({
        uuid,
    }: {
        /** A UUID string identifying this curation. **/
        uuid: string,
    }): Promise<CurationDetail> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns Curation
     * @throws ApiError
     */
    public static async apiCurationDelete({
        uuid,
    }: {
        /** A UUID string identifying this curation. **/
        uuid: string,
    }): Promise<Curation> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation/${uuid}/delete/`,
        });
        return result.body;
    }

    /**
     * @returns CurationsDiscoverCategory
     * @throws ApiError
     */
    public static async apiCurationsDiscoverCategoryList(): Promise<Array<CurationsDiscoverCategory>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curations-discover-category/`,
        });
        return result.body;
    }

    /**
     * @returns CurationsDiscoverCategory
     * @throws ApiError
     */
    public static async apiCurationsDiscoverCategoryRead({
        uuid,
    }: {
        /** A UUID string identifying this curation. **/
        uuid: string,
    }): Promise<CurationsDiscoverCategory> {
        const result = await __request({
            method: 'GET',
            path: `/api/curations-discover-category/${uuid}/`,
        });
        return result.body;
    }

}
