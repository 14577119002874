import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Linking } from 'react-native';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { usePersistedStore, useStore } from '../../zustand/store';
import { Button } from '../Button';
import { Refresh } from '../icons';
import { Link } from '../Link';
import { ErrorScreen } from './ErrorScreen';

export type Props = {
  onReload: () => void;
};

/**
 * Error Boundary Fallback to use with screens.
 * All screens should use `withReloadErrorBoundary` so will hit this on error and user can navigate to home to escape.
 */
export const ReloadErrorFallback: React.VFC<Props> = ({ onReload }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();
  const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const isConnected = useStore((state) => state.isConnected);

  return (
    <ErrorScreen
      title={isConnected ? 'Something went wrong' : 'You appear to be offline'}
      actionButton={
        <Button
          StartIcon={() => <Refresh color={colors.white} />}
          variant="primary"
          size="lg"
          onPress={onReload}
          text="Reload"
        />
      }
      subActionLink={
        <Text style={tailwind('text-warmGray-400')}>
          Or{' '}
          <Link onPress={() => navigation.replace(isUserLoggedIn ? 'Tabs' : 'LogIn')}>
            Back to {isUserLoggedIn ? 'Home' : 'Log In'}
          </Link>
        </Text>
      }
      footerLink={
        <Text style={tailwind('text-warmGray-500')}>
          If you&apos;d like to report a bug{' '}
          <Link onPress={() => Linking.openURL(encodeURI('mailto: help@upsidetechnology.co?subject=Crash Report'))}>
            press here
          </Link>
        </Text>
      }
    />
  );
};
