import React, { ReactNode } from 'react';
import { ReloadErrorFallback } from './ReloadErrorFallback';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error | null;
}

/**
 * Error Boundary to use with screens.
 * All screens should use `withReloadErrorBoundary` so will hit this on error and user can navigate to home to escape.
 */
export class ReloadErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    console.error('ReloadErrorBoundary showing Fallback.', error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ReloadErrorFallback onReload={() => this.setState({ error: null })} />;
    }

    return children;
  }
}
