import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as yup from 'yup';
import { useCreateTradingBankAccountMutation } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { TextInput } from '../../old/inputs';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'TradingAccountBankDetails'>;

export const CREATE_TRADING_BANK_ACCOUNT = gql`
  mutation CreateTradingBankAccount($bankAccount: TradingBankAccountInput!) {
    createTradingBankAccount(bankAccount: $bankAccount) {
      error {
        ... on TradingValidationError {
          message
          errors
        }
      }
    }
  }
`;

const schema = yup.object({
  name: yup.string().trim().required('Required'),
  accountNumber: yup
    .string()
    .trim()
    .matches(/^\d{8}$/, 'Must be an 8 digit number.')
    .required('Required'),
  sortCode: yup
    .string()
    .trim()
    .matches(/^\d{6}$/, 'Must be a 6 digit number.')
    .required('Required'),
  currency: yup.string().trim().uppercase().required('Required'),
  countryCode: yup.string().trim().uppercase().required('Required'),
});

type FormSchema = yup.InferType<typeof schema>;

const initialValues: FormSchema = {
  name: '',
  accountNumber: '',
  sortCode: '',
  currency: 'GBP',
  countryCode: 'GB',
};

export const TradingAccountBankDetails: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const [mutate, { loading, data, reset }] = useCreateTradingBankAccountMutation();

  const onSubmit = async (values: FormSchema, helpers: FormikHelpers<FormSchema>) => {
    try {
      reset();
      const { start, middle, end } = parseSortCode(values.sortCode);
      const result = await mutate({
        variables: {
          bankAccount: {
            name: values.name,
            accountNumber: values.accountNumber,
            sortCode: `${start}-${middle}-${end}`,
            currency: values.currency,
            countryCode: values.countryCode,
          },
        },
      });

      const validationErrorResult = result.data?.createTradingBankAccount.error;
      if (validationErrorResult) {
        console.error(createValidationError(validationErrorResult));
        helpers.setSubmitting(false);
        return;
      }

      navigation.replace('TradingAccountSubmit');
    } catch (e) {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView>
        <TitleBar title="Bank Details" />
        <ScrollView>
          <ScreenSidePadding>
            <Text>Please enter your bank details below.</Text>
            <Formik<FormSchema>
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={onSubmit}
              validationSchema={schema}
            >
              {({
                values: { name, accountNumber, sortCode, currency, countryCode },
                handleBlur,
                handleChange,
                errors,
                handleSubmit,
              }) => {
                const submissionError = data?.createTradingBankAccount.error;
                return (
                  <View style={tailwind('pt-4')}>
                    <TextInput
                      label="Name *"
                      value={name}
                      errorMessage={errors.name}
                      onChangeText={handleChange('name')}
                      onBlur={handleBlur('name')}
                      placeholder="Name as it appears on your bank statement."
                      accessibilityLabel="Name"
                    />
                    <Spacer />
                    <TextInput
                      label="Account number *"
                      value={accountNumber}
                      errorMessage={errors.accountNumber}
                      onChangeText={handleChange('accountNumber')}
                      onBlur={handleBlur('accountNumber')}
                      placeholder="8 digits"
                      accessibilityLabel="Account number"
                    />
                    <Spacer />
                    <TextInput
                      label="Sort code *"
                      value={sortCode}
                      errorMessage={errors.sortCode}
                      onChangeText={handleChange('sortCode')}
                      onBlur={handleBlur('sortCode')}
                      placeholder="6 digits"
                      accessibilityLabel="Sort code"
                    />
                    <Spacer />
                    <TextInput
                      label="Currency *"
                      value={currency}
                      errorMessage={errors.currency}
                      onChangeText={handleChange('currency')}
                      onBlur={handleBlur('currency')}
                      placeholder="Currency code (e.g GBP)"
                      accessibilityLabel="Currency"
                    />
                    <Spacer />
                    <TextInput
                      label="Country (Country Code) *"
                      value={countryCode}
                      errorMessage={errors.countryCode}
                      onChangeText={handleChange('countryCode')}
                      onBlur={handleBlur('countryCode')}
                      placeholder="Country code (e.g GB)"
                      accessibilityLabel="Country code"
                    />
                    <Spacer />
                    {submissionError && (
                      <View style={tailwind('bg-red-100 p-4 rounded-md text-red-500')}>
                        <Text style={tailwind('text-red-600')}>{submissionError.message}</Text>
                        {Object.keys(submissionError.errors).map((k) => (
                          <Text style={tailwind('text-red-600 py-1 pl-2')} key={k}>{`∙${k}: ${
                            (submissionError.errors as Record<string, string>)[k]
                          }`}</Text>
                        ))}
                      </View>
                    )}
                    <Spacer />
                    <Button onPress={handleSubmit} loading={loading} isDisabled={loading} text="Next" />
                    <Spacer />
                  </View>
                );
              }}
            </Formik>
          </ScreenSidePadding>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
});

function parseSortCode(sortCode: string) {
  return { start: sortCode.slice(0, 2), middle: sortCode.slice(2, 4), end: sortCode.slice(4) };
}

const Spacer: FC = () => {
  const tailwind = useTailwind();
  return <View style={tailwind('py-2')} />;
};
