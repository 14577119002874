import { useNavigation } from '@react-navigation/core';
import { maxBy, minBy } from 'lodash';
import { FC, forwardRef, useCallback, useMemo } from 'react';
import { Platform, View, ViewStyle } from 'react-native';
import Animated, { Layout } from 'react-native-reanimated';
import {
  Maybe,
  PrePortfolio,
  PrePortfolioMember,
  PrePortfolioSimulationMember,
  PrePortfolioStatsPayload,
} from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { LoggedInStackNavigationProps } from '../../navigation';
import { colors, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { formatDate, parseUTCDateFromString } from '../../util/date';
import { formatNumber, formatPercent, formatPrice, getPercentageDiff } from '../../util/number';
import { isFiniteNumber, isNotNullKeys } from '../../util/typeGuards';
import { Button } from '../Button';
import { CashLogo } from '../CashLogo';
import { PortfolioChart } from '../Charts';
import { EsgScores } from '../EsgScores';
import { ArrowDown, ArrowUp } from '../icons';
import { InfoBottomSheetBody, InfoBottomSheetHeading, InfoBottomSheetText } from '../InfoBottomSheet';
import { PriceInput } from '../inputs';
import { InstrumentLogo } from '../InstrumentLogo';
import { Link } from '../Link';
import { PortfolioInfoBox } from '../PortfolioInfoBox';
import { ScreenSidePadding } from '../StyledScreen';
import { PortfolioBreakdownRow } from './PortfolioBreakdownRow';

type Props = {
  onEdit: () => void | Promise<void>;
  members?: (PrePortfolioSimulationMember | PrePortfolioMember)[];
  statsPayload?: Maybe<PrePortfolioStatsPayload>;
  prePortfolio?: Maybe<PrePortfolio>;
  simulationId?: string;
  esgViewed: boolean;
  notional: number | null;
} & (
  | { isPortfolioScreen?: false; onChangeNotional: (value: number | null) => void }
  | { isPortfolioScreen: true; onChangeNotional?: undefined }
);

const CASH_INSTRUMENT_ID = 'CASH';
/**
 * Portfolio body used in Portfolio and SelectRisk screens
 * WARNING: Every navigation event from SelectRisk MUST be navigation.replace() otherwise layout bugs could
 * occur due to layout animation container remaining mounted.
 */
export const PortfolioBody = forwardRef<View, Props>(
  (
    {
      onEdit,
      members,
      statsPayload,
      prePortfolio,
      simulationId,
      isPortfolioScreen = false,
      esgViewed,
      onChangeNotional = undefined,
      notional,
    },
    ref,
  ) => {
    const tailwind = useTailwind();
    const stats = statsPayload?.data;
    // Throw if payload contains insufficient data
    if (!(members && stats && prePortfolio))
      throw new Error(
        `Insufficient data available to present Portfolio Simulation.
         Portfolio: ${prePortfolio?.id}, Simulation: ${simulationId}, Error: ${statsPayload?.error}`,
      );

    const navigation = useNavigation<LoggedInStackNavigationProps>();
    const { present } = useBottomSheet();

    const cashMember: Partial<PrePortfolioMember> = {
      weight: prePortfolio.cashProportion ?? 0,
      instrument: {
        id: CASH_INSTRUMENT_ID,
        name: 'Cash',
        ticker: 'Cash',
      },
    };

    const sortedMembers = [...(members ?? []), cashMember]
      .filter((m) => (m.weight ?? 0) > 0)
      .sort((a, b) => {
        if (!(isFiniteNumber(a.weight) && isFiniteNumber(b.weight)))
          throw new Error('Received portfolio member without weight');
        return b.weight - a.weight;
      });

    const { priceSeries, seriesStartDate, seriesEndPrice, seriesStartPrice } = useMemo(() => {
      const historicalPrices = stats?.historicalPrices ?? [];

      const endHistoricalPrice = maxBy(historicalPrices, (p) => parseUTCDateFromString(p.date ?? '').getTime());
      const startHistoricalPrice = minBy(historicalPrices, (p) => parseUTCDateFromString(p.date ?? '').getTime());
      const priceSeries =
        historicalPrices
          ?.filter(isNotNullKeys)
          .map((p): [number, number] => [
            parseUTCDateFromString(p.date).getTime(),
            notional ? p.price * notional : p.price,
          ]) ?? [];

      return {
        priceSeries,
        seriesStartDate: parseUTCDateFromString(startHistoricalPrice?.date ?? ''),
        seriesStartPrice: startHistoricalPrice?.price ?? 0,
        seriesEndPrice: endHistoricalPrice?.price ?? 0,
      };
    }, [stats?.historicalPrices, notional]);

    const netPriceDelta = getPercentageDiff(seriesStartPrice, seriesEndPrice);
    const textColor = getDeltaColor(netPriceDelta);

    const onPressMember = useCallback(
      (memberId) => {
        if (!isPortfolioScreen || memberId === CASH_INSTRUMENT_ID) return;
        navigation.push('Instrument', { instrumentId: memberId });
      },
      [navigation, isPortfolioScreen],
    );

    return (
      <>
        {!isPortfolioScreen && !!onChangeNotional && (
          <View style={tailwind('py-6')}>
            <Text style={tailwind('text-center text-sm text-warmGray-400')}>Based on</Text>
            <NotionalInput onChange={onChangeNotional} value={notional} />
          </View>
        )}
        {sortedMembers.map((member, index) => {
          if (!(member.instrument && isFiniteNumber(member.weight)))
            throw new Error(`Missing fields on prePortfolioMember instrumentId: ${member.id}`);

          // Only animate layout in selectRisk screen
          return isPortfolioScreen ? (
            <PortfolioMemberRow
              member={member}
              notional={notional}
              currencyIso={prePortfolio?.currency?.iso ?? ''}
              onPressMember={onPressMember}
              style={index === 0 ? tailwind('border-t') : undefined}
              key={member?.instrument?.id ?? ''}
            />
          ) : (
            <Animated.View layout={isPortfolioScreen ? undefined : Layout} key={member?.instrument?.id ?? ''}>
              <PortfolioMemberRow
                member={member}
                notional={notional}
                currencyIso={prePortfolio?.currency?.iso ?? ''}
                onPressMember={onPressMember}
                style={index === 0 ? tailwind('border-t') : undefined}
              />
            </Animated.View>
          );
        })}
        <ScreenSidePadding>
          {isPortfolioScreen ? (
            <View style={tailwind('py-6')}>
              <Button variant="secondary" text="Edit" onPress={onEdit} />
              <Text style={tailwind('text-xs text-center text-warmGray-400 pt-2')}>
                Editing will override this blueprint.
              </Text>
            </View>
          ) : (
            <Pressable
              accessibilityLabel="Edit"
              // Reset so Portfolio screens no longer in stack once flow exited
              onPress={onEdit}
            >
              <Text style={tailwind('text-warmGray-400 text-sm pt-6 pb-4 self-center')}>
                Want to change something? <Link>Edit</Link>
              </Text>
            </Pressable>
          )}
          <View style={tailwind('py-2')}>
            <Text style={tailwind('text-lg text-warmGray-800 font-semibold pb-1')}>Historical Performance</Text>
            <Text style={tailwind('text-sm text-warmGray-400')}>
              Based on price history since {formatDate(seriesStartDate, 'Do MMM YYYY')}
            </Text>
            <View style={tailwind('flex-row items-center pt-2')}>
              {netPriceDelta > 0 ? (
                <ArrowUp color={colors.green[500]} style={tailwind('h-3 w-3')} />
              ) : (
                <ArrowDown color={colors.red[500]} style={tailwind('h-3 w-3')} />
              )}
              <Text style={tailwind(`pl-2 ${textColor} text-sm`)}>{formatPercent(netPriceDelta, 2, false)}</Text>
            </View>
          </View>
          <View style={tailwind('overflow-hidden py-2')}>
            <PortfolioChart prices={priceSeries} currencyIso={prePortfolio.currency?.iso ?? ''} />
          </View>
          <View style={tailwind('flex-row justify-center py-6')}>
            <PortfolioInfoBox
              label="Correlation"
              value={formatNumber(stats?.correlation ?? 0, 2)}
              style={tailwind('rounded-l-lg flex-1')}
              onPress={() => {
                present(<CorrelationBottomSheet />);
              }}
            />
            <PortfolioInfoBox
              label="Max. Drawdown"
              value={formatPercent(Math.abs(stats?.maximumDrawdown ?? 0), 0, false)}
              style={tailwind('flex-1 mx-1')}
              onPress={() => {
                present(<DrawdownBottomSheet />);
              }}
            />
            <PortfolioInfoBox
              label="Volatility"
              value={formatPercent(stats?.annualisedVolatility ?? 0, 0, false)}
              style={tailwind('rounded-r-lg flex-1')}
              onPress={() => {
                present(<VolatilityBottomSheet />);
              }}
            />
          </View>

          <View style={tailwind('pt-4')}>
            <Text style={tailwind('text-lg text-warmGray-800 font-semibold pb-4')}>ESG Summary</Text>
            <EsgScores ref={ref} viewed={esgViewed} {...stats?.esg} />
          </View>
        </ScreenSidePadding>
      </>
    );
  },
);

const NotionalInput: FC<{ value: number | null; onChange: (value: number | null) => void }> = ({ value, onChange }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('items-center')}>
      <PriceInput
        disableAutoFocus
        startAdornment="£"
        startAdornmentStyle={tailwind(`text-2xl ${Platform.OS !== 'web' ? '-mb-2' : ''} mr-0 pr-2 text-teal-700`)}
        containerStyles={{
          height: 'auto',
          ...tailwind(
            `flex-row pb-2 border-b border-l-0 border-t-0 border-r-0 border-teal-400 rounded-none text-teal-700 text-2xl w-36 justify-center pr-5`,
          ),
        }}
        inputStyle={tailwind(`text-center text-2xl text-teal-700 flex-shrink  ${Platform.OS === 'web' ? 'w-32' : ''} `)}
        value={value}
        onChange={onChange}
        placeholder={''}
      />
    </View>
  );
};

type MemberRowProps = {
  member: Partial<PrePortfolioMember> | PrePortfolioSimulationMember;
  currencyIso: string;
  notional: number | null;
  onPressMember: (id: string) => void;
  style?: ViewStyle;
};

export const PortfolioMemberRow: FC<MemberRowProps> = ({ member, currencyIso, notional, onPressMember, style }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      accessibilityRole="link"
      accessibilityLabel={member.instrument?.ticker ?? ''}
      style={[tailwind('border-b border-warmGray-200 py-1 mx-6'), style]}
      onPress={() => onPressMember(member.instrument?.id ?? '')}
    >
      <PortfolioBreakdownRow
        size="md"
        Logo={
          member.instrument?.id === CASH_INSTRUMENT_ID ? (
            <CashLogo />
          ) : (
            <InstrumentLogo
              size="medium"
              logoUrl={member?.instrument?.logoUrl ?? ''}
              ticker={member?.instrument?.ticker ?? ''}
            />
          )
        }
        topLeft={member.instrument?.name ?? ''}
        bottomLeft={member.instrument?.ticker ?? ''}
        topRight={formatPercent(member.weight ?? 0, 2, false)}
        bottomRight={formatPrice((member.weight ?? 0) * (notional ?? 0), currencyIso ?? '')}
      />
    </Pressable>
  );
};

export const CorrelationBottomSheet = () => (
  <InfoBottomSheetBody>
    <InfoBottomSheetHeading>Correlation</InfoBottomSheetHeading>
    <InfoBottomSheetText>
      Correlation is used to see whether company share prices are likely to move together, or move apart.
      {'\n'}
      {'\n'}
      The correlation coefficient is a number between -1 and 1.
      {'\n'}
      {'\n'}A number closer to +1 indicates that the assets in your portfolio tend to move in the same direction.
      {'\n'}
      {'\n'}A number closer to -1 indicates that the assets in your portfolio move in the opposite direction.
      {'\n'}
      {'\n'}A number of 0 means there is no visible correlation between the stocks in your portfolio.
    </InfoBottomSheetText>
  </InfoBottomSheetBody>
);

export const DrawdownBottomSheet = () => (
  <InfoBottomSheetBody>
    <InfoBottomSheetHeading>Drawdown</InfoBottomSheetHeading>
    <InfoBottomSheetText>
      A drawdown refers to how much a portfolio is down from the peak before it recovers back to the peak.
      {'\n'}
      {'\n'}
      It is an important risk factor investors consider when analysing a portfolio.
    </InfoBottomSheetText>
  </InfoBottomSheetBody>
);

export const VolatilityBottomSheet = () => (
  <InfoBottomSheetBody>
    <InfoBottomSheetHeading>Volatility</InfoBottomSheetHeading>
    <InfoBottomSheetText>
      Volatility is a key indication of risk. It is the measure of how unpredictable an asset&apos;s price changes are.
      {'\n'}
      {'\n'}
      The higher the volatility of an asset&apos;s returns, the more unpredictable they are, and hence the riskier the
      asset is perceived to be.
    </InfoBottomSheetText>
  </InfoBottomSheetBody>
);

const getDeltaColor = (priceDelta: number) => (priceDelta > 0 ? 'text-green-500' : 'text-red-500');
