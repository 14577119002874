import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetScrollView,
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
  useBottomSheetInternal,
} from '@gorhom/bottom-sheet';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';
import Animated, { useAnimatedStyle } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTailwind } from '../theme';

type Config = { snapPoint: string; scrollView?: boolean };

export type BottomSheetContext = {
  present: (modalChildren: React.ReactElement, config?: Config) => void;
  dismiss: () => void;
};

export const BottomSheetContext = React.createContext<BottomSheetContext>({
  present: (_, __) => undefined,
  dismiss: () => undefined,
});

/** Context provided to child components of BottomSheet to signal whether component is rendered inside a BottomSheet */
export const BottomSheetRenderContext = React.createContext<{ isInBottomSheet: boolean }>({ isInBottomSheet: false });

// Incrementing key to force re-render of modal children every provider render. (Handles edge cases related to re-opening feed card menus)
let key = 0;

/**
 * Bottom sheet provider. onDismiss handled automatically on standard dismiss interactions
 * with an optional dismiss event provided via context.
 *
 */
export const BottomSheetProvider: React.FC = ({ children }) => {
  const tailwind = useTailwind();
  const insets = useSafeAreaInsets();
  const [modalChildren, setChildren] = useState<React.ReactElement | null>(null);
  const [config, setConfig] = useState<Config | null>(null);
  // WARNING: Do not change index 0, here, this is a dummy. See https://github.com/gorhom/react-native-bottom-sheet/issues/208
  // Backdrop only appears if we're presenting index 1
  const snapPoints = useMemo(() => ['1%', config?.snapPoint ?? 'CONTENT_HEIGHT'], [config]);

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } =
    useBottomSheetDynamicSnapPoints(snapPoints);

  const bottomSheetRef = useRef<BottomSheetModal>(null);

  useEffect(() => {
    if (!!modalChildren) {
      bottomSheetRef?.current?.present();
    } else {
      bottomSheetRef.current?.dismiss();
    }
  }, [modalChildren]);

  // useCallback to prevent circular update loop when calling present/dismiss in a useEffect downstream
  const present = useCallback(
    (modalChildren: React.ReactElement, config?: Config) => {
      setChildren(modalChildren);
      setConfig(config ?? null);
    },
    [setChildren, setConfig],
  );
  const dismiss = useCallback(() => setChildren(null), [setChildren]);

  return (
    <BottomSheetModalProvider>
      <BottomSheetContext.Provider
        value={{
          present,
          dismiss,
        }}
      >
        <BottomSheetModal
          ref={bottomSheetRef}
          // Force re-render of children by incrementing key
          key={`${key++}`}
          onDismiss={() => setChildren(null)}
          index={!!modalChildren ? 1 : 0}
          snapPoints={config ? snapPoints : animatedSnapPoints}
          handleHeight={config ? undefined : animatedHandleHeight}
          contentHeight={config ? undefined : animatedContentHeight}
          backdropComponent={modalChildren ? BottomSheetBackdrop : undefined}
          keyboardBehavior={'interactive'}
          keyboardBlurBehavior={'restore'}
          handleIndicatorStyle={tailwind('w-16 bg-warmGray-200 h-1 mt-2')}
          backgroundStyle={[{ borderTopLeftRadius: 24, borderTopRightRadius: 24 }]}
        >
          <BottomSheetRenderContext.Provider value={{ isInBottomSheet: Boolean(modalChildren) }}>
            {config?.scrollView ? (
              <WebScrollViewWrapper>
                <BottomSheetScrollView>
                  <BottomSheetView
                    style={{ paddingBottom: Platform.OS === 'web' ? 24 : insets.bottom }}
                    onLayout={handleContentLayout}
                  >
                    {modalChildren}
                  </BottomSheetView>
                </BottomSheetScrollView>
              </WebScrollViewWrapper>
            ) : (
              <BottomSheetView
                style={{ paddingBottom: Platform.OS === 'web' ? 24 : insets.bottom }}
                onLayout={handleContentLayout}
              >
                {modalChildren}
              </BottomSheetView>
            )}
          </BottomSheetRenderContext.Provider>
        </BottomSheetModal>

        {/* Ensure context available in rest of app */}
        <BottomSheetRenderContext.Provider value={{ isInBottomSheet: false }}>
          {children}
        </BottomSheetRenderContext.Provider>
      </BottomSheetContext.Provider>
    </BottomSheetModalProvider>
  );
};

/**
 * BottomSheetDraggable View parent sets height to container height but flexBasis: 0 hence height is not applied, but is computed as content body height (even if larger than screen)
 * This breaks overflow in Web since scrollView height wraps the body, not the content height.
 * We are not given access to style that view, hence this web wrapper to force content height to snap point height.
 *
 * The view causing the issue can be found on line 1603 at https://github.dev/gorhom/react-native-bottom-sheet/blob/4a4a9dedef4f83b620a21e1a5670de0e1020a2f6/src/components/bottomSheet/BottomSheet.tsx#L1603-L1608
 */
const WebScrollViewWrapper: FC = ({ children }) => {
  const { animatedContentHeight } = useBottomSheetInternal();
  const animatedStyles = useAnimatedStyle(() => {
    return {
      height: animatedContentHeight.value,
      flexBasis: 'auto',
    };
  });

  return Platform.OS === 'web' ? <Animated.View style={animatedStyles}>{children}</Animated.View> : <>{children}</>;
};
