/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Group } from '../models/Group';
import type { GroupMembership } from '../models/GroupMembership';
import { request as __request } from '../core/request';

export class GroupService {

    /**
     * @returns Group
     * @throws ApiError
     */
    public static async apiGroupList(): Promise<Array<Group>> {
        const result = await __request({
            method: 'GET',
            path: `/api/group/`,
        });
        return result.body;
    }

    /**
     * @returns Group
     * @throws ApiError
     */
    public static async apiGroupCreate({
        data,
    }: {
        data: Group,
    }): Promise<Group> {
        const result = await __request({
            method: 'POST',
            path: `/api/group/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Group
     * @throws ApiError
     */
    public static async apiGroupRead({
        uuid,
    }: {
        /** A UUID string identifying this group. **/
        uuid: string,
    }): Promise<Group> {
        const result = await __request({
            method: 'GET',
            path: `/api/group/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns Group
     * @throws ApiError
     */
    public static async apiGroupUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this group. **/
        uuid: string,
        data: Group,
    }): Promise<Group> {
        const result = await __request({
            method: 'PUT',
            path: `/api/group/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Group
     * @throws ApiError
     */
    public static async apiGroupPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this group. **/
        uuid: string,
        data: Group,
    }): Promise<Group> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/group/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiGroupDelete({
        uuid,
    }: {
        /** A UUID string identifying this group. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/group/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns GroupMembership
     * @throws ApiError
     */
    public static async apiGroupMembershipList(): Promise<Array<GroupMembership>> {
        const result = await __request({
            method: 'GET',
            path: `/api/group_membership/`,
        });
        return result.body;
    }

    /**
     * @returns GroupMembership
     * @throws ApiError
     */
    public static async apiGroupMembershipCreate({
        data,
    }: {
        data: GroupMembership,
    }): Promise<GroupMembership> {
        const result = await __request({
            method: 'POST',
            path: `/api/group_membership/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns GroupMembership
     * @throws ApiError
     */
    public static async apiGroupMembershipRead({
        uuid,
    }: {
        /** A UUID string identifying this group membership. **/
        uuid: string,
    }): Promise<GroupMembership> {
        const result = await __request({
            method: 'GET',
            path: `/api/group_membership/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns GroupMembership
     * @throws ApiError
     */
    public static async apiGroupMembershipUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this group membership. **/
        uuid: string,
        data: GroupMembership,
    }): Promise<GroupMembership> {
        const result = await __request({
            method: 'PUT',
            path: `/api/group_membership/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns GroupMembership
     * @throws ApiError
     */
    public static async apiGroupMembershipPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this group membership. **/
        uuid: string,
        data: GroupMembership,
    }): Promise<GroupMembership> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/group_membership/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiGroupMembershipDelete({
        uuid,
    }: {
        /** A UUID string identifying this group membership. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/group_membership/${uuid}/`,
        });
        return result.body;
    }

}
