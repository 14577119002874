import { FC } from 'react';
import { LightBulb, ThumbsUp } from '../../old/icons';
import { TutorialCard } from '../../old/TutorialCard';
import { useTailwind } from '../../theme';
import { useTutorialCardPersistedStore } from '../../zustand/store';

export const NewlyCreatedIdeaTutorialCard: FC = () => {
  const tailwind = useTailwind();
  return (
    <TutorialCard
      title="Well done for creating your first Idea!"
      style={tailwind('py-2')}
      onDismiss={() => useTutorialCardPersistedStore.setState({ myIdeaTutorialDismissed: true })}
      Icon={ThumbsUp}
    >
      This page will give you a detailed view of your Idea. Come back to this page to see how your Idea is doing.
      {'\n\n'}
      Be the first the start the conversation by adding a comment explaining your Idea in a bit more detail.
    </TutorialCard>
  );
};

export const OtherUserIdeaTutorialCard: FC = () => {
  const tailwind = useTailwind();
  return (
    <TutorialCard
      title="Welcome to the Idea page"
      style={tailwind('py-2')}
      onDismiss={() => useTutorialCardPersistedStore.setState({ otherUserIdeaTutorialDismissed: true })}
      Icon={LightBulb}
    >
      The Idea page will give you a more detailed view of someone else&apos;s idea. {'\n\n'} Do you agree, disagree or
      just want to find out more from the Idea creator? Start the conversation by adding a comment.
    </TutorialCard>
  );
};
