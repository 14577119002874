import React from 'react';
import Animated, {
  runOnJS,
  useAnimatedReaction,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated';
import { useChart } from '../context/useChart';
import { Ticks } from '../ticks';
import { Label } from './Label';

type Props = {
  widthHandler: (width: number) => void;
  currencyCode?: string;
};

/**
 * The Y axis is composed of 2 layers, of which only one is visible at a time (controlled by their opacity).
 * One first render, layer1 is the visible layer and layer 2 opacity is 0.
 * They swap visibility each time the selectedInterval changes.
 * If ChartData is not an array, make layer 1 and 2 equal.
 */
export const YAxis: React.FC<Props> = ({ widthHandler, currencyCode }) => {
  const { height, data } = useChart();
  const [ticks, setTickIndices] = React.useState<{
    layer1: Ticks | undefined;
    layer2: Ticks | undefined;
    layer1Active: boolean;
  }>({
    layer1: data.value.ticks,
    layer2: undefined,
    layer1Active: true,
  });
  const activeWidth = useSharedValue(0);
  const rightOffset = useDerivedValue(() =>
    withSpring(activeWidth.value, {
      stiffness: 50,
      damping: 20,
      overshootClamping: true,
    }),
  );

  const transition = useDerivedValue(
    () =>
      withSpring(ticks.layer1Active ? 1 : 0, {
        stiffness: 50,
        damping: 20,
        overshootClamping: true,
      }),
    [ticks],
  );
  const inverseTransition = useDerivedValue(() => 1 - transition.value);

  useAnimatedReaction(
    () => data.value.ticks,
    (curr, prev) => {
      if (prev !== null && curr !== prev) {
        if (ticks.layer1Active) {
          runOnJS(setTickIndices)({
            layer1: prev,
            layer2: curr,
            layer1Active: false,
          });
        } else {
          runOnJS(setTickIndices)({ layer1: curr, layer2: prev, layer1Active: true });
        }
      }
    },
    [ticks, setTickIndices],
  );

  return (
    <>
      {ticks.layer1 && (
        <Layer
          transition={transition}
          ticks={ticks.layer1}
          previousTicks={ticks.layer2}
          currencyCode={currencyCode}
          height={height}
          rightOffset={rightOffset}
          onWidth={(width) => {
            if (ticks.layer1Active) {
              widthHandler(width);
              activeWidth.value = width;
            }
          }}
        />
      )}
      {ticks.layer2 && (
        <Layer
          transition={inverseTransition}
          ticks={ticks.layer2}
          previousTicks={ticks.layer1}
          currencyCode={currencyCode}
          height={height}
          rightOffset={rightOffset}
          onWidth={(width) => {
            if (!ticks.layer1Active) {
              widthHandler(width);
              activeWidth.value = width;
            }
          }}
        />
      )}
    </>
  );
};

export const TICK_COUNT = 4;
type LayerProps = {
  ticks: Ticks;
  previousTicks: Ticks | undefined;
  currencyCode: string | undefined;
  onWidth: (width: number) => void;
  height: Animated.SharedValue<number>;
  rightOffset: Animated.SharedValue<number>;
  transition: Animated.SharedValue<number>;
};
const Layer: React.VFC<LayerProps> = ({
  ticks,
  previousTicks,
  currencyCode,
  onWidth,
  height,
  rightOffset,
  transition,
}) => {
  const values = generateTicks(ticks);
  let maxLabelWidth = 0;
  const styles = useAnimatedStyle(() => {
    return {
      opacity: transition.value,
      right: rightOffset.value,
    };
  });
  const widthCallback = (labelWidth: number) => {
    if (labelWidth > maxLabelWidth) {
      maxLabelWidth = labelWidth;
      onWidth(labelWidth);
    }
  };
  return (
    <Animated.View style={[styles, { position: 'absolute' }]}>
      {values.map((v) => (
        <Label
          key={v}
          height={height}
          value={v}
          prevTicks={previousTicks}
          ticks={ticks}
          transition={transition}
          onLayout={(width) => widthCallback(width)}
          currencyCode={currencyCode}
        />
      ))}
    </Animated.View>
  );
};

const TICK_INDICES = Array.from(Array(TICK_COUNT).keys());

const generateTicks = (ticks: Ticks | undefined) =>
  ticks ? TICK_INDICES.map((offset) => (100 * ticks.low + offset * (100 * ticks.step)) / 100) : [];
