import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { colors } from '../../theme';

export const ArrowDown: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        d="M0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683417 -0.0976305 1.31658 -0.0976305 1.70711 0.292893L0.292893 1.70711ZM10.2929 11.7071L0.292893 1.70711L1.70711 0.292893L11.7071 10.2929L10.2929 11.7071Z"
        fill={props.color ?? colors.fuchsia[600]}
        strokeWidth="2"
      />
      <Path
        d="M3 11H11V3"
        stroke={props.color ?? colors.fuchsia[600]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
