/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TradePreviewResponse } from '../models/TradePreviewResponse';
import { request as __request } from '../core/request';

export class CalculationService {

    /**
     * @returns TradePreviewResponse
     * @throws ApiError
     */
    public static async apiTradePreviewList({
        curationId,
        ideaId,
        tradeBy,
        amount,
        price,
        date,
    }: {
        curationId: string,
        ideaId: string,
        tradeBy: 'SHARES' | 'CASH' | 'NAV_PERCENT',
        amount: string,
        price: string,
        date: string,
    }): Promise<TradePreviewResponse> {
        const result = await __request({
            method: 'GET',
            path: `/api/trade-preview/`,
            query: {
                'curation_id': curationId,
                'idea_id': ideaId,
                'trade_by': tradeBy,
                'amount': amount,
                'price': price,
                'date': date,
            },
            errors: {
                400: `Bad request`,
            },
        });
        return result.body;
    }

}
