import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useCallback, useEffect, useState, VFC } from 'react';
import { Platform, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { PRE_PORTFOLIO_SIMULATION_FIELDS } from '../../fragments/prePortfolio';
import {
  useCreatePrePortfolioSimulationsMutation,
  useGetSimulationsAndPrePortfolioQuery,
} from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { CircleSpinner } from '../../old/CircleSpinner';
import { CircleTick } from '../../old/icons/CircleTick';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { WizardBodyText, WizardHeadingText } from '../../old/Wizard';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export const GET_PRE_PORTFOLIO_SIMULATIONS = gql`
  ${PRE_PORTFOLIO_SIMULATION_FIELDS}
  query getPrePortfolioSimulations($prePortfolioId: ID!) {
    prePortfolioSimulations(prePortfolioId: $prePortfolioId) {
      simulations {
        ...CorePrePortfolioSimulationFields
      }
    }
  }
`;

type SimulatorStepState = 'idle' | 'spinning' | 'finished';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'PrePortfolioSimulatorRunning'>;

export const PrePortfolioSimulatorRunning: VFC<Props> = withReloadErrorBoundary(({ navigation, route }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const { prePortfolioId } = route.params;
  const [pollingActive, setPollingActive] = useState(false);
  const [stepOneState, setStepOneState] = useState<SimulatorStepState>('spinning');
  const [stepTwoState, setStepTwoState] = useState<SimulatorStepState>('idle');
  const [stepThreeState, setStepThreeState] = useState<SimulatorStepState>('idle');

  const [createPrePortfolioSimulationsMutation, { error: mutationError }] = useCreatePrePortfolioSimulationsMutation({
    variables: { prePortfolioId },
    onCompleted: () => {
      setPollingActive(true);
    },
  });

  useEffect(() => {
    createPrePortfolioSimulationsMutation({ variables: { prePortfolioId } });
  }, [createPrePortfolioSimulationsMutation, prePortfolioId]);

  const { data, stopPolling, error } = useGetSimulationsAndPrePortfolioQuery({
    skip: !pollingActive,
    pollInterval: 2000,
    variables: { prePortfolioId },
  });

  // Stop polling once we've retrieved a set of simulations with stats
  useEffect(() => {
    const firstSimulationWithStats = data?.prePortfolioSimulations.simulations.find((i) => i.stats);
    if (firstSimulationWithStats) {
      stopPolling();
    }
  }, [data, stopPolling]);

  useEffect(() => {
    const statsErrorValue = data?.prePortfolioSimulations.simulations.find((i) => i.stats.error)?.stats.error;
    const statsError = statsErrorValue ? createValidationError(statsErrorValue) : undefined;

    const e = error || mutationError || statsError;
    if (e) {
      console.error(e);
      toast.show('Something went wrong while running simulations. Please try again.');
      navigation.replace('Tabs', { screen: 'BlueprintTab', params: { screen: 'Blueprint' } });
    }
  }, [data, error, mutationError, navigation, toast]);

  // Finish first animation 1.5s after mount
  useEffect(() => {
    setTimeout(() => {
      setStepOneState('finished');
    }, 1500);
  }, []);

  // Finish step 2 animation 1s after triggering spinning
  useEffect(() => {
    if (stepTwoState === 'spinning') {
      setTimeout(() => {
        setStepTwoState('finished');
      }, 1500);
    }
  }, [stepTwoState]);

  // When step 2 is finished and we have data, show step 3 spinner for 1.5s.
  useEffect(() => {
    if (stepThreeState !== 'spinning') return;

    if (stepTwoState === 'finished') {
      if (data?.prePortfolioSimulations) {
        setTimeout(() => {
          setStepThreeState('finished');
        }, 1500);
      }
    }
  }, [data, stepTwoState, stepThreeState, setStepThreeState]);

  const onStepOneAnimationFinished = useCallback(() => {
    setStepTwoState('spinning');
  }, [setStepTwoState]);

  const onStepTwoAnimationFinished = useCallback(() => {
    setStepThreeState('spinning');
  }, [setStepThreeState]);

  const onStepThreeAnimationFinished = () => navigation.replace('PrePortfolioSelectRisk', { prePortfolioId });

  return (
    <SafeAreaView>
      <TitleBar />
      <ScreenSidePadding>
        <WizardHeadingText>Running simulation...</WizardHeadingText>
        <WizardBodyText style={tailwind('pt-6 text-base')}>
          We&apos;re busy building a portfolio blueprint based on the stocks you selected.
        </WizardBodyText>
        <View style={tailwind('pt-24 self-center')}>
          <SimulatorStep
            state={stepOneState}
            text="Running historical simulations."
            onTickAnimationFinished={onStepOneAnimationFinished}
          />
          <SimulatorStep
            state={stepTwoState}
            text="Constructing optimal combinations."
            onTickAnimationFinished={onStepTwoAnimationFinished}
          />
          <SimulatorStep
            state={stepThreeState}
            onTickAnimationFinished={onStepThreeAnimationFinished}
            text="Generating Portfolio statistics."
          />
        </View>
      </ScreenSidePadding>
    </SafeAreaView>
  );
});

const SimulatorStep: React.VFC<{
  state: SimulatorStepState;
  onTickAnimationFinished?: () => void;
  text: string;
  accessibilityLabel?: string;
}> = ({ state, onTickAnimationFinished, text }) => {
  const tailwind = useTailwind();
  const Icon: React.ReactNode =
    state === 'idle' ? (
      <View style={tailwind('h-6 w-6')} />
    ) : state === 'spinning' ? (
      <CircleSpinner height={24} width={24} />
    ) : (
      <CircleTick height={24} width={24} onAnimationFinished={onTickAnimationFinished} />
    );

  // Tick isn't animated in Web, so fire finish handler when state is 'finished'
  useEffect(() => {
    if (state === 'finished' && Platform.OS === 'web' && onTickAnimationFinished) {
      onTickAnimationFinished();
    }
  }, [state, onTickAnimationFinished]);
  const textColor = state === 'idle' ? 'text-warmGray-400' : 'text-warmGray-700';

  return (
    <View accessibilityLabel={state} style={tailwind('flex-row py-6 pr-4 items-center')}>
      <View style={tailwind('pr-4')}>{Icon}</View>
      <Text style={tailwind(`pr-4 text-sm ${textColor}`)}>{text}</Text>
    </View>
  );
};
