import { useNavigation } from '@react-navigation/core';
import { VFC } from 'react';
import { Image } from 'react-native';
import { HomeNavigationProps } from '..';
import { DatoAppContent } from '../../../generated/graphql';
import { shadowMd, useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { Text } from '../../../ui/Text';

// If desktop layout, use desktop max width instead
export const APP_CONTENT_CARD_WIDTH = 244;
export const APP_CONTENT_IMAGE_WIDTH = APP_CONTENT_CARD_WIDTH - 16;
export const APP_CONTENT_IMAGE_HEIGHT = 144;

type Props = Pick<DatoAppContent, 'id' | 'coverImage' | 'title' | 'excerpt'>;

export const AppContentCard: VFC<Props> = ({ id, coverImage, title, excerpt }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <Pressable
      style={[tailwind('px-2 pt-2 pb-4 rounded-lg'), shadowMd, { width: APP_CONTENT_CARD_WIDTH }]}
      accessibilityRole="button"
      onPress={() => navigation.push('AppContent', { appContentId: id })}
    >
      <Image
        style={[{ height: APP_CONTENT_IMAGE_HEIGHT, width: APP_CONTENT_IMAGE_WIDTH }, tailwind('rounded-xl')]}
        accessibilityIgnoresInvertColors
        source={{ uri: coverImage?.url ?? '' }}
      />
      <Text style={tailwind('text-warmGray-800 font-semibold text-base pt-3 pb-2 pl-1')}>{title}</Text>
      <Text numberOfLines={3} style={tailwind('text-warmGray-700 text-sm pl-1')}>
        {excerpt}
      </Text>
    </Pressable>
  );
};
