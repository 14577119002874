import { StyleProp, View, ViewStyle } from 'react-native';
import { shadowMd, useTailwind } from '../../theme';

export const Badge: React.FC<{ style?: StyleProp<ViewStyle>; size?: 'sm' | 'md' }> = ({ style, children, size }) => {
  const tailwind = useTailwind();
  return (
    <View
      style={[
        tailwind('w-6 h-6 rounded-full flex justify-center items-center'),
        size === 'sm' && tailwind('h-5 w-5'),
        shadowMd,
        style,
      ]}
    >
      <View style={tailwind('rounded-full overflow-hidden')}>{children}</View>
    </View>
  );
};
