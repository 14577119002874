import Svg, { Path, Rect, SvgProps } from 'react-native-svg';
import { colors } from '../../theme';

export const Upside = (props: SvgProps) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Rect width={16} height={16} rx={4} fill={props.color ?? colors.primary['default']} />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.347 10.904 13.5 6.21l-2.912-1.31-2.028 4.42c-.285.62-.557 1.158-1.333.81-.776-.35-.546-.907-.261-1.527l2.028-4.42-2.912-1.309-2.154 4.693c-1.062 2.315-.078 4.026 2.165 5.035 2.242 1.008 4.192.616 5.254-1.7Z"
        fill="#fff"
      />
    </Svg>
  );
};
