import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { VFC } from 'react';
import { View } from 'react-native';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { EXTERNAL_URIs } from '../../services/externalURIs';
import { useTailwind } from '../../theme';
import { ProfileRating } from '../icons/ProfileRating';
import { InfoBottomSheetBody, InfoBottomSheetHeading, InfoBottomSheetText } from '../InfoBottomSheet';
import { Link } from '../Link';

export const RatingExplainerBottomSheet: VFC<{ dismiss: () => void }> = ({ dismiss }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<NativeStackNavigationProp<LoggedInStackParamList>>();
  return (
    <InfoBottomSheetBody>
      <InfoBottomSheetHeading>Verified Users</InfoBottomSheetHeading>
      <InfoBottomSheetText>Users are verified based on their Idea usage and performance.</InfoBottomSheetText>
      <View style={tailwind('flex-row items-center pt-6')}>
        <InfoBottomSheetText style={tailwind('font-semibold')}>Active users → </InfoBottomSheetText>
        <ProfileRating style={tailwind('h-3 w-3')} isGold={false} />
      </View>
      <InfoBottomSheetText style={tailwind('py-1')}>Green tick users have initiated 10 Ideas.</InfoBottomSheetText>

      <View style={tailwind('flex-row items-center pt-4')}>
        <InfoBottomSheetText style={tailwind('font-semibold')}>Performing users → </InfoBottomSheetText>
        <ProfileRating style={tailwind('h-3 w-3')} isGold={true} />
      </View>

      <InfoBottomSheetText style={tailwind('py-1')}>
        Gold tick users have a proven track record of great Ideas. It’s our first public performance rating based on
        Idea efficacy.
      </InfoBottomSheetText>

      <View style={tailwind('flex-row flex-wrap py-4')}>
        <InfoBottomSheetText>Find out more about how to become a verified user </InfoBottomSheetText>
        <Link
          onPress={() => {
            navigation.navigate('WebviewScreen', { uri: EXTERNAL_URIs.VERIFIED_TICKS });
            dismiss();
          }}
        >
          here.
        </Link>
      </View>
    </InfoBottomSheetBody>
  );
};
