import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { View } from 'react-native';
import { GetInstrumentQuery } from '../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { Link } from '../Link';
import { InstrumentDescription, InstrumentDescriptionSkeleton } from './InstrumentDescription';
import { InstrumentAboutBox, InstrumentSummaryBoxSkeleton } from './InstrumentSummaryBox';
import { formatContent, formatPercent, getNextEarningsDateText } from './utils';

export type Props = {
  instrument: Pick<
    GetInstrumentQuery['instrument'],
    'currentFinancials' | 'countryOfListing' | 'industry' | 'sector' | 'quotePrice' | 'currency' | 'id' | 'companyInfo'
  >;
  /** Only show a subset in the partial view */
  partialView?: boolean;
};

export const InstrumentSummaryBody: React.FC<Props> = ({ instrument, partialView }) => {
  const tailwind = useTailwind();
  const navigator = useNavigation<LoggedInStackNavigationProps>();
  const { countryOfListing, industry, sector, currentFinancials, currency, quotePrice } = instrument;

  const nextEarningsDateText = getNextEarningsDateText(
    currentFinancials?.nextEarningsDateStart,
    currentFinancials?.nextEarningsDateEnd,
  );

  return (
    <View style={tailwind('pb-4')}>
      <View style={tailwind('flex-row justify-between items-center pb-5')}>
        <Text style={tailwind('text-xl font-semibold text-warmGray-800')}>Summary</Text>
        {partialView && (
          <Link
            redirectIfLoggedOut
            onPress={() => navigator.navigate('InstrumentSummary', { instrumentId: instrument.id })}
          >
            See all
          </Link>
        )}
      </View>
      {instrument.companyInfo && <InstrumentDescription>{instrument.companyInfo}</InstrumentDescription>}
      <Row>
        <InstrumentAboutBox
          title="Primary exchange"
          content={formatContent(currentFinancials?.primaryExchange?.toUpperCase())}
        />
        <InstrumentAboutBox title="Country of listing" content={formatContent(countryOfListing?.name)} />
      </Row>
      <Row>
        <InstrumentAboutBox title="Sector" content={formatContent(sector?.name)} />
        <InstrumentAboutBox title="Industry" content={formatContent(industry?.name)} />
      </Row>
      <Row>
        <InstrumentAboutBox
          title="Market cap"
          content={formatContent({ amount: currentFinancials?.marketCap, currencyIso: currency?.iso }, true)}
        />
        <InstrumentAboutBox title="Average volume" content={formatContent(currentFinancials?.averageVolume)} />
      </Row>
      <Row>
        <InstrumentAboutBox
          title="Previous close"
          content={formatContent({ amount: quotePrice?.previousClose, currencyIso: currency?.iso })}
        />
        <InstrumentAboutBox
          title="Open"
          content={formatContent({ amount: currentFinancials?.marketOpen, currencyIso: currency?.iso })}
        />
      </Row>
      <Row>
        <InstrumentAboutBox
          title="EPS"
          content={formatContent({ amount: currentFinancials?.eps, currencyIso: currency?.iso })}
        />
        <InstrumentAboutBox title="Dividend yield" content={formatPercent(currentFinancials?.dividendYield)} />
      </Row>
      {!partialView && (
        <>
          <Row>
            <InstrumentAboutBox
              title="Day range"
              content={`${formatContent({
                amount: currentFinancials?.dayLow,
                currencyIso: currency?.iso,
              })} - ${formatContent({ amount: currentFinancials?.dayHigh, currencyIso: currency?.iso })}`}
            />
            <InstrumentAboutBox
              title="52 Week range"
              content={`${formatContent({
                amount: currentFinancials?.fiftyTwoWeekLow,
                currencyIso: currency?.iso,
              })} - ${formatContent({ amount: currentFinancials?.fiftyTwoWeekHigh, currencyIso: currency?.iso })}`}
            />
          </Row>
          <Row>
            <InstrumentAboutBox title="P/E ratio" content={formatContent(currentFinancials?.peRatio)} />
            <InstrumentAboutBox title="PEG ratio" content={formatContent(currentFinancials?.pegRatio)} />
          </Row>
          <Row>
            {/* Next earnings date has custom text */}
            <InstrumentAboutBox title="Next earnings date" content={nextEarningsDateText} />
          </Row>
        </>
      )}
    </View>
  );
};

export const Row: React.FC = ({ children }) => {
  const tailwind = useTailwind();
  return <View style={tailwind('flex-row pt-4')}>{children}</View>;
};

export const InstrumentSummaryBodySkeleton = () => {
  return (
    <SkeletonView>
      <InstrumentDescriptionSkeleton />
      <Row>
        <InstrumentSummaryBoxSkeleton />
        <InstrumentSummaryBoxSkeleton />
      </Row>
      <Row>
        <InstrumentSummaryBoxSkeleton />
        <InstrumentSummaryBoxSkeleton />
      </Row>
    </SkeletonView>
  );
};
