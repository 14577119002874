import React from 'react';
import { FlatList, ListRenderItemInfo, View } from 'react-native';
import { GetUserProfileQuery } from '../../generated/graphql';
import { Divider } from '../../old/Divider';
import { InstrumentNavigationWrapper } from '../../old/InstrumentNavigationWrapper';
import { InstrumentPriceRow, InstrumentPriceRowSkeleton } from '../../old/InstrumentPriceRow';
import { useTailwind } from '../../theme';
import { SkeletonView } from '../../ui/Skeleton';

type InstrumentWatchlistItem = GetUserProfileQuery['publicInstrumentWatchlist'][0];

type Props = {
  instruments: InstrumentWatchlistItem[];
};

export const Watchlist: React.FC<Props> = ({ instruments }) => {
  const tailwind = useTailwind();
  return (
    <FlatList
      contentContainerStyle={tailwind('flex-grow px-6')}
      ItemSeparatorComponent={() => <Divider />}
      data={instruments}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
    />
  );
};

const renderItem = ({ item }: ListRenderItemInfo<InstrumentWatchlistItem>) => <Item {...item} />;

const Item: React.VFC<InstrumentWatchlistItem> = (item) => {
  const tailwind = useTailwind();
  return (
    <InstrumentNavigationWrapper instrumentId={item.instrument?.id ?? ''} style={tailwind('py-4')}>
      <InstrumentPriceRow {...item.instrument} showWatchlistToggle />
    </InstrumentNavigationWrapper>
  );
};

const keyExtractor = (item: InstrumentWatchlistItem) => `${item.instrument?.id}`;

export const WatchlistSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView style={tailwind('mt-8')}>
      {[...Array(10).keys()].map((key) => (
        <View key={key} style={tailwind('px-6 mt-4')}>
          <InstrumentPriceRowSkeleton />
        </View>
      ))}
    </SkeletonView>
  );
};
