import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { colors } from '../../theme';

export const ArrowUp: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <Path
        d="M0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893Z"
        fill={props.color ?? colors.blue[500]}
        strokeWidth="2"
      />
      <Path
        d="M3 1H11V9"
        stroke={props.color ?? colors.blue[500]}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
