import { clamp } from 'lodash';
import { FC } from 'react';
import { View, ViewStyle } from 'react-native';
import Animated, { useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { colors, useTailwind } from '../../theme';

export type Props = {
  /**
   * Percentage value between 0 and 1
   */
  progress: number;
  type?: 'primary' | 'secondary' | 'tertiary';
  width?: number;
  animate?: boolean;
  enableAnimation?: boolean;
  color?: string;
};

export const ProgressBar: FC<Props> = ({ progress, color = colors.primary.dark, width, animate, enableAnimation }) => {
  const tailwind = useTailwind();
  progress = clamp(progress, 0, 1) * 100;

  const fill = useDerivedValue(() => {
    if (enableAnimation) {
      if (animate) {
        return withSpring(progress, {
          stiffness: 120,
          damping: 12,
        });
      } else {
        return 0;
      }
    } else {
      return progress;
    }
  }, [progress, animate]);
  const barStyles = useAnimatedStyle<ViewStyle>(() => ({ width: `${fill.value}%` }));

  return (
    <View style={[tailwind('rounded-2xl flex-row w-full bg-gray-900/10 h-1.5'), Number.isFinite(width) && { width }]}>
      <Animated.View style={[tailwind('h-1.5 rounded-2xl'), { backgroundColor: color }, barStyles]} />
    </View>
  );
};
