import { useMemo } from 'react';
// @ts-ignore no corresponding types package available
import { connectToDevTools } from 'react-devtools-core';
import { Platform, View } from 'react-native';
import { enableScreens } from 'react-native-screens';
import { initNetInfo } from './hooks/useSetupNetInfo';
import { RootNavigator } from './navigation/RootNavigator';
import { getGraphqlClient } from './services/graphql';
import { LogBox } from './services/logbox';
import { initOpenAPI } from './services/openApi';
import { queryClient } from './services/react-query';
import { initReactotron } from './services/reactotron';
import { initSentry } from './services/sentry';
import { colors } from './theme';
import { RootAppProvider } from './ui/AppProvider';

if (Platform.OS !== 'web') {
  // Alas this doesnt seem to work
  LogBox.ignoreLogs([
    // we have a circular dependency on jwt/login/graphql/openApi for the logout calls. One day can fix with zustand and async actions https://github.com/facebook/metro/issues/287
    /Require cycle/,
    // turn off "Please install/enable redux devtools extension"
    /Please install/,
  ]);

  // only enable screens on native, not web
  enableScreens();
}

// Connect to flipper dev tools
if (__DEV__) {
  console.log(`Connecting to Flipper DevTools`);
  connectToDevTools({
    host: 'localhost',
    port: 8097,
  });
}

initSentry();
initReactotron();
initOpenAPI();
initNetInfo();

// eslint-disable-next-line import/no-default-export
export default function App() {
  const graphQlClient = useMemo(() => getGraphqlClient(), []);

  return (
    // Background color set to green in mobile to prevent white screen during transition from splash to landing screen
    <View style={[{ flexGrow: 1 }, Platform.OS !== 'web' && { backgroundColor: colors.leaf['500'] }]}>
      <RootAppProvider graphqlClient={graphQlClient} queryClient={queryClient}>
        <RootNavigator />
      </RootAppProvider>
    </View>
  );
}
