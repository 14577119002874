import { QueryClient } from 'react-query';

export const makeQueryClient = () => {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });
};

/**
 * Global Query client that's reset by replacing the binding with a new instance in tests and stories
 */
export let queryClient = makeQueryClient();

export const resetQueryClient = () => {
  queryClient.cancelQueries();
  queryClient = makeQueryClient();
};
