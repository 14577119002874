import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useInitialiseTradingPortfolioMutation } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'TradingAccountSubmit'>;

export const INITIALISE_TRADING_PORTFOLIO = gql`
  mutation InitialiseTradingPortfolio($initialPortfolioIntention: InitialPortfolioIntentionInput!) {
    initialiseTradingPortfolio(initialPortfolioIntention: $initialPortfolioIntention) {
      portfolio {
        id
        status
      }
      error {
        ... on TradingValidationError {
          message
          errors
        }
      }
    }
  }
`;

export const TradingAccountSubmit: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const [mutate, { loading, data }] = useInitialiseTradingPortfolioMutation({
    onCompleted: ({ initialiseTradingPortfolio: { error } }) => {
      if (error) {
        const validationError = createValidationError(error);
        console.error(validationError);
        toast.show('Something went wrong, please try again.');
      } else {
        navigation.replace('Tabs', { screen: 'HomeTab', params: { screen: 'Home' } });
      }
    },
    onError: (e) => {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    },
  });

  // Used to present wealthKernel JSON errors
  const submissionError = data?.initialiseTradingPortfolio.error;

  return (
    <SafeAreaView>
      <TitleBar title="Submit" />
      <ScreenSidePadding>
        <Text style={tailwind('text-center py-4')}>Press submit to register your trading account.</Text>
        {submissionError && (
          <View style={tailwind('py-4')}>
            <View style={tailwind('bg-red-100 p-4 rounded-md text-red-500')}>
              <Text style={tailwind('text-red-600')}>{submissionError.message}</Text>
              {Object.keys(submissionError.errors).map((k) => (
                <Text style={tailwind('text-red-600 py-1 pl-2')} key={k}>{`∙${k}: ${
                  (submissionError.errors as Record<string, string>)[k]
                }`}</Text>
              ))}
            </View>
          </View>
        )}
        <Button isDisabled={loading} loading={loading} onPress={mutate} text="Submit" />
      </ScreenSidePadding>
    </SafeAreaView>
  );
});
