import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { ClosePrice, Currency, Idea, Maybe, QuotePrice, useGetInPlayPricesQuery } from '../../generated/graphql';
import { InPlayChart } from '../../old/InPlayChart';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatDate, parseUTCDateFromString, subtractDay } from '../../util/date';

type Props = Pick<Idea, 'referenceDate' | 'referencePrice' | 'targetDate' | 'targetPrice' | 'position'> & {
  quotePrice?: Maybe<Pick<QuotePrice, 'midPrice' | 'lastUpdated'>>;
} & Pick<Currency, 'iso'> & { instrumentId?: string };

export const Performance: React.VFC<Props> = ({
  referenceDate,
  targetDate,
  iso,
  targetPrice,
  referencePrice,
  instrumentId,
  quotePrice,
}) => {
  const tailwind = useTailwind();
  if (!referenceDate || !targetDate || !iso || isNil(targetPrice) || isNil(referencePrice) || isNil(instrumentId)) {
    console.error(`Cannot render in play performance`);
    return null;
  }

  return (
    <>
      <Text style={tailwind('px-6 text-lg font-semibold text-warmGray-800 py-4')}>Performance</Text>
      <View style={tailwind('px-3')}>
        <PerformanceChart
          quotePrice={quotePrice}
          instrumentId={instrumentId}
          currencyIso={iso}
          targetDate={targetDate}
          referenceDate={referenceDate}
          targetPrice={targetPrice}
          referencePrice={referencePrice}
        />
      </View>
    </>
  );
};

export const GET_IN_PLAY_PRICES = gql`
  query getInPlayPrices($instrumentId: ID!, $dateFrom: Date!, $dateTo: Date!) {
    instrument(id: $instrumentId) {
      id
      closePriceSeries(dateFrom: $dateFrom, dateTo: $dateTo) {
        id
        price
        date
      }
    }
  }
`;

const PerformanceChart: React.VFC<{
  instrumentId: string;
  currencyIso: string;
  targetPrice: number;
  targetDate: string | Date;
  referenceDate: string | Date;
  referencePrice: number;
  quotePrice: Maybe<Pick<QuotePrice, 'midPrice' | 'lastUpdated'>> | undefined;
}> = ({ targetDate, referenceDate, targetPrice, referencePrice, instrumentId, quotePrice, currencyIso }) => {
  const ideaDuration = dayjs.duration(dayjs(targetDate).diff(referenceDate)).asDays();
  const startDate = subtractDay(parseUTCDateFromString(referenceDate), Math.ceil(ideaDuration / 2));
  const [width, setWidth] = React.useState(0);
  const { data } = useGetInPlayPricesQuery({
    variables: {
      instrumentId: instrumentId,
      dateFrom: formatDate(startDate, 'YYYY-MM-DD'),
      dateTo: targetDate,
    },
  });
  if (!data?.instrument.closePriceSeries) {
    return null;
  }
  const prices = data.instrument.closePriceSeries
    .map((p) => ({ date: p?.date, price: p?.price }))
    .filter(priceIsDefined);

  if (
    quotePrice?.midPrice &&
    quotePrice?.lastUpdated &&
    formatDate(quotePrice.lastUpdated, 'YYYY-MM-DD') > prices[prices.length - 1].date &&
    formatDate(quotePrice.lastUpdated, 'YYYY-MM-DD') <= targetDate
  ) {
    prices.push({ price: quotePrice.midPrice, date: formatDate(quotePrice.lastUpdated, 'YYYY-MM-DD') });
  }

  return (
    <View
      accessibilityLabel="Performance Chart"
      onLayout={(e) => {
        setWidth(e.nativeEvent.layout.width);
      }}
    >
      <InPlayChart
        reference={{ price: referencePrice, date: referenceDate }}
        target={{ price: targetPrice, date: targetDate }}
        currency={currencyIso}
        prices={prices}
        width={width}
      />
    </View>
  );
};

const priceIsDefined = (price: Maybe<Pick<ClosePrice, 'price' | 'date'>>): price is { date: string; price: number } =>
  ![price?.date, price?.price].some(isNil);
