import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as yup from 'yup';
import { TradingIdentifierType, useUpsertTradingPartyMutation } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { TextInput } from '../../old/inputs';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'TradingAccountPersonalDetails'>;

export const UPSERT_TRADING_PARTY = gql`
  mutation UpsertTradingParty($party: TradingPartyInput!) {
    upsertTradingParty(party: $party) {
      error {
        ... on TradingValidationError {
          message
          errors
        }
      }
    }
  }
`;

const schema = yup.object({
  title: yup.string().trim(),
  firstName: yup.string().trim().required('Required'),
  surname: yup.string().trim().required('Required'),
  telephoneNumber: yup
    .string()
    // Match pattern sourced from WealthKernel API docs https://docs.wealthkernel.com/docs/api/7d00a930e12bd-add-a-party
    .matches(/^(\+?)([\d ]{11,13})$/, 'Please enter a valid UK phone number')
    .required('Required'),
  dateOfBirth: yup
    .string()
    .trim()
    .matches(/^\d{8}$/, { message: 'Must be of the form DDMMYYYY' })
    .required('Required'),
  nationality: yup.string().trim().uppercase().required('Required'),
  taxResidency: yup.string().trim().uppercase().required('Required'),
  nationalInsuranceNumber: yup.string().trim().uppercase().required('Required'),
});

type FormSchema = yup.InferType<typeof schema>;

const initialValues: FormSchema = {
  title: '',
  firstName: '',
  surname: '',
  telephoneNumber: '+44',
  dateOfBirth: '',
  nationality: 'GB',
  taxResidency: 'GB',
  nationalInsuranceNumber: '',
};

export const TradingAccountPersonalDetails: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const [mutate, { loading, data, reset }] = useUpsertTradingPartyMutation();

  const onSubmit = async (values: FormSchema, helpers: FormikHelpers<FormSchema>) => {
    try {
      reset();
      const [day, month, year] = [
        values.dateOfBirth.slice(0, 2),
        values.dateOfBirth.slice(2, 4),
        values.dateOfBirth.slice(4),
      ];

      const result = await mutate({
        variables: {
          party: {
            forename: values.firstName,
            surname: values.surname,
            dateOfBirth: `${year}-${month}-${day}`,
            telephoneNumber: values.telephoneNumber,
            identifiers: [{ issuer: 'GB', type: TradingIdentifierType.Nino, value: values.nationalInsuranceNumber }],
            taxResidencies: values.taxResidency.split(','),
            nationalities: values.nationality.split(','),
          },
        },
      });

      const validationErrorResult = result.data?.upsertTradingParty.error;
      if (validationErrorResult) {
        console.error(createValidationError(validationErrorResult));
        helpers.setSubmitting(false);
        return;
      }
      navigation.replace('TradingAccountAddressDetails');
    } catch (e) {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView>
        <TitleBar title="Personal Details" />
        <ScrollView>
          <ScreenSidePadding>
            <Text>Please enter your details below.</Text>
            <Formik<FormSchema>
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={onSubmit}
              validationSchema={schema}
            >
              {({
                values: {
                  title,
                  firstName,
                  surname,
                  telephoneNumber,
                  dateOfBirth,
                  nationality,
                  taxResidency,
                  nationalInsuranceNumber,
                },
                handleBlur,
                handleChange,
                errors,
                handleSubmit,
              }) => {
                const submissionError = data?.upsertTradingParty.error;
                return (
                  <View style={tailwind('pt-4')}>
                    <TextInput
                      label="Title"
                      value={title}
                      errorMessage={errors.title}
                      onChangeText={handleChange('title')}
                      onBlur={handleBlur('title')}
                      placeholder="Title"
                      accessibilityLabel="Title"
                    />
                    <Spacer />
                    <TextInput
                      label="First name *"
                      value={firstName}
                      errorMessage={errors.firstName}
                      onChangeText={handleChange('firstName')}
                      onBlur={handleBlur('firstName')}
                      placeholder="First Name"
                      accessibilityLabel="First Name"
                    />
                    <Spacer />
                    <TextInput
                      label="Surname *"
                      value={surname}
                      errorMessage={errors.surname}
                      onChangeText={handleChange('surname')}
                      onBlur={handleBlur('surname')}
                      placeholder="Surname"
                      accessibilityLabel="Surname"
                    />
                    <Spacer />
                    <TextInput
                      label="Telephone number *"
                      value={telephoneNumber}
                      errorMessage={errors.telephoneNumber}
                      onChangeText={handleChange('telephoneNumber')}
                      onBlur={handleBlur('telephoneNumber')}
                      accessibilityLabel="Telephone number"
                    />
                    <Spacer />
                    <TextInput
                      label="Date of Birth *"
                      value={dateOfBirth}
                      errorMessage={errors.dateOfBirth}
                      onChangeText={handleChange('dateOfBirth')}
                      onBlur={handleBlur('dateOfBirth')}
                      placeholder="DDMMYYYY"
                      accessibilityLabel="Date of Birth"
                    />
                    <Spacer />
                    <TextInput
                      label="Nationality (Country Code) *"
                      value={nationality}
                      errorMessage={errors.nationality}
                      onChangeText={handleChange('nationality')}
                      onBlur={handleBlur('nationality')}
                      placeholder="eg. GB, DE for multiple"
                      accessibilityLabel="Nationality"
                    />
                    <Spacer />
                    <TextInput
                      label="Tax Residency (Country Code) *"
                      value={taxResidency}
                      errorMessage={errors.taxResidency}
                      onChangeText={handleChange('taxResidency')}
                      onBlur={handleBlur('taxResidency')}
                      placeholder="eg. GB, DE for multiple"
                      accessibilityLabel="Tax Residency"
                    />
                    <Spacer />
                    <TextInput
                      label="National Insurance number *"
                      value={nationalInsuranceNumber}
                      errorMessage={errors.nationalInsuranceNumber}
                      onChangeText={handleChange('nationalInsuranceNumber')}
                      onBlur={handleBlur('nationalInsuranceNumber')}
                      placeholder="National Insurance Number"
                      accessibilityLabel="National Insurance Number"
                    />
                    <Spacer />
                    {submissionError && (
                      <View style={tailwind('bg-red-100 p-4 rounded-md text-red-500')}>
                        <Text style={tailwind('text-red-600')}>{submissionError.message}</Text>
                        {Object.keys(submissionError.errors).map((k) => (
                          <Text style={tailwind('text-red-600 py-1 pl-2')} key={k}>{`∙${k}: ${
                            (submissionError.errors as Record<string, string>)[k]
                          }`}</Text>
                        ))}
                      </View>
                    )}
                    <Spacer />
                    <Button onPress={handleSubmit} loading={loading} isDisabled={loading} text="Next" />
                    <Spacer />
                  </View>
                );
              }}
            </Formik>
          </ScreenSidePadding>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
});

const Spacer: FC = () => {
  const tailwind = useTailwind();
  return <View style={tailwind('py-2')} />;
};
