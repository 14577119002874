/**
 * Taken from react-query https://github.com/tannerlinsley/react-query/blob/76e314d0887edbd702369e8554cc840508561b94/src/core/utils.ts#L378
 * Shallow compare objects. Only works with objects that always have the same properties.
 */
export function shallowEqualObjects<T>(a: T, b: T): boolean {
  if ((a && !b) || (b && !a)) {
    return false;
  }

  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}
