import Svg, { Path, SvgProps } from 'react-native-svg';

export const Tracker = (props: SvgProps) => (
  <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" {...props}>
    <Path
      d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16Z"
      stroke={props.color ?? '#fff'}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9 13.8a4.8 4.8 0 1 0 0-9.6 4.8 4.8 0 0 0 0 9.6Z"
      stroke={props.color ?? '#fff'}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M9 10.6a1.6 1.6 0 1 0 0-3.2 1.6 1.6 0 0 0 0 3.2Z"
      stroke={props.color ?? '#fff'}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const TrackerBold = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M8 3.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9ZM8 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill={props.color ?? '#fff'}
    />
    <Path d="M8 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill={props.color ?? '#fff'} />
    <Path
      d="M8 0a8 8 0 1 0 8 8 8.023 8.023 0 0 0-8-8Zm0 13.5A5.5 5.5 0 1 1 13.5 8 5.506 5.506 0 0 1 8 13.5Z"
      fill={props.color ?? '#fff'}
    />
  </Svg>
);
