import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useEffect, useMemo, useState, VFC } from 'react';
import { Image, RefreshControl, ScrollView, useWindowDimensions, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { CLIENT_URL } from '../../constants/env';
import { layout } from '../../constants/layout';
import { useGetBlogQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { IconButton } from '../../old/Button';
import { Share as ShareIcon } from '../../old/icons';
import { NewsSkeleton } from '../../old/NewsSkeleton';
import { TitleBar } from '../../old/TitleBar';
import { share } from '../../services/sharing';
import { useTailwind } from '../../theme';
import { Markdown } from '../../ui/Markdown';
import { useMarkdownStyle } from '../../ui/Markdown/useMarkdownStyle';
import { DESKTOP_MAX_WIDTH, SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { getReadTime } from '../../util/string';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export const GET_BLOG = gql`
  query getBlog($id: ID!, $imgixWidth: Int) {
    datoBlog(id: $id, markdown: false, imgixParams: { width: $imgixWidth }) {
      blog {
        id
        title
        slug
        excerpt
        description
        destination
        publishedDate
        coverImage {
          url
        }
      }
    }
  }
`;

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'Blog'>;

export const Blog: VFC<Props> = withReloadErrorBoundary(
  ({
    route: {
      params: { blogId },
    },
  }) => {
    const markdownStyle = useMarkdownStyle();
    const tailwind = useTailwind();
    const { isUserLoggedIn: loggedIn, userId } = usePersistedStore(({ isUserLoggedIn, userId }) => ({
      isUserLoggedIn,
      userId,
    }));
    const toast = useToast();
    const { width } = useWindowDimensions();

    const {
      data,
      loading: dataLoading,
      refetch,
      error,
    } = useGetBlogQuery({
      variables: { id: blogId, imgixWidth: Math.floor(Math.min(width, DESKTOP_MAX_WIDTH) * layout.pixelRatio) },
    });

    const { refreshing, onRefresh } = useRefresh(refetch);
    const [imageDimensions, setImageDimensions] = useState({ width: Math.min(width, DESKTOP_MAX_WIDTH), height: 0 });
    const [imageLoaded, setImageLoaded] = useState(false);

    if (error) {
      throw error;
    }

    const onShare = async () => {
      try {
        const status = await share(
          {
            message: `Check out this Upside Blog: "${data?.datoBlog.blog?.title}"`,
            url: `${CLIENT_URL}/Blog/${blogId}/`,
            title: `Upside Blog: "${data?.datoBlog.blog?.title}"`,
          },
          userId,
        );
        if (status === 'CopiedToClipboard') {
          toast.show('Link copied to clipboard.');
        }
      } catch (e) {
        console.error(e);
        toast.show('Unable to share link. Please try again');
      }
    };

    // Image renders with height 0 unless height specified. Set height style to height of image received once loaded.
    useEffect(() => {
      if (data) {
        Image.getSize(data?.datoBlog.blog?.coverImage?.url ?? '', (width, height) => {
          setImageDimensions({ width: width / layout.pixelRatio, height: height / layout.pixelRatio });
          setImageLoaded(true);
        });
      }
    }, [data]);

    const loading = dataLoading || !imageLoaded;

    const readTime = useMemo(() => {
      const words = data?.datoBlog.blog?.description?.split(' ') ?? [];
      return getReadTime(words.length);
    }, [data]);

    return (
      <SafeAreaView>
        <TitleBar
          redirectIfLoggedOut
          showLogo={!loggedIn}
          hideBackButton={!loggedIn}
          endAdornment={
            <IconButton accessibilityLabel="Share" icon={<ShareIcon height={24} width={24} />} onPress={onShare} />
          }
        />
        {loading ? (
          <NewsSkeleton />
        ) : (
          <ScrollView
            contentContainerStyle={tailwind('pt-4 pb-6')}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          >
            <Text style={{ ...tailwind('px-6 pb-4 text-center'), ...markdownStyle.heading1 }}>
              {data?.datoBlog?.blog?.title}
            </Text>
            <View style={tailwind('pt-2 pb-4')}>
              <Image
                accessibilityIgnoresInvertColors
                source={{ uri: data?.datoBlog.blog?.coverImage?.url ?? '' }}
                style={imageDimensions}
                resizeMode="contain"
              />
            </View>
            <View style={tailwind('px-6')}>
              <Text style={tailwind('text-warmGray-600')}>
                {formatDate(new Date(data?.datoBlog.blog?.publishedDate ?? ''), 'Do MMM YYYY')}
                {' ・ '}
                <Text style={tailwind('italic text-warmGray-600')}>{readTime} min read </Text>
              </Text>
            </View>
            <View style={tailwind('px-6 pt-2')}>
              <Markdown>{data?.datoBlog?.blog?.description ?? ''}</Markdown>
            </View>
          </ScrollView>
        )}
      </SafeAreaView>
    );
  },
);
