/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentWatchlistItem } from '../models/InstrumentWatchlistItem';
import type { InstrumentWatchlistSummary } from '../models/InstrumentWatchlistSummary';
import { request as __request } from '../core/request';

export class InstrumentWatchlistService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiInstrumentWatchlistItemList({
        instrumentIds,
        cursor,
        pageSize,
    }: {
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<InstrumentWatchlistItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-watchlist-item/`,
            query: {
                'instrument_ids': instrumentIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * Set the user of the watchlist item to the current user and checks that the instrument doesn't already have
     * a watchlist item.
     * @returns InstrumentWatchlistItem
     * @throws ApiError
     */
    public static async apiInstrumentWatchlistItemCreate({
        data,
    }: {
        data: InstrumentWatchlistItem,
    }): Promise<InstrumentWatchlistItem> {
        const result = await __request({
            method: 'POST',
            path: `/api/instrument-watchlist-item/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns InstrumentWatchlistItem
     * @throws ApiError
     */
    public static async apiInstrumentWatchlistItemRead({
        uuid,
    }: {
        /** A UUID string identifying this instrument watchlist item. **/
        uuid: string,
    }): Promise<InstrumentWatchlistItem> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-watchlist-item/${uuid}/`,
        });
        return result.body;
    }

    /**
     * Deactivates the watchlist item.
     * @returns void
     * @throws ApiError
     */
    public static async apiInstrumentWatchlistItemDelete({
        uuid,
    }: {
        /** A UUID string identifying this instrument watchlist item. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/instrument-watchlist-item/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns InstrumentWatchlistSummary
     * @throws ApiError
     */
    public static async apiInstrumentWatchlistSummaryRead({
        user,
    }: {
        user: string,
    }): Promise<InstrumentWatchlistSummary> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-watchlist-summary/${user}/`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiUserInstrumentWatchlistItemList({
        user,
        cursor,
        pageSize,
    }: {
        /** The user to filter results for **/
        user?: number,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<InstrumentWatchlistItem>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/user-instrument-watchlist-item/`,
            query: {
                'user': user,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

}
