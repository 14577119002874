import dayjs from 'dayjs';
import { isNil, isNumber } from 'lodash';
import { Maybe } from 'yup/lib/types';
import { formatNumber, formatPercent as formatPercentBase, formatPrice } from '../../util/number';
import { isMoney, isNullish } from '../../util/typeGuards';
import { Money, Nullable } from '../../util/types';

/**
 * Created to avoid inline conditional formatting calls and defensive coding for the long list of properties in the instrument summary.
 *
 * @param content number, string or Money value
 * @returns A formatted number, string or price/money. Defaults to N/A if content is nullish
 */
export const formatContent = (content?: Maybe<string | number | Partial<Nullable<Money>>>, average = false): string => {
  if (isNullish(content)) {
    return 'N/A';
  } else if (isMoney(content)) {
    if (!isNumber(content.amount)) return 'N/A';
    return formatPrice(content.amount, content.currencyIso, { average });
  } else if (typeof content === 'string') {
    return content;
  } else if (typeof content === 'number') {
    return formatNumber(content);
  } else return 'N/A';
};

export const getNextEarningsDateText = (start?: Maybe<string | Date>, end?: Maybe<string | Date>) => {
  if (!(start && end)) return 'N/A';

  return `${formatEarningsDate(new Date(start))} - ${formatEarningsDate(new Date(end))}`;
};

export const formatEarningsDate = (date: Date) => dayjs(date).format('DD MMM YYYY');

/**
 *
 * @param x Percentage value 1 is 100%
 * @returns If value is defined, a formatted percentage, else, N/A
 */
export const formatPercent = (x: Maybe<number>): string => (!isNil(x) ? formatPercentBase(x, 2, false) : 'N/A');
