import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useEffect, VFC } from 'react';
import { View } from 'react-native';
import { FEED_CARD_FIELDS } from '../../fragments/feed';
import { useGetFeedItemQuery } from '../../generated/graphql';
import { AllStackParamList } from '../../navigation';
import { CardSwitch } from '../../old/Feed';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

/* eslint-disable graphql/template-strings */
export const GET_FEED_ITEM = gql`
  ${FEED_CARD_FIELDS}
  query getFeedItem($id: ID!, $loggedIn: Boolean!) {
    feedItem(id: $id) {
      ...FeedCardFields
    }
  }
`;

export type Props = NativeStackScreenProps<AllStackParamList, 'FeedItem'>;
/** Proxy screen to re-direct to relevant feed item screen */
export const FeedItem: VFC<Props> = withReloadErrorBoundary(({ route, navigation }) => {
  const tailwind = useTailwind();
  const { feedItemId } = route.params;
  const loggedIn = usePersistedStore((s) => s.isUserLoggedIn);
  const { data, error } = useGetFeedItemQuery({ variables: { id: feedItemId, loggedIn } });
  const feedItem = data?.feedItem;

  if (error) {
    throw error;
  }

  // When feedItem loaded, parse feed item type and navigate to relevant screen
  useEffect(() => {
    if (feedItem) {
      switch (feedItem.__typename) {
        case 'CorrelationFeedItem':
          navigation.replace('Instrument', {
            instrumentId: feedItem.subjectInstrument?.id ?? '',
            feedItemId: feedItem.id,
          });
          break;
        case 'NewsFeedItem':
          navigation.replace('News', {
            newsId: feedItem.id,
          });
          break;
        case 'UpsideVoiceFeedItem':
          navigation.replace('Voice', {
            voiceId: feedItem.id,
          });
          break;
        default:
          // TODO: Update this to exhaustive switch or create feed item sharing util fns
          if ('instrument' in feedItem) {
            navigation.replace('Instrument', {
              instrumentId: feedItem.instrument?.id ?? '',
              feedItemId: feedItem.id,
            });
          } else {
            // Log error and attempt to render feed item in card switch
            console.error(
              `Feed item received but no redirect link specified, id: ${'id' in feedItem ? feedItem.id : ''}`,
            );
          }
      }
    }
  }, [feedItem, navigation]);

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar redirectIfLoggedOut />
      <View style={tailwind('flex-grow')}>{feedItem && <CardSwitch card={feedItem} />}</View>
    </SafeAreaView>
  );
});
