import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Chat = (props: SvgProps) => (
  <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
    <Path
      d="M12 .5H2a.934.934 0 0 0-.707.342A1.272 1.272 0 0 0 1 1.667v7c0 .31.105.606.293.825A.934.934 0 0 0 2 9.833h3l2 2.8 2-2.8h3c.265 0 .52-.123.707-.341.188-.22.293-.516.293-.825v-7c0-.31-.105-.607-.293-.825A.934.934 0 0 0 12 .5ZM4.033 4 10 3.967M4 6.6l4-.033"
      stroke={props.color ?? '#78716C'}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);
