import { FC } from 'react';
import { TextProps, View, ViewProps } from 'react-native';
import { Instrument, Maybe } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { Upside } from '../icons';
import { Text } from '../Text';

export type Component = {
  instrument?: Maybe<Pick<Instrument, 'id' | 'logoUrl'>>;
};

export type CommonProps = {
  title: string;
  subText: string;
  components: Component[];
  componentCount: number;
  onPress?: () => undefined;
  selected?: boolean;
  weight: number;
};

export const Heading: FC = ({ children }) => {
  const tailwind = useTailwind();
  return (
    <Text numberOfLines={1} style={tailwind('text-sm font-medium text-default')}>
      {children}
    </Text>
  );
};

export const ByUpside: FC<Pick<ViewProps, 'style'>> = ({ style }) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('flex-row justify-start items-center'), style]}>
      <SubText style={tailwind('mr-1.5')}>By Upside</SubText>
      <Upside width={14} height={14} color={colors.primary['dark']} />
    </View>
  );
};

export const SubText: FC<Pick<TextProps, 'style'>> = ({ children, style }) => {
  const tailwind = useTailwind();
  return (
    <Text numberOfLines={1} style={[tailwind('text-xs text-gray-600'), style]}>
      {children}
    </Text>
  );
};
