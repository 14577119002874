import { Platform } from 'react-native';
import { ENVIRONMENT, VERSION } from '../../constants/env';
import { FeedItem, PrePortfolio } from '../../generated/graphql';
import { ConvictionOption } from '../../screens/CreateIdea/stages/Conviction';
import { TimeframeOption } from '../../screens/CreateIdea/stages/Timeframe';
import { StringKey } from '../../util/types';
import { usePersistedStore } from '../../zustand/store';

// Metadata appended to events. Ideally flat, but can change property type to any if needed in future.
export type EventMetadata = {
  [key: string]: string | boolean | number;
};

/** matches mailchimp merge tag possible values */
export type MailchimpSignupSource = 'mobile' | 'test-environment';

/** matches mailchimp merge tag possible values */
export type MailchimpMarketingOptions = 'yes' | 'no';

/**
 * Any of these traits are sent to mailchimp if they have a corresponding matching Merge tag (10 chars max)
 */
export type IdentifyMetaData = {
  /** in mailchimp too */
  email?: string;
  nickname?: string;
  isStaff?: boolean;
  /** Watchlist Count, in mailchimp so can send emails if haven't added to watchlist */
  watchCount?: number;
  /** signup source, in mailchimp too */
  signup?: MailchimpSignupSource;
  /** for mailchimp */
  userId?: string;
  /** for mailchimp, if ticked allow marketing email on signup */
  marketing?: MailchimpMarketingOptions;
  /** for mailchimp to personalise the email */
  firstName?: string;
  /** true when user given permission for push notification */
  grantedPushToken?: boolean;
  hasProfileImage?: boolean;
  /** pass on utm param from branch */
  installUtmSource?: string;
  /** pass on utm param from branch */
  installUtmMedium?: string;
  /** pass on utm param from branch */
  installUtmCampaign?: string;

  /**
   * Pass on device info explicitly to mixpanel as device info within segment not passed on
   * device info was working for android mixpanel but not ios strangely. So sending it explicitly
   */
  deviceOsName?: string | null;
  deviceOsVersion?: string | null;
  deviceBrand?: string | null;
  deviceManufacturer?: string | null;
  deviceModelName?: string | null;
  devicePlatformApiLevel?: number | null;
  deviceWindowWidth?: number | null;
  deviceWindowHeight?: number | null;
  deviceScreenWidth?: number | null;
  deviceScreenHeight?: number | null;
};

export type AnalyticsEvent = {
  ['Log in']: null;
  ['Feed card viewed']: {
    ['Feed item id']: FeedItem['id'];
    ['Feed card type']: FeedItem['__typename'];
    ['Minimum view time (s)']: string;
  };
  ['Completed scroll']: {
    'Cards in feed': number;
    'Time to scroll end (s)': number;
  };
  ['Feed card pressed']: {
    ['Feed item id']: FeedItem['id'];
    ['Instrument ticker']?: string;
    ['Feed card type']?: FeedItem['__typename'];
    ['Destination screen']: string;
  };
  ['Feed card not interested']: {
    ['Feed item id']: FeedItem['id'];
    ['Feed card type']?: FeedItem['__typename'];
  };
  ['Watchlist changed']: {
    action: 'add' | 'remove';
    'Instrument ticker': string;
    'Instrument id': string;
  };
  ['Idea watchlist changed']: {
    action: 'add' | 'remove';
    'Idea id': string;
    'Author name': string;
    'Author id': string;
    'Instrument ticker': string;
    'Instrument id': string;
  };
  ['Loaded a Page']: {
    name: string;
    title: string;
    initialPath: string;
    url?: string;
  } & { [key: string]: string };
  ['Idea created']: {
    ideaId: string;
    'Instrument ticker': string;
    'Instrument name': string;
    sentiment: 'LONG' | 'SHORT';
    rationales: string[];
    conviction: ConvictionOption;
    timeframe: TimeframeOption;
    prediction: number;
    // these needed so have all info in payload to post to slack
    userId: string;
    nickname: string;
  };
  ['Creating idea']:
    | { sentiment: 'LONG' | 'SHORT' }
    | { priceTargetPercent: number | string }
    | { timeframe: TimeframeOption }
    | { conviction: ConvictionOption }
    | { rationale: string; selected: boolean };
  ['Viewed Stock Page']: {
    instrumentId?: string | null;
    instrumentName?: string | null;
    ticker?: string | null;
    sector?: string | null;
  };
  ['Button Pressed']: {
    type: 'button' | 'link' | 'icon';
    label: string;
    isUserLoggedIn: boolean;
  };

  /** notification pressed from a push or notification centre */
  ['Notification Pressed']: {
    notificationSource: 'pushNotification' | 'notificationCentre';
    action: 'deepLink' | 'bottomSheet';
    /** for deep link notifications */
    deepLinkUrl?: string;
  };
  ['Portfolio Blueprint built']: {
    nickname?: string;
    riskLevel: PrePortfolio['selectedRiskLevel'];
    cashProportion: PrePortfolio['cashProportion'];
    'Member tickers': string[];
    notional: PrePortfolio['notional'];
  };
  ['Branch Deeplink']: {
    deepLinkUrl?: string;
  };
  ['Landing pager']: {
    page: number;
  };
  ['Search item pressed']: {
    type: 'User' | 'Instrument';
    id: string;
    name: string;
    'Search term': string;
  };
  ['Search typed']: {
    'Search term': string;
  };
  /** cannot identify anon users in mixpanel, so use this event to track anonymous logged out user properties */
  ['Logged out user']: {
    /**
     * device info was working for android mixpanel but not ios strangely. So sending it explicitly
     */
    deviceOsName?: string | null;
    deviceOsVersion?: string | null;
    deviceBrand?: string | null;
    deviceManufacturer?: string | null;
    deviceModelName?: string | null;
    devicePlatformApiLevel?: number | null;
    deviceWindowWidth?: number | null;
    deviceWindowHeight?: number | null;
    deviceScreenWidth?: number | null;
    deviceScreenHeight?: number | null;
  };
  /** cannot identify anon users in mixpanel, so use this event to track anonymous logged out utm params */
  ['Logged out UTM params']: {
    /** pass on utm param from branch */
    installUtmSource?: string;
    /** pass on utm param from branch */
    installUtmMedium?: string;
    /** pass on utm param from branch */
    installUtmCampaign?: string;
  };
  ['Select risk level']: {
    riskLevel: 'low' | 'medium' | 'high';
    action: 'drag' | 'press';
  };
};

/** default meta data added to all track and identify */
export const defaults: EventMetadata = {
  env: ENVIRONMENT,
  platform: Platform.OS,
  appVersion: VERSION,
  anonymousId: usePersistedStore.getState().anonymousId ?? '',
};

/** common interface for native and web implementation */
export type Analytics = {
  /** do not use identify with anonymous user (null/empty userId) as not compatible with mixpanel merging profiles when sign up */
  identify: (userId: string, metadata?: IdentifyMetaData) => void;
  track: <T extends AnalyticsEvent, K extends StringKey<T>>(event: K, metadata: T[K]) => void;
  flush: () => void;
  reset: () => void;
};
