import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC, useState } from 'react';
import { Image, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { UpdateProfileImageMutation } from '../../generated/graphql';
import { useUpdateProfileImage } from '../../hooks/mutations/profileImage';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { Camera } from '../../old/icons';
import { Link } from '../../old/Link';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { analytics } from '../../services/analytics';
import { createImageFile } from '../../services/createImageFile';
import { requestProfilePhoto } from '../../services/profilePhoto';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'UploadProfileImageSignup'>;

export const UploadProfileImageSignup: FC<Props> = withReloadErrorBoundary(
  ({
    navigation,
    route: {
      params: { nickname },
    },
  }) => {
    const tailwind = useTailwind();
    const toast = useToast();
    const userId = usePersistedStore((state) => state.userId);
    const [loadingImage, setLoadingImage] = useState(false);
    const [imageUri, setImageUri] = useState<string | null>(null);
    const [updateProfileImage, { loading: mutationLoading }] = useUpdateProfileImage({
      onError: (e) => {
        console.error(e);
        toast.show('Something went wrong, please try again.');
      },
      onCompleted: (data) => {
        const validationError = data?.updateProfileImage.error;
        if (validationError) {
          console.error(validationError);
          toast.show(getValidationMessage(validationError));
        } else {
          analytics.identify(`${userId}`, { hasProfileImage: true });
          toast.show('Your profile image has been saved.');
          navigation.replace('Tabs', { screen: 'HomeTab', params: { screen: 'Home' } });
        }
      },
    });

    const onChooseImage = async () => {
      setLoadingImage(true);
      const imageUri = await requestProfilePhoto();
      setLoadingImage(false);
      if (!imageUri) return;
      setImageUri(imageUri ?? '');
    };

    const onSaveImage = async () => {
      const file = await createImageFile(imageUri ?? '');
      updateProfileImage({ variables: { image: file } });
    };

    const loading = loadingImage || mutationLoading;
    return (
      <SafeAreaView>
        <TitleBar
          hideBackButton
          endAdornment={
            <Link
              disabled={loading}
              onPress={() => navigation.replace('Tabs', { screen: 'HomeTab', params: { screen: 'Home' } })}
            >
              Skip
            </Link>
          }
        />
        <ScreenSidePadding style={tailwind('flex-grow')}>
          <Text style={tailwind('text-2xl font-semibold text-warmGray-800 pb-3')}>Add a profile image</Text>
          <Text style={tailwind('text-sm text-warmGray-500 pb-11')}>
            Add a profile image so your friends know it{"'"}s you.
          </Text>
          <View>
            {imageUri ? (
              <Image
                style={[tailwind('self-center rounded-full'), { height: 200, width: 200 }]}
                accessibilityIgnoresInvertColors
                source={{ uri: imageUri }}
              />
            ) : (
              <Pressable
                accessibilityRole="button"
                onPress={onChooseImage}
                disabled={loading}
                style={{
                  height: 200,
                  width: 200,
                  ...tailwind('rounded-full justify-center self-center items-center bg-warmGray-100'),
                }}
              >
                <Camera hideCircle height={48} width={48} />
              </Pressable>
            )}
            <Text style={tailwind('pt-6 text-center text-warmGray-500 text-base font-medium')}>{nickname}</Text>
            <View style={tailwind('py-6')} />
            <View>
              <Button
                isDisabled={loading}
                loading={loading}
                onPress={onChooseImage}
                variant="inverted"
                text="Choose from library"
              />
            </View>
          </View>
          <View style={tailwind('flex-1 justify-end py-2')}>
            <Button
              loading={loading}
              isDisabled={!imageUri || loading}
              onPress={onSaveImage}
              variant="primary"
              text="Continue"
            />
          </View>
        </ScreenSidePadding>
      </SafeAreaView>
    );
  },
);

const getValidationMessage = (validationError: UpdateProfileImageMutation['updateProfileImage']['error']) => {
  switch (validationError?.__typename) {
    case 'EmptyFile':
      return 'This file is empty, please upload another.';
    case 'FileSizeInvalid':
      return 'This file is too large, please upload a smaller file.';
    case 'FileTypeInvalid':
      return 'This file is invalid, please upload an image file.';
    default:
      return 'Something went wrong, please try again.';
  }
};
