import * as scale from 'd3-scale';
import * as shape from 'd3-shape';

export type Boundaries = {
  earliest: number;
  latest: number;
  lowest: number;
  highest: number;
};

export type ChartData = {
  boundaries: Boundaries;
  prices: [number, number][];
};

export type Price = {
  date: string | Date;
  price: number;
};

export const getPriceBoundaries = (prices: [number, number][]): Boundaries => ({
  earliest: Math.min(...prices.map((d) => d[0])),
  latest: Math.max(...prices.map((d) => d[0])),
  lowest: Math.min(...prices.map((d) => d[1])),
  highest: Math.max(...prices.map((d) => d[1])),
});

export const getPath = (
  data: { prices: Price[]; min: number; max: number },
  width: number,
  height: number,
): string | undefined => {
  if (data.prices.length === 0) {
    return undefined;
  }
  const timeScale = scale
    .scaleLinear()
    .domain([0, data.prices.length - 1])
    .range([0, width]);
  const priceScale = scale.scaleLinear().domain([data.min, data.max]).range([height, 0]);
  const line = shape
    .line()
    .x((_, i) => timeScale(i))
    .y(([_, p]) => priceScale(p))
    .curve(shape.curveMonotoneX)(data.prices.map((p, i) => [i, p.price]));

  return line ?? undefined;
};
