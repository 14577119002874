/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { request as __request } from '../core/request';

export class InvitationCodeService {

    /**
     * Set invitation code as used and copy code metadata to user
     * Setting an already used invitation code as used again is allowed and overrides the user metadata.
     * @returns any
     * @throws ApiError
     */
    public static async authUseInvitationCodeUserCreate({
        invitationCode,
        userId,
    }: {
        invitationCode: string,
        userId: string,
    }): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/auth/use-invitation-code/${invitationCode}/user/${userId}`,
        });
        return result.body;
    }

    /**
     * Validate Invitation code
     * Checks that the invitation code exists and has not been used yet.
     * @returns any
     * @throws ApiError
     */
    public static async authValidateInvitationCodeRead({
        invitationCode,
    }: {
        invitationCode: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/auth/validate-invitation-code/${invitationCode}/`,
        });
        return result.body;
    }

}
