import { FC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { formatPercent } from '../../util/number';
import { Pressable } from '../Pressable';
import { Text } from '../Text';
import { CommonProps, Heading } from './common';

export const PortfolioCashCard: FC<Pick<CommonProps, 'weight' | 'onPress'>> = ({ onPress, weight }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      style={[tailwind('flex-1 px-3 py-2 rounded-2xl border-dashed border border-tertiary-default')]}
      onPress={onPress}
      accessibilityRole="button"
    >
      <Heading>Cash</Heading>
      <View style={tailwind('flex-1 items-end justify-between flex-row')}>
        <Text style={tailwind('text-tertiary-dark text-xs font-medium')}>{formatPercent(weight, 1, false, true)}</Text>
      </View>
    </Pressable>
  );
};
