import { gql, NetworkStatus } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useMemo } from 'react';
import { FlatList, Image, ListRenderItem, RefreshControl, useWindowDimensions, View } from 'react-native';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { GetDiscoverCategoryQuery, useGetDiscoverCategoryQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Divider } from '../../old/Divider';
import { InstrumentNavigationWrapper } from '../../old/InstrumentNavigationWrapper';
import { InstrumentPriceRow, InstrumentPriceRowSkeleton } from '../../old/InstrumentPriceRow';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { formatDatoImageUrl } from '../../services/datoImage';
import { useTailwind } from '../../theme';
import { DESKTOP_MAX_WIDTH, SafeAreaView } from '../../ui/SafeAreaView';
import { SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { Unpack } from '../../util/types';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

/** no item limit here, there is a limit of 50 is on backend */
/* eslint-disable graphql/template-strings */
export const getDiscoverCategory = gql`
  ${CORE_INSTRUMENT_FIELDS}
  query getDiscoverCategory($id: ID, $categoryType: DiscoverCategoryType!, $loggedIn: Boolean!) {
    discoverCategory(input: { id: $id, categoryType: $categoryType }) {
      id
      title
      description
      imageUrl
      items {
        ... on MostWatchlistedFeedItem {
          id
          instrument {
            ...CoreInstrumentFields
          }
        }
        ... on DiscoverCategoryFeedItem {
          id
          instrument {
            ...CoreInstrumentFields
          }
        }
        ... on SignificantDailyMoverFeedItem {
          id
          instrument {
            ...CoreInstrumentFields
          }
        }
      }
    }
  }
`;

type DiscoverCategory = GetDiscoverCategoryQuery['discoverCategory'];
type DiscoverCategoryFeedItem = Unpack<DiscoverCategory['items']>;
/** Used to prepend Image and title and make use of stickyHeaderIndices to make Title and description sticky */
type PrependedPresentationalItem = { id: string; __typename: 'TitleAndDescription' | 'Image' };
const titleAndDescriptionPlaceholder: PrependedPresentationalItem = {
  id: 'TitleAndDescription',
  __typename: 'TitleAndDescription',
};
const imagePlaceholder: PrependedPresentationalItem = {
  id: 'Image',
  __typename: 'Image',
};

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'DiscoverCategory'>;

export const DiscoverCategory: React.FC<Props> = withReloadErrorBoundary(({ route }) => {
  const tailwind = useTailwind();
  const { categoryId, categoryType } = route.params;
  const { width } = useWindowDimensions();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const { data, previousData, error, refetch, networkStatus } = useGetDiscoverCategoryQuery({
    variables: {
      id: categoryId,
      categoryType: categoryType,
      loggedIn,
    },
  });
  const { refreshing, onRefresh } = useRefresh(refetch);
  if (error) {
    throw error;
  }

  const discoverCategoryItems = data?.discoverCategory.items ?? previousData?.discoverCategory.items;

  /// Prepend image and title placeholders
  const flatListData = useMemo(
    () => [imagePlaceholder, titleAndDescriptionPlaceholder, ...(discoverCategoryItems ?? [])],
    [discoverCategoryItems],
  );
  const imageWidth = Math.min(width, DESKTOP_MAX_WIDTH);
  const IMAGE_HEIGHT = 196;

  const renderItem: ListRenderItem<DiscoverCategoryFeedItem | PrependedPresentationalItem> = useCallback(
    ({ item }) => {
      switch (item.__typename) {
        case 'Image':
          return (
            <View>
              {!!data?.discoverCategory.imageUrl?.length && (
                <Image
                  accessibilityIgnoresInvertColors
                  style={{ height: IMAGE_HEIGHT, width: imageWidth }}
                  source={{
                    uri: formatDatoImageUrl(data.discoverCategory.imageUrl, { width, height: IMAGE_HEIGHT }),
                  }}
                  resizeMode="cover"
                />
              )}
            </View>
          );
        case 'TitleAndDescription':
          return (
            <View style={tailwind('bg-white')}>
              <Text style={tailwind('text-lg font-semibold px-6 pt-3 pb-2')}>{data?.discoverCategory?.title}</Text>
              <Text style={tailwind('px-6 pb-4')}>{data?.discoverCategory?.description}</Text>
            </View>
          );
        case 'MostWatchlistedFeedItem':
        case 'DiscoverCategoryFeedItem':
        case 'SignificantDailyMoverFeedItem':
          return (
            <InstrumentNavigationWrapper instrumentId={item.instrument?.id ?? ''} style={tailwind('py-4 px-6')}>
              <InstrumentPriceRow showWatchlistToggle {...item.instrument} />
            </InstrumentNavigationWrapper>
          );
        default:
          return null;
      }
    },
    [
      data?.discoverCategory.description,
      data?.discoverCategory.title,
      data?.discoverCategory.imageUrl,
      imageWidth,
      width,
      IMAGE_HEIGHT,
      tailwind,
    ],
  );

  return (
    <SafeAreaView>
      <TitleBar />

      {networkStatus === NetworkStatus.loading ? (
        <DiscoverCategorySkeleton />
      ) : (
        <>
          <FlatList
            stickyHeaderIndices={[1]}
            ItemSeparatorComponent={Divider}
            data={flatListData}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
          />
        </>
      )}
    </SafeAreaView>
  );
});

const keyExtractor = (item: DiscoverCategoryFeedItem | PrependedPresentationalItem, index: number) =>
  `${'id' in item ? item.id : index}`;

export const DiscoverCategorySkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView style={tailwind('h-full w-full')}>
      <ScreenSidePadding>
        {new Array(10).fill(null).map((_, i) => (
          <View key={i} style={tailwind('mt-4')}>
            <InstrumentPriceRowSkeleton />
          </View>
        ))}
      </ScreenSidePadding>
    </SkeletonView>
  );
};
