import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { PressableProps } from 'react-native';
import { LoggedOutStackParamList } from '../navigation/RootStackNavigator';
import { analytics } from '../services/analytics';
import { AnalyticsEvent } from '../services/analytics/types';
import { usePersistedStore } from '../zustand/store';

export type UseRedirectPressArgs = {
  redirectIfLoggedOut?: boolean;
  onPress?: () => void;
  accessibilityLabel: PressableProps['accessibilityLabel'];
  /** if want to track this press, pass in valid type (eg button, icon) */
  trackingType?: AnalyticsEvent['Button Pressed']['type'];
};

/**
 * Common handlePress function to use on button press to track but also redirect to logged out sign up page if needed
 */
export const useRedirectPress = ({
  redirectIfLoggedOut,
  onPress,
  trackingType,
  accessibilityLabel,
}: UseRedirectPressArgs) => {
  const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const navigation = useNavigation<NavigationProp<LoggedOutStackParamList>>();

  const handlePress = useCallback(() => {
    if (trackingType) {
      // track Button, link press etc
      analytics.track('Button Pressed', {
        type: trackingType,
        label: accessibilityLabel ?? '<Missing Label>',
        isUserLoggedIn,
      });
    }
    if (redirectIfLoggedOut && !isUserLoggedIn) {
      navigation.navigate('SignUp', { external: true });
    } else if (onPress) {
      onPress();
    }
  }, [trackingType, redirectIfLoggedOut, isUserLoggedIn, onPress, accessibilityLabel, navigation]);

  return { handlePress };
};
