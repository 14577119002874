/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdeaInsight } from '../models/IdeaInsight';
import { request as __request } from '../core/request';

export class IdeaInsightService {

    /**
     * @returns IdeaInsight
     * @throws ApiError
     */
    public static async apiIdeaInsightList({
        ideaId,
    }: {
        /** Multiple values may be separated by commas. **/
        ideaId?: string,
    }): Promise<Array<IdeaInsight>> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-insight/`,
            query: {
                'idea_id': ideaId,
            },
        });
        return result.body;
    }

    /**
     * @returns IdeaInsight
     * @throws ApiError
     */
    public static async apiIdeaInsightCreate({
        data,
    }: {
        data: IdeaInsight,
    }): Promise<IdeaInsight> {
        const result = await __request({
            method: 'POST',
            path: `/api/idea-insight/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaInsight
     * @throws ApiError
     */
    public static async apiIdeaInsightRead({
        uuid,
    }: {
        /** A UUID string identifying this idea insight. **/
        uuid: string,
    }): Promise<IdeaInsight> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-insight/${uuid}/`,
        });
        return result.body;
    }

    /**
     * Deactivates the insight related to idea.
     * @returns void
     * @throws ApiError
     */
    public static async apiIdeaInsightDelete({
        uuid,
    }: {
        /** A UUID string identifying this idea insight. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/idea-insight/${uuid}/`,
        });
        return result.body;
    }

}
