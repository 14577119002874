import Svg, { Path, SvgProps } from 'react-native-svg';
import { colors } from '../../theme';

export const Wallet = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M15.5 6v1h7c.223 0 .33.07.38.12.05.05.12.157.12.38v15c0 .223-.07.33-.12.38-.05.05-.157.12-.38.12h-18C2.502 23 1 21.498 1 19.5v-15C1 2.502 2.502 1 4.5 1H15c.223 0 .33.07.38.12.05.05.12.157.12.38V6Zm-1-3V2h-10c-.677 0-1.32.23-1.795.705C2.23 3.181 2 3.823 2 4.5V7h12.5V3ZM21 22h1V8H2v11.5c0 .677.23 1.32.705 1.795.476.475 1.118.705 1.795.705H21Z"
      stroke={colors.warmGray['400']}
      strokeWidth={1}
    />
    <Path d="M19.5 13.5h-3v3h3v-3Z" fill={colors.warmGray['400']} />
  </Svg>
);

export const WalletFilled = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M15.5 6v1h7c.223 0 .33.07.38.12.05.05.12.157.12.38v15c0 .223-.07.33-.12.38-.05.05-.157.12-.38.12h-18C2.502 23 1 21.498 1 19.5v-15C1 2.502 2.502 1 4.5 1H15c.223 0 .33.07.38.12.05.05.12.157.12.38V6Zm-1-3V2h-10c-.677 0-1.32.23-1.795.705C2.23 3.181 2 3.823 2 4.5V7h12.5V3ZM21 22h1V8H2v11.5c0 .677.23 1.32.705 1.795.476.475 1.118.705 1.795.705H21Z"
      stroke={colors.teal['700']}
      strokeWidth={1}
      fill={colors.teal['100']}
    />
    <Path d="M19.5 13.5h-3v3h3v-3Z" fill={colors.teal['700']} />
  </Svg>
);
