import Svg, { Path, SvgProps } from 'react-native-svg';

export const ThumbsUp = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M6 23H1V12h5M6 12 9 1a3 3 0 0 1 3 3v6h7.5a3 3 0 0 1 2.965 3.456l-1.077 7A3 3 0 0 1 18.426 23H6V12Z"
      stroke={props.color ?? '#fff'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
