import React from 'react';
import { TextProps } from 'react-native';
import { useRedirectPress, UseRedirectPressArgs } from '../../hooks/useRedirectPress';
import { useTailwind } from '../../theme';
import { CommonPressableProps } from '../../ui/Pressable/types';
import { Text } from '../../ui/Text';

export type Props = { disabled?: boolean } & Omit<TextProps, 'suppressHighlighting' | 'accessibilityRole' | 'onPress'> &
  CommonPressableProps;

const trackingType: UseRedirectPressArgs['trackingType'] = 'link';

/**
 * An inline button with no padding, just teal text and a bit bolder.
 *
 * Add `onPress` to navigate somewhere. Add redirectIfLoggedOut to send a user to SignUp instead if they are not logged in
 *
 * @param root0
 * @param root0.style
 * @param root0.onPress
 * @param root0.redirectIfLoggedOut send user to to SignUp screen instead of executing onPress if logged out
 * @param root0.children
 */
export const Link: React.FC<Props> = ({ style, onPress, children, redirectIfLoggedOut, disabled, ...props }) => {
  const tailwind = useTailwind();
  const { handlePress } = useRedirectPress({
    trackingType,
    redirectIfLoggedOut,
    onPress,
    accessibilityLabel: children?.toString() ?? '<Missing Link Text>',
  });

  return (
    <Text
      {...props}
      onPress={disabled ? undefined : handlePress}
      style={[tailwind('font-medium text-teal-800'), disabled ? tailwind('text-warmGray-500') : undefined, style]}
      suppressHighlighting
      accessibilityRole="button"
    >
      {children}
    </Text>
  );
};
