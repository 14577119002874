/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import { request as __request } from '../core/request';

export class CountryService {

    /**
     * @returns Country
     * @throws ApiError
     */
    public static async apiCountryList({
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<Country>> {
        const result = await __request({
            method: 'GET',
            path: `/api/country/`,
            query: {
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * @returns Country
     * @throws ApiError
     */
    public static async apiCountryRead({
        uuid,
    }: {
        /** A UUID string identifying this country. **/
        uuid: string,
    }): Promise<Country> {
        const result = await __request({
            method: 'GET',
            path: `/api/country/${uuid}/`,
        });
        return result.body;
    }

}
