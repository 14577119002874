import { Linking } from 'react-native';
import { SvgProps } from 'react-native-svg';
import {
  Academy,
  Bug,
  Certificate,
  Close,
  Contact,
  Discord,
  Faq,
  Pencil,
  Privacy,
  Smile,
  UHollow,
  User,
  Warning,
} from '../../old/icons';
import { EXTERNAL_URIs } from '../../services/externalURIs';
import { visitExternalLink } from '../../services/openLink';
import { forceTestException } from '../../services/sentry';
import { colors } from '../../theme';
import { usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { Props as UpsideSharesProps } from '../UpsideShares';
import { MenuNavigationProps } from './Menu';

export type MenuItem = { Icon: React.FC<SvgProps>; label: string; onPress: () => void };
export type MenuSection = { title: string; menuItems: MenuItem[] };

/**
 * if on web cant always navigate back, so go home instead
 */
const tryGoBack = (navigation: MenuNavigationProps) => {
  if (navigation.canGoBack()) {
    navigation.goBack();
  }
  navigation.navigate('Tabs', { screen: 'HomeTab', params: { screen: 'Home' } });
};

export const accountSection = (
  navigation: MenuNavigationProps,
  summary: UpsideSharesProps['route']['params'],
): MenuSection => ({
  title: 'Account',
  menuItems: [
    {
      label: 'My Upside Shares',
      Icon: Certificate,
      onPress: () => navigation.replace('UpsideShares', summary),
    },
  ],
});

export const academySection = (navigation: MenuNavigationProps): MenuSection => ({
  title: 'Academy',
  menuItems: [
    {
      label: 'Why Upside?',
      Icon: UHollow,
      onPress: () => visitExternalLink(EXTERNAL_URIs.WHY_UPSIDE, navigation),
    },
    {
      label: 'Upside Academy shorts',
      Icon: Academy,
      onPress: () => visitExternalLink(EXTERNAL_URIs.ACADEMY, navigation),
    },
  ],
});

export const otherSection = (navigation: MenuNavigationProps): MenuSection => ({
  title: 'Other',
  menuItems: [
    {
      label: 'Privacy policy',
      Icon: Privacy,
      onPress: () => visitExternalLink(EXTERNAL_URIs.PRIVACY_POLICY, navigation),
    },
    // {
    //   label: 'Terms and conditions',
    //   Icon: Terms,
    //   onPress: () => visitExternalLink(EXTERNAL_URIs.TERMS_AND_CONDITIONS, navigation),
    // },
  ],
});

export const helpSection = (navigation: MenuNavigationProps): MenuSection => ({
  title: 'Help',
  menuItems: [
    {
      label: 'FAQs',
      Icon: Faq,
      onPress: () => visitExternalLink(EXTERNAL_URIs.FAQ, navigation),
    },
    {
      label: 'Report a bug',
      Icon: Bug,
      onPress: () => Linking.openURL(encodeURI('mailto: help@upsidetechnology.co?subject=Bug Report')),
    },
    {
      label: 'Contact us',
      Icon: Contact,
      onPress: () => Linking.openURL(encodeURI('mailto: help@upsidetechnology.co')),
    },
    {
      label: 'Request account deletion',
      Icon: Close,
      onPress: () =>
        Linking.openURL(
          encodeURI(
            `mailto: help@upsidetechnology.co?subject=Please delete my account&body=My username is ${
              usePersistedStore.getState().nickname
            }.\nThank you\n\nPlease note: We will endeavour to delete your account as soon as possible but this can take up to 30 days to be removed from our systems.`,
          ),
        ),
    },
  ],
});

export const communitySection = (): MenuSection => ({
  title: 'Community',
  menuItems: [
    {
      label: 'Request a feature',
      Icon: Pencil,
      onPress: () => Linking.openURL(encodeURI('mailto: help@upsidetechnology.co?subject=Feature Request')),
    },
    {
      label: 'Join our Discord',
      Icon: Discord,
      onPress: () => Linking.openURL('https://discord.gg/xeEdNN3NGt'),
    },
  ],
});

export const staffSection = (navigation: MenuNavigationProps): MenuSection => {
  const charlieMode = usePersistedStore.getState().charlieMode;
  return {
    title: 'Staff',
    menuItems: [
      {
        label: 'Reset tutorials',
        Icon: Close,
        onPress: () => {
          useTutorialCardPersistedStore.setState({
            feedTutorialDismissed: false,
            discoverTutorialDismissed: false,
            watchlistTutorialDismissed: false,
            instrumentScreenTutorialDismissed: false,
            ideasTutorialDismissed: false,
            createIdeaTutorialDismissed: false,
            myIdeaTutorialDismissed: false,
            otherUserIdeaTutorialDismissed: false,
          });
          tryGoBack(navigation);
        },
      },
      {
        label: `${charlieMode ? 'Disable' : 'Enable'} Charlie mode`,
        Icon: Smile,
        onPress: () => {
          usePersistedStore.setState({
            charlieMode: !charlieMode,
          });
        },
      },
      {
        label: 'Disable staff permissions (reload to restore)',
        Icon: User,
        onPress: () => {
          usePersistedStore.setState({
            isStaff: false,
          });
          tryGoBack(navigation);
        },
      },
      {
        label: 'Send Sentry a Test Error',
        Icon: () => <Warning color={colors.teal[800]} />,
        onPress: () => {
          forceTestException();
          tryGoBack(navigation);
        },
      },
    ],
  };
};
