import { FieldMergeFunction } from '@apollo/client';
import { last } from 'lodash';
import { Connection } from '../generated/graphql';

/**
 * Pagination handler to be used for uni-directional pagination (increasing page count)
 *
 * There are some cases where the paginated queries are re-fetched (cache + network effect) and the incoming set is such that existing === incoming.
 * This merges incoming results with existing based on overlap of last in existing set with incoming set, not full diff of 2 sets for perf reasons.
 */
export const additivePagination: FieldMergeFunction<Connection, Connection> = (existing, incoming, { readField }) => {
  const existingNodes = existing?.nodes ?? [];
  const incomingNodes = incoming?.nodes ?? [];
  const lastExisting = last(existingNodes);

  /**
   * Check if incoming/existing results overlap. ID of last item in existing set is searched for in incoming set.
   * If found, incoming set sliced with index of last item used as start of slice, results are then merged.
   */
  let incomingOverlapOffset;

  if (existingNodes.length > 0) {
    const lastExistingIndex = incomingNodes.findIndex((i) => readField('id', i) === readField('id', lastExisting));
    incomingOverlapOffset = lastExistingIndex > 0 ? lastExistingIndex + 1 : undefined;
  }

  const slicedIncomingNodes = incomingOverlapOffset ? incomingNodes.slice(incomingOverlapOffset) : incomingNodes;
  const mergedNodes = [...existingNodes, ...slicedIncomingNodes];

  return { ...incoming, nodes: mergedNodes };
};
