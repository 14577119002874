import { CLIENT_URL } from '../../constants/env';
import { FeedCardFieldsFragment, Position } from '../../generated/graphql';
import { ShareContent } from '../../services/sharing';
import { getShareIdeaPayload } from '../../util/idea';

/** Idea feed card returns same payload as Idea detail sharing */
export const getFeedItemShareContent = (feedItem: Partial<FeedCardFieldsFragment>, userId: number): ShareContent => {
  const isShareable = 'id' in feedItem;
  if (!isShareable) throw new Error(`Cannot share feedItem with missing id.`);

  const url = `${CLIENT_URL}/FeedItem/${feedItem.id}`;

  switch (feedItem.__typename) {
    case 'CorrelationFeedItem': {
      const message = `Upside Insight: Correlation on ${feedItem.subjectInstrument?.name}`;
      return { url, message, title: message };
    }
    case 'NewsFeedItem': {
      const message = `Upside News: ${feedItem.news?.title}`;
      return { url, message, title: message };
    }
    case 'NewIdeaFeedItem': {
      return getShareIdeaPayload(userId ?? 0, feedItem.idea);
    }
    case 'MacdFeedItem': {
      const message = `Upside Insight: MACD signal on ${feedItem.instrument?.name}`;
      return { url, message, title: message };
    }
    case 'RelativeStrengthIndexFeedItem': {
      const message = `Upside Insight: RSI signal on ${feedItem.instrument?.name}`;
      return { url, message, title: message };
    }
    case 'ReportRatingFeedItem': {
      const message = `Upside Insight: Report rating published on ${feedItem.instrument?.name}`;
      return { url, message, title: message };
    }
    case 'NetShortDisclosureFeedItem': {
      const message = `Upside Insight: Net short position declared on ${feedItem.instrument?.name}`;
      return { url, message, title: message };
    }
    case 'SignificantDailyMoverFeedItem': {
      const message = `Upside Insight: Significant move spotted on ${feedItem.instrument?.name}`;
      return { url, message, title: message };
    }
    case 'NewCommentFeedItem': {
      const message = `Check out this Comment for a ${
        feedItem.idea?.position === Position.Long ? 'Bullish' : 'Bearish'
      } Upside Idea on ${feedItem.idea?.instrument?.name}`;
      return { url, message, title: message };
    }
    case 'UpsideVoiceFeedItem': {
      const message = `Upside Voice: ${feedItem.voice?.title}`;
      return { url, message, title: message };
    }
    default:
      const message = 'Check out this Upside insight';
      return { url, message, title: message };
  }
};
