import React from 'react';
import { View, ViewStyle } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { Button } from '../Button';

export type Props = {
  title: string;
  actionText: string;
  children: React.ReactText | React.ReactText[];
  style?: ViewStyle;
  onPressAction: () => void | Promise<void>;
  actionLoading?: boolean;
};

export const ActionCard: React.FC<Props> = ({ title, actionText, onPressAction, actionLoading, style, children }) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('pt-4 px-6 bg-teal-700 rounded-lg'), style]}>
      <Text style={tailwind('text-warmGray-200 text-xs font-bold pb-2')}>{title}</Text>
      <Text style={tailwind('text-white text-lg font-light pb-4')}>{children}</Text>
      <View style={tailwind('pb-6')}>
        <Button
          style={tailwind('self-center')}
          rounded
          variant="inverted"
          size="sm"
          text={actionText}
          isDisabled={actionLoading}
          onPress={() => onPressAction()}
        />
      </View>
    </View>
  );
};
