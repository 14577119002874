import { FC } from 'react';
import { View } from 'react-native';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { useTailwind } from '../../theme';
import { Button } from '../Button';
import { BaseLayerDiagram, BundlesLayerDiagram, StocksLayerDiagram } from '../LayerDiagram';
import { Text } from '../Text';

type Props = {
  variant: 'Base' | 'Bundles' | 'Stocks';
  onPressFinish: () => void;
};
export const LayerBottomSheet: FC<Props> = ({ variant, onPressFinish }) => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      {variant === 'Base' ? <BaseBody /> : variant === 'Bundles' ? <BundlesBody /> : <StocksBody />}
      {/* Spacer */}
      <View style={tailwind('pt-10')} />
      <Button isFullWidth text="Got it!" onPress={onPressFinish} />
    </ScreenSidePadding>
  );
};

export const BaseBody: FC = () => {
  const tailwind = useTailwind();
  return (
    <View>
      <BaseLayerDiagram />
      <Text style={tailwind('text-default text-sm font-medium pt-8 pb-4')}>What{"'"}s this?</Text>
      <Text style={tailwind('text-base text-default h-32')}>
        Your base layer is made up of a diverse group of stocks which could help you mitigate some of the risks normally
        associated with investing in stocks.
      </Text>
    </View>
  );
};

export const BundlesBody: FC = () => {
  const tailwind = useTailwind();
  return (
    <View>
      <BundlesLayerDiagram />
      <Text style={tailwind('text-default text-sm font-medium  pt-8 pb-4')}>What{"'"}s this?</Text>
      <Text style={tailwind('text-base text-default h-32')}>
        Bundles are like base but smaller and more focused on certain themes. They allow you to invest in areas that are
        bespoke to your values and interests.
      </Text>
    </View>
  );
};
export const StocksBody: FC = () => {
  const tailwind = useTailwind();
  return (
    <View>
      <StocksLayerDiagram />
      <Text style={tailwind('text-default text-sm font-medium pt-8 pb-4')}>What{"'"}s this?</Text>
      <Text style={tailwind('text-base text-default h-32')}>
        Invest in stocks when your conviction is strong enough to invest in individual companies.
      </Text>
    </View>
  );
};
