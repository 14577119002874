import Svg, { Path, SvgProps } from 'react-native-svg';

export const Alpha = (props: SvgProps) => (
  <Svg width={360} height={360} viewBox="0 0 256 256" fill="none" {...props}>
    <Path
      d="M139.991 8.927a96.33 96.33 0 0 1 27.403 7.793l10.626 4.815c26.614 12.058 46.508 35.602 54.338 64.308l14.432 52.91c4.84 17.743 4.049 36.661-2.259 54.048-8.22 22.658-25.161 40.965-46.842 50.617l-4.488 1.998c-15.541 6.919-32.533 9.591-49.295 7.752l-55.695-6.109c-45.493-5.567-79.798-44.329-80.728-91.216l-1.684-84.84C5.26 43.808 20.63 18.71 44.873 7.2c11.44-5.431 24.11-7.46 36.55-5.85l58.568 7.578Z"
      fill="#FAE8FF"
      fillOpacity={0.4}
    />
    <Path
      d="M38.142 205.124a2998.632 2998.632 0 0 1 134.781-2.571c12.566.037 25.094.184 37.66.368.189 0 .189-.258 0-.294a2946.001 2946.001 0 0 0-134.82.881c-12.565.368-25.093.808-37.621 1.323-.19.036-.19.293 0 .293Z"
      fill="#202124"
    />
    <Path
      d="m211.7 81.166-3.451 20.133-3.636-4.051s-41.816 41.317-42.632 41.398c-.779.081-40.666 11.423-40.666 11.423l-47.27 58.047-33.579 7.25v-3.2l32.206-6.967 46.417-56.953 41.816-11.949 40.703-42.128-3.228-2.795 13.32-10.207Z"
      fill="#3B82F6"
    />
    <Path
      d="M93.88 116.088c-.127-.587-.538-2.237-1.835-3.19a5.022 5.022 0 0 0-.885-.477c-.632-.513-1.391-1.1-2.245-1.613-.885-.55-1.74-.954-2.466-1.247-.854-.33-1.613-.587-2.245-.733-.127.22-.285.513-.38.916-.221.954.095 1.76.222 1.98.347.11.853.257 1.422.514.79.33 1.518.66 2.245 1.32.886.77.728 1.1 1.582 1.796.19.147.885.697 1.928.917 1.17.257 2.15 0 2.656-.183Z"
      fill="#A98F8D"
    />
    <Path
      d="M115.286 67.32c-.189.184-.474.55-.727 1.028-.411.88-.411 1.686-.443 1.943 0 .073-.537 2.347-1.549 6.966-.474 2.127-.98 4.364-1.455 6.454-.632 2.787-1.549 6.6-2.687 11.33a466.68 466.68 0 0 0-6.135 6.196 389.086 389.086 0 0 0-10.498 11.367c.317.22.728.55 1.107 1.1.759 1.137.822 2.347.822 2.823.696-.256 1.74-.66 3.004-1.136 4.111-1.577 6.166-2.384 6.893-2.677 2.119-.917 4.143-2.09 8.253-4.437 2.056-1.173 3.226-1.906 4.585-3.226.664-.66 1.803-1.797 2.783-3.594.79-1.393 1.075-2.53 1.739-5.096 1.043-4.107 1.581-6.124 2.15-8.874 1.202-5.683 1.233-6.856 1.138-8.03-.094-1.063-.347-2.823-1.075-4.62-2.15-5.133-6.672-7.04-7.905-7.516Z"
      fill="#fff"
    />
    <Path
      d="M114.685 68.384c-1.865 8.323-3.731 16.647-5.691 24.933-.032.184-.253.11-.222-.073a3089.78 3089.78 0 0 0 5.692-24.933c.032-.184.285-.11.221.073ZM107.855 95.81c-5.502 5.391-10.845 11.001-15.968 16.867-.095.11-.284-.073-.158-.183 5.154-5.83 10.466-11.477 15.968-16.867.095-.11.285.074.158.184ZM124.203 82.61c-.948 4.804-2.023 9.57-3.32 14.3-1.043 3.777-2.466 6.711-5.375 8.947-6.419 4.914-13.945 8.25-21.376 10.377-.158.037-.22-.22-.063-.257 7.146-2.053 14.103-5.243 20.427-9.716 1.644-1.174 3.225-2.494 4.3-4.4 1.012-1.834 1.581-3.924 2.119-5.977 1.17-4.4 2.182-8.873 3.067-13.347.032-.146.285-.073.221.074ZM92.109 112.787c.853.55 1.454 1.504 1.612 2.604.032.183-.19.256-.221.073-.158-1.063-.727-1.907-1.518-2.42-.126-.11 0-.367.127-.257ZM92.615 116.014c-1.898 1.357-3.921-1.393-5.25-2.64-.126-.11.064-.293.159-.183 1.233 1.173 3.225 3.85 4.964 2.603.127-.11.253.147.127.22ZM89.832 113.227a4.613 4.613 0 0 0-2.277-.586c-.158 0-.158-.294 0-.294.854 0 1.644.22 2.403.624.127.11 0 .33-.126.256ZM91.476 112.861c-2.213-1.907-4.648-3.227-7.304-3.96-.158-.037-.095-.293.063-.257 2.688.734 5.154 2.054 7.368 3.96.095.147 0 .367-.127.257ZM87.777 111.614c-1.17-.77-2.403-1.356-3.731-1.723-.159-.037-.095-.293.063-.257a13 13 0 0 1 3.794 1.76c.127.074 0 .294-.126.22ZM86.543 111.871c-.885-.587-1.802-.953-2.814-1.1-.158-.037-.158-.293 0-.293 1.044.146 2.024.55 2.94 1.136.127.11 0 .367-.126.257ZM86.006 112.494a4.965 4.965 0 0 0-1.992-.66c-.158 0-.158-.293 0-.293a5.035 5.035 0 0 1 2.118.696c.127.11 0 .367-.126.257Z"
      fill="#202124"
    />
    <Path
      d="M159.27 55.954a19.04 19.04 0 0 1 1.802 4.84c.443 1.944.475 3.52.506 4.694.095 7.003-.189 11.66-.189 11.66-.19 2.86-.411 6.123-.981 10.45-.664 5.06-1.486 9.533-2.371 13.419-.759 3.337-1.739 7.224-3.099 11.514-2.561 1.723-7.273 4.4-13.628 5.17a29.731 29.731 0 0 1-13.85-1.54c-.158-.697-.379-1.724-.632-3.007-.664-3.263-.886-4.987-1.17-6.49-.348-1.907-1.012-3.887-2.34-7.847-1.581-4.73-2.056-5.536-3.447-9.79-.379-1.173-1.36-4.07-2.087-6.856-.917-3.447-1.992-8.434-2.498-14.85 1.739-.953 4.237-2.31 7.336-3.74 4.648-2.2 9.802-4.584 16.822-6.27 6.925-1.65 11.984-1.724 13.186-1.724a46.706 46.706 0 0 1 6.64.367Z"
      fill="#fff"
    />
    <Path
      d="M161.578 62.224c.696 16.647-1.391 33.33-6.134 49.097-.063.183-.285.11-.221-.074 4.743-15.73 6.83-32.376 6.134-49.023-.032-.183.221-.183.221 0ZM159.27 56.064c-15.304-1.87-30.229 3.96-43.92 11.367-.127.073-.253-.147-.127-.22 13.723-7.444 28.68-13.273 44.047-11.404.158 0 .158.294 0 .257ZM116.298 70.914c.253 15.62 9.707 28.49 10.814 43.89 0 .183-.221.183-.253 0-1.138-15.4-10.593-28.27-10.814-43.89 0-.183.253-.183.253 0ZM154.559 112.054c-1.423 1.87-3.352 2.86-5.376 3.593-2.498.917-5.059 1.651-7.62 2.127-4.775.917-9.644.807-14.135-1.503-.126-.074-.031-.294.127-.22 4.585 2.383 9.612 2.383 14.482 1.357 2.15-.44 4.3-1.064 6.387-1.761 2.245-.77 4.396-1.686 5.977-3.776.094-.147.253.036.158.183Z"
      fill="#202124"
    />
    <Path
      d="m61.708 169.371-.002.002c-.136.2-.18.588.058 1.29.234.689.718 1.616 1.521 2.829 1.583 2.387 4.374 5.834 8.825 10.668.683-.86 1.365-1.726 2.047-2.594.704-.894 1.407-1.788 2.111-2.674-.904-1.223-1.837-2.479-2.77-3.734-4.31-2.9-7.147-4.593-8.99-5.425-.927-.419-1.58-.609-2.033-.637-.44-.028-.65.097-.767.275Z"
      fill="#fff"
      stroke="#292524"
      strokeWidth={0.5}
    />
    <Path
      d="M72.346 174.204c.221.404 1.518 3.63.41 3.447-.505-.11-.758-1.32-.79-1.76-.094-.587-.031-1.1.095-1.65.063-.403.38-1.503-.095-1.723-.19.036-.347.073-.537.11.095.44.284.843.569 1.21.095.146-.063.33-.158.183-.285-.403-.886-1.357-.6-1.943.22-.44.537-.33.758 0 .57.733.411 1.576.253 2.42a4.123 4.123 0 0 0-.032 1.393c.032.22.285 1.54.728 1.32.379-.183-.696-2.64-.79-2.823-.096-.184.126-.33.189-.184ZM70.986 173.214c.158.587.222 1.174.19 1.797a.127.127 0 0 1-.253 0c.032-.587 0-1.173-.158-1.723-.063-.147.19-.22.221-.074ZM69.816 172.334c.316.477.38 1.1.221 1.65-.063.147-.284.11-.22-.073.157-.513.094-1.027-.19-1.43-.095-.147.126-.293.19-.147ZM68.583 171.271c.19.587.285 1.21.253 1.87a.127.127 0 0 1-.253 0 4.917 4.917 0 0 0-.221-1.797c-.063-.183.158-.256.221-.073ZM67.666 170.941c.19.477.158 1.027-.095 1.467-.095.146-.284 0-.19-.147.19-.367.222-.807.095-1.21-.063-.147.127-.257.19-.11ZM71.998 183.114c-3.067-3.593-6.166-7.187-9.233-10.817-.095-.11.063-.33.158-.183 3.067 3.593 6.166 7.187 9.233 10.817.127.11-.063.293-.158.183Z"
      fill="#292524"
    />
    <Path
      d="M140.646 117.774a18.7 18.7 0 0 1 .727 5.867 18.135 18.135 0 0 1-2.624 8.727c-4.364.88-8.159 1.833-11.289 2.676-5.881 1.577-11.099 3.337-15.557 4.987-2.656.99-4.901 1.87-6.546 2.567a296.283 296.283 0 0 1-6.45 9.056 341.313 341.313 0 0 1-9.423 12.21c-3.478 4.327-7.778 9.424-12.87 15.07-.41-.22-1.296-.806-1.96-2.053-.474-.917-.6-1.76-.664-2.237a973.444 973.444 0 0 1 4.585-10.413 858.201 858.201 0 0 1 7.842-17.197c6.23-13.346 8.759-17.966 10.94-21.706 1.107-1.87 2.024-3.374 2.688-4.4 1.012-.294 2.498-.66 4.332-1.064 6.166-1.393 10.941-1.833 14.735-2.163 3.131-.293 6.293-.55 10.593-.55 4.364 0 8.095.293 10.941.623Z"
      fill="#DBEAFE"
    />
    <Path
      d="M129.768 117.664a99.603 99.603 0 0 0-29.723 3.374c-.158.036-.221-.22-.063-.257 9.771-2.64 19.763-3.777 29.786-3.373.158-.037.158.256 0 .256ZM99.033 122.504c-9.36 16.72-17.17 34.467-24.948 52.177-.063.147-.285.037-.19-.147 7.779-17.71 15.589-35.493 24.949-52.176.063-.147.284 0 .19.146ZM138.78 132.514a187.612 187.612 0 0 0-33.391 10.23c-.158.074-.221-.22-.063-.256a187.422 187.422 0 0 1 33.391-10.23c.126-.074.19.22.063.256ZM103.903 144.944c-8.474 11.99-17.55 23.394-27.225 34.064-.095.11-.284-.074-.158-.184 9.676-10.706 18.75-22.073 27.225-34.063.095-.147.253.037.158.183ZM76.109 178.458a5.147 5.147 0 0 1-2.119-2.64c-.063-.147.158-.294.19-.147.411 1.1 1.107 1.98 2.055 2.567.127.073 0 .33-.126.22Z"
      fill="#202124"
    />
    <Path
      d="M117.07 207.472c1.586-.923 4.205-3.242 8.733-8.414-.118-2.069-.208-4.169-.297-6.238l-.004-.092a191.327 191.327 0 0 1-4.371-.677c-3.816 6.744-5.646 10.447-6.407 12.573-.384 1.073-.485 1.716-.449 2.131.033.391.188.595.398.798.222.213.438.39.766.431.335.043.837-.049 1.631-.512Z"
      fill="#fff"
      stroke="#000"
      strokeWidth={0.5}
    />
    <Path
      d="M121.262 194.077c.475.294.917.697 1.17 1.284.19.367.285.953-.19 1.063-.411.11-.853-.256-1.106-.586-.348-.44-.538-.99-.601-1.577-.032-.184.221-.184.253 0 .095.733.506 1.687 1.17 1.907.348.11.443-.294.316-.66a1.675 1.675 0 0 0-.316-.514 2.07 2.07 0 0 0-.791-.66c-.158-.073-.063-.33.095-.257Z"
      fill="#fff"
    />
    <Path
      d="M120.44 194.077c-.189-.623-.759-1.32-1.391-1.356-.474 0-.538.476-.253.77.127.11.253.22.411.293.348.183.696.33 1.044.44.158.037.094.293-.064.257-.506-.147-1.264-.367-1.644-.88-.19-.294-.253-.734 0-1.027.316-.33.917-.073 1.202.147.411.293.727.733.885 1.246.095.22-.126.294-.19.11ZM120.472 196.204c-.285-.586-.854-.916-1.423-.806-.158.036-.221-.22-.063-.257.664-.11 1.328.22 1.676.917.095.146-.095.293-.19.146ZM119.682 197.487c-.159-.77-.917-.843-1.518-.88-.158 0-.158-.293 0-.293.695.037 1.549.183 1.739 1.1.032.183-.19.22-.221.073ZM118.733 198.771c-.19-.697-.886-1.064-1.455-.734-.126.074-.253-.146-.126-.22.695-.403 1.581.037 1.802.917.063.11-.158.183-.221.037ZM117.848 200.054c-.253-.587-.791-.917-1.36-.807-.158.037-.221-.22-.063-.256.664-.11 1.296.22 1.612.916.095.184-.126.33-.189.147ZM116.804 201.631c-.158-.477-.664-.733-1.043-.513-.127.073-.253-.184-.127-.22.538-.294 1.17.036 1.391.66.064.183-.189.256-.221.073ZM125.373 198.367c-2.782 2.897-5.533 5.794-8.316 8.69-.126.11-.285-.073-.158-.183 2.783-2.897 5.533-5.793 8.316-8.69.127-.11.285.073.158.183Z"
      fill="#292524"
    />
    <Path
      d="M155.223 111.137c.126.514.253 1.174.442 2.017.127.587.506 2.64 1.012 5.573.538 3.044.791 5.427 1.075 8.104.38 3.593.127 2.273.38 4.473.221 1.833.379 2.493.126 3.557-.379 1.576-1.328 2.493-1.549 2.713-.506.477-.885.623-1.739 1.063-2.53 1.21-2.056 1.137-3.478 1.76a30.16 30.16 0 0 1-4.522 1.577c-3.257.917-6.767 1.87-11.478 2.127-1.834.11-4.332.11-7.305-.257-.063 3.923-.189 8.91-.442 14.667-.158 3.776-.348 8.653-.791 14.74a413.618 413.618 0 0 1-1.834 20.02 5.281 5.281 0 0 1-1.423.513c-1.423.22-2.561-.33-3.067-.623-.411-1.614-1.012-4.034-1.802-6.93-1.36-4.95-2.308-7.957-3.194-10.854-3.257-10.78-5.312-19.726-6.387-24.456-.443-1.98-.885-3.997-1.739-8.104-.506-2.456-1.233-5.903-1.739-10.23-.032-.366-.285-2.42-.253-5.096.031-1.76.158-3.117.664-4.584.189-.55.379-.99.537-1.283a58.471 58.471 0 0 1 6.135-2.31c5.407-1.687 9.644-2.127 13.249-2.457 4.015-.366 9.327-.623 15.62-.11a40.43 40.43 0 0 0 4.522-1.063c1.011-.33 2.719-.843 4.806-1.907 1.17-.696 2.624-1.503 4.174-2.64Z"
      fill="#DBEAFE"
    />
    <Path
      d="M155.792 113.081c1.043 5.17 1.834 10.413 2.308 15.73.19 2.09.759 4.84.127 6.856-.506 1.54-1.834 2.31-3.036 2.934a48.466 48.466 0 0 1-25.328 5.573c-.158 0-.158-.293 0-.293a48.052 48.052 0 0 0 23.146-4.51c1.961-.917 4.775-1.87 5.154-4.657.285-2.2-.158-4.767-.379-6.967a140.352 140.352 0 0 0-2.214-14.593c-.063-.183.159-.257.222-.073ZM149.468 117.297c-14.293-.88-29.122-1.686-42.719 4.4-.158.074-.19-.22-.063-.256 13.628-6.124 28.458-5.317 42.782-4.437.158.037.158.293 0 .293ZM106.306 122.908c-1.518 5.94-.158 12.173.98 18.04 1.107 5.866 2.435 11.696 3.89 17.49 2.877 11.476 6.26 22.733 9.644 34.026.063.184-.19.257-.222.074-3.509-11.734-7.019-23.43-9.992-35.384-1.454-5.903-2.782-11.843-3.889-17.856-.98-5.39-2.055-11.037-.664-16.464.063-.183.316-.11.253.074ZM128.377 139.554a660.41 660.41 0 0 1-2.751 52.47c-.031.183-.253.183-.253 0a659.518 659.518 0 0 0 2.751-52.47c.032-.183.253-.183.253 0ZM125.215 193.381c-1.518.77-3.13.733-4.616-.11-.127-.074-.032-.33.126-.22 1.391.806 2.941.88 4.395.11.095-.11.222.146.095.22Z"
      fill="#202124"
    />
    <Path
      d="M190.384 58.3c.285-.33.728-.806 1.234-1.392 1.011-1.21 1.011-1.43 1.802-2.347 1.296-1.54 2.182-2.053 2.751-2.347.316-.146 1.107-.513 1.265-.256.189.293-.569 1.356-1.297 2.236.475-.073 1.17-.183 1.961-.55.98-.44 1.17-.77 1.834-.843.126 0 1.454-.147 2.308.99.506.66.601 1.43.601 1.76-.127.33-.38.843-.759 1.32-.506.623-1.012.953-1.676 1.32-1.043.587-1.739.88-2.909 1.43-1.549.733-2.308 1.063-2.719 1.32-1.328.77-1.487 1.136-1.961 1.1-.664-.037-1.518-.733-2.435-3.74Z"
      fill="#A98F8D"
    />
    <Path
      d="M191.238 57.017c.38.257.791.624 1.233 1.174 1.075 1.393 1.265 3.006 1.297 3.703-.411.697-.98 1.65-1.708 2.824-.632 1.026-3.193 5.06-8.031 10.926-2.308 2.823-5.344 6.233-9.044 9.974-.253.366-.822 1.21-1.865 1.65-.19.073-.759.293-1.486.256-.886-.037-1.518-.44-1.771-.587-1.486-.88-.791-.036-5.123-2.346a90.49 90.49 0 0 1-6.071-3.483c-1.802-1.1-3.288-2.09-4.363-2.824-.601-1.503-1.139-3.3-1.328-5.39-.822-9.057 5.249-15.803 6.292-16.977.348.44.854 1.064 1.423 1.76.601.734 1.644 1.944 3.984 4.437 3.099 3.3 3.795 3.85 4.648 4.364.886.513 1.676.843 2.214 1.026.664-.183 1.612-.44 2.719-.843 1.455-.514 2.656-1.1 6.103-3.007 2.909-1.577 4.363-2.383 5.881-3.337 2.024-1.21 3.7-2.383 4.996-3.3Z"
      fill="#fff"
    />
    <Path
      d="M159.997 56.358a57.26 57.26 0 0 0 5.787 6.673 51.187 51.187 0 0 0 3.858 3.41c1.77 1.43 3.162.586 4.964-.22 5.692-2.677 11.225-5.794 16.601-9.277.126-.073.253.147.126.22a136.515 136.515 0 0 1-18.276 10.083c-1.012.477-1.613.807-2.593.147-1.265-.807-2.435-1.943-3.542-2.97-2.561-2.347-4.901-4.987-7.051-7.847-.158-.146.032-.366.126-.22ZM191.934 57.42c.917.917 1.518 2.127 1.771 3.484.031.183-.19.257-.222.073-.253-1.32-.822-2.456-1.707-3.336-.127-.147.063-.33.158-.22ZM193.736 62.37c-5.186 8.14-11.067 15.62-17.644 22.294-1.075 1.1-2.372 2.897-3.921 3.007-1.676.11-3.636-1.137-5.154-1.834-4.079-1.87-8.032-4.216-11.763-6.893-.126-.11 0-.33.127-.22 3.478 2.493 7.114 4.693 10.909 6.49 1.739.843 3.731 2.053 5.628 2.09 2.024.073 4.237-3.043 5.692-4.583 5.881-6.197 11.193-13.09 15.905-20.497.095-.147.284 0 .221.147Z"
      fill="#202124"
    />
    <Path
      d="M191.365 57.017c1.644-2.163 3.32-5.06 6.071-5.206.063 0 .126.073.126.183-.189.807-.569 1.43-1.17 1.907-.126.11-.253-.147-.126-.22.19-.147.885-.77.854-1.063-.127-.77-.917-.257-1.202-.11-.727.366-1.36.916-1.929 1.576-.854.99-1.644 2.09-2.435 3.153-.126.11-.284-.073-.189-.22ZM193.831 61.234c1.455-.697 2.878-1.393 4.332-2.09 1.834-.88 3.953-1.65 4.68-4.033.063-.183.284-.11.221.073-.537 1.834-1.802 2.714-3.257 3.483-1.96.99-3.952 1.907-5.913 2.86-.126.037-.189-.22-.063-.293ZM202.59 54.67c-.854.844-1.771 1.578-2.783 2.164-.126.073-.253-.147-.126-.22a14.436 14.436 0 0 0 2.751-2.127c.126-.11.284.074.158.184ZM201.957 53.754c-.948.403-1.833.953-2.687 1.577-.127.11-.253-.147-.127-.22.854-.66 1.771-1.174 2.751-1.614.158-.073.222.184.063.257ZM200.914 53.46c-.854.147-1.613.514-2.308 1.064-.127.11-.253-.147-.127-.22.728-.587 1.518-.953 2.372-1.1.158-.037.221.22.063.257ZM199.965 52.91c-.917.367-1.802.807-2.624 1.32-.126.074-.253-.146-.127-.22.854-.55 1.771-.99 2.688-1.356.127-.037.19.22.063.257ZM139.887 53.79c.443-.256 1.043-.66 1.707-1.246.791-.733 1.803-1.613 2.435-3.116.19-.477.917-2.237.506-4.437-.474-2.677-2.245-4.07-2.719-4.437-1.202-.953-2.34-1.173-2.846-1.247-2.783-.513-4.996.88-5.85 1.467-2.182 1.43-3.288 3.41-3.731 4.254-1.328 2.456-1.613 4.766-1.771 6.05-.19 1.796-.126 3.3 0 4.326.854.623 2.277 1.467 4.111 1.687 4.395.513 7.557-2.677 8.158-3.3Z"
      fill="#202124"
    />
    <Path
      d="M139.507 50.93c.095.514.19 1.174.285 2.017.095 1.1.19 2.347-.032 3.74-.126.88-.348 2.2-1.201 3.447a6.008 6.008 0 0 1-3.352 2.383 58.056 58.056 0 0 0 1.929 2.164c.221.403.632 1.356.506 2.53-.032.403-.095 1.026-.538 1.54-.443.55-1.043.66-1.391.696-.727.11-1.328-.146-1.897-.366a5.772 5.772 0 0 1-1.676-1.064c-1.233-1.026-1.834-2.09-1.961-2.31-.095-.183-.189-.366-.253-.476.253-.11.506-.22.822-.33a4.935 4.935 0 0 1 1.518-.22c-.253-.477-.506-.99-.79-1.504a41.283 41.283 0 0 0-1.455-2.42c-.221-.22-.474-.55-.79-.953-.127-.183-.791-1.137-1.265-2.713-.285-.954-.569-1.944-.474-3.19.063-.734.253-1.32.379-1.724a15.315 15.315 0 0 1 2.15-1.906c1.17-.844 2.245-1.614 3.763-1.76 1.265-.11 2.245.22 2.751.403 1.454.587 2.466 1.467 2.972 2.017Z"
      fill="#A98F8D"
    />
    <Path
      d="M139.666 51.004c.664 4.657.347 10.854-4.68 11.697-4.079.697-7.115-3.3-7.494-7.737 0-.183.221-.183.253 0 .379 4.437 3.573 8.397 7.62 7.37 4.585-1.136 4.68-6.966 4.079-11.293-.031-.147.19-.22.222-.037Z"
      fill="#202124"
    />
    <Path
      d="M139.824 53.68c2.434-1.576 4.964-4.142 4.743-7.662-.222-3.667-3.099-6.417-6.135-6.674-6.134-.513-10.687 6.307-10.719 12.907a.127.127 0 0 1-.253 0c.063-7.187 5.692-14.997 12.427-12.907 2.561.807 4.711 3.52 4.933 6.674.221 3.63-2.34 6.306-4.838 7.92-.158.073-.285-.184-.158-.257Z"
      fill="#202124"
    />
    <Path
      d="M139.444 51.041c-1.771-1.503-3.794-2.823-6.103-2.31-2.15.477-3.889 2.127-5.533 3.667-.127.11-.285-.074-.158-.184 1.802-1.686 3.731-3.52 6.134-3.813 2.182-.293 4.079.953 5.787 2.42.126.11 0 .33-.127.22Z"
      fill="#202124"
    />
    <Path
      d="M135.934 51.444c.696 1.393 2.309 4.95.095 5.647-.158.036-.221-.22-.063-.257 1.992-.623.379-4.033-.221-5.243-.095-.184.126-.33.189-.147ZM130.274 60.977a328.205 328.205 0 0 1 2.625 4.51c.095.147-.127.294-.19.147-.885-1.503-1.771-3.007-2.624-4.51-.095-.183.094-.293.189-.147Z"
      fill="#202124"
    />
    <Path
      d="M137.231 64.68c.759 2.09.695 4.988-1.803 4.95-2.339 0-4.3-2.052-5.565-4.106-.095-.146.127-.293.19-.146.854 1.393 1.929 2.64 3.289 3.373 1.075.587 2.972 1.173 3.826-.33.632-1.137.221-2.53-.19-3.667-.032-.183.19-.256.253-.073ZM132.962 53.46c-.209 0-.379-.328-.379-.733 0-.404.17-.733.379-.733.21 0 .38.328.38.733 0 .406-.17.734-.38.734Z"
      fill="#202124"
    />
  </Svg>
);
