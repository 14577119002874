const numberWithCommas = (num: string): string => {
  'worklet';
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const UP = '▲';
const DOWN = '▼';

export const formatPriceDiff = (price: number, base: number, currencySymbol?: string): string => {
  'worklet';
  const diff = price - base;
  const sign = diff >= 0 ? '+' : '-';
  const diffFormatted = `${sign}${currencySymbol ?? ''}${numberWithCommas(Math.abs(diff).toFixed(2))}`;
  return diffFormatted;
};

export const formatPercentageDiff = (price: number, base: number): string => {
  'worklet';
  const diff = price - base;
  const arrow = diff >= 0 ? UP : DOWN;
  const percentage = `${arrow}${numberWithCommas(Math.abs((100 * diff) / base).toFixed(2))}%`;
  return percentage;
};
