import React from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { FeedItem } from '../../generated/graphql';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { analytics } from '../../services/analytics';
import { ExternalURIs } from '../../services/externalURIs';
import { visitExternalLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { Button } from '../Button';
import { ScreenSidePadding } from '../StyledScreen';

type Props = {
  // Called after button press action to run any side-effects (e.g bottomSheet dismissal). If button action async, result is awaited before this is called
  onPressMenuItem: () => void;
  helpLink?: ExternalURIs[keyof ExternalURIs];
  navigation: AllRoutesStackNavigationProps;
  feedItem: Pick<FeedItem, 'id' | '__typename'>;
  /** Action to perform on share. */
  onShare?: () => Promise<void>;
};

/**
  Feed card menu bottom sheet to be used with the feedCardMenuReducer.
 */
export const FeedCardMenu: React.FC<Props> = ({
  helpLink,
  onShare,
  onPressMenuItem,
  navigation,
  feedItem: { id, __typename },
}) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const onLearnMore = () => {
    if (!helpLink) return;
    visitExternalLink(helpLink, navigation);
    onPressMenuItem();
  };

  const onPressShare = async () => {
    if (!onShare) return;
    await onShare();
    onPressMenuItem();
  };

  const onNotInterested = () => {
    analytics.track('Feed card not interested', { 'Feed item id': id, 'Feed card type': __typename });
    toast.show("Thanks for your feedback, we'll try and show less of this in your feed over time.");
    onPressMenuItem();
  };

  return (
    <ScreenSidePadding>
      <View style={tailwind('py-1')}>
        {onShare && <Button onPress={onPressShare} size="md" variant="secondary" text="Share" />}
      </View>
      <View style={tailwind('py-1')}>
        {helpLink && <Button onPress={onLearnMore} size="md" variant="secondary" text="Learn more" />}
      </View>
      <View style={tailwind('py-1')}>
        <Button onPress={onNotInterested} size="md" variant="secondary" text="Not interested in this" />
      </View>
    </ScreenSidePadding>
  );
};
