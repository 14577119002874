import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, ImageStyle, View, ViewStyle } from 'react-native';
import { DiscoverCategoryType } from '../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../navigation';
import { Button } from '../../old/Button';
import { Link } from '../../old/Link';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { shadowMd, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const EmptyFeedNoWatchlist: React.FC = () => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  return (
    <View style={tailwind('flex-1 justify-center px-6 ')}>
      <View style={tailwind('flex-row justify-center items-center')}>
        <LogoCard
          url="https://upside-company-logos.s3.eu-west-2.amazonaws.com/logo_NFLX_US_200px.png"
          imageStyles={tailwind('h-12 w-12')}
        />
        <LogoCard
          url="https://upside-company-logos.s3.eu-west-2.amazonaws.com/logo_AAPL_US_200px.png"
          imageStyles={tailwind('h-14 w-14 p-2')}
          cardStyles={tailwind('ml-3')}
        />
        <LogoCard
          url="https://upside-company-logos.s3.eu-west-2.amazonaws.com/logo_TSLA_US_200px.png"
          imageStyles={tailwind('h-20 w-20 p-2')}
          cardStyles={tailwind('mx-5')}
        />
        <LogoCard
          url="https://upside-company-logos.s3.eu-west-2.amazonaws.com/logo_GOOGL_US_200px.png"
          imageStyles={tailwind('h-14 w-14 p-2')}
          cardStyles={tailwind('mr-3')}
        />
        <LogoCard
          url="https://upside-company-logos.s3.eu-west-2.amazonaws.com/logo_AMZN_US_200px.png"
          imageStyles={tailwind('h-12 w-12 p-2')}
        />
      </View>
      <Text style={tailwind('mt-8 mb-4 text-gray-500 text-center font-normal text-base')}>
        We build your feed based on instruments you’re interested in.
      </Text>
      <Text style={tailwind('mb-10 text-gray-500 text-center font-normal text-base')}>
        Add instruments to your watchlist to unlock personalised content on your feed.
      </Text>
      <Button
        text="Discover"
        onPress={() => {
          navigation.navigate('Tabs', { screen: 'DiscoverTab', params: { screen: 'Discover' } });
        }}
      />
      <Text style={tailwind('text-sm text-center pt-3 text-warmGray-500')}>
        Quickstart?{' '}
        <Link
          onPress={() =>
            navigation.push('DiscoverCategory', {
              categoryType: DiscoverCategoryType.MostWatchlisted,
            })
          }
        >
          Add from most watchlisted
        </Link>
      </Text>
      <View style={tailwind('p-8')} />
    </View>
  );
};

export const EmptyFeed: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding style={tailwind('flex-1')}>
      <View style={tailwind('flex-1 items-center justify-center')}>
        <Text style={tailwind('font-semibold mb-1 text-center')}>No results found</Text>
        <Text style={tailwind('text-sm text-warmGray-500 text-center')}>
          Consider adding instruments to your watchlist to see more alerts and analysis.
        </Text>
      </View>
    </ScreenSidePadding>
  );
};

const LogoCard: React.FC<{ url: string; imageStyles?: ImageStyle; cardStyles?: ViewStyle }> = ({
  url,
  imageStyles,
  cardStyles,
}) => {
  const tailwind = useTailwind();
  return (
    <View style={[shadowMd, tailwind('rounded-2xl bg-white justify-center items-center'), cardStyles]}>
      <View style={[tailwind('overflow-hidden rounded-2xl justify-center items-center'), imageStyles]}>
        <Image
          source={{ uri: url }}
          accessibilityIgnoresInvertColors={true}
          style={[tailwind('h-full w-full')]}
          resizeMode="contain"
        />
      </View>
    </View>
  );
};
