import { Platform, Share } from 'react-native';
import { colors } from '../theme';

export type ShareContent = { message: string; url: string; title: string };
export type ShareStatus = 'Shared' | 'CopiedToClipboard' | 'Dismissed';

/**
 * Cross platform share handler. Uses Web share or native API. If Web-share not available in the browser, attempt copy to clipboard.
 *  Intentionally not handling 'dismissedAction' since it's not a requirement yet.
 *
 *  If sharing is unsuccessful, error is thrown. This is left to be handled in the respective component.
 *
 * @param content Url, title and message ;
 * @returns Share status
 */
export const share = async (content: ShareContent, userId?: string | number): Promise<ShareStatus> => {
  content.url = getShareUrl(content.url, userId);
  if (Platform.OS === 'web') {
    return await shareWeb(content);
  } else {
    return await shareNative(content);
  }
};

const shareNative = async (content: ShareContent): Promise<ShareStatus> => {
  if (Platform.OS === 'android') {
    // Android doesn't accept url property, append share link to message.
    content.message = `${content.message}

    ${content.url}
    `;
  }

  await Share.share(content, { dialogTitle: content.title, tintColor: colors.emerald[700] });
  return 'Shared';
};

/**
 * Attempts to use the Web Share API. If not available, attempts to copy to clipboard.
 *
 * @param content Share content;
 * @returns Status of the shared item
 */
const shareWeb = async (content: ShareContent): Promise<ShareStatus> => {
  // Share API not supported in Chrome MacOS https://developer.mozilla.org/en-US/docs/Web/API/Navigator/canShare
  if (await navigator.canShare?.(content)) {
    try {
      // Web share API message should be passed in 'text' key. https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
      await navigator.share({ ...content, text: content.message });
      return 'Shared';
    } catch (e) {
      // Abort Error thrown when Web Share dialog is dismissed.
      if (e instanceof Error && e.name === 'AbortError') {
        console.error(e);
        return 'Dismissed';
      } else {
        throw e;
      }
    }
  } else {
    // Copy to clipboard if unable to share.
    await navigator.clipboard.writeText(content.url);
    return 'CopiedToClipboard';
  }
};

/**
 * Appends UTM params to shared URLs so we can track referrals and share usage
 *
 * @param url Destination URL
 * @returns url with utm params appended
 */
const getShareUrl = (url: string, userId?: string | number) => {
  const parsedUrl = new URL(url);
  parsedUrl.searchParams.set('utm_medium', 'share');
  parsedUrl.searchParams.set('utm_source', 'app');
  parsedUrl.searchParams.set('utm_campaign', `${userId ?? 'no-user-id'}`);

  return parsedUrl.toString();
};
