import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import type { SchemaOf } from 'yup';
import { AuthService } from '../../generated/open-api';
import { LoggedOutStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { TextInput } from '../../old/inputs';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';

export type Props = NativeStackScreenProps<LoggedOutStackParamList, 'RequestPasswordReset'>;

type ResetPasswordFormSchema = {
  email: string;
};

const resetPasswordFormSchema: SchemaOf<ResetPasswordFormSchema> = Yup.object().shape({
  email: Yup.string().email('Must be a valid email address').required('Email required'),
});

const initialValues: ResetPasswordFormSchema = { email: '' };

export const RequestPasswordReset: React.FC<Props> = ({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const { mutateAsync, isSuccess, isError, error } = useMutation(({ email }: ResetPasswordFormSchema) =>
    AuthService.authUsersResetPassword({ data: { email } }),
  );

  const onSubmit = async ({ email }: ResetPasswordFormSchema, formik: FormikHelpers<ResetPasswordFormSchema>) => {
    await mutateAsync({ email });
    formik.setSubmitting(false);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.show('A link to reset your password has been sent to your email address.');
    }
  }, [isSuccess, toast]);

  useEffect(() => {
    if (isError) {
      toast.show('Error sending reset link, please try again.', { type: 'danger' });
      if (error && error instanceof Error) {
        console.error(`Error resetting password`, error);
      }
    }
  }, [isError, error, toast]);

  return (
    <SafeAreaView>
      <TitleBar title="Reset Password" />
      <ScrollView>
        <ScreenSidePadding style={tailwind('flex-1')}>
          <View style={tailwind('flex-grow')}>
            <Formik
              initialValues={initialValues}
              validationSchema={resetPasswordFormSchema}
              validateOnChange={false}
              onSubmit={onSubmit}
            >
              {({ values, errors, handleSubmit, handleChange, isSubmitting }) => (
                <View>
                  <View style={tailwind('py-2')}>
                    <TextInput
                      accessibilityLabel="Email"
                      onChangeText={handleChange('email')}
                      placeholder="Email"
                      value={values.email}
                      keyboardType="email-address"
                      errorMessage={errors.email}
                      autoCapitalize="none"
                    />
                  </View>
                  <View style={tailwind('py-2')}>
                    <Button
                      text={isSubmitting ? 'Sending reset link...' : 'Send reset link'}
                      size="sm"
                      loading={isSubmitting}
                      isDisabled={isSubmitting}
                      onPress={() => handleSubmit()}
                    />
                  </View>
                </View>
              )}
            </Formik>
            <View style={tailwind('py-2')}>
              <Button text="Back to log in" size="sm" variant="inverted" onPress={() => navigation.navigate('LogIn')} />
            </View>
          </View>
        </ScreenSidePadding>
      </ScrollView>
    </SafeAreaView>
  );
};
