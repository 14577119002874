export const WEBSITE_BLOGS_URI = 'https://upsidetechnology.co/blog/' as const;
export type WebsiteBlog = `${typeof WEBSITE_BLOGS_URI}${string}`;

export const EXTERNAL_ORIGIN_WHITELIST = [
  'https://help.upsidetechnology.*',
  'https://upsidetechnology.*',
  'https://*.youtube.*',
  'https://*.cnbc.*',
  'https://*.reuters.*',
  'https://*.cnn.*',
];

/**
 * Record of external URIs available from the app
 */
export const EXTERNAL_URIs = {
  PRIVACY_POLICY: 'https://help.upsidetechnology.co/privacy-policy',
  TERMS_AND_CONDITIONS: 'https://help.upsidetechnology.co/terms-and-conditions',
  FAQ: 'https://help.upsidetechnology.co/',
  SIGNIFICANT_MOVERS: 'https://help.upsidetechnology.co/significant-movers',
  REPORT_RATINGS: 'https://help.upsidetechnology.co/analyst-ratings',
  CORRELATION: 'https://help.upsidetechnology.co/correlation',
  DEATH_CROSS: 'https://help.upsidetechnology.co/crosses',
  RELATIVE_STRENGTH_INDEX: 'https://help.upsidetechnology.co/relative-strength-indicator',
  MACD: 'https://help.upsidetechnology.co/macd',
  IDEAS: 'https://help.upsidetechnology.co/idea-card',
  ESG: 'https://help.upsidetechnology.co/esg-ratings',
  ACADEMY: 'https://help.upsidetechnology.co/upside-academy-shorts',
  SEEDRS: 'https://www.seedrs.com/upside',
  WHY_UPSIDE: 'https://help.upsidetechnology.co/why-upside',
  VERIFIED_TICKS: 'https://help.upsidetechnology.co/what-do-the-verified-ticks-mean',
  TRADING_SURVEY: 'https://10uoxkle6re.typeform.com/to/UrpNkEoP',
} as const;

export type ExternalURIs = typeof EXTERNAL_URIs;
