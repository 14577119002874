import React from 'react';
import { ScrollView as NativeScrollView, ScrollViewProps } from 'react-native';
import { useTailwind } from '../../theme';

type Props = ScrollViewProps & { children: React.ReactNode };

/**
 * ScrollView with correct background, flex grow the contents
 */
export const ScrollView = React.forwardRef<NativeScrollView, Props>(({ children, style, ...props }, ref) => {
  const tailwind = useTailwind();
  return (
    <NativeScrollView style={[style, tailwind('flex-1')]} ref={ref} {...props}>
      {children}
    </NativeScrollView>
  );
});
