import * as React from 'react';
import Svg, { Ellipse, G, Path, SvgProps } from 'react-native-svg';

/** Cool Error Dog illustration from https://www.pixeltrue.com/free-packs/error-state */
export const ErrorNaughtyDog = (props: SvgProps) => (
  <Svg viewBox="0 0 600 600" {...props}>
    <Path
      fill="#C7D4FF"
      d="M419.6 375c20.2 8.9-12.7 10-56.9 13.5-44.2 3.6-69.2.4-102.1-.1-32.8-.5-81.4 1.8-84.7-8-3.4-9.8 24.1-16.4 26.6-21.9 2.4-5.4-58.4-2.4-56.9-8.7.5-2.1 2.4-4 5.5-5.7 26.8-14.9 142.9-16.3 199.4-16.7 63-.5 128.3-1.4 154.3 13.5 3.5 2 5 3.7 4.9 5.2-.4 9.8-66.4 10.3-86.1 13.4-23 3.5-24.2 6.6-4 15.5z"
    />
    <Path
      fill="#99ADF9"
      d="M390 358.6c14.9 5.8-9.4 6.4-42 8.7-32.7 2.2-51.2.2-75.4-.2-24.3-.4-60.1 1.1-62.6-5.3s17.8-10.6 19.6-14.1c1.8-3.5-43.2-1.6-42.1-5.8.4-1.4 1.8-2.6 4-3.7 19.8-9.6 105.6-10.3 147.3-10.6 46.5-.3 105.1-3.4 114 6.1 4.3 4.6-45.4 12.9-60 14.8-16.9 2.3-17.8 4.3-2.8 10.1z"
    />
    <Path
      fill="#99ADF9"
      d="M419.6 375c20.2 8.9-12.7 10-56.9 13.5-44.2 3.6-69.2.4-102.1-.1-32.8-.5-81.4 1.8-84.7-8-3.4-9.8 24.1-16.4 26.6-21.9 2.4-5.4-58.4-2.4-56.9-8.7.5-2.1 2.4-4 5.5-5.7.8 1.5 4 3 12.9 3.7 20.3 1.5 49 2.6 50.7 6.9 1.7 4.3-28.2 16.1-24.4 22.1 3.8 5.9 84.3 3.5 132.4 5.2 48.1 1.7 90.9-1.4 85.4-4.7s-18.9-10.6-8.8-17.2c10.1-6.6 65.8-7.7 89.3-10 9.6-.9 16.4-2.5 21-4-.4 9.8-66.4 10.3-86.1 13.4-22.9 3.5-24.1 6.6-3.9 15.5zM474 338.9c-6.1.5-5.6 6.5 4.8 5.1 10.3-1.3 7.1-6.1-4.8-5.1z"
    />
    <Path
      fill="#99ADF9"
      d="M437.9 343.4c-6.1.5-5.6 6.5 4.8 5.1 10.4-1.2 7.1-6.1-4.8-5.1zM175.2 340.9c-6.1.5-5.6 6.5 4.8 5.1 10.4-1.2 7.1-6-4.8-5.1zM207.8 361.6c-6.1.5-5.6 6.5 4.8 5.1 10.3-1.3 7.1-6.1-4.8-5.1z"
    />
    <Path
      fill="#C7D4FF"
      d="M530.3 370c-6.9 4.7-17.5 5.7-33.2 5.9-11.3.2-17.7-3.4-16.9-6.9.6-2.6 5.3-5.1 14.9-6.1 17.9-1.8 34.3.4 36.5 3.9.7 1 .3 2.1-1.3 3.2zM191.7 326.2c-8.3 4.8-21.3 6.1-40.7 6.7-13.9.4-21.9-3-20.9-6.5.7-2.6 6.4-5.3 18.2-6.4 21.9-2.2 42.2-.4 45.1 3.1.8.9.3 1.9-1.7 3.1zM509.8 178.1s13.6-1.4 28.7 18.2c12 15.5 9.3 34 9.3 34s9.2-19-3.2-36.7c-13.3-18.9-34.8-15.5-34.8-15.5zM502.6 239.2s3.6 8.4.2 20.4-9.8 17-9.8 17 11.5-3.1 14.3-16.5c2.9-13.5-4.7-20.9-4.7-20.9z"
    />
    <Path
      fill="#99ADF9"
      d="M213.9 248.4s-1.4-7.8 9.6-22.5 27-20.3 27-20.3l-19.4-40.1s7.3 28.1-3.4 36.5c-10.8 8.4-28.6-2.4-28.6-2.4s10.6 18.6 7.2 26.8C203 234.6 185 236 185 236l28.9 12.4z"
    />
    <G fill="#99ADF9">
      <Path d="m275.8 407-12 14.4c-1.2 1.5-3.5 1.7-5 .4-1.5-1.2-1.7-3.5-.4-5l.4-.4 14.2-12.3c.8-.7 2.1-.6 2.8.2.7.9.6 2 0 2.7zM292.5 408.7l-2.4 29.2c-.1 1.6-1.6 2.8-3.2 2.7-1.6-.1-2.8-1.6-2.7-3.2v-.1l3.9-29.1c.2-1.2 1.3-2 2.5-1.9 1.2.2 2 1.3 1.9 2.4zM309.6 407.6l7.9 20.7c.7 1.8-.2 3.9-2 4.6-1.8.7-3.9-.2-4.6-2-.1-.1-.1-.3-.1-.5l-5-21.6c-.2-1.1.4-2.1 1.5-2.4 1-.2 2 .3 2.3 1.2zM325.7 404.3l11.9 8c1.6 1.1 2.1 3.3 1 4.9-1.1 1.6-3.3 2.1-4.9 1-.2-.1-.4-.3-.5-.4l-10.3-10c-.9-.9-.9-2.3 0-3.1.7-.9 1.9-1 2.8-.4z" />
    </G>
    <Path
      fill="#FEC272"
      d="M535.2 254.6c-5.9 18.4-31.7 27.8-31.7 27.8l.8-.8c3.7-4.2 19-23.6 5.9-48.8-14.8-28.6-63.7-18.8-63.7-18.8l-1.1-7.1-1.2-7.4s26.6-23.7 62.5-10.2c34.2 12.9 34.4 47 28.5 65.3z"
    />
    <Path
      fill="#FCAA51"
      d="M516.2 270.6c-2.8 4.4-7.2 8.1-11.8 11 3.7-4.2 19-23.6 5.9-48.8-14.8-28.6-63.7-18.8-63.7-18.8l-1.1-7.1c10.6-1.5 38.6-3.9 57.6 7.1 22.6 13.1 22.5 41.9 13.1 56.6z"
    />
    <Path
      fill="#FEC272"
      d="M455.1 236.5c-.5 15.9-3.1 29.7-6.7 37.7-7.7 17.4-13.1 27.3-24.4 36.1-4.2 3.3-9.2 6.4-15.5 9.7-23.1 12.1-86 12.9-86 12.9-2.5.2-5 .4-7.5.5-22.9 1.2-44.5-1.3-57.8-8.5-6.2-3.3-10.6-7.7-12.5-13.1-6.3-18.4-8.6-46.5 33.2-117.7 26.9-45.8 60.5-63.9 92.3-63.7 16.9.1 33.2 5.4 47.8 14.4.6.4 1.3.8 1.9 1.2 28.2 18.2 36.2 58.6 35.2 90.5z"
    />
    <Path
      fill="#FFCB8D"
      d="M370.2 130.3c-18.8 7.6-47.7 26.5-73.9 73.5-33.3 59.7-38.5 103.8-39.2 121-6.2-3.3-10.6-7.7-12.5-13.1-6.3-18.4-8.6-46.5 33.2-117.7 27.1-45.8 60.7-63.9 92.4-63.7z"
    />
    <Path
      fill="#FCAA51"
      d="M455.1 236.5c-.5 15.9-3.1 29.7-6.7 37.7-10.6 23.8-16.8 33.6-39.9 45.7-23.1 12.1-86 12.9-86 12.9-2.5.2-5 .4-7.5.5-.7-9.5 1.7-22.3 7.3-39 17-51 44-69.8 74.5-75.7 26.3-5 46.8 7.8 58.3 17.9z"
    />
    <Path
      fill="#FCAA51"
      d="M455.1 236.5c-.5 15.9-3.1 29.7-6.7 37.7-7.7 17.4-13.1 27.3-24.4 36.1 7-19.4 13.7-46.1 14.3-78.3.7-42.6-11.1-71.5-20.3-87.2.6.4 1.3.8 1.9 1.2 28.2 18.2 36.2 58.6 35.2 90.5z"
    />
    <Path
      fill="#FEC272"
      d="M455.6 243.9c-1.6 18.7-9.4 48.7-16.7 68.6-2.9 7.9-5.7 14.3-8.1 17.6-4 5.7-11.8 8.2-19.6 8.7-8.2.6-16.5-.8-20.3-2.9-7-3.7-24.4-35.6-20.5-70.4.2-2.1.5-4.2.9-6.3 7-37.1 24.4-47.5 50.8-44.6 5.2.6 10.7 1.5 15.8 3.3 10.6 3.8 19.1 11 17.7 26z"
    />
    <Path
      fill="#FCAA51"
      d="M438.9 312.5c-2.9 7.9-5.7 14.3-8.1 17.6-8.2 11.7-32.4 9.8-39.9 5.9-7-3.7-24.4-35.6-20.5-70.4 2.2 16.1 10.2 38.7 36.9 44.5 12.9 2.9 23.3 3.3 31.6 2.4z"
    />
    <Path
      fill="#FCAA51"
      d="M455.6 243.9c-1.6 18.7-9.4 48.7-16.7 68.6-2.9 7.9-5.7 14.3-8.1 17.6-4 5.7-11.8 8.2-19.6 8.7 7.9-14.2 26.1-52.9 27-106.9.1-4.8 0-9.5-.2-14 10.5 3.8 19 11 17.6 26z"
    />
    <Path
      fill="#FEC272"
      d="M430.1 343.3c-.9.2-2.3.4-4 .5-1.3.1-2.7.3-4.3.4-3 .2-6.4.4-9.9.5-1.4 0-2.9.1-4.3.1-3 .1-5.9 0-8.6 0h-1.1c-1.1 0-2.2-.1-3.1-.1-.4 0-.8-.1-1.1-.1-1.6-.1-2.8-.4-3.7-.6-6.1-1.9-9.8-7.9-5.1-15.2 4.7-7.3 14.9-13.1 28.4-13.1 13.5 0 24.8 7.9 25.4 16.4.6 8.4-2.3 10-8.6 11.2z"
    />
    <Path
      fill="#FFA245"
      d="M412 331.4v13.3c-1.4 0-2.9.1-4.3.1v-13.4c0-1.2 1-2.2 2.1-2.2s2.2 1 2.2 2.2zM399.2 331.6v13.2h-1.1c-1.1 0-2.2-.1-3.1-.1v-13c0-1.3 1-2.4 2.1-2.4 1.1-.1 2.1 1 2.1 2.3zM426.2 331.4v12.4c-1.3.1-2.7.3-4.3.4v-12.8c0-1.2 1-2.2 2.1-2.2s2.2 1 2.2 2.2z"
    />
    <Path
      fill="#FF8D76"
      d="M261.9 265.2c-8.2 17.2-2.3 59-8.2 71.9s-21.9 19.5-38.3 19.2c-1.8 0-3.5-.3-5.2-.7-13.8-3.4-23.6-18.7-15.9-36.8 8.6-20.3 29-30.5 37.5-60.6 7.7-27 18.5-31.8 29.5-33 3.7-.4 6 1.2 7.3 4.1 3.3 7.3-.7 23.5-6.7 35.9z"
    />
    <Path
      fill="#FC7059"
      d="M261.9 265.2c-8.2 17.2-2.3 59-8.2 71.9s-21.9 19.5-38.3 19.2c-1.8 0-3.5-.3-5.2-.7-2.2-5.1-3.1-12.7-.2-23.7 7.3-27.6 21.4-32.8 24-52.2 2.7-19.4 3-31.4 10-41.9 6.3-9.4 21.3-8.8 24.6-8.5 3.3 7.3-.7 23.5-6.7 35.9z"
    />
    <Path
      fill="#FF8D76"
      d="M393 321.9c-1.1 3.4-2.9 6.3-5.2 8.8-9 9.8-25.4 13-35 11.4-12-2-23.8-26.1-22.6-44 1.2-17.9-11.7-41.4-14.9-53.1-2.4-8.7-2-15.3.6-19.3.9-1.4 2.1-2.5 3.5-3.3 3.3-1.7 15.5-7 28.2 12 12.6 19.1 11.7 29.3 25.8 43.4 14.1 14.2 24.9 27.4 19.6 44.1z"
    />
    <Path
      fill="#FC7059"
      d="M387.9 330.7c-9 9.8-25.4 13-35 11.4-12-2-23.8-26.1-22.6-44 1.2-17.9-11.7-41.4-14.9-53.1-2.4-8.7-2-15.3.6-19.3 7.6-1.1 19.6.1 25.2 15.8 8.6 23.9 7.4 36.2 20.7 47.9 13.3 11.7 24.3 24.4 26 41.1v.2z"
    />
    <Path
      fill="#FEC272"
      d="M371.2 338.4c-1.3 11-7.1 18.4-20.4 23.1-19.9 7-38.4-2.3-45.7-3.5-7.5-1.3-18.8 5.9-30.2 9.9-11.4 4.1-32.8 5.6-44.2-5.7-6-6-9.9-14.5-10.6-23.4-.7-7.8 1.1-16 5.9-23.2 10.2-15.2 15.6-30.1 17.2-59.4 1.6-29.3 24.4-41.1 46.3-43 6.9-.6 26.1-1.6 40.7 11.3 14.7 12.9 13.5 39.3 18.5 52.2 5 12.9 18.5 20.8 21.7 42.8 1.1 7.3 1.5 13.5.8 18.9z"
    />
    <Path
      fill="#FCAA51"
      d="M314.1 321.2c-2.9 7.9-10 13.6-14.8 13.6-4.8 0-14.1-4.6-18.6-8.8-4.8-4.5-5.1-8.8-4-11.7 0-.1.1-.3.2-.4 1.3-2.8 9.1-5.3 18.9-6.6 7.5-1 16-1.2 18 .1.5.4 1 .9 1.4 1.7 1 2.3 1.1 6.3-1.1 12.1zM371.2 338.4c-1.3 11-7.1 18.4-20.4 23.1-19.9 7-38.4-2.3-45.7-3.5-7.5-1.3-18.8 5.9-30.2 9.9-11.4 4.1-32.8 5.6-44.2-5.7-6-6-9.9-14.5-10.6-23.4 4.7 7.7 14.5 20.8 27.4 23 18.4 3.1 29.3-13.3 54.8-13.3 32.8 0 35.2 12.5 55.7 1.8 5.7-3.1 10-7.4 13.2-11.9z"
    />
    <Path
      fill="#FC7059"
      d="M313.5 349.8c-.2 2.2-.5 4.7-1.2 7.2-1.9 7-9.7 11.3-16.1 7-4.1-2.7-6.1-8.9-7-13.1-.5-2.5-.7-4.2-.7-4.2l12.2-13 13 9c-.1 0 .1 3.1-.2 7.1z"
    />
    <Path
      fill="#F45145"
      d="M313.5 349.8c-6.3.6-12.1-3.5-12.1-3.5s-2.3 2.4-7.2 4.2c-1.6.6-3.4.7-5 .5-.5-2.5-.7-4.2-.7-4.2l12.2-13 13 9c-.1-.1.1 3-.2 7z"
    />
    <Path
      fill="#1C3177"
      d="M262.1 337.9c-2.9 3.3 4 10.8 15.2 12.3 11.3 1.5 22-4.5 23.2-9.2s.1-12.2.1-12.2h-3.8s1.2 10.7-8.5 15c-15 6.6-24.2-8.3-26.2-5.9z"
    />
    <Path
      fill="#1C3177"
      d="M302.4 326.7s.7 10.1 10 13.8c9.2 3.7 13.4 1.3 16.9-.3 3.8-1.8 3.8-5.6 6.5-4.5 1.4.6.9 5.6-3.9 8.8-5.7 3.8-11.3 4.3-19.1 2.4-7.8-1.9-12-5.4-13-9.7-1-4.3-1.6-9.2-1.6-9.2l4.2-1.3z"
    />
    <Path
      fill="#1C3177"
      d="M314.1 316.7c-2.9 7.9-10 13.6-14.8 13.6-4.8 0-14.1-4.6-18.6-8.8-4.8-4.5-5.1-8.8-4-11.7 0-.1.1-.3.2-.4 1.3-2.8 9.1-5.3 18.9-6.6 7.5-1 16-1.2 18 .1.5.4 1 .9 1.4 1.7 1 2.4 1.1 6.3-1.1 12.1z"
    />
    <Path
      fill="#0B2359"
      d="M314.1 316.7c-2.9 7.9-10 13.6-14.8 13.6-4.8 0-14.1-4.6-18.6-8.8-4.8-4.5-5.1-8.8-4-11.7.5 0 1 .2 1.5.8 2.4 2.6 4.5 8.2 13.2 13.1 8.8 4.9 12.8 1.3 16.7-3.8 3.9-5.1 4.6-13.9 6.5-15.1.2-.1.3-.2.5-.2 1.1 2.4 1.2 6.3-1 12.1z"
    />
    <Ellipse fill="#1C3177" cx={262.4} cy={292.8} rx={7.3} ry={10.8} />
    <Ellipse transform="rotate(-5.981 326.7 287.568)" fill="#1C3177" cx={326.7} cy={287.6} rx={7.3} ry={10.8} />
    <Path
      fill="#FF8D76"
      d="M257.4 258.8c-4.1 3.5-6.4 7.5-7.5 10.5-1.2 2.9.2 4 3.7 4.1 3.5.1 9.4-4.2 12.6-9.2 3.2-5.1-2.5-10.9-8.8-5.4zM327.4 252.4c4.6 2.8 7.4 6.5 9.1 9.2s.4 4-3 4.6c-3.5.6-9.9-2.7-13.9-7.1-4-4.4.7-11.1 7.8-6.7z"
    />
    <Path
      fill="#1C3177"
      d="M72.9 471.3c-4.6-.1-7.6-.3-8.1-.3-2.6-.2-4.5-2.4-4.4-5 .2-2.6 2.3-4.6 5-4.4.5 0 35.3 3.6 64-8.2 10.6-4.3 16.5-8.2 17.6-11.3.8-2.3-1-5.7-4-11.1-1-1.8-2.1-3.7-3.2-5.8-2.4-4.7-2.6-9.9-.6-14.9 6.5-16 34-26.6 42.3-29.4 46.1-15.8 80.8-9.7 82.2-9.4 2.5.5 4.2 2.9 3.8 5.5-.5 2.6-2.9 4.2-5.5 3.8-.3-.1-33.9-5.9-77.5 9.1-19 6.5-33.4 16-36.7 24-1 2.5-.9 4.8.3 7.1 1 1.9 2 3.8 3 5.5 3.6 6.3 6.9 12.3 4.7 18.8-2.1 6.2-9 11.2-22.9 16.9-23.5 9.6-44.4 9.5-60 9.1z"
    />
    <Path
      fill="#F0F6FF"
      d="M254.1 383.6c1.7 4 5.8 6.5 12.6 7.7 10.3 1.8 16.9-.8 16.9-.8l-.2-3.8-1.4-22.2s-8.8-1.2-18 1.8c-8.1 2.7-11.6 7.1-10.9 13.8.2 1.2.5 2.4 1 3.5z"
    />
    <Ellipse
      transform="scale(-1) rotate(-3.629 -11913.044 8922.828)"
      fill="#C2D7EF"
      cx={282.7}
      cy={377.4}
      rx={3.7}
      ry={13.2}
    />
    <Path
      fill="#C2D7EF"
      d="M254.1 383.6c1.7 4 5.8 6.5 12.6 7.7 10.3 1.8 16.9-.8 16.9-.8l-.2-3.8c-4-.2-9.1-.5-14-1-7-.9-12.2-1.6-15.3-2.1z"
    />
    <Path
      fill="#87AACC"
      d="m293.7 373.7-9.1.9c-1.2.1-2.3-.8-2.4-2l-.1-.6c-.1-1.2.8-2.3 2-2.4l9.1-.9c1.4-.1 2.6.9 2.7 2.2.2 1.5-.8 2.7-2.2 2.8zM294.6 384.9l-9.1.9c-1.2.1-2.3-.8-2.4-2l-.1-.6c-.1-1.2.8-2.3 2-2.4l9.1-.9c1.4-.1 2.6.9 2.7 2.2.2 1.5-.8 2.7-2.2 2.8z"
    />
    <Path
      fill="#1C3177"
      d="M536.9 469.2c-6.8-.2-66.6-2.4-83.9-23.8-11.7-14.5-4.8-24.3 1.2-33 1.4-2 2.8-4.1 4.1-6.2 2.1-3.6 2.2-7.5.2-11.2-2.1-3.9-9.9-13.1-36.5-14.2-38.1-1.7-87.5.4-88 .5-2.6.1-4.8-1.9-4.9-4.5-.1-2.6 1.9-4.8 4.5-4.9.5 0 50.4-2.2 88.9-.5 30.2 1.4 40.7 12.6 44.3 19.2 3.6 6.6 3.4 14-.3 20.4-1.4 2.5-3 4.7-4.5 6.8-5.9 8.5-8.9 12.8-1.6 21.8 12.7 15.7 60.2 20 77.4 20.3 2.6.1 4.6 2.2 4.6 4.8-.1 2.6-2.2 4.6-4.8 4.6-.3-.1-.5-.1-.7-.1z"
    />
    <Path
      fill="#F0F6FF"
      d="M340.1 379.8c-1.4 4.1-5.2 7-11.9 8.7-10.1 2.6-16.9.5-16.9.5l-.1-3.8-.4-22.2s8.7-2 18 .3c8.3 2 12.1 6.1 12 12.9-.1 1.3-.3 2.5-.7 3.6z"
    />
    <Path
      fill="#C2D7EF"
      d="M340.1 379.8c-1.4 4.1-5.2 7-11.9 8.7-10.1 2.6-16.9.5-16.9.5l-.1-3.8c4-.5 9-1.2 13.9-2.2 6.9-1.3 11.9-2.4 15-3.2z"
    />
    <Ellipse
      transform="rotate(-1.057 311.102 375.996) scale(.99997)"
      fill="#C2D7EF"
      cx={311.1}
      cy={376}
      rx={3.7}
      ry={13.2}
    />
    <Ellipse
      transform="rotate(-1.057 310.943 370.736) scale(.99997)"
      fill="#87AACC"
      cx={311}
      cy={370.7}
      rx={0.9}
      ry={4.4}
    />
    <Ellipse
      transform="rotate(-1.057 311.033 382.07) scale(.99997)"
      fill="#87AACC"
      cx={311}
      cy={382.1}
      rx={0.9}
      ry={4.4}
    />
    <Path
      fill="#FCAA51"
      d="M247.6 378.5c0 .8 0 1.6-.1 2.3-.5 5.1-3 8.8-8.4 11.3-1.5.7-3.3 1.3-5.4 1.7-3.6.9-8.1 1.4-13.5 1.7-1.2.1-2.5.1-3.9.1h-2.9c-3.4 0-6.6-.1-9.6-.3-2-.2-4-.5-5.7-.8-7.4-1.6-12.2-5.1-13.1-12 .6 5.1-10.2-28.1 31.1-32 19.1-1.9 31.5 16.6 31.5 28z"
    />
    <Path
      fill="#FEC272"
      d="M247.6 378.5c0 .8 0 1.6-.1 2.3-4 5.4-13.4 8-31.4 8.3-14.3.3-25.8-.7-31.1-6.7.6 5.1-10.2-28.1 31.1-32 19.1-1.8 31.5 16.7 31.5 28.1z"
    />
    <Path
      fill="#FFA245"
      d="M203.8 395.3c-2-.2-4-.5-5.7-.8-.3-2.7-.9-9.2-.4-14.6.7-7.1 3.4-7.5 4.2-6.2.8 1.3.3 8.9 1.2 15.9.2 2 .5 4 .7 5.7zM239.1 392.1c-1.5.7-3.3 1.3-5.4 1.7-.2-1.9-.4-4.2-.7-6.6-.8-7-3.1-14.3-2.7-15.7.4-1.4 3.2-1.7 5.5 5.1 2.3 6.3 3.2 14.4 3.3 15.5zM220.2 395.5c-1.2.1-2.5.1-3.9.1h-2.9c0-2.7-.1-6.3-.3-9.6-.3-6.5-.5-10.7 1.5-11.1 2-.4 4.1 6.8 5 14.4.3 2.6.5 4.6.6 6.2z"
    />
    <Path
      fill="#FCAA51"
      d="M346.5 373.3c0 .8.1 1.6.2 2.3.6 5.1 3.1 8.8 8.5 11.1 1.6.7 3.4 1.2 5.4 1.7 3.6.8 8.1 1.3 13.5 1.5 1.2 0 2.5.1 3.9.1h2.9c3.4 0 6.6-.1 9.6-.5 2-.2 3.9-.5 5.7-.9 7.3-1.7 12.1-5.2 12.9-12.2 1.3-11.8-12.4-30-31.5-31.6-19.1-1.6-31.3 17-31.1 28.5z"
    />
    <Path
      fill="#FEC272"
      d="M346.5 373.3c0 .8.1 1.6.2 2.3 4.1 5.4 13.5 7.8 31.5 7.9 14.3.1 25.8-1.1 31-7.1 1.3-11.8-12.4-30-31.5-31.6-19.2-1.6-31.4 17-31.2 28.5z"
    />
    <Path
      fill="#FFA245"
      d="M390.5 389.5c2-.2 3.9-.5 5.7-.9.3-2.7.8-9.2.2-14.6-.8-7.1-3.5-7.5-4.3-6.2-.7 1.3-.1 8.9-.9 15.9-.2 2.1-.5 4-.7 5.8zM355.2 386.7c1.6.7 3.4 1.2 5.4 1.7.1-1.9.3-4.2.6-6.6.7-7 2.9-14.3 2.4-15.7-.4-1.4-3.2-1.7-5.5 5.1-2 6.3-2.8 14.4-2.9 15.5zM374.1 389.8c1.2 0 2.5.1 3.9.1h2.9c0-2.7 0-6.3.1-9.6.2-6.5.3-10.7-1.6-11.1-2-.4-4 6.9-4.8 14.5-.3 2.6-.4 4.6-.5 6.1z"
    />
  </Svg>
);
