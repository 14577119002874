import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const FullLogo: React.VFC = (props: SvgProps) => (
  <Svg width={147} height={40} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M134.048.347L118.529 7.46l11.238 21.727 15.764-7.226-3.071-5.935-7.315 3.352-1.103-2.131 6.458-2.961-2.921-5.648-6.458 2.96-1.073-2.074 7.07-3.241-3.07-5.936zM28.141 22.824l6.28-13.015-8.493-3.633-5.914 12.257c-.83 1.722-1.625 3.213-3.887 2.245-2.264-.967-1.594-2.512-.762-4.233l5.914-12.257L12.785.555l-6.28 13.016c-3.098 6.42-.229 11.168 6.312 13.965 6.54 2.798 12.227 1.708 15.324-4.712zm20.052-10.01L36.67 10.361l-5.664 23.571 8.767 1.867 1.705-7.096 4.65.99c5.08 1.081 8.988-1.24 10.145-6.055 1.405-5.846-2.236-9.579-8.08-10.824zm-4.391 10.14l-.897-.191.984-4.095.864.184c1.494.317 3.331.808 2.888 2.652-.458 1.907-2.245 1.79-3.84 1.45zm16.419 7.464l.46.37c1.616 1.28 3.58 2.35 5.723 2.43.919.036 2.396-.26 2.443-1.35.042-.983-.95-1.195-1.763-1.368a19.217 19.217 0 01-.254-.056l-1.858-.391c-3.613-.747-6.588-2.881-6.426-6.66.246-5.733 5.929-8.724 11.507-8.512 2.925.111 5.584.886 8.04 2.423l-2.783 6.085c-1.555-1.086-3.323-1.955-5.33-2.031-.817-.031-2.02.212-2.062 1.204-.035.8.875 1.023 1.574 1.195.086.021.168.041.246.062l2.09.528c3.91.983 6.682 3.077 6.507 7.176-.248 5.765-5.95 8.403-11.494 8.192-3.232-.123-6.938-1.098-9.77-2.585l3.15-6.712zm30.104-16.085l-9.285.852 2.49 24.055 9.284-.852-2.489-24.055zm3.714-.42l9.177-2.312c8.321-2.096 15.679-.266 18.116 8.312 1.972 6.937-1.976 12.643-9.245 14.473l-11.413 2.875-6.635-23.349zm13.659 14.08l.558-.14c2.994-.755 4.926-2.635 4.056-5.701-.933-3.283-3.336-4.171-6.625-3.343l-.559.141 2.57 9.043z"
      fill="#7EC56D"
    />
  </Svg>
);
