import React from 'react';
import { Image, View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { getLogoPlaceholder } from '../../util/string';

type Size = 'small' | 'medium' | 'large';
type Props = { logoUrl?: string; ticker: string; size: Size };

export const InstrumentLogo: React.FC<Props> = ({ size, logoUrl, ticker }) => {
  const tailwind = useTailwind();
  const sizeStyles = sizeToStyles(size);
  return logoUrl ? (
    <Image
      source={{ uri: logoUrl }}
      style={tailwind(sizeStyles)}
      resizeMode="center"
      accessibilityIgnoresInvertColors={true}
    />
  ) : (
    <View style={tailwind(`bg-greys-500 justify-center items-center ${sizeStyles}`)}>
      <Text style={[tailwind('text-xs text-center text-greys-700 font-medium'), { fontFamily: 'IBMPlexMono-Regular' }]}>
        {getLogoPlaceholder(ticker ?? '')}
      </Text>
    </View>
  );
};

const sizeToStyles = (size: Size): string => {
  switch (size) {
    case 'small':
      return 'h-9 w-9 rounded-xl';
    case 'medium':
      return 'h-11 w-11 rounded-xl';
    case 'large':
      return 'h-14 w-14 rounded-2xl';
  }
};
