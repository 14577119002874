import React from 'react';
import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

export const CirclePartial: React.VFC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <G clipPath="url(#prefiåx__clip0_280_8099)" fill="#0F766E">
        <Path
          opacity={0.4}
          d="M12 24a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22a10 10 0 1010 10A10.011 10.011 0 0012 2z"
        />
        <Path d="M23.957 11c.047.55-.405 1-.958 1-.552 0-.994-.448-1.05-.997a10.011 10.011 0 00-8.952-8.952C12.448 1.995 12 1.553 12 1c0-.553.45-1.005 1-.958A12.013 12.013 0 0123.957 11z" />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_280_8099">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
