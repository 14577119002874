import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Profile = (props: SvgProps) => (
  <Svg width={26} height={26} viewBox="0 0 26 26" fill="none" {...props}>
    <Path
      d="M13 13.8a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM20.925 21.994a8.786 8.786 0 0 0-15.85 0"
      stroke="#0F766E"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Z"
      stroke="#0F766E"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
