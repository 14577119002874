import { Financials } from '../../generated/graphql';
import { formatNumber } from '../../util/number';
/**
 * Divides number by 1000 and formats to be presented in financials table
 *
 * @param value Number
 * @returns Formatted number with space separators insteada of commas.
 */
export const formatFinancialValue = (value: number) => formatNumber(value / 1000, 0).replace(/,/g, ' ');

/**
 * Returns the current (latest) and previous (2nd latest) financials from a list of earnings
 *
 * @param financials Array of financials
 * @returns Two most recent financials.
 */
export const getCurrentAndPreviousFinancials = (
  financials: Financials[],
): { currentFinancials: Financials; prevFinancials?: Financials } => {
  if (financials.length < 2) return { currentFinancials: financials[0] };

  const sortedFinancials = [...financials].sort((a, b) => {
    // Sort by date, descending
    return new Date(b.date ?? '').getTime() - new Date(a.date ?? '').getTime();
  });

  return { currentFinancials: sortedFinancials[0], prevFinancials: sortedFinancials[1] };
};
