import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { TextInput, View } from 'react-native';
import { LoggedInStackNavigationProps } from '../../navigation';
import { IconButton } from '../../old/Button/IconButton';
import { Close } from '../../old/icons/Close';
import { colors, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';

type Props = {
  value: string;
  onChangeText: (text: string) => void;
  onClear?: () => void;
};

export const SearchBar = React.forwardRef<TextInput, Props>(({ value, onChangeText, onClear }, ref) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  const onPressClear = () => {
    onChangeText('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <View style={tailwind('h-14 items-center flex-row')}>
      <Pressable
        onPress={() => navigation.goBack()}
        style={tailwind('pr-6 justify-center h-full')}
        accessibilityRole="button"
      >
        <Text style={tailwind('font-medium text-teal-800')}>Close</Text>
      </Pressable>
      <TextInput
        autoFocus
        accessibilityLabel="Search"
        ref={ref}
        style={tailwind('flex-1')}
        value={value}
        onChangeText={onChangeText}
        selectionColor={colors.amber['400']}
        placeholder="Search for companies or users"
      />
      {value ? (
        <IconButton
          accessibilityLabel="Clear Search Text"
          style={tailwind('items-center justify-center pl-2')}
          onPress={onPressClear}
          icon={<Close />}
        />
      ) : null}
    </View>
  );
});
