import BottomSheet from '@gorhom/bottom-sheet';
import { NavigationContainer, useNavigation, useNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';
import { StyleSheet } from 'react-native';
import { AppEntityType } from '../../generated/graphql';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { usePersistedStore } from '../../zustand/store';
import { Comments } from './Comments';
import { Input } from './Input';
import { Replies } from './Replies';

export type CommentsParamList = {
  Comments: {
    entityId: string;
    entityType: AppEntityType;
    forceRefetch?: boolean;
  };
  Replies: {
    entityId: string;
    entityType: AppEntityType;
    parentCommentId: string;
    replyId?: string;
    forceRefetch?: boolean;
  };
};

type Props = {
  entityType: AppEntityType;
  entityId: string;
  offset: number;
  initialVisibility?: boolean;
  commentId?: string;
};

const Stack = createNativeStackNavigator<CommentsParamList>();

type CommentSheetContext = {
  showCommentInput: (parentCommentId?: string) => void;
  showUserProfile: (userId: number) => void;
  showIdea: (ideaId: string) => void;
};

export const CommentSheetContext = React.createContext<CommentSheetContext>({
  showCommentInput: () => undefined,
  showUserProfile: () => undefined,
  showIdea: () => undefined,
});

export const CommentSheet = React.forwardRef<BottomSheet, Props>(
  ({ entityId, entityType, commentId, offset, initialVisibility }, ref) => {
    const tailwind = useTailwind();
    const mainNavigation = useNavigation<AllRoutesStackNavigationProps>();
    const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
    const sheetNavigation = useNavigationContainerRef<CommentsParamList>();
    const [inputVisible, setInputVisible] = React.useState<{ show: boolean; parentCommentId?: string }>({
      show: false,
    });

    React.useEffect(() => {
      if (commentId) {
        sheetNavigation.navigate('Replies', { entityId, entityType, parentCommentId: commentId, forceRefetch: true });
      }
    }, [commentId, entityId, entityType, sheetNavigation]);

    return (
      <CommentSheetContext.Provider
        value={{
          showCommentInput: (parentCommentId?: string) => {
            if (isUserLoggedIn) {
              setInputVisible({ show: true, parentCommentId });
            } else {
              mainNavigation.navigate('SignUp', { external: true });
            }
          },
          showUserProfile: (userId: number) => {
            mainNavigation.push('UserProfile', { userId });
          },
          showIdea: (ideaId: string) => {
            // don't navigate anywhere if press on idea flair chip on current idea screen you're on
            if (ideaId === entityId && entityType === AppEntityType.Idea) return;
            mainNavigation.push('Idea', { ideaId });
          },
        }}
      >
        <BottomSheet
          key={entityId}
          handleIndicatorStyle={tailwind('bg-teal-700')}
          backgroundStyle={tailwind('rounded-t-3xl')}
          snapPoints={[offset]}
          /**
           * Setting activeOffsetY forces BottomSheet to ignore horizontal pan gestures
           * so the iOS swipe back gesture on the stack navigator can work
           */
          activeOffsetY={[-12, 12]}
          handleStyle={StyleSheet.absoluteFill}
          enablePanDownToClose={true}
          ref={ref}
          index={initialVisibility ? 0 : -1}
          style={styles.sheet}
          keyboardBlurBehavior="none"
          keyboardBehavior="fillParent"
          android_keyboardInputMode="adjustResize"
        >
          <NavigationContainer independent={true} ref={sheetNavigation}>
            <Stack.Navigator
              initialRouteName="Comments"
              screenOptions={{
                headerShown: false,
                presentation: 'card',
                animation: 'slide_from_right',
                contentStyle: tailwind('bg-white rounded-t-3xl'),
              }}
            >
              <Stack.Screen name="Comments" component={Comments} initialParams={{ entityId, entityType }} />
              <Stack.Screen name="Replies" component={Replies} />
            </Stack.Navigator>
          </NavigationContainer>
        </BottomSheet>
        <Input
          visible={inputVisible.show}
          entityId={entityId}
          entityType={entityType}
          parentCommentId={inputVisible.parentCommentId}
          dismissHandler={() => {
            setInputVisible({ show: false });
          }}
          onPostSuccessHandler={(id) => {
            setInputVisible({ show: false });
            const { parentCommentId } = inputVisible;
            if (parentCommentId) {
              sheetNavigation.current?.navigate('Replies', {
                entityId,
                entityType,
                parentCommentId: parentCommentId,
                replyId: id,
              });
            } else {
              sheetNavigation.current?.navigate('Comments', {
                entityId,
                entityType,
              });
            }
          }}
        />
      </CommentSheetContext.Provider>
    );
  },
);

const styles = StyleSheet.create({
  sheet: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3.25,
    },
    shadowOpacity: 0.5,
    shadowRadius: 5.65,
    backgroundColor: 'white',
    elevation: 6,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    flex: 1,
  },
});
