import { FeedItem, FeedItemType } from '../generated/graphql';
import { EXTERNAL_URIs, ExternalURIs } from '../services/externalURIs';

/**
 * Feed item types we can render, affects both main and instrument feed.
 */
export const FEED_ITEM_TYPES: FeedItemType[] = [
  FeedItemType.Cross,
  FeedItemType.Correlation,
  FeedItemType.NewIdea,
  FeedItemType.News,
  FeedItemType.ReportRating,
  FeedItemType.RelativeStrengthIndex,
  FeedItemType.SignificantDailyMover,
  FeedItemType.NetShortDisclosure,
  FeedItemType.Macd,
  FeedItemType.NewComment,
  FeedItemType.UpsideVoice,
];

/**
 * Map typenames to accompanying help link
 */
export type FeedCardHelpLinks = Record<
  NonNullable<FeedItem['__typename']>,
  ExternalURIs[keyof ExternalURIs] | undefined
>;

export const FEED_CARD_HELP_LINKS: FeedCardHelpLinks = {
  CorrelationFeedItem: EXTERNAL_URIs.CORRELATION,
  CrossFeedItem: EXTERNAL_URIs.DEATH_CROSS,
  RelativeStrengthIndexFeedItem: EXTERNAL_URIs.RELATIVE_STRENGTH_INDEX,
  ReportRatingFeedItem: EXTERNAL_URIs.REPORT_RATINGS,
  SignificantDailyMoverFeedItem: EXTERNAL_URIs.SIGNIFICANT_MOVERS,
  NewIdeaFeedItem: EXTERNAL_URIs.IDEAS,
  MacdFeedItem: EXTERNAL_URIs.MACD,
  MostWatchlistedFeedItem: undefined,
  CurationsCategoryFeedItem: undefined,
  DiscoverCategoryFeedItem: undefined,
  NetShortDisclosureFeedItem: undefined,
  NewsFeedItem: undefined,
  SectorDiscoverCategoryFeedItem: undefined,
  NewCommentFeedItem: undefined,
  UpsideVoiceFeedItem: undefined,
};
