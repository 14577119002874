import { FC } from 'react';
import { formatPercent } from '../../util/number';
import { BadgeColor, PortfolioStatistic } from '../PortfolioStatistic';

type Props = {
  drawdown: number;
};

const descriptionText = 'The maximum peak to trough decline on your portfolio in the last year.';

export const Drawdown: FC<Props> = ({ drawdown }) => {
  const badgeProps = getBadgeProperties(Math.abs(drawdown));
  return (
    <PortfolioStatistic
      valueText={formatPercent(Math.abs(drawdown), 0, false)}
      descriptionText={descriptionText}
      {...badgeProps}
    />
  );
};

function getBadgeProperties(drawdown: number): { badgeText: 'LOW' | 'MEDIUM' | 'HIGH'; badgeColor: BadgeColor } {
  if (drawdown < 0.1) {
    return { badgeText: 'LOW', badgeColor: 'GREEN' };
  } else if (drawdown < 0.3) {
    return { badgeText: 'MEDIUM', badgeColor: 'YELLOW' };
  } else {
    return { badgeText: 'HIGH', badgeColor: 'RED' };
  }
}
