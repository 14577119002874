import { gql, MutationHookOptions } from '@apollo/client';
import { CORE_IDEA_FIELDS } from '../fragments/idea';
import { CreateIdeaMutation, CreateIdeaMutationVariables, Query, useCreateIdeaMutation } from '../generated/graphql';

export const createIdea = gql`
  ${CORE_IDEA_FIELDS}
  mutation createIdea($idea: CreateIdeaInput!) {
    createIdea(input: $idea) {
      ...CoreIdeaFields
    }
  }
`;

const ideasPaginated: keyof Query = 'ideasPaginated';
const publicIdeasPaginated: keyof Query = 'publicIdeasPaginated';
const ideasForPortfolio: keyof Query = 'ideasForPortfolio';
const publicIdea: keyof Query = 'publicIdea';

export const useCreateIdea = (args?: MutationHookOptions<CreateIdeaMutation, CreateIdeaMutationVariables>) => {
  const instrumentWatchlistItemsField: keyof Query = 'instrumentWatchlistItems';
  return useCreateIdeaMutation({
    ...args,
    update: (cache) => {
      cache.evict({ fieldName: publicIdea });
      // Evict all fields that include a list of Ideas so new Idea is included in result sets.
      cache.evict({ fieldName: ideasPaginated });
      cache.evict({ fieldName: publicIdeasPaginated });
      cache.evict({ fieldName: ideasForPortfolio });
      // Invalidate watchlist cache to ensure idea instrument shows watchlisted state
      cache.evict({ fieldName: instrumentWatchlistItemsField });
      cache.gc();
    },
  });
};
