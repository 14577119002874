import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Bolt = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M9.2 0H5.4c-.4 0-.8.3-1 .7l-2 7c-.2.7.3 1.3.9 1.3H7l-1.5 7 7.3-9.4c.5-.6 0-1.6-.8-1.6H9l1.1-3.7C10.3.6 9.8 0 9.2 0z"
      fill="#A3A3A3"
    />
  </Svg>
);
