import { orderBy } from 'lodash';
import { FC, useMemo, useRef } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { portfolioWeightingToRiskLevel } from '../../util/portfolioIntended';
import { usePortfolioIntendedPersistedStore } from '../../zustand/store';
import { DoughnutChart } from '../DoughnutChart';
import { EsgScores } from '../EsgScores';
import { InstrumentWeightingRow } from '../InstrumentWeightingRow';
import { Correlation } from './Correlation';
import { Drawdown } from './Drawdown';
import { Performance } from './Performance';
import { SharpeRatio } from './SharpeRatio';
import { Volatility } from './Volatility';
export const PortfolioStatsPreview: FC = () => {
  const tailwind = useTailwind();
  const {
    insights,
    baseLayer: { weight: base },
    stackLayer: { weight: bundles },
    stockLayer: { weight: stocks },
  } = usePortfolioIntendedPersistedStore(({ insights, baseLayer, stackLayer, stockLayer }) => ({
    insights,
    baseLayer,
    stackLayer,
    stockLayer,
  }));
  const riskLevel = portfolioWeightingToRiskLevel({ base, bundles, stocks });
  const esgRef = useRef(null);

  const top3Holdings = useMemo(() => {
    return orderBy(insights?.flattenedPortfolioComponents, 'weight', 'desc').slice(0, 3);
  }, [insights]);

  return (
    <View>
      <Title text="Performance" />
      <Performance riskLevel={riskLevel} annualisedReturn={insights?.annualisedReturn ?? 0} />

      <Title text="Your top 3 holdings" />
      {top3Holdings.map(({ instrument: { id, displayName, ticker }, weight }) => (
        <View key={id} style={tailwind('py-4 border-b border-greys-200')}>
          <InstrumentWeightingRow weight={weight} id={id} displayName={displayName} ticker={ticker} key={id} />
        </View>
      ))}

      <Title text="Sectors" />
      <DoughnutChart
        data={
          insights?.sectorWeights.map(({ sector, weight }) => ({ name: sector?.name ?? '', value: weight ?? 0 })) ?? []
        }
      />

      <Title text="ESG" />
      <EsgScores viewed={true} ref={esgRef} {...insights?.esg} />

      <Title text="Volatility" />
      <Volatility volatility={insights?.annualisedVolatility ?? 0} />

      <Title text="Drawdown" />
      <Drawdown drawdown={insights?.maximumDrawdown ?? 0} />

      <Title text="Sharpe ratio" />
      <SharpeRatio sharpeRatio={insights?.sharpeRatio ?? 0} />

      <Title text="Correlation" />
      <Correlation correlation={insights?.correlation ?? 0} />
    </View>
  );
};

const Title: FC<{ text: string }> = ({ text }) => {
  const tailwind = useTailwind();
  return <Text style={tailwind('font-medium text-default text-xl pb-2 pt-8')}>{text}</Text>;
};
