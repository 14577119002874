import React from 'react';
import { View, ViewProps } from 'react-native';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export type Props = ViewProps & {
  title: string;
  numberText: string;
  isHighlighted: boolean;
  highlightColor: string;
  position: 'left' | 'middle' | 'right';
};

/**
 * a Buy / Sell / Hold square metric in a card
 *
 * Usage:
 ```
<View style={tailwind('flex-row')}>
      <MetricSquare
        title="Sell"
        numberText={formatPercent(sellCount / total, 0)}
        isHighlighted={averageRating === ReportRating.Sell}
        highlightColor={colors.red['500']}
        position="left"
      />
      <MetricSquare
        title="Hold"
        numberText={formatPercent(holdCount / total, 0)}
        isHighlighted={averageRating === ReportRating.Hold}
        highlightColor={colors.warmGray['500']}
        position="middle"
      />
      <MetricSquare
        title="Buy"
        numberText={formatPercent(buyCount / total, 0)}
        isHighlighted={averageRating === ReportRating.Buy}
        highlightColor={colors.green['500']}
        position="right"
      />
</View>
 ```
 *
 * @param root0
 * @param root0.title
 * @param root0.numberText
 * @param root0.isHighlighted
 * @param root0.highlightColor
 * @param root0.position
 * @param root0.style
 */
export const MetricSquare: React.FC<Props> = ({
  title,
  numberText,
  isHighlighted,
  highlightColor,
  position,
  style,
  ...props
}) => {
  const tailwind = useTailwind();
  const textColor = isHighlighted ? colors.white : colors.warmGray['500'];
  return (
    <View
      style={[
        style,
        tailwind('px-2 py-2 flex-grow'),
        {
          backgroundColor: isHighlighted ? highlightColor : colors.warmGray['100'],
        },
        position === 'left' ? tailwind('rounded-l-lg mr-1') : null,
        position === 'right' ? tailwind('rounded-r-lg ml-1') : null,
      ]}
      {...props}
    >
      <View style={tailwind('flex items-center')}>
        <Text style={[tailwind('uppercase text-xs'), { color: textColor }]}>{title}</Text>
      </View>
      <View style={tailwind('flex items-center')}>
        <Text style={[tailwind('uppercase text-lg font-semibold'), { color: textColor }]}>{numberText}</Text>
      </View>
    </View>
  );
};
