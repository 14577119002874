/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Bubble } from '../models/Bubble';
import type { BubbleFile } from '../models/BubbleFile';
import type { BubbleHyperlink } from '../models/BubbleHyperlink';
import { request as __request } from '../core/request';

export class BubbleService {

    /**
     * @returns BubbleFile
     * @throws ApiError
     */
    public static async apiBubbleFileList(): Promise<Array<BubbleFile>> {
        const result = await __request({
            method: 'GET',
            path: `/api/bubble-file/`,
        });
        return result.body;
    }

    /**
     * @returns BubbleFile
     * @throws ApiError
     */
    public static async apiBubbleFileCreate({
        data,
    }: {
        data: BubbleFile,
    }): Promise<BubbleFile> {
        const result = await __request({
            method: 'POST',
            path: `/api/bubble-file/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns BubbleFile
     * @throws ApiError
     */
    public static async apiBubbleFileRead({
        uuid,
    }: {
        /** A UUID string identifying this bubble file. **/
        uuid: string,
    }): Promise<BubbleFile> {
        const result = await __request({
            method: 'GET',
            path: `/api/bubble-file/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns BubbleFile
     * @throws ApiError
     */
    public static async apiBubbleFileUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this bubble file. **/
        uuid: string,
        data: BubbleFile,
    }): Promise<BubbleFile> {
        const result = await __request({
            method: 'PUT',
            path: `/api/bubble-file/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns BubbleFile
     * @throws ApiError
     */
    public static async apiBubbleFilePartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this bubble file. **/
        uuid: string,
        data: BubbleFile,
    }): Promise<BubbleFile> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/bubble-file/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiBubbleFileDelete({
        uuid,
    }: {
        /** A UUID string identifying this bubble file. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/bubble-file/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns BubbleHyperlink
     * @throws ApiError
     */
    public static async apiBubbleHyperlinkList(): Promise<Array<BubbleHyperlink>> {
        const result = await __request({
            method: 'GET',
            path: `/api/bubble-hyperlink/`,
        });
        return result.body;
    }

    /**
     * @returns BubbleHyperlink
     * @throws ApiError
     */
    public static async apiBubbleHyperlinkCreate({
        data,
    }: {
        data: BubbleHyperlink,
    }): Promise<BubbleHyperlink> {
        const result = await __request({
            method: 'POST',
            path: `/api/bubble-hyperlink/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns BubbleHyperlink
     * @throws ApiError
     */
    public static async apiBubbleHyperlinkRead({
        uuid,
    }: {
        /** A UUID string identifying this bubble hyperlink. **/
        uuid: string,
    }): Promise<BubbleHyperlink> {
        const result = await __request({
            method: 'GET',
            path: `/api/bubble-hyperlink/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns BubbleHyperlink
     * @throws ApiError
     */
    public static async apiBubbleHyperlinkUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this bubble hyperlink. **/
        uuid: string,
        data: BubbleHyperlink,
    }): Promise<BubbleHyperlink> {
        const result = await __request({
            method: 'PUT',
            path: `/api/bubble-hyperlink/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns BubbleHyperlink
     * @throws ApiError
     */
    public static async apiBubbleHyperlinkPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this bubble hyperlink. **/
        uuid: string,
        data: BubbleHyperlink,
    }): Promise<BubbleHyperlink> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/bubble-hyperlink/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiBubbleHyperlinkDelete({
        uuid,
    }: {
        /** A UUID string identifying this bubble hyperlink. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/bubble-hyperlink/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns Bubble
     * @throws ApiError
     */
    public static async apiBubbleList(): Promise<Array<Bubble>> {
        const result = await __request({
            method: 'GET',
            path: `/api/bubble/`,
        });
        return result.body;
    }

    /**
     * @returns Bubble
     * @throws ApiError
     */
    public static async apiBubbleCreate({
        data,
    }: {
        data: Bubble,
    }): Promise<Bubble> {
        const result = await __request({
            method: 'POST',
            path: `/api/bubble/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Bubble
     * @throws ApiError
     */
    public static async apiBubbleRead({
        uuid,
    }: {
        /** A UUID string identifying this bubble. **/
        uuid: string,
    }): Promise<Bubble> {
        const result = await __request({
            method: 'GET',
            path: `/api/bubble/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns Bubble
     * @throws ApiError
     */
    public static async apiBubbleUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this bubble. **/
        uuid: string,
        data: Bubble,
    }): Promise<Bubble> {
        const result = await __request({
            method: 'PUT',
            path: `/api/bubble/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Bubble
     * @throws ApiError
     */
    public static async apiBubblePartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this bubble. **/
        uuid: string,
        data: Bubble,
    }): Promise<Bubble> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/bubble/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiBubbleDelete({
        uuid,
    }: {
        /** A UUID string identifying this bubble. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/bubble/${uuid}/`,
        });
        return result.body;
    }

}
