import { FC, forwardRef } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../Text';
export type BadgeColor = 'RED' | 'YELLOW' | 'GREEN';
type Props = {
  valueText: string;
  badgeColor: BadgeColor;
  badgeText: string;
  descriptionText: string;
};
export const PortfolioStatistic: FC<Props> = forwardRef(({ valueText, badgeColor, badgeText, descriptionText }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('pt-7')}>
      <Text style={tailwind('text-default text-4xl text-center')}>{valueText}</Text>
      <View style={tailwind('items-center pt-3 pb-8')}>
        <Badge value={badgeText} color={badgeColor} />
      </View>

      <Text style={tailwind('text-default text-sm')}>{descriptionText}</Text>
    </View>
  );
});

const Badge: FC<{ value: string; color: BadgeColor }> = ({ value, color }) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('rounded-lg bg-primary-light px-4 py-1'), getBadgeBackground(color, tailwind)]}>
      <Text style={[tailwind('font-medium text-xs'), getBadgeTextStyle(color, tailwind)]}>{value.toUpperCase()}</Text>
    </View>
  );
};

function getBadgeBackground(badge: BadgeColor, tailwind: ReturnType<typeof useTailwind>) {
  switch (badge) {
    case 'GREEN':
      return tailwind('bg-primary-light ');
    case 'YELLOW':
      return tailwind(' bg-secondary-light ');
    case 'RED':
      return tailwind('bg-tertiary-light');
  }
}

function getBadgeTextStyle(badge: BadgeColor, tailwind: ReturnType<typeof useTailwind>) {
  switch (badge) {
    case 'GREEN':
      return tailwind('text-primary-dark');
    case 'YELLOW':
      return tailwind(' text-secondary-dark ');
    case 'RED':
      return tailwind('text-tertiary-dark');
  }
}
