import React from 'react';
import { ReloadErrorBoundary } from '../old/ErrorBoundary/ReloadErrorBoundary';

/**
 * The reloadErrorBoundary re-renders the component in its initial state when a user
 * presses reload.
 *
 * @param Component Component to wrap in error boundary
 * @returns The component argument wrapped in a reload error boundary.
 */
export function withReloadErrorBoundary<Props>(Component: React.FC<Props>) {
  return (props: Props) => (
    <ReloadErrorBoundary>
      <Component {...props} />
    </ReloadErrorBoundary>
  );
}
