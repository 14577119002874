// eslint-disable-next-line no-restricted-imports
import RNSegmentedControl, { SegmentedControlProps } from '@react-native-segmented-control/segmented-control';
import React from 'react';
import { colors, useTailwind } from '../../theme';

type Props = Omit<SegmentedControlProps, 'tintColor' | 'backgroundColor' | 'appearance'>;

export const SegmentedControl: React.FC<Props> = (props) => {
  const tailwind = useTailwind();
  return (
    <RNSegmentedControl
      {...props}
      tintColor={'white'}
      backgroundColor={colors.warmGray['100']}
      appearance="light"
      fontStyle={{ ...tailwind('text-warmGray-800 text-sm font-medium'), ...props.fontStyle }}
      accessibilityRole="tab"
    />
  );
};
