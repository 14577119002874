import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { colors } from '../../theme';

export const LightBulb: React.FC<SvgProps> = (props: SvgProps) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M10 10.584L9.59963 9.6676C9.2354 9.82674 9 10.1865 9 10.584H10ZM6 10.584H7C7 10.1865 6.7646 9.82674 6.40037 9.6676L6 10.584ZM6 12H5C5 12.5523 5.44772 13 6 13V12ZM10 12V13C10.5523 13 11 12.5523 11 12H10ZM12 6C12 7.6387 11.0144 9.04944 9.59963 9.6676L10.4004 11.5003C12.5174 10.5754 14 8.46194 14 6H12ZM8 2C10.2091 2 12 3.79086 12 6H14C14 2.68629 11.3137 0 8 0V2ZM4 6C4 3.79086 5.79086 2 8 2V0C4.68629 0 2 2.68629 2 6H4ZM6.40037 9.6676C4.98556 9.04944 4 7.6387 4 6H2C2 8.46194 3.48263 10.5754 5.59963 11.5003L6.40037 9.6676ZM7 12V10.584H5V12H7ZM10 11H6V13H10V11ZM9 10.584V12H11V10.584H9Z"
        fill={props.color ?? colors.trueGray[400]}
      />
      <Path
        d="M6 15L10 15"
        stroke={props.color ?? colors.trueGray[400]}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export const LightBulbFilled = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 10.584A5.001 5.001 0 018 1a5 5 0 012 9.584V12H6v-1.416z"
      fill={props.color ?? '#0F766E'}
    />
    <Path
      d="M6 10.584l.4-.916a1 1 0 01.6.916H6zm4 0H9a1 1 0 01.6-.916l.4.916zM10 12h1a1 1 0 01-1 1v-1zm-4 0v1a1 1 0 01-1-1h1zM4 6c0 1.639.986 3.05 2.4 3.668L5.6 11.5A6.001 6.001 0 012 6h2zm4-4a4 4 0 00-4 4H2a6 6 0 016-6v2zm4 4a4 4 0 00-4-4V0a6 6 0 016 6h-2zM9.6 9.668A4.001 4.001 0 0012 6h2a6.001 6.001 0 01-3.6 5.5l-.8-1.832zM9 12v-1.416h2V12H9zm-3-1h4v2H6v-2zm1-.416V12H5v-1.416h2z"
      fill={props.color ?? '#0F766E'}
    />
    <Path
      d="M10 15H6"
      stroke={props.color ?? '#0F766E'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
