import * as scale from 'd3-scale';
import * as shape from 'd3-shape';

export const HEIGHT = 1000;
export const WIDTH = 1000;

export const getPath = (
  prices: [number, number][],
  floor: number,
  ceiling: number,
  width: number,
  height: number,
): string | undefined => {
  if (prices.length === 0) {
    return undefined;
  }
  const timeScale = scale
    .scaleTime()
    .domain([0, prices.length - 1])
    .range([0, width]);
  const priceScale = scale.scaleLinear().domain([floor, ceiling]).range([height, 0]);
  const line = shape
    .line()
    .x((_, i) => timeScale(i))
    .y(([_, p]) => priceScale(p))
    .curve(shape.curveMonotoneX)(prices);

  return line ?? undefined;
};
