import { manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';

export const requestProfilePhoto = async () => {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [1, 1],
    quality: 1,
  });

  if (result.cancelled) return;

  const resized = await manipulateAsync(result.uri, [{ resize: { height: 1000, width: 1000 } }], {
    base64: true,
    compress: 0.5,
  });
  return resized.uri;
};
