import { VFC } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { useTailwind } from '../../theme';
import { DESKTOP_MAX_WIDTH } from '../SafeAreaView';
import { WebView } from '../WebView';
import { getYoutubeUrl } from './util';

// Youtube aspect ratio
const EMBED_ASPECT_RATIO = 16 / 9;

/**
 *
 * @param props.embed Youtube embed tag content e.g `youtube: https://youtube.com/?v=DIDK2J39ZJA`
 */
export const YoutubeEmbed: VFC<{ embed: string }> = ({ embed }) => {
  const tailwind = useTailwind();
  const { width } = useWindowDimensions();
  const url = getYoutubeUrl(embed);

  if (!(url && width)) {
    return null;
  }
  // Default to window width - screen side padding
  const webViewWidth = Math.min(width ?? 0, DESKTOP_MAX_WIDTH) - 48;

  // adding renderToHardwareTextureAndroid to fix crashing on samsung only: https://github.com/react-native-webview/react-native-webview/issues/811
  return (
    <View
      renderToHardwareTextureAndroid={true}
      style={{ height: webViewWidth / EMBED_ASPECT_RATIO, width: webViewWidth, ...tailwind('my-2') }}
    >
      <WebView accessibilityLabel="Youtube" key={embed} uri={url} />
    </View>
  );
};
