import { gql, MutationHookOptions } from '@apollo/client';
import { useToast } from 'react-native-toast-notifications';
import { defaultRefetchQueries } from '../../constants/defaultRefetchQueries';
import {
  AddIdeaToWatchlistMutation,
  AddIdeaToWatchlistMutationVariables,
  Idea,
  namedOperations,
  RemoveIdeaFromWatchlistMutation,
  RemoveIdeaFromWatchlistMutationVariables,
  useAddIdeaToWatchlistMutation,
  useRemoveIdeaFromWatchlistMutation,
} from '../../generated/graphql';
import { analytics } from '../../services/analytics';

let optimisticId = 0;

/** get unique key each time in case clashes eg press star too fast so 2 in-flight adds */
const getPlaceholderId = () => `IDEA-WATCHLIST-TOGGLE-PLACEHOLDER-${optimisticId++}`;

export const addIdeaToWatchlist = gql`
  mutation addIdeaToWatchlist($ideaId: ID!) {
    addIdeaToWatchlist(input: { ideaId: $ideaId }) {
      id
      idea {
        id
        watchlistId
      }
    }
  }
`;

export const removeIdeaFromWatchlist = gql`
  mutation removeIdeaFromWatchlist($watchlistId: ID!) {
    removeIdeaFromWatchlist(input: { id: $watchlistId }) {
      id
      idea {
        id
        watchlistId
      }
    }
  }
`;

export const useIdeaWatchlistToggle = (
  {
    ideaId,
    watchlistId,
    user,
    instrumentId,
    instrumentTicker,
  }: { ideaId: string; instrumentId: string; instrumentTicker: string } & Pick<Idea, 'watchlistId' | 'user'>,
  postUpdateOptions: { showToasterOnMutation?: boolean } = {},
) => {
  const toast = useToast();
  const { showToasterOnMutation } = postUpdateOptions;

  const [addToWatchlist, { loading: addLoading }] = useAddIdeaToWatchlist({
    refetchQueries: defaultRefetchQueries,
    onCompleted: () => {
      if (showToasterOnMutation) {
        toast.hideAll();
        toast.show(`Tracking ${user?.nickname}'s Idea on ${instrumentTicker}`);
      }
      analytics.track('Idea watchlist changed', {
        action: 'add',
        'Idea id': ideaId,
        'Author id': `${user?.id}`,
        'Author name': user?.nickname ?? '',
        'Instrument ticker': instrumentTicker,
        'Instrument id': instrumentId,
      });
    },
    onError: (error) => {
      console.error(`Error adding idea to watchlist`, { error, instrumentTicker });
      toast.show('Something went wrong. Try again');
    },
    variables: { ideaId: ideaId },
  });

  const [removeFromWatchlist, { loading: removeLoading }] = useRemoveIdeaFromWatchlist(ideaId, {
    refetchQueries: defaultRefetchQueries,
    onCompleted: () => {
      if (showToasterOnMutation) {
        toast.hideAll();
        toast.show(`Stopped tracking ${user?.nickname}'s Idea on ${instrumentTicker}`);
      }
      analytics.track('Idea watchlist changed', {
        action: 'remove',
        'Idea id': ideaId,
        'Author id': `${user?.id}`,
        'Author name': user?.nickname ?? '',
        'Instrument ticker': instrumentTicker,
        'Instrument id': instrumentId,
      });
    },
    onError: (error) => {
      console.error(`Error removing idea from watchlist`, { ideaId, error, instrumentTicker });
      toast.show('Something went wrong. Try again');
    },
    variables: { watchlistId: watchlistId ?? '' },
  });

  const loading = addLoading || removeLoading;

  const toggleIdeaWatchlist = () => {
    // Prevent spam
    if (loading) return;

    if (watchlistId) {
      removeFromWatchlist();
    } else {
      addToWatchlist();
    }
  };

  return { toggleIdeaWatchlist, loading };
};

/** add idea to watchlist with optimistic response */
const useAddIdeaToWatchlist = (
  args?: MutationHookOptions<AddIdeaToWatchlistMutation, AddIdeaToWatchlistMutationVariables>,
) => {
  const uuid = getPlaceholderId();
  return useAddIdeaToWatchlistMutation({
    ...args,
    refetchQueries: [...defaultRefetchQueries, namedOperations.Query.getTrackedIdeas],
    optimisticResponse: {
      addIdeaToWatchlist: {
        id: uuid,
        __typename: 'IdeaWatchlistItem',
        idea: {
          __typename: 'Idea',
          id: args?.variables?.ideaId ?? '',
          watchlistId: uuid,
        },
      },
    },
  });
};

/** remove idea from watchlist with optimistic response */
const useRemoveIdeaFromWatchlist = (
  ideaId: string,
  args?: MutationHookOptions<RemoveIdeaFromWatchlistMutation, RemoveIdeaFromWatchlistMutationVariables>,
) => {
  return useRemoveIdeaFromWatchlistMutation({
    ...args,
    refetchQueries: [...defaultRefetchQueries, namedOperations.Query.getTrackedIdeas],
    optimisticResponse: {
      removeIdeaFromWatchlist: {
        id: args?.variables?.watchlistId ?? '',
        __typename: 'IdeaWatchlistItem',
        idea: {
          __typename: 'Idea',
          id: ideaId,
          watchlistId: null,
        },
      },
    },
  });
};
