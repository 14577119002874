import React from 'react';
import { useTailwind } from '../../theme';
import { Props } from './WebView';

export const WebView: React.FC<Props> = ({ uri }) => {
  const tailwind = useTailwind();
  return (
    <iframe
      title="WebView"
      style={tailwind('flex-1 border-none')}
      // Expo Linking openURL double-encodes query params, hence an extra decoding is needed here.
      src={decodeURIComponent(uri)}
      sandbox="allow-scripts allow-same-origin"
    />
  );
};
