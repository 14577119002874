import React from 'react';
import { Linking } from 'react-native';
import { colors } from '../../theme';
import { SimpleButton } from '../Button';
import { Bug } from '../icons';
import { ErrorScreen } from './ErrorScreen';

/**
 * Root Error Boundary Fallback in AppProvider.
 * High up so above navigator.
 * Hopefully rarely see this as should be use `withReloadErrorBoundary` in all our screens
 */
export const DefaultFallback: React.VFC = () => {
  return (
    <ErrorScreen
      title="Something went wrong"
      actionButton={
        <SimpleButton
          variant="secondary"
          StartIcon={() => <Bug color={colors.black} />}
          onPress={() => Linking.openURL(encodeURI('mailto: help@upsidetechnology.co?subject=Crash Report'))}
          text="Report a Bug"
        />
      }
    />
  );
};
