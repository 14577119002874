import { isNil } from 'lodash';
import { View } from 'react-native';
import { Idea, IdeaStatus, Position } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatDate, parseUTCDateFromString } from '../../util/date';
import { ProgressBar } from '../ProgressBar';

type Props = Pick<Idea, 'referenceDate' | 'targetDate' | 'position' | 'status' | 'closeTime'> & { today: Date };

export const Timeline: React.VFC<Props> = ({ referenceDate, targetDate, position, today, status, closeTime }) => {
  const tailwind = useTailwind();
  if (isNil(referenceDate) || isNil(targetDate) || isNil(position) || isNil(status)) {
    console.error('Missing position, reference, target date or status');
    return null;
  }
  const targetTime = parseUTCDateFromString(targetDate).getTime();
  const referenceTime = parseUTCDateFromString(referenceDate).getTime();
  const todayTime = today.getTime();
  const proportion = (todayTime - referenceTime) / (targetTime - referenceTime);
  const color = progressBarColor(position, status);
  return (
    <>
      <View style={tailwind('flex-row justify-center items-center py-1')}>
        <Text style={tailwind('text-sm text-warmGray-500 px-4')}>{formatDate(referenceDate, 'DD MMM YY')}</Text>
        <ProgressBar progress={proportion * 100} color={color} style={tailwind('flex-auto h-2')} />
        <Text style={tailwind('text-sm text-warmGray-500 px-4')}>{formatDate(targetDate, 'DD MMM YY')}</Text>
      </View>
      {closeTime && (
        <Text style={tailwind('text-warmGray-500 font-normal pb-2 text-sm text-center')}>
          Closed on {formatDate(closeTime, 'DD MMM YY')}
        </Text>
      )}
    </>
  );
};

const progressBarColor = (position: Position, status: IdeaStatus): string => {
  if (status === IdeaStatus.Closed) {
    return 'warmGray-500';
  }
  return position === Position.Long ? colors.blue['500'] : colors.fuchsia['500'];
};
