import { gql } from '@apollo/client';
import { BottomTabNavigationProp, BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, CompositeScreenProps, useNavigation, useScrollToTop } from '@react-navigation/native';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import { orderBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, VFC } from 'react';
import { RefreshControl, ScrollView as TScrollView, View } from 'react-native';
import { layout } from '../../constants/layout';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import {
  GetHomeScreenDataQuery,
  useGetHomeScreenDataQuery,
  useGetOnboardingSummaryQuery,
} from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { HomeStackParamList } from '../../navigation/HomeNavigator';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { MarketDataRow } from '../../old/MarketDataRow/MarketDataRow';
import { SideScrollHeader } from '../../old/SideScrollHeader';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { TitleBarMainIcons } from '../../old/TitleBarMainIcons';
import { onesignal } from '../../services/onesignal';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, usePortfolioIntendedPersistedStore } from '../../zustand/store';
import {
  getTradingOnboardingCard,
  IdeaOnboardingCard,
  PortfolioIntendedOnboardingCard,
  PortfolioOnboardingCard,
  WatchlistOnboardingCard,
} from './ OnboardingCards';
import { AppContentSideScroll } from './AppContentSideScroll';
import { APP_CONTENT_IMAGE_HEIGHT, APP_CONTENT_IMAGE_WIDTH } from './AppContentSideScroll/AppContentCard';
import { BLOG_IMAGE_WIDTH } from './BlogSideScroll/BlogCard';
import { BlogSideScroll } from './BlogSideScroll/BlogSideScroll';
import { IdeaSideScroll } from './IdeaSideScroll';
import { getTradingOnboardingState } from './util';
import { WatchlistSideScroll } from './WatchlistSideScroll';

// Make onboarding summary a different query so can refetch with minimal network overhead (aka separately from all home screen data).
export const GET_ONBOARDING_SUMMARY = gql`
  query getOnboardingSummary {
    me {
      user {
        tradingOnboardingFinished
      }
    }
    myTradingParty {
      party {
        id
        addresses {
          id
        }
        bankAccounts {
          id
        }
      }
    }
    myPortfolios {
      portfolios {
        id
        status
      }
    }
    journeySummary {
      summary {
        instrumentWatchlist {
          hasWatchlisted
          watchlistAdditionsBeforeComplete
        }
        idea {
          hasCreatedIdea
          ideaCreationsBeforeComplete
        }
        blueprint {
          hasStartedBlueprint
          hasFinishedBlueprint
          daysStale
        }
      }
    }
  }
`;

export const GET_HOMESCREEN_DATA = gql`
  ${CORE_IDEA_FIELDS}
  query getHomeScreenData($blogImgixWidth: Int, $appContentImgixWidth: Int, $appContentImgixHeight: Int) {
    myMarketData {
      instruments {
        id
        ticker
        displayName
        name
        quotePrice {
          id
          midPrice
          previousClose
          pctChange1d
        }
      }
    }
    instrumentWatchlistItems {
      id
      instrument {
        id
        ticker
        logoUrl
        quotePrice {
          id
          pctChange1d
        }
      }
    }
    ideasPaginated(filter: { statuses: [ACTIVE] }) {
      nodes {
        ...CoreIdeaFields
      }
    }
    datoAppContents(
      orderBy: [PUBLISHED_DATE_DESC]
      imgixParams: { width: $appContentImgixWidth, height: $appContentImgixHeight }
      pagination: { first: 6 }
    ) {
      connection {
        nodes {
          id
          title
          excerpt
          coverImage {
            url
          }
        }
      }
    }
    datoBlogs(
      orderBy: [PUBLISHED_DATE_DESC]
      pagination: { first: 20 }
      imgixParams: { width: $blogImgixWidth, height: $blogImgixWidth }
      filter: { destination: { in: [APP, ALL] } }
    ) {
      connection {
        nodes {
          id
          title
          slug
          excerpt
          publishedDate
          coverImage {
            url
          }
        }
      }
    }
  }
`;

export type Props = CompositeScreenProps<
  NativeStackScreenProps<HomeStackParamList>,
  CompositeScreenProps<BottomTabScreenProps<TabsParamList, 'HomeTab'>, NativeStackScreenProps<LoggedInStackParamList>>
>;

export type HomeNavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<HomeStackParamList>,
  CompositeNavigationProp<
    BottomTabNavigationProp<TabsParamList, 'HomeTab'>,
    NativeStackNavigationProp<LoggedInStackParamList>
  >
>;

export const Home: VFC<Props> = withReloadErrorBoundary(() => {
  const tailwind = useTailwind();
  const isStaff = usePersistedStore(({ isStaff }) => isStaff);
  const { hasPortfolioIntended } = usePortfolioIntendedPersistedStore(({ hasPortfolioIntended }) => ({
    hasPortfolioIntended,
  }));
  // scroll to top if press tab
  const ref = React.useRef<TScrollView | null>(null);
  useScrollToTop(ref);
  // Use this width because it accounts for desktop max width
  const width = layout.window.width;

  // ask for push permissions on first render now signed up and showing Home screen
  useEffect(() => {
    onesignal.pushPrompt();
  }, []);

  const {
    data: onboardingSummaryData,
    loading: onboardingLoading,
    error: onboardingError,
    refetch: refetchOnboarding,
  } = useGetOnboardingSummaryQuery();
  const {
    data,
    loading: homeScreenDataLoading,
    error: homeScreenDataError,
    refetch: refetchHomeScreen,
  } = useGetHomeScreenDataQuery({
    variables: {
      blogImgixWidth: Math.ceil(BLOG_IMAGE_WIDTH * layout.pixelRatio),
      appContentImgixWidth: Math.ceil(APP_CONTENT_IMAGE_WIDTH * layout.pixelRatio),
      appContentImgixHeight: Math.ceil(APP_CONTENT_IMAGE_HEIGHT * layout.pixelRatio),
    },
  });

  const refetch = useCallback(async () => {
    await Promise.all([refetchHomeScreen(), refetchOnboarding()]);
  }, [refetchHomeScreen, refetchOnboarding]);

  const { onRefresh, refreshing } = useRefresh(refetch);

  const error = homeScreenDataError || onboardingError;
  const loading = homeScreenDataLoading || onboardingLoading;

  if (error) {
    throw error;
  }

  const { watchlistGainers, watchlistLosers } = useMemo(() => {
    const gainers =
      data?.instrumentWatchlistItems.filter((i) => (i.instrument?.quotePrice?.pctChange1d ?? 0) > 0) ?? [];
    const losers = data?.instrumentWatchlistItems.filter((i) => (i.instrument?.quotePrice?.pctChange1d ?? 0) < 0) ?? [];

    return {
      watchlistGainers: orderBy(gainers, (w) => w.instrument?.quotePrice?.pctChange1d, 'desc'),
      watchlistLosers: orderBy(losers, (w) => w.instrument?.quotePrice?.pctChange1d, 'asc'),
    };
  }, [data]);

  const { ideasOnTrack, ideasOffTrack } = useMemo(() => {
    const onTrack = data?.ideasPaginated.nodes.filter((i) => (i.performance?.actualReturn ?? 0) >= 0) ?? [];
    const offTrack = data?.ideasPaginated.nodes.filter((i) => (i.performance?.actualReturn ?? 0) < 0) ?? [];

    return {
      ideasOnTrack: orderBy(onTrack, (i) => i.performance?.actualReturn, 'desc'),
      ideasOffTrack: orderBy(offTrack, (i) => i.performance?.actualReturn, 'asc'),
    };
  }, [data]);

  const marketDataCardPadding = 8;
  const marketDataCardWidth = (width - 48) / 2 - marketDataCardPadding;
  const onboardingCardWidth = width - 48;

  const journeySummary = onboardingSummaryData?.journeySummary?.summary;
  const showWatchlistSection = Boolean(journeySummary?.instrumentWatchlist?.hasWatchlisted);
  const showIdeaSection = Boolean(onboardingSummaryData?.journeySummary?.summary?.idea?.hasCreatedIdea);

  const tradingOnboardingState = useMemo(() => {
    return getTradingOnboardingState(
      onboardingSummaryData?.myTradingParty.party,
      onboardingSummaryData?.myPortfolios?.portfolios ?? [],
    );
  }, [onboardingSummaryData]);

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar title="Home" showLogo hideBackButton endAdornment={<TitleBarMainIcons />} />
      {loading ? (
        <HomeSkeleton />
      ) : (
        <ScrollView
          contentContainerStyle={tailwind('pt-2')}
          ref={ref}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        >
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            style={tailwind('pb-3')}
            contentContainerStyle={tailwind('px-6 py-2')}
            accessibilityLabel="Market Summary"
            snapToInterval={marketDataCardWidth * 2}
            snapToAlignment="start"
            decelerationRate="fast"
          >
            {data?.myMarketData.instruments.map((instrument) => (
              <View style={{ width: marketDataCardWidth, paddingRight: marketDataCardPadding }} key={instrument.id}>
                <MarketDataRow {...instrument} />
              </View>
            ))}
          </ScrollView>
          {isStaff && tradingOnboardingState !== 'COMPLETE' && (
            <ScrollView
              snapToInterval={onboardingCardWidth + 8}
              snapToAlignment="start"
              decelerationRate="fast"
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={tailwind('px-6')}
              style={tailwind('py-3')}
              horizontal
            >
              {!hasPortfolioIntended() && (
                <View style={tailwind('mr-2')}>
                  <PortfolioIntendedOnboardingCard width={onboardingCardWidth} />
                </View>
              )}
              <View style={tailwind('mr-2')}>
                {getTradingOnboardingCard(tradingOnboardingState, onboardingCardWidth)}
              </View>
            </ScrollView>
          )}
          {(!journeySummary?.instrumentWatchlist?.hasWatchlisted ||
            !journeySummary?.idea?.hasCreatedIdea ||
            !journeySummary?.blueprint?.hasFinishedBlueprint) && (
            <ScrollView
              snapToInterval={onboardingCardWidth + 8}
              snapToAlignment="start"
              decelerationRate="fast"
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={tailwind('px-6')}
              style={tailwind('py-3')}
              horizontal
            >
              {!journeySummary?.instrumentWatchlist?.hasWatchlisted && (
                <View style={tailwind('mr-2')}>
                  <WatchlistOnboardingCard width={onboardingCardWidth} />
                </View>
              )}
              {!journeySummary?.idea?.hasCreatedIdea && (
                <View style={tailwind('mr-2')}>
                  <IdeaOnboardingCard width={onboardingCardWidth} />
                </View>
              )}
              {!journeySummary?.blueprint?.hasFinishedBlueprint && (
                <View style={tailwind('mr-2')}>
                  <PortfolioOnboardingCard width={onboardingCardWidth} />
                </View>
              )}
            </ScrollView>
          )}
          {/** Present Ideas before large blog cards when Ideas available, else present Watchlist before large blog cards. */}
          {showIdeaSection && showWatchlistSection ? (
            <>
              <IdeaSection onTrackIdeas={ideasOnTrack} offTrackIdeas={ideasOffTrack} />
              <AppContentSideScroll data={data?.datoAppContents?.connection?.nodes ?? []} />
              <WatchlistSection watchlistGainers={watchlistGainers} watchlistLosers={watchlistLosers} />
            </>
          ) : showWatchlistSection && !showIdeaSection ? (
            <>
              <WatchlistSection watchlistGainers={watchlistGainers} watchlistLosers={watchlistLosers} />
              <AppContentSideScroll data={data?.datoAppContents?.connection?.nodes ?? []} />
            </>
          ) : (
            <AppContentSideScroll data={data?.datoAppContents?.connection?.nodes ?? []} />
          )}
          <View>
            <Text style={tailwind('text-lg font-semibold px-6 pb-2')}>Articles you may enjoy</Text>
            <BlogSideScroll data={data?.datoBlogs.connection?.nodes ?? []} />
          </View>
        </ScrollView>
      )}
    </SafeAreaView>
  );
});

const WatchlistSection: VFC<{
  watchlistGainers: GetHomeScreenDataQuery['instrumentWatchlistItems'];
  watchlistLosers: GetHomeScreenDataQuery['instrumentWatchlistItems'];
}> = ({ watchlistGainers, watchlistLosers }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <View accessibilityLabel="My Watchlist" style={tailwind('pb-4')}>
      <SideScrollHeader
        text="My Watchlist"
        onPress={() => navigation.navigate('WatchlistTab', { screen: 'Watchlist' })}
      />
      <WatchlistSideScroll title="Gainers" data={watchlistGainers} emptyText="No watchlist gainers today" />
      <View style={tailwind('pt-3')} />
      <WatchlistSideScroll title="Losers" data={watchlistLosers} emptyText="No watchlist losers today" />
    </View>
  );
};

const IdeaSection: VFC<{
  onTrackIdeas: GetHomeScreenDataQuery['ideasPaginated']['nodes'];
  offTrackIdeas: GetHomeScreenDataQuery['ideasPaginated']['nodes'];
}> = ({ onTrackIdeas, offTrackIdeas }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <View accessibilityLabel="My Ideas">
      <SideScrollHeader text="My Ideas" onPress={() => navigation.navigate('IdeasTab', { screen: 'Ideas' })} />
      <IdeaSideScroll title="On track" data={onTrackIdeas} emptyText="No on track Ideas" />
      <View style={tailwind('pt-3')} />
      <IdeaSideScroll title="Off track" data={offTrackIdeas} emptyText="No off track Ideas" />
    </View>
  );
};

const HomeSkeleton: VFC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView>
      <ScreenSidePadding>
        <Skeleton style={tailwind('my-6 w-48 h-10')} />
        <View style={tailwind('flex-row flex-wrap')}>
          {new Array(6).fill(null).map((_, i) => (
            <View key={i} style={{ flexBasis: '50%', ...tailwind('py-2 px-1 rounded-md') }}>
              <Skeleton style={tailwind('flex-grow')} />
            </View>
          ))}
        </View>
        <Skeleton style={tailwind('mt-6 h-48')} />

        <View style={tailwind('mt-6')}>
          <Skeleton style={tailwind('w-52 h-8')} />
          <View style={tailwind('flex-row mt-3')}>
            {new Array(5).fill(null).map((_, i) => (
              <Skeleton key={i} style={tailwind('h-24 w-48 mr-4 rounded-md')} />
            ))}
          </View>
        </View>

        <View style={tailwind('mt-6')}>
          <Skeleton style={tailwind('w-52 h-8')} />
          <View style={tailwind('flex-row mt-3')}>
            {new Array(5).fill(null).map((_, i) => (
              <Skeleton key={i} style={tailwind('h-24 w-48 mr-4 rounded-md')} />
            ))}
          </View>
        </View>
      </ScreenSidePadding>
    </SkeletonView>
  );
};
