import * as React from 'react';
import Svg, { Circle, ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

export const ProfileRating: React.FC<SvgProps & { isGold?: boolean }> = ({ isGold, ...props }) => {
  return (
    <Svg viewBox="0 0 14 14" fill="none" {...props}>
      <Circle cx={7} cy={7} r={6.5} fill={isGold ? '#DAB904' : '#22C55E'} />
      <G clipPath="url(#prefix__clip0_408_914)">
        <Path
          d="M9.363 4.862L5.875 8.35 4.638 7.113a.363.363 0 00-.526 0 .363.363 0 000 .524l1.5 1.5a.34.34 0 00.263.113.34.34 0 00.263-.113l3.75-3.75a.362.362 0 000-.524.362.362 0 00-.525 0z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="prefix__clip0_408_914">
          <Path fill="#fff" transform="translate(4 4)" d="M0 0h6v6H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
