import { isNil } from 'lodash';
import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Text as RNText } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { Link } from '../Link';
import { Props } from './props';

/**
 * The text font is `text-sm` which sets the line-height to 20
 */
const LINE_HEIGHT = 20;

export const ExpandableText: React.VFC<Props> = ({ children, lineLimit }) => {
  const tailwind = useTailwind();
  /**
   * It is necessary to initially set the expanded state to true so that the <Text/> component
   * can measure the height of the expanded state.
   */
  const [expanded, setExpanded] = React.useState(true);
  const heightLimit = LINE_HEIGHT * lineLimit;

  const [heightTotal, setHeightTotal] = React.useState<number | undefined>(undefined);
  const trimmable = !isNil(heightTotal) ? heightTotal > heightLimit : true;
  const ref = React.useRef<RNText>(null);

  /**
   * After initial render to calculate the expanded height, immediately collapse the text.
   */
  React.useEffect(() => {
    ref.current?.measure((_x, _y, _w, h) => {
      setHeightTotal(h);
      setExpanded(false);
    });
  }, []);

  return (
    <>
      <Text ref={ref} style={tailwind('text-sm text-warmGray-700')} numberOfLines={expanded ? undefined : lineLimit}>
        {children}
      </Text>
      {trimmable && (
        <Link style={tailwind('pt-1')} onPress={() => setExpanded(!expanded)}>
          {expanded ? 'Read less' : 'Read more'}
        </Link>
      )}
    </>
  );
};
