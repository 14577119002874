import { gql } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { Platform } from 'react-native';
import { RefreshJwtAccessTokenMutation, RefreshJwtAccessTokenMutationVariables } from '../generated/graphql';
import { getGraphqlClient } from './graphql';
import { logOutCleanUp } from './logIn';
import { getSecureItem, setSecureItem } from './storage';

export const REFRESH_JWT_ACCESS_TOKEN = gql`
  mutation refreshJwtAccessToken($refreshToken: NonEmptyString) {
    refresh(refreshToken: $refreshToken) {
      accessToken
      error {
        ... on AuthenticationFailed {
          message
        }
        ... on TokenNotProvided {
          message
        }
      }
    }
  }
`;

/**
 * contents of decoded jwt token
 */
export interface AccessToken {
  /** expiry time in epoch seconds */
  exp: number;
}

/**
 * Store JWT access token string in-memory only as more secure (local storage prone to XSS on web).
 */
let accessToken = '';

/**
 * for testing purposes only (jest/storybook)
 *
 * Use this to set the in-memory JWT access token to an expiry date 2 hours in the future by default.
 * Or null to set back to empty when logged out.
 *
 * Saves MSW kicking in and refreshing the access token at the start of each test then
 */
export const setAccessTokenForTesting = (expiry: Date | null) => {
  if (!expiry) {
    // for logged out case, pass in null to set empty access token
    accessToken = '';
  } else {
    accessToken = generateMockJwtAccessToken(expiry);
  }
};

/** for testing purposes only, use this to generate valid encoded access token */
export const generateMockJwtAccessToken = (expiry: Date) => {
  const accessToken: AccessToken = {
    exp: expiry.getTime() / 1000, // epoch in seconds
  };
  return `.${btoa(JSON.stringify(accessToken))}.`;
};

/**
 * Queue up promise resolvers waiting for their access token whilst we are refreshing the access token. Will resolve with new token
 */
let resolversWaitingAccessToken: Array<(value: string | PromiseLike<string>) => void> = [];

/**
 * Store access token in localstorage (less secure) on web when in NODE_ENV development (aka localhost)
 *
 * When on localhost need it in local storage to persist access token between hot reload refreshes, so can't use in-memory
 */
const useLocalStorageForAccessToken = Platform.OS === 'web' && process.env.NODE_ENV === 'development';

if (useLocalStorageForAccessToken) {
  console.log(`Using localstorage for JWT access token as NODE_ENV development and on web`);
}

/**
 * Get in-memory JWT access token
 *
 * Stored in local storage only in dev/test/cypress (less secure as local storage prone to XSS)
 *
 * If multiple calls happen at once that need new access token will form queue for promises to resolve once retrieved new access token
 *
 * @returns JWT encoded string
 */
export const getAccessToken = async (): Promise<string> => {
  if (useLocalStorageForAccessToken) {
    // on localhost web or in jest test only, grab access token from local storage
    accessToken = (await getSecureItem('accessToken')) ?? '';
  }

  if (!hasAccessTokenExpired(accessToken)) {
    return accessToken;
  }

  // kick off API call to get new access token if this is the first call to refresh
  // and add to queue of promises which resolves with new token when refresh is done
  if (resolversWaitingAccessToken.length === 0) {
    console.log(`JWT access token expired, kicking off refresh access token and queueing this request`);
    const promise = new Promise<string>((resolve) => {
      resolversWaitingAccessToken.push(resolve);
    });
    refreshAccessToken().then((newToken) => {
      console.log(`JWT: now resolving ${resolversWaitingAccessToken.length} waiting promises.`);
      // resolve all promises awaiting this new token
      resolversWaitingAccessToken.forEach((resolve) => resolve(newToken ?? ''));
      resolversWaitingAccessToken = [];
    });
    return promise;
  }

  // refresh in progress, just add to queue of promises which resolves with new token when refresh is done
  const promise = new Promise<string>((resolve) => {
    resolversWaitingAccessToken.push(resolve);
  });
  console.log(`JWT refresh access token in progress, queue now length ${resolversWaitingAccessToken.length}`);
  return promise;
};

/**
 * Update in-memory JWT access token.
 *
 * Stored in local storage only in dev/test/cypress (less secure as local storage prone to XSS)
 *
 * @param token JWT encoded string
 */
export const setAccessToken = async (token: string) => {
  if (useLocalStorageForAccessToken) {
    setSecureItem('accessToken', token);
  }
  accessToken = token;
};

/**
 * @returns true if JWT access token has expired. If it has expired best call refresh API to get new token
 */
const hasAccessTokenExpired = (accessToken: string): boolean => {
  if (!accessToken) return true;

  const decodedToken = jwtDecode<AccessToken>(accessToken);
  if (!decodedToken.exp) {
    return false;
  }
  // valid if more that 10 secs til expiry
  const currentTimestampInSeconds = new Date().getTime() / 1000;
  const secondsToExpiry = decodedToken.exp - currentTimestampInSeconds;
  return secondsToExpiry < 10;
};

/**
 * store refresh token in secure store for android/ios only
 *
 * web should be using a HTTP-only secure cookie instead and this will error if platform is web
 */
export const setRefreshToken = async (refreshToken: string) => {
  if (Platform.OS === 'web') {
    throw new Error(`should not set refresh token on web, please use http-only secure cookie instead`);
  }
  setSecureItem('refreshToken', refreshToken);
};

/**
 * Calls JWT refresh endpoint to get new access token and stores it in memory
 *
 * Resolves all things waiting for the new access token when successful
 *
 * @returns new access token
 */
const refreshAccessToken = async (): Promise<string | null> => {
  const graphqlClient = getGraphqlClient();
  try {
    console.log(`Calling JWT refresh access token`);

    // ios/android grab refresh token from secure store
    // for web refresh token already in HTTP only secure cookie so is sent by browser to server
    const refreshToken = Platform.OS !== 'web' ? await getSecureItem('refreshToken') : undefined;

    const { errors, data } = await graphqlClient.mutate<
      RefreshJwtAccessTokenMutation,
      RefreshJwtAccessTokenMutationVariables
    >({
      mutation: REFRESH_JWT_ACCESS_TOKEN,
      variables: {
        refreshToken,
      },
    });

    if (errors) {
      console.error(`Errors returned from refresh mutation => logging user out`, errors);
      await logOutCleanUp();
      return null;
    }
    if (data?.refresh.error?.message) {
      console.error(`Error from refresh mutation => logging user out: ${data?.refresh.error.message}`);
      await logOutCleanUp();
      return null;
    }
    if (!data?.refresh.accessToken) {
      console.error('Error: accessToken returned was empty => logging user out');
      await logOutCleanUp();
      return null;
    }

    await setAccessToken(data?.refresh.accessToken);
    console.log(`JWT refresh of access token successful`);

    return data?.refresh.accessToken;
  } catch (e) {
    console.error(`JWT Refresh Error caught => logging user out`, e);
    await logOutCleanUp();
    return null;
  }
};
