import { sortBy } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import Svg, { G, Line, Path } from 'react-native-svg';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { getPath, getPriceBoundaries, Price } from '../../util/chart';
import { formatDate, parseUTCDateFromString } from '../../util/date';
import { formatPrice } from '../../util/number';

export type Props = {
  currency: string;
  target: Price;
  reference: Price;
  prices: Price[];
  width: number;
};

const HEIGHT = 256;

export const InPlayChart: React.VFC<Props> = ({ prices, target, reference, currency, width }) => {
  const tailwind = useTailwind();
  const dateStyles = tailwind('text-xs text-center text-warmGray-500 px-0.5');
  const [yLabelsWidth, setYLabelsWidth] = React.useState(0);
  const [xLabelWidth, setXLabelWidth] = React.useState(0);

  const onLabelLayout = React.useCallback((width: number, comparison: number, setWidth: (width: number) => void) => {
    if (width > comparison) {
      setWidth(width);
    }
  }, []);

  const displayedPrices = replaceClosePriceWithReferencePrice(
    sortBy(prices, (x) => x.date),
    reference,
  );
  const boundaries = getPriceBoundaries(
    [...displayedPrices, target].map((p): [number, number] => [parseUTCDateFromString(p.date).getTime(), p.price]),
  );

  /**
   * These parameters set the boundaries of some chart components
   *
   * @constant x1 The start of the x-axis (time)
   * @constant x2 End of the x-axis (time)
   * @constant y1 Highest point of the chart paths (price and projection paths)
   * @constant y2 Lowest point of the chart paths (price and projection paths)
   */
  const { x1, x2, y1, y2 } = { x1: xLabelWidth / 2, x2: width - yLabelsWidth, y1: HEIGHT * 0.1, y2: 0.9 * HEIGHT };
  const pathProportion =
    (parseUTCDateFromString(displayedPrices[displayedPrices.length - 1].date).getTime() -
      parseUTCDateFromString(displayedPrices[0].date).getTime()) /
    (boundaries.latest - boundaries.earliest);

  const pathWidth = (x2 - x1) * pathProportion;
  const referenceIndex = displayedPrices.findIndex((p) => p.date === reference.date);
  const prePathWidth = (referenceIndex / (displayedPrices.length - 1)) * pathWidth;
  const inPlayPathWidth = pathWidth - prePathWidth;

  const prePath = getPath(
    { prices: displayedPrices.slice(0, referenceIndex + 1), min: boundaries.lowest, max: boundaries.highest },
    prePathWidth,
    0.8 * HEIGHT,
  );
  const inPlayPath = getPath(
    { prices: displayedPrices.slice(referenceIndex), min: boundaries.lowest, max: boundaries.highest },
    inPlayPathWidth,
    0.8 * HEIGHT,
  );

  const progressLineColor = getProgressLine(displayedPrices, target, reference);
  const trajectoryColor = target.price - reference.price >= 0 ? colors.blue['500'] : colors.fuchsia['600'];

  const projectedX1 = x1 + prePathWidth;
  const projectedX2 = displayedPrices.find((p) => p.date === target.date)
    ? x1 + (displayedPrices.findIndex((p) => p.date === target.date) / (displayedPrices.length - 1)) * (pathWidth - 1)
    : x2;
  const projectedY1 =
    y1 + (y2 - y1) * (1 - (reference.price - boundaries.lowest) / (boundaries.highest - boundaries.lowest));
  const projectedY2 =
    y1 + (y2 - y1) * (1 - (target.price - boundaries.lowest) / (boundaries.highest - boundaries.lowest));

  const dateLabelOffsets = getDateLabelOffsets(x1, projectedX1, projectedX2, xLabelWidth);

  return (
    <View>
      {width > 0 && (
        <Svg width={x2} height={HEIGHT} viewBox={`0 0 ${x2} ${HEIGHT}`} preserveAspectRatio="none">
          <Line
            x1={x1}
            x2={x2}
            y1={HEIGHT}
            y2={HEIGHT}
            stroke={colors.warmGray['300']}
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
          />
          <Line
            x1={x2}
            x2={x2}
            y1={0}
            y2={HEIGHT}
            stroke={colors.warmGray['300']}
            strokeWidth={2}
            vectorEffect="non-scaling-stroke"
          />
          <G x={x1} y={y1} strokeWidth={2} vectorEffect="non-scaling-stroke">
            <Path d={prePath} stroke={colors.warmGray['500']} fill="transparent" />
            <G x={prePathWidth}>
              <Path d={inPlayPath} stroke={progressLineColor} fill="transparent" />
            </G>
          </G>
          <G vectorEffect="non-scaling-stroke" strokeWidth={1.5} strokeDasharray="4 4">
            <Line
              opacity={0.6}
              stroke={trajectoryColor}
              x1={projectedX1}
              x2={projectedX2}
              y1={projectedY1}
              y2={projectedY2}
            />
            <Line
              opacity={0.5}
              stroke={colors.warmGray['600']}
              x1={projectedX1}
              x2={projectedX2}
              y1={projectedY1}
              y2={projectedY1}
            />
          </G>
        </Svg>
      )}
      <View style={{ position: 'absolute', left: x2 }}>
        <View
          style={{ top: projectedY2 - 8, position: 'absolute', paddingLeft: 4 }}
          onLayout={(e) => onLabelLayout(e.nativeEvent.layout.width, yLabelsWidth, setYLabelsWidth)}
        >
          <Text style={[tailwind('text-xs text-center'), { color: trajectoryColor }]}>
            {formatPrice(target.price, currency)}
          </Text>
        </View>
        <View
          style={{ top: projectedY1 - 8, position: 'absolute', paddingLeft: 4 }}
          onLayout={(e) => onLabelLayout(e.nativeEvent.layout.width, yLabelsWidth, setYLabelsWidth)}
        >
          <Text style={tailwind('text-xs text-center text-warmGray-500')}>
            {formatPrice(reference.price, currency)}
          </Text>
        </View>
      </View>
      <View style={tailwind('flex-row my-1')}>
        <View
          style={{ left: dateLabelOffsets.leadUpStartDate, position: 'absolute' }}
          onLayout={(e) => onLabelLayout(e.nativeEvent.layout.width, xLabelWidth, setXLabelWidth)}
        >
          <Text style={dateStyles}>{formatDate(displayedPrices[0].date, 'DD MMM YY')}</Text>
        </View>
        <View
          style={{ left: dateLabelOffsets.referenceDate, position: 'absolute' }}
          onLayout={(e) => onLabelLayout(e.nativeEvent.layout.width, xLabelWidth, setXLabelWidth)}
        >
          <Text style={dateStyles}>{formatDate(reference.date, 'DD MMM YY')}</Text>
        </View>
        <View
          style={{ left: dateLabelOffsets.targetDate, position: 'absolute' }}
          onLayout={(e) => onLabelLayout(e.nativeEvent.layout.width, xLabelWidth, setXLabelWidth)}
        >
          <Text style={dateStyles}>{formatDate(target.date, 'DD MMM YY')}</Text>
        </View>
      </View>
    </View>
  );
};

const getProgressLine = (prices: Price[], target: Price, reference: Price): string =>
  prices[prices.length - 1].price - reference.price > 0 ? colors.blue['500'] : colors.fuchsia['600'];

const replaceClosePriceWithReferencePrice = (prices: Price[], reference: Price): Price[] => {
  for (let i = 0; i < prices.length; i++) {
    if (prices[i].date === reference.date) {
      return [...prices.slice(0, i), reference, ...prices.slice(i + 1)];
    }
    if (prices[i].date > reference.date) {
      return [...prices.slice(0, i), reference, ...prices.slice(i)];
    }
  }
  return [...prices, reference];
};

const getDateLabelOffsets = (
  x1: number,
  projectedX1: number,
  projectedX2: number,
  labelWidth: number,
): { leadUpStartDate: number; referenceDate: number; targetDate: number } => {
  const leadUpStartDate = x1 - labelWidth / 2;
  const referenceDate = Math.max(projectedX1 - labelWidth / 2, leadUpStartDate + labelWidth);
  const targetDate = Math.max(projectedX2 - labelWidth / 2, referenceDate + labelWidth);
  return {
    leadUpStartDate,
    referenceDate,
    targetDate,
  };
};
