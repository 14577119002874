import { useNavigation } from '@react-navigation/core';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import { useMemo, VFC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as Yup from 'yup';
import { GBP_CURRENCY_ID } from '../../constants/env';
import { useGetPrePortfolioAndIdeasQuery } from '../../generated/graphql';
import { useUpdatePrePortfolio } from '../../hooks/mutations/preportfolio';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { useLogAndToastError } from '../../hooks/useLogAndToastError';
import { LoggedInStackNavigationProps } from '../../navigation';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { IdeaSnippetRow } from '../../old/IdeaSnippetRow';
import { InfoBottomSheetBody, InfoBottomSheetHeading, InfoBottomSheetText } from '../../old/InfoBottomSheet';
import { InfoLink, Link } from '../../old/Link';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { WizardBodyText, WizardHeadingText } from '../../old/Wizard';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { isNotNull } from '../../util/typeGuards';
import { NonNullableArrays } from '../../util/types';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'PrePortfolioSelectIdeas'>;

const schema = Yup.object({
  selectedInstrumentIds: Yup.array().of(Yup.string().required()).min(1).required(),
});

// Yup has a bug related to types inferred from required arrays, using a utility type to get around this https://github.com/jquense/yup/issues/1389
type Form = NonNullableArrays<Yup.TypeOf<typeof schema>>;

export const PrePortfolioSelectIdeas: VFC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const { present, dismiss } = useBottomSheet();
  const { data, loading, error: queryError } = useGetPrePortfolioAndIdeasQuery();
  useLogAndToastError(queryError);

  const prePortfolio = data?.prePortfolio.prePortfolio;
  const portfolioIdeas = useMemo(() => data?.ideasForPortfolio?.connection?.nodes ?? [], [data]);

  // Only select members that are included in the ideasForPortfolio set in case some have been closed
  const selectedIds = useMemo(
    () =>
      (data?.prePortfolio.prePortfolio?.members?.map((i) => i.instrument?.id) ?? []).filter((id) =>
        portfolioIdeas.some((idea) => (idea.instrument?.id ?? '') === id),
      ),
    [data, portfolioIdeas],
  );

  const [updatePrePortfolio, { error }] = useUpdatePrePortfolio();

  const onSubmit = async (values: Form, formikHelpers: FormikHelpers<Form>) => {
    try {
      if (!prePortfolio?.id) throw new Error('Cannot update prePortfolio members without a prePortfolioID');
      const result = await updatePrePortfolio({
        variables: {
          prePortfolioId: prePortfolio?.id,
          members: values.selectedInstrumentIds.map((instrumentId) => ({ instrumentId })),
          currencyId: GBP_CURRENCY_ID,
        },
      });
      formikHelpers.setSubmitting(false);
      const validationError = result.data?.updatePrePortfolio.error;
      if (validationError) {
        throw createValidationError(validationError);
      }
      if (error) {
        throw error;
      }
      navigation.navigate('PrePortfolioSimulatorRunning', {
        prePortfolioId: prePortfolio?.id ?? '',
      });
    } catch (e) {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    }
  };

  const onPressHelp = () => {
    present(<InfoBottomSheet dismiss={dismiss} />);
  };

  return (
    <SafeAreaView>
      <TitleBar />
      <ScreenSidePadding>
        <WizardHeadingText>Which stocks do you want to add?</WizardHeadingText>
        <WizardBodyText style={tailwind('pt-2')}>
          Select from your active bullish ideas. We believe that you shouldn&apos;t trade until you have a view.
        </WizardBodyText>
        <View style={tailwind('items-end justify-center pt-4 pb-2')}>
          <InfoLink text="Where are my other ideas?" onPress={onPressHelp} />
        </View>
      </ScreenSidePadding>
      {loading ? (
        <ScreenSidePadding style={tailwind('pt-4')}>
          <SelectIdeasSkeleton />
        </ScreenSidePadding>
      ) : portfolioIdeas.length > 0 ? (
        <Formik<Form>
          validationSchema={schema}
          initialValues={{ selectedInstrumentIds: selectedIds.filter(isNotNull) }}
          onSubmit={onSubmit}
        >
          {({ setFieldValue, values: { selectedInstrumentIds }, submitForm, isSubmitting, isValid }) => (
            <>
              {/** px-5 instead of 6 provides extra space for shadows */}
              <ScrollView style={tailwind('flex-grow')} contentContainerStyle={tailwind('pt-4 px-5')}>
                {portfolioIdeas.map((idea) => (
                  <View style={tailwind('py-2')} key={idea.id}>
                    <IdeaSnippetRow
                      {...idea}
                      selected={selectedInstrumentIds.includes(idea.instrument?.id ?? '')}
                      onPress={({ instrumentId }) => {
                        const newSelectedInstrumentIds = selectedInstrumentIds.includes(instrumentId)
                          ? selectedInstrumentIds.filter((selectedId) => selectedId !== instrumentId)
                          : [...selectedInstrumentIds, instrumentId];
                        setFieldValue('selectedInstrumentIds', newSelectedInstrumentIds);
                      }}
                    />
                  </View>
                ))}
              </ScrollView>
              <ScreenSidePadding>
                <View style={tailwind('pb-1 pt-2')}>
                  <Button
                    text="Next"
                    isDisabled={!isValid || isSubmitting || !selectedInstrumentIds.length}
                    loading={isSubmitting}
                    onPress={() => submitForm()}
                  />
                </View>
              </ScreenSidePadding>
            </>
          )}
        </Formik>
      ) : (
        <EmptyView />
      )}
    </SafeAreaView>
  );
});

const EmptyView: React.VFC = () => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  return (
    <View style={tailwind('flex-grow items-center justify-center')}>
      <Text style={tailwind('text-warmGray-500 text-sm pb-2')}>You currently have no active bullish Ideas</Text>
      <Link
        style={tailwind('text-sm')}
        onPress={() => navigation.navigate('Tabs', { screen: 'WatchlistTab', params: { screen: 'Watchlist' } })}
      >
        Create one from your watchlist
      </Link>
    </View>
  );
};

const InfoBottomSheet: VFC<{ dismiss?: () => void }> = () => {
  const tailwind = useTailwind();
  return (
    <InfoBottomSheetBody>
      <InfoBottomSheetHeading>Where are my other ideas?</InfoBottomSheetHeading>
      <InfoBottomSheetText style={tailwind('pb-4')}>
        We allow you to add your active bullish ideas to your portfolio.
        {'\n\n'}
        If you want to initiate a new Idea on a stock which is completed, tap on the stock page and initiate an idea.
        Once it’s created, it will appear here for selection.
        {'\n\n'}
        You also can’t add your bearish ideas as we’re currently only supporting a long only portfolio.
      </InfoBottomSheetText>
      {/**  Present the link once we have portfolio help sections in notion */}
      {/* <Link
        onPress={() => {
          navigation.navigate('WebviewScreen', { uri: EXTERNAL_URIs.REPORT_RATINGS });
          if (dismiss) {
            dismiss();
          }
        }}
        style={tailwind('pt-2')}
      >
        Learn more
      </Link> */}
    </InfoBottomSheetBody>
  );
};

const SelectIdeasSkeleton: VFC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView>
      {Array(4)
        .fill(null)
        .map((i) => (
          <View key={i} style={tailwind('py-2')}>
            <Skeleton style={tailwind('h-12 w-full rounded-lg')} />
          </View>
        ))}
    </SkeletonView>
  );
};
