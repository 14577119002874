import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export const Info: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={9} height={9} viewBox="0 0 9 9" fill="none" {...props}>
      <Path d="M4.5 3.375v3.75" stroke="#0F766E" />
      <Path d="M4.875 2.25a.375.375 0 11-.75 0 .375.375 0 01.75 0z" fill="#0F766E" />
      <Circle cx={4.5} cy={4.5} r={4} stroke="#0F766E" />
    </Svg>
  );
};
