import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export function MagnifyingGlass(props: SvgProps) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM22 22l-6-6"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}
