/* eslint-disable @typescript-eslint/triple-slash-reference */
/// <reference path="util.d.ts"/>
import { Platform } from 'react-native';
import { MarkdownIt, stringToTokens, tokensToAST } from 'react-native-markdown-display';
import { cleanupTokens } from 'react-native-markdown-display/src/lib/util/cleanupTokens';
import groupTextTokens from 'react-native-markdown-display/src/lib/util/groupTextTokens';
import omitListItemParagraph from 'react-native-markdown-display/src/lib/util/omitListItemParagraph';
import { setupURLPolyfill } from 'react-native-url-polyfill';

// Polyfill URL global if not in Web
if (Platform.OS !== 'web') {
  setupURLPolyfill();
}

export const markdownItInstance = new MarkdownIt({ typographer: true });

/**
 *  Deconstruct react-native-markdown-display parser method to insert video_embed tokens and mark them as blocks
 *  react-native-markdown-display separates block tokens from text (inline) tokens as RN doesn't support Views inside Text components, hence must be hoisted from tree to render correctly
 *
 * See https://github.com/iamacup/react-native-markdown-display/blob/master/src/lib/parser.js for reference to deconstructed parser
 *
 * @param markdown String containing Markdown
 * @param markdownItInstance
 * @returns Abstract Syntax Tree to be rendered
 */
export function markdownToAST(markdown: string, markdownItInstance: MarkdownIt) {
  let tokens = stringToTokens(markdown, markdownItInstance);
  tokens = cleanupTokens(tokens);
  // Label video_embed tokens and mark as block.
  tokens.forEach((token) => {
    if (token.type === 'code_inline' && isYoutubeEmbed(token.content)) {
      token.type = 'video_embed';
      token.block = true;
    }
  });
  tokens = groupTextTokens(tokens);
  tokens = omitListItemParagraph(tokens);

  return tokensToAST(tokens);
}

// Check content is a youtube embed per our gatsby-plugin rules https://github.com/ntwcklng/gatsby-remark-embed-youtube
function isYoutubeEmbed(content: string) {
  return content.startsWith('youtube: ');
}

// Simple validation on URL, not exhaustive since we expect this to be checked in Gatsby beforehand
export function getYoutubeUrl(content: string) {
  const urlString = content.split('youtube: ')[1].trim();
  try {
    const url = new URL(urlString);
    if (!url.hostname?.includes('youtube.com')) {
      console.error(`Invalid youtube URL supplied ${urlString}`);
      return;
    }
    // Convert a standard Youtube video link to an embed link https://developers.google.com/youtube/player_parameters
    if (!url.pathname.includes('embed')) {
      const videoId = url.searchParams.get('v');
      url.searchParams.delete('v');
      url.pathname = `embed/${videoId}`;
    }
    // Play video inline when pressing play on iOS (default behavior is full-screen)
    url.searchParams.set('playsinline', '1');
    return url.toString();
  } catch {
    console.error(`Error, invalid URL supplied. Tag content: ${content}`);
  }
}
