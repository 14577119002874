//
// Keep these in sync with .env
// Explicitly read each process.env key for babel inline-dotenv plugin to transpile
//

export const API_BASE_URL = process.env.API_BASE_URL ?? 'http://localhost:8000';
export const GRAPHQL_API_URL = process.env.GRAPHQL_API_URL ?? '';

export type Environment = 'local' | 'dev' | 'preview' | 'prod';

export const ENVIRONMENT: Environment = (process.env.ENVIRONMENT ?? 'local') as Environment;
export const VERSION = process.env.VERSION ?? '';
export const SENTRY_DSN = process.env.SENTRY_DSN ?? '';
export const SEGMENT_WRITE_KEY = process.env.SEGMENT_WRITE_KEY ?? '';
export const BRANCH_KEY = process.env.BRANCH_KEY ?? '';
export const CLIENT_URL = process.env.CLIENT_URL ?? '';
export const UNIVERSAL_LINK_DOMAIN = process.env.UNIVERSAL_LINK_DOMAIN ?? '';
export const GBP_CURRENCY_ID = process.env.GBP_CURRENCY_ID ?? '';
export const ONESIGNAL_APP_ID = process.env.ONESIGNAL_APP_ID ?? '';
export const COMMIT_HASH = process.env.COMMIT_HASH ?? '';
export const RUNTIME_VERSION = process.env.RUNTIME_VERSION ?? '';
// @ts-expect-error not on global definition
const isHermes = !!global.HermesInternal;

if (process.env.NODE_ENV !== 'test') {
  console.log(`Env: ${ENVIRONMENT}
GRAPHQL_API_URL: ${GRAPHQL_API_URL}
API_BASE_URL: ${API_BASE_URL}
isHermes:${isHermes}
VERSION: ${VERSION}`);
}
