import { useNavigation } from '@react-navigation/native';
import { isNil } from 'lodash';
import { ViewStyle } from 'react-native';
import { LoggedInStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { Link } from '../Link';

export type Props = { commentCount?: number; ideaId: string; style?: ViewStyle; onPressViewCommentLink?: () => void };

/**
 * "View 2 comments" or "Add comment" if no commentCount on idea
 *
 * shown at bottom of an idea or new comment card
 */
export const ViewCommentLink: React.VFC<Props> = ({ commentCount, ideaId, style, onPressViewCommentLink }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return !isNil(commentCount) && commentCount > 0 ? (
    <Link
      onPress={() => {
        if (onPressViewCommentLink) {
          onPressViewCommentLink();
        } else {
          navigation.push('Idea', { ideaId: ideaId, showComments: true });
        }
      }}
      style={[tailwind('px-4 self-end'), style]}
    >
      View {commentCount} comment{commentCount > 1 && 's'}
    </Link>
  ) : (
    <Link
      onPress={() => {
        if (onPressViewCommentLink) {
          onPressViewCommentLink();
        } else {
          navigation.push('Idea', { ideaId: ideaId, showComments: true });
        }
      }}
      style={[tailwind('px-4 self-end'), style]}
    >
      Add comment
    </Link>
  );
};
