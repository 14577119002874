import { useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { useToast } from 'react-native-toast-notifications';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { isNullish } from '../../../util/typeGuards';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle } from '../../Card';
import { LightBulb } from '../../icons';
import { IdeaCard } from '../../IdeaCard';
import { InlineUserIcon } from '../../InlineUserIcon';
import { Link } from '../../Link';

export type Props = NarrowByTypename<FeedCardFieldsFragment, 'NewIdeaFeedItem'>;

export const NewIdeaCard: React.FC<Props> = ({ idea, __typename, created, id }) => {
  const toast = useToast();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  const onPressUser = useCallback(() => {
    const ideaCreator = idea?.user?.id;
    if (isNullish(ideaCreator)) {
      console.error(`User id for idea ${idea?.id} not defined`);
      toast.show('Unexpected error occurred');
      return;
    }
    navigation.push('UserProfile', { userId: ideaCreator });
    analytics.track('Feed card pressed', {
      'Feed card type': __typename,
      'Destination screen': 'UserProfile',
      'Feed item id': id,
      'Instrument ticker': idea?.instrument?.ticker ?? '',
    });
  }, [__typename, id, idea, navigation, toast]);

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, idea }}
        eventTime={created ? new Date(created) : undefined}
        icon={<LightBulb />}
      >
        Investment Idea
      </CardTitle>
      <CardHeadline>
        <CardHeadlineText>
          <InlineUserIcon userProfile={idea?.user} onPressUser={onPressUser} /> is{' '}
          {idea?.position === 'LONG' ? 'bullish' : 'bearish'} on{' '}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: idea?.instrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Feed card type': __typename,
                'Destination screen': 'Instrument',
                'Feed item id': id,
                'Instrument ticker': idea?.instrument?.ticker ?? '',
              });
            }}
          >
            {idea?.instrument?.name ?? ''}
          </Link>
        </CardHeadlineText>
      </CardHeadline>
      <IdeaCard
        topic={idea?.topic}
        ideaId={idea?.id ?? ''}
        position={idea?.position}
        status={idea?.status}
        closeTime={idea?.closeTime}
        referenceDate={idea?.referenceDate}
        targetDate={idea?.targetDate}
        referencePrice={idea?.referencePrice}
        targetPrice={idea?.targetPrice}
        rationaleTags={idea?.rationaleTags}
        name={idea?.instrument?.name}
        ticker={idea?.instrument?.ticker}
        nickname={idea?.user?.nickname}
        logoUrl={idea?.instrument?.logoUrl}
        performance={idea?.performance}
        snapshot="ideaInstrument"
        onPress={() => {
          navigation.push('Idea', { ideaId: idea?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Feed card type': __typename,
            'Destination screen': 'Idea',
            'Feed item id': id ?? '',
            'Instrument ticker': idea?.instrument?.ticker ?? '',
          });
        }}
      />
    </Card>
  );
};
