import { sortBy } from 'lodash';
import React, { FC } from 'react';
import { InteractionManager, View } from 'react-native';
import { useDerivedValue, useSharedValue } from 'react-native-reanimated';
import { useTailwind } from '../../../theme';
import { getPriceBoundaries } from '../../../util/chart';
import { YAxis } from '../axis';
import { TICK_COUNT } from '../axis/YAxis';
import { ChartCanvas } from '../ChartCanvas';
import { chartColor } from '../color';
import { ChartContext } from '../context/ChartContext';
import { getTicks } from '../ticks';

export type Props = {
  currencyIso: string;
  prices: [number, number][];
};

export const PortfolioChart: FC<Props> = ({ prices, currencyIso }) => {
  const tailwind = useTailwind();
  // TODO: Initial transition 0 and set to 1 when scrolled into view
  const transition = useSharedValue(1);

  const [width, setWidth] = React.useState<number | undefined>();
  const yAxisWidth = useSharedValue(0);
  const chartWidth = useDerivedValue(() => (width !== undefined ? width - yAxisWidth.value : 0));
  const height = useSharedValue(196);

  const sortedPrices = sortBy(prices, (x) => x[0]);
  const boundaries = getPriceBoundaries(sortedPrices);
  const ticks = getTicks(boundaries.lowest, boundaries.highest, TICK_COUNT);
  const color = chartColor(sortedPrices);
  const contextData = useDerivedValue(() => ({ prices: prices, ticks: ticks, colors: color }), [prices, ticks, color]);
  return (
    <ChartContext.Provider value={{ chartWidth, height, data: contextData }}>
      <View
        onLayout={(e) => {
          setWidth(e.nativeEvent.layout.width);
        }}
        style={tailwind('py-2')}
      >
        <ChartCanvas transition={transition} />
        <YAxis
          currencyCode={currencyIso}
          widthHandler={(width) => {
            /**
             * The wrapping in InteractionManager and setTimeout to a regression introduced in react-native-reanimated@2.3.1
             * https://github.com/software-mansion/react-native-reanimated/issues/2571
             *
             * PR to fix: https://github.com/software-mansion/react-native-reanimated/pull/2580
             * Fix will probably be in react-native-reanimated@2.3.2 as part of expo 45
             */
            InteractionManager.runAfterInteractions(() => {
              setTimeout(() => {
                yAxisWidth.value = width;
              }, 300);
            });
          }}
        />
      </View>
    </ChartContext.Provider>
  );
};
