import { View as MotiView } from 'moti';
import React from 'react';
import { View, ViewProps } from 'react-native';
import { Esg } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { ProgressBar } from '../ProgressBar';

type Props = {
  /**
   * Bars and values hidden until set to true
   */
  viewed?: boolean;
} & Pick<
  Esg,
  | 'totalScore'
  | 'totalGrade'
  | 'environmentScore'
  | 'environmentGrade'
  | 'socialScore'
  | 'socialGrade'
  | 'governanceScore'
  | 'governanceGrade'
>;

export const EsgScores = React.forwardRef<View, Props>(
  (
    {
      viewed,
      totalScore,
      totalGrade,
      environmentScore,
      environmentGrade,
      socialScore,
      socialGrade,
      governanceScore,
      governanceGrade,
    },
    ref,
  ) => {
    const tailwind = useTailwind();
    return (
      /**
       * Must set otherwise can't detect if container is in view on android
       */
      <View collapsable={false} ref={ref}>
        <Text style={tailwind('text-warmGray-500 text-sm font-bold')}>Overall</Text>
        <View style={tailwind('flex-row items-center border-b border-trueGray-300 pb-2')}>
          <ProgressBar
            animate={viewed}
            enableAnimation={true}
            progress={getPercentageScore(totalScore ?? 0, 3000)}
            color={colors.seaBlue}
            lineWidth={12}
            style={tailwind('my-2 flex-grow')}
          />
          <ScoreCell viewed={viewed} accessibilityLabel="Esg score">
            {totalScore}
          </ScoreCell>
          <RatingCell viewed={viewed} accessibilityLabel="Esg grade">
            {totalGrade}
          </RatingCell>
        </View>

        <Text style={tailwind('text-warmGray-500 text-sm pt-4 -mb-1')}>Environmental</Text>
        <View style={tailwind('flex-row items-center pb-2')}>
          <ProgressBar
            animate={viewed}
            enableAnimation={true}
            progress={getPercentageScore(environmentScore ?? 0, 1000)}
            color={colors.lightGreen}
            lineWidth={8}
            style={tailwind('my-2 flex-grow')}
          />
          <ScoreCell viewed={viewed} accessibilityLabel="Environmental score">
            {environmentScore}
          </ScoreCell>
          <RatingCell viewed={viewed} accessibilityLabel="Environmental grade">
            {environmentGrade}
          </RatingCell>
        </View>
        <Text style={tailwind('text-warmGray-500 text-sm -mb-1')}>Social</Text>
        <View style={tailwind('flex-row items-center pb-2')}>
          <ProgressBar
            animate={viewed}
            enableAnimation={true}
            color={colors.pinkRed}
            progress={getPercentageScore(socialScore ?? 0, 1000)}
            lineWidth={8}
            style={tailwind('my-2 flex-grow')}
          />
          <ScoreCell viewed={viewed} accessibilityLabel="Social score">
            {socialScore}
          </ScoreCell>
          <RatingCell viewed={viewed} accessibilityLabel="Social grade">
            {socialGrade}
          </RatingCell>
        </View>
        <Text style={tailwind('text-warmGray-500 text-sm -mb-1')}>Governance</Text>
        <View style={tailwind('flex-row items-center pb-2')}>
          <ProgressBar
            color={colors.orangeAmber}
            animate={viewed}
            enableAnimation={true}
            progress={getPercentageScore(governanceScore ?? 0, 1000)}
            lineWidth={8}
            style={tailwind('my-2 flex-grow')}
          />
          <ScoreCell viewed={viewed} accessibilityLabel="Governance score">
            {governanceScore}
          </ScoreCell>
          <RatingCell viewed={viewed} accessibilityLabel="Governance grade">
            {governanceGrade}
          </RatingCell>
        </View>
        <Text style={tailwind('text-xs text-warmGray-500 pt-2 pb-4')}>Source: ESG Enterprise</Text>
      </View>
    );
  },
);

const ScoreCell: React.FC<ViewProps & { viewed?: boolean }> = ({ viewed, children, ...props }) => {
  const tailwind = useTailwind();
  return (
    <MotiView delay={150} animate={{ opacity: viewed ? 1 : 0 }} {...props}>
      <Text style={tailwind('pl-2 w-12 text-center')}>{children}</Text>
    </MotiView>
  );
};
const RatingCell: React.FC<ViewProps & { viewed?: boolean }> = ({ viewed, children, ...props }) => {
  const tailwind = useTailwind();
  return (
    <MotiView delay={150} animate={{ opacity: viewed ? 1 : 0 }} {...props}>
      <Text style={tailwind('pl-2 w-12 text-warmGray-500 text-sm text-center')}>{children}</Text>
    </MotiView>
  );
};

const getPercentageScore = (score: number, max: number) => (score / max) * 100;
