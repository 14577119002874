import React from 'react';
import { useAnimatedReaction } from 'react-native-reanimated';
import Svg, { ClipPath, Defs, LinearGradient, Path, Rect, Stop } from 'react-native-svg';
import { useChart } from '../context/useChart';
import { getPath, HEIGHT, WIDTH } from './line';
import { Props } from './props';

export const ChartCanvas: React.VFC<Props> = () => {
  const { chartWidth, height, data } = useChart();
  const [currentWidth, setCurrentWidth] = React.useState(0);
  const [chartData, setChartData] = React.useState(data.value);
  const path = chartData.ticks
    ? getPath(chartData.prices, chartData.ticks.low, chartData.ticks.high, WIDTH, HEIGHT)
    : '';

  useAnimatedReaction(
    () => data.value,
    (current) => {
      setChartData(current);
    },
  );
  useAnimatedReaction(
    () => chartWidth.value,
    (width, prevWidth) => {
      if (width !== prevWidth) {
        setCurrentWidth(width);
      }
    },
  );

  return (
    <Svg width={currentWidth} height={height.value} viewBox={`0 0 ${WIDTH} ${HEIGHT}`} preserveAspectRatio="none">
      <Defs>
        <ClipPath id="chart-clip">
          <Path d={path ? `${path}  L ${WIDTH} ${HEIGHT} L 0 ${HEIGHT}` : ''} />
        </ClipPath>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradient">
          <Stop stopColor={chartData.colors?.gradient} offset="0%" stopOpacity={1} />
          <Stop stopColor="#FFFFFF" offset="100%" stopOpacity={0.5} />
        </LinearGradient>
      </Defs>
      <Rect width={WIDTH} height={HEIGHT} clipPath="url(#chart-clip)" fill="url(#gradient)" />
      <Path
        d={path}
        stroke={chartData.colors?.path}
        strokeWidth={2}
        fill="transparent"
        vectorEffect="non-scaling-stroke"
      />
    </Svg>
  );
};
