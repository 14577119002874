import NetInfo from '@react-native-community/netinfo';
import { useEffect } from 'react';
import { useStore } from '../zustand/store';

/** on app bootstrap configure */
export const initNetInfo = () => {
  NetInfo.configure({
    reachabilityShouldRun: () => false, // not using reachability for now, so turn off calling https://clients3.google.com/generate_204 all the time
  });
};

/** Listen to network connection and update zustand */
export function useSetupNetInfo() {
  useEffect(() => {
    try {
      const unsubscribe = NetInfo.addEventListener(({ isConnected, type }) => {
        console.log(`useSetupNetInfo: isConnected ${isConnected}, type ${type}`);
        useStore.setState({ isConnected });
      });
      return () => unsubscribe();
    } catch (e) {
      console.error(`useSetupNetInfo: Error listening for net info`, e);
    }
  }, []);
}
