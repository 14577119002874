import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, RefreshControl, useWindowDimensions, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { CLIENT_URL } from '../../constants/env';
import { layout } from '../../constants/layout';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { useGetVoiceQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { IconButton } from '../../old/Button';
import { Share as ShareIcon } from '../../old/icons';
import { IdeaSnippetRow } from '../../old/IdeaSnippetRow';
import { InstrumentPriceRow } from '../../old/InstrumentPriceRow';
import { NewsSkeleton } from '../../old/NewsSkeleton';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar, titleBarImageSource } from '../../old/TitleBar';
import { share } from '../../services/sharing';
import { useTailwind } from '../../theme';
import { Markdown } from '../../ui/Markdown';
import { DESKTOP_MAX_WIDTH, SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { formatDate } from '../../util/date';
import { getReadTime } from '../../util/string';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'Voice'>;

/* eslint-disable graphql/template-strings */
export const getVoice = gql`
  ${CORE_INSTRUMENT_FIELDS}
  ${CORE_IDEA_FIELDS}
  query getVoice($id: ID!, $loggedIn: Boolean!, $imgixParams: ImgixInput) {
    voice(id: $id) {
      id
      title
      excerpt
      body
      publishDate
      heroImage(imgixParams: $imgixParams) {
        url
      }
      instruments {
        ...CoreInstrumentFields
      }
      ideas {
        ...CoreIdeaFields
      }
    }
  }
`;

export const UpsideVoice: React.FC<Props> = withReloadErrorBoundary(({ navigation, route }) => {
  const tailwind = useTailwind();
  const { voiceId } = route.params;
  const { width } = useWindowDimensions();
  const { isUserLoggedIn: loggedIn, userId } = usePersistedStore(({ isUserLoggedIn, userId }) => ({
    isUserLoggedIn,
    userId,
  }));
  const toast = useToast();

  const {
    data,
    loading: dataLoading,
    refetch,
    error,
  } = useGetVoiceQuery({
    variables: {
      id: voiceId,
      loggedIn,
      imgixParams: { width: Math.floor(Math.min(width, DESKTOP_MAX_WIDTH) * layout.pixelRatio) },
    },
  });

  const { refreshing, onRefresh } = useRefresh(refetch);
  const [imageDimensions, setImageDimensions] = useState({ width: Math.min(width, DESKTOP_MAX_WIDTH), height: 0 });
  const [imageLoaded, setImageLoaded] = useState(false);

  const onShare = async () => {
    try {
      const status = await share(
        {
          message: `Check out this Upside Voice: "${data?.voice?.title}"`,
          url: `${CLIENT_URL}/Voice/${voiceId}/`,
          title: `Upside Voice: "${data?.voice?.title}"`,
        },
        userId,
      );
      if (status === 'CopiedToClipboard') {
        toast.show('Link copied to clipboard.');
      }
    } catch (e) {
      console.error(e);
      toast.show('Unable to share link. Please try again');
    }
  };

  if (error) {
    throw error;
  }

  // Image renders with height 0 unless height specified. Set height style to height of image received once loaded.
  useEffect(() => {
    if (data) {
      Image.getSize(data?.voice?.heroImage?.url ?? '', (width, height) => {
        setImageDimensions({ width: width / layout.pixelRatio, height: height / layout.pixelRatio });
        setImageLoaded(true);
      });
    }
  }, [data]);

  const loading = dataLoading || !imageLoaded;

  const readTime = useMemo(() => {
    const words = data?.voice.body?.split(' ') ?? [];
    return getReadTime(words.length);
  }, [data]);

  return (
    <SafeAreaView>
      <TitleBar
        redirectIfLoggedOut
        showLogo={!loggedIn}
        hideBackButton={!loggedIn}
        endAdornment={
          <IconButton accessibilityLabel="Share" icon={<ShareIcon height={24} width={24} />} onPress={onShare} />
        }
      />
      {loading ? (
        <NewsSkeleton />
      ) : (
        <ScrollView
          contentContainerStyle={tailwind('pt-4 pb-6')}
          refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        >
          <ScreenSidePadding>
            <Text style={tailwind('my-2 text-gray-800 text-xl font-semibold')}>{data?.voice?.title}</Text>
            <View style={tailwind('py-2 flex-row')}>
              <Image
                source={titleBarImageSource}
                accessibilityIgnoresInvertColors={false}
                style={tailwind('h-5 w-5 rounded-full mr-2')}
              />
              <Text style={tailwind('text-xs text-warmGray-500 self-center')}>Upside voice</Text>
            </View>
          </ScreenSidePadding>
          {data?.voice.heroImage?.url && (
            <View style={tailwind('pt-2 pb-4')}>
              <Image
                accessibilityIgnoresInvertColors
                source={{ uri: data.voice.heroImage.url ?? '' }}
                style={imageDimensions}
                resizeMode="contain"
              />
            </View>
          )}
          <ScreenSidePadding>
            <View style={tailwind('py-3 flex-row justify-between')}>
              <Text style={tailwind('text-gray-500 text-sm font-normal')}>
                {data?.voice?.publishDate ? formatDate(data?.voice?.publishDate, 'D MMM YYYY, hh:mmA') : ''}
              </Text>
              <Text style={tailwind('text-gray-500 text-sm font-normal')}>{readTime} min read</Text>
            </View>

            <View style={tailwind('pt-2')}>
              <Markdown>{data?.voice?.body ?? ''}</Markdown>
            </View>

            {data?.voice?.instruments.map((instrument) => (
              <InstrumentPriceRow key={instrument?.id} showWatchlistToggle isPressableCard {...instrument} />
            ))}

            {data?.voice?.ideas.map((idea) => (
              <IdeaSnippetRow
                onPress={({ ideaId }) => navigation.push('Idea', { ideaId })}
                bottomRightView="actualReturn"
                style={tailwind('mt-4')}
                key={idea?.id}
                id={idea?.id ?? ''}
                {...idea}
              />
            ))}
          </ScreenSidePadding>
        </ScrollView>
      )}
    </SafeAreaView>
  );
});
