import { gql, NetworkStatus } from '@apollo/client';
import BottomSheet from '@gorhom/bottom-sheet';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import dayjs from 'dayjs';
import { isNil, isNumber } from 'lodash';
import React, { useCallback } from 'react';
import { RefreshControl, ScrollView as RNScrollView, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useToast } from 'react-native-toast-notifications';
import { CORE_COMMENT_FIELDS } from '../../fragments/comments';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { CORE_USER_PROFILE_FIELDS } from '../../fragments/userProfile';
import { AppEntityType, GetIdeaQuery, IdeaStatus, useGetIdeaQuery } from '../../generated/graphql';
import { useIdeaWatchlistToggle } from '../../hooks/mutations/useIdeaWatchlistToggle';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackNavigationProps } from '../../navigation';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button, IconButton } from '../../old/Button';
import { Chip } from '../../old/Chip';
import { CommentsSummary } from '../../old/Comment/CommentSummary';
import { LightBulb, Share, Tracker, TrackerBold } from '../../old/icons';
import { IdeaCard, IdeaCardSkeleton } from '../../old/IdeaCard';
import { Persona, PersonaSkeleton } from '../../old/Persona';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { share } from '../../services/sharing';
import { CommentSheet } from '../../sheets/CommentSheet/CommentSheet';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { getShareIdeaPayload } from '../../util/idea';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { CloseIdea } from './CloseIdea';
import { IdeaDetails } from './IdeaDetails';
import { Performance } from './Performance';
import { NewlyCreatedIdeaTutorialCard, OtherUserIdeaTutorialCard } from './TutorialCards';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'Idea'>;

/* eslint-disable graphql/template-strings */
export const getIdea = gql`
  ${CORE_INSTRUMENT_FIELDS}
  ${CORE_COMMENT_FIELDS}
  ${CORE_USER_PROFILE_FIELDS}
  query getIdea($ideaId: ID!, $loggedIn: Boolean!) {
    publicIdea(id: $ideaId) {
      id
      conviction
      status
      user {
        ...CoreUserProfileFields
        # Used to check if user any active Ideas on this instrument for Close/Create New button
        ideas(filter: { instrument: { ideaIds: [$ideaId] }, statuses: [ACTIVE] }, pagination: { limit: 1 }) {
          connection {
            nodes {
              id
            }
          }
        }
      }
      instrument {
        ...CoreInstrumentFields
      }
      rationaleTags {
        id
        name
        sortOrder
      }
      position
      referenceDate
      referencePrice
      targetDate
      targetPrice
      headline
      description
      closeTime
      performance {
        id
        actualReturn
        actualPrice
        actualPriceMovement
        priceChangeSincePosted
      }
      topic(pagination: { limit: 1 }) {
        commentCount
        comments {
          nodes {
            ...CoreCommentFields
          }
        }
      }
      watchlistId @include(if: $loggedIn)
    }
  }
`;

export const Idea: React.FC<Props> = withReloadErrorBoundary(({ route, navigation }) => {
  const tailwind = useTailwind();
  const { ideaId, showComments, forceRefetch, commentId } = route.params;
  const sheetRef = React.useRef<BottomSheet>(null);
  const scrollRef = React.useRef<RNScrollView>(null);
  const { present, dismiss } = useBottomSheet();
  const userId = usePersistedStore((state) => state.userId);
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const toast = useToast();
  const { top } = useSafeAreaInsets();
  const [offsets, setOffsets] = React.useState<Offsets>({
    safeAreaHeight: undefined,
    scrollView: undefined,
    ideaCard: undefined,
    ideaHeight: undefined,
    top,
  });
  const { myIdeaTutorialDismissed, otherUserIdeaTutorialDismissed } = useTutorialCardPersistedStore(
    ({ myIdeaTutorialDismissed, otherUserIdeaTutorialDismissed }) => ({
      myIdeaTutorialDismissed,
      otherUserIdeaTutorialDismissed,
    }),
  );

  React.useEffect(() => {
    if (showComments) {
      scrollRef.current?.scrollTo({ y: offsets.ideaCard, animated: true });
    }
  }, [showComments, scrollRef, offsets]);
  const { data, refetch, error, networkStatus } = useGetIdeaQuery({
    variables: { ideaId, loggedIn },
  });
  // if deeplinking to here, user might have previously seen page, so the comments will be cached and stale.
  // Hence force re-fetch upon focus
  useFocusEffect(
    React.useCallback(() => {
      console.log(`useFocusEffect running`);
      if (forceRefetch) {
        console.log(`Forcing refetch on focus as forceRefetch route param passed`);
        refetch();
      }
    }, [forceRefetch, refetch]),
  );

  const { toggleIdeaWatchlist } = useIdeaWatchlistToggle(
    {
      ideaId: ideaId,
      instrumentId: data?.publicIdea?.instrument?.id ?? '',
      instrumentTicker: data?.publicIdea?.instrument?.ticker ?? '',
      watchlistId: data?.publicIdea.watchlistId,
      user: data?.publicIdea.user,
    },
    { showToasterOnMutation: true },
  );

  const isTracking = !isNil(data?.publicIdea.watchlistId);
  const { refreshing, onRefresh } = useRefresh(refetch);

  if (error) {
    throw new Error(`Error fetching idea with id ${ideaId}`);
  }

  const showNewlyCreatedIdeaTutorial = data?.publicIdea.user?.id === userId && !myIdeaTutorialDismissed;
  const showOtherUserIdeaTutorial = data?.publicIdea.user?.id !== userId && !otherUserIdeaTutorialDismissed;

  const tutorialCard = showNewlyCreatedIdeaTutorial ? (
    <NewlyCreatedIdeaTutorialCard />
  ) : showOtherUserIdeaTutorial ? (
    <OtherUserIdeaTutorialCard />
  ) : null;

  const isMyIdea = userId === data?.publicIdea?.user?.id ?? 0;

  const onShare = async () => {
    if (!data?.publicIdea) return;
    const idea = data.publicIdea;

    try {
      const payload = getShareIdeaPayload(userId ?? 0, idea);
      const status = await share(payload, userId);
      if (status === 'CopiedToClipboard') {
        toast.show('Link copied to clipboard.');
      }
    } catch (e) {
      console.error(e);
      toast.show('Unable to share link. Are sharing or clipboard permissions enabled?');
    }
  };

  const onClose = () => {
    present(<CloseIdea dismiss={dismiss} ideaId={data?.publicIdea.id ?? ''} />);
  };

  const onViewComments = useCallback(() => {
    scrollRef.current?.scrollTo({ y: offsets.ideaCard, animated: true });
    sheetRef.current?.snapToIndex(0);
  }, [scrollRef, sheetRef, offsets]);

  const loading = isLoading(networkStatus);

  return (
    <>
      <SafeAreaView
        onLayout={(e) => {
          const { height } = e.nativeEvent.layout;
          const area = offsets.safeAreaHeight ?? 0;
          if (isNumber(height) && height > area) {
            setOffsets((prev) => ({ ...prev, safeAreaHeight: height }));
          }
        }}
      >
        <View
          onLayout={(e) => {
            const { height } = e.nativeEvent.layout;
            if (isNumber(height)) {
              setOffsets((prev) => ({ ...prev, scrollView: height }));
            }
          }}
        >
          <TitleBar
            title=""
            redirectIfLoggedOut
            showLogo={!loggedIn}
            hideBackButton={!loggedIn}
            endAdornment={
              <IconButton accessibilityLabel="Share" icon={<Share height={24} width={24} />} onPress={onShare} />
            }
          />
        </View>
        {loading ? (
          <IdeaScreenSkeleton />
        ) : (
          <ScrollView ref={scrollRef} refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}>
            <ScreenSidePadding>
              <View style={tailwind('py-4')}>
                <Persona
                  size="md"
                  onUserProfilePress={() => {
                    navigation.push('UserProfile', { userId: data?.publicIdea.user?.id ?? 0 });
                  }}
                  {...data?.publicIdea.user}
                  subText={dayjs(new Date(data?.publicIdea.referenceDate ?? '')).format('DD MMM YYYY')}
                />
              </View>
              {tutorialCard}
              <View
                style={tailwind('py-2')}
                onLayout={(e) => {
                  const { y, height } = e.nativeEvent.layout;
                  setOffsets((prev) => ({
                    ...prev,
                    ideaCard: y,
                    ideaHeight: height,
                  }));
                }}
              >
                <IdeaCard
                  ideaId={data?.publicIdea.id ?? ''}
                  position={data?.publicIdea?.position}
                  status={data?.publicIdea.status}
                  closeTime={data?.publicIdea.closeTime}
                  referenceDate={data?.publicIdea?.referenceDate}
                  targetDate={data?.publicIdea?.targetDate}
                  referencePrice={data?.publicIdea?.referencePrice}
                  targetPrice={data?.publicIdea?.targetPrice}
                  name={data?.publicIdea?.instrument?.name}
                  ticker={data?.publicIdea?.instrument?.ticker}
                  instrumentId={data?.publicIdea.instrument?.id ?? ''}
                  nickname={data?.publicIdea?.user?.nickname}
                  logoUrl={data?.publicIdea?.instrument?.logoUrl}
                  performance={data?.publicIdea.performance}
                  topic={data?.publicIdea.topic}
                  snapshot="instrument"
                  onPressViewComments={onViewComments}
                />
              </View>
              {!isMyIdea && data?.publicIdea.status === IdeaStatus.Active && (
                <Button
                  redirectIfLoggedOut
                  StartIcon={isTracking ? TrackerBold : Tracker}
                  text={isTracking ? 'Tracking' : 'Track Idea'}
                  onPress={toggleIdeaWatchlist}
                  size="md"
                  variant={isTracking ? 'secondary' : 'primary'}
                  style={tailwind('my-6')}
                />
              )}
            </ScreenSidePadding>
            <Performance
              quotePrice={data?.publicIdea.instrument?.quotePrice}
              referenceDate={data?.publicIdea.referenceDate}
              referencePrice={data?.publicIdea.referencePrice}
              targetDate={data?.publicIdea.targetDate}
              targetPrice={data?.publicIdea.targetPrice}
              iso={data?.publicIdea.instrument?.currency?.iso}
              position={data?.publicIdea.position}
              instrumentId={data?.publicIdea.instrument?.id}
            />
            <ScreenSidePadding>
              <IdeaDetails
                targetDate={data?.publicIdea.targetDate}
                targetPrice={data?.publicIdea.targetPrice}
                referenceDate={data?.publicIdea.referenceDate}
                referencePrice={data?.publicIdea.referencePrice}
                conviction={data?.publicIdea.conviction}
                midPrice={data?.publicIdea?.instrument?.quotePrice?.midPrice}
                iso={data?.publicIdea.instrument?.currency?.iso}
                position={data?.publicIdea.position}
                performance={data?.publicIdea.performance}
              />
              <IdeaRationale rationaleTags={data?.publicIdea.rationaleTags} />
              {isMyIdea && (
                <MyIdeaButton
                  ideaId={data?.publicIdea.id ?? ''}
                  currentActiveIdeaId={data?.publicIdea.user?.ideas?.connection?.nodes?.[0]?.id}
                  instrumentId={data?.publicIdea?.instrument?.id ?? ''}
                  onClose={onClose}
                />
              )}
            </ScreenSidePadding>
            <CommentsSummary
              commentCount={data?.publicIdea.topic?.commentCount ?? 0}
              comment={data?.publicIdea.topic?.comments?.nodes[0]}
              ideaId={data?.publicIdea.id ?? ''}
              onPress={onViewComments}
            />
          </ScrollView>
        )}
      </SafeAreaView>
      <CommentSheet
        entityId={ideaId}
        entityType={AppEntityType.Idea}
        ref={sheetRef}
        offset={getOffset(offsets)}
        initialVisibility={showComments}
        commentId={commentId}
      />
    </>
  );
});
export const IdeaRationale: React.VFC<Pick<GetIdeaQuery['publicIdea'], 'rationaleTags'>> = ({ rationaleTags }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('py-4')}>
      <Text style={tailwind('text-lg font-semibold text-warmGray-800')}>Rationale</Text>
      <View style={tailwind('flex-row flex-wrap')}>
        {rationaleTags?.map((i) => (
          <View key={i?.id} style={tailwind('py-2 pr-2')}>
            <Chip text={i?.name ?? ''} />
          </View>
        ))}
      </View>
    </View>
  );
};

export const IdeaScreenSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <ScreenSidePadding>
      <SkeletonView>
        <View style={tailwind('pt-2')}>
          <PersonaSkeleton />
        </View>
        <View style={tailwind('py-4')}>
          <IdeaCardSkeleton />
        </View>
      </SkeletonView>
    </ScreenSidePadding>
  );
};

type Offsets = {
  top: number;
  safeAreaHeight?: number;
  scrollView?: number;
  ideaCard?: number;
  ideaHeight?: number;
};

const getOffset = (offsets: Offsets): number => {
  const { safeAreaHeight: area, scrollView, ideaHeight, top } = offsets;
  if (isNumber(area) && isNumber(scrollView) && isNumber(ideaHeight)) {
    return Math.max(area - scrollView - ideaHeight - top, 1);
  }

  /**
   * Offset cannot be zero, hence using 1 as a placeholder offset
   * until layouts are measured
   */
  return 1;
};

const isLoading = (networkStatus: NetworkStatus): boolean => {
  switch (networkStatus) {
    case NetworkStatus.loading:
    case NetworkStatus.setVariables:
    case NetworkStatus.refetch:
      return true;
    default:
      return false;
  }
};

export const MyIdeaButton: React.VFC<{
  ideaId: string;
  currentActiveIdeaId?: string;
  instrumentId: string;
  onClose: () => void;
}> = ({ ideaId, currentActiveIdeaId, instrumentId, onClose }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  // If this Idea is user's current active one, show close button
  return currentActiveIdeaId === ideaId ? (
    <Button variant="secondary" onPress={onClose} text="Close Idea" size="md" style={tailwind('my-4')} />
  ) : // Else if user doesn't have an active Idea on this instrument, show create button
  !currentActiveIdeaId ? (
    <Button
      variant="alternative"
      onPress={() => {
        navigation.navigate('CreateIdea', { instrumentId });
      }}
      size="md"
      StartIcon={LightBulb}
      text="Create new Idea"
      style={tailwind('my-4')}
    />
  ) : null;
};
