import {
  DefaultTheme,
  NavigationContainer,
  NavigationContainerProps,
  NavigationContainerRef,
  Theme,
} from '@react-navigation/native';
import * as React from 'react';
import { useRef } from 'react';
import { Linking, Platform, View } from 'react-native';
import { useIdentify } from '../hooks/useIdentify';
import { usePrimeCache } from '../hooks/usePrimeCache';
import { useSetupNetInfo } from '../hooks/useSetupNetInfo';
import { useSplashScreenEffects } from '../hooks/useSplashScreenEffects';
import { analytics } from '../services/analytics';
import { colors, useTailwind } from '../theme';
import { linking } from './linking';
import { RootStackNavigator, RootStackParamList } from './RootStackNavigator';

// Set default useNavigation paramList and linking config to our RootStackParamList type https://reactnavigation.org/docs/typescript/#specifying-default-types-for-usenavigation-link-ref-etc
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace ReactNavigation {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface RootParamList extends RootStackParamList {}
  }
}

export const theme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: colors.white,
    text: colors.warmGray[700],
    card: colors.white,
  },
};

export type Props = {
  /** can use in storybook/jest/deeplink to launch navigation with initial state */
  initialState?: NavigationContainerProps['initialState'];
};

/**
 * Root Navigation of whole app which also kicks off some app bootstrap hooks
 */
export const RootNavigator: React.FC<Props> = ({ initialState }) => {
  const tailwind = useTailwind();
  // kick off some app bootstrap hooks
  const { isLoadingComplete } = useSplashScreenEffects();
  useSetupNetInfo();
  usePrimeCache();
  useIdentify();

  // Ref to listen for navigator events
  const navigationRef = useRef<NavigationContainerRef<RootStackParamList>>(null);
  const routeNameRef = useRef<string | undefined>();

  return (
    <NavigationContainer
      ref={navigationRef}
      onReady={() => (routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name)}
      onStateChange={async () => {
        // Track all screen visits with route params. Only track route changes, not screen reloads.
        const currentRoute = navigationRef.current?.getCurrentRoute();
        const previousRouteName = routeNameRef.current;
        const currentRouteName = currentRoute?.name ?? '';
        const url = Platform.OS === 'web' ? window?.location.href : '';
        const initialPath = (await Linking.getInitialURL()) ?? '';

        if (previousRouteName !== currentRouteName) {
          console.log(`Navigated to page ${currentRouteName}`, { ...currentRoute?.params });
          analytics.track('Loaded a Page', {
            name: currentRouteName,
            title: currentRouteName,
            initialPath,
            url,
            ...currentRoute?.params,
          });
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
      linking={linking}
      initialState={initialState}
      theme={theme}
    >
      {isLoadingComplete ? <RootStackNavigator /> : <View style={tailwind('bg-leaf-500 flex-grow')} />}
    </NavigationContainer>
  );
};
