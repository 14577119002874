import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Bug: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={26} height={26} viewBox={'0 0 26 26'} fill="none" {...props}>
      <Path
        d="M9 6.07V5a4 4 0 118 0v1.07M1 22.6a3.2 3.2 0 013.2-3.2h1.167M1 6.6a3.2 3.2 0 003.2 3.2h1.466"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        d="M1 14.6h4"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
      <Path
        d="M25 22.6a3.2 3.2 0 00-3.2-3.2h-1.167M25 6.6a3.2 3.2 0 01-3.2 3.2h-1.466"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
      <Path
        d="M25 14.6h-4M21 13a8 8 0 10-16 0v4a8 8 0 1016 0v-4z"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="square"
      />
    </Svg>
  );
};
