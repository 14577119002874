import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { IconButton } from '../Button/IconButton';
import { MagnifyingGlass, Profile } from '../icons';

/**
 * Common icon set to use in `endAdornment` of `TitleBar` for Tab screens (eg search icon, profile)
 */
export const TitleBarMainIcons: React.VFC = () => {
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();

  return (
    <>
      <IconButton
        accessibilityRole="search"
        accessibilityLabel="Search Icon"
        onPress={() => navigation.navigate('SearchModal')}
        icon={<MagnifyingGlass width={24} height={24} />}
        style={tailwind('pl-2 pr-4')}
      />
      <IconButton
        accessibilityRole="button"
        accessibilityLabel="Profile Icon"
        onPress={() => navigation.navigate('Menu')}
        icon={<Profile width={24} height={24} />}
      />
    </>
  );
};
