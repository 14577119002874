import { ReactNativeFile } from 'apollo-upload-client';

/**
 * Create file object that can be uploaded with apollo-upload client. Must be Blob on Web
 * Uses fetch's dataUri parser and then converts to binary blob data
 *
 * @param imageUri of file to be uploaded (expected dataUri in most instances)
 */
export const createImageFile = async (imageUri: string): Promise<ReactNativeFile | Blob> => {
  return new Blob([await (await fetch(imageUri)).blob()], { type: 'image/jpeg' });
};
