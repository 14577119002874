import { useNavigation } from '@react-navigation/core';
import _ from 'lodash';
import { VFC } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { HomeNavigationProps } from '..';
import { layout } from '../../../constants/layout';
import { GetHomeScreenDataQuery } from '../../../generated/graphql';
import { IdeaSnippetRow } from '../../../old/IdeaSnippetRow';
import { useTailwind } from '../../../theme';
import { DESKTOP_MAX_WIDTH } from '../../../ui/SafeAreaView';
import { Text } from '../../../ui/Text';
import { Unpack } from '../../../util/types';

// If desktop layout, use desktop max width instead
const IDEA_SNIPPET_WIDTH = Math.min(DESKTOP_MAX_WIDTH, layout.window.width) - 64;
const IDEA_SNIPPET_SPACING = 16;
type IdeaItem = Unpack<GetHomeScreenDataQuery['ideasPaginated']['nodes']>;

type Props = {
  title: string;
  data: IdeaItem[];
  emptyText: string;
};
export const IdeaSideScroll: VFC<Props> = ({ title, data, emptyText }) => {
  const tailwind = useTailwind();
  return (
    <>
      <Text style={[tailwind('text-sm font-medium px-6 pb-2'), !data.length && tailwind('flex-grow')]}>
        {title}
        {!!data.length && (
          <Text style={tailwind('text-warmGray-500')}>
            {'  '}
            {data.length}
          </Text>
        )}
      </Text>
      <FlatList
        horizontal
        contentContainerStyle={tailwind('px-5 py-2')}
        ItemSeparatorComponent={() => <View style={{ paddingLeft: IDEA_SNIPPET_SPACING }} />}
        showsHorizontalScrollIndicator={false}
        // Chunk to present 2 rows of data
        data={_.chunk(data, 2)}
        keyExtractor={keyExtractor}
        snapToInterval={IDEA_SNIPPET_WIDTH + IDEA_SNIPPET_SPACING}
        snapToAlignment="start"
        decelerationRate="fast"
        renderItem={renderItem}
        ListEmptyComponent={() => <EmptyComponent text={emptyText} />}
      />
    </>
  );
};

// Generate id for every cell in 2-row sideScroll.
let emptyCellId = 0;
const keyExtractor = ([idea0, idea1]: IdeaItem[]) => `a-${idea0?.id ?? ++emptyCellId}b-${idea1?.id ?? ++emptyCellId}`;

const renderItem: ListRenderItem<IdeaItem[]> = ({ item }) => <Item items={item} />;

const Item: VFC<{ items: IdeaItem[] }> = ({ items }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <View style={{ width: IDEA_SNIPPET_WIDTH }}>
      <IdeaSnippetRow
        onPress={({ ideaId }) => navigation.push('Idea', { ideaId })}
        bottomRightView="actualReturn"
        {...items[0]}
      />
      <View style={tailwind('py-2')} />
      {items[1] && (
        <IdeaSnippetRow
          onPress={({ ideaId }) => navigation.push('Idea', { ideaId })}
          bottomRightView="actualReturn"
          {...items[1]}
        />
      )}
    </View>
  );
};

const EmptyComponent: VFC<{ text: string }> = ({ text }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('pl-1 items-center justify-center')}>
      <Text style={tailwind('text-warmGray-400 text-sm text-center')}>{text}</Text>
    </View>
  );
};
