import { isNil } from 'lodash';
import React from 'react';
import { CoreInstrumentFieldsFragment, Maybe } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { getPercentageTextColor } from '../../util/color';
import { formatPercent } from '../../util/number';

export const TickerRow: React.FC<{ instruments: Maybe<CoreInstrumentFieldsFragment>[] }> = ({ instruments }) => {
  const tailwind = useTailwind();
  const displayedInstruments = instruments
    .map((instrument) => ({
      id: instrument?.id,
      ticker: instrument?.ticker,
      change: instrument?.quotePrice?.pctChange1d,
    }))
    .filter(tickerAndChangeDefined);

  if (displayedInstruments.length === 0) {
    return null;
  }
  return (
    <Text numberOfLines={1} ellipsizeMode="tail">
      {displayedInstruments
        .map((instrument) => (
          <TickerSnippet key={instrument.id} ticker={instrument.ticker} change={instrument.change} />
        ))
        .reduce((prev, curr) => (
          <>
            {prev}
            <Text style={tailwind('text-gray-400 text-xs font-medium')}> · </Text>
            {curr}
          </>
        ))}
    </Text>
  );
};

const TickerSnippet: React.FC<{ ticker: string; change: number }> = ({ ticker, change }) => {
  const tailwind = useTailwind();
  return (
    <Text style={tailwind(`${getPercentageTextColor(change)} text-xs font-medium`)}>
      {ticker}
      {SPACE}
      {formatPercent(change)}
    </Text>
  );
};

const tickerAndChangeDefined = (instrument: {
  id?: string;
  ticker?: Maybe<string>;
  change?: Maybe<number>;
}): instrument is { id: string; ticker: string; change: number } =>
  ![instrument.id, instrument.ticker, instrument.change].some(isNil);

const SPACE = ' ';
