/* eslint-disable graphql/template-strings */
import { gql } from '@apollo/client';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeScreenProps, useScrollToTop } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { FlatList, ListRenderItemInfo, RefreshControl, View } from 'react-native';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { GetWatchlistQuery, useGetWatchlistQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { WatchlistStackParamList } from '../../navigation/WatchlistNavigator';
import { Divider } from '../../old/Divider';
import { Eye } from '../../old/icons';
import { InstrumentNavigationWrapper } from '../../old/InstrumentNavigationWrapper';
import { InstrumentPriceRow, InstrumentPriceRowSkeleton } from '../../old/InstrumentPriceRow';
import { TitleBar } from '../../old/TitleBar';
import { TitleBarMainIcons } from '../../old/TitleBarMainIcons';
import { isTutorialCardDummy, TutorialCard, tutorialCardDummy, TutorialCardDummy } from '../../old/TutorialCard';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { SkeletonView } from '../../ui/Skeleton';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { TutorialCardPersistedStore, usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { EmptyWatchlist } from './EmptyWatchlist';

export type Props = CompositeScreenProps<
  NativeStackScreenProps<WatchlistStackParamList>,
  CompositeScreenProps<
    BottomTabScreenProps<TabsParamList, 'WatchlistTab'>,
    NativeStackScreenProps<LoggedInStackParamList>
  >
>;

type FlatListItem = InstrumentWatchlistItem | TutorialCardDummy;
type InstrumentWatchlistItem = GetWatchlistQuery['instrumentWatchlistItems'][0];

export const getWatchlist = gql`
  ${CORE_INSTRUMENT_FIELDS}
  query GetWatchlist($loggedIn: Boolean!) {
    instrumentWatchlistItems {
      id
      instrument {
        ...CoreInstrumentFields
      }
    }
  }
`;

export const Watchlist: React.FC<Props> = withReloadErrorBoundary<Props>(() => {
  const tailwind = useTailwind();
  const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const watchlistTutorialCardDismissed = useTutorialCardPersistedStore(watchlistTutorialDismissedSelector);
  const { data, error, refetch, loading } = useGetWatchlistQuery({
    variables: { loggedIn: isUserLoggedIn },
  });
  const { refreshing, onRefresh } = useRefresh(refetch);

  // scroll to top if press tab
  const ref = React.useRef<FlatList | null>(null);
  useScrollToTop(ref);

  if (error) {
    throw error;
  }

  // prepend tutorial card
  const flatListData =
    !watchlistTutorialCardDismissed && data?.instrumentWatchlistItems.length
      ? [tutorialCardDummy, ...(data?.instrumentWatchlistItems ?? [])]
      : data?.instrumentWatchlistItems;

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar title="Watchlist" showLogo hideBackButton endAdornment={<TitleBarMainIcons />} />
      <FlatList
        ref={ref}
        contentContainerStyle={tailwind('px-6 flex-grow')}
        ItemSeparatorComponent={Divider}
        data={flatListData}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
        ListEmptyComponent={loading ? <WatchlistSkeleton /> : EmptyWatchlist}
      />
    </SafeAreaView>
  );
});

const renderItem = ({ item }: ListRenderItemInfo<FlatListItem>) => {
  return <WatchlistItem item={item} />;
};

/**
 * render instrument row
 */
const WatchlistItem: React.FC<{ item: FlatListItem }> = ({ item }) => {
  const tailwind = useTailwind();
  if (isTutorialCardDummy(item)) {
    return (
      <TutorialCard
        title="Welcome to your Watchlist"
        onDismiss={() => useTutorialCardPersistedStore.setState({ watchlistTutorialDismissed: true })}
        Icon={Eye}
      >
        We{"'"}ve added a few stocks to get you started. Make it your own by adding some more.{'\n'}
        {'\n'}Tap the row to delve deeper into prices and insights.
      </TutorialCard>
    );
  }
  switch (item.__typename) {
    case 'InstrumentWatchlistItem':
      return (
        <InstrumentNavigationWrapper instrumentId={item.instrument?.id ?? ''} style={tailwind('py-4')}>
          <InstrumentPriceRow showWatchlistToggle={false} {...item.instrument} />
        </InstrumentNavigationWrapper>
      );
    default:
      return null;
  }
};

const keyExtractor = (item: FlatListItem) => `${isTutorialCardDummy(item) ? item.id : item.instrument?.id}`;

const watchlistTutorialDismissedSelector = (state: TutorialCardPersistedStore) => state.watchlistTutorialDismissed;

const WatchlistSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView>
      {new Array(20).fill(null).map((_, i) => (
        <View key={i} style={tailwind('border-b border-warmGray-200 py-4')}>
          <InstrumentPriceRowSkeleton />
        </View>
      ))}
    </SkeletonView>
  );
};
