import React from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { ErrorNaughtyDog } from '../icons';

export type Props = {
  title: string;
  actionButton: React.ReactNode;
  /** under big action button add optional text + link */
  subActionLink?: React.ReactNode;
  /** at footer add optional text + link */
  footerLink?: React.ReactNode;
};

/**
 * Common Error Screen with nice illustration
 */
export const ErrorScreen: React.VFC<Props> = ({ title, actionButton, subActionLink, footerLink }) => {
  const tailwind = useTailwind();
  return (
    <SafeAreaView>
      <View style={tailwind('px-6 pt-6 items-center')}>
        <Text style={tailwind('text-warmGray-800 text-2xl')}>{title}</Text>
      </View>
      <View style={tailwind('flex-1 px-6 items-center justify-evenly')}>
        <View>
          <ErrorNaughtyDog width={300} height={300} />
          {actionButton}

          {subActionLink && <View style={tailwind('flex-row pt-2 items-center justify-center')}>{subActionLink}</View>}
        </View>

        {footerLink && <View style={tailwind('pt-8')}>{footerLink}</View>}
      </View>
    </SafeAreaView>
  );
};
