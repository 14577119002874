import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const UHollow: React.VFC<SvgProps> = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="m17.927 18.373 4.731-10.058-5.157-2.263-4.452 9.466-.01.022c-.316.672-.736 1.567-1.419 2.125-.38.312-.848.53-1.408.573-.544.042-1.101-.086-1.668-.335-.566-.248-1.038-.572-1.371-1.005a2.385 2.385 0 0 1-.496-1.443c-.013-.874.41-1.772.723-2.439l.014-.028 4.43-9.418-5.158-2.263-4.753 10.106c-1.178 2.507-1.165 4.542-.38 6.185.804 1.68 2.503 3.137 5.071 4.263 2.565 1.125 4.832 1.41 6.693.883 1.828-.518 3.432-1.866 4.61-4.37Z"
      stroke="#0F766E"
      strokeWidth={2}
    />
  </Svg>
);
