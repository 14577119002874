import { useContext } from 'react';
import { BottomSheetContext } from '../providers/BottomSheetProvider';

/**
 * Hook returning present and dismiss handlers for the global bottom sheet.
 *
 * @returns `{present, dismiss}` handlers for bottom sheet. Present expects a component, with the bottom sheet
 * handling the snap point height to wrap the component.
 */
export const useBottomSheet = () => {
  return useContext(BottomSheetContext);
};
