import { gql, NetworkStatus } from '@apollo/client';
import { isNil } from 'lodash';
import { FlatList, ListRenderItemInfo, RefreshControl, View } from 'react-native';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import { CoreIdeaFieldsFragment, Maybe, useGetTrackedIdeasQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { IdeaFlatListItem } from '../../old/IdeaFlatListItem';
import { IdeasSkeleton } from '../../old/IdeasSkeleton';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const TRACKED_IDEAS = gql`
  ${CORE_IDEA_FIELDS}
  query getTrackedIdeas {
    ideaWatchlistItems {
      id
      idea {
        ...CoreIdeaFields
      }
    }
  }
`;
export const TrackedIdeas: React.VFC = () => {
  const tailwind = useTailwind();
  const { data, error, refetch, networkStatus } = useGetTrackedIdeasQuery();
  const { refreshing, onRefresh } = useRefresh(refetch);

  if (error) {
    throw error;
  }

  const ideas = (data?.ideaWatchlistItems ?? []).map((a) => a.idea).filter(ideaIsDefined);

  return networkStatus === NetworkStatus.loading ? (
    <IdeasSkeleton />
  ) : (
    <FlatList
      ListEmptyComponent={EmptyView}
      contentContainerStyle={tailwind('px-6 py-6')}
      data={ideas}
      renderItem={renderItem}
      refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      keyExtractor={keyExtractor}
    />
  );
};

const renderItem = ({ item }: ListRenderItemInfo<CoreIdeaFieldsFragment>) => <IdeaFlatListItem {...item} />;

const keyExtractor = (item: CoreIdeaFieldsFragment) => item.id;
const EmptyView: React.VFC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-1 items-center justify-center')}>
      <Text style={tailwind('text-warmGray-500 text-lg pb-2')}>No tracked Ideas</Text>
    </View>
  );
};
const ideaIsDefined = (idea: Maybe<CoreIdeaFieldsFragment> | undefined): idea is CoreIdeaFieldsFragment => !isNil(idea);
