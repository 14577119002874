import { gql } from '@apollo/client';
import { Platform } from 'react-native';
import { LogoutMutation, LogoutMutationVariables, Maybe, User, UserProfile } from '../generated/graphql';
import { usePersistedStore } from '../zustand/store';
import { analytics } from './analytics';
import { branch } from './branch';
import { getGraphqlClient } from './graphql';
import { setAccessToken, setRefreshToken } from './jwt';
import { onesignal } from './onesignal';
import { queryClient } from './react-query';
import { clearSentryUser } from './sentry';
import { clearAllSecureTokens } from './storage';

export const LOGOUT = gql`
  mutation logout {
    logout {
      error {
        message
      }
    }
  }
`;

/**
 * Logs out, clears apollo+react query caches, sets isUserLoggedIn state false etc
 */
export const logOut = async () => {
  console.log(`Logout called`);
  try {
    const { data } = await getGraphqlClient().mutate<LogoutMutation, LogoutMutationVariables>({ mutation: LOGOUT });
    if (data?.logout.error?.message) {
      console.error(`Error logging out: ${data?.logout.error?.message}`);
    }
  } finally {
    await logOutCleanUp();
  }
};

/**
 * After `logOut` called the server, this completes the cleanup: clears apollo+react query caches, sets isUserLoggedIn state false etc
 */
export const logOutCleanUp = async () => {
  console.log(`Clearing all secure tokens`);
  clearAllSecureTokens();
  console.log(`Setting isUserLoggedIn false`);
  // Set replace to true to ensure removal of all other state keys
  usePersistedStore.setState({ isUserLoggedIn: false }, true);

  // clear caches
  queryClient.clear();
  // use clearStore not resetStore to not refetch active queries
  await getGraphqlClient().clearStore();
  clearSentryUser();
  analytics.reset();
  branch.logout();
  onesignal.logout();
};

type SetLoginStateArgs = {
  /** as returned from login or sign up mutation */
  accessToken?: Maybe<string>;
  /**
   * As returned from login or sign up mutation.
   * Only stored in secure store for ios/android.
   * On web more secure to store in cookie on graphql domain (secure, http-only)
   */
  refreshToken?: Maybe<string>;
  /** user fields to store in persisted zustand store */
  user?: Maybe<Pick<User, 'id' | 'email' | 'isStaff'>>;
  /** user profile fields to store in persisted zustand store */
  profile?: Maybe<Pick<UserProfile, 'nickname'>>;
};

/**
 * Upon login or sign up, store access token and refresh token (not for web as uses a http only secure cookie on graphql domain instead).
 * Plus set zustand stores as logged in with correct fields
 *
 * To check dropped web cookie in chrome on graphql.upsidetechnology.co domain go here: chrome://settings/content/all?searchSubpage=upsidetechnology.co&search=cook
 */
export const setLoginState = async ({ accessToken, refreshToken, user, profile }: SetLoginStateArgs): Promise<void> => {
  console.log(`setLoginState: setting zustand logged in state, storing token`);

  if (!accessToken) {
    console.error(`Trying to set an empty accessToken`);
  }
  await setAccessToken(accessToken ?? '');

  if (Platform.OS !== 'web') {
    // dont store refresh token on web as more secure in a secure HTTP-only cookie
    // for ios/android store store in secure store though
    if (!refreshToken) {
      console.error(`Trying to set an empty refreshToken`);
    }
    await setRefreshToken(refreshToken ?? '');
  }

  analytics.track('Log in', null);
  console.log(`Now logged in`);

  usePersistedStore.setState({
    isUserLoggedIn: true,
    userId: user?.id ?? undefined,
    email: user?.email ?? undefined,
    isStaff: user?.isStaff ?? false,
    nickname: profile?.nickname ?? undefined,
  });
};
