import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export const TrendUp = (props: SvgProps) => {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Circle cx={18} cy={18} r={18} fill="#22C55E" />
      <Path d="M11 21l4-4 3 3 6-6" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M19 13h6v6" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
};
