import React from 'react';
import { View } from 'react-native';
import { Maybe } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Skeleton } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';

export type Props = {
  title: string;
  content?: Maybe<string | number>;
  currencyIso?: Maybe<string>;
};

export const InstrumentAboutBox: React.FC<Props> = ({ title, content }) => {
  const tailwind = useTailwind();
  return (
    <View accessibilityLabel={title} style={tailwind('flex-1')}>
      <Text style={tailwind('text-xs text-warmGray-600')}>{title}</Text>
      <Text style={tailwind('text-sm capitalize text-warmGray-800')}>{content}</Text>
    </View>
  );
};

export const InstrumentSummaryBoxSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-1')}>
      <Skeleton style={tailwind('h-3 w-8 mb-2')} />
      <Skeleton style={tailwind('h-6 w-16')} />
    </View>
  );
};
