import React from 'react';
import { StyleSheet } from 'react-native';
import { LongPressGestureHandler, PanGestureHandler, State } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';
import { Vector } from 'react-native-redash';
import { useTailwind } from '../../../theme';
import { useChart } from '../context/useChart';
import { CursorPoint } from '../CursorPoint';
import { AnimatedGhost } from '../Ghost';
import { Props, setPosition } from './common';

export const Cursor: React.FC<Props> = ({ currentPrice, displayPrice, dragging }) => {
  const tailwind = useTailwind();
  const { chartWidth, height, data } = useChart();
  const longPress = React.createRef<LongPressGestureHandler>();
  const pan = React.createRef<PanGestureHandler>();
  const position = useSharedValue<Vector>({ x: 0, y: 0 });
  const [pressed, setPressed] = React.useState(false);

  const pointColor = useDerivedValue(() => data.value.colors?.dot);
  const xHairStyles = useAnimatedStyle(() => ({
    transform: [{ translateX: position.value.x - 0.5 }],
  }));
  const cursorOpacity = useAnimatedStyle(() => ({
    opacity: dragging.value ? 1 : 0,
  }));
  const panGestureHandler = useAnimatedGestureHandler({
    onActive: (event, _ctx) => {
      dragging.value = true;
      if (data.value.ticks) {
        setPosition(
          data.value.prices,
          data.value.ticks.low,
          data.value.ticks.high,
          chartWidth,
          height.value,
          position,
          displayPrice,
          event.x,
        );
      }
    },
    onEnd: () => {
      dragging.value = false;
      runOnJS(setPressed)(false);
      displayPrice.value = currentPrice;
    },
  });
  return (
    <PanGestureHandler
      ref={pan}
      simultaneousHandlers={[longPress]}
      onGestureEvent={panGestureHandler}
      activeOffsetX={pressed ? [0, 0] : [-42, 42]}
    >
      <AnimatedGhost>
        <LongPressGestureHandler
          minDurationMs={200}
          ref={longPress}
          simultaneousHandlers={[pan]}
          onHandlerStateChange={(event) => {
            switch (event.nativeEvent.state) {
              case State.ACTIVE:
                setPressed(true);
                if (data.value.ticks) {
                  setPosition(
                    data.value.prices,
                    data.value.ticks.low,
                    data.value.ticks.high,
                    chartWidth,
                    height.value,
                    position,
                    displayPrice,
                    event.nativeEvent.x,
                  );
                }
                dragging.value = true;
                break;
              case State.END:
                dragging.value = false;
                displayPrice.value = currentPrice;
                setPressed(false);
                break;
            }
          }}
        >
          <Animated.View style={StyleSheet.absoluteFill}>
            <Animated.View style={[StyleSheet.absoluteFill, cursorOpacity]}>
              <Animated.View
                style={[
                  tailwind('h-full w-0 border-warmGray-500 opacity-30'),
                  { borderLeftWidth: 1, borderRadius: 1 },
                  xHairStyles,
                ]}
              />
              <CursorPoint position={position} color={pointColor} />
            </Animated.View>
          </Animated.View>
        </LongPressGestureHandler>
      </AnimatedGhost>
    </PanGestureHandler>
  );
};
