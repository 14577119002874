import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

export const Confetti = (props: SvgProps) => (
  <Svg width={360} height={228} viewBox="0 0 180 114" fill="none" {...props}>
    <G clipPath="url(#a)">
      <Path fill="#fff" d="M0 0h180v114H0z" />
      <G>
        <Path fill="#FFF1B3" d="M0 0h180v114H0z" />
      </G>
      <Path
        d="m51.501 69 7.717-4.673 1.102-14.019-8.819 1.558V69ZM31.66 6.692l7.716-4.673L40.478-12l-8.818 1.558V6.692Z"
        fill="#FAE8FF"
      />
      <Path
        d="m66.933 69 12.126-1.557 1.102-17.135-11.023 3.115L66.933 69ZM3 62.77l12.125-1.558 1.103-17.134-11.023 3.115L3 62.77Z"
        fill="#FBBF24"
      />
      <Path d="m69.138 37.847 11.023-4.673v-14.02H69.138v18.693Z" fill="#D946EF" />
      <Path
        d="m82.366 12.924 5.511-4.673 3.307-14.02-7.716 3.116-1.102 15.577ZM53.706 9.809l5.511-4.674 3.307-14.019-7.716 3.116-1.102 15.577Z"
        fill="#FAE8FF"
      />
      <Path d="M88.98 69h7.716l-2.205-9.346H88.98V69Z" fill="#3B82F6" />
      <Path
        d="m28.353 62.77 6.614 3.115 3.307-18.692h-9.921V62.77ZM93.389 40.962l6.614 3.115 3.306-18.692h-9.92v15.577ZM5.205 12.923l6.614 3.116 3.306-18.693h-9.92v15.577Z"
        fill="#EF4444"
      />
      <Path d="m5.205 12.923 6.614 3.116 3.306-18.693h-9.92v15.577Z" fill="#EF4444" />
      <Path
        d="m51.501 69 7.717-4.673 1.102-14.019-8.819 1.558V69ZM31.66 6.692l7.716-4.673L40.478-12l-8.818 1.558V6.692Z"
        fill="#FAE8FF"
      />
      <Path
        d="m66.933 69 12.126-1.557 1.102-17.135-11.023 3.115L66.933 69ZM3 62.77l12.125-1.558 1.103-17.134-11.023 3.115L3 62.77Z"
        fill="#FBBF24"
      />
      <Path d="m69.138 37.847 11.023-4.673v-14.02H69.138v18.693Z" fill="#D946EF" />
      <Path d="m82.366 12.924 5.511-4.673 3.307-14.02-7.716 3.116-1.102 15.577Z" fill="#FAE8FF" />
      <Path d="m53.706 9.809 5.511-4.674 3.307-14.019-7.716 3.116-1.102 15.577Z" fill="#EF4444" />
      <Path d="M88.98 69h7.716l-2.205-9.346H88.98V69Z" fill="#3B82F6" />
      <Path
        d="m93.389 40.962 6.614 3.115 3.306-18.692h-9.92v15.577ZM5.205 12.923l6.614 3.116 3.306-18.693h-9.92v15.577Z"
        fill="#EF4444"
      />
      <Path d="m5.205 12.923 6.614 3.116 3.306-18.693h-9.92v15.577Z" fill="#EF4444" />
      <Path
        d="m144.37-12 6.246 4.427.892 13.281-7.138-1.475V-12ZM128.307 47.029l6.247 4.427.892 13.281-7.139-1.476V47.03Z"
        fill="#FAE8FF"
      />
      <Path d="m156.862-12 9.816 1.476.892 16.233-8.923-2.952L156.862-12Z" fill="#FBBF24" />
      <Path d="m158.647 17.514 8.923 4.427v13.282h-8.923V17.514Z" fill="#D946EF" />
      <Path
        d="m169.355 41.125 4.462 4.427 2.677 13.282-6.247-2.952-.892-14.757ZM146.154 44.077l4.462 4.427 2.677 13.281-6.246-2.951-.893-14.757Z"
        fill="#FAE8FF"
      />
      <Path
        d="M140.8 20.466h6.247l-1.785 8.854H140.8v-8.854ZM174.709-12h6.246l-1.784 8.854h-4.462V-12ZM122.953 23.417h5.354l-1.529 17.708h-3.825V23.417Z"
        fill="#D946EF"
      />
      <Path
        d="m125.631-6.097 5.354-2.951 2.677 17.708h-8.031V-6.097ZM178.278 14.563l5.354-2.951 2.677 17.708h-8.031V14.563ZM106.891 41.126l5.354-2.952 2.677 17.709h-8.031V41.126Z"
        fill="#EF4444"
      />
      <Path d="m106.891 41.126 5.354-2.952 2.677 17.709h-8.031V41.126Z" fill="#EF4444" />
      <Path d="m144.37-12 6.246 4.427.892 13.281-7.138-1.475V-12Z" fill="#22C55E" />
      <Path d="m128.307 47.029 6.247 4.427.892 13.281-7.139-1.476V47.03Z" fill="#FAE8FF" />
      <Path
        d="m156.862-12 9.816 1.476.892 16.233-8.923-2.952L156.862-12ZM105.309-5.998l10.084.584.916 6.416-9.166-1.166-1.834-5.834Z"
        fill="#FBBF24"
      />
      <Path d="m158.647 17.514 8.923 4.427v13.282h-8.923V17.514Z" fill="#3B82F6" fillOpacity={0.69} />
      <Path d="m169.355 41.125 4.462 4.427 2.677 13.282-6.247-2.952-.892-14.757Z" fill="#22C55E" />
      <Path d="m146.154 44.077 4.462 4.427 2.677 13.281-6.246-2.951-.893-14.757Z" fill="#D946EF" />
      <Path d="M140.8 20.466h6.247l-1.785 8.854H140.8v-8.854Z" fill="#FBBF24" />
      <Path
        d="M174.709-12h6.246l-1.784 8.854h-4.462V-12ZM122.953 23.417h5.354l-1.529 17.708h-3.825V23.417Z"
        fill="#D946EF"
      />
      <Path
        d="m125.631-6.097 5.354-2.951 2.677 17.708h-8.031V-6.097ZM178.278 14.563l5.354-2.951 2.677 17.708h-8.031V14.563ZM106.891 41.126l5.354-2.952 2.677 17.709h-8.031V41.126Z"
        fill="#EF4444"
      />
      <Path d="m106.891 41.126 5.354-2.952 2.677 17.709h-8.031V41.126Z" fill="#EF4444" />
      <Path d="m3 35 7.875-2.5L12 25l-9 .833V35ZM3 112l6.125-3.25L10 99l-7 1.083V112Z" fill="#22C55E" />
      <Path d="m36.154 87.692 7.58-4.673L44.817 69l-8.663 1.558v17.134Z" fill="#FAE8FF" />
      <Path
        d="m70.805 150 11.91-1.557 1.084-17.135-10.829 3.115L70.805 150ZM8 143.77l11.911-1.558 1.083-17.134-10.828 3.115L8 143.77Z"
        fill="#FBBF24"
      />
      <Path d="m73 119 11-2.75V108H73v11Z" fill="#D946EF" />
      <Path
        d="m85.965 93.924 5.414-4.673 3.248-14.02-7.58 3.116-1.082 15.577ZM57.81 90.808l5.415-4.673 3.248-14.019-7.58 3.116-1.082 15.576Z"
        fill="#FAE8FF"
      />
      <Path d="M92.462 150h7.58l-2.166-9.346h-5.414V150Z" fill="#3B82F6" />
      <Path
        d="m32.905 143.77 6.497 3.115 3.249-18.692h-9.746v15.577ZM96.793 121.962l6.497 3.115 3.248-18.692h-9.745v15.577ZM10.166 93.923l6.497 3.116 3.248-18.693h-9.745v15.577Z"
        fill="#EF4444"
      />
      <Path d="m10.166 93.923 6.497 3.116 3.248-18.693h-9.745v15.577Z" fill="#EF4444" />
      <Path
        d="m56 137.692 7.58-4.673L64.663 119 56 120.558v17.134ZM36.154 87.692l7.58-4.673L44.817 69l-8.663 1.558v17.134Z"
        fill="#FAE8FF"
      />
      <Path
        d="m70.805 150 11.91-1.557 1.084-17.135-10.829 3.115L70.805 150ZM8 130.692l11.911-1.557L20.994 112l-10.828 3.115L8 130.692Z"
        fill="#FBBF24"
      />
      <Path d="m40 33 7-3.75V18h-7v15Z" fill="#D946EF" />
      <Path d="m85.965 93.924 5.414-4.673 3.248-14.02-7.58 3.116-1.082 15.577Z" fill="#FAE8FF" />
      <Path
        d="M188 64.346h7.58L193.414 55H188v9.346ZM23 16.346h7.58L28.414 7H23v9.346Z"
        fill="#3B82F6"
        fillOpacity={0.76}
      />
      <Path d="M51.503 107.173h7.58l-2.166-9.346h-5.414v9.346Z" fill="#FBBF24" />
      <Path d="M-1.58 91.5H6l-2.166-9.346H-1.58V91.5Z" fill="#3B82F6" fillOpacity={0.76} />
      <Path d="M188 10.346h7.58L193.414 1H188v9.346Z" fill="#FAE8FF" />
      <Path
        d="m35 134.577 6.497 3.115L44.746 119H35v15.577ZM96.793 121.962l6.497 3.115 3.248-18.692h-9.745v15.577ZM10.166 93.923l6.497 3.116 3.248-18.693h-9.745v15.577Z"
        fill="#EF4444"
      />
      <Path d="m10.166 93.923 6.497 3.116 3.248-18.693h-9.745v15.577Z" fill="#EF4444" />
      <Path
        d="m146.57 72.998 6.136 4.427.877 13.281-7.013-1.475V72.998ZM130.791 132.026l6.136 4.428.877 13.281-7.013-1.476v-16.233Z"
        fill="#FAE8FF"
      />
      <Path
        d="m158.842 72.998 9.642 1.475.877 16.233-8.766-2.951-1.753-14.757ZM108 78.9l9.642 1.476.877 16.233-8.766-2.952L108 78.9Z"
        fill="#FBBF24"
      />
      <Path d="m160.595 102.512 8.766 4.427v13.281h-8.766v-17.708Z" fill="#D946EF" />
      <Path
        d="m171.114 126.123 4.383 4.427 2.63 13.281-6.136-2.951-.877-14.757ZM148.323 129.074l4.383 4.428 2.63 13.281-6.136-2.951-.877-14.758Z"
        fill="#FAE8FF"
      />
      <Path
        d="M143.064 105.464h6.136l-1.754 8.854h-4.382v-8.854ZM176.374 72.998h6.136l-1.753 8.854h-4.383v-8.854ZM125.532 108.415h5.259l-1.502 17.708h-3.757v-17.708Z"
        fill="#D946EF"
      />
      <Path
        d="m128.162 78.9 5.259-2.95 2.63 17.708h-7.889V78.901ZM179.88 99.561l5.259-2.951 2.63 17.708h-7.889V99.561ZM109.753 126.124l5.26-2.952 2.629 17.709h-7.889v-14.757Z"
        fill="#EF4444"
      />
      <Path d="m109.753 126.124 5.26-2.952 2.629 17.709h-7.889v-14.757Z" fill="#EF4444" />
      <Path
        d="m146.57 72.998 6.136 4.427.877 13.281-7.013-1.475V72.998ZM20 24l6.136 4.427.877 13.282L20 40.233V24ZM130.791 132.026l6.136 4.428.877 13.281-7.013-1.476v-16.233Z"
        fill="#FAE8FF"
      />
      <Path
        d="m158.842 72.998 9.642 1.475.877 16.233-8.766-2.951-1.753-14.757ZM108 78.9l9.642 1.476.877 16.233-8.766-2.952L108 78.9Z"
        fill="#FBBF24"
      />
      <Path
        d="m160.595 102.512 8.766 4.427v13.281h-8.766v-17.708ZM26 90l8.766 4.427v13.282H26V90Z"
        fill="#3B82F6"
        fillOpacity={0.72}
      />
      <Path d="M143.064 105.464h6.136l-1.754 8.854h-4.382v-8.854Z" fill="#FBBF24" />
      <Path
        d="M176.374 72.998h6.136l-1.753 8.854h-4.383v-8.854ZM125.532 108.415h5.259l-1.502 17.708h-3.757v-17.708Z"
        fill="#D946EF"
      />
      <Path
        d="m128.162 78.9 5.259-2.95 2.63 17.708h-7.889V78.901ZM179.88 99.561l5.259-2.951 2.63 17.708h-7.889V99.561ZM109.753 126.124l5.26-2.952 2.629 17.709h-7.889v-14.757Z"
        fill="#EF4444"
      />
      <Path d="m109.753 126.124 5.26-2.952 2.629 17.709h-7.889v-14.757Z" fill="#EF4444" />
      <Path d="M139 57c0 25.957-21.043 47-47 47S45 82.957 45 57s21.043-47 47-47 47 21.043 47 47Z" fill="#64B950" />
      <Path
        d="M118.111 49.648 107.615 72.36c-5.176 11.204-14.678 13.105-25.607 8.223-10.931-4.88-15.726-13.166-10.55-24.37L81.954 33.5l14.194 6.34-9.883 21.388c-1.389 3.004-2.508 5.7 1.274 7.388 3.78 1.688 5.108-.913 6.496-3.918l9.883-21.389 14.194 6.339Z"
        fill="#fff"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h180v114H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
