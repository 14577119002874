import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { FeedCardFieldsFragment, MacdDirection } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { TrendDownFilled, TrendUp, Warning } from '../../icons';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';

export type Props = NarrowByTypename<FeedCardFieldsFragment, 'MacdFeedItem'>;

export const MacdCard: React.FC<Props> = ({ id, __typename, created, direction, instrument }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, instrument }}
        icon={<Warning />}
        eventTime={created ? new Date(created) : undefined}
      >
        Technical indicator
      </CardTitle>
      <CardHeadline icon={direction == MacdDirection.Long ? <TrendUp /> : <TrendDownFilled />}>
        <CardHeadlineText>
          {direction == MacdDirection.Short && (
            <Text style={tailwind('text-red-600 font-semibold')}>MACD bearish signal: </Text>
          )}
          {direction == MacdDirection.Long && (
            <Text style={tailwind('text-green-600 font-semibold')}>MACD bullish signal: </Text>
          )}
          <Link
            redirectIfLoggedOut
            onPress={() => {
              navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
              analytics.track('Feed card pressed', {
                'Feed card type': __typename,
                'Destination screen': 'Instrument',
                'Feed item id': id ?? '',
                'Instrument ticker': instrument?.ticker ?? '',
              });
            }}
          >
            {instrument?.name}
          </Link>{' '}
          moving average price crossed {direction == MacdDirection.Long ? 'above' : 'below'} signal. This indicates a
          potential price {direction == MacdDirection.Long ? 'upturn' : 'downturn'}.
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Feed card type': __typename,
            'Destination screen': 'Instrument',
            'Feed item id': id ?? '',
            'Instrument ticker': instrument?.ticker ?? '',
          });
        }}
      >
        <View>
          <InstrumentPriceRow {...instrument} />
        </View>
      </OutlineInnerCard>
    </Card>
  );
};
