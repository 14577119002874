import React from 'react';
import { TextProps as RNTextProps, TextInput } from 'react-native';

import Animated, { useAnimatedReaction } from 'react-native-reanimated';

Animated.addWhitelistedNativeProps({ text: true });

interface TextProps {
  text: Animated.SharedValue<string>;
  style?: Animated.AnimateProps<RNTextProps>['style'];
}

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

export const ReText = (props: TextProps) => {
  const { text, style } = props;
  const inputRef = React.useRef<TextInput>(null);

  useAnimatedReaction(
    () => {
      return text.value;
    },
    (data, prevData) => {
      if (inputRef.current && data !== prevData) {
        inputRef.current.setNativeProps({
          value: data,
        });
      }
    },
  );
  return (
    <AnimatedTextInput
      ref={inputRef}
      underlineColorAndroid="transparent"
      editable={false}
      value={text.value}
      style={style}
    />
  );
};
