import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Gauge = (props: SvgProps) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M2 0a1 1 0 00-1 1v2a1 1 0 002 0v-.949a11.942 11.942 0 016.741 2.795l-.67.67a1 1 0 101.414 1.414l.669-.67a11.939 11.939 0 012.795 6.741H13a1 1 0 100 2h2a1 1 0 001-1C16 6.28 9.72 0 2 0z"
        fill="#A3A3A3"
      />
      <Path
        d="M7.707 9.707a1 1 0 10-1.414-1.414L4.286 10.3A2.969 2.969 0 003 10a3 3 0 103 3c0-.462-.113-.895-.3-1.286l2.007-2.007z"
        fill="#A3A3A3"
      />
    </Svg>
  );
};
