import React from 'react';
import { CoreInstrumentFieldsFragment } from '../../generated/graphql';

/**
 * Context for stock selection screen
 * Items kept in sync with Zustand store
 */
export type StockSelectionContext = {
  selectedStocks: CoreInstrumentFieldsFragment[];
  addInstrument: (instrumentId: string) => void;
  removeInstrument: (instrumentId: string) => void;
  mostWatchlistedInstruments: CoreInstrumentFieldsFragment[];
};
export const StockSelectionContext = React.createContext<StockSelectionContext>({
  selectedStocks: [],
  addInstrument: () => undefined,
  removeInstrument: () => undefined,
  mostWatchlistedInstruments: [],
});

export const useStockSelection = () => React.useContext(StockSelectionContext);
