import { VFC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { Info } from '../icons/Info';

export type Props = {
  onPress: () => void;
  text: string;
};

/**
 * Link component with info icon. Commonly used to present 'Learn more' bottom sheets.
 */
export const InfoLink: VFC<Props> = ({ onPress, text }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      accessibilityRole="button"
      onPress={onPress}
      hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
      style={tailwind('flex-row')}
    >
      <View style={tailwind('pr-1 justify-center')}>
        <Info style={tailwind('h-3 w-3')} />
      </View>
      <Text style={tailwind('font-medium text-teal-800 text-xs')}>{text}</Text>
    </Pressable>
  );
};
