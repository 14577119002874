import * as React from 'react';
import Svg, { Circle, Path, SvgProps } from 'react-native-svg';

export function CorrelationPositiveFilled(props: SvgProps) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <Circle cx={18} cy={18} r={18} fill="#0F766E" />
      <Path d="M11 19l8-8M17 25l8-8" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
      <Path d="M19 14v-3h-3M25 20v-3h-3" stroke="#fff" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
}
