import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { colors } from '../../theme';

export const Logo: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={360} height={152} viewBox="0 0 360 152" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.758 35.322l-30.694 14.723 22.227 44.97 31.178-14.956-6.073-12.286-14.468 6.94-2.182-4.412 12.774-6.128-5.778-11.69-12.774 6.128-2.122-4.295 13.985-6.708-6.073-12.286zM90.285 81.845l12.422-26.939-16.8-7.518L74.21 72.757c-1.643 3.564-3.215 6.65-7.689 4.646-4.476-2.002-3.151-5.199-1.507-8.762l11.698-25.368-16.8-7.519-12.422 26.94c-6.127 13.288-.452 23.114 12.486 28.904 12.936 5.79 24.182 3.535 30.309-9.753zm39.66-20.717l-22.79-5.078-11.203 48.787 17.339 3.863 3.372-14.687 9.196 2.05c10.048 2.238 17.78-2.568 20.067-12.533 2.779-12.099-4.422-19.826-15.981-22.402zm-8.685 20.986l-1.773-.395 1.945-8.476 1.709.381c2.955.658 6.589 1.672 5.712 5.49-.906 3.947-4.441 3.703-7.593 3zm32.475 15.448l.912.766c3.194 2.65 7.079 4.863 11.318 5.032 1.817.072 4.739-.542 4.833-2.796.082-2.035-1.879-2.473-3.489-2.833h-.001c-.172-.038-.34-.076-.501-.114l-3.674-.81c-7.148-1.546-13.032-5.963-12.71-13.785.487-11.865 11.725-18.057 22.76-17.618 5.786.23 11.044 1.834 15.901 5.015l-5.504 12.595c-3.075-2.247-6.573-4.046-10.543-4.204-1.615-.065-3.995.438-4.078 2.493-.068 1.654 1.731 2.117 3.114 2.473.169.043.333.085.485.128l4.134 1.093c7.735 2.035 13.218 6.37 12.87 14.854-.489 11.931-11.766 17.392-22.734 16.955-6.391-.255-13.723-2.273-19.325-5.351l6.232-13.893zm59.543-33.29l-18.365 1.762 4.924 49.789 18.364-1.763-4.923-49.788zm7.346-.872l18.151-4.785c16.459-4.337 31.012-.55 35.833 17.204 3.899 14.358-3.909 26.167-18.287 29.957l-22.574 5.95L220.624 63.4zm27.016 29.143l1.105-.291c5.92-1.56 9.743-5.453 8.02-11.798-1.845-6.794-6.597-8.633-13.103-6.918l-1.105.291 5.083 18.716z"
        fill={props.color ?? colors.leaf[500]}
      />
    </Svg>
  );
};
