import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Feed = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M23 3H1v6h22V3zM1 15h22M1 21h22"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export const FeedFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M23 3H1v6h22V3z"
        fill="#CCFBF1"
        stroke="#0F766E"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M1 15h22M1 21h22"
        stroke="#0F766E"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
