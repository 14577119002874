import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useEffect, useRef, useState, VFC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import { CORE_PRE_PORTFOLIO_FIELDS, PRE_PORTFOLIO_SIMULATION_FIELDS } from '../../fragments/prePortfolio';
import { useGetSimulationsAndPrePortfolioQuery } from '../../generated/graphql';
import { useUpdatePrePortfolio } from '../../hooks/mutations/preportfolio';
import { useLogAndToastError } from '../../hooks/useLogAndToastError';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { isItemInView } from '../../old/EsgScores/util';
import { PortfolioBody } from '../../old/PortfolioBody/PortfolioBody';
import { RiskSlider } from '../../old/RiskSlider';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { WizardBodyText, WizardHeadingText } from '../../old/Wizard';
import { analytics } from '../../services/analytics';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Skeleton } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { isFiniteNumber } from '../../util/typeGuards';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';

export const GET_SIMULATIONS_AND_PREPORTFOLIO = gql`
  ${CORE_PRE_PORTFOLIO_FIELDS}
  ${PRE_PORTFOLIO_SIMULATION_FIELDS}
  query getSimulationsAndPrePortfolio($prePortfolioId: ID!) {
    prePortfolio {
      prePortfolio {
        ...CorePrePortfolioFields
      }
    }
    prePortfolioSimulations(prePortfolioId: $prePortfolioId) {
      simulations {
        ...CorePrePortfolioSimulationFields
      }
    }
  }
`;

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'PrePortfolioSelectRisk'>;
/**
 * WARNING: Every navigation event from SelectRisk MUST be navigation.replace() otherwise layout bugs could
 * occur due to layout animation container remaining mounted.
 */
export const PrePortfolioSelectRisk: VFC<Props> = withReloadErrorBoundary(({ route, navigation }) => {
  const tailwind = useTailwind();
  const nickname = usePersistedStore((state) => state.nickname);
  const [mutationPayloadError, setMutationPayloadError] = useState<Error | null>(null);
  const { prePortfolioId } = route.params;
  const toast = useToast();
  const [selectedRiskValue, setSelectedRiskValue] = useState(3);
  const [esgViewed, setEsgViewed] = useState(false);
  const [updatePrePortfolio, { loading: mutationLoading, error: mutationError }] = useUpdatePrePortfolio();
  const {
    data,
    loading,
    error: queryError,
  } = useGetSimulationsAndPrePortfolioQuery({
    variables: { prePortfolioId },
  });
  const [notional, setNotional] = useState<number | null>(null);
  // Required to prevent text input from rendering until notional controlled input state populated
  const [renderBody, setRenderBody] = useState(false);

  useEffect(() => {
    if (data) {
      setNotional(data?.prePortfolio?.prePortfolio?.notional ?? 1000);
      setRenderBody(true);
    }
  }, [data]);

  const prePortfolio = data?.prePortfolio.prePortfolio;
  const selectedSimulation = data?.prePortfolioSimulations?.simulations.find((i) => i.riskLevel === selectedRiskValue);

  // If mutation errors, log and toast the error.
  const mutationSubmissionError = mutationError ?? mutationPayloadError;
  useLogAndToastError(mutationSubmissionError);

  // If query or selectedSimulation error, throw and present error boundary
  const selectedSimulationError = selectedSimulation?.stats.error
    ? createValidationError(selectedSimulation.stats.error)
    : undefined;
  const throwableError = selectedSimulationError || queryError;

  if (throwableError) {
    throw throwableError;
  }

  const onFinish = async () => {
    const result = await updatePrePortfolio({
      variables: {
        prePortfolioId: prePortfolioId,
        members: selectedSimulation?.members.map((m) => ({
          instrumentId: m.instrument?.id ?? '',
          weight: m.weight ?? 0,
        })),
        selectedRiskLevel: selectedRiskValue,
        notional,
        cashProportion: 0,
        built: true,
      },
    });

    const payloadError = result.data?.updatePrePortfolio.error;

    if (payloadError) {
      const error = createValidationError(payloadError);
      setMutationPayloadError(error);
    } else {
      analytics.track('Portfolio Blueprint built', {
        nickname,
        riskLevel: selectedRiskValue,
        notional,
        'Member tickers': selectedSimulation?.members?.map((m) => m.instrument?.ticker ?? '') ?? [],
        cashProportion: prePortfolio?.cashProportion,
      });
      // Reset so Portfolio screens no longer in stack once flow exited
      navigation.reset({ routes: [{ name: 'Tabs', state: { routes: [{ name: 'PortfolioTab' }] } }] });
      toast.show('Portfolio Blueprint saved.');
    }
  };

  const esgRef = useRef<View>(null);

  const isValidNotional = isFiniteNumber(notional) && notional > 0;
  const isFinishDisabled = !isValidNotional || mutationLoading || !(prePortfolio && data?.prePortfolioSimulations);

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar
        endAdornment={
          <Button
            text="Finish"
            label="Small finish button"
            size="sm"
            onPress={onFinish}
            isDisabled={isFinishDisabled}
            loading={mutationLoading}
          />
        }
      />

      <ScrollView
        stickyHeaderIndices={[1]}
        contentContainerStyle={tailwind('flex-grow py-4')}
        scrollEventThrottle={500}
        onScroll={() => {
          isItemInView(esgRef, 0.3, () => {
            if (!esgViewed) {
              setEsgViewed(true);
            }
          });
        }}
      >
        <ScreenSidePadding>
          <WizardHeadingText>Select your optimal blueprint</WizardHeadingText>
          <WizardBodyText style={tailwind('text-base pt-2 pb-8')}>
            Adjust the risk preference and see your optimal portfolio blueprint. Tap finish when you&apos;re happy
          </WizardBodyText>
        </ScreenSidePadding>
        <View style={tailwind('bg-white')}>
          <View style={tailwind('mx-8')}>
            <RiskSlider onChange={(v) => setSelectedRiskValue(v)} value={selectedRiskValue} />
          </View>
          <View style={tailwind('flex-row justify-between border-b pb-4 border-warmGray-200 px-2')}>
            <Text style={tailwind('text-warmGray-400')}>Lower Risk</Text>
            <Text style={tailwind('text-warmGray-400')}>Higher Risk</Text>
          </View>
        </View>
        {/* Render null to work around reanimated overlapping skeleton and layout animation bug https://github.com/software-mansion/react-native-reanimated/issues/3124 */}
        {loading || !renderBody ? null : (
          <>
            <PortfolioBody
              onEdit={() => {
                navigation.replace('PrePortfolioSelectIdeas');
              }}
              members={selectedSimulation?.members}
              statsPayload={selectedSimulation?.stats}
              simulationId={selectedSimulation?.id}
              prePortfolio={prePortfolio}
              esgViewed={esgViewed}
              ref={esgRef}
              notional={notional}
              onChangeNotional={(value) => setNotional(value)}
            />

            <ScreenSidePadding>
              <View style={tailwind('pb-1 pt-2')}>
                <Button text="Finish" isDisabled={isFinishDisabled} loading={mutationLoading} onPress={onFinish} />
              </View>
            </ScreenSidePadding>
          </>
        )}
      </ScrollView>
    </SafeAreaView>
  );
});

export const PortfolioSelectRiskSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View>
      {new Array(10).fill(null).map((_, i) => (
        <View key={i} style={tailwind('py-2')}>
          <PortfolioBreakdownRowSkeleton />
        </View>
      ))}
    </View>
  );
};

export const PortfolioBreakdownRowSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row items-center')}>
      <Skeleton style={tailwind('h-11 w-11 rounded-lg')} />
      <View style={tailwind('flex-1 justify-center pl-2')}>
        <Skeleton style={tailwind('h-4 w-32 mb-2')} />
        <Skeleton style={tailwind('h-3 w-11')} />
      </View>
      <View style={tailwind('items-end')}>
        <Skeleton style={tailwind('h-4 w-14 mb-2')} />
        <Skeleton style={tailwind('h-3 w-11')} />
      </View>
    </View>
  );
};
