import currencyToSymbolMap from 'currency-symbol-map';
import numbro from 'numbro';

/**
 * @param number
 * @param decimalPlaces 2 dp by default
 */
export function formatNumber(number: number, decimalPlaces = 2, trimMantissa = false, forceSign = false): string {
  return numbro(number ?? undefined).format({
    thousandSeparated: true,
    mantissa: decimalPlaces,
    trimMantissa,
    forceSign,
  });
}

/**
 * Formats a percent fraction to a percent string
 *
 * @param percent number usually between 0 and 1
 * @param decimalPlaces 2 dp by default
 * @param forceSign adds +/- sign, true by default
 */
export function formatPercent(percent: number, decimalPlaces = 2, forceSign = true, trimMantissa = false): string {
  return numbro(percent).format({
    output: 'percent',
    mantissa: decimalPlaces,
    thousandSeparated: true,
    forceSign: forceSign,
    trimMantissa,
  });
}

/**
 * formats price to 2dp with correct currency symbol, or no symbol if currency not provided (e.g indices)
 *
 * @param price
 * @param currencyIso
 * @param forceSign adds +/- sign, false by default
 * @param average
 */
export const formatPrice = (price: number, currencyIso?: string, options?: numbro.Format): string =>
  numbro(price).format({
    output: currencyIso ? 'currency' : 'number',
    currencySymbol: currencyIso ? currencyToSymbolMap(currencyIso) : '',
    currencyPosition: 'prefix',
    forceSign: false,
    average: false,
    mantissa: 2,
    thousandSeparated: true,
    ...options,
  });

export const round = (x: number, decimalPlaces = 2): number => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(x * factor) / factor;
};

export const getPercentageDiff = (oldValue: number, newValue: number) => {
  if (oldValue == 0) {
    console.error(
      `calculating percentage difference between ${oldValue} and ${newValue} would have resulted in a division by zero error`,
    );
    return 0;
  }

  return (newValue - oldValue) / Math.abs(oldValue);
};

/**
 *
 * @param text A number, can be comma separated
 * @returns the parsed number. If text is not a valid number, returns NaN
 */
export const textToNumber = (text: string): number => numbro.unformat(text);
