import React from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const EmptyState: React.VFC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('justify-center flex-grow')}>
      <Text style={tailwind('font-semibold mb-1 text-center')}>Unable to retrieve instrument financials.</Text>
      <Text style={tailwind('mb-1 text-center text-warmGray-400')}>
        Come back after the company{"'"}s next earnings report.
      </Text>
    </View>
  );
};
