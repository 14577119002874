import { useNavigation } from '@react-navigation/native';
import { FC, useCallback } from 'react';
import { colors } from '../../../theme';
import { HomeNavigationProps } from '../Home';
import { TradingOnboardingState } from '../util';
import { OnboardingCard } from './OnboardingCard';

type Props = {
  width: number;
};

export const WatchlistOnboardingCard: FC<Props> = ({ width }) => {
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <OnboardingCard
      body={'Track your favourite stocks by adding them to your Watchlist.'}
      color={colors.pinkRed}
      width={width}
      title="Build your Watchlist"
      onPress={() => navigation.navigate('Tabs', { screen: 'DiscoverTab', params: { screen: 'Discover' } })}
    ></OnboardingCard>
  );
};
export const IdeaOnboardingCard: FC<Props> = ({ width }) => {
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <OnboardingCard
      body="Have a view on a stock? Create an Idea on a stock in your Watchlist."
      color={colors.skyBlue}
      width={width}
      title="Create an Idea"
      onPress={() => navigation.navigate('WatchlistTab', { screen: 'Watchlist' })}
    ></OnboardingCard>
  );
};

export const PortfolioOnboardingCard: FC<Props> = ({ width }) => {
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <OnboardingCard
      title="Build your Portfolio Blueprint"
      body="Let us help you build a personalised Portfolio Blueprint from your Ideas."
      color={colors.deepGreen}
      width={width}
      onPress={() => navigation.navigate('Tabs', { screen: 'BlueprintTab', params: { screen: 'Blueprint' } })}
    ></OnboardingCard>
  );
};

export const SetupTradingOnboardingCard: FC<Props> = ({ width }) => {
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <OnboardingCard
      title="1. Set up trading account"
      body="You're almost ready. Create your trading account."
      width={width}
      color={colors.skyBlue}
      onPress={() => navigation.navigate('TradingAccountPersonalDetails')}
    />
  );
};

export const ResumeTradingOnboardingCard: FC<
  Props & { state: Extract<TradingOnboardingState, 'NEEDS_BANK_ACCOUNT' | 'NEEDS_ADDRESS' | 'NEEDS_SUBMISSION'> }
> = ({ width, state }) => {
  const navigation = useNavigation<HomeNavigationProps>();

  const onPress = useCallback(() => {
    switch (state) {
      case 'NEEDS_BANK_ACCOUNT':
        return navigation.navigate('TradingAccountBankDetails');
      case 'NEEDS_ADDRESS':
        return navigation.navigate('TradingAccountAddressDetails');
      case 'NEEDS_SUBMISSION':
        return navigation.navigate('TradingAccountSubmit');
    }
  }, [state, navigation]);

  return (
    <OnboardingCard
      title="1. Set up trading account"
      body="You're almost there. Continue where you left off."
      width={width}
      color={colors.skyBlue}
      onPress={onPress}
    />
  );
};

export const KYCPendingOnboardingCard: FC<Props> = ({ width }) => {
  return (
    <OnboardingCard
      title="1. Set up trading account"
      body="Under review. We'll let you know when review is complete."
      label="UNDER REVIEW"
      width={width}
      color={colors.skyBlue}
      // Disable press for this card
      onPress={() => undefined}
    />
  );
};

export const KYCFailedOnboardingCard: FC<Props> = ({ width }) => {
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <OnboardingCard
      title="1. Set up trading account"
      body="Your details didn't pass our checks. Please re-enter your details."
      label="REVIEW FAILED"
      width={width}
      color={colors.skyBlue}
      onPress={() => navigation.navigate('TradingAccountPersonalDetails')}
    />
  );
};

export function getTradingOnboardingCard(
  state: Exclude<TradingOnboardingState, 'COMPLETE'>,
  width: number,
): React.ReactNode {
  switch (state) {
    case 'NEEDS_PARTY':
      return <SetupTradingOnboardingCard width={width} />;
    case 'NEEDS_BANK_ACCOUNT':
    case 'NEEDS_ADDRESS':
    case 'NEEDS_SUBMISSION':
      return <ResumeTradingOnboardingCard width={width} state={state} />;
    case 'KYC_PENDING':
      return <KYCPendingOnboardingCard width={width} />;
    case 'KYC_FAILED':
      return <KYCFailedOnboardingCard width={width} />;
  }
}

export const PortfolioIntendedOnboardingCard: FC<Props> = ({ width }) => {
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <OnboardingCard
      title="Portfolio"
      body="Set up your portfolio intended."
      width={width}
      color={colors.deepGreen}
      onPress={() => navigation.navigate('OnboardingAboutYou')}
    />
  );
};
