import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Discover = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM22 22l-6-6M6 10a4 4 0 014-4"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export const DiscoverFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M10 18a8 8 0 100-16 8 8 0 000 16z"
        fill="#CCFBF1"
        stroke="#0F766E"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M22 22l-6-6M6 10a4 4 0 014-4"
        stroke="#0F766E"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
