import getSymbolFromCurrency from 'currency-symbol-map';
import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import Animated, { useAnimatedStyle, useDerivedValue, withSpring } from 'react-native-reanimated';
import { useTailwind } from '../../theme';
import { MONTHS } from '../../util/date';
import { TimeInterval, timeIntervals } from '../../util/string';
import { PriceDifference } from '../PriceDifference';
import { ReText } from '../ReText';

type Props = {
  currencyCode?: string;
  price: Animated.SharedValue<[number, number]>;
  dragging: Animated.SharedValue<boolean>;
  selectedInterval: Animated.SharedValue<number>;
  basePrice: Animated.SharedValue<[number, number] | undefined>;
  style?: ViewStyle;
};

export const Header: React.VFC<Props> = ({ currencyCode, price, basePrice, selectedInterval, dragging, style }) => {
  const tailwind = useTailwind();
  const styles = {
    price: tailwind('text-warmGray-800 text-3xl font-normal'),
    diff: tailwind('text-sm font-normal'),
    date: tailwind('text-sm text-warmGray-500 font-normal'),
    timeframe: tailwind('text-sm text-warmGray-500 font-normal'),
  };
  const currencySymbol = currencyCode ? getSymbolFromCurrency(currencyCode) : '';
  const timeFrame = useDerivedValue(() => getTimeIntervalText(timeIntervals[selectedInterval.value]));
  const formattedPrice = useDerivedValue(() => `${currencySymbol}${numberWithCommas(price.value[1].toFixed(2))}`);
  const formattedDate = useDerivedValue(() => formatDate(price.value[0], timeIntervals[selectedInterval.value]));
  const transition = useDerivedValue(() => {
    return withSpring(dragging.value ? 1 : 0);
  });
  const timeIntervalStyle = useAnimatedStyle(() => {
    return {
      opacity: 1 - transition.value,
      transform: [
        {
          translateX: transition.value * 100,
        },
      ],
    };
  });
  const dateStyle = useAnimatedStyle(() => {
    return {
      opacity: transition.value,
      transform: [
        {
          translateX: (transition.value - 1) * 100,
        },
      ],
    };
  });
  return (
    <View style={style}>
      <ReText style={styles.price} text={formattedPrice} />
      <PriceDifference price={price} basePrice={basePrice} currencyIso={currencyCode} />
      <View>
        <Animated.View style={timeIntervalStyle}>
          <ReText style={styles.timeframe} text={timeFrame} />
        </Animated.View>
        <Animated.View style={[StyleSheet.absoluteFill, dateStyle]}>
          <ReText style={styles.date} text={formattedDate} />
        </Animated.View>
      </View>
    </View>
  );
};

const numberWithCommas = (num: string): string => {
  'worklet';
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const formatDate = (date: number, _timeInterval: TimeInterval): string => {
  'worklet';
  const d = new Date(date);
  return `${d.getDate()} ${MONTHS[d.getMonth()]} ${d.getFullYear()}`;
};
/** For price chart, nice text of what the graph is showing */
const getTimeIntervalText = (timeInterval: TimeInterval): string => {
  'worklet';
  switch (timeInterval) {
    case '7D':
      return 'Last week';
    case '1M':
      return 'Last month';
    case '3M':
      return 'Last 3 months';
    case '1Y':
      return 'Last year';
    case '5Y':
      return 'Last 5 years';
  }
};
