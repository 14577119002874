import React, { useEffect } from 'react';
import { Platform, View } from 'react-native';
import Animated, {
  measure,
  runOnUI,
  useAnimatedRef,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { ChevronRight } from '../icons/ChevronRight';

export type Props = {
  collapsed?: boolean;
  header: React.ReactElement | null;
  onPress: () => void;
};

export const Collapsable: React.FC<Props> = ({ children, header, onPress, collapsed }) => {
  const tailwind = useTailwind();
  const aRef = useAnimatedRef<View>();

  const toggle = useSharedValue(0);
  const rotation = useDerivedValue(() => toggle.value * 90);
  const height = useSharedValue(0);

  useEffect(() => {
    toggle.value = withTiming(collapsed ? 0 : 1, { duration: 200 });
  }, [collapsed, toggle]);

  const chevronStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    };
  });

  const contentStyles = useAnimatedStyle(() => {
    return {
      opacity: toggle.value,
      height: 1 + height.value * toggle.value, // height must initially be non-zero in order for it to be measured
    };
  });

  return (
    <>
      <Pressable
        accessibilityRole="button"
        style={tailwind('flex-row items-center')}
        onPress={() => {
          if (height.value === 0) {
            if (Platform.OS === 'web') {
              aRef.current?.measure((_x, _y, _w, h) => {
                height.value = h;
              });
            } else {
              runOnUI(() => {
                'worklet';
                height.value = measure(aRef).height;
              })();
            }
          }
          onPress();
        }}
      >
        <Animated.View style={[tailwind('mr-2'), chevronStyles]}>
          <ChevronRight />
        </Animated.View>
        {header}
      </Pressable>
      <Animated.View style={contentStyles}>
        <View ref={aRef} style={tailwind('overflow-hidden')}>
          {children}
        </View>
      </Animated.View>
    </>
  );
};
