import { capitalize } from 'lodash';
import { VFC } from 'react';
import { View } from 'react-native';
import { IdeaStatus, Maybe } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Chip } from '../Chip';

const FILTER_VALUES = ['Active', 'Completed'] as const;
export type FilterValue = typeof FILTER_VALUES[number];
type FilterValueToInputMap = Record<FilterValue, Maybe<Array<IdeaStatus> | IdeaStatus>>;

export const filterValueToInputMap: FilterValueToInputMap = {
  Active: IdeaStatus.Active,
  Completed: [IdeaStatus.Evaluated, IdeaStatus.Expired, IdeaStatus.Closed],
};

export type Props = {
  value: FilterValue;
  onChange: (v: FilterValue) => void;
};

export const IdeaFilters: VFC<Props> = ({ value, onChange }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('px-4 flex-row justify-center')}>
      {FILTER_VALUES.map((item) => {
        return (
          <View key={item} style={tailwind('px-2')}>
            <Chip size={'sm'} selected={item === value} text={capitalize(item)} onPress={() => onChange(item)} />
          </View>
        );
      })}
    </View>
  );
};
