import { gql } from '@apollo/client';
import {
  BottomSheetFlatList,
  BottomSheetFlatListMethods,
  BottomSheetView,
  useBottomSheetInternal,
} from '@gorhom/bottom-sheet';
import { useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { isNumber } from 'lodash';
import React from 'react';
import { Platform } from 'react-native';
import { useAnimatedStyle } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { CORE_COMMENT_FIELDS } from '../../fragments/comments';
import { CORE_PAGINATION_FIELDS } from '../../fragments/pageInfo';
import { useGetRepliesQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { Comment, EmptyComments } from '../../old/Comment';
import { CommentSkeleton } from '../../old/Comment/Comment';
import { Divider } from '../../old/Divider';
import { useTailwind } from '../../theme';
import { isFiniteNumber } from '../../util/typeGuards';
import { CommentsParamList } from './CommentSheet';
import { Header, HEIGHT as HEADER_HEIGHT } from './Header';
import { InputArea } from './Input';
import { CommentItem, renderComment } from './renderComment';
import { CommentsSkeleton } from './Skeleton';
import { useCommentSheet } from './useCommentSheet';

/* eslint-disable graphql/template-strings */
export const getReplies = gql`
  ${CORE_COMMENT_FIELDS}
  ${CORE_PAGINATION_FIELDS}
  query getReplies($parentCommentId: ID!, $ideaId: ID!) {
    getComment(commentInput: { commentId: $parentCommentId }) {
      comment {
        ...CoreCommentFields
        repliesPaginated {
          nodes {
            ...CoreCommentFields
          }
        }
      }
    }
  }
`;

export const Replies: React.VFC<NativeStackScreenProps<CommentsParamList, 'Replies'>> = ({ route }) => {
  const tailwind = useTailwind();
  const { parentCommentId, replyId, forceRefetch, entityId } = route.params;
  const listRef = React.useRef<BottomSheetFlatListMethods>(null);
  const { showCommentInput, showUserProfile, showIdea } = useCommentSheet();
  const { bottom: bottomSafeArea } = useSafeAreaInsets();
  const { data, refetch, loading } = useGetRepliesQuery({
    variables: {
      parentCommentId,
      ideaId: entityId, // assuming for now all comment bottom sheets are on an Idea screen, hence entityId is ideaId for GQL query
    },
  });
  React.useEffect(() => {
    const displayedCount = data?.getComment?.comment?.repliesPaginated?.nodes.length;
    const actualCount = data?.getComment?.comment?.replyCount;
    if (isFiniteNumber(actualCount) && isFiniteNumber(displayedCount) && actualCount > displayedCount) {
      refetch();
    }
  }, [refetch, data]);

  React.useEffect(() => {
    const replyIndex = data?.getComment?.comment?.repliesPaginated?.nodes?.findIndex((r) => r.id === replyId);
    if (isNumber(replyIndex) && replyIndex >= 0) {
      listRef.current?.scrollToIndex({ index: replyIndex, animated: true });
    }
  }, [data, replyId]);

  const bottomSheetInternal = useBottomSheetInternal();
  const { refreshing, onRefresh } = useRefresh(refetch);

  // if deeplinking to here, user might have previously seen page, so the comments will be cached and stale.
  // Hence force re-fetch upon focus
  useFocusEffect(
    React.useCallback(() => {
      if (forceRefetch) {
        console.log(`Forcing refetch on focus as forceRefetch route param passed`);
        refetch();
      }
    }, [forceRefetch, refetch]),
  );
  const comment = data?.getComment?.comment;
  const activeIdea = comment?.user?.ideas?.connection?.nodes?.[0];

  const replies: CommentItem[] =
    comment?.repliesPaginated?.nodes?.map((r) => ({
      ...r,
      onUserProfilePress: showUserProfile,
      onFlairPress: showIdea,
      showRepliesLink: false,
    })) ?? [];

  const listStyles = useAnimatedStyle(
    () => ({
      height: bottomSheetInternal.animatedContentHeight.value - HEADER_HEIGHT,
    }),
    [bottomSheetInternal],
  );

  return (
    <BottomSheetView pointerEvents="box-none" style={Platform.OS !== 'web' ? { flex: 1 } : undefined}>
      <Header type="Replies" count={data?.getComment?.comment?.replyCount} />
      <BottomSheetFlatList
        ref={listRef}
        style={Platform.OS === 'web' ? listStyles : undefined}
        contentContainerStyle={{ paddingBottom: bottomSafeArea }}
        focusHook={useFocusEffect}
        data={replies}
        onScrollToIndexFailed={() => {
          listRef.current?.scrollToEnd({ animated: true });
        }}
        renderItem={renderComment}
        keyExtractor={(item) => item.id}
        ListEmptyComponent={
          loading ? (
            <>
              <CommentSkeleton />
              <Divider />
              <CommentsSkeleton />
            </>
          ) : (
            <EmptyComments commentType="replies" />
          )
        }
        ListHeaderComponent={
          comment && (
            <>
              <Comment
                text={comment.text}
                user={comment.user}
                created={comment.created}
                style={tailwind('bg-warmGray-100')}
                onUserProfilePress={() => showUserProfile(comment.user?.id ?? 0)}
                activeIdea={activeIdea}
                onFlairPress={() => showIdea(activeIdea?.id ?? '')}
                showRepliesLink={false}
              />
              <Divider />
              <InputArea type="reply" onPress={() => showCommentInput(parentCommentId)} />
            </>
          )
        }
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    </BottomSheetView>
  );
};
