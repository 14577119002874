import { Slider } from '@miblanchard/react-native-slider';
import React from 'react';
import { View } from 'react-native';
import { colors, useTailwind } from '../../theme';

type Props = {
  onChange: (value: number) => void;
  /**
   * A number between
   */
  value: number;
};

/**
 * Controlled risk slider component. Values between 1 and 7
 *
 * @param param0 Value, onChange
 */
export const RiskSlider: React.VFC<Props> = ({ value, onChange }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('-mr-3')}>
      <Slider
        value={value}
        onValueChange={(value) => onChange(Number(value))}
        minimumTrackTintColor={colors.teal[700]}
        thumbStyle={tailwind('bg-teal-700 h-5 w-5 rounded-full')}
        trackMarks={[1, 2, 3, 4, 5, 6, 7]}
        /* marginRight set to ensure last trackMark is centered on end of track. Library seems to
        leave space equivalent to ~thumbStyleWidth/2 between end of track and last trackMark.
        Before marginRight
        *---*---*---*-
        After marginRight
        *---*---*---*
         */
        trackStyle={tailwind('bg-warmGray-300 h-0.5 mr-3')}
        renderTrackMarkComponent={(index) => <View style={tailwind(getTrackMarkStyle(value, index))} />}
        maximumValue={7}
        minimumValue={1}
        step={1}
      />
    </View>
  );
};

const getTrackMarkStyle = (value: number, index: number) => {
  if (index + 1 < value) {
    return 'rounded-full h-2 w-2  bg-teal-700';
  } else if (value === index + 1) {
    return 'rounded-full h-4 w-4 bg-teal-700';
  } else {
    return 'rounded-full h-2 w-2 bg-warmGray-300';
  }
};
