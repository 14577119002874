import React from 'react';
import { ChartContext } from './ChartContext';

export const useChart = () => {
  const context = React.useContext(ChartContext);

  if (context === null) {
    throw new Error("'useChart' cannot be used out of the ChartProvider");
  }

  return context;
};
