import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import { INSTRUMENT_FINANCIALS } from '../../fragments/instrument';
import { GetInstrumentFinancialsQuery, useGetInstrumentFinancialsQuery } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { SegmentedControl } from '../../old/SegmentedControl';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { isNotNull } from '../../util/typeGuards';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { EmptyState } from './EmptyState';
import { FinancialsTable } from './FinancialsTable';
import { getCurrentAndPreviousFinancials } from './util';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'InstrumentFinancials'>;

export const getInstrumentFinancials = gql`
  ${INSTRUMENT_FINANCIALS}
  query getInstrumentFinancials($id: ID!) {
    instrument(id: $id) {
      id
      ticker
      companyInfo
      yearlyFinancials {
        ...InstrumentFinancials
      }
      quarterlyFinancials {
        ...InstrumentFinancials
      }
    }
  }
`;

const segments = ['Annual', 'Quarterly'] as const;
type Segment = typeof segments[number];

export const InstrumentFinancials: React.FC<Props> = withReloadErrorBoundary(
  ({
    navigation,
    route: {
      params: { instrumentId, selectedIndex = 0 },
    },
  }) => {
    const tailwind = useTailwind();
    const { data, loading, error } = useGetInstrumentFinancialsQuery({ variables: { id: instrumentId } });

    if (error) {
      throw error;
    }

    return (
      <SafeAreaView style={tailwind('flex-1')}>
        <TitleBar title={data?.instrument.ticker} />
        <ScreenSidePadding style={tailwind('flex-1')}>
          <Text style={tailwind('text-warmGray-600 font-semibold text-lg py-3')}>Financials</Text>
          {loading ? (
            <FinancialsSkeleton />
          ) : (
            <>
              <View style={tailwind('pt-4')}>
                <SegmentedControl
                  values={segments.slice()}
                  selectedIndex={selectedIndex}
                  onChange={(e) => navigation.setParams({ selectedIndex: e.nativeEvent.selectedSegmentIndex })}
                />
              </View>
              <ContentBody segment={segments[selectedIndex]} data={data} />
            </>
          )}
        </ScreenSidePadding>
      </SafeAreaView>
    );
  },
);

const ContentBody: React.FC<{ segment: Segment; data?: GetInstrumentFinancialsQuery; loading?: boolean }> = ({
  segment,
  data,
}) => {
  switch (segment) {
    case 'Annual': {
      const yearlyFinancials = data?.instrument?.yearlyFinancials?.filter(isNotNull);
      if (yearlyFinancials?.length) {
        const { currentFinancials, prevFinancials } = getCurrentAndPreviousFinancials(yearlyFinancials);
        return <FinancialsTable financials={currentFinancials} prevFinancials={prevFinancials} variant="Annual" />;
      } else return <EmptyState />;
    }
    case 'Quarterly': {
      const quarterlyFinancials = data?.instrument?.quarterlyFinancials?.filter(isNotNull);
      if (quarterlyFinancials?.length) {
        const { currentFinancials, prevFinancials } = getCurrentAndPreviousFinancials(quarterlyFinancials);
        return <FinancialsTable financials={currentFinancials} prevFinancials={prevFinancials} variant="Quarterly" />;
      } else return <EmptyState />;
    }
    default:
      return null;
  }
};

const FinancialsSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView>
      <View style={tailwind('py-3')}>
        <Skeleton style={tailwind('h-8')} />
      </View>

      {new Array(10).fill(null).map((_, i) => (
        <View style={tailwind('py-3 flex-row justify-between')} key={i}>
          <Skeleton style={tailwind('h-8 w-48')} />
          <Skeleton style={tailwind('h-8 w-16')} />
          <Skeleton style={tailwind('h-8 w-16')} />
        </View>
      ))}
    </SkeletonView>
  );
};
