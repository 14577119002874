import { MotiPressable } from 'moti/interactions';
import { Image, View } from 'react-native';
import { XIcon } from 'react-native-heroicons/outline';
import { Instrument } from '../../generated/graphql';
import { colors, shadowUpper, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { useStockSelection } from './useStockSelection';

const SLOTS = 3;
export const SelectedStocks: React.VFC = () => {
  const tailwind = useTailwind();
  const { selectedStocks, removeInstrument } = useStockSelection();

  return (
    <View style={[tailwind('rounded-t-3xl pt-3'), shadowUpper]}>
      <View style={tailwind('flex-row justify-between px-6 items-center text-default')}>
        <Text style={tailwind('text-default font-regular')}>Your stocks</Text>
        <Text style={tailwind('text-greys-500 text-base font-regular')}>
          {selectedStocks?.length}/{SLOTS}
        </Text>
      </View>
      <View style={tailwind('flex-row flex-1 pl-6 pr-24 mt-5')}>
        {selectedStocks?.map((slot) => (
          <StockSlot key={slot.id} instrument={slot} onRemoveStock={removeInstrument} />
        ))}
        {Array.from(new Array(SLOTS - selectedStocks.length).keys()).map((i) => (
          <StockSlot key={i} instrument={null} />
        ))}
      </View>
    </View>
  );
};

type StockSlotProps = {
  instrument: Pick<Instrument, 'id' | 'logoUrl' | 'ticker'> | null;
  onRemoveStock?: (instrumentId: string) => void;
};

const StockSlot: React.VFC<StockSlotProps> = ({ instrument, onRemoveStock }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('mr-4')}>
      <View style={tailwind('w-16 h-16 rounded-xl')}>
        {instrument ? (
          <>
            <Image
              style={tailwind('h-16 w-16 rounded-xl')}
              source={{ uri: instrument.logoUrl ?? '' }}
              resizeMode="center"
              accessibilityIgnoresInvertColors={true}
            />
            <MotiPressable
              onPress={() => {
                onRemoveStock && onRemoveStock(instrument.id);
              }}
              containerStyle={tailwind(
                'absolute right-0 top-0 -mr-3.5 -mt-3.5 w-7 h-7 bg-tertiary-dark rounded-full items-center justify-center',
              )}
            >
              <XIcon color={colors.white} width={16} height={16} />
            </MotiPressable>
          </>
        ) : (
          <View style={tailwind('h-full w-full rounded-xl bg-greys-50')} />
        )}
      </View>
      <View style={tailwind('h-8 justify-center')}>
        {instrument && (
          <Text numberOfLines={1} style={tailwind('text-default font-semibold text-sm text-center')}>
            {instrument.ticker}
          </Text>
        )}
      </View>
    </View>
  );
};
