import { gql } from '@apollo/client';
import { INSTRUMENT_ESG } from './instrument';

export const PRE_PORTFOLIO_STATS_FIELDS = gql`
  ${INSTRUMENT_ESG}
  fragment PrePortfolioStats on PrePortfolioStatsPayload {
    error {
      ... on PrePortfolioStatsInvalidDate {
        message
      }
      ... on ValidationError {
        message
      }
    }
    data {
      annualisedReturn
      annualisedVolatility
      sharpeRatio
      maximumDrawdown
      correlation
      esg {
        ...InstrumentEsg
      }
      countryCashWeight
      countryWeights {
        country {
          id
          name
        }
        weight
      }
      sectorCashWeight
      sectorWeights {
        sector {
          id
          name
        }
        weight
      }
      marketCapCashWeight
      marketCapWeights {
        marketCap
        weight
      }
      historicalPrices {
        price
        date
      }
    }
  }
`;

export const CORE_PRE_PORTFOLIO_FIELDS = gql`
  ${PRE_PORTFOLIO_STATS_FIELDS}
  fragment CorePrePortfolioFields on PrePortfolio {
    id
    currency {
      id
      iso
    }
    notional
    cashProportion
    selectedRiskLevel
    lastBuiltDate
    members {
      id
      weight
      instrument {
        id
        name
        ticker
        logoUrl
      }
    }
    stats {
      ...PrePortfolioStats
    }
  }
`;

export const PRE_PORTFOLIO_SIMULATION_FIELDS = gql`
  ${PRE_PORTFOLIO_STATS_FIELDS}
  fragment CorePrePortfolioSimulationFields on PrePortfolioSimulation {
    id
    riskLevel
    members {
      id
      weight
      instrument {
        id
        name
        ticker
        logoUrl
      }
    }
    stats {
      ...PrePortfolioStats
    }
  }
`;
