/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentPriceSeries } from '../models/InstrumentPriceSeries';
import type { InstrumentSplitAdjustedPriceWithInstrument } from '../models/InstrumentSplitAdjustedPriceWithInstrument';
import type { LatestInstrumentPriceRequest } from '../models/LatestInstrumentPriceRequest';
import { request as __request } from '../core/request';

export class InstrumentPriceService {

    /**
     * @returns InstrumentSplitAdjustedPriceWithInstrument
     * @throws ApiError
     */
    public static async apiGetLatestInstrumentPrice({
        data,
    }: {
        data: LatestInstrumentPriceRequest,
    }): Promise<Array<InstrumentSplitAdjustedPriceWithInstrument>> {
        const result = await __request({
            method: 'POST',
            path: `/api/get-latest-instrument-price/`,
            body: data,
            errors: {
                400: `bad request`,
            },
        });
        return result.body;
    }

    /**
     * Gets the latest close price for every instrument.
     * @returns InstrumentSplitAdjustedPriceWithInstrument
     * @throws ApiError
     */
    public static async apiInstrumentPriceLatestList({
        dateTo,
        instrumentIds,
        bloombergTickers,
    }: {
        dateTo?: string,
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
        /** Multiple values may be separated by commas. **/
        bloombergTickers?: string,
    }): Promise<Array<InstrumentSplitAdjustedPriceWithInstrument>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-price-latest/`,
            query: {
                'date_to': dateTo,
                'instrument_ids': instrumentIds,
                'bloomberg_tickers': bloombergTickers,
            },
        });
        return result.body;
    }

    /**
     * Gets the latest close price for the given instrument.
     * @returns InstrumentSplitAdjustedPriceWithInstrument
     * @throws ApiError
     */
    public static async apiInstrumentPriceLatestRead({
        instrument,
    }: {
        instrument: string,
    }): Promise<InstrumentSplitAdjustedPriceWithInstrument> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-price-latest/${instrument}/`,
        });
        return result.body;
    }

    /**
     * Gets a list of close price series for every instrument.
     * @returns InstrumentPriceSeries
     * @throws ApiError
     */
    public static async apiInstrumentPriceSeriesList({
        dateFrom,
        dateTo,
        instrumentIds,
    }: {
        dateFrom?: string,
        dateTo?: string,
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
    }): Promise<Array<InstrumentPriceSeries>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-price-series/`,
            query: {
                'date_from': dateFrom,
                'date_to': dateTo,
                'instrument_ids': instrumentIds,
            },
        });
        return result.body;
    }

    /**
     * Gets a series of close prices for the given instrument.
     * @returns InstrumentPriceSeries
     * @throws ApiError
     */
    public static async apiInstrumentPriceSeriesRead({
        instrument,
    }: {
        instrument: string,
    }): Promise<InstrumentPriceSeries> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-price-series/${instrument}/`,
        });
        return result.body;
    }

}
