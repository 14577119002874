import { FC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { formatPercent } from '../../util/number';
import { CollectionComponents } from '../CollectionComponents';
import { Pressable } from '../Pressable';
import { Text } from '../Text';
import { CommonProps, Heading, SubText } from './common';

export const PortfolioBundleCard: FC<CommonProps> = ({
  title,
  components,
  componentCount,
  subText,
  onPress,
  weight,
}) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      style={[tailwind('flex-1 bg-secondary-lightest px-4 pr-3 py-3'), { borderRadius: 20 }]}
      onPress={onPress}
      accessibilityRole="button"
    >
      <Heading>{title}</Heading>
      <SubText style={tailwind('pt-0.5 pb-2')}>{subText}</SubText>
      <View style={tailwind('items-center justify-between flex-row')}>
        <Text style={tailwind('text-secondary-dark text-xs font-medium')}>{formatPercent(weight, 1, false, true)}</Text>
        <CollectionComponents componentCount={componentCount} badgeSize="sm" cutOff={3} components={components} />
      </View>
    </Pressable>
  );
};
