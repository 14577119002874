import { MotiPressable } from 'moti/interactions';
import { Image, ViewStyle } from 'react-native';
import { InformationCircleIcon } from 'react-native-heroicons/outline';
import { SvgProps } from 'react-native-svg';
import { Instrument } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { Text } from '../Text';

export type Props = {
  instrument: Pick<Instrument, 'id' | 'ticker' | 'displayName' | 'logoUrl'>;
  selected?: boolean;
  onPress?: () => void;
  /** optionally show "i" icon if pass this in */
  onPressInformation?: () => void;
  onPressIcon?: () => void;
  icon?: React.FC<Pick<SvgProps, 'stroke' | 'color' | 'height' | 'width' | 'style'>>;
};

export const StockCard: React.VFC<Props> = ({
  instrument,
  selected = false,
  onPress,
  onPressInformation,
  onPressIcon,
  icon: Icon,
}) => {
  const tailwind = useTailwind();
  const cardStyle = cardStyles(selected, tailwind);
  return (
    <MotiPressable
      accessibilityRole="button"
      style={[
        tailwind('items-center rounded-2xl px-2 pt-4 pb-3 flex-shrink flex-grow h-36'),
        { maxWidth: 96 },
        cardStyle,
      ]}
      onPress={onPress}
    >
      <Image
        style={[tailwind('w-10 h-10 rounded-xl overflow-hidden')]}
        source={{ uri: instrument.logoUrl ?? '' }}
        resizeMode="center"
        accessibilityIgnoresInvertColors={true}
      />
      <Text style={tailwind('text-center text-default text-base font-semibold mt-2')} numberOfLines={1}>
        {instrument.ticker}
      </Text>
      <Text style={tailwind('text-center text-grey text-xs font-regular mb-1')} numberOfLines={1}>
        {instrument.displayName}
      </Text>
      {onPressInformation && (
        <MotiPressable onPress={onPressInformation} style={tailwind('p-1')}>
          <InformationCircleIcon width={16} height={16} color={colors.primary['default']} />
        </MotiPressable>
      )}
      {selected && Icon && (
        <MotiPressable
          onPress={onPressIcon}
          containerStyle={tailwind(
            'absolute right-0 top-0 -mr-3.5 -mt-3.5 w-7 h-7 bg-tertiary-default rounded-full items-center justify-center',
          )}
        >
          <Icon color={colors.white} width={16} height={16} />
        </MotiPressable>
      )}
    </MotiPressable>
  );
};

const cardStyles = (selected: boolean, tailwind: ReturnType<typeof useTailwind>): ViewStyle => {
  return selected
    ? tailwind('bg-tertiary-lightest border border-tertiary-default')
    : tailwind('bg-greys-50 border border-greys-50');
};
