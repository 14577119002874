import { colors } from '../theme';
import { round } from './number';
import { isFiniteNumber } from './typeGuards';

type PercentageColor = Extract<keyof typeof colors, 'warmGray' | 'red' | 'green'>;
export type PercentageTextColor = `text-${PercentageColor}-600`;

export const getPercentageTextColor = (percentageChange: number | undefined | null): PercentageTextColor => {
  if (!isFiniteNumber(percentageChange)) {
    return 'text-warmGray-600';
  }
  /**
   * Check if the percentageChange is 0 after being rounded to 2 decimal places
   */
  if (round(100 * percentageChange) === 0) {
    return 'text-warmGray-600';
  }
  return percentageChange > 0 ? 'text-green-600' : 'text-red-600';
};

export const DEFAULT_PERCENTAGE_TEXT_COLOR: PercentageTextColor = 'text-warmGray-600';
