import Animated, { useAnimatedProps, useDerivedValue, withSpring } from 'react-native-reanimated';
import Svg, { ClipPath, Defs, G, Path, Rect, RectProps, SvgProps } from 'react-native-svg';
import { colors } from '../../../theme';

export type Props = {
  filled?: boolean;
};

const AnimatedRect = Animated.createAnimatedComponent(Rect);

export const Bookmark: React.VFC<Props> = ({ filled }) => {
  const fill = useDerivedValue(
    () => withSpring(filled ? 1 : 0, { overshootClamping: true, damping: 60, mass: 5 }),
    [filled],
  );

  const yellowProps = useAnimatedProps<RectProps>(() => {
    return {
      height: Math.min(24, 2 * fill.value * 24),
    };
  });
  const greenProps = useAnimatedProps<RectProps>(() => {
    return {
      height: fill.value < 0.5 ? 0 : (fill.value - 0.5) * 2 * 24,
    };
  });

  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Defs>
        <ClipPath id="bookmark-clip">
          <Path
            d="m20 22-8-4.546L4 22V3.818c0-.482.187-.944.52-1.285A1.758 1.758 0 0 1 5.779 2h12.444c.472 0 .924.192 1.257.533.334.34.521.803.521 1.285V22Z"
            strokeWidth={0.1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </ClipPath>
      </Defs>
      <G clipPath="url(#bookmark-clip)" clipRule="nonzero">
        <AnimatedRect width={24} fill={colors.amber['400']} animatedProps={yellowProps} />
        <AnimatedRect width={24} fill={colors.teal['700']} animatedProps={greenProps} />
      </G>
      <BookmarkUnfilled />
    </Svg>
  );
};

export const BookmarkUnfilled = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="m20 22-8-4.546L4 22V3.818c0-.482.187-.944.52-1.285A1.758 1.758 0 0 1 5.779 2h12.444c.472 0 .924.192 1.257.533.334.34.521.803.521 1.285V22Z"
      stroke={props.color ?? colors.teal['700']}
      strokeWidth={props.strokeWidth ?? 2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export const BookmarkFilled = (props: SvgProps) => (
  <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="m20 22-8-4.546L4 22V3.818c0-.482.187-.944.52-1.285A1.758 1.758 0 0 1 5.779 2h12.444c.472 0 .924.192 1.257.533.334.34.521.803.521 1.285V22Z"
      fill={props.color ?? colors.teal['700']}
      stroke={props.color ?? colors.teal['700']}
      strokeWidth={props.strokeWidth ?? 2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);
