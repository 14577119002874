import React from 'react';
import { View } from 'react-native';
import { ClosePrice, Currency, Idea, QuotePrice } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatDate, getRoundedTimeframe } from '../../util/date';
import { formatPercent, formatPrice, getPercentageDiff } from '../../util/number';
import { ChangeSincePosted } from './ChangeSincePosted';
import { IdeaDetailBox } from './IdeaDetailBox';

type Props = Pick<
  Idea,
  'referenceDate' | 'referencePrice' | 'targetDate' | 'targetPrice' | 'conviction' | 'position' | 'performance'
> &
  Pick<QuotePrice, 'midPrice'> &
  Pick<ClosePrice, 'price'> &
  Pick<Currency, 'iso'>;

export const IdeaDetails: React.FC<Props> = ({
  targetDate,
  targetPrice,
  referenceDate,
  referencePrice,
  midPrice,
  conviction,
  iso,
  position,
  performance,
}) => {
  const tailwind = useTailwind();
  const targetPriceChange = referencePrice && targetPrice ? getPercentageDiff(referencePrice, targetPrice) : 0;
  const confidence = conviction ? `${Math.round(conviction / 20)} (out of 5)` : '';

  return (
    <View style={tailwind('pt-8 pb-1 px-1')}>
      <Text style={tailwind('text-lg font-semibold text-warmGray-800')}>Details</Text>
      <View style={tailwind('pt-3 flex-row')}>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox title="Posted" content={referenceDate ? formatDate(referenceDate, 'DD MMM YYYY') : ''} />
        </View>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox
            title="Timeframe"
            content={getRoundedTimeframe(referenceDate?.toString() ?? '', targetDate?.toString() ?? '')}
          />
        </View>
      </View>
      <View style={tailwind('pt-3 flex-row')}>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox title="Target date" content={targetDate ? formatDate(targetDate, 'DD MMM YYYY') : ''} />
        </View>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox
            title="Price when posted"
            content={referencePrice && iso ? formatPrice(referencePrice, iso) : ''}
          />
        </View>
      </View>
      <View style={tailwind('pt-3 flex-row')}>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox
            title="Target price"
            content={
              targetPrice && iso && targetPriceChange
                ? `${formatPrice(targetPrice, iso)} (${formatPercent(targetPriceChange, 2, true)})`
                : ''
            }
          />
        </View>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox
            title="Current price"
            content={performance?.actualPrice ? formatPrice(performance?.actualPrice, iso ?? '') : ''}
          />
        </View>
      </View>
      <View style={tailwind('pt-3 flex-row')}>
        <View style={tailwind('flex-1')}>
          <ChangeSincePosted
            position={position}
            performance={performance}
            midPrice={midPrice}
            iso={iso}
            referencePrice={referencePrice}
          />
        </View>
        <View style={tailwind('flex-1')}>
          <IdeaDetailBox title="Confidence" content={confidence} />
        </View>
      </View>
    </View>
  );
};
