import { FC } from 'react';
import { View } from 'react-native';
import { InformationCircleIcon } from 'react-native-heroicons/outline';
import { colors, textColors, useTailwind } from '../../theme';
import { Pressable } from '../Pressable';
import { Text } from '../Text';

export type Props = {
  onPress: () => void;
  text: string;
  inverted?: boolean;
  size?: 'sm' | 'md';
};

export const InfoLink: FC<Props> = ({ onPress, inverted, text, size = 'md' }) => {
  const tailwind = useTailwind();

  return (
    <Pressable
      accessibilityRole="link"
      accessibilityLabel={text}
      onPress={onPress}
      style={tailwind('flex-row items-center')}
    >
      <View style={tailwind('justify-center')}>
        <InformationCircleIcon size={12} color={inverted ? colors.primary.light : textColors.light} />
      </View>
      <Text
        style={[
          tailwind('pl-1 text-sm'),
          inverted ? tailwind('text-primary-light') : tailwind('text-light'),
          size === 'sm' && tailwind('text-xs'),
        ]}
      >
        {text}
      </Text>
    </Pressable>
  );
};
