/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateResearchPurchase } from '../models/CreateResearchPurchase';
import type { ResearchAuthor } from '../models/ResearchAuthor';
import type { ResearchFile } from '../models/ResearchFile';
import type { ResearchFileMetadata } from '../models/ResearchFileMetadata';
import type { ResearchFileType } from '../models/ResearchFileType';
import type { ResearchProvider } from '../models/ResearchProvider';
import type { ResearchPurchase } from '../models/ResearchPurchase';
import type { ResearchReport } from '../models/ResearchReport';
import type { ResearchReportImport } from '../models/ResearchReportImport';
import type { RetrievalResearchReport } from '../models/RetrievalResearchReport';
import { request as __request } from '../core/request';

export class ResearchService {

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchAuthorList({
        email,
        cursor,
        pageSize,
    }: {
        email?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ResearchAuthor>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-author/`,
            query: {
                'email': email,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns ResearchAuthor
     * @throws ApiError
     */
    public static async apiResearchAuthorCreate({
        data,
    }: {
        data: ResearchAuthor,
    }): Promise<ResearchAuthor> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-author/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns ResearchAuthor
     * @throws ApiError
     */
    public static async apiResearchAuthorRead({
        uuid,
    }: {
        /** A UUID string identifying this research author. **/
        uuid: string,
    }): Promise<ResearchAuthor> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-author/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns ResearchAuthor
     * @throws ApiError
     */
    public static async apiResearchAuthorPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this research author. **/
        uuid: string,
        data: ResearchAuthor,
    }): Promise<ResearchAuthor> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/research-author/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns ResearchFileMetadata
     * @throws ApiError
     */
    public static async apiResearchFileMetadataCreate({
        data,
    }: {
        data: ResearchFileMetadata,
    }): Promise<ResearchFileMetadata> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-file-metadata/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchFileMetadataRead({
        fileExternalId,
        researchProvider,
    }: {
        fileExternalId: string,
        researchProvider: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-file-metadata/${researchProvider}/${fileExternalId}/`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchFileTypeList({
        researchProvider,
        fileFormat,
        cursor,
        pageSize,
    }: {
        researchProvider?: string,
        fileFormat?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ResearchFileType>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-file-type/`,
            query: {
                'research_provider': researchProvider,
                'file_format': fileFormat,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns ResearchFileType
     * @throws ApiError
     */
    public static async apiResearchFileTypeCreate({
        data,
    }: {
        data: ResearchFileType,
    }): Promise<ResearchFileType> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-file-type/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns ResearchFileType
     * @throws ApiError
     */
    public static async apiResearchFileTypeRead({
        uuid,
    }: {
        /** A UUID string identifying this research file type. **/
        uuid: string,
    }): Promise<ResearchFileType> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-file-type/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns ResearchFileType
     * @throws ApiError
     */
    public static async apiResearchFileTypePartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this research file type. **/
        uuid: string,
        data: ResearchFileType,
    }): Promise<ResearchFileType> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/research-file-type/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchFileList({
        researchProvider,
        fileType,
        fileExternalId,
        cursor,
        pageSize,
    }: {
        researchProvider?: string,
        fileType?: string,
        fileExternalId?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ResearchFile>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-file/`,
            query: {
                'research_provider': researchProvider,
                'file_type': fileType,
                'file_external_id': fileExternalId,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns ResearchFile
     * @throws ApiError
     */
    public static async apiResearchFileCreate({
        data,
    }: {
        data: ResearchFile,
    }): Promise<ResearchFile> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-file/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns ResearchFile
     * @throws ApiError
     */
    public static async apiResearchFileRead({
        uuid,
    }: {
        /** A UUID string identifying this research file. **/
        uuid: string,
    }): Promise<ResearchFile> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-file/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns ResearchFile
     * @throws ApiError
     */
    public static async apiResearchFilePartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this research file. **/
        uuid: string,
        data: ResearchFile,
    }): Promise<ResearchFile> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/research-file/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchProviderList({
        name,
        isVisible,
        cursor,
        pageSize,
    }: {
        name?: string,
        isVisible?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ResearchProvider>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-provider/`,
            query: {
                'name': name,
                'is_visible': isVisible,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns ResearchProvider
     * @throws ApiError
     */
    public static async apiResearchProviderCreate({
        data,
    }: {
        data: ResearchProvider,
    }): Promise<ResearchProvider> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-provider/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns ResearchProvider
     * @throws ApiError
     */
    public static async apiResearchProviderRead({
        uuid,
    }: {
        /** A UUID string identifying this research provider. **/
        uuid: string,
    }): Promise<ResearchProvider> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-provider/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns ResearchProvider
     * @throws ApiError
     */
    public static async apiResearchProviderPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this research provider. **/
        uuid: string,
        data: ResearchProvider,
    }): Promise<ResearchProvider> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/research-provider/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchPurchaseList({
        cursor,
        pageSize,
    }: {
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<ResearchPurchase>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-purchase/`,
            query: {
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns CreateResearchPurchase
     * @throws ApiError
     */
    public static async apiResearchPurchaseCreate({
        data,
    }: {
        data: CreateResearchPurchase,
    }): Promise<CreateResearchPurchase> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-purchase/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns ResearchPurchase
     * @throws ApiError
     */
    public static async apiResearchPurchaseRead({
        uuid,
    }: {
        /** A UUID string identifying this research purchase. **/
        uuid: string,
    }): Promise<ResearchPurchase> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-purchase/${uuid}/`,
        });
        return result.body;
    }

    /**
     * Import research report data
     * Write-only endpoint for importing research report data.
     * @returns ResearchReportImport
     * @throws ApiError
     */
    public static async apiResearchReportImportCreate({
        data,
    }: {
        data: ResearchReportImport,
    }): Promise<ResearchReportImport> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-report-import/`,
            body: data,
        });
        return result.body;
    }

    /**
     * Research reports are ordered by publish_date desc
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchReportList({
        researchProviderIds,
        fileExternalIds,
        sectorIds,
        industryIds,
        countryIds,
        instrumentIds,
        createdAtBefore,
        createdAtAfter,
        publishDateBefore,
        publishDateAfter,
        researchProviderIsVisible,
        leadAuthorIds,
        cursor,
        pageSize,
    }: {
        /** Multiple values may be separated by commas. **/
        researchProviderIds?: string,
        /** Multiple values may be separated by commas. **/
        fileExternalIds?: string,
        /** Multiple values may be separated by commas. **/
        sectorIds?: string,
        /** Multiple values may be separated by commas. **/
        industryIds?: string,
        /** Multiple values may be separated by commas. **/
        countryIds?: string,
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
        createdAtBefore?: string,
        createdAtAfter?: string,
        publishDateBefore?: string,
        publishDateAfter?: string,
        researchProviderIsVisible?: string,
        /** Multiple values may be separated by commas. **/
        leadAuthorIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<RetrievalResearchReport>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-report/`,
            query: {
                'research_provider_ids': researchProviderIds,
                'file_external_ids': fileExternalIds,
                'sector_ids': sectorIds,
                'industry_ids': industryIds,
                'country_ids': countryIds,
                'instrument_ids': instrumentIds,
                'created_at_before': createdAtBefore,
                'created_at_after': createdAtAfter,
                'publish_date_before': publishDateBefore,
                'publish_date_after': publishDateAfter,
                'research_provider_is_visible': researchProviderIsVisible,
                'lead_author_ids': leadAuthorIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns ResearchReport
     * @throws ApiError
     */
    public static async apiResearchReportCreate({
        data,
    }: {
        data: ResearchReport,
    }): Promise<ResearchReport> {
        const result = await __request({
            method: 'POST',
            path: `/api/research-report/`,
            body: data,
        });
        return result.body;
    }

    /**
     * Download purchased research report PDF
     * Given a research report id, returns the pdf of the report if the user has purchased
     * the report.
     *
     * This endpoint returns a 400 if the user hasn't purchased the report and 404 status if the report or file
     * doesn't exist.
     * @returns any
     * @throws ApiError
     */
    public static async apiResearchReportPdfList({
        researchReportId,
    }: {
        researchReportId: string,
    }): Promise<any> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-report/${researchReportId}/pdf/`,
        });
        return result.body;
    }

    /**
     * @returns RetrievalResearchReport
     * @throws ApiError
     */
    public static async apiResearchReportRead({
        uuid,
    }: {
        /** A UUID string identifying this research report. **/
        uuid: string,
    }): Promise<RetrievalResearchReport> {
        const result = await __request({
            method: 'GET',
            path: `/api/research-report/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns ResearchReport
     * @throws ApiError
     */
    public static async apiResearchReportPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this research report. **/
        uuid: string,
        data: ResearchReport,
    }): Promise<ResearchReport> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/research-report/${uuid}/`,
            body: data,
        });
        return result.body;
    }

}
