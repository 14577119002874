/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Industry } from '../models/Industry';
import { request as __request } from '../core/request';

export class IndustryService {

    /**
     * @returns Industry
     * @throws ApiError
     */
    public static async apiIndustryList({
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<Industry>> {
        const result = await __request({
            method: 'GET',
            path: `/api/industry/`,
            query: {
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * @returns Industry
     * @throws ApiError
     */
    public static async apiIndustryRead({
        uuid,
    }: {
        /** A UUID string identifying this industry. **/
        uuid: string,
    }): Promise<Industry> {
        const result = await __request({
            method: 'GET',
            path: `/api/industry/${uuid}/`,
        });
        return result.body;
    }

}
