import * as SecureStorage from 'expo-secure-store';
import { Platform } from 'react-native';
import { ENVIRONMENT } from '../constants/env';

// Helper functions to abstract local storage from the device. Uses secure-storage if mobile and localStorage if web.

/** all valid secure keys */
const SECURE_KEYS = ['accessToken', 'refreshToken'] as const;
export type SecureKey = typeof SECURE_KEYS[number];

/**
 * Append environment on dev/preview native so dont have clashing key names in secure store as you change environment
 *
 * web is fine as local storage tied to different domains
 *
 * Don't append on prod or will break current users
 */
const getFullKeyName = (key: SecureKey): string => {
  if (ENVIRONMENT === 'prod' || Platform.OS === 'web') return key;
  return `${key}-${ENVIRONMENT}`;
};

export const setSecureItem = async (key: SecureKey, value: string): Promise<void> => {
  const fullKey = getFullKeyName(key);

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    return SecureStorage.setItemAsync(fullKey, value);
  }
  if (Platform.OS === 'web') {
    return localStorage.setItem(fullKey, value);
  }
  throw new Error('No supported storage found in the runtime environment');
};

export const getSecureItem = async (key: SecureKey): Promise<string | null> => {
  const fullKey = getFullKeyName(key);

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    return SecureStorage.getItemAsync(fullKey);
  }
  if (Platform.OS === 'web') {
    return localStorage.getItem(fullKey);
  }
  throw new Error('No supported storage found in the runtime environment');
};

export const deleteSecureItem = async (key: SecureKey): Promise<void> => {
  const fullKey = getFullKeyName(key);

  if (Platform.OS === 'ios' || Platform.OS === 'android') {
    return SecureStorage.deleteItemAsync(fullKey);
  }
  if (Platform.OS === 'web') {
    return localStorage.removeItem(fullKey);
  }
  throw new Error('No supported storage found in the runtime environment');
};

/**
 * On logout clear all the secure tokens
 */
export const clearAllSecureTokens = async () => {
  await Promise.all(SECURE_KEYS.map((key) => deleteSecureItem(key)));
};
