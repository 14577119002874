/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentDetail } from '../models/InstrumentDetail';
import type { InstrumentESG } from '../models/InstrumentESG';
import type { InstrumentFinancialsCurrent } from '../models/InstrumentFinancialsCurrent';
import type { InstrumentFinancialsQuarterly } from '../models/InstrumentFinancialsQuarterly';
import type { InstrumentFinancialsYearly } from '../models/InstrumentFinancialsYearly';
import type { InstrumentFullList } from '../models/InstrumentFullList';
import type { InstrumentId } from '../models/InstrumentId';
import type { InstrumentList } from '../models/InstrumentList';
import type { InstrumentSearch } from '../models/InstrumentSearch';
import type { InstrumentTags } from '../models/InstrumentTags';
import { request as __request } from '../core/request';

export class InstrumentService {

    /**
     * List current esg data for the given list of instruments.
     * @returns InstrumentESG
     * @throws ApiError
     */
    public static async apiInstrumentEsgCurrentList({
        instrumentIds,
    }: {
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
    }): Promise<Array<InstrumentESG>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-esg-current/`,
            query: {
                'instrument_ids': instrumentIds,
            },
        });
        return result.body;
    }

    /**
     * Retrieves current esg data for the given instrument.
     * @returns InstrumentESG
     * @throws ApiError
     */
    public static async apiInstrumentEsgCurrentRead({
        instrument,
    }: {
        instrument: string,
    }): Promise<InstrumentESG> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-esg-current/${instrument}/`,
        });
        return result.body;
    }

    /**
     * List historical esg data for the given list of instruments.
     * @returns InstrumentESG
     * @throws ApiError
     */
    public static async apiInstrumentEsgHistoricalList({
        instrumentIds,
    }: {
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
    }): Promise<Array<InstrumentESG>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-esg-historical/`,
            query: {
                'instrument_ids': instrumentIds,
            },
        });
        return result.body;
    }

    /**
     * Retrieves historical esg data for the given instrument.
     * @returns InstrumentESG
     * @throws ApiError
     */
    public static async apiInstrumentEsgHistoricalRead({
        instrument,
    }: {
        instrument: string,
    }): Promise<Array<InstrumentESG>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-esg-historical/${instrument}/`,
        });
        return result.body;
    }

    /**
     * List current financials for the given list of instruments.
     * @returns InstrumentFinancialsCurrent
     * @throws ApiError
     */
    public static async apiInstrumentFinancialsCurrentList({
        instrumentIds,
    }: {
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
    }): Promise<Array<InstrumentFinancialsCurrent>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-financials-current/`,
            query: {
                'instrument_ids': instrumentIds,
            },
        });
        return result.body;
    }

    /**
     * Retrieves current financials for the given instrument.
     * @returns InstrumentFinancialsCurrent
     * @throws ApiError
     */
    public static async apiInstrumentFinancialsCurrentRead({
        instrument,
    }: {
        /** A unique value identifying this instrument financials current. **/
        instrument: string,
    }): Promise<InstrumentFinancialsCurrent> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-financials-current/${instrument}/`,
        });
        return result.body;
    }

    /**
     * List quarterly financials for the given list of instruments.
     * @returns InstrumentFinancialsQuarterly
     * @throws ApiError
     */
    public static async apiInstrumentFinancialsQuarterlyList({
        instrumentIds,
    }: {
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
    }): Promise<Array<InstrumentFinancialsQuarterly>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-financials-quarterly/`,
            query: {
                'instrument_ids': instrumentIds,
            },
        });
        return result.body;
    }

    /**
     * List yearly financials for the given list of instruments.
     * @returns InstrumentFinancialsYearly
     * @throws ApiError
     */
    public static async apiInstrumentFinancialsYearlyList({
        instrumentIds,
    }: {
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
    }): Promise<Array<InstrumentFinancialsYearly>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-financials-yearly/`,
            query: {
                'instrument_ids': instrumentIds,
            },
        });
        return result.body;
    }

    /**
     * Full instrument list
     * Paginated instrument list including most of their fields. Foreign key fields are returned as FK and not
     * serialized further.
     *
     * This endpoint doesn't require authentication.
     * @returns any
     * @throws ApiError
     */
    public static async apiInstrumentFullList({
        bloombergTicker,
        isin,
        bloombergGlobalId,
        isActive,
        instrumentType,
        countryIds,
        industryIds,
        sectorIds,
        uuids,
        cursor,
        pageSize,
    }: {
        /** Multiple values may be separated by commas. **/
        bloombergTicker?: string,
        /** Multiple values may be separated by commas. **/
        isin?: string,
        /** Multiple values may be separated by commas. **/
        bloombergGlobalId?: string,
        isActive?: string,
        /** Multiple values may be separated by commas. **/
        instrumentType?: string,
        /** Multiple values may be separated by commas. **/
        countryIds?: string,
        /** Multiple values may be separated by commas. **/
        industryIds?: string,
        /** Multiple values may be separated by commas. **/
        sectorIds?: string,
        /** Multiple values may be separated by commas. **/
        uuids?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<InstrumentFullList>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-full/`,
            query: {
                'bloomberg_ticker': bloombergTicker,
                'isin': isin,
                'bloomberg_global_id': bloombergGlobalId,
                'is_active': isActive,
                'instrument_type': instrumentType,
                'country_ids': countryIds,
                'industry_ids': industryIds,
                'sector_ids': sectorIds,
                'uuids': uuids,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns InstrumentId
     * @throws ApiError
     */
    public static async apiInstrumentIdList({
        isActive,
        bloombergTickers,
    }: {
        isActive?: string,
        /** Multiple values may be separated by commas. **/
        bloombergTickers?: string,
    }): Promise<InstrumentId> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-id/`,
            query: {
                'is_active': isActive,
                'bloomberg_tickers': bloombergTickers,
            },
        });
        return result.body;
    }

    /**
     * Get a list the most popular (most watchlisted) instruments.
     * @returns InstrumentFullList
     * @throws ApiError
     */
    public static async apiInstrumentPopularList({
        limit,
        dateFrom,
        dateTo,
    }: {
        limit?: number,
        /** A date from which to count the number of watchlist events. If not supplied then the watchlist count is for all time. **/
        dateFrom?: string,
        /** A date to which to count the number of watchlist events. If not supplied then the watchlist count is to present day. **/
        dateTo?: string,
    }): Promise<Array<InstrumentFullList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-popular/`,
            query: {
                'limit': limit,
                'date_from': dateFrom,
                'date_to': dateTo,
            },
        });
        return result.body;
    }

    /**
     * Instrument list and retrieve.
     * **Note: The list instrument endpoint is deprecated, 'full instrument' list endpoint should be used instead where
     * possible.**
     * @returns InstrumentList
     * @throws ApiError
     */
    public static async apiInstrumentList({
        bloombergTicker,
        isin,
        bloombergGlobalId,
        isActive,
        instrumentType,
        countryIds,
        industryIds,
        sectorIds,
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        bloombergTicker?: string,
        /** Multiple values may be separated by commas. **/
        isin?: string,
        /** Multiple values may be separated by commas. **/
        bloombergGlobalId?: string,
        isActive?: string,
        /** Multiple values may be separated by commas. **/
        instrumentType?: string,
        /** Multiple values may be separated by commas. **/
        countryIds?: string,
        /** Multiple values may be separated by commas. **/
        industryIds?: string,
        /** Multiple values may be separated by commas. **/
        sectorIds?: string,
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<InstrumentList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument/`,
            query: {
                'bloomberg_ticker': bloombergTicker,
                'isin': isin,
                'bloomberg_global_id': bloombergGlobalId,
                'is_active': isActive,
                'instrument_type': instrumentType,
                'country_ids': countryIds,
                'industry_ids': industryIds,
                'sector_ids': sectorIds,
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * Instrument search
     * This endpoint provides optimised functionality for searching instruments (by name or their bloomberg ticker).
     *
     * Some optimisations made on this endpoint:
     * * Only a subset of the instrument fields are returned and no FKs are expanded
     * * Pagination is not supported, but there's a hardcoded limit to number of results returned (10)
     * * Returned results are ordered by similarity to query
     * * Empty/Whitespace searched are not permitted
     * * Use trigram GIN indices under the hood
     *
     * This endpoint doesn't require authentication.
     * @returns InstrumentSearch
     * @throws ApiError
     */
    public static async apiInstrumentSearchList({
        searchQuery,
        isActive,
        instrumentTypes,
        maxResults,
    }: {
        searchQuery: string,
        isActive?: string,
        /** Multiple values may be separated by commas. **/
        instrumentTypes?: string,
        /** Max search results **/
        maxResults?: number,
    }): Promise<Array<InstrumentSearch>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument/search/`,
            query: {
                'search_query': searchQuery,
                'is_active': isActive,
                'instrument_types': instrumentTypes,
                'maxResults': maxResults,
            },
        });
        return result.body;
    }

    /**
     * Instrument list and retrieve.
     * **Note: The list instrument endpoint is deprecated, 'full instrument' list endpoint should be used instead where
     * possible.**
     * @returns InstrumentDetail
     * @throws ApiError
     */
    public static async apiInstrumentRead({
        uuid,
    }: {
        uuid: string,
    }): Promise<InstrumentDetail> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument/${uuid}/`,
        });
        return result.body;
    }

    /**
     * Instrument list and retrieve.
     * **Note: The list instrument endpoint is deprecated, 'full instrument' list endpoint should be used instead where
     * possible.**
     * @returns InstrumentTags
     * @throws ApiError
     */
    public static async apiInstrumentPartialUpdate({
        uuid,
        data,
    }: {
        uuid: string,
        data: InstrumentTags,
    }): Promise<InstrumentTags> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/instrument/${uuid}/`,
            body: data,
        });
        return result.body;
    }

}
