/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activation } from '../models/Activation';
import type { CookieTokenObtainPairResponse } from '../models/CookieTokenObtainPairResponse';
import type { DefaultTokenObtainPairResponse } from '../models/DefaultTokenObtainPairResponse';
import type { PasswordResetConfirm } from '../models/PasswordResetConfirm';
import type { SendEmailReset } from '../models/SendEmailReset';
import type { SetPassword } from '../models/SetPassword';
import type { SetUsername } from '../models/SetUsername';
import type { TokenRefresh } from '../models/TokenRefresh';
import type { TokenRefreshResponse } from '../models/TokenRefreshResponse';
import type { TokenVerify } from '../models/TokenVerify';
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';
import type { UsernameResetConfirm } from '../models/UsernameResetConfirm';
import type { UserSerializer } from '../models/UserSerializer';
import type { UserTokenObtainPair } from '../models/UserTokenObtainPair';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * For mobile usage, we can't use cookies, so the refresh token is returned in the body.
     * @returns DefaultTokenObtainPairResponse
     * @throws ApiError
     */
    public static async authJwtCreateMobileCreate({
        data,
    }: {
        data: UserTokenObtainPair,
    }): Promise<DefaultTokenObtainPairResponse> {
        const result = await __request({
            method: 'POST',
            path: `/auth/jwt/create-mobile/`,
            body: data,
            errors: {
                400: `parse_error`,
                401: `token_not_valid`,
            },
        });
        return result.body;
    }

    /**
     * This class extends the rest_framework view to authenticate.
     * For security reasons, we didn't want to return the refresh token in the body.
     * Instead, we send the refresh token in a secure cookie not readable by the javascript.
     * @returns CookieTokenObtainPairResponse
     * @throws ApiError
     */
    public static async authJwtCreateCreate({
        data,
    }: {
        data: UserTokenObtainPair,
    }): Promise<CookieTokenObtainPairResponse> {
        const result = await __request({
            method: 'POST',
            path: `/auth/jwt/create/`,
            body: data,
            errors: {
                400: `parse_error`,
                401: `token_not_valid`,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async authJwtLogoutCreate(): Promise<any> {
        const result = await __request({
            method: 'POST',
            path: `/auth/jwt/logout/`,
        });
        return result.body;
    }

    /**
     * For mobile usage, we can't use cookies, so we expect the refresh token in the body.
     * @returns TokenRefreshResponse
     * @throws ApiError
     */
    public static async authJwtRefreshMobileCreate({
        data,
    }: {
        data: TokenRefresh,
    }): Promise<TokenRefreshResponse> {
        const result = await __request({
            method: 'POST',
            path: `/auth/jwt/refresh-mobile/`,
            body: data,
            errors: {
                400: `parse_error`,
                401: `token_not_valid`,
            },
        });
        return result.body;
    }

    /**
     * This class extends the rest_framework view to create a jwt with the refresh token.
     * To stay consistent with the authentication class, the refresh token is not in the body anymore.
     * Instead, we are expecting to get the refresh token in the cookie.
     * @returns TokenRefreshResponse
     * @throws ApiError
     */
    public static async authJwtRefreshCreate({
        data,
    }: {
        data: TokenRefresh,
    }): Promise<TokenRefreshResponse> {
        const result = await __request({
            method: 'POST',
            path: `/auth/jwt/refresh/`,
            body: data,
            errors: {
                400: `parse_error`,
                401: `token_not_valid`,
            },
        });
        return result.body;
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no
     * information about a token's fitness for a particular use.
     * @returns TokenVerify
     * @throws ApiError
     */
    public static async authJwtVerifyCreate({
        data,
    }: {
        data: TokenVerify,
    }): Promise<TokenVerify> {
        const result = await __request({
            method: 'POST',
            path: `/auth/jwt/verify/`,
            body: data,
        });
        return result.body;
    }

    /**
     * Record last login datetime.
     * N.B. An idle session, which hasn't been ended by the user explicitly logging out
     * will have the max date recorded in the `logout_date` column.
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authMeRead(): Promise<UserSerializer> {
        const result = await __request({
            method: 'GET',
            path: `/auth/me/`,
        });
        return result.body;
    }

    /**
     * Use this endpoint to retrieve/update user.
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authMeUpdate({
        data,
    }: {
        data: UserSerializer,
    }): Promise<UserSerializer> {
        const result = await __request({
            method: 'PUT',
            path: `/auth/me/`,
            body: data,
        });
        return result.body;
    }

    /**
     * Use this endpoint to retrieve/update user.
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authMePartialUpdate({
        data,
    }: {
        data: UserSerializer,
    }): Promise<UserSerializer> {
        const result = await __request({
            method: 'PATCH',
            path: `/auth/me/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authUsersList(): Promise<Array<UserSerializer>> {
        const result = await __request({
            method: 'GET',
            path: `/auth/users/`,
        });
        return result.body;
    }

    /**
     * @returns UserCreate
     * @throws ApiError
     */
    public static async authUsersCreate({
        data,
    }: {
        data: UserCreate,
    }): Promise<UserCreate> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Activation
     * @throws ApiError
     */
    public static async authUsersActivation({
        data,
    }: {
        data: Activation,
    }): Promise<Activation> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/activation/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static async authUsersMeRead(): Promise<Array<User>> {
        const result = await __request({
            method: 'GET',
            path: `/auth/users/me/`,
        });
        return result.body;
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static async authUsersMeUpdate({
        data,
    }: {
        data: User,
    }): Promise<User> {
        const result = await __request({
            method: 'PUT',
            path: `/auth/users/me/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns User
     * @throws ApiError
     */
    public static async authUsersMePartialUpdate({
        data,
    }: {
        data: User,
    }): Promise<User> {
        const result = await __request({
            method: 'PATCH',
            path: `/auth/users/me/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async authUsersMeDelete(): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/auth/users/me/`,
        });
        return result.body;
    }

    /**
     * @returns SendEmailReset
     * @throws ApiError
     */
    public static async authUsersResendActivation({
        data,
    }: {
        data: SendEmailReset,
    }): Promise<SendEmailReset> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/resend_activation/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns SendEmailReset
     * @throws ApiError
     */
    public static async authUsersResetUsername({
        data,
    }: {
        data: SendEmailReset,
    }): Promise<SendEmailReset> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/reset_email/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns UsernameResetConfirm
     * @throws ApiError
     */
    public static async authUsersResetUsernameConfirm({
        data,
    }: {
        data: UsernameResetConfirm,
    }): Promise<UsernameResetConfirm> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/reset_email_confirm/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns SendEmailReset
     * @throws ApiError
     */
    public static async authUsersResetPassword({
        data,
    }: {
        data: SendEmailReset,
    }): Promise<SendEmailReset> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/reset_password/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns PasswordResetConfirm
     * @throws ApiError
     */
    public static async authUsersResetPasswordConfirm({
        data,
    }: {
        data: PasswordResetConfirm,
    }): Promise<PasswordResetConfirm> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/reset_password_confirm/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns SetUsername
     * @throws ApiError
     */
    public static async authUsersSetUsername({
        data,
    }: {
        data: SetUsername,
    }): Promise<SetUsername> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/set_email/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns SetPassword
     * @throws ApiError
     */
    public static async authUsersSetPassword({
        data,
    }: {
        data: SetPassword,
    }): Promise<SetPassword> {
        const result = await __request({
            method: 'POST',
            path: `/auth/users/set_password/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authUsersRead({
        id,
    }: {
        /** A unique integer value identifying this user. **/
        id: number,
    }): Promise<UserSerializer> {
        const result = await __request({
            method: 'GET',
            path: `/auth/users/${id}/`,
        });
        return result.body;
    }

    /**
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authUsersUpdate({
        id,
        data,
    }: {
        /** A unique integer value identifying this user. **/
        id: number,
        data: UserSerializer,
    }): Promise<UserSerializer> {
        const result = await __request({
            method: 'PUT',
            path: `/auth/users/${id}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns UserSerializer
     * @throws ApiError
     */
    public static async authUsersPartialUpdate({
        id,
        data,
    }: {
        /** A unique integer value identifying this user. **/
        id: number,
        data: UserSerializer,
    }): Promise<UserSerializer> {
        const result = await __request({
            method: 'PATCH',
            path: `/auth/users/${id}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async authUsersDelete({
        id,
    }: {
        /** A unique integer value identifying this user. **/
        id: number,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/auth/users/${id}/`,
        });
        return result.body;
    }

}
