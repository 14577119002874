import * as shape from 'd3-shape';
import { orderBy, sumBy } from 'lodash';
import { FC, useMemo } from 'react';
import { View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { colors, textColors, useTailwind } from '../../theme';
import { formatPercent } from '../../util/number';
import { Unpack } from '../../util/types';
import { Text } from '../Text';
type DoughnutChartData = Array<{ name: string; value: number }>;

type Props = {
  data: DoughnutChartData;
};

const colorTable = [
  textColors.default,
  colors.primary.dark,
  colors.tertiary.dark,
  colors.secondary.default,
  colors.primary.light,
  colors.secondary.light,
  colors.tertiary.light,
  colors.greys[200],
];

const arc = shape.arc().innerRadius(42).outerRadius(50).cornerRadius(8);
const pie = shape
  .pie<Unpack<DoughnutChartData>>()
  .startAngle(0)
  .endAngle(Math.PI * 2)
  /**
   * Force chart to maintain input order so 'Other' is last, even if it is a larger category
   */
  .sort(() => 0)
  .value((d) => d.value);

/**
 * Doughnut chart. Limit 8 categories.
 * If more than 8 categories are provided, the rest are grouped into 'Other'
 */
export const DoughnutChart: FC<Props> = ({ data }) => {
  const tailwind = useTailwind();
  const { pieData, sum } = useMemo(() => {
    /**
     * Sort descending, then slice to 8 categories, with the last one being a grouped 'Other' category.
     */
    const sortedData = orderBy(data, 'value', 'desc').reduce<DoughnutChartData>((prev, curr, index) => {
      if (data.length > 8 && index === 7) {
        prev.push({ name: 'Other', value: curr.value });
      } else if (index > 7) {
        prev[7].value += curr.value;
      } else {
        prev.push(curr);
      }
      return prev;
    }, []);

    const pieData = pie(sortedData);
    const sum = sumBy(sortedData, 'value');
    return { pieData, sum };
  }, [data]);

  return (
    <View style={tailwind('pt-4')}>
      <View style={tailwind('items-center')}>
        <Svg style={tailwind('h-36 w-36')} viewBox="-50 -50 100 100">
          {pieData.map((d, i) => (
            /**
             * Increase end angle so arcs overlap
             */
            <Path
              d={arc({ ...d, endAngle: d.endAngle + 0.1 } as unknown as shape.DefaultArcObject) ?? ''}
              fill={colorTable[i]}
              key={d.data.name}
            />
          ))}
        </Svg>
      </View>

      <View style={tailwind('flex-row flex-wrap pt-8')}>
        {pieData.map((d, i) => (
          <LegendKey key={d.data.name} weight={d.value / sum} label={d.data.name} color={colorTable[i]} />
        ))}
      </View>
    </View>
  );
};

const LegendKey: FC<{ color: string; label: string; weight: number }> = ({ color, weight, label }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row basis-1/2 items-center')}>
      <Text style={tailwind('text-default shrink-0 text-sm font-mono font-medium w-12 text-right')}>
        {formatPercent(weight, 1, false)}
      </Text>
      <View style={[tailwind('rounded-full mr-1 mx-2'), { backgroundColor: color, height: 8, width: 8 }]} />{' '}
      <Text numberOfLines={1} style={tailwind('text-grey text-sm')}>
        {label}
      </Text>
    </View>
  );
};
