import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export const CashLogo = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('h-11 w-11 items-center justify-center rounded-xl')}>
      <Text style={tailwind('text-3xl')}>💵</Text>
    </View>
  );
};
