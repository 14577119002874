import React from 'react';
import { View } from 'react-native';
import { colors, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { Tick } from '../icons';

export type Props = {
  value: boolean;
  isDisabled?: boolean;
  onValueChange: (value: boolean) => void;
  /** optional label on right */
  labelOnRight?: string;
};

export const CheckBox: React.FC<Props> = ({ labelOnRight, onValueChange, value, isDisabled }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      style={tailwind('flex-row items-center')}
      onPress={() => onValueChange(!value)}
      disabled={isDisabled}
      accessibilityLabel={labelOnRight}
    >
      <View
        style={[
          tailwind('w-5 h-5 flex-row items-center justify-center border-2 rounded-md'),
          value ? tailwind('border-teal-700 bg-teal-800') : tailwind('border-warmGray-600'),
          isDisabled && tailwind('bg-coolGray-200'),
        ]}
      >
        {value && <Tick fill={colors.white} height={12} width={12} />}
      </View>
      {labelOnRight && <Text style={tailwind('text-warmGray-400 text-sm pl-2 flex-1')}>{labelOnRight}</Text>}
    </Pressable>
  );
};
