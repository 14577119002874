import { useEffect, useState } from 'react';
import { Image } from 'react-native';
import { FEED_ITEM_TYPES } from '../constants/feed';
import {
  DiscoverCategoryType,
  GetDiscoverQuery,
  useGetBaseStackQuery,
  useGetDiscoverQuery,
  useGetFeedQuery,
  useGetMostWatchlistedQuery,
  useGetStacksQuery,
  useGetUserQuery,
} from '../generated/graphql';
import { CURATION_SQUARE_CARD_HEIGHT, CURATION_SQUARE_CARD_WIDTH } from '../old/discoverCards/CurationSquareCard';
import { formatDatoImageUrl } from '../services/datoImage';
import { usePersistedStore, useStore } from '../zustand/store';

/**
 * fire off some graphql queries when you switched to logged in to prime the cache
 */
export const usePrimeCache = () => {
  const userId = usePersistedStore((state) => state.userId);
  const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const isStaff = Boolean(usePersistedStore((state) => state.isStaff));
  const isStorybook = Boolean(useStore((state) => state.isStorybook));
  const [curationImagesRequested, setCurationImagesRequested] = useState(false);
  const skip = !isUserLoggedIn || isStorybook;

  // run any side effects once logged
  useEffect(() => {
    if (skip) return;
    console.log(`User logged in, running usePrimeCache`);
  }, [skip]);

  // preload user profile to retrieve profile rating
  useGetUserQuery({ variables: { id: userId ?? 0 }, skip });
  // Onboarding queries
  useGetStacksQuery({ skip: isStorybook });
  useGetBaseStackQuery({ skip: isStorybook });
  useGetMostWatchlistedQuery({ skip: isStorybook, variables: { loggedIn: isUserLoggedIn } });

  // preload some tabs
  useGetDiscoverQuery({
    variables: { loggedIn: isUserLoggedIn, isStaff },
    skip,
    // Only pre-fetch images on first completion
    onCompleted: !curationImagesRequested
      ? (data) => {
          prefetchDiscoverCurationImages(data);
          setCurationImagesRequested(true);
        }
      : undefined,
  });
  useGetFeedQuery({
    variables: { loggedIn: isUserLoggedIn, feedTypes: FEED_ITEM_TYPES },
    skip,
  });

  // return skip to make testing easier and perhaps one day some UI component might say "refreshing"
  // Dont need to use this hook response in App code probably
  return { skip };
};

/**
 * Helper to prefetch Curations discover category images after primeCache resolved
 *
 * @param data Discover category query data
 */
const prefetchDiscoverCurationImages = (data: GetDiscoverQuery) => {
  const curations = data.discover.find((i) => 'categoryType' in i && i.categoryType === DiscoverCategoryType.Curations);

  curations?.items?.forEach((item) => {
    if (item.__typename === 'CurationsCategoryFeedItem') {
      Image.prefetch(
        formatDatoImageUrl(item.imageUrl ?? '', {
          width: CURATION_SQUARE_CARD_WIDTH,
          height: CURATION_SQUARE_CARD_HEIGHT,
        }),
      );
    }
  });
};
