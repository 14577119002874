import { View } from 'react-native';
import { ReportRating } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatPercent } from '../../util/number';
import { MetricSquare } from '../MetricSquare';

type RatingProps = { buyCount: number; sellCount: number; holdCount: number; averageRating: ReportRating };

/**
 * The 3 rating squares
 *
 * @param root0
 * @param root0.buyCount
 * @param root0.sellCount
 * @param root0.holdCount
 * @param root0.averageRating
 */
export const ReportRatingSnippet: React.FC<RatingProps> = ({ buyCount, sellCount, holdCount, averageRating }) => {
  const tailwind = useTailwind();
  const total = buyCount + sellCount + holdCount;
  return (
    <>
      <View style={tailwind('flex-row')}>
        <MetricSquare
          title="Sell"
          numberText={formatPercent(sellCount / total, 0, false)}
          isHighlighted={averageRating === ReportRating.Sell}
          highlightColor={colors.red['500']}
          position="left"
          accessibilityLabel="Sell"
        />
        <MetricSquare
          title="Hold"
          numberText={formatPercent(holdCount / total, 0, false)}
          isHighlighted={averageRating === ReportRating.Hold}
          highlightColor={colors.warmGray['500']}
          position="middle"
          accessibilityLabel="Hold"
        />
        <MetricSquare
          accessibilityLabel="Buy"
          title="Buy"
          numberText={formatPercent(buyCount / total, 0, false)}
          isHighlighted={averageRating === ReportRating.Buy}
          highlightColor={colors.green['500']}
          position="right"
        />
      </View>
      <Text style={tailwind('text-sm text-warmGray-500 text-center pt-3')}>Rating based on {total} analyses</Text>
    </>
  );
};
