import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { last } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AllStackParamList } from '../../navigation';
import { useTailwind } from '../../theme';
import { Fab } from '../../ui/Button';
import { BaseLayerDiagram, BundlesLayerDiagram, StocksLayerDiagram } from '../../ui/LayerDiagram';
import { ProgressBar } from '../../ui/ProgressBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';
import { BaseBodyText, BundlesBodyText, InitialBodyText, StocksBodyText } from './BodyText';
import { InitialBodyUpper } from './BodyUpper';

const layerStates = ['Base', 'Bundles', 'Stocks'] as const;
type LayerStates = typeof layerStates[number];

type Props = NativeStackScreenProps<AllStackParamList, 'OnboardingGetStarted'>;

export const OnboardingGetStarted: FC<Props> = withReloadErrorBoundary(({ navigation, route }) => {
  const { isMarketing, showBackButton } = route?.params ?? {};

  const [layerState, setLayerState] = useState<LayerStates[] | null>(null);
  const { top } = useSafeAreaInsets();
  const tailwind = useTailwind();
  const BodyText = getBodyText(last(layerState));
  const BodyUpper = getBodyUpper(last(layerState));
  const bgStyle = getBgStyle(tailwind, last(layerState));

  // persist (then delete) if arrived from marketing site and should show email screen not sign up screen at end of onboarding wizard
  useEffect(() => {
    console.log(`Arrived on OnboardingGetStarted. Persisting isMarketing ${isMarketing}`);
    usePersistedStore.setState({ isMarketing });
  }, [isMarketing]);

  const onPressNext = () => {
    const nextState = getFirstUnvisitedLayerState(layerState);
    if (nextState) {
      return setLayerState([...(layerState ?? []), nextState]);
    } else {
      navigation.navigate('OnboardingAboutYou', undefined);
    }
  };

  return (
    <SafeAreaView>
      <ScrollView stickyHeaderIndices={[0]} contentContainerStyle={tailwind('pb-24')}>
        {/* Negative margin to color notch while using safe area view */}
        <View style={[tailwind('px-6'), { marginTop: -1 * top, paddingTop: top }, bgStyle]}>
          <TopBar hideBackButton={!showBackButton} endAdornment={<ProgressBar width={42} progress={1 / 6} />} />
        </View>
        <View style={[tailwind('px-6 pb-5'), bgStyle]}>
          <TitleBar text="Get started" />
          <BodyUpper />
        </View>
        <View style={tailwind('px-6')}>
          <BodyText />
        </View>
      </ScrollView>
      <Fab onPress={onPressNext} />
    </SafeAreaView>
  );
});

export const getFirstUnvisitedLayerState = (prevLayerStates: LayerStates[] | null) => {
  if (prevLayerStates === null) {
    return 'Base';
  }
  const remainingStatesToVisit = layerStates.filter((state) => !prevLayerStates.includes(state));
  if (remainingStatesToVisit.length === 0) {
    return;
  }
  return remainingStatesToVisit[0];
};

const getBgStyle = (tailwind: ReturnType<typeof useTailwind>, state?: LayerStates) => {
  switch (state) {
    case 'Base':
      return tailwind('bg-primary-lightest');
    case 'Bundles':
      return tailwind('bg-secondary-lightest');
    case 'Stocks':
      return tailwind('bg-tertiary-lightest');
    default:
      return tailwind('bg-primary-lightest');
  }
};

const getBodyText = (state?: LayerStates) => {
  switch (state) {
    case 'Base':
      return BaseBodyText;
    case 'Bundles':
      return BundlesBodyText;
    case 'Stocks':
      return StocksBodyText;
    default:
      return InitialBodyText;
  }
};

const getBodyUpper = (state?: LayerStates) => {
  switch (state) {
    case 'Base':
      return BaseLayerDiagram;
    case 'Bundles':
      return BundlesLayerDiagram;
    case 'Stocks':
      return StocksLayerDiagram;
    default:
      return InitialBodyUpper;
  }
};
