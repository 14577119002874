import { round } from 'lodash';
import { Component, CoreInstrumentFieldsFragment, Instrument } from '../../generated/graphql';
import { isNotNull } from '../../util/typeGuards';

export function flattenPortfolioComponents(
  baseComponents: Component[],
  baseItemWeight: number,
  bundleComponents: Component[],
  bundleItemWeight: number,
  stocks: CoreInstrumentFieldsFragment[],
  stockItemWeight: number,
) {
  const weightAdjustedBaseComponents = baseComponents
    .map((component) => {
      if (!component.instrument) return null;
      return {
        instrument: component.instrument,
        weight: component.weight * baseItemWeight,
      };
    })
    .filter(isNotNull);
  const weightAdjustedBundleComponents = bundleComponents
    .map((component) => {
      if (!component.instrument) return null;
      return {
        instrument: component.instrument,
        weight: component.weight * bundleItemWeight,
      };
    })
    .filter(isNotNull);
  const weightAdjustedStockComponents = stocks.map((instrument) => ({ instrument, weight: stockItemWeight }));

  const weightAdjustedComponents = [
    ...weightAdjustedBaseComponents,
    ...weightAdjustedBundleComponents,
    ...weightAdjustedStockComponents,
  ];
  const aggregatedComponents = weightAdjustedComponents.reduce<
    Record<string, { instrument: Pick<Instrument, 'id' | 'displayName' | 'ticker'>; weight: number }>
  >((prev, curr) => {
    if (!curr.instrument?.id) return prev;

    if (prev[curr.instrument.id]) {
      prev[curr.instrument.id].weight += curr.weight;
    } else {
      prev[curr.instrument.id] = curr;
    }

    // Round all weights
    prev[curr.instrument.id].weight = round(prev[curr.instrument.id].weight, 4);
    return prev;
  }, {});

  return Object.values(aggregatedComponents).map(({ instrument, weight }) => ({ instrument, weight }));
}
