import React from 'react';
import { View } from 'react-native';
import { ClosePrice, Currency, Idea, Position, QuotePrice } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatPercent, formatPrice } from '../../util/number';
import { isFiniteNumber } from '../../util/typeGuards';

type Props = Pick<Idea, 'referencePrice' | 'position' | 'performance'> &
  Pick<QuotePrice, 'midPrice'> &
  Pick<ClosePrice, 'price'> &
  Pick<Currency, 'iso'>;

export const ChangeSincePosted: React.FC<Props> = ({ midPrice, referencePrice, position, iso, performance }) => {
  const tailwind = useTailwind();
  const actualPrice = performance?.actualPrice;
  const priceDeltaPercent = performance?.priceChangeSincePosted ?? 0;
  const isActualReturnUp = position === Position.Long ? priceDeltaPercent >= 0 : priceDeltaPercent < 0;

  return (
    <View>
      <Text style={tailwind('text-xs text-warmGray-500')}>Change since posted</Text>
      {midPrice && referencePrice && isFiniteNumber(actualPrice) && (
        <View style={tailwind('flex-row')}>
          <Text style={tailwind('text-sm text-warmGray-800')}>{`${formatPrice(
            actualPrice - referencePrice,
            iso ?? '',
          )} (`}</Text>
          <Text style={[isActualReturnUp ? tailwind('text-green-500') : tailwind('text-red-600'), tailwind('text-sm')]}>
            {formatPercent(priceDeltaPercent, 2)}
          </Text>
          <Text style={tailwind('text-sm text-warmGray-800')}>{')'}</Text>
        </View>
      )}
    </View>
  );
};
