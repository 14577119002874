import { useNavigation } from '@react-navigation/native';
import { FC } from 'react';
import { Image, useWindowDimensions, View } from 'react-native';
import { Component } from '../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../navigation';
import { COVER_IMAGE_HEIGHT } from '../../screens/Stack/Stack';
import { useTailwind } from '../../theme';
import { Markdown } from '../../ui/Markdown';
import { Pressable } from '../../ui/Pressable';
import { DESKTOP_MAX_WIDTH } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { StackInstrumentPriceRow } from '../StackInstrumentPriceRow';
import { ScreenSidePadding } from '../StyledScreen';

export type Props = {
  title: string;
  imageUrl: string;
  body: string;
  components: Component[];
  totalCount: number;
};

export const StackBody: FC<Props> = ({ title, imageUrl, body, components, totalCount }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  const { width } = useWindowDimensions();
  const imageWidth = Math.min(width, DESKTOP_MAX_WIDTH);

  return (
    <View style={tailwind('flex-grow p-1')}>
      <Image
        style={{ height: COVER_IMAGE_HEIGHT, width: imageWidth }}
        resizeMode="cover"
        source={{ uri: imageUrl }}
        accessibilityIgnoresInvertColors
      />
      <ScreenSidePadding style={tailwind('pt-2')}>
        <Text style={tailwind('text-lg text-default font-semibold mt-3')}>{title}</Text>
        <Markdown>{body}</Markdown>
        <View style={tailwind('flex-row justify-between py-4')}>
          <Text style={tailwind('font-semibold text-default')}>{`Stocks (${totalCount})`}</Text>
          <Text style={tailwind('text-xs text-grey pr-1')}>Weights</Text>
        </View>
      </ScreenSidePadding>
      {components?.map(({ weight, instrument }, index) => (
        <Pressable
          accessibilityRole="button"
          key={instrument?.id ?? ''}
          onPress={() => navigation.navigate('Instrument', { instrumentId: instrument?.id ?? '' })}
          style={[tailwind('py-4 border-b border-warmGray-200 px-6'), index === 0 && tailwind('border-t')]}
        >
          <StackInstrumentPriceRow {...instrument} weight={weight ?? 0} />
        </Pressable>
      ))}
    </View>
  );
};
