import { useNavigation } from '@react-navigation/core';
import React, { useCallback } from 'react';
import { Image, View, ViewStyle } from 'react-native';
import { ENVIRONMENT } from '../../constants/env';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { usePersistedStore } from '../../zustand/store';
import { IconButton } from '../Button/IconButton';
import { ArrowLeft } from '../icons';
import { ScreenSidePadding } from '../StyledScreen';

export type Props = {
  /** can set to empty string for no title if you want */
  title?: string | null;
  showLogo?: boolean;
  hideBackButton?: boolean;
  redirectIfLoggedOut?: boolean;
  style?: ViewStyle;
  /** for search icon etc */
  endAdornment?: React.ReactNode;
  /** true if screen is modal and will render back arrow as "Close" */
  isModal?: boolean;
};

// Image src has to be known statically https://reactnative.dev/docs/images#static-image-resources
export const titleBarImageSource =
  ENVIRONMENT === 'local'
    ? require(`../../assets/images/local/icon-titlebar.png`)
    : ENVIRONMENT === 'dev'
    ? require(`../../assets/images/dev/icon-titlebar.png`)
    : ENVIRONMENT === 'preview'
    ? require(`../../assets/images/preview/icon-titlebar.png`)
    : require(`../../assets/images/prod/icon-titlebar.png`);

/**
 * TitleBar to be used directly under SafeAreaView
 */
export const TitleBar: React.VFC<Props> = ({
  style,
  title,
  showLogo,
  hideBackButton,
  endAdornment,
  redirectIfLoggedOut,
  isModal,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();
  const isUserLoggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const showEnvironment = ENVIRONMENT !== 'prod';

  // possibly can deep link to this screen, so if cant go back, go to Home Tabs or Login
  const handleBack = useCallback(() => {
    navigation.canGoBack()
      ? navigation.pop()
      : isUserLoggedIn
      ? navigation.navigate('Tabs')
      : navigation.navigate('LogIn');
  }, [isUserLoggedIn, navigation]);

  return (
    <ScreenSidePadding>
      <View style={[tailwind('flex-row py-4 items-center'), style]}>
        <View style={tailwind('flex-row items-center flex-1')}>
          {!hideBackButton &&
            (isModal ? (
              <Pressable
                accessibilityRole="button"
                hitSlop={{ top: 8, bottom: 8 }}
                redirectIfLoggedOut={redirectIfLoggedOut}
                onPress={handleBack}
                style={tailwind('pr-3 h-full')}
              >
                <Text style={tailwind('font-medium text-teal-800')}>Close</Text>
              </Pressable>
            ) : (
              <IconButton
                style={tailwind('mr-3')}
                redirectIfLoggedOut={redirectIfLoggedOut}
                onPress={handleBack}
                accessibilityLabel="Go Back"
                icon={<ArrowLeft height={24} width={24} />}
              />
            ))}

          {/* for logged out U icon redirect to sign up on press. Do nothing if logged in */}
          {showLogo && (
            <Pressable
              accessibilityRole="button"
              hitSlop={{ top: 8, bottom: 8 }}
              redirectIfLoggedOut={redirectIfLoggedOut}
              onPress={() => null}
              style={tailwind('pr-3 h-full')}
            >
              <Image
                source={titleBarImageSource}
                accessibilityIgnoresInvertColors={false}
                style={tailwind('h-9 w-9 rounded-lg mr-2')}
              />
            </Pressable>
          )}
          <Text
            style={tailwind('font-semibold text-warmGray-800 text-2xl flex-1')}
            numberOfLines={1}
            accessibilityRole="header"
            accessibilityLabel={title ? `Page title: ${title}` : 'Page title: Empty'}
          >
            {title}
          </Text>
        </View>

        <View style={tailwind('flex-row items-center')}>
          {showEnvironment && (
            <View>
              <Text style={[tailwind('text-warmGray-400 text-xs'), endAdornment ? tailwind('mr-2') : null]}>
                {ENVIRONMENT}
              </Text>
            </View>
          )}
          {endAdornment}
        </View>
      </View>
    </ScreenSidePadding>
  );
};
