import { useNavigation } from '@react-navigation/core';
import { Formik, FormikErrors } from 'formik';
import { flatten, isNull, round, sortBy } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Keyboard, ScrollView as RNScrollView, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import {
  AppEntityType,
  CreateIdeaMutationVariables,
  IdeaFormQuery,
  IdeaStatus,
  Position,
} from '../../generated/graphql';
import { useCreateIdea } from '../../hooks/idea';
import { usePostIdeaComment } from '../../hooks/mutations/usePostIdeaComment';
import { LoggedInStackNavigationProps } from '../../navigation';
import { Button } from '../../old/Button';
import { TextInput } from '../../old/inputs';
import { InstrumentChart } from '../../old/InstrumentChart';
import { InstrumentHeader } from '../../old/InstrumentHeader';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TutorialCard } from '../../old/TutorialCard';
import { analytics } from '../../services/analytics';
import { errorMessage } from '../../sheets/CommentSheet/Input';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { ScrollView } from '../../ui/ScrollView';
import { add, effectiveBusinessDate, formatDate, parseUTCDateFromString } from '../../util/date';
import { TutorialCardPersistedStore, usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { Stage } from './Stage';
import { Conviction, ConvictionOption } from './stages/Conviction';
import { Direction } from './stages/Direction';
import { PriceTarget } from './stages/PriceTarget';
import { Rationale, RationaleCategory } from './stages/Rationale';
import { TimeFrame, TimeframeOption } from './stages/Timeframe';

type Props = {
  instrument: IdeaFormQuery['instrument'];
  ideaRationaleCategories: IdeaFormQuery['ideaRationaleCategories'];
  now: Date;
};

export const FormBody: React.VFC<Props> = ({ instrument, ideaRationaleCategories, now }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  const scrollRef = React.useRef<RNScrollView>(null);
  const createIdeaTutorialDismissed = useTutorialCardPersistedStore(createIdeaTutorialDismissedSelector);
  const { nickname, userId } = usePersistedStore(({ nickname, userId }) => ({
    nickname,
    userId,
  }));

  // Track offsets outside state/useEffect flow to prevent layout jank in rationale pill animation
  const { current: offsets } = useRef({
    priceTarget: 0,
    rationales: 0,
    textInput: 0,
  });

  const [createIdea] = useCreateIdea({
    onError: (error) => {
      toast.show('Something went wrong, please try again.');
      console.error(`Failed to create idea`, error);
      console.error(error);
    },
  });
  const [postComment] = usePostIdeaComment(undefined, { refetchQueries: undefined, onError: undefined });

  // Scroll to text input when keyboard activated
  useEffect(() => {
    const showSubscription = Keyboard.addListener('keyboardDidShow', () => {
      scrollRef.current?.scrollTo({ y: offsets.textInput });
    });
    return () => {
      showSubscription.remove();
    };
  }, [scrollRef, offsets]);

  const currentPrice = getCurrentPrice(instrument.quotePrice);
  if (!currentPrice) {
    throw new Error(`Cannot fetch current price for instrument ${instrument.id}`);
  }

  const onSubmit = async (values: CreateIdeaSchema) => {
    // This will throw when failing and onError will execute
    const newIdea = await createIdea({
      variables: inputToRequest(values, instrument.id, now, currentPrice?.price),
    });

    // Toast contents depend on comment state
    let toastMessage = `Created Idea on ${newIdea.data?.createIdea?.instrument?.ticker}`;

    if (newIdea.data?.createIdea?.id && values.targetPrice) {
      analytics.track('Idea created', {
        ideaId: newIdea.data.createIdea.id ?? '',
        userId: `${userId}`,
        nickname: nickname ?? '',
        'Instrument ticker': instrument.ticker ?? '',
        'Instrument name': instrument.name ?? '',
        conviction: values.conviction,
        sentiment: values.position === Position.Long ? 'LONG' : 'SHORT',
        timeframe: values.timeframe,
        prediction: Math.round((100 * (values.targetPrice - currentPrice.price)) / currentPrice.price),
        rationales: rationaleNames(ideaRationaleCategories, values.rationales),
      });
      navigation.replace('Idea', { ideaId: newIdea.data?.createIdea?.id });
    }

    // Trim here as not using yup schema
    if (values.openingComment.trim()) {
      try {
        const commentResult = await postComment({
          variables: {
            ideaId: newIdea.data?.createIdea?.id ?? '',
            appEntityType: AppEntityType.Idea,
            text: values.openingComment,
          },
        });
        const commentValidationError = commentResult.data?.postComment?.error;
        if (commentValidationError) {
          toastMessage = `Created Idea on ${
            newIdea.data?.createIdea?.instrument?.ticker
          } but we need you to update your opening comment because: ${errorMessage(commentValidationError)}.`;
          console.error(new Error(commentValidationError.message));
        }
      } catch (e) {
        console.error(e);
        toastMessage = `Created Idea on ${newIdea.data?.createIdea?.instrument?.ticker} but something went wrong when creating your opening comment.`;
      }
    }

    // Longer toast as toast messages are lengthier too
    toast.show(toastMessage, { duration: 3000 });
  };

  const rationales = transformRationales(ideaRationaleCategories);
  return (
    <>
      <InstrumentHeader
        name={instrument.name ?? ''}
        ticker={instrument.ticker ?? ''}
        logoUrl={instrument.logoUrl ?? undefined}
        sector={instrument.sector?.name ?? undefined}
      />
      <KeyboardAvoidingView>
        <ScrollView ref={scrollRef}>
          <InstrumentChart
            closePriceSeries={instrument.closePriceSeries ?? []}
            now={now}
            currencyIso={instrument.currency?.iso ?? ''}
            currentPrice={currentPrice}
            initialInterval="1Y"
          />
          <ScreenSidePadding>
            {!createIdeaTutorialDismissed && (
              <TutorialCard
                title="Welcome to Idea Creation"
                onDismiss={() => useTutorialCardPersistedStore.setState({ createIdeaTutorialDismissed: true })}
                style={tailwind('mt-4')}
              >
                Now it{"'"}s time to express your views. Bullish? Bearish? Form an opinion on target price and timeline,
                and use the rationale tag cloud to express why you take this position.
              </TutorialCard>
            )}
          </ScreenSidePadding>
          <View style={tailwind('py-2')} />
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validateOnChange={false}
            validate={(values) => validate(values, currentPrice.price)}
          >
            {({ values, setFieldValue, handleChange, errors, handleSubmit, isSubmitting }) => (
              <>
                <Stage text="What is your sentiment on this stock?">
                  <Direction
                    position={values.position}
                    onPress={(position) => {
                      if (position !== values.position) {
                        setFieldValue('position', position);
                        setFieldValue('targetPrice', null);
                        analytics.track('Creating idea', { sentiment: position === Position.Long ? 'LONG' : 'SHORT' });
                      }
                    }}
                  />
                </Stage>
                <Stage
                  text="How much do you think it will move?"
                  subText="Set your price target"
                  offsetHandler={(offset) => {
                    offsets.priceTarget = offset;
                  }}
                >
                  <PriceTarget
                    currentPrice={currentPrice.price}
                    position={values.position}
                    target={values.targetPrice}
                    currency={instrument.currency?.iso ?? ''}
                    onChangeTarget={(target) => {
                      setFieldValue('targetPrice', target, true);
                    }}
                    errorMsg={errors.targetPrice}
                  />
                </Stage>
                <Stage text="How long do you think it will take?">
                  <TimeFrame
                    selected={values.timeframe}
                    onSelected={(timeframe) => {
                      setFieldValue('timeframe', timeframe);
                      analytics.track('Creating idea', { timeframe });
                    }}
                  />
                </Stage>
                <Stage text="How confident are you in this Idea?" subText="As a score out of 5">
                  <Conviction
                    conviction={values.conviction}
                    onSelected={(conviction) => {
                      setFieldValue('conviction', conviction);
                      analytics.track('Creating idea', { conviction });
                    }}
                  />
                </Stage>
                <Stage
                  text="Why do you think this?"
                  subText="Select at least one rationale pill"
                  offsetHandler={(offset) => {
                    offsets.rationales = offset;
                  }}
                >
                  <Rationale
                    errorMsg={Array.isArray(errors.rationales) ? errors.rationales.join(',') : errors.rationales}
                    rationales={rationales}
                    selectedTagIds={values.rationales}
                    onTagPress={(_, tag) => {
                      const alreadySelected = values.rationales.includes(tag);
                      const updatedRationales = alreadySelected
                        ? values.rationales.filter((rationale) => rationale !== tag)
                        : [...values.rationales, tag];
                      setFieldValue('rationales', updatedRationales, true);
                      analytics.track('Creating idea', {
                        rationale: rationaleNames(ideaRationaleCategories, [tag])[0],
                        selected: !alreadySelected,
                      });
                    }}
                  />
                </Stage>
                <Stage
                  text="Want to explain in a bit more detail?"
                  offsetHandler={(offset) => {
                    offsets.textInput = offset;
                  }}
                >
                  <TextInput
                    multiline
                    accessibilityLabel="Comment Input"
                    placeholder="Create the first comment and start the conversation"
                    onChangeText={handleChange('openingComment')}
                    style={tailwind('h-24 mt-2 py-2')}
                  />
                </Stage>
                <View style={tailwind('m-6')}>
                  <Button
                    loading={isSubmitting}
                    isDisabled={isSubmitting}
                    variant="primary"
                    text="Publish Idea"
                    onPress={() => {
                      const errors = validate(values, currentPrice.price);
                      if (errors.targetPrice) {
                        scrollRef.current?.scrollTo({ y: offsets.priceTarget, animated: true });
                      } else if (errors.rationales) {
                        scrollRef.current?.scrollTo({ y: offsets.rationales, animated: true });
                      }
                      handleSubmit();
                    }}
                  />
                </View>
              </>
            )}
          </Formik>
        </ScrollView>
      </KeyboardAvoidingView>
    </>
  );
};
type CreateIdeaSchema = {
  conviction: ConvictionOption;
  position: Position;
  targetPrice: number | null;
  timeframe: TimeframeOption;
  rationales: string[];
  openingComment: '';
};

const initialValues: CreateIdeaSchema = {
  conviction: 3,
  position: Position.Long,
  timeframe: 6,
  rationales: [],
  targetPrice: null,
  openingComment: '',
};

const validate = (values: CreateIdeaSchema, currentPrice: number): FormikErrors<CreateIdeaSchema> => {
  const errors: FormikErrors<CreateIdeaSchema> = {};
  if (isNull(values.targetPrice)) {
    errors.targetPrice = 'Price target is missing';
  } else {
    if (values.position === Position.Long && values.targetPrice < currentPrice) {
      errors.targetPrice = 'Target price must be at least the current price';
    }
    if (values.position === Position.Short && values.targetPrice > currentPrice) {
      errors.targetPrice = 'Target price must not exceed the current price';
    }
  }
  if (values.rationales.length < 1) {
    errors.rationales = 'Select at least 1 rationale';
  }

  return errors;
};

const inputToRequest = (
  values: CreateIdeaSchema,
  instrumentId: string,
  now: Date,
  currentPrice: number,
): CreateIdeaMutationVariables => {
  if (isNull(values.targetPrice)) throw new Error('Cannot submit form with no target price');
  const referencePrice = round(currentPrice, 12);
  const targetPrice = round(values.targetPrice, 12);
  const referenceDate = formatDate(effectiveBusinessDate(now), 'YYYY-MM-DD');
  const targetDate = formatDate(effectiveBusinessDate(add(now, values.timeframe, 'M')), 'YYYY-MM-DD');
  console.log(
    `Submitting idea on instrument ${instrumentId} with reference price ${referencePrice}, targetPrice ${targetPrice}, referenceDate ${referenceDate}, targetDate ${targetDate}`,
  );

  return {
    idea: {
      instrumentId,
      referenceDate,
      referencePrice,
      targetDate,
      targetPrice,
      position: values.position,
      conviction: values.conviction * 20,
      rationaleTagIds: values.rationales,
      status: IdeaStatus.Active,
      description: {},
    },
  };
};

const createIdeaTutorialDismissedSelector = (state: TutorialCardPersistedStore) => state.createIdeaTutorialDismissed;

const transformRationales = (categories?: IdeaFormQuery['ideaRationaleCategories']): RationaleCategory[] =>
  sortBy(categories, (category) => category.sortOrder).map((category) => ({
    id: category.id,
    name: category.name ?? '',
    tags: category.tags
      ? sortBy(category.tags, (tag) => tag.sortOrder)?.map((tag) => ({
          name: tag.name ?? '',
          id: tag.id,
          selected: false,
        }))
      : [],
  }));

const rationaleNames = (categories: IdeaFormQuery['ideaRationaleCategories'], rationaleIds: string[]): string[] =>
  flatten(categories.map((c) => c.tags ?? []))
    .filter((tag) => rationaleIds.includes(tag.id))
    .map((tag) => tag.name ?? '')
    .filter((n) => n.length > 0);

const getCurrentPrice = (
  quotePrice: IdeaFormQuery['instrument']['quotePrice'] | undefined,
): { price: number; date: Date } | undefined =>
  quotePrice?.midPrice && quotePrice.lastUpdated
    ? {
        price: quotePrice.midPrice,
        date: parseUTCDateFromString(quotePrice.lastUpdated),
      }
    : undefined;
