import _ from 'lodash';
import { useEffect, useState, VFC } from 'react';
import { View } from 'react-native';
import { usePrevious } from '../../hooks/usePrevious';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { Chip } from '../Chip';
import { PriceInput } from './PriceInput';
/**
 * When selected, means a custom input value should be entered
 */
export const CUSTOM_INPUT = '···' as const;
export type CustomInput = typeof CUSTOM_INPUT;

type Props = {
  value: number | null;
  /**
   * Function called on change event
   * isChipValue is true if a chip is selected, false if CUSTOM_INPUT is selected
   */
  onChange: (value: number | null, isChipValue?: boolean) => void;
  errorMsg?: string;
  options: number[];
  getChipText?: (amount: number) => string;
  /**
   * How many chips to present per row
   */
  numColumns: number;
  onChipSelect?: (value: number | CustomInput) => void;
  inputOptions?: {
    startAdornment?: string;
    endAdornment?: string;
  };
};

/**
 * A number select and input field in one component
 * If the value prop is not in the list of options, CUSTOM_INPUT is selected and an input box appears below the chips
 */
export const ChooseAmountInput: VFC<Props> = ({
  value,
  onChange,
  errorMsg,
  options,
  getChipText,
  numColumns,
  inputOptions,
  onChipSelect,
}) => {
  const tailwind = useTailwind();
  // Show custom input box if pre-filled with non existing chip value.
  const initialState = value === null || options.includes(value) ? value : CUSTOM_INPUT;
  const [selected, setSelected] = useState<number | CustomInput | null>(initialState);
  const chipFlexBasis = 100 / numColumns;

  useEffect(() => {
    /** Fire off selected event when a chip is selected  */
    if (selected) {
      onChipSelect?.(selected);
    }
  }, [selected, onChipSelect]);

  const previousOptions = usePrevious(options);
  /** Reset to initial state when options change */
  useEffect(() => {
    if (_.difference(previousOptions, options).length > 0) {
      setSelected(initialState);
    }
  }, [initialState, options, previousOptions]);

  return (
    <View>
      <View style={tailwind('flex-row flex-wrap pb-6')}>
        {options.map((v) => (
          <View style={[tailwind('px-0.5 pb-2'), { flexBasis: `${chipFlexBasis}%` }]} key={v}>
            <Chip
              textSize="base"
              rounded="md"
              size="md"
              text={getChipText ? getChipText(v) : `${v}`}
              selected={v === selected}
              onPress={() => {
                setSelected(v);
                onChange(v, true);
              }}
              style={tailwind('w-full')}
            />
          </View>
        ))}
        <View style={[tailwind('px-0.5 pb-2'), { flexBasis: `${chipFlexBasis}%` }]}>
          <Chip
            textSize="base"
            rounded="md"
            size="md"
            text={CUSTOM_INPUT}
            style={tailwind('w-full')}
            selected={selected === CUSTOM_INPUT}
            onPress={() => {
              setSelected(CUSTOM_INPUT);
            }}
          />
        </View>
      </View>
      {selected === CUSTOM_INPUT && (
        <PriceInput
          value={value}
          startAdornment={inputOptions?.startAdornment}
          endAdornment={inputOptions?.endAdornment}
          onChange={onChange}
          error={Boolean(errorMsg)}
          disableAutoFocus
        />
      )}
      {Boolean(errorMsg) && <Text style={tailwind('text-red-600 my-2')}>{errorMsg}</Text>}
    </View>
  );
};
