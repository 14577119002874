import { FC } from 'react';
import { Image, View } from 'react-native';
import { DatoImageFile, Instrument, StackContent } from '../../generated/graphql';
import { shadowMd, useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { isNotNull } from '../../util/typeGuards';
import { URound } from '../icons';

export type Props = Pick<StackContent, 'name' | 'description'> &
  Pick<DatoImageFile, 'url'> & {
    instruments: Partial<Instrument>[];
    totalCount: number;
  };

const SLICE_LENGTH = 4;

export const StackCard: FC<Props> = ({ instruments, name, description, totalCount }) => {
  const tailwind = useTailwind();
  const slicedLogoUrls = instruments
    .slice(0, SLICE_LENGTH)
    .map((instrument) => instrument?.logoUrl)
    .filter(isNotNull);

  const restLength = totalCount > SLICE_LENGTH ? totalCount - SLICE_LENGTH : undefined;

  return (
    <View style={[tailwind('px-3 py-4 rounded-lg'), shadowMd]}>
      <Text numberOfLines={1} style={tailwind('text-warmGray-600 text-lg')}>
        {name}
      </Text>
      <View>
        <View style={tailwind('flex-row justify-between -mt-1')}>
          <View style={tailwind('flex-row')}>
            <Text style={tailwind('text-warmGray-500 text-xs pr-0.5')}>by Upside</Text>
            <URound />
          </View>
          <View style={tailwind('flex-row justify-end self-center')}>
            {slicedLogoUrls.map((uri) => (
              <Image
                style={[tailwind('h-8 w-8 bg-white rounded-full border-warmGray-300 -ml-2'), { borderWidth: 1 }]}
                source={{ uri }}
                resizeMode="contain"
                accessibilityIgnoresInvertColors
                key={uri}
              />
            ))}
            {restLength && (
              <View
                style={[
                  tailwind('h-8 w-8 items-center justify-center -ml-2 border-warmGray-300 rounded-full bg-white'),
                  { borderWidth: 1 },
                ]}
              >
                <Text style={tailwind('text-warmGray-600 text-xs')}>{`+${restLength}`}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
      <Text numberOfLines={3} style={tailwind('text-warmGray-600 text-xs pt-2')}>
        {description}
        {/* Hacky way of ensuring 3 lines worth of space are rendered when text is only 1 line */}
        {'\n\n'}
      </Text>
    </View>
  );
};
