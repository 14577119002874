import { gql, NetworkStatus } from '@apollo/client';
import { BottomTabNavigationProp, BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CompositeNavigationProp, CompositeScreenProps, useNavigation, useScrollToTop } from '@react-navigation/native';
import { NativeStackNavigationProp, NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useMemo } from 'react';
import { FlatList, ListRenderItem, RefreshControl, View } from 'react-native';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { STACK_FIELDS } from '../../fragments/stack';
import {
  CurationsCategoryFeedItem,
  DiscoverCategoryType,
  FeedItem,
  GetDiscoverQuery,
  useGetDiscoverQuery,
} from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { LoggedInStackNavigationProps } from '../../navigation';
import { DiscoverStackParamList } from '../../navigation/DiscoverNavigator';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { TabsParamList } from '../../navigation/TabsNavigator';
import { MagnifyingGlass } from '../../old/icons';
import { SideScrollHeader } from '../../old/SideScrollHeader';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { TitleBarMainIcons } from '../../old/TitleBarMainIcons';
import { isTutorialCardDummy, TutorialCard, TutorialCardDummy, tutorialCardDummy } from '../../old/TutorialCard';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';
import { isNotNull } from '../../util/typeGuards';
import { Unpack } from '../../util/types';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { TutorialCardPersistedStore, usePersistedStore, useTutorialCardPersistedStore } from '../../zustand/store';
import { CurationSideScroll } from './CurationSideScroll';
import { DiscoverSideScroll } from './DiscoverSideScroll';
import { StacksSideScroll } from './StacksSideScroll';

/** limit to 10 items for side scroll */
/* eslint-disable graphql/template-strings */
export const GET_DISCOVER = gql`
  ${CORE_INSTRUMENT_FIELDS}
  ${STACK_FIELDS}

  query GetDiscover($loggedIn: Boolean!, $isStaff: Boolean!) {
    stacks @include(if: $isStaff) {
      stacks {
        ...StackFields
      }
    }
    discover {
      id
      itemType
      categoryType
      title
      items(limit: 10) {
        ... on MostWatchlistedFeedItem {
          id
          instrument {
            ...CoreInstrumentFields
          }
        }
        ... on DiscoverCategoryFeedItem {
          id
          instrument {
            ...CoreInstrumentFields
          }
        }
        ... on SectorDiscoverCategoryFeedItem {
          id
          type
          sector {
            id
            name
            logoUrl
          }
        }
        ... on SignificantDailyMoverFeedItem {
          id
          latestReturn
          zScore
          date
          instrument {
            ...CoreInstrumentFields
          }
        }

        ... on CurationsCategoryFeedItem {
          id
          type
          created
          name
          imageUrl
        }
      }
    }
  }
`;

export type DiscoverCategory = Unpack<GetDiscoverQuery['discover']>;
export type DiscoverFeedItem = Unpack<DiscoverCategory['items']>;

export type DiscoverNavigationProps = CompositeNavigationProp<
  NativeStackNavigationProp<DiscoverStackParamList, 'Discover'>,
  CompositeNavigationProp<
    BottomTabNavigationProp<TabsParamList, 'DiscoverTab'>,
    NativeStackNavigationProp<LoggedInStackParamList>
  >
>;

export type Props = CompositeScreenProps<
  NativeStackScreenProps<DiscoverStackParamList>,
  CompositeScreenProps<
    BottomTabScreenProps<TabsParamList, 'DiscoverTab'>,
    NativeStackScreenProps<LoggedInStackParamList>
  >
>;

export const Discover: React.FC<Props> = withReloadErrorBoundary(() => {
  const discoverTutorialDismissed = useTutorialCardPersistedStore(discoverTutorialDismissedSelector);
  const { loggedIn, isStaff } = usePersistedStore(({ isUserLoggedIn: loggedIn, isStaff }) => ({ loggedIn, isStaff }));
  // scroll to top if press tab
  const ref = React.useRef<FlatList | null>(null);
  useScrollToTop(ref);

  const { data, error, refetch, networkStatus } = useGetDiscoverQuery({
    variables: { loggedIn, isStaff: !!isStaff },
  });

  const { refreshing, onRefresh } = useRefresh(refetch);

  if (error) {
    throw error;
  }
  const flatListData = useMemo(() => {
    const items =
      !discoverTutorialDismissed && data?.discover?.length
        ? [tutorialCardDummy, data?.stacks, ...(data?.discover ?? [])]
        : [data?.stacks, ...(data?.discover ?? [])];
    return items.filter(isNotNull);
  }, [discoverTutorialDismissed, data]);

  return (
    <SafeAreaView edges={['top', 'left', 'right']}>
      <TitleBar title="Discover" hideBackButton showLogo endAdornment={<TitleBarMainIcons />} />

      {networkStatus === NetworkStatus.loading ? (
        <DiscoverSkeleton />
      ) : (
        <>
          <FlatList
            ref={ref}
            scrollEnabled={true}
            data={flatListData}
            renderItem={renderItem}
            refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
            keyExtractor={keyExtractor}
            contentContainerStyle={{ flex: 0 }}
          />
        </>
      )}
    </SafeAreaView>
  );
});

const keyExtractor = (item: Unpack<GetDiscoverQuery['discover']> | TutorialCardDummy | GetDiscoverQuery['stacks']) => {
  return item && 'id' in item ? item.id : item?.__typename ?? '';
};

/** only render categories we expect. Add to this switch for new itemTypes */
export const renderItem: ListRenderItem<DiscoverCategory | TutorialCardDummy | GetDiscoverQuery['stacks']> = ({
  item,
}) => {
  return <DiscoverCategorySwitch item={item} />;
};

const DiscoverCategorySwitch: React.FC<{ item: DiscoverCategory | TutorialCardDummy | GetDiscoverQuery['stacks'] }> = ({
  item,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<DiscoverNavigationProps>();
  if (!item) {
    return null;
  }

  if (isTutorialCardDummy(item)) {
    return (
      <ScreenSidePadding>
        <TutorialCard
          title="Welcome to Discover"
          onDismiss={() => useTutorialCardPersistedStore.setState({ discoverTutorialDismissed: true })}
          Icon={MagnifyingGlass}
          style={tailwind('my-4')}
        >
          These diverse categories are your way to delve deeper into our universe of stocks, and to surface topical and
          noteworthy companies.{'\n\n'}Tap the arrows to see the fuller lists. Tap the star to add an instrument to your
          watchlist and see insights in your Feed.
        </TutorialCard>
      </ScreenSidePadding>
    );
  }

  if (item.__typename === 'StacksPayload') {
    return (
      <View style={tailwind('pb-2')}>
        <SideScrollHeader onPress={() => navigation.navigate('Stacks')} text={'Stacks'} />
        <StacksSideScroll items={item.stacks.filter(isNotNull)} />
      </View>
    );
  }

  // Help TypeScript assert that only discover categories are available in switch block
  if (!('categoryType' in item)) {
    return null;
  }

  switch (item.categoryType) {
    case DiscoverCategoryType.MostWatchlisted:
    case DiscoverCategoryType.InstrumentRecommendation:
    case DiscoverCategoryType.SignificantMovers:
      return <DiscoverCategoryRow category={item} />;
    case DiscoverCategoryType.Sectors:
      return <DiscoverCategoryRow category={item} hideSeeAll />;
    case DiscoverCategoryType.Curations:
      return (
        <View style={tailwind('pb-2')}>
          <SideScrollHeader hideArrow text={item.title ?? ''} />
          <CurationSideScroll
            discoverCategoryType={item.categoryType}
            data={item.items?.filter(isCurationCategoryFeedItem) ?? []}
          />
        </View>
      );
    default:
      return null;
  }
};

const discoverTutorialDismissedSelector = (state: TutorialCardPersistedStore) => state.discoverTutorialDismissed;
const isCurationCategoryFeedItem = (item: Partial<FeedItem>): item is CurationsCategoryFeedItem =>
  item.__typename === 'CurationsCategoryFeedItem';

const DiscoverCategoryRow: React.FC<{
  category: DiscoverCategory;
  hideSeeAll?: boolean;
}> = ({ category, hideSeeAll }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  return (
    <View style={tailwind('pb-2')}>
      <SideScrollHeader
        hideArrow={hideSeeAll}
        text={category.title ?? ''}
        onPress={() => {
          if (!hideSeeAll) {
            navigation.push('DiscoverCategory', {
              categoryId: category.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              categoryType: category.categoryType!,
            });
          }
        }}
      />
      <DiscoverSideScroll accessibilityLabel={category.title ?? ''} items={category.items} />
    </View>
  );
};

const DiscoverSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView style={tailwind('ml-6 mb-8')}>
      {new Array(10).fill(null).map((_, i) => (
        <DiscoverSkeletonRow key={i} />
      ))}
    </SkeletonView>
  );
};

const DiscoverSkeletonRow = () => {
  const tailwind = useTailwind();
  return (
    <>
      <Skeleton style={tailwind('w-44 h-4 rounded mb-4')} />
      <View style={tailwind('flex-row mb-8')}>
        {new Array(10).fill(null).map((_, i) => (
          <Skeleton key={i} style={tailwind('h-36 w-40 mr-3 rounded')} />
        ))}
      </View>
    </>
  );
};
