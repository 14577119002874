import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const ArrowLeft = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.707 5.707a1 1 0 00-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 100-2H4.414l5.293-5.293z"
        fill="#0F766E"
      />
    </Svg>
  );
};
