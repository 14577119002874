import { Image } from 'react-native';
import { useTailwind } from '../../theme';

export const StockIcon: React.VFC<{ logoUrl: string }> = ({ logoUrl }) => {
  const tailwind = useTailwind();
  return (
    <Image
      style={[tailwind('w-4 h-4')]}
      source={{ uri: logoUrl }}
      accessibilityIgnoresInvertColors={true}
      resizeMode="contain"
    />
  );
};
