import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { FC } from 'react';
import { View } from 'react-native';
import { STACK_FIELDS } from '../../fragments/stack';
import { useGetBaseStackQuery } from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { AllStackParamList } from '../../navigation';
import { StackBody } from '../../old/StackBody';
import { useTailwind } from '../../theme';
import { Fab } from '../../ui/Button';
import { CollectionCard } from '../../ui/CollectionCard';
import { PortfolioWeights } from '../../ui/PortfolioWeights';
import { ProgressBar } from '../../ui/ProgressBar';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { TitleBar } from '../../ui/TitleBar';
import { TopBar } from '../../ui/TopBar';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePortfolioIntendedPersistedStore } from '../../zustand/store';

export const GET_BASE_STACK = gql`
  ${STACK_FIELDS}
  query GetBaseStack {
    baseStack {
      stack {
        ...StackFields
      }
    }
  }
`;

export type Props = NativeStackScreenProps<AllStackParamList, 'OnboardingSelectBase'>;

export const OnboardingSelectBase: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const { present } = useBottomSheet();
  const { data, error } = useGetBaseStackQuery();
  const { setBase, baseLayer, stackLayer, stockLayer } = usePortfolioIntendedPersistedStore(
    ({ setBase, baseLayer, stackLayer, stockLayer }) => ({ setBase, baseLayer, stackLayer, stockLayer }),
  );

  if (error) {
    throw error;
  }

  const stack = data?.baseStack?.stack;

  const onPressNext = () => {
    setBase([stack?.id ?? '']);
    return navigation.navigate('OnboardingSelectBundles');
  };

  return (
    <SafeAreaView>
      <ScrollView stickyHeaderIndices={[0]} contentContainerStyle={tailwind('pb-24 px-6')}>
        <TopBar endAdornment={<ProgressBar width={42} progress={3 / 6} />} />
        <TitleBar text="Confirm your base" />
        <Text style={tailwind('text-grey text-sm pb-5 pt-8')}>Current layer</Text>
        <PortfolioWeights
          weights={{ base: baseLayer.weight, bundles: stackLayer.weight, stocks: stockLayer.weight }}
          highlight="BASE"
        />
        <Text style={tailwind('text-base text-default font-semibold pt-10 pb-8')}>Handpicked for you</Text>
        {data && (
          <View style={tailwind('pb-5')} key={stack?.id}>
            <CollectionCard
              id={stack?.id ?? ''}
              name={stack?.content?.name ?? ''}
              description={stack?.content?.description ?? ''}
              components={stack?.components?.nodes ?? []}
              componentCount={stack?.components?.pageInfo.totalCount ?? 0}
              onPressOverview={() =>
                present(
                  <StackBody
                    title={stack?.content?.name ?? ''}
                    body={stack?.content?.description ?? ''}
                    components={stack?.components?.nodes ?? []}
                    imageUrl={stack?.content?.coverImage?.url ?? ''}
                    totalCount={stack?.components?.pageInfo.totalCount ?? 0}
                  />,
                  { scrollView: true, snapPoint: '80%' },
                )
              }
              collectionType="base"
              selected={true}
            />
          </View>
        )}
      </ScrollView>
      {!!data?.baseStack.stack && <Fab onPress={onPressNext} />}
    </SafeAreaView>
  );
});
