import { FC } from 'react';
import { View } from 'react-native';
import { Instrument } from '../../generated/graphql';
import { InstrumentLogo } from '../../old/InstrumentLogo';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatPercent } from '../../util/number';

type Props = Pick<Instrument, 'id' | 'ticker' | 'logoUrl' | 'displayName'> & { weight: number };

export const InstrumentWeightingRow: FC<Props> = ({ ticker, displayName, weight, logoUrl }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row justify-between')}>
      <View style={tailwind('flex-row flex-shrink')}>
        <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
        <View style={tailwind('flex-shrink ml-2')}>
          <View>
            <Text style={tailwind('text-sm font-medium text-default')} numberOfLines={1}>
              {displayName}
            </Text>
          </View>
          <View style={tailwind('flex-row items-center')}>
            <Text style={tailwind('text-sm text-grey')} numberOfLines={1}>
              {ticker}
            </Text>
          </View>
        </View>
      </View>
      <View style={tailwind('justify-center')}>
        <Text style={tailwind('font-medium text-right text-default text-base justify-center ml-4')}>
          {formatPercent(weight, undefined, false)}
        </Text>
      </View>
    </View>
  );
};
