import { FC } from 'react';
import { TextProps } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { ScreenSidePadding } from '../StyledScreen';

/** A container to help style bottom sheet content. Content in a bottom sheet requires more vertical padding than usual */
export const InfoBottomSheetBody: FC = ({ children }) => {
  const tailwind = useTailwind();
  return <ScreenSidePadding style={tailwind('pb-12 pt-6')}>{children}</ScreenSidePadding>;
};

export const InfoBottomSheetHeading: FC<{ style?: TextProps['style'] }> = ({ children, style }) => {
  const tailwind = useTailwind();
  return <Text style={[tailwind('text-base font-medium text-warmGray-800 pb-6'), style]}>{children}</Text>;
};

export const InfoBottomSheetText: FC<{ style?: TextProps['style'] }> = ({ children, style }) => {
  const tailwind = useTailwind();
  return <Text style={[tailwind('text-sm text-warmGray-800'), style]}>{children}</Text>;
};
