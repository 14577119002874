import React from 'react';
import { View } from 'react-native';
import { Position } from '../../../generated/graphql';
import { SentimentChip } from '../../../old/Chip/SentimentChip';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';

type Props = {
  position: Position;
  onPress: (position: Position) => void;
};

export const Direction: React.VFC<Props> = ({ position, onPress }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('mt-4 flex-row justify-between')}>
      <View style={tailwind('mr-1 flex-grow')}>
        <SentimentChip
          sentiment="bullish"
          selected={position === Position.Long}
          onPress={() => {
            onPress(Position.Long);
          }}
        />
        <Text style={tailwind('text-xs pt-1 text-center text-warmGray-400 font-normal')}>Price will go up</Text>
      </View>
      <View style={tailwind('ml-1 flex-grow')}>
        <SentimentChip
          sentiment="bearish"
          selected={position === Position.Short}
          onPress={() => {
            onPress(Position.Short);
          }}
        />
        <Text style={tailwind('text-xs pt-1 text-center text-warmGray-400 font-normal')}>Price will go down</Text>
      </View>
    </View>
  );
};
