import Animated from 'react-native-reanimated';
import { Vector } from 'react-native-redash';

export type Props = {
  currentPrice: [number, number];
  displayPrice: Animated.SharedValue<[number, number]>;
  dragging: Animated.SharedValue<boolean>;
};

export const clamp = (value: number, min: number, max: number): number => {
  'worklet';
  if (value >= max) {
    return max;
  } else if (value <= min) {
    return min;
  }
  return value;
};

export const getIndex = (width: number, x: number, points: [number, number][]): number => {
  'worklet';
  const halfWidth = width / 2;
  if (x < halfWidth) {
    x = x - (24 * (halfWidth - x)) / halfWidth;
  }

  const closestTimeIndex = clamp(Math.round((points.length - 1) * (x / width)), 0, points.length - 1);
  return closestTimeIndex;
};

export const setPosition = (
  prices: [number, number][],
  floor: number,
  ceiling: number,
  width: Animated.SharedValue<number>,
  height: number,
  position: Animated.SharedValue<Vector>,
  displayPrice: Animated.SharedValue<[number, number]>,
  x: number,
) => {
  'worklet';
  const closestTimeIndex = getIndex(width.value, x, prices);
  const price = prices[closestTimeIndex];
  position.value = {
    x: width.value * (closestTimeIndex / (prices.length - 1)),
    y: height - (height * (price[1] - floor)) / (ceiling - floor),
  };
  displayPrice.value = price;
};
