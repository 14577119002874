import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const SendArrow: React.VFC<SvgProps> = (props: SvgProps) => (
  <Svg width={32} height={32} viewBox="0 0 24 24" fill="none" {...props}>
    <Path
      d="M11.875 22.75c6.006 0 10.875-4.869 10.875-10.875S17.881 1 11.875 1 1 5.869 1 11.875 5.869 22.75 11.875 22.75z"
      stroke={props.color ?? '#55A09A'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
    <Path d="M11.875 17.875v-12" stroke={props.color ?? '#55A09A'} strokeWidth={2} strokeMiterlimit={10} />
    <Path
      d="M7.75 10l4.125-4.125L16 10"
      stroke={props.color ?? '#55A09A'}
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);
