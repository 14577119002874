import _ from 'lodash';
import { VFC } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { DatoBlog } from '../../../generated/graphql';
import { useTailwind } from '../../../theme';
import { BLOG_CARD_WIDTH, BlogCard } from './BlogCard';

const BLOG_CARD_SPACING = 16;

type BlogSnippet = Pick<DatoBlog, 'id' | 'title' | 'publishedDate' | 'coverImage'>;

type Props = {
  data: BlogSnippet[];
};
export const BlogSideScroll: VFC<Props> = ({ data }) => {
  const tailwind = useTailwind();
  return (
    <FlatList
      horizontal
      contentContainerStyle={tailwind('px-6 py-2')}
      ItemSeparatorComponent={() => <View style={{ paddingLeft: BLOG_CARD_SPACING }} />}
      showsHorizontalScrollIndicator={false}
      // Chunk to present 2 rows of data
      data={_.chunk(data, 2)}
      keyExtractor={keyExtractor}
      snapToInterval={BLOG_CARD_WIDTH + BLOG_CARD_SPACING}
      snapToAlignment="start"
      decelerationRate="fast"
      renderItem={renderItem}
    />
  );
};

const keyExtractor = ([blogA, blogB]: BlogSnippet[]) => `${blogA?.id ?? ''}${blogB?.id ?? ''}`;

// Expect received items to be chunked in tuples of [BlogPost, BlogPost] since they're rendered as 2 rows
const renderItem: ListRenderItem<BlogSnippet[]> = ({ item }) => (
  <View>
    <BlogCard {...item[0]} />
    <Spacer />
    {item[1] && <BlogCard {...item[1]} />}
  </View>
);

const Spacer: React.FC = () => {
  const tailwind = useTailwind();
  return <View style={tailwind('py-2')} />;
};
