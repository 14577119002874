import React from 'react';
import { CoreInstrumentFieldsFragment } from '../../generated/graphql';
import { useInstrumentWatchlistToggle } from '../../hooks/mutations/useInstrumentWatchlistToggle';
import { IconButton } from '../Button/IconButton';
import { Star, StarFilled } from '../icons/Star';

/**
 * Locally Optimistic render of filled/not-filled star.
 *
 */
export const WatchlistButton: React.VFC<
  Pick<CoreInstrumentFieldsFragment, 'id' | 'name' | 'ticker' | 'watchlistId'>
> = ({ id, ticker, name, watchlistId }) => {
  // localWatchlistId is optimistic and reflects final value, also do not use loading state as being optimistic
  const { toggleWatchlist, localWatchlistId } = useInstrumentWatchlistToggle({ id, name, ticker, watchlistId });

  return (
    <IconButton
      accessibilityLabel="Watchlist Star Icon"
      onPress={() => toggleWatchlist()}
      hitSlop={{ top: 30, right: 30, bottom: 30, left: 30 }}
      accessibilityRole={'button'}
      accessibilityHint={localWatchlistId ? 'Remove from watchlist' : 'Watch'}
      icon={localWatchlistId ? <StarFilled height={24} width={24} /> : <Star height={24} width={24} />}
      redirectIfLoggedOut
    />
  );
};
