import { LinearGradient } from 'expo-linear-gradient';
import { FC } from 'react';
import { ImageBackground, View } from 'react-native';
import { Maybe } from '../../../generated/graphql';
import { formatDatoImageUrl } from '../../../services/datoImage';
import { shadowMd, useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';

export type Props = {
  id?: Maybe<string>;
  name?: Maybe<string>;
  imageUrl?: Maybe<string>;
};

export const CURATION_SQUARE_CARD_WIDTH = 164;
export const CURATION_SQUARE_CARD_HEIGHT = 136;
export const CurationSquareCard: FC<Props> = ({ imageUrl, name }) => {
  const tailwind = useTailwind();
  return (
    <View style={[shadowMd, tailwind('rounded-lg')]}>
      <ImageBackground
        source={{
          uri: imageUrl
            ? formatDatoImageUrl(imageUrl, { width: CURATION_SQUARE_CARD_WIDTH, height: CURATION_SQUARE_CARD_HEIGHT })
            : '',
        }}
        resizeMethod="auto"
        resizeMode="cover"
        style={{ width: CURATION_SQUARE_CARD_WIDTH, height: CURATION_SQUARE_CARD_HEIGHT }}
        imageStyle={[tailwind('rounded-lg')]}
      >
        <View style={[tailwind('flex-grow justify-end')]}>
          <View style={tailwind('rounded-lg overflow-hidden ')}>
            {/* Use linear gradient to darken just the text without darkening image further */}
            <LinearGradient colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.6)']}>
              <Text style={[tailwind('text-base font-medium mb-2 mx-3 pt-2.5 text-white')]}>{name ?? ''}</Text>
            </LinearGradient>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};
