import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Academy = (props: SvgProps) => (
  <Svg width={28} height={28} viewBox={'0 0 28 28'} fill="none" {...props}>
    <Path
      d="M7.429 11.579V20c0 2.093 3.837 3.79 8.571 3.79s8.571-1.697 8.571-3.79v-8.421M21.143 25.053 23.286 28H19l2.143-2.947Zm0 0v-11.79"
      stroke="#0F766E"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
    <Path
      d="M16 15.79 4 9.895 16 4l12 5.895-12 5.895Z"
      stroke="#0F766E"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="square"
    />
  </Svg>
);
