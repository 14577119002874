import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import React from 'react';
import { View, ViewProps } from 'react-native';
import { Financials } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { formatPercent, getPercentageDiff } from '../../util/number';
import { formatFinancialValue } from './util';

type FinancialsRows =
  | 'totalRevenue'
  | 'costOfRevenue'
  | 'grossProfit'
  | 'operatingExpenses'
  | 'operatingIncome'
  | 'preTaxIncome'
  | 'taxAndOtherDeductions'
  | 'netIncome'
  | 'netMargin';

const financialsRows: Array<FinancialsRows> = [
  'totalRevenue',
  'costOfRevenue',
  'grossProfit',
  'operatingExpenses',
  'operatingIncome',
  'preTaxIncome',
  'taxAndOtherDeductions',
  'netIncome',
  'netMargin',
];

const financialsRowNames: Record<FinancialsRows, string> = {
  totalRevenue: 'Total revenue',
  costOfRevenue: 'Cost of revenue',
  grossProfit: 'Gross profit',
  operatingExpenses: 'Operating expenses',
  operatingIncome: 'Operating income',
  preTaxIncome: 'Pre-tax income',
  taxAndOtherDeductions: 'Taxes and other deductions',
  netIncome: 'Net income',
  netMargin: 'Net margin (%)',
};

const borderedRows: (keyof Financials)[] = ['grossProfit', 'netMargin'];

export const FinancialsTable: React.VFC<{
  financials: Financials;
  prevFinancials?: Financials;
  variant: 'Quarterly' | 'Annual';
}> = ({ financials, prevFinancials, variant }) => {
  const tailwind = useTailwind();
  const financialsDateText = dayjs(new Date(financials.date ?? '')).format('MMM YYYY');
  return (
    <View>
      <Row style={tailwind('mt-3')}>
        {/* Empty top left heading cell */}
        <View style={tailwind('flex-auto flex-nowrap')} />
        <View style={tailwind('justify-center')}>
          <Text style={tailwind('text-xs text-warmGray-500')}>{financialsDateText}</Text>
        </View>
        {prevFinancials && (
          <View style={tailwind('w-16 text-right')}>
            <Text style={tailwind('text-xs text-warmGray-500 text-right')}>
              % {variant === 'Quarterly' ? 'QoQ' : 'YoY'} Change
            </Text>
          </View>
        )}
      </Row>

      {financialsRows.map((propertyName, index) => (
        <FinancialsRow
          key={index}
          propertyName={propertyName}
          financials={financials}
          prevFinancials={prevFinancials}
        />
      ))}
      <Text style={tailwind('text-xs text-warmGray-400 self-end mt-3')}>Numbers in thousands</Text>
    </View>
  );
};

const FinancialsRow: React.VFC<{
  propertyName: FinancialsRows;
  financials: Financials;
  prevFinancials?: Financials;
}> = ({ propertyName, financials, prevFinancials }) => {
  const tailwind = useTailwind();
  const value = financials[propertyName];
  const prevValue = prevFinancials?.[propertyName];

  const presentedValue = isNumber(value)
    ? propertyName === 'netMargin'
      ? formatPercent(value)
      : formatFinancialValue(value)
    : 'N/A';
  return (
    <Row
      style={borderedRows.includes(propertyName) ? tailwind('border-warmGray-200 border-b border-t') : undefined}
      key={financialsRowNames[propertyName]}
      accessibilityLabel={financialsRowNames[propertyName]}
    >
      <View style={tailwind('flex-auto flex-wrap')}>
        <Text style={tailwind('text-warmGray-500 text-xs')}>{financialsRowNames[propertyName]}</Text>
      </View>
      <View style={tailwind('items-end')}>
        <Text style={tailwind('text-warmGray-700')}>{presentedValue}</Text>
      </View>
      {prevFinancials && isNumber(value) && (
        <View style={tailwind('w-16 items-end')}>
          <Text style={tailwind('text-warmGray-700')}>
            {prevValue ? formatPercent(getPercentageDiff(prevValue, value), 0) : 'N/A'}
          </Text>
        </View>
      )}
    </Row>
  );
};

const Row: React.FC<{ style?: ViewProps['style']; accessibilityLabel?: ViewProps['accessibilityLabel'] }> = ({
  children,
  accessibilityLabel,
  style,
}) => {
  const tailwind = useTailwind();
  return (
    <View accessibilityLabel={accessibilityLabel} style={[tailwind('flex-row py-3'), style]}>
      {children}
    </View>
  );
};
