import { FC } from 'react';
import { View } from 'react-native';
import { CoreInstrumentFieldsFragment } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Skeleton } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { formatPercent } from '../../util/number';
import { InstrumentLogo } from '../InstrumentLogo';

export type Props = Pick<Omit<CoreInstrumentFieldsFragment, 'id'>, 'displayName' | 'ticker' | 'logoUrl'> & {
  id?: string | undefined;
  weight: number;
};

export const StackInstrumentPriceRow: FC<Props> = ({ displayName, ticker, logoUrl, weight }) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row justify-between')}>
      <View style={tailwind('flex-row flex-shrink')}>
        <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
        <View style={tailwind('flex-shrink ml-2')}>
          <View>
            <Text style={tailwind('text-sm font-medium text-warmGray-800')} numberOfLines={1}>
              {displayName}
            </Text>
          </View>
          <View style={tailwind('flex-row items-center')}>
            <Text style={tailwind('text-xs text-warmGray-500')} numberOfLines={1}>
              {ticker}
            </Text>
          </View>
        </View>
      </View>
      <View style={tailwind('justify-center')}>
        <Text style={tailwind('text-right justify-center ml-4')}>{formatPercent(weight, undefined, false)}</Text>
      </View>
    </View>
  );
};

export const StackInstrumentPriceRowSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row items-center')}>
      <Skeleton style={tailwind('h-9 w-9 rounded-lg')} />
      <View style={tailwind('flex-1 justify-center pl-2')}>
        <Skeleton style={tailwind('h-4 w-32 mb-2')} />
        <Skeleton style={tailwind('h-3 w-11')} />
      </View>
      <View style={tailwind('items-end')}>
        <Skeleton style={tailwind('h-4 w-14 mb-2')} />
        <Skeleton style={tailwind('h-3 w-11')} />
      </View>
    </View>
  );
};
