import { noop } from 'lodash';
import { View, ViewStyle } from 'react-native';
import { CheckIcon } from 'react-native-heroicons/solid';
import { Instrument, Maybe } from '../../generated/graphql';
import { colors, useTailwind } from '../../theme';
import { Button } from '../Button';
import { CollectionComponents } from '../CollectionComponents';
import { Upside } from '../icons';
import { Text } from '../Text';

type CollectionType = 'base' | 'bundle';

export type Component = {
  instrument?: Maybe<Pick<Instrument, 'id' | 'logoUrl'>>;
  weight?: number;
};

export type Props = {
  id: string;
  selected?: boolean;
  name: string;
  description: string;
  collectionType: CollectionType;
  onPressOverview?: (id: string) => void;
  onPressSelect?: (id: string) => void;
  components: Component[];
  componentCount: number;
};

export const CollectionCard: React.VFC<Props> = ({
  id,
  selected = false,
  name,
  description,
  components,
  componentCount,
  onPressOverview = noop,
  onPressSelect = noop,
  collectionType,
}) => {
  const tailwind = useTailwind();
  const cardStyle = cardStyles(collectionType, selected, tailwind);
  const showSelectButton = collectionType !== 'base';
  return (
    <View accessibilityLabel={name} style={[tailwind('rounded-3xl p-4'), cardStyle]}>
      <View style={tailwind('flex-row justify-between')}>
        <Text style={tailwind('font-semibold text-default text-sm pr-2')} numberOfLines={1} lineBreakMode="tail">
          {name}
        </Text>
        <CollectionComponents componentCount={componentCount} cutOff={5} components={components} />
      </View>
      <View style={tailwind('flex-row justify-start items-center mt-1.5')}>
        <Text style={tailwind('font-regular font-normal text-greys-700 text-xs mr-1.5')}>By Upside</Text>
        <Upside width={14} height={14} color={colors.primary['dark']} />
      </View>
      <Text style={tailwind('font-regular text-xs text-greys-900 mt-2')} numberOfLines={3} ellipsizeMode="tail">
        {description}
      </Text>
      <View style={tailwind('flex-row flex-wrap justify-between mt-3')}>
        {showSelectButton ? (
          <Button
            text={selected ? 'Selected' : 'Select'}
            variant={selected ? 'contained' : 'outlined'}
            style={selectButtonStyles(selected, collectionType, tailwind)}
            textStyle={selected ? undefined : tailwind('text-primary-dark')}
            onPress={() => onPressSelect(id)}
            isFullWidth
            StartIcon={undefined}
            EndIcon={selected ? CheckIcon : undefined}
          />
        ) : (
          // Empty placeholder view to preserve space-between alignment
          <View />
        )}
        <Button
          text="Overview"
          variant="text"
          textStyle={tailwind('text-light')}
          isLightButton={true}
          onPress={() => onPressOverview(id)}
          isFullWidth
        />
      </View>
    </View>
  );
};

const cardStyles = (
  collectionType: CollectionType,
  selected: boolean,
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  switch (collectionType) {
    case 'base':
      return selected
        ? tailwind('bg-primary-lightest border border-primary-default')
        : tailwind('bg-greys-50 border border-greys-50');
    case 'bundle':
      return selected
        ? tailwind('bg-secondary-lightest border border-secondary-default')
        : tailwind('bg-greys-50 border border-greys-50');
  }
};

const selectButtonStyles = (
  selected: boolean,
  collectionType: CollectionType,
  tailwind: ReturnType<typeof useTailwind>,
): ViewStyle => {
  if (!selected) {
    return tailwind('min-w-[120px]');
  }

  switch (collectionType) {
    case 'base':
      return tailwind('min-w-[120px] bg-primary-default');
    case 'bundle':
      return tailwind('min-w-[120px] bg-secondary-default');
  }
};
