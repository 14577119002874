/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurationLeague } from '../models/CurationLeague';
import { request as __request } from '../core/request';

export class CurationLeagueService {

    /**
     * Get the latest actual return percentage of all curations for the given period
     * @returns CurationLeague
     * @throws ApiError
     */
    public static async apiCurationLeagueList({
        curationPeriod,
    }: {
        /** Curation period for which curation league data is returned **/
        curationPeriod: 'ITD' | 'YTD' | 'MTD' | 'LAST_7D',
    }): Promise<Array<CurationLeague>> {
        const result = await __request({
            method: 'GET',
            path: `/api/curation-league/`,
            query: {
                'curation_period': curationPeriod,
            },
            errors: {
                400: `Bad request`,
            },
        });
        return result.body;
    }

}
