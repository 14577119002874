import { gql } from '@apollo/client';

/**
 * make sure have a variable called `$ideaId` for this fragment to work
 *
 * Gets user comment itself + user nickname, profile rating and active idea
 */
export const CORE_COMMENT_FIELDS = gql`
  fragment CoreCommentFields on Comment {
    id
    replyCount
    user {
      ...CoreUserProfileFields
      ideas(filter: { instrument: { ideaIds: [$ideaId] } }, pagination: { limit: 1 }) {
        connection {
          nodes {
            id
            position
            instrument {
              id
              ticker
            }
          }
        }
      }
    }
    text
    status
    created
  }
`;

/**
 * Gets user comment itself + user nickname, profile rating
 */
export const CORE_COMMENT_FIELDS_WITHOUT_ACTIVE_IDEA = gql`
  fragment CoreCommentFieldsWithoutActiveIdea on Comment {
    id
    replyCount
    user {
      id
      nickname
      profileRating
    }
    text
    status
    created
  }
`;
