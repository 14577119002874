import { useNavigation } from '@react-navigation/native';
import { VFC } from 'react';
import { Image, View } from 'react-native';
import { HomeNavigationProps } from '..';
import { layout } from '../../../constants/layout';
import { DatoBlog } from '../../../generated/graphql';
import { shadowMd, useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { DESKTOP_MAX_WIDTH } from '../../../ui/SafeAreaView';
import { Text } from '../../../ui/Text';
import { formatDate } from '../../../util/date';

// If desktop layout, use desktop max width instead
export const BLOG_CARD_WIDTH = Math.min(layout.window.width, DESKTOP_MAX_WIDTH) - 96;
export const BLOG_IMAGE_WIDTH = 92;

type Props = Pick<DatoBlog, 'id' | 'coverImage' | 'title' | 'publishedDate' | 'slug'>;

export const BlogCard: VFC<Props> = ({ id, coverImage, title, publishedDate }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<HomeNavigationProps>();
  return (
    <Pressable
      style={[tailwind('p-2 rounded-lg'), shadowMd, { width: BLOG_CARD_WIDTH }]}
      onPress={() => navigation.push('Blog', { blogId: id })}
      accessibilityRole="button"
    >
      <View style={tailwind('flex-row')}>
        <Image
          style={[tailwind('rounded-lg'), { height: BLOG_IMAGE_WIDTH, width: BLOG_IMAGE_WIDTH }]}
          accessibilityIgnoresInvertColors
          source={{ uri: coverImage?.url ?? '' }}
        />
        <View style={tailwind('justify-between h-24 pl-3 flex-1 py-0.5')}>
          <Text numberOfLines={3} style={tailwind('text-warmGray-800 font-semibold flex-wrap pr-1')}>
            {title}
          </Text>
          <View>
            <Text style={tailwind('text-warmGray-500 text-xs py-2')}>Upside blogs</Text>
            <Text style={tailwind('text-warmGray-500 text-xs')}>
              {formatDate(new Date(publishedDate ?? ''), 'Do MMMM YYYY')}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  );
};
