import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';
import { CORE_IDEA_FIELDS } from '../../fragments/idea';
import { CORE_INSTRUMENT_FIELDS } from '../../fragments/instrument';
import { CORE_USER_PROFILE_FIELDS } from '../../fragments/userProfile';
import { GetUserProfileQuery, useGetUserProfileQuery } from '../../generated/graphql';
import { useBottomSheet } from '../../hooks/useBottomSheet';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { Persona, PersonaSkeleton } from '../../old/Persona';
import { SegmentedControl } from '../../old/SegmentedControl';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { useTailwind } from '../../theme';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { SkeletonView } from '../../ui/Skeleton';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { usePersistedStore } from '../../zustand/store';
import { UserIdeas } from './UserIdeas';
import { Watchlist, WatchlistSkeleton } from './Watchlist';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'UserProfile'>;

const segments = ['Ideas', 'Watchlist'] as const;
type Segment = typeof segments[number];

/* eslint-disable graphql/template-strings */
export const getPublicIdeas = gql`
  ${CORE_IDEA_FIELDS}
  ${CORE_INSTRUMENT_FIELDS}
  ${CORE_USER_PROFILE_FIELDS}
  query getUserProfile($userId: Int!, $loggedIn: Boolean!) {
    publicProfile(userId: $userId) {
      ...CoreUserProfileFields
    }
    publicInstrumentWatchlist(userId: $userId) {
      instrument {
        ...CoreInstrumentFields
      }
    }
  }
`;

export const UserProfile: React.FC<Props> = withReloadErrorBoundary(({ route, navigation }) => {
  const tailwind = useTailwind();
  const { userId, initialSelectedIndex = 0 } = route.params;
  const { present, dismiss } = useBottomSheet();
  const loggedIn = usePersistedStore((state) => state.isUserLoggedIn);
  const { data, previousData, error } = useGetUserProfileQuery({
    variables: { userId, loggedIn },
  });

  const onUserProfilePress = () => {
    present(
      <ScreenSidePadding>
        <Button
          variant="secondary"
          text="View"
          onPress={() => {
            navigation.push('ViewProfileImage', { imageUri: data?.publicProfile.media?.profileFull ?? '' });
            dismiss();
          }}
        />
      </ScreenSidePadding>,
    );
  };

  // in apollo client 3 data now becomes undefined when loading again
  // so use this boolean to only show skeleton on initial load.
  // https://github.com/apollographql/apollo-client/issues/7038
  const presentableData = data ?? previousData;

  if (error) {
    throw error;
  }

  return (
    <SafeAreaView>
      <TitleBar title="" />

      <View style={tailwind('pt-2 pb-6 px-6')}>
        {presentableData ? (
          <Persona
            {...presentableData.publicProfile}
            onUserProfilePress={data?.publicProfile.media?.id ? onUserProfilePress : undefined}
          />
        ) : (
          <SkeletonView>
            <PersonaSkeleton />
          </SkeletonView>
        )}
      </View>

      <SegmentedControl
        style={tailwind('mx-6 mb-3')}
        values={segments.slice()}
        onChange={(e) => {
          navigation.setParams({ initialSelectedIndex: e.nativeEvent.selectedSegmentIndex });
        }}
        selectedIndex={initialSelectedIndex}
      />
      <View style={tailwind('flex-1')}>
        <SegmentSwitch segment={segments[initialSelectedIndex]} data={presentableData} userId={userId} />
      </View>
    </SafeAreaView>
  );
});

const SegmentSwitch: React.FC<{ segment: Segment; data?: GetUserProfileQuery; userId: number }> = ({
  segment,
  data,
  userId,
}) => {
  switch (segment) {
    case 'Ideas':
      return <UserIdeas userId={userId} />;
    case 'Watchlist':
      return data ? <Watchlist instruments={data.publicInstrumentWatchlist} /> : <WatchlistSkeleton />;
  }
};
