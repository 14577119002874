import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

export const Panels = (props: SvgProps) => (
  <Svg width={360} height={360} viewBox="0 0 256 256" fill="none" {...props}>
    <Path
      d="M151.776 14.526a97.61 97.61 0 0 1 28.653 6.69c30.761 12.298 53.784 39.472 61.748 72.882l8.294 34.792a93.324 93.324 0 0 1-4.493 57.089c-8.543 20.722-24.223 37.378-43.916 46.649l-7.428 3.497c-15.669 7.377-32.784 10.705-49.89 9.7l-52.935-3.11c-43.023-3.06-75.926-40.868-74.78-85.928l1.866-73.344c.72-28.302 16.656-53.88 41.186-66.102 11.332-5.648 23.861-8.107 36.34-7.133l55.355 4.318Z"
      fill="#FFFBEB"
    />
    <G clipPath="url(#a)">
      <Path
        d="m33.526 72.298 1.03 79.741s57.333 6.571 57.333 6.072c0-.5 2.022-95.075 2.022-95.075l-60.385 9.262ZM161.352 62.998h61.91c.382 29.322.763 58.682 1.183 88.003-21.171 1.192-42.342 2.344-63.551 3.536l.458-91.539Z"
        fill="#fff"
        stroke="#202124"
        strokeMiterlimit={10}
      />
      <Path
        d="M88.913 55.35c.267 35.01.573 69.98.84 104.99 27.274-.193 54.586-.423 81.86-.615-.877-34.74-1.755-69.442-2.67-104.182-26.626-.077-53.328-.116-80.03-.192Z"
        fill="#fff"
        stroke="#202124"
        strokeMiterlimit={10}
      />
      <Path
        d="M144.504 108.989c0-7.997-6.579-14.458-14.644-14.458-8.064 0-14.643 6.461-14.643 14.458 0 6.589 4.456 12.155 10.54 13.882v6.077h7.923v-6.013c6.226-1.663 10.824-7.229 10.824-13.946Z"
        fill="#FBBF24"
      />
      <Path
        d="M125.757 122.871h.375v-.283l-.272-.078-.103.361Zm0 6.077h-.375v.375h.375v-.375Zm7.923 0v.375h.375v-.375h-.375Zm0-6.013-.096-.362-.279.074v.288h.375Zm11.199-13.946c0-8.209-6.752-14.833-15.019-14.833v.75c7.863 0 14.269 6.299 14.269 14.083h.75ZM129.86 94.156c-8.266 0-15.018 6.624-15.018 14.833h.75c0-7.784 6.406-14.083 14.268-14.083v-.75Zm-15.018 14.833c0 6.763 4.574 12.471 10.813 14.243l.205-.722c-5.929-1.683-10.268-7.106-10.268-13.521h-.75Zm10.54 13.882v6.077h.75v-6.077h-.75Zm.375 6.452h7.923v-.75h-7.923v.75Zm8.298-.375v-6.013h-.75v6.013h.75Zm-.278-5.651c6.381-1.705 11.102-7.412 11.102-14.308h-.75c0 6.539-4.475 11.962-10.545 13.584l.193.724Z"
        fill="#0C0C0C"
      />
      <Path d="M136.086 128.245h-12.451v5.694h12.451v-5.694Z" fill="#FFFBEB" />
      <Path
        d="M123.565 130.036h12.592M123.635 132.275h12.168M129.861 127.734v-20.28M124.696 106.494l5.023 7.997M135.025 106.366l-5.023 7.997M98.946 98.37l10.399 1.855M108.778 86.726l5.164 5.502M121.301 80.138l2.122 8.06M133.256 87.75l1.698-6.013M140.472 92.868l7.711-8.125M146.132 98.625l12.026-4.798M148.183 104.96l7.286-.449M149.245 110.397l4.598 1.919M101.563 111.803l8.702-3.007M112.315 114.683l-4.81 4.862"
        stroke="#0C0C0C"
        strokeWidth={0.75}
        strokeMiterlimit={10}
      />
      <Path
        d="M215.751 129.506h-.015l-.014.001-36.679 2.112c-.667-.007-1.423-.609-2.043-1.933-.605-1.292-.993-3.111-.993-5.143v-22.508c0-2.032.388-3.85.993-5.143.621-1.326 1.379-1.928 2.046-1.933l36.875 1.537h.02c.793 0 1.496.364 2.026 1.217.545.877.907 2.28.907 4.322v22.508c0 2.035-.408 3.271-.971 3.99-.546.697-1.291.973-2.152.973Z"
        fill="#fff"
        stroke="#000"
      />
      <Path
        d="M180.97 119.139c.792-1.654 1.56-3.358 2.25-5.207.486-1.265.64-1.411 1.304-.438.64.925 1.279 1.898 1.893 2.871.077.098.205.146.281 0 1.023-2.335 2.046-4.72 3.069-7.056.333-.778.998-3.065 1.586-2.919.383.048 1.381 2.384 1.688 2.919 2.199 3.65 4.501 7.008 6.93 10.073.102.098.205 0 .256-.146.563-1.508.639-1.8 1.407-.729.076.146.204 0 .255-.146.819-2.288 1.663-4.477 2.609-6.57a53.396 53.396 0 0 1 1.381-2.968c.46-.925.844-2.336 1.509-1.606.486.535.92 1.703 1.33 2.384.511.876 1.022 1.752 1.56 2.579a70.192 70.192 0 0 0 2.966 4.234c.077.097.205.049.256-.146l3.222-10.949c.128-.389-.204-.778-.332-.389-.588 1.946-1.151 3.941-1.739 5.888-.307 1.07-.588 2.433-.998 3.358-.486 1.167-.486 1.216-1.176.292-1.918-2.628-3.683-5.548-5.345-8.76-.077-.146-.205-.146-.281 0a79.261 79.261 0 0 0-3.223 6.862c-.383.924-.767 1.898-1.151 2.871-.127.34-.434 1.654-.664 1.8-.41.244-.563-.194-.896-.681-.076-.146-.204 0-.255.146-.358.973-.435 1.703-1.074 1.265-.384-.292-.793-1.022-1.126-1.508a154.192 154.192 0 0 1-2.711-3.845c-1.56-2.335-3.043-4.817-4.475-7.396-.077-.146-.205-.146-.281 0-1.177 2.725-2.353 5.401-3.53 8.126-.179.39-.511 1.606-.767 1.752-.486.341-.716-.389-1.074-.924-.588-.925-1.227-1.752-1.841-2.628-.077-.097-.205 0-.256.146-.869 2.433-1.816 4.72-2.864 6.861-.128.438.128.973.307.584Z"
        fill="#202124"
      />
      <Path
        d="M215.086 106.876c.511-.924 1.074-1.752 1.713-2.384-.102-.049-.179-.146-.281-.195a54.4 54.4 0 0 1 .435 3.504c.051.438.409.243.358-.195a54.4 54.4 0 0 0-.435-3.504c-.025-.243-.179-.34-.281-.194-.665.681-1.254 1.508-1.791 2.481-.153.292.103.828.282.487Z"
        fill="#202124"
      />
      <Path
        d="M180.433 128.385c.522 0 .946-.806.946-1.801 0-.994-.424-1.8-.946-1.8-.523 0-.946.806-.946 1.8 0 .995.423 1.801.946 1.801Z"
        fill="#22C55E"
      />
      <Path d="m68.668 131.004 5.05.723v-20.989l-5.05.389v19.877Z" fill="#fff" stroke="#000" strokeWidth={0.3} />
      <Path d="m60.518 99.999 5.35-.504v31.731l-5.35-.503V99.999Z" fill="#3B82F6" />
      <Path d="m53.52 125.119 4.588-.44v6.243l-4.589-.766v-5.037Z" fill="#5492ED" />
      <Path d="m46.671 129.591 3.873.709V92.997l-3.376.581-.497 36.013Z" fill="#fff" stroke="#000" strokeWidth={0.3} />
      <Path d="m43.525 129.718 33.992 2.012" stroke="#202124" strokeWidth={0.75} strokeMiterlimit={10} />
      <Path
        d="M93.915 224.976a280.363 280.363 0 0 0-.992 4.919 8.144 8.144 0 0 0 1.945.422c1.45.116 2.632-.153 3.357-.422-.114-1.461-.267-2.959-.381-4.42-1.297-.154-2.594-.307-3.93-.499Z"
        fill="#A3A3A3"
      />
      <Path
        d="M94.372 172.827a4456.2 4456.2 0 0 0-.343 31.935l-.114 20.252c.076.231.343.846.953 1.23.763.499 1.793.461 2.632-.077.42-1.96 1.03-4.996 1.755-8.723 2.785-13.835 3.357-17.294 4.654-23.135.877-3.997 2.25-9.799 4.234-16.87.687-2.998-.267-4.497-.572-4.881-1.869-2.767-7.477-3.228-13.199.269Z"
        fill="#FAE8FF"
      />
      <Path
        d="m94.068 172.981-.23 51.38c0 .192.268.192.306 0l.23-51.38c-.039-.192-.306-.192-.306 0ZM106.846 180.167c-3.28 15.064-6.523 30.09-9.155 45.27-.038.192.229.269.267.077 2.594-15.18 5.875-30.244 9.155-45.27.038-.154-.229-.231-.267-.077ZM93.991 225.706a2.893 2.893 0 0 0 3.624.576c.152-.077 0-.346-.153-.23a2.654 2.654 0 0 1-3.28-.538c-.115-.154-.344.038-.191.192Z"
        fill="#202124"
      />
      <Path
        d="M93.457 229.511c1.411.423 2.67.654 4.082.077.152-.077.076-.347-.077-.27-1.335.578-2.594.347-3.929-.077-.152-.077-.229.193-.076.27Z"
        fill="#FAE8FF"
      />
      <Path
        d="M113.598 227.589c.076 1.268.152 2.498.229 3.766 8.888-.077 13.351-.5 13.427-1.268.038-.653-3.319-1.614-10.147-2.844-1.182.115-2.327.231-3.509.346Z"
        fill="#A3A3A3"
      />
      <Path
        d="M101.239 173.749c.61 2.191 1.258 4.42 1.869 6.61.915 4.65 1.831 9.3 2.823 14.027a969.774 969.774 0 0 0 7.629 33.203c.19.154.686.615 1.449.692 1.144.115 1.946-.615 2.06-.73-.076-1.307-.229-3.152-.343-5.381a1004.856 1004.856 0 0 1-1.374-28.86c-.19-6.149-.381-13.412-.495-21.597-1.297.346-2.671.691-4.12.96-3.433.692-6.637 1-9.498 1.076Z"
        fill="#FAE8FF"
      />
      <Path
        d="M114.742 171.713a1010.1 1010.1 0 0 0 2.251 55.837c0 .192.305.192.305 0-1.259-18.599-1.984-37.199-2.251-55.837 0-.154-.305-.154-.305 0ZM102.841 179.552c3.09 16.025 6.637 31.935 10.719 47.73.038.192.305.115.267-.077-4.082-15.795-7.629-31.705-10.719-47.73-.038-.192-.305-.115-.267.077ZM114.017 227.819c.725.731 1.869.923 2.785.423.153-.077 0-.346-.153-.23a2.077 2.077 0 0 1-2.441-.385c-.114-.153-.305.039-.191.192Z"
        fill="#202124"
      />
      <Path
        d="M118.786 227.589c-.115.269-.382 1.575.228 1.46.229-.038.344-.307.382-.538a1.434 1.434 0 0 0-.191-.883c-.114-.154-.343 0-.229.153.077.154.153.308.153.461 0 .077 0 .538-.153.385-.076-.077-.038-.385-.038-.461 0-.193.076-.385.153-.538.038-.116-.229-.192-.305-.039Z"
        fill="#FAE8FF"
      />
      <Path
        d="M119.129 227.435a.594.594 0 0 1-.229-.307c-.038-.115-.038-.308.038-.384.115-.116.267.076.267.192.038.153-.038.307-.038.422-.038.192.229.269.267.077.076-.269.153-.576-.038-.807-.115-.153-.343-.269-.534-.153-.229.115-.267.345-.267.576.038.269.152.499.381.653.153.077.305-.154.153-.269ZM120.044 227.858c.191.231.306.5.23.807-.039.192.229.269.267.077.114-.384 0-.769-.267-1.076-.153-.154-.344.038-.23.192ZM120.807 227.935c.23.192.344.499.268.807-.038.192.229.269.267.077.115-.385-.038-.807-.344-1.076-.152-.116-.343.076-.191.192ZM121.685 228.089c.267.192.382.538.305.884-.038.192.23.269.268.076.153-.461-.038-.922-.42-1.191-.191-.115-.306.115-.153.231ZM114.17 231.125l7.438-.461c.191 0 .191-.308 0-.308l-7.438.462c-.191.038-.191.307 0 .307Z"
        fill="#FAE8FF"
      />
      <Path
        d="M86.705 178.399c.19 1.192.229 2.152.267 2.767.076 1.538-.076 2.306.305 3.497.23.807.496 1.576 1.145 1.807.61.23 1.564-.039 1.83-.692.153-.346.077-.692 0-.884-.266-.845-.533-1.691-.8-2.498.19 0 .648 0 .915-.269.153-.154.305-.423.229-1.345-.076-.769-.267-1.383-.649-2.459-.648-1.73-.8-1.845-.915-1.883-.84-.269-2.212 1.806-2.327 1.959Z"
        fill="#fff"
      />
      <Path
        d="M87.735 140.662c-.229.576-.572 1.46-.877 2.574-.382 1.461-.496 2.614-.573 3.228-.495 4.996-.762 7.533-.8 9.031-.23 6.341.152 11.375.457 15.411.23 3.112.534 5.687.725 7.455.19.115.648.423 1.297.423 1.03 0 1.678-.731 1.793-.846.038-1.268.152-2.844.305-4.727.114-1.153.381-3.958.992-7.532.229-1.46.724-4.227 1.602-7.647a88.642 88.642 0 0 1 3.89-11.683c-2.898-1.883-5.836-3.766-8.81-5.687Z"
        fill="#fff"
      />
      <Path
        d="M87.62 140.585c-1.182 2.575-1.449 5.534-1.754 8.339a88.825 88.825 0 0 0-.534 9.569c-.038 6.648.534 13.296 1.259 19.906.038.192.305.192.305 0-.687-6.11-1.22-12.259-1.259-18.407 0-2.959.076-5.88.343-8.839.305-3.42.496-7.225 1.946-10.414 0-.154-.23-.308-.305-.154ZM93.762 153.382a130.169 130.169 0 0 0-4.12 23.557c0 .192.267.192.306 0a128.773 128.773 0 0 1 4.12-23.48c0-.192-.268-.269-.306-.077ZM87.201 178.63c.725.768 1.717.077 2.289-.538.114-.154-.076-.346-.19-.192-.459.499-1.26 1.191-1.908.538-.115-.154-.305.038-.19.192ZM89.68 178.053a6.885 6.885 0 0 1 .802 3.69c0 .192.267.192.305 0 .038-1.345-.23-2.652-.84-3.843-.114-.154-.343-.039-.267.153ZM89.528 180.859c0 .538.153 1.076.382 1.576.076.153.344.038.229-.154a3.12 3.12 0 0 1-.344-1.422.134.134 0 0 0-.267 0ZM86.781 179.129c.077 2.46-.495 6.034 1.831 7.648.153.115.306-.154.153-.231-2.174-1.498-1.64-5.072-1.716-7.417a.134.134 0 0 0-.268 0Z"
        fill="#202124"
      />
      <Path
        d="M87.735 183.241a4.618 4.618 0 0 0 1.412 3.036c.152.116.343-.077.19-.192-.8-.73-1.259-1.729-1.335-2.805 0-.231-.267-.231-.267-.039ZM88.536 183.088c.267.96.572 1.883.992 2.767.076.153.305.038.229-.154a20.905 20.905 0 0 1-.954-2.729c-.038-.153-.305-.076-.267.116ZM89.223 182.127c.267 1.153.648 2.267 1.106 3.382.076.154.305.038.229-.154a20.813 20.813 0 0 1-1.068-3.305c0-.192-.305-.115-.267.077Z"
        fill="#202124"
      />
      <Path
        d="M87.735 140.662c.115 1.306.343 3.266.572 5.649.878 8.723.84 8.57.954 9.338.801 5.265 2.136 9.415 2.975 11.952a77.671 77.671 0 0 0 1.946 5.341c2.403.731 4.463 1.038 5.989 1.153 2.403.231 6.98.346 12.244-1.46 1.068-.346 1.908-.73 2.48-.961.343-1.844.648-3.727.915-5.61a251.1 251.1 0 0 0 2.518-25.402c-2.06-.961-4.959-2.037-8.545-2.69a36.955 36.955 0 0 0-10.91-.423 36.388 36.388 0 0 0-11.138 3.113Z"
        fill="#fff"
      />
      <Path
        d="M87.659 140.893c9.574-4.497 20.713-4.497 30.287-.077.153.077.306-.154.153-.231a36.238 36.238 0 0 0-30.593.077c-.152.077 0 .307.153.231ZM88.155 145.773a78.453 78.453 0 0 0 5.645 26.439c.076.154.344.077.267-.076a76.425 76.425 0 0 1-5.645-26.363.134.134 0 0 0-.267 0ZM118.175 145.043a201.83 201.83 0 0 1-3.433 26.209c-.038.192.229.269.267.076 1.717-8.685 2.899-17.446 3.471-26.285 0-.192-.305-.192-.305 0ZM94.334 172.943c6.676 2.305 13.885 1.921 20.294-1.038.152-.077.038-.307-.153-.231-6.332 2.921-13.465 3.267-20.064 1-.153-.077-.23.23-.077.269Z"
        fill="#202124"
      />
      <Path
        d="M121.189 176.978c-.077.384-.191.999-.344 1.729-.419 1.96-.686 2.651-.343 2.959.153.115.42.23 1.221-.077-.115.961-.267 1.96-.382 2.921.077.192.267.538.649.807.496.384 1.03.384 1.22.384.267-.769.611-1.921.763-3.382.115-1.076.077-1.883 0-2.767-.038-.96-.19-2.229-.496-3.727-.724.346-1.525.768-2.288 1.153Z"
        fill="#fff"
      />
      <Path
        d="M118.328 140.7a7.775 7.775 0 0 0-2.67 1.307c-3.7 2.728-3.739 7.724-3.739 8.493.954 2.805 1.87 5.61 2.823 8.416 1.984 5.879 3.929 11.798 5.875 17.716.19.153.534.384 1.068.499.915.154 1.602-.269 1.793-.384.076-.922.152-2.306.152-3.958.076-6.687-.763-11.837-1.716-17.14-.725-4.15-1.488-7.263-1.87-8.877-.61-2.344-1.22-4.419-1.716-6.072Z"
        fill="#fff"
      />
      <Path
        d="M118.175 140.739a123.186 123.186 0 0 1 5.341 35.508c0 .193.305.193.305 0a123.543 123.543 0 0 0-5.34-35.585c-.077-.192-.344-.115-.306.077ZM111.767 150.538c2.861 8.531 5.722 17.063 8.583 25.632.076.193.343.116.267-.076-2.861-8.532-5.722-17.063-8.583-25.633-.038-.153-.343-.077-.267.077ZM120.502 176.709c.878.692 2.06.769 3.014.154.152-.116 0-.347-.153-.231a2.34 2.34 0 0 1-2.67-.077c-.114-.154-.343.038-.191.154ZM121.952 177.938c.076.846.496 1.537.686 2.344.305 1.23.229 2.498.153 3.728 0 .192.267.192.305 0 .076-1.038.114-2.114-.038-3.151-.153-1.038-.725-1.922-.801-2.959-.038-.115-.343-.115-.305.038ZM120.922 177.631a48.76 48.76 0 0 0-.42 2.306c-.076.461-.343 1.114-.191 1.614.153.499.801.615.992.077.076-.193-.229-.231-.267-.077-.458-.077-.61-.308-.458-.692 0-.077.039-.192.039-.269.038-.346.114-.653.19-.999.115-.615.229-1.23.344-1.883.076-.192-.229-.269-.229-.077ZM123.783 178.822a14.87 14.87 0 0 1-.687 6.841c-.076.192.229.269.267.076.763-2.267.992-4.534.687-6.917-.038-.192-.305-.192-.267 0ZM121.571 181.551c.114.999 0 1.959-.382 2.882-.077.153.191.23.267.076.382-.96.535-1.959.382-2.958 0-.154-.267-.154-.267 0ZM122.066 181.973c.23 1.115.191 2.191-.191 3.267-.076.192.23.269.268.077a6.138 6.138 0 0 0 .191-3.42c-.038-.154-.306-.077-.268.076ZM104.748 133.245c.686-.73 1.03-1.422 1.259-1.922.305-.653.534-1.229.419-1.959-.038-.269-.114-.846-.572-1.269-.763-.691-1.984-.345-2.708-.115-1.374.384-2.327 1.23-2.861 1.806-.229-.153-.801-.499-1.564-.461-.267 0-.763.039-1.145.384-.457.462-.19.961-.457 1.653-.267.73-.992 1.153-2.403 1.998-1.526.884-1.526.654-2.098 1.115-1.145.884-1.526 2.152-1.908 3.497-.267.922-.724 2.382-.496 4.304.115.845.344 2.69 1.64 4.227 2.137 2.536 5.875 2.767 8.393 2.075 2.059-.576 3.356-1.767 3.814-2.19.534-.5 1.984-1.922 2.594-4.304.534-2.152.115-3.882-.191-4.996a10.637 10.637 0 0 0-1.716-3.843Z"
        fill="#202124"
      />
      <Path
        d="M100.819 129.902c.343.153.801.384 1.259.691a6.65 6.65 0 0 1 1.373 1.422c.038-.153.267-.845-.076-1.46-.42-.73-1.488-1.076-2.556-.653ZM106.159 131.016a7.73 7.73 0 0 1-1.411 2.267c.153.269.267.538.42.807.457.884.839 1.768 1.22 2.613.115-.192.267-.422.382-.73.076-.192.343-.845.381-1.767.038-.807-.152-1.422-.229-1.691-.19-.692-.534-1.192-.763-1.499Z"
        fill="#fff"
      />
      <Path
        d="M98.568 129.248c-.648-.038-.953.116-1.258.73-.534 1.077-.382 2.037-1.564 2.806-.687.423-1.488.615-2.213.999-2.136 1.191-2.937 3.497-3.28 5.841-.801 5.111 1.907 8.954 7.133 9.377 4.844.384 9.04-3.343 9.574-8.147.611-5.534-3.242-10.145-8.315-11.683-.191-.038-.267.231-.077.269 4.616 1.384 8.316 5.419 8.164 10.453-.153 4.996-4.235 9.223-9.346 8.801-2.708-.231-5.417-1.461-6.485-4.112-.8-2.076-.61-4.497-.038-6.572.382-1.422 1.03-2.805 2.25-3.689.84-.576 1.87-.769 2.747-1.307.458-.269.877-.614 1.144-1.076.42-.691.344-2.498 1.526-2.421.23.039.23-.269.038-.269Z"
        fill="#202124"
      />
      <Path
        d="M100.399 129.94c1.069-1.345 2.823-2.152 4.54-1.807 2.594.538.763 3.843-.305 5.035-.115.153.076.346.19.192.916-1.076 2.899-4.227.954-5.265-1.907-.999-4.387.077-5.646 1.614-.038.154.153.346.267.231Z"
        fill="#202124"
      />
      <Path
        d="M106.045 131.093c1.144 1.575 1.297 3.574.381 5.303-.076.154.153.308.229.154a5.325 5.325 0 0 0-.381-5.611c-.115-.154-.344 0-.229.154Z"
        fill="#202124"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(32 54)" d="M0 0h193v177.82H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
