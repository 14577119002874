import React from 'react';
import { useWindowDimensions, View } from 'react-native';
import Animated, { useAnimatedScrollHandler, useDerivedValue } from 'react-native-reanimated';
import { DESKTOP_MAX_WIDTH } from '../SafeAreaView';
import { Props } from './props';

export const PagerView: React.FC<Props> = ({ onPageScroll, children }) => {
  const dimensions = useWindowDimensions();
  const width = useDerivedValue(() => dimensions.width, [dimensions]);
  const scrollHandler = useAnimatedScrollHandler(
    (e) => {
      onPageScroll({ offset: e.contentOffset.x / Math.min(width.value, DESKTOP_MAX_WIDTH) });
    },
    [onPageScroll, children],
  );
  return (
    <Animated.ScrollView
      scrollEventThrottle={16}
      onScroll={scrollHandler}
      style={{ flex: 1 }}
      horizontal={true}
      pagingEnabled={true}
      showsHorizontalScrollIndicator={false}
    >
      {React.Children.map(children, (screen, index) => (
        <View key={index} style={{ maxWidth: DESKTOP_MAX_WIDTH, width: dimensions.width }}>
          {screen}
        </View>
      ))}
    </Animated.ScrollView>
  );
};
