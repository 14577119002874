import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik, FormikHelpers } from 'formik';
import { FC } from 'react';
import { View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as yup from 'yup';
import { useCreateTradingAddressMutation } from '../../generated/graphql';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { TextInput } from '../../old/inputs';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { createValidationError } from '../../services/errors';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';

export type Props = NativeStackScreenProps<LoggedInStackParamList, 'TradingAccountAddressDetails'>;

export const CREATE_TRADING_ADDRESS = gql`
  mutation CreateTradingAddress($address: TradingAddressInput!) {
    createTradingAddress(address: $address) {
      error {
        ... on TradingValidationError {
          message
          errors
        }
      }
    }
  }
`;

const schema = yup.object({
  line1: yup.string().trim().required('Required'),
  line2: yup
    .string()
    .trim()
    .transform((value) => (value === '' ? null : value))
    .nullable(),
  line3: yup
    .string()
    .trim()
    .transform((value) => (value === '' ? null : value))
    .nullable(),
  city: yup.string().trim().required('Required'),
  countryCode: yup.string().uppercase().required('Required'),
  region: yup
    .string()
    .trim()
    .uppercase()
    .transform((value) => (value === '' ? null : value))
    .nullable(),
  postalCode: yup.string().trim().uppercase().required('Required'),
});

type FormSchema = yup.InferType<typeof schema>;

const initialValues: FormSchema = {
  line1: '',
  line2: null,
  line3: null,
  city: '',
  region: null,
  countryCode: 'GB',
  postalCode: '',
};

export const TradingAccountAddressDetails: FC<Props> = withReloadErrorBoundary(({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const [mutate, { loading, data, reset }] = useCreateTradingAddressMutation();

  const onSubmit = async (values: FormSchema, helpers: FormikHelpers<FormSchema>) => {
    try {
      reset();
      const result = await mutate({
        variables: {
          address: {
            line1: values.line1,
            line2: values.line2,
            line3: values.line3,
            city: values.city,
            region: values.city,
            countryCode: values.countryCode,
            postalCode: values.postalCode,
          },
        },
      });

      const validationErrorResult = result.data?.createTradingAddress.error;
      if (validationErrorResult) {
        console.error(createValidationError(validationErrorResult));
        helpers.setFieldError('submission', JSON.stringify(validationErrorResult));
        helpers.setSubmitting(false);
        return;
      }

      navigation.replace('TradingAccountBankDetails');
    } catch (e) {
      console.error(e);
      toast.show('Something went wrong, please try again.');
    } finally {
      helpers.setSubmitting(false);
    }
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView>
        <TitleBar title="Address Details" />
        <ScrollView>
          <ScreenSidePadding>
            <Text>Please enter your trading address details below.</Text>
            <Formik<FormSchema>
              initialValues={initialValues}
              validateOnBlur={false}
              validateOnChange={false}
              onSubmit={onSubmit}
              validationSchema={schema}
            >
              {({
                values: { line1, line2, line3, city, region, postalCode, countryCode },
                handleBlur,
                handleChange,
                errors,
                handleSubmit,
              }) => {
                const submissionError = data?.createTradingAddress.error;
                return (
                  <View style={tailwind('pt-4')}>
                    <TextInput
                      label="Line 1 *"
                      value={line1}
                      errorMessage={errors.line1}
                      onChangeText={handleChange('line1')}
                      onBlur={handleBlur('line1')}
                      placeholder="Line 1"
                      accessibilityLabel="Line 1"
                    />
                    <Spacer />
                    <TextInput
                      label="Line 2"
                      value={line2 ?? ''}
                      errorMessage={errors.line2}
                      onChangeText={handleChange('line2')}
                      onBlur={handleBlur('line2')}
                      placeholder="Line 2"
                      accessibilityLabel="Line 2"
                    />
                    <Spacer />
                    <TextInput
                      label="Line 3"
                      value={line3 ?? ''}
                      errorMessage={errors.line3}
                      onChangeText={handleChange('line3')}
                      onBlur={handleBlur('line3')}
                      placeholder="Line 3"
                      accessibilityLabel="Line 3"
                    />
                    <Spacer />
                    <TextInput
                      label="City *"
                      value={city}
                      errorMessage={errors.city}
                      onChangeText={handleChange('city')}
                      onBlur={handleBlur('city')}
                      placeholder="City"
                      accessibilityLabel="City"
                    />
                    <Spacer />
                    <TextInput
                      label="Region"
                      value={region ?? ''}
                      errorMessage={errors.region}
                      onChangeText={handleChange('region')}
                      onBlur={handleBlur('region')}
                      placeholder="Region"
                      accessibilityLabel="Region"
                    />
                    <Spacer />
                    <TextInput
                      label="Country (Country Code) *"
                      value={countryCode}
                      errorMessage={errors.countryCode}
                      onChangeText={handleChange('countryCode')}
                      onBlur={handleBlur('countryCode')}
                      placeholder="ISO code(s): e.g GB,DE for multiple"
                      accessibilityLabel="Country code"
                    />
                    <Spacer />
                    <TextInput
                      label="Post code *"
                      value={postalCode}
                      errorMessage={errors.postalCode}
                      onChangeText={handleChange('postalCode')}
                      onBlur={handleBlur('postalCode')}
                      placeholder="Post code"
                      accessibilityLabel="Post code"
                    />
                    <Spacer />
                    {submissionError && (
                      <View style={tailwind('bg-red-100 p-4 rounded-md text-red-500')}>
                        <Text style={tailwind('text-red-600')}>{submissionError.message}</Text>
                        {Object.keys(submissionError.errors).map((k) => (
                          <Text style={tailwind('text-red-600 py-1 pl-2')} key={k}>{`∙${k}: ${
                            (submissionError.errors as Record<string, string>)[k]
                          }`}</Text>
                        ))}
                      </View>
                    )}
                    <Spacer />
                    <Button onPress={handleSubmit} loading={loading} isDisabled={loading} text="Next" />
                    <Spacer />
                  </View>
                );
              }}
            </Formik>
          </ScreenSidePadding>
        </ScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
});

const Spacer: FC = () => {
  const tailwind = useTailwind();
  return <View style={tailwind('py-2')} />;
};
