/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentFullList } from '../models/InstrumentFullList';
import { request as __request } from '../core/request';

export class InstrumentRecommendationService {

    /**
     * @returns InstrumentFullList
     * @throws ApiError
     */
    public static async apiInstrumentRecommendationList(): Promise<Array<InstrumentFullList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/instrument-recommendation/`,
        });
        return result.body;
    }

}
