import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Privacy: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" {...props}>
      <Path
        d="M11 1v20M19.904 11H2.096M11 1C8 3 4 4 1 4c0 7 3 14 10 17 7-3 10-10 10-17-3 0-7-1-10-3z"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
