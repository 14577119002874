import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Contact: React.FC<SvgProps> = (props) => {
  return (
    <Svg width={24} height={22} viewBox="0 0 24 22" fill="none" {...props}>
      <Path
        d="M23 4v11a2 2 0 01-2 2h-2v4l-6-4h-1"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M17 1H3a2 2 0 00-2 2v8a2 2 0 002 2h2v5l7-5h5a2 2 0 002-2V3a2 2 0 00-2-2z"
        stroke="#0F766E"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
