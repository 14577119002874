import { isNil } from 'lodash';
import React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Comment as CommentData } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Skeleton } from '../../ui/Skeleton';
import { feedItemRelativeTime, parseUTCDateFromString } from '../../util/date';
import { IdeaFlairChip, Props as IdeaFlairChipProps } from '../Chip/IdeaFlairChip';
import { ExpandableText } from '../ExpandableText';
import { Link } from '../Link';
import { Persona } from '../Persona';

export type Props = Pick<CommentData, 'text' | 'created' | 'user'> &
  Partial<Pick<CommentData, 'replyCount'>> & {
    /** no reply link on comment summary, so only show in bottom sheet, and not in replies bottom sheet */
    showRepliesLink: boolean;
    onReplyPress?: () => void;
    onUserProfilePress?: () => void;
    /** if providing an activeIdea will show idea flair chip and this is the press event to push to idea screen */
    onFlairPress?: () => void;
    /** When displaying comment summary clicking on the text anywhere should show bottom sheet */
    onCommentTextPress?: () => void;
    /** if have active idea with show idea flair chip in Persona */
    activeIdea?: IdeaFlairChipProps['activeIdea'];
    style?: StyleProp<ViewStyle>;
  };

export const Comment: React.VFC<Props> = ({
  text,
  created,
  user,
  replyCount,
  onReplyPress,
  onUserProfilePress,
  onFlairPress,
  onCommentTextPress,
  showRepliesLink,
  style,
  activeIdea,
}) => {
  const tailwind = useTailwind();
  return (
    <View style={[tailwind('px-6 py-3'), style]}>
      <Persona
        size="sm"
        onUserProfilePress={() => onUserProfilePress?.()}
        {...user}
        subText={feedItemRelativeTime(parseUTCDateFromString(created))}
        flairChip={
          activeIdea && (
            <IdeaFlairChip
              onPress={() => {
                onFlairPress?.();
              }}
              activeIdea={activeIdea}
            />
          )
        }
      />
      <Pressable accessibilityRole="button" style={tailwind('pl-8 pt-2')} onPress={onCommentTextPress}>
        <ExpandableText lineLimit={5}>{text}</ExpandableText>
        {showRepliesLink && (
          <>
            {!isNil(replyCount) && replyCount > 0 ? (
              <Link style={tailwind('pt-1.5')} onPress={onReplyPress}>
                View {replyCount} {replyCount > 1 ? 'replies' : 'reply'}
              </Link>
            ) : (
              <Link style={tailwind('pt-1.5')} onPress={onReplyPress}>
                Reply
              </Link>
            )}
          </>
        )}
      </Pressable>
    </View>
  );
};

export const CommentSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('px-6 py-3')}>
      <View style={tailwind('flex-row items-center h-6')}>
        <Skeleton style={tailwind('h-6 w-6 rounded-full mr-2')} />
        <View style={tailwind('flex-col justify-around h-full')}>
          <Skeleton style={tailwind('h-2 w-12 rounded-sm')} />
          <Skeleton style={tailwind('h-2 w-6 rounded-sm')} />
        </View>
      </View>
      <View style={tailwind('pt-2 pl-8')}>
        {new Array(3).fill(null).map((_, i) => (
          <Skeleton key={i} style={tailwind('h-3 w-full my-1')} />
        ))}
      </View>
    </View>
  );
};
