import { View as MotiView } from 'moti';
import React from 'react';
import { SvgProps } from 'react-native-svg';
import { useTailwind } from '../../theme';
import { CirclePartial } from '../icons/CirclePartial';

export const CircleSpinner: React.VFC<SvgProps> = (props) => {
  const tailwind = useTailwind();
  return (
    <MotiView
      from={{ rotate: '0deg' }}
      transition={{
        loop: true,
        duration: 800,
        type: 'timing',
        repeatReverse: false,
        delay: 0,
      }}
      animate={{ rotate: '360deg' }}
      style={[tailwind('self-start'), { height: props.height, width: props.width }]}
    >
      <CirclePartial {...props} />
    </MotiView>
  );
};
