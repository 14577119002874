import React from 'react';
import { View } from 'react-native';
import { CoreInstrumentFieldsFragment } from '../../../generated/graphql';
import { shadowMd, useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { formatPercent, formatPrice } from '../../../util/number';
import { isFiniteNumber } from '../../../util/typeGuards';
import { InstrumentLogo } from '../../InstrumentLogo';

export type Props = Omit<CoreInstrumentFieldsFragment, 'id'>;

/**
 * Instrument, ticker, logo and price
 *
 * @param root0
 * @param root0.name
 * @param root0.ticker
 * @param root0.currency
 * @param root0.quotePrice
 * @param root0.closePrice
 */

export const InstrumentSquareCard: React.FC<Props> = ({ name, ticker, currency, quotePrice, closePrice, logoUrl }) => {
  const tailwind = useTailwind();
  let formattedPrice = '';
  let formattedPercentChange = '';
  let percentTextColor = 'text-warmGray-600';
  const dailyPercentageChange = quotePrice?.pctChange1d;
  if ((closePrice?.price ?? quotePrice?.midPrice) && currency?.iso) {
    formattedPrice = formatPrice(quotePrice?.midPrice ?? closePrice?.price ?? 0, currency.iso);
    formattedPercentChange = dailyPercentageChange ? formatPercent(dailyPercentageChange) : '';
  }
  if (isFiniteNumber(dailyPercentageChange)) {
    if (dailyPercentageChange > 0) {
      percentTextColor = 'text-green-600';
    } else if (dailyPercentageChange < 0) {
      percentTextColor = 'text-red-600';
    }
  }

  return (
    <View style={[shadowMd, tailwind('rounded-lg px-3'), { width: 152 }]}>
      <View style={tailwind('mt-3')}>
        <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
      </View>
      <Text style={tailwind('text-sm font-medium text-warmGray-800 pt-2')} numberOfLines={1}>
        {name}
      </Text>
      <Text style={tailwind('text-xs text-warmGray-500')} numberOfLines={1}>
        {ticker}
      </Text>
      <View style={tailwind('flex-row pt-1 items-end pb-3')}>
        <View style={tailwind('pr-1')}>
          <Text style={tailwind('text-warmGray-800')} numberOfLines={1}>
            {formattedPrice}
          </Text>
        </View>
        <View>
          <Text style={tailwind(`text-xs ${percentTextColor}`)} numberOfLines={1}>
            {formattedPercentChange}
          </Text>
        </View>
      </View>
    </View>
  );
};
