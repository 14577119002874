import { StringKey } from '../../util/types';
import { Analytics, AnalyticsEvent, defaults, IdentifyMetaData } from './types';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

/**
 * no-op on web
 *
 * Web analytics is initialized in the script tag of ../../web/index.html
 */
export const initAnalytics = async () => null;

/** just re-export window.analytics */
export const analytics: Analytics = {
  identify: (userId: string, metadata?: IdentifyMetaData) => {
    window.analytics.identify(userId, { ...metadata, ...defaults });
  },

  track: <T extends AnalyticsEvent, K extends StringKey<T>>(event: K, metadata: T[K]) => {
    window.analytics.track(event, { ...metadata, ...defaults });
  },

  flush: () => {
    window.analytics.flush();
  },

  reset: () => {
    window.analytics.reset();
  },
};
