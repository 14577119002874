import { gql, NetworkStatus } from '@apollo/client';
import {
  BottomSheetFlatList,
  BottomSheetFlatListMethods,
  BottomSheetView,
  useBottomSheetInternal,
} from '@gorhom/bottom-sheet';
import { useFocusEffect } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { Platform } from 'react-native';
import { useAnimatedStyle } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { CORE_COMMENT_FIELDS } from '../../fragments/comments';
import { CORE_PAGINATION_FIELDS } from '../../fragments/pageInfo';
import { useGetCommentsQuery } from '../../generated/graphql';
import { useRefresh } from '../../hooks/useRefresh';
import { EmptyComments } from '../../old/Comment';
import { Divider } from '../../old/Divider';
import { withReloadErrorBoundary } from '../../wrappers/WithReloadErrorBoundary';
import { CommentsParamList } from './CommentSheet';
import { Header, HEIGHT as HEADER_HEIGHT } from './Header';
import { InputArea } from './Input';
import { CommentItem, renderComment } from './renderComment';
import { CommentsSkeleton } from './Skeleton';
import { useCommentSheet } from './useCommentSheet';

export const getComments = gql`
  ${CORE_COMMENT_FIELDS}
  ${CORE_PAGINATION_FIELDS}
  query getComments($ideaId: ID!, $entityType: AppEntityType!) {
    getTopic(topicInput: { appEntityType: $entityType, appEntityId: $ideaId }) {
      topic {
        commentCount
        comments {
          nodes {
            ...CoreCommentFields
          }
          pageInfo {
            ...CorePaginationFields
          }
        }
      }
    }
  }
`;

export const Comments: React.VFC<NativeStackScreenProps<CommentsParamList, 'Comments'>> = withReloadErrorBoundary(
  ({ route, navigation }) => {
    const { entityId, entityType, forceRefetch } = route.params;
    const listRef = React.useRef<BottomSheetFlatListMethods>(null);
    const { data, refetch, networkStatus } = useGetCommentsQuery({
      variables: {
        ideaId: entityId, // assuming for now all comment bottom sheets are on an Idea screen, hence entityId is ideaId for GQL query
        entityType,
      },
    });
    const { showCommentInput, showUserProfile, showIdea } = useCommentSheet();
    const { bottom: bottomSafeArea } = useSafeAreaInsets();

    // if deeplinking to here, user might have previously seen page, so the comments will be cached and stale.
    // Hence force re-fetch upon focus
    useFocusEffect(
      React.useCallback(() => {
        if (forceRefetch) {
          console.log(`Forcing refetch on focus as forceRefetch route param passed`);
          refetch();
        }
      }, [forceRefetch, refetch]),
    );

    const { refreshing, onRefresh } = useRefresh(refetch);
    const goToReplies = React.useCallback(
      (commentId: string) => {
        navigation.navigate('Replies', { parentCommentId: commentId, entityId, entityType });
      },
      [navigation, entityId, entityType],
    );
    const comments: CommentItem[] =
      data?.getTopic?.topic?.comments?.nodes.map((c) => ({
        ...c,
        showRepliesLink: true,
        onUserProfilePress: showUserProfile,
        onReplyPress: goToReplies,
        onFlairPress: showIdea,
      })) ?? [];

    const loading = networkStatus === NetworkStatus.loading;
    const bottomSheetInternal = useBottomSheetInternal();

    const listStyles = useAnimatedStyle(
      () => ({
        height: bottomSheetInternal.animatedContentHeight.value - HEADER_HEIGHT,
      }),
      [bottomSheetInternal],
    );

    return (
      <BottomSheetView pointerEvents="box-none" style={Platform.OS !== 'web' ? { flex: 1 } : undefined}>
        <Header type="Comments" count={data?.getTopic?.topic?.commentCount} />
        <BottomSheetFlatList
          ListHeaderComponent={<InputArea type="comment" onPress={showCommentInput} />}
          ListEmptyComponent={loading ? <CommentsSkeleton /> : <EmptyComments commentType="comments" />}
          ItemSeparatorComponent={Divider}
          style={listStyles}
          contentContainerStyle={{ paddingBottom: bottomSafeArea }}
          focusHook={useFocusEffect}
          ref={listRef}
          refreshing={refreshing}
          onRefresh={onRefresh}
          keyExtractor={(c) => c.id}
          data={comments}
          renderItem={renderComment}
        />
      </BottomSheetView>
    );
  },
);
