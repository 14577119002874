import { FC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { formatPercent } from '../../util/number';
import { CollectionComponents } from '../CollectionComponents';
import { Pressable } from '../Pressable';
import { Text } from '../Text';
import { CommonProps, Heading, SubText } from './common';

export const PortfolioBaseCard: FC<CommonProps> = ({ title, components, componentCount, subText, onPress, weight }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      style={tailwind('flex-1 bg-primary-lightest px-5 pr-3 py-4 rounded-3xl')}
      onPress={onPress}
      accessibilityRole="button"
    >
      <Heading>{title}</Heading>
      <SubText style={tailwind('pt-1 pb-3')}>{subText}</SubText>
      <View style={tailwind('items-center justify-between flex-row')}>
        <Text style={tailwind('text-primary-dark text-xs font-medium')}>{formatPercent(weight, 1, false, true)}</Text>
        <CollectionComponents componentCount={componentCount} cutOff={5} components={components} />
      </View>
    </Pressable>
  );
};
