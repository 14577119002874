import React from 'react';
import { useTailwind } from '../../theme';
import { Skeleton } from '../../ui/Skeleton';
import { ExpandableText } from '../ExpandableText';

const LINE_LIMIT = 7;

export const InstrumentDescription: React.VFC<{ children: React.ReactText }> = ({ children }) => {
  return <ExpandableText lineLimit={LINE_LIMIT}>{children}</ExpandableText>;
};

export const InstrumentDescriptionSkeleton = () => {
  const tailwind = useTailwind();
  return <Skeleton style={tailwind('h-24')} />;
};
