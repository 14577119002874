import { capitalize } from 'lodash';
import React, { useCallback, useState } from 'react';
import { FlatList, View } from 'react-native';
import { FilterCategory } from '../../generated/graphql';
import { useDebounce } from '../../hooks/useDebounce';
import { Chip } from '../../old/Chip';
import { useTailwind } from '../../theme';

const FILTER_CATEGORIES = Object.values(FilterCategory);
export type FilterCategoriesTags = Array<FilterCategory>;

export const FeedFilter: React.FC<{
  onChange: (selected: FilterCategoriesTags) => void;
  value: FilterCategoriesTags;
  // Tags to omit from feed filters
  omitTags?: FilterCategoriesTags;
}> = ({ onChange, value, omitTags = [] }) => {
  const tailwind = useTailwind();
  const renderItem = useCallback(
    ({ item }) => {
      return (
        <Chip
          size={'sm'}
          selected={item === 'ALL' ? value.length == 0 : value.includes(item)}
          key={item}
          text={capitalize(item)}
          onPress={() => onChange(item)}
        />
      );
    },
    [value, onChange],
  );

  return (
    <FlatList
      data={
        ['ALL', ...FILTER_CATEGORIES].filter((i) => !omitTags.includes(i as FilterCategory)) as (
          | FilterCategory
          | 'ALL'
        )[]
      }
      horizontal
      showsHorizontalScrollIndicator={false}
      keyExtractor={keyExtractor}
      contentContainerStyle={tailwind('px-5')}
      ItemSeparatorComponent={() => <View style={tailwind('px-1')} />}
      renderItem={renderItem}
    />
  );
};

const keyExtractor = (item: FilterCategory | 'ALL') => item;

/**
 * Hook to use with feed filters.
 * This enables filters to be a controlled component (values + state controlled in the parent component)
 *
 * The onChange function should be passed as a prop to the filter
 *
 * @returns Selected tags, debounced selected tags, onChange
 */
export function useFeedFilter() {
  const [selectedFilterTags, setSelectedFilterTags] = useState<FilterCategoriesTags>([]);
  const debouncedSelectedFilterTags = useDebounce(selectedFilterTags, 250);

  const onFilterChange = useCallback(
    (tag) => {
      if (tag === 'ALL') {
        setSelectedFilterTags([]);
      } else {
        const newSelectedTags = selectedFilterTags.includes(tag)
          ? selectedFilterTags.filter((i) => i !== tag)
          : [...selectedFilterTags, tag];
        setSelectedFilterTags(newSelectedTags);
      }
    },
    [selectedFilterTags],
  );

  return { debouncedSelectedFilterTags, selectedFilterTags, onFilterChange };
}
