import { FC } from 'react';
import { formatNumber } from '../../util/number';
import { BadgeColor, PortfolioStatistic } from '../PortfolioStatistic';

type Props = {
  sharpeRatio: number;
};

const descriptionText =
  "A measure of your portfolio's return relative to the risk taken to achieve the it. Generally, a higher Sharpe Ratio indicates good performance, given the risk.";

export const SharpeRatio: FC<Props> = ({ sharpeRatio }) => {
  const badgeProps = getBadgeProperties(sharpeRatio);

  return (
    <PortfolioStatistic valueText={formatNumber(sharpeRatio, 1)} descriptionText={descriptionText} {...badgeProps} />
  );
};

function getBadgeProperties(sharpeRatio: number): {
  badgeText: 'UNDERPERFORMED' | 'ACCEPTABLE' | 'GOOD' | 'EXCELLENT';
  badgeColor: BadgeColor;
} {
  if (sharpeRatio < 0) {
    return {
      badgeText: 'UNDERPERFORMED',
      badgeColor: 'RED',
    };
  } else if (sharpeRatio > 2) {
    return {
      badgeText: 'EXCELLENT',
      badgeColor: 'GREEN',
    };
  } else if (sharpeRatio > 1) {
    return {
      badgeText: 'GOOD',
      badgeColor: 'GREEN',
    };
  } else {
    return {
      badgeText: 'ACCEPTABLE',
      badgeColor: 'YELLOW',
    };
  }
}
