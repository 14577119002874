import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import { useCallback, VFC } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { CurationsCategoryFeedItem, DiscoverCategoryType, Maybe } from '../../../generated/graphql';
import { CURATION_SQUARE_CARD_WIDTH, CurationSquareCard } from '../../../old/discoverCards/CurationSquareCard';
import { useTailwind } from '../../../theme';
import { Pressable } from '../../../ui/Pressable';
import { DiscoverNavigationProps } from '../Discover';

const SQUARE_CARD_SPACING = 16;

type Props = {
  data: CurationsCategoryFeedItem[];
  discoverCategoryType?: Maybe<DiscoverCategoryType>;
};
export const CurationSideScroll: VFC<Props> = ({ data, discoverCategoryType }) => {
  const tailwind = useTailwind();
  const renderItem: ListRenderItem<CurationsCategoryFeedItem[]> = useCallback(
    ({ item }) => <Item items={item} discoverCategoryType={discoverCategoryType} />,
    [discoverCategoryType],
  );

  return (
    <FlatList
      horizontal
      contentContainerStyle={tailwind('px-5 py-2')}
      ItemSeparatorComponent={() => <View style={{ paddingLeft: SQUARE_CARD_SPACING }} />}
      showsHorizontalScrollIndicator={false}
      // Chunk to present 2 rows of data
      data={_.chunk(data, 2)}
      keyExtractor={keyExtractor}
      snapToInterval={CURATION_SQUARE_CARD_WIDTH + SQUARE_CARD_SPACING}
      snapToAlignment="start"
      decelerationRate="fast"
      renderItem={renderItem}
    />
  );
};

// Generate id for every cell in 2-row sideScroll.
let emptyCellId = 0;
const keyExtractor = ([curation0, curation1]: CurationsCategoryFeedItem[]) =>
  `a-${curation0?.id ?? ++emptyCellId}b-${curation1?.id ?? ++emptyCellId}`;

const Item: VFC<{ items: CurationsCategoryFeedItem[]; discoverCategoryType?: Maybe<DiscoverCategoryType> }> = ({
  items,
  discoverCategoryType,
}) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<DiscoverNavigationProps>();

  return (
    <View style={{ width: CURATION_SQUARE_CARD_WIDTH }}>
      <Pressable
        accessibilityRole="button"
        onPress={() =>
          navigation.navigate('DiscoverCategory', {
            categoryId: items[0].id ?? '',
            categoryType: discoverCategoryType ?? DiscoverCategoryType.Curations,
          })
        }
      >
        <CurationSquareCard {...items[0]} />
      </Pressable>
      {/* Spacer */}
      <View style={tailwind('py-2')} />
      <Pressable
        accessibilityRole="button"
        onPress={() =>
          navigation.navigate('DiscoverCategory', {
            categoryId: items[1].id ?? '',
            categoryType: discoverCategoryType ?? DiscoverCategoryType.Curations,
          })
        }
      >
        {items[1] && <CurationSquareCard {...items[1]} />}
      </Pressable>
    </View>
  );
};
