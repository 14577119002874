import { isDevice } from 'expo-device';
import { Platform } from 'react-native';
import OneSignal from 'react-native-onesignal';
import { ONESIGNAL_APP_ID } from '../constants/env';
import { usePersistedStore } from '../zustand/store';
import { analytics } from './analytics';
import { visitLink } from './openLink';

type SetExternalIdResults = {
  push?: {
    success?: boolean;
  };
};

/** known additional data keys can set in one signal dashboard */
export type AdditionalData = {
  deepLink?: string;
};

const skipOneSignal = () => Platform.OS === 'web' || !isDevice;

export const onesignal = {
  init: () => {
    try {
      if (skipOneSignal()) {
        console.log(`Not setting up onesignal on web/simulator`);
        return;
      }
      console.log(`Setting onesignal app id`);
      OneSignal.setLogLevel(6, 0);
      OneSignal.setAppId(ONESIGNAL_APP_ID);

      // Method for handling notifications received while app in foreground
      OneSignal.setNotificationWillShowInForegroundHandler((notificationReceivedEvent) => {
        console.log(`Foreground Push Notification received`, notificationReceivedEvent);
        const notification = notificationReceivedEvent.getNotification();
        console.log('Foreground push notification object: ', notification);
        // Complete with null means don't show a notification.
        notificationReceivedEvent.complete(notification);
      });

      // Method for handling notifications opened
      OneSignal.setNotificationOpenedHandler((event) => {
        console.log(`Push Notification Opened`, event);
        processNotification(event.notification.additionalData);
      });

      // listen to when subscribed to pushes to tell analytics
      OneSignal.addSubscriptionObserver((event) => {
        const userId = usePersistedStore.getState().userId;
        if (event.from.isSubscribed !== event.to.isSubscribed) {
          console.log(`OneSignal subscription event: setting analytics grantedPushToken ${event.to.isSubscribed}`);
          analytics.identify(`${userId}`, { grantedPushToken: event.to.isSubscribed });
        }
      });
    } catch (e) {
      console.error(`Error init onesignal`, e);
    }
  },
  setIdentity: (userId: string) => {
    try {
      if (skipOneSignal()) {
        return;
      }
      console.log(`Setting external id ${userId} on onesignal`);
      OneSignal.setExternalUserId(userId, (results: SetExternalIdResults) => {
        // The results will contain push and email success statuses
        console.log('Results of setting onesignal external user id', results);

        // Push can be expected in almost every situation with a success status, but
        // as a pre-caution its good to verify it exists
        if (results.push && results.push.success) {
          console.log('Successful onesignal push register');
        }
      });
    } catch (e) {
      console.error(`Error setting external id on onesignal`, e);
    }
  },
  /** on iOS display the push prompt designed in onesignal  */
  pushPrompt: async () => {
    const userId = usePersistedStore.getState().userId;
    if (Platform.OS === 'android') {
      // android by default grants push permission
      console.log('android setting analytics grantedPushToken true by default');
      analytics.identify(`${userId}`, { grantedPushToken: true });
      return;
    }
    const deviceState = await OneSignal.getDeviceState();
    if (deviceState?.isSubscribed == false) {
      console.log(`Setting prompt_push_notification trigger for OneSignal to display ios push prompt`);
      // make sure in one signal dashboard soft push prompt is triggered when key `prompt_push_notification` is true
      OneSignal.addTrigger('prompt_push_notification', 'true');
    } else {
      console.log(`Already subscribed to push permission`);
    }
  },

  logout: () => {
    if (skipOneSignal()) {
      return;
    }
    console.log(`removing external id from onesignal`);
    OneSignal.removeExternalUserId((results: SetExternalIdResults) => {
      // The results will contain push and email success statuses
      console.log('Results of removing onesignal external user id', results);
    });
  },
};

const processNotification = (data?: AdditionalData | undefined) => {
  try {
    if (!data) return;

    // process additional data
    if (data.deepLink) {
      analytics.track('Notification Pressed', {
        action: 'deepLink',
        notificationSource: 'pushNotification',
        deepLinkUrl: data.deepLink ?? '',
      });
      console.log(`Push Notification with deepLink, navigating to ${data.deepLink}`);
      return visitLink(data.deepLink);
    }
  } catch (e: unknown) {
    console.error(`Error processing one signal push notification press`, e);
  }
};
