import { Dimensions, View } from 'react-native';

/**
 * Measures a container's position relative to window to detect whether it is in view or not.
 *
 * @param viewThreshold Proportion of content that should be visible to be considered 'in view'
 * @param onIsInView Callback called if container is in view
 */
export const isItemInView = (
  ref: React.RefObject<View> | React.MutableRefObject<View>,
  viewThreshold: number,
  onIsInView: () => void,
): void => {
  if (!ref.current) return;
  ref.current.measureInWindow((_, y, __, height) => {
    const screenHeight = Dimensions.get('window').height;
    // Set how much of the item should be in the viewport to be considered 'in view';
    const viewableOffset = viewThreshold * height;
    const isInView = y < screenHeight - viewableOffset;
    if (isInView) {
      onIsInView();
    }
  });
};
