import React from 'react';
import { View, ViewStyle } from 'react-native';
import { useTailwind } from '../../theme';

export type Props = {
  style?: ViewStyle;
};
export const VerticalDivider: React.FC<Props> = ({ style }) => {
  const tailwind = useTailwind();
  return <View style={[tailwind('bg-warmGray-200 w-0.5'), style]} />;
};
