import { useNavigation } from '@react-navigation/native';
import { isNil } from 'lodash';
import React from 'react';
import { View } from 'react-native';
import { CoreInstrumentFieldsFragment } from '../../generated/graphql';
import { AllRoutesStackNavigationProps } from '../../navigation';
import { useTailwind } from '../../theme';
import { Skeleton } from '../../ui/Skeleton';
import { Text } from '../../ui/Text';
import { getPercentageTextColor } from '../../util/color';
import { formatPercent, formatPrice } from '../../util/number';
import { OutlineInnerCard } from '../Card';
import { InstrumentLogo } from '../InstrumentLogo';
import { Price } from './Price';
import { WatchlistButton } from './WatchlistButton';

export type Props = Omit<CoreInstrumentFieldsFragment, 'id'> & {
  id?: string | undefined;
  pctChange1d?: number | null;
  /** render watchlist star button on the right, and move the price below instrument name */
  showWatchlistToggle?: boolean;
  /** true to surround with `OutlineInnerCard` and press to navigate to instrument screen */
  isPressableCard?: boolean;
};

/**
 * Instrument, ticker, logo and price.
 * Now Memoized to help speed up pressing star to watchlist on Discover Category
 *
 * Optionally pressable to navigate to instrument screen and surround in card outline
 */
export const InstrumentPriceRow = React.memo(function InstrumentPriceRow({
  name,
  id,
  watchlistId,
  ticker,
  logoUrl,
  currency,
  quotePrice,
  closePrice,
  pctChange1d,
  showWatchlistToggle,
  isPressableCard,
}: Props) {
  const tailwind = useTailwind();
  let formattedPrice = '';
  let formattedPercentChange = '';
  const percentageChange = pctChange1d ?? quotePrice?.pctChange1d;
  if ((closePrice?.price ?? quotePrice?.midPrice) && currency?.iso) {
    formattedPrice = formatPrice(quotePrice?.midPrice ?? closePrice?.price ?? 0, currency.iso);
    formattedPercentChange = !isNil(percentageChange) ? formatPercent(percentageChange) : '';
  }
  const percentageTextColor = getPercentageTextColor(percentageChange);

  return (
    <PressableCardWrapper id={id} isPressableCard={isPressableCard}>
      <View style={tailwind('flex-row justify-between')}>
        <View style={tailwind('flex-row flex-shrink')}>
          <InstrumentLogo logoUrl={logoUrl ?? undefined} ticker={ticker ?? ''} size="small" />
          <View style={tailwind('flex-shrink ml-2')}>
            <View>
              <Text style={tailwind('text-sm font-medium text-warmGray-800')} numberOfLines={1}>
                {name}
              </Text>
            </View>
            <View style={tailwind('flex-row items-center')}>
              <Text style={tailwind('text-xs text-warmGray-500')} numberOfLines={1}>
                {ticker}
              </Text>
              {showWatchlistToggle && (
                <>
                  <Text>{' · '}</Text>
                  <Price
                    formattedPercentChange={formattedPercentChange}
                    formattedPercentColor={percentageTextColor}
                    formattedPrice={formattedPrice}
                  />
                </>
              )}
            </View>
          </View>
        </View>
        <View style={tailwind('items-end justify-center ml-4')}>
          {showWatchlistToggle ? (
            <WatchlistButton name={name} id={id ?? ''} watchlistId={watchlistId} ticker={ticker} />
          ) : (
            <Price
              formattedPercentChange={formattedPercentChange}
              formattedPercentColor={percentageTextColor}
              formattedPrice={formattedPrice}
            />
          )}
        </View>
      </View>
    </PressableCardWrapper>
  );
});

type PressableCardWrapperProps = { isPressableCard?: boolean; id?: string };

/** outline pressable card, or fragment if not pressable */
const PressableCardWrapper: React.FC<PressableCardWrapperProps> = ({ isPressableCard, id, children }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();
  if (!isPressableCard) {
    return <>{children}</>;
  }
  return (
    <OutlineInnerCard
      key={id}
      style={tailwind('my-2')}
      onPress={() => {
        navigation.push('Instrument', { instrumentId: id ?? '' });
      }}
    >
      {children}
    </OutlineInnerCard>
  );
};

export const InstrumentPriceRowSkeleton = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row items-center')}>
      <Skeleton style={tailwind('h-9 w-9 rounded-lg')} />
      <View style={tailwind('flex-1 justify-center pl-2')}>
        <Skeleton style={tailwind('h-4 w-32 mb-2')} />
        <Skeleton style={tailwind('h-3 w-11')} />
      </View>
      <View style={tailwind('items-end')}>
        <Skeleton style={tailwind('h-4 w-14 mb-2')} />
        <Skeleton style={tailwind('h-3 w-11')} />
      </View>
    </View>
  );
};
