import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { CrossDirection, FeedCardFieldsFragment } from '../../../generated/graphql';
import { AllRoutesStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardHeadline, CardHeadlineText, CardTitle, OutlineInnerCard } from '../../Card';
import { TrendDownFilled, TrendUp, Warning } from '../../icons';
import { InstrumentPriceRow } from '../../InstrumentPriceRow';
import { Link } from '../../Link';

export type Props = NarrowByTypename<FeedCardFieldsFragment, 'CrossFeedItem'>;

export const CrossCard: React.FC<Props> = ({ crossDirection, crossDate, instrument, id, __typename }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<AllRoutesStackNavigationProps>();
  return (
    <Card>
      <CardTitle
        feedItem={{ id, __typename, instrument }}
        icon={<Warning />}
        eventTime={crossDate ? new Date(crossDate) : undefined}
      >
        Technical indicator
      </CardTitle>
      <CardHeadline icon={crossDirection === CrossDirection.Death ? <TrendDownFilled /> : <TrendUp />}>
        <CardHeadlineText>
          {crossDirection === CrossDirection.Death ? (
            <Text style={tailwind('text-red-600')}>Death cross event: </Text>
          ) : (
            <Text style={tailwind('text-green-600')}>Golden cross event: </Text>
          )}
          <Link redirectIfLoggedOut>{instrument?.name}</Link> price pattern indicates a potential price{' '}
          {crossDirection === CrossDirection.Death ? 'downturn' : 'upturn'}.
        </CardHeadlineText>
      </CardHeadline>
      <OutlineInnerCard
        onPress={() => {
          navigation.push('Instrument', { instrumentId: instrument?.id ?? '' });
          analytics.track('Feed card pressed', {
            'Feed card type': __typename,
            'Destination screen': 'Instrument',
            'Feed item id': id ?? '',
            'Instrument ticker': instrument?.ticker ?? '',
          });
        }}
      >
        <InstrumentPriceRow {...instrument} />
      </OutlineInnerCard>
    </Card>
  );
};
