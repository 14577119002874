import { useNavigation } from '@react-navigation/native';
import { VFC } from 'react';
import { View } from 'react-native';
import { Instrument, Maybe, QuotePrice } from '../../generated/graphql';
import { HomeNavigationProps } from '../../screens/Home';
import { shadowMd, useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { formatNumber, formatPercent, getPercentageDiff } from '../../util/number';

export type Props = Pick<Instrument, 'id' | 'displayName' | 'type' | 'ticker'> & {
  quotePrice?: Maybe<Pick<QuotePrice, 'midPrice' | 'previousClose'>>;
};

export const MarketDataRow: VFC<Props> = ({ id, displayName, quotePrice }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<HomeNavigationProps>();
  const midPrice = quotePrice?.midPrice;
  const previousClose = quotePrice?.previousClose;
  const delta = (midPrice ?? 0) - (previousClose ?? 0);
  const up = delta >= 0;
  const percentageDelta = getPercentageDiff(previousClose ?? 0, midPrice ?? 0);
  // TODO: Switch on instrument type once FX can be identified separately to equities
  const isFx = displayName === 'GBP/USD';
  return (
    <Pressable
      accessibilityRole="button"
      onPress={() => navigation.push('MarketInstrument', { instrumentId: id })}
      style={[tailwind('rounded-lg px-3 py-2'), shadowMd]}
    >
      <View style={tailwind('flex-row justify-between pb-1.5')}>
        <Text numberOfLines={1} style={tailwind('text-sm font-semibold text-warmGray-700 pr-1 self-center')}>
          {displayName}
        </Text>
        <View style={tailwind(`${getDeltaBackground(up)} px-1.5 py-1 rounded-md`)}>
          <Text style={tailwind('text-xs font-semibold text-warmGray-100 self-center')}>
            {formatPercent(percentageDelta)}
          </Text>
        </View>
      </View>
      <View style={tailwind('flex-row justify-between')}>
        <Text style={tailwind('text-warmGray-500 text-xs')}>{formatNumber(midPrice ?? 0, isFx ? 4 : 2)}</Text>
        <Text style={tailwind(`text-xs pr-1.5 ${getDeltaText(up)}`)}>
          {formatNumber(delta, isFx ? 4 : 2, false, true)}
        </Text>
      </View>
    </Pressable>
  );
};

function getDeltaBackground(up: boolean) {
  return up ? 'bg-green-600' : 'bg-red-500';
}

function getDeltaText(up: boolean) {
  return up ? 'text-green-500' : 'text-red-500';
}
