import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Skeleton, SkeletonView } from '../../ui/Skeleton';

export const NewsSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('px-6')}>
      <SkeletonView>
        {[88, 76].map((width, i) => (
          <View style={tailwind('py-1')} key={i}>
            <Skeleton style={{ width: `${width}%`, height: 20 }} />
          </View>
        ))}
        <View style={tailwind('py-3')}>
          <Skeleton style={{ width: '60%', height: 14 }} />
        </View>
        <View style={tailwind('py-3')}>
          <Skeleton style={{ height: 176 }} />
        </View>
        <View style={tailwind('py-3')}>
          {[98, 99, 86, 96, 93].map((width, i) => (
            <View style={tailwind('py-1')} key={i}>
              <Skeleton style={{ width: `${width}%`, height: 16 }} />
            </View>
          ))}
        </View>
      </SkeletonView>
    </View>
  );
};
