import { PixelRatio } from 'react-native';
import { round } from '../util/number';

/**
 * A helper function to apply custom dimension params to a dato imageURL for cases where only the base url of the image is applied (e.g Curation Discover Categories)
 *
 * @param datoUrl Dato image URL
 * @param width Desired image width (PixelRatio is already taken account of inside the function)
 * @param height Desired image height
 * @returns A dato image url with appropriate params applied
 */
export const formatDatoImageUrl = (datoUrl: string, { width, height }: { width: number; height?: number }) => {
  const url = new URL(datoUrl);
  if (url.host !== 'www.datocms-assets.com') {
    throw new Error(`Non-dato URL provided dato url formatter: ${url}`);
  }

  if (height) {
    url.searchParams.append('h', `${Math.ceil(height)}`);
  }
  url.searchParams.append('w', `${Math.ceil(width)}`);
  url.searchParams.append('auto', `compress`);
  url.searchParams.append('fm', `jpg`);
  url.searchParams.append('crop', `focalpoint`);
  url.searchParams.append('fit', `crop`);
  // Set pixel ratio
  url.searchParams.append('dpr', `${round(PixelRatio.get(), 1)}`);

  return url.toString();
};
