import { gql } from '@apollo/client';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { Formik } from 'formik';
import React from 'react';
import { Platform, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';
import * as Yup from 'yup';
import type { SchemaOf } from 'yup';
import { useLoginMutation } from '../../generated/graphql';
import { LoggedOutStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { PasswordInput, TextInput } from '../../old/inputs';
import { Link } from '../../old/Link';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { TitleBar } from '../../old/TitleBar';
import { setLoginState } from '../../services/logIn';
import { useTailwind } from '../../theme';
import { KeyboardAvoidingView } from '../../ui/KeyboardAvoidingView';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';

export type Props = NativeStackScreenProps<LoggedOutStackParamList, 'LogIn'>;

type LogInFormSchema = {
  email: string;
  password: string;
};

export const LOGIN = gql`
  mutation login($email: NonEmptyString!, $password: NonEmptyString!, $web: Boolean!) {
    login(email: $email, password: $password) {
      error {
        ... on AuthenticationFailed {
          message
        }
      }
      accessToken
      refreshToken @skip(if: $web)
      user {
        id
        email
        isStaff
        profile {
          id
          nickname
          profileRating
        }
      }
    }
  }
`;

const initialValues: LogInFormSchema = { email: '', password: '' };

const logInFormSchema: SchemaOf<LogInFormSchema> = Yup.object().shape({
  email: Yup.string().email('Must be a valid email address').trim().required('Email required'),
  password: Yup.string().required('Password required'),
});

export const LogIn: React.FC<Props> = ({ navigation }) => {
  const tailwind = useTailwind();
  const toast = useToast();
  const [login] = useLoginMutation();

  const onSubmit = async (values: LogInFormSchema) => {
    const response = await login({
      variables: {
        email: values.email,
        password: values.password,
        web: Platform.OS === 'web',
      },
    });
    const error = response.data?.login.error;
    if (error) {
      switch (error.__typename) {
        case 'AuthenticationFailed':
          toast.show(error.message);
          return;
        default:
          console.error(error);
          toast.show('An unknown error occurred');
          return;
      }
    }

    try {
      const loginData = response.data?.login;
      await setLoginState({
        accessToken: loginData?.accessToken,
        refreshToken: loginData?.refreshToken,
        user: loginData?.user,
        profile: loginData?.user?.profile,
      });
    } catch (e) {
      console.error(error);
      toast.show('An unknown error occurred');
    }
  };

  return (
    <SafeAreaView>
      <TitleBar title="Welcome Back" />

      <ScrollView>
        <KeyboardAvoidingView>
          <ScreenSidePadding style={tailwind('flex-1')}>
            <View style={tailwind('flex-grow')}>
              <Formik
                initialValues={initialValues}
                validationSchema={logInFormSchema}
                validateOnChange={false}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, handleSubmit, errors, isSubmitting }) => (
                  <View>
                    <View style={tailwind('pb-2')}>
                      <TextInput
                        accessibilityLabel="Email"
                        placeholder="Email"
                        value={values.email}
                        onChangeText={handleChange('email')}
                        keyboardType="email-address"
                        errorMessage={errors.email}
                        autoCapitalize="none"
                        textContentType="username"
                        autoComplete="username"
                      />
                    </View>
                    <View style={tailwind('py-2')}>
                      <PasswordInput
                        placeholder="Password"
                        onChangeText={handleChange('password')}
                        value={values.password}
                        errorMessage={errors.password}
                        textContentType="password"
                        autoComplete="password"
                      />
                    </View>
                    <View style={tailwind('py-2')}>
                      <Button
                        text={isSubmitting ? 'Logging you in...' : 'Log In'}
                        size="sm"
                        loading={isSubmitting}
                        isDisabled={isSubmitting}
                        onPress={() => handleSubmit()}
                      />
                    </View>
                  </View>
                )}
              </Formik>
              <View style={tailwind('py-2')}>
                <Button
                  text="Forgot Password"
                  size="sm"
                  variant="inverted"
                  onPress={() => navigation.navigate('RequestPasswordReset')}
                />
              </View>
            </View>
            <View style={tailwind('flex-row pt-2 items-center justify-center')}>
              <Text style={tailwind('text-warmGray-400')}>
                Don&apos;t have an account?{' '}
                <Link onPress={() => navigation.navigate('SignUp', { external: false })}>Sign Up</Link>
              </Text>
            </View>
          </ScreenSidePadding>
        </KeyboardAvoidingView>
      </ScrollView>
    </SafeAreaView>
  );
};
