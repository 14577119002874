import React from 'react';
import { View } from 'react-native';
import { Idea } from '../../generated/graphql';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { getRoundedTimeframe } from '../../util/date';
import { getPercentageDiff } from '../../util/number';
import { isFiniteNumber } from '../../util/typeGuards';
import { IdeaPriceDelta } from '../IdeaPriceDelta';

type Props = Pick<Idea, 'referenceDate' | 'targetDate' | 'referencePrice' | 'targetPrice' | 'performance'>;

export const IdeaSnapshot: React.FC<Props> = ({
  targetDate,
  targetPrice,
  referencePrice,
  referenceDate,
  performance,
}) => {
  const tailwind = useTailwind();
  const targetPriceChange = referencePrice && targetPrice ? getPercentageDiff(referencePrice, targetPrice) : undefined;
  return (
    <View style={tailwind('px-3 flex-row border-warmGray-200 justify-evenly border-t')}>
      <View style={tailwind('py-2 flex-col items-center')}>
        <Text style={tailwind('text-xs text-warmGray-500')}>Timeframe</Text>
        {referenceDate && targetDate && (
          <Text style={tailwind('text-lg text-warmGray-800')}>
            {getRoundedTimeframe(referenceDate, targetDate, true)}
          </Text>
        )}
      </View>
      <View accessibilityLabel="Predicted change" style={tailwind('py-2 flex-col items-center')}>
        <Text style={tailwind('text-xs text-warmGray-500')}>Predicted</Text>
        {isFiniteNumber(targetPriceChange) && <IdeaPriceDelta delta={targetPriceChange} />}
      </View>
      <View accessibilityLabel="Actual change" style={tailwind('pt-2 flex-col items-center')}>
        <Text style={tailwind('text-xs text-warmGray-500')}>Actual</Text>
        <IdeaPriceDelta delta={performance?.priceChangeSincePosted ?? 0} />
      </View>
    </View>
  );
};
