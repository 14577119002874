/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Country } from '../models/Country';
import type { Idea } from '../models/Idea';
import type { IdeaFile } from '../models/IdeaFile';
import type { IdeaId } from '../models/IdeaId';
import type { IdeaList } from '../models/IdeaList';
import type { IdeaPerformance } from '../models/IdeaPerformance';
import type { IdeaRationaleTag } from '../models/IdeaRationaleTag';
import type { IdeaRationaleTagCategory } from '../models/IdeaRationaleTagCategory';
import type { IdeaSummary } from '../models/IdeaSummary';
import type { Sector } from '../models/Sector';
import { request as __request } from '../core/request';

export class IdeaService {

    /**
     * @returns IdeaFile
     * @throws ApiError
     */
    public static async apiIdeaFileList({
        ideaId,
    }: {
        ideaId?: string,
    }): Promise<Array<IdeaFile>> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-file/`,
            query: {
                'idea_id': ideaId,
            },
        });
        return result.body;
    }

    /**
     * @returns IdeaFile
     * @throws ApiError
     */
    public static async apiIdeaFileCreate({
        data,
    }: {
        data: IdeaFile,
    }): Promise<IdeaFile> {
        const result = await __request({
            method: 'POST',
            path: `/api/idea-file/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaFile
     * @throws ApiError
     */
    public static async apiIdeaFileRead({
        uuid,
    }: {
        /** A UUID string identifying this idea file. **/
        uuid: string,
    }): Promise<IdeaFile> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-file/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiIdeaFileDelete({
        uuid,
    }: {
        /** A UUID string identifying this idea file. **/
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/idea-file/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns IdeaId
     * @throws ApiError
     */
    public static async apiIdeaIdList({
        isPublic,
    }: {
        isPublic?: string,
    }): Promise<IdeaId> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-id/`,
            query: {
                'is_public': isPublic,
            },
        });
        return result.body;
    }

    /**
     * @returns IdeaPerformance
     * @throws ApiError
     */
    public static async apiIdeaPerformanceList({
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<IdeaPerformance>> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-performance/`,
            query: {
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * @returns IdeaPerformance
     * @throws ApiError
     */
    public static async apiIdeaPerformanceRead({
        uuid,
    }: {
        /** A UUID string identifying this idea performance. **/
        uuid: string,
    }): Promise<IdeaPerformance> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-performance/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiIdeaPortfolioList({
        cursor,
        pageSize,
    }: {
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<IdeaList>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-portfolio/`,
            query: {
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiIdeaPublicList({
        user,
        statuses,
        instrumentId,
        countryIso2Codes,
        sectors,
        userIds,
        uuids,
        instrumentIds,
        cursor,
        pageSize,
    }: {
        user?: number,
        /** Multiple values may be separated by commas. **/
        statuses?: string,
        instrumentId?: string,
        /** Multiple values may be separated by commas. **/
        countryIso2Codes?: string,
        /** Multiple values may be separated by commas. **/
        sectors?: string,
        /** Multiple values may be separated by commas. **/
        userIds?: number,
        /** Multiple values may be separated by commas. **/
        uuids?: string,
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<IdeaList>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-public/`,
            query: {
                'user': user,
                'statuses': statuses,
                'instrument_id': instrumentId,
                'country_iso2_codes': countryIso2Codes,
                'sectors': sectors,
                'user_ids': userIds,
                'uuids': uuids,
                'instrument_ids': instrumentIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns Country
     * @throws ApiError
     */
    public static async apiIdeaPublicCountry({
        user,
        statuses,
        instrumentId,
        countryIso2Codes,
        sectors,
        userIds,
        uuids,
        instrumentIds,
        cursor,
        pageSize,
    }: {
        user?: number,
        /** Multiple values may be separated by commas. **/
        statuses?: string,
        instrumentId?: string,
        /** Multiple values may be separated by commas. **/
        countryIso2Codes?: string,
        /** Multiple values may be separated by commas. **/
        sectors?: string,
        /** Multiple values may be separated by commas. **/
        userIds?: number,
        /** Multiple values may be separated by commas. **/
        uuids?: string,
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<Country> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-public/country/`,
            query: {
                'user': user,
                'statuses': statuses,
                'instrument_id': instrumentId,
                'country_iso2_codes': countryIso2Codes,
                'sectors': sectors,
                'user_ids': userIds,
                'uuids': uuids,
                'instrument_ids': instrumentIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns Sector
     * @throws ApiError
     */
    public static async apiIdeaPublicSector({
        user,
        statuses,
        instrumentId,
        countryIso2Codes,
        sectors,
        userIds,
        uuids,
        instrumentIds,
        cursor,
        pageSize,
    }: {
        user?: number,
        /** Multiple values may be separated by commas. **/
        statuses?: string,
        instrumentId?: string,
        /** Multiple values may be separated by commas. **/
        countryIso2Codes?: string,
        /** Multiple values may be separated by commas. **/
        sectors?: string,
        /** Multiple values may be separated by commas. **/
        userIds?: number,
        /** Multiple values may be separated by commas. **/
        uuids?: string,
        /** Multiple values may be separated by commas. **/
        instrumentIds?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<Sector> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-public/sector/`,
            query: {
                'user': user,
                'statuses': statuses,
                'instrument_id': instrumentId,
                'country_iso2_codes': countryIso2Codes,
                'sectors': sectors,
                'user_ids': userIds,
                'uuids': uuids,
                'instrument_ids': instrumentIds,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns Idea
     * @throws ApiError
     */
    public static async apiIdeaPublicRead({
        uuid,
    }: {
        uuid: string,
    }): Promise<Idea> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-public/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTagCategory
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagCategoryList(): Promise<Array<IdeaRationaleTagCategory>> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-rationale-tag-category/`,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTagCategory
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagCategoryCreate({
        data,
    }: {
        data: IdeaRationaleTagCategory,
    }): Promise<IdeaRationaleTagCategory> {
        const result = await __request({
            method: 'POST',
            path: `/api/idea-rationale-tag-category/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTagCategory
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagCategoryRead({
        uuid,
    }: {
        /** A UUID string identifying this idea rationale tag category. **/
        uuid: string,
    }): Promise<IdeaRationaleTagCategory> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-rationale-tag-category/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTagCategory
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagCategoryPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this idea rationale tag category. **/
        uuid: string,
        data: IdeaRationaleTagCategory,
    }): Promise<IdeaRationaleTagCategory> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/idea-rationale-tag-category/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTag
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagList({
        uuids,
    }: {
        /** Multiple values may be separated by commas. **/
        uuids?: string,
    }): Promise<Array<IdeaRationaleTag>> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-rationale-tag/`,
            query: {
                'uuids': uuids,
            },
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTag
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagCreate({
        data,
    }: {
        data: IdeaRationaleTag,
    }): Promise<IdeaRationaleTag> {
        const result = await __request({
            method: 'POST',
            path: `/api/idea-rationale-tag/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTag
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagRead({
        uuid,
    }: {
        /** A UUID string identifying this idea rationale tag. **/
        uuid: string,
    }): Promise<IdeaRationaleTag> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-rationale-tag/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns IdeaRationaleTag
     * @throws ApiError
     */
    public static async apiIdeaRationaleTagPartialUpdate({
        uuid,
        data,
    }: {
        /** A UUID string identifying this idea rationale tag. **/
        uuid: string,
        data: IdeaRationaleTag,
    }): Promise<IdeaRationaleTag> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/idea-rationale-tag/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns IdeaSummary
     * @throws ApiError
     */
    public static async apiIdeaSummaryRead({
        user,
        statuses,
    }: {
        user: string,
        statuses?: string,
    }): Promise<IdeaSummary> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea-summary/${user}/`,
            query: {
                'statuses': statuses,
            },
        });
        return result.body;
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static async apiIdeaList({
        userId,
        creatorId,
        positions,
        statuses,
        countryIso2Codes,
        sectors,
        targetDateFrom,
        targetDateTo,
        minConviction,
        maxConviction,
        isPublic,
        instrumentId,
        sectorDisplayNames,
        cursor,
        pageSize,
    }: {
        userId?: number,
        creatorId?: string,
        /** Multiple values may be separated by commas. **/
        positions?: string,
        /** Multiple values may be separated by commas. **/
        statuses?: string,
        /** Multiple values may be separated by commas. **/
        countryIso2Codes?: string,
        /** Multiple values may be separated by commas. **/
        sectors?: string,
        targetDateFrom?: string,
        targetDateTo?: string,
        minConviction?: number,
        maxConviction?: number,
        isPublic?: string,
        instrumentId?: string,
        /** Multiple values may be separated by commas. **/
        sectorDisplayNames?: string,
        /** The pagination cursor value. **/
        cursor?: string,
        /** Number of results to return per page. **/
        pageSize?: number,
    }): Promise<{
        count: number,
        next?: string | null,
        previous?: string | null,
        results: Array<IdeaList>,
    }> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea/`,
            query: {
                'user_id': userId,
                'creator_id': creatorId,
                'positions': positions,
                'statuses': statuses,
                'country_iso2_codes': countryIso2Codes,
                'sectors': sectors,
                'target_date_from': targetDateFrom,
                'target_date_to': targetDateTo,
                'min_conviction': minConviction,
                'max_conviction': maxConviction,
                'is_public': isPublic,
                'instrument_id': instrumentId,
                'sector_display_names': sectorDisplayNames,
                'cursor': cursor,
                'page_size': pageSize,
            },
        });
        return result.body;
    }

    /**
     * @returns Idea
     * @throws ApiError
     */
    public static async apiIdeaCreate({
        data,
    }: {
        data: Idea,
    }): Promise<Idea> {
        const result = await __request({
            method: 'POST',
            path: `/api/idea/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns Idea
     * @throws ApiError
     */
    public static async apiIdeaRead({
        uuid,
    }: {
        uuid: string,
    }): Promise<Idea> {
        const result = await __request({
            method: 'GET',
            path: `/api/idea/${uuid}/`,
        });
        return result.body;
    }

    /**
     * @returns Idea
     * @throws ApiError
     */
    public static async apiIdeaPartialUpdate({
        uuid,
        data,
    }: {
        uuid: string,
        data: Idea,
    }): Promise<Idea> {
        const result = await __request({
            method: 'PATCH',
            path: `/api/idea/${uuid}/`,
            body: data,
        });
        return result.body;
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static async apiIdeaDelete({
        uuid,
    }: {
        uuid: string,
    }): Promise<void> {
        const result = await __request({
            method: 'DELETE',
            path: `/api/idea/${uuid}/`,
        });
        return result.body;
    }

}
