import React from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { SkeletonView } from '../../ui/Skeleton';
import { IdeaCardSkeleton } from '../IdeaCard/IdeaCard';

export const IdeasSkeleton: React.FC = () => {
  const tailwind = useTailwind();
  return (
    <SkeletonView style={tailwind('mt-8')}>
      {new Array(10).fill(null).map((_, i) => (
        <View key={i} style={tailwind('px-6 mt-4')}>
          <IdeaCardSkeleton />
        </View>
      ))}
    </SkeletonView>
  );
};
