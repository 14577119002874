import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Bulb = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M20 9A8 8 0 004 9a7.997 7.997 0 005 7.413V19h6v-2.587A7.997 7.997 0 0020 9zM9 23h6M8 9a4 4 0 014-4"
        stroke={props.color ?? '#0F766E'}
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export const BulbFilled = (props: SvgProps) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Path
        d="M20 9A8 8 0 004 9a7.997 7.997 0 005 7.413V19h6v-2.587A7.997 7.997 0 0020 9z"
        fill="#CCFBF1"
        stroke="#0F766E"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M9 23h6M8 9a4 4 0 014-4"
        stroke="#0F766E"
        strokeWidth={2}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
