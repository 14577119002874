import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import Animated from 'react-native-reanimated';

/**
 * This wrapping component is necessary for react-native-gesture-handler
 * to work properly. Depending on its use, its behavior may differ between platforms
 */
class Ghost extends React.Component {
  render() {
    return this.props.children;
  }
}

/**
 * For web, the default ghost has undesired behavior, regarding pickup up gestures
 * This fixes it for web
 */
class WebGhost extends React.Component {
  render() {
    return <Animated.View style={StyleSheet.absoluteFill}>{this.props.children}</Animated.View>;
  }
}

export const AnimatedGhost = Platform.OS === 'web' ? WebGhost : Animated.createAnimatedComponent(Ghost);
