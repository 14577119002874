import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

export type Props = {
  Logo?: React.ReactNode;
  topLeft: string;
  bottomLeft: string;
  topRight: string;
  bottomRight: string;
  size?: 'md' | 'lg';
};

export const PortfolioBreakdownRow: React.FC<Props> = ({
  Logo,
  topLeft,
  bottomLeft,
  topRight,
  bottomRight,
  size = 'md',
}) => {
  const tailwind = useTailwind();
  return (
    <View style={tailwind('flex-row justify-between items-center p-3')}>
      {Logo}
      <View style={tailwind('flex-1 ml-2')}>
        <View style={tailwind('flex-shrink ')}>
          <Text
            style={tailwind(`${size === 'md' ? 'text-sm' : 'text-lg'} font-medium text-warmGray-800`)}
            numberOfLines={1}
          >
            {topLeft}
          </Text>
        </View>
        <View style={tailwind('flex-row items-center')}>
          <Text style={tailwind('text-xs text-warmGray-500')} numberOfLines={1}>
            {bottomLeft}
          </Text>
        </View>
      </View>
      <View style={tailwind('items-end justify-center ml-4')}>
        <Text style={tailwind(`text-warmGray-800 ${size === 'md' ? 'text-sm' : 'text-lg'}`)}>{topRight}</Text>
        <Text style={tailwind('text-warmGray-400')}>{bottomRight}</Text>
      </View>
    </View>
  );
};
