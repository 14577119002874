import { useNavigation } from '@react-navigation/native';
import React, { useMemo } from 'react';
import { Image, View } from 'react-native';
import { FeedCardFieldsFragment } from '../../../generated/graphql';
import { LoggedInStackNavigationProps } from '../../../navigation';
import { analytics } from '../../../services/analytics';
import { useTailwind } from '../../../theme';
import { Text } from '../../../ui/Text';
import { getReadTime } from '../../../util/string';
import { NarrowByTypename } from '../../../util/types';
import { Card, CardTitle, OutlineInnerCard } from '../../Card';
import { Voice } from '../../icons';
import { TickerRow } from '../../TickerRow';
import { titleBarImageSource } from '../../TitleBar';

export type Props = Pick<
  NarrowByTypename<FeedCardFieldsFragment, 'UpsideVoiceFeedItem'>,
  'id' | '__typename' | 'voice'
>;

export const UpsideVoiceCard: React.FC<Props> = ({ voice, __typename, id }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();

  const readTime = useMemo(() => {
    const words = voice?.body?.split(' ') ?? [];
    return getReadTime(words.length);
  }, [voice?.body]);

  if (!voice) {
    return null;
  }

  const { title, excerpt, heroImage, instruments, publishDate } = voice;

  const cardPressed = () => {
    navigation.push('Voice', { voiceId: id });
    analytics.track('Feed card pressed', {
      'Destination screen': 'Voice',
      'Feed card type': __typename,
      'Feed item id': id,
      'Instrument ticker': instruments.map((i) => i?.id).join(' '),
    });
  };

  return (
    <Card>
      <View>
        <CardTitle
          feedItem={{ id, __typename, voice }}
          icon={<Voice />}
          eventTime={publishDate ? new Date(publishDate) : undefined}
        >
          Upside voice
        </CardTitle>
        <OutlineInnerCard onPress={() => cardPressed()} innerStyle={tailwind('px-0 py-0')}>
          <View style={tailwind('bg-teal-50 flex-1')}>
            <View style={tailwind('py-2 px-3 flex-row flex-1')}>
              <Image
                source={titleBarImageSource}
                accessibilityIgnoresInvertColors={false}
                style={tailwind('h-8 w-8 rounded-full mr-2 self-center')}
              />
              <Text numberOfLines={3} style={tailwind('text-teal-800 font-medium self-center flex-wrap pr-1')}>
                {title}
              </Text>
            </View>
          </View>
          <View style={tailwind('py-3 px-3 flex-row')}>
            <Text style={tailwind('text-warmGray-800 text-xs')}>{excerpt}</Text>
          </View>
          {heroImage?.url && (
            <View style={tailwind('px-3 justify-center')}>
              <Image
                source={{ uri: heroImage.url ?? '' }}
                style={tailwind('h-44 rounded-lg')}
                resizeMode="cover"
                accessibilityIgnoresInvertColors={true}
              />
            </View>
          )}
          <Text style={tailwind('italic py-2 px-4 self-end text-warmGray-600 text-xs')}>{readTime} min read</Text>
          <View style={tailwind('pb-3 px-3 flex-row')}>
            <TickerRow instruments={instruments} />
          </View>
        </OutlineInnerCard>
      </View>
    </Card>
  );
};
