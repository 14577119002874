import React from 'react';
import { View, ViewStyle } from 'react-native';
import { useTailwind } from '../../theme';
import { Pressable } from '../../ui/Pressable';
import { Text } from '../../ui/Text';
import { Info } from '../icons/Info';

export type Props = {
  label: string;
  value: string;
  style?: ViewStyle;
  onPress: () => void;
};

export const PortfolioInfoBox: React.FC<Props> = ({ label, value, style, onPress }) => {
  const tailwind = useTailwind();
  return (
    <Pressable
      onPress={onPress}
      accessibilityLabel={label}
      style={[tailwind('bg-warmGray-100 items-center px-1 pt-2'), style]}
    >
      <View style={tailwind('flex-row items-center')}>
        <Text style={tailwind('text-teal-700 text-xs font-semibold pr-1')}>{label}</Text>
        <Info height={10} width={10} />
      </View>
      <Text style={tailwind('text-warmGray-500 text-base font-light pb-2')}>{value}</Text>
    </Pressable>
  );
};
