export const getLogoPlaceholder = (ticker: string) => {
  return ticker.split(' ')[0].substr(0, 4);
};

/**
 * Crude estimation of read time rounded up to the nearest minute. Uses average words/min result from study below
 * https://digest.bps.org.uk/2019/06/13/most-comprehensive-review-to-date-suggests-the-average-persons-reading-speed-is-slower-than-commonly-thought/
 *
 * @returns Estimated read time in minutes, rounded up.
 */
export const getReadTime = (wordCount: number): number => {
  // Assumes an average of 238 words per minute
  const minutes = wordCount / 238;
  return Math.ceil(minutes);
};

export const timeIntervals = ['7D', '1M', '3M', '1Y', '5Y'] as const;

export type TimeInterval = typeof timeIntervals[number];
