import { FC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../Text';
import { LayersBaseColoured, LayersBundlesColoured, LayersStocksColoured } from '../VectorImages';

export const BaseLayerDiagram: FC = () => {
  const tailwind = useTailwind();

  return (
    <>
      <Text style={tailwind('text-base font-semibold text-default pt-6 pb-12')}>
        Your <Text style={tailwind('text-light font-semibold')}>Base</Text> layer
      </Text>
      <View style={tailwind('items-center')}>
        <View style={tailwind('pr-6 pb-4')}>
          <LayersBaseColoured />
        </View>
        <View style={tailwind('rounded-lg bg-primary-light px-4 py-1')}>
          <Text style={tailwind('font-medium text-primary-dark text-xs')}>LOWER RISK</Text>
        </View>
      </View>
    </>
  );
};

export const BundlesLayerDiagram: FC = () => {
  const tailwind = useTailwind();

  return (
    <>
      <Text style={tailwind('text-base font-semibold text-default pt-6 pb-12')}>
        Your <Text style={tailwind('text-secondary-dark font-semibold')}>Bundles</Text> layer
      </Text>
      <View style={tailwind('items-center')}>
        <View style={tailwind('pr-6 pb-4')}>
          <LayersBundlesColoured />
        </View>
        <View style={tailwind('rounded-lg bg-secondary-light px-4 py-1')}>
          <Text style={tailwind('font-medium text-secondary-dark text-xs')}>MEDIUM RISK</Text>
        </View>
      </View>
    </>
  );
};

export const StocksLayerDiagram: FC = () => {
  const tailwind = useTailwind();
  return (
    <>
      <Text style={tailwind('text-base font-semibold text-default pt-6 pb-12')}>
        Your <Text style={tailwind('text-tertiary-dark font-semibold')}>Single Stocks</Text> layer
      </Text>
      <View style={tailwind('items-center')}>
        <View style={tailwind('pr-6 pb-4')}>
          <LayersStocksColoured />
        </View>
        <View style={tailwind('rounded-lg bg-tertiary-light px-4 py-1')}>
          <Text style={tailwind('font-medium  text-xs text-tertiary-dark ')}>HIGHER RISK</Text>
        </View>
      </View>
    </>
  );
};
