/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstrumentFullList } from '../models/InstrumentFullList';
import { request as __request } from '../core/request';

export class MarketDataService {

    /**
     * Full instrument list
     * Paginated instrument list including most of their fields. Foreign key fields are returned as FK and not
     * serialized further.
     *
     * This endpoint doesn't require authentication.
     * @returns InstrumentFullList
     * @throws ApiError
     */
    public static async apiMarketDataList(): Promise<Array<InstrumentFullList>> {
        const result = await __request({
            method: 'GET',
            path: `/api/market-data/`,
        });
        return result.body;
    }

}
