import { CLIENT_URL } from '../constants/env';
import { CoreIdeaFieldsFragment, Maybe, Position } from '../generated/graphql';
import { formatDate } from './date';
import { formatPercent, getPercentageDiff } from './number';

export const getShareIdeaPayload = (userId: number, idea?: Maybe<CoreIdeaFieldsFragment>) => {
  if (!idea) throw new Error('Attempted to share invalid Idea');
  const isMyIdea = userId === idea.user?.id;
  const positionText = idea.position === Position.Long ? 'Bullish' : 'Bearish';

  const message = `Check out ${isMyIdea ? 'my' : 'this'} ${positionText} Upside Idea on ${idea.instrument?.name}
  Created: ${formatDate(new Date(idea.referenceDate ?? ''), 'DD MMM YY')}
  Predicted change: ${formatPercent(getPercentageDiff(idea.referencePrice ?? 0, idea.targetPrice ?? 0))}
  Target date: ${formatDate(new Date(idea.targetDate ?? ''), 'DD MMM YY')}
  Change since posted: ${formatPercent(idea.performance?.priceChangeSincePosted ?? 0)}
    `;

  return {
    message,
    url: `${CLIENT_URL}/Idea/${idea.id}`,
    title: message,
  };
};
