import { NavigationProp, useNavigation } from '@react-navigation/core';
import dayjs from 'dayjs';
import _, { isNil } from 'lodash';
import React, { useRef, useState } from 'react';
import { RefreshControl, View } from 'react-native';
import { GetInstrumentQuery, ReportRating } from '../../generated/graphql';
import { useInstrumentWatchlistToggle } from '../../hooks/mutations/useInstrumentWatchlistToggle';
import { LoggedInStackNavigationProps } from '../../navigation';
import { LoggedInStackParamList } from '../../navigation/RootStackNavigator';
import { Button } from '../../old/Button';
import { IconButton } from '../../old/Button/IconButton';
import { EsgScores } from '../../old/EsgScores';
import { isItemInView } from '../../old/EsgScores/util';
import { LightBulb, LightBulbFilled } from '../../old/icons';
import { Info } from '../../old/icons/Info';
import { Star, StarFilled } from '../../old/icons/Star';
import { InstrumentChart } from '../../old/InstrumentChart';
import { InstrumentHeader } from '../../old/InstrumentHeader';
import { InstrumentAboutBox, InstrumentSummaryBody, Row } from '../../old/InstrumentSummary';
import { formatContent } from '../../old/InstrumentSummary/utils';
import { Link } from '../../old/Link';
import { ReportRatingSnippet } from '../../old/ReportRatingSnippet';
import { ScreenSidePadding } from '../../old/StyledScreen';
import { EXTERNAL_URIs } from '../../services/externalURIs';
import { visitExternalLink } from '../../services/openLink';
import { useTailwind } from '../../theme';
import { ScrollView } from '../../ui/ScrollView';
import { Text } from '../../ui/Text';
import { parseUTCDateFromString } from '../../util/date';
import { formatPercent } from '../../util/number';

type Props = {
  instrument: GetInstrumentQuery['instrument'];
  ideas: GetInstrumentQuery['ideasPaginated'];
  /** now time when query executed to get all prices prior to this. Use `getPriceDateFrom` to generate */
  nowDate: Date;
  onRefresh: () => void;
  refreshing: boolean;
};

export const Overview: React.FC<Props> = ({ instrument, ideas, nowDate, onRefresh, refreshing }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<LoggedInStackNavigationProps>();
  const instrumentId = instrument?.id;
  const ideaId = getActiveIdeaId(ideas);
  const { toggleWatchlist } = useInstrumentWatchlistToggle(instrument, { showToasterOnMutation: true });
  const [esgViewed, setEsgViewed] = useState(false);

  const closePriceSeries = instrument?.closePriceSeries;
  const isInWatchlist = !!instrument?.watchlistId;
  const esgRef = useRef<View>(null);

  return (
    <ScrollView
      scrollEventThrottle={500}
      refreshControl={<RefreshControl onRefresh={onRefresh} refreshing={refreshing} />}
      onScroll={() => {
        isItemInView(esgRef, 0.3, () => {
          if (!esgViewed) {
            setEsgViewed(true);
          }
        });
      }}
    >
      <InstrumentHeader
        name={instrument?.name ?? ''}
        ticker={instrument?.ticker ?? ''}
        logoUrl={instrument?.logoUrl ?? undefined}
        sector={instrument?.sector?.name ?? undefined}
      />
      <InstrumentChart
        closePriceSeries={closePriceSeries ?? []}
        now={nowDate}
        currencyIso={instrument.currency?.iso ?? undefined}
        currentPrice={getCurrentPrice(instrument.quotePrice)}
      />
      <ScreenSidePadding>
        <View style={tailwind('pt-4 pb-6 flex-row border-b border-warmGray-300')}>
          <View style={tailwind('flex-1')}>
            <Button
              redirectIfLoggedOut
              onPress={() => toggleWatchlist()}
              StartIcon={isInWatchlist ? StarFilled : Star}
              variant={isInWatchlist ? 'secondary' : 'primary'}
              text={isInWatchlist ? 'Watching' : 'Watch'}
            />
          </View>
          {/* spacer */}
          <View style={tailwind('px-1')} />
          <View style={tailwind('flex-1')}>
            {ideaId ? (
              <Button
                variant="alternative"
                StartIcon={LightBulbFilled}
                text="View idea"
                redirectIfLoggedOut
                onPress={() => {
                  navigation.push('Idea', { ideaId });
                }}
              />
            ) : (
              <Button
                variant="alternative"
                StartIcon={LightBulb}
                text="Create Idea"
                redirectIfLoggedOut
                onPress={() => {
                  navigation.navigate('CreateIdea', { instrumentId: instrumentId ?? '' });
                }}
              />
            )}
          </View>
        </View>
        <View style={tailwind('py-8')}>
          <InstrumentSummaryBody partialView instrument={instrument} />
        </View>

        <View>
          <InstrumentFinancialsSummary instrument={instrument} />
        </View>
        {instrument.esgCurrent && (
          <>
            <View style={tailwind('pb-4 flex-row justify-between items-center')}>
              <Text style={tailwind('text-xl font-semibold text-warmGray-800')}>ESG</Text>
              <IconButton
                accessibilityLabel="Esg Info"
                hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
                onPress={() => visitExternalLink(EXTERNAL_URIs.ESG, navigation)}
                style={tailwind('px-2')}
                redirectIfLoggedOut
                icon={<Info height={16} width={16} />}
              />
            </View>
            <EsgScores ref={esgRef} viewed={esgViewed} {...instrument.esgCurrent} />
          </>
        )}

        {instrument.reportRatingCurrent && (
          <View style={tailwind('pt-6 pb-4')}>
            <Text style={tailwind('text-xl font-semibold text-warmGray-800 pb-4')}>Analyst Ratings</Text>
            <ReportRatingSnippet
              averageRating={instrument.reportRatingCurrent.modeRating ?? ReportRating.Hold}
              buyCount={instrument.reportRatingCurrent.buyCount ?? 0}
              holdCount={instrument.reportRatingCurrent.holdCount ?? 0}
              sellCount={instrument.reportRatingCurrent.sellCount ?? 0}
            />
          </View>
        )}
      </ScreenSidePadding>
    </ScrollView>
  );
};

const InstrumentFinancialsSummary: React.FC<{ instrument: GetInstrumentQuery['instrument'] }> = ({ instrument }) => {
  const tailwind = useTailwind();
  const navigation = useNavigation<NavigationProp<LoggedInStackParamList>>();
  const latestYearlyFinancials = instrument?.yearlyFinancials?.length
    ? _.maxBy(instrument.yearlyFinancials, (i) => new Date(i?.date ?? '').getTime())
    : null;

  if (!latestYearlyFinancials) return null;

  return (
    <View style={tailwind('pb-10')}>
      <View style={tailwind('flex-row justify-between items-center')}>
        <Text style={tailwind('text-xl font-semibold text-warmGray-800')}>Financials</Text>
        <Link
          redirectIfLoggedOut
          onPress={() =>
            navigation.navigate('InstrumentFinancials', {
              instrumentId: instrument.id,
            })
          }
        >
          See all
        </Link>
      </View>
      <Row>
        <InstrumentAboutBox
          title="Total revenue"
          content={formatContent(
            {
              amount: latestYearlyFinancials.totalRevenue,
              currencyIso: instrument.currency?.iso,
            },
            true,
          )}
        />
        <InstrumentAboutBox
          title="Cost of revenue"
          content={formatContent(
            {
              amount: latestYearlyFinancials.costOfRevenue,
              currencyIso: instrument.currency?.iso,
            },
            true,
          )}
        />
      </Row>
      <Row>
        <InstrumentAboutBox
          title="Net income"
          content={formatContent(
            {
              amount: latestYearlyFinancials.netIncome,
              currencyIso: instrument.currency?.iso,
            },
            true,
          )}
        />
        <InstrumentAboutBox title="Net margin" content={formatPercent(latestYearlyFinancials.netMargin ?? 0)} />
      </Row>
      <Text style={[tailwind('text-warmGray-500 italic pt-4'), { fontSize: 10 }]}>
        Values are annual ending {dayjs(new Date(latestYearlyFinancials.date ?? '')).format('MMM YYYY')}. Tap{' '}
        {"'See all' "} for more (incl. quarterly)
      </Text>
    </View>
  );
};

const getActiveIdeaId = (ideas: GetInstrumentQuery['ideasPaginated']): string | undefined =>
  ideas && ideas.nodes.length > 0 ? ideas.nodes[0].id : undefined;

const getCurrentPrice = (
  quotePrice: GetInstrumentQuery['instrument']['quotePrice'] | undefined,
): { price: number; date: Date } | undefined =>
  !isNil(quotePrice?.midPrice) && quotePrice?.lastUpdated
    ? {
        price: quotePrice.midPrice,
        date: parseUTCDateFromString(quotePrice.lastUpdated),
      }
    : undefined;
