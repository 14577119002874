import { FC } from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';
import { LayersAllColoured } from '../../ui/VectorImages';

export const InitialBodyUpper: FC = () => {
  const tailwind = useTailwind();
  return (
    <>
      <Text style={tailwind('text-base font-semibold text-default pt-6 pb-12')}>
        Let{"'"}s build your <Text style={tailwind('text-light font-semibold')}>Upside</Text> portfolio
      </Text>
      <View style={tailwind('items-center')}>
        <View style={tailwind('pr-6 pb-4')}>
          <LayersAllColoured />
        </View>
      </View>
    </>
  );
};
