import React from 'react';
import { View } from 'react-native';
import { useTailwind } from '../../theme';
import { Text } from '../../ui/Text';

type Props = {
  text: string;
  subText?: string;
  offsetHandler?: (offset: number) => void;
};

export const Stage: React.FC<Props> = ({ text, subText, children, offsetHandler }) => {
  const tailwind = useTailwind();
  return (
    <View
      style={tailwind('py-3 px-6')}
      onLayout={(e) => {
        offsetHandler && offsetHandler(e.nativeEvent.layout.y);
      }}
    >
      <Text style={tailwind('text-lg text-warmGray-800 font-normal')}>{text}</Text>
      {subText && <Text style={tailwind('text-xs text-warmGray-400 font-normal')}>{subText}</Text>}
      {children}
    </View>
  );
};
