import React from 'react';
import {
  Platform,
  // eslint-disable-next-line no-restricted-imports
  KeyboardAvoidingView as RNKeyboardAvoidingView,
  KeyboardAvoidingViewProps as RNKeyboardAvoidingViewProps,
} from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useTailwind } from '../../theme';

export const KeyboardAvoidingView: React.FC<RNKeyboardAvoidingViewProps> = (props) => {
  const tailwind = useTailwind();
  const insets = useSafeAreaInsets();
  return (
    <RNKeyboardAvoidingView
      keyboardVerticalOffset={Platform.OS === 'ios' ? insets.top : 0}
      style={tailwind('flex-grow')}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      {...props}
    />
  );
};
