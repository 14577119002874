import { VFC } from 'react';
import { FlatList, ListRenderItem, View } from 'react-native';
import { DatoAppContent } from '../../../generated/graphql';
import { useTailwind } from '../../../theme';
import { APP_CONTENT_CARD_WIDTH, AppContentCard } from './AppContentCard';

const APP_CONTENT_CARD_SPACING = 16;

type Props = {
  data: DatoAppContent[];
};
export const AppContentSideScroll: VFC<Props> = ({ data }) => {
  const tailwind = useTailwind();
  if (data.length === 0) return null;

  return (
    <View style={tailwind('pb-4')}>
      <FlatList
        horizontal
        contentContainerStyle={tailwind('px-6 py-2')}
        ItemSeparatorComponent={() => <View style={{ paddingLeft: APP_CONTENT_CARD_SPACING }} />}
        showsHorizontalScrollIndicator={false}
        data={data}
        keyExtractor={keyExtractor}
        snapToInterval={APP_CONTENT_CARD_WIDTH + APP_CONTENT_CARD_SPACING}
        snapToAlignment="start"
        decelerationRate="fast"
        renderItem={renderItem}
      />
    </View>
  );
};

const keyExtractor = (item: DatoAppContent) => item?.id ?? '';

const renderItem: ListRenderItem<DatoAppContent> = ({ item }) => <AppContentCard {...item} />;
