import { API_BASE_URL } from '../constants/env';
import { OpenAPI } from '../generated/open-api';
import type { ApiRequestOptions } from '../generated/open-api/core/ApiRequestOptions';
import { getAccessToken } from './jwt';

export const initOpenAPI: () => void = () => {
  OpenAPI.BASE = API_BASE_URL;

  // always send `mode: 'cors'` and `credentials: 'include'` in open api calls
  OpenAPI.WITH_CREDENTIALS = true;

  OpenAPI.HEADERS = {
    // Tell monolith what platform is being used for platform specific server functionality (e.g password reset email)
    ['X-Upside-Client']: 'mobile',
  };

  OpenAPI.TOKEN = async (options: ApiRequestOptions) => {
    let token = '';
    if (!options.path.startsWith('/auth/jwt') && !options.path.startsWith('/auth/users/reset_password')) {
      token = await getAccessToken();
    }
    return token ?? '';
  };
};
