import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { MotiView, useAnimationState } from 'moti';
import React from 'react';
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import Animated, { useAnimatedStyle, useDerivedValue, useSharedValue } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { LoggedOutStackParamList } from '../../navigation/RootStackNavigator';
import { Button as OldButton } from '../../old/Button';
import { Logo } from '../../old/Landing/Logo';
import { analytics } from '../../services/analytics';
import { colors, useTailwind } from '../../theme';
import { Button } from '../../ui/Button';
import { PagerView } from '../../ui/PagerView';
import { OffsetData } from '../../ui/PagerView/props';
import { SafeAreaView } from '../../ui/SafeAreaView';
import { Text } from '../../ui/Text';
import { Alpha, Coffee, Panels, Torch } from './images';

type PageInterface = {
  key: number;
  text: string;
  subText: string;
  image: React.ReactNode;
};
const pages: PageInterface[] = [
  {
    key: 0,
    text: 'An investment journey centred around you',
    subText: "We'll help you become a more confident and skilled investor.",
    image: <Panels height="100%" />,
  },
  {
    key: 1,
    text: 'Uncover your next investment Idea',
    subText: 'Create your own Ideas and get feedback. Scout Ideas within the Upside community.',
    image: <Torch height="100%" />,
  },
  {
    key: 2,
    text: 'Build a personalised Portfolio Blueprint',
    subText: 'Build a personalised Portfolio Blueprint based on your profile and the Ideas you believe in',
    image: <Coffee height="100%" />,
  },
  {
    key: 3,
    text: 'Explore and Learn',
    subText: "Explore our wide universe of stocks and learn more about the companies you're interested in.",
    image: <Alpha height="100%" />,
  },
];

export type Props = NativeStackScreenProps<LoggedOutStackParamList, 'Landing'>;
export const Landing: React.FC<Props> = ({ navigation }) => {
  const tailwind = useTailwind();
  const offset = useSharedValue(0);
  const { width: PAGE_WIDTH, height: WINDOW_HEIGHT } = useWindowDimensions();
  const { top: TOP_INSET, bottom: BOTTOM_INSET } = useSafeAreaInsets();
  const pageHeight = WINDOW_HEIGHT - TOP_INSET - BOTTOM_INSET;
  const animation = useAnimationState({
    from: {
      opacity: 0,
      scale: 0.2,
    },
    to: {
      opacity: 1,
      scale: 1,
    },
  });
  const activeIndex = useDerivedValue(() => Math.round(offset.value));

  const pagerHandler = React.useCallback(
    (e: OffsetData) => {
      'worklet';
      offset.value = e.offset;
    },
    [offset],
  );

  const onPageSelected = (position: number) => {
    analytics.track('Landing pager', { page: position });
  };

  return (
    <View style={tailwind('flex-grow')}>
      <MotiView
        transition={{ type: 'spring' }}
        state={Platform.OS === 'web' ? undefined : animation}
        style={tailwind('flex-1 overflow-hidden')}
      >
        <SafeAreaView>
          <PagerView onPageScroll={pagerHandler} onPageSelected={onPageSelected}>
            {pages.map((page) => (
              <Page
                key={page.key}
                text={page.text}
                subText={page.subText}
                image={page.image}
                width={PAGE_WIDTH}
                height={pageHeight}
              />
            ))}
          </PagerView>
          <View style={[StyleSheet.absoluteFill, tailwind('flex justify-between')]} pointerEvents="box-none">
            <Logo style={[tailwind('self-center'), { height: HEADER_HEIGHT }]} pointerEvents="none" />
            <View style={[tailwind('px-6 flex justify-around'), { height: FOOTER_HEIGHT }]} pointerEvents="box-none">
              <View style={tailwind('flex-row justify-center pb-3')} pointerEvents="none">
                {pages.map((_, index) => (
                  <Dot key={index} activeIndex={activeIndex} index={index} />
                ))}
              </View>
              <Button
                onPress={() =>
                  navigation.navigate('OnboardingGetStarted', { isMarketing: false, showBackButton: true })
                }
                text="Get started"
                size="large"
                variant="contained"
                isFullWidth
              />
              <OldButton
                onPress={() => navigation.navigate('LogIn')}
                text="Sign in"
                size="lg"
                variant="inverted-bright"
              />
            </View>
          </View>
        </SafeAreaView>
      </MotiView>
    </View>
  );
};

const Page: React.VFC<{ text: string; subText: string; image: React.ReactNode; width: number; height: number }> = ({
  text,
  subText,
  image,
  height,
}) => {
  const tailwind = useTailwind();
  return (
    <View
      style={[
        tailwind('flex px-6 items-center justify-evenly'),
        { height: height },
        { paddingTop: HEADER_HEIGHT, paddingBottom: FOOTER_HEIGHT },
      ]}
    >
      <View style={{ maxHeight: height - HEADER_HEIGHT - FOOTER_HEIGHT - 156, flexGrow: 1 }}>{image}</View>
      <Text style={tailwind('text-lg font-semibold text-warmGray-700 text-center')}>{text}</Text>
      <Text style={tailwind('text-base font-normal text-warmGray-700 text-center')}>{subText}</Text>
    </View>
  );
};

const HEADER_HEIGHT = 116;
const FOOTER_HEIGHT = 156;

const Dot: React.VFC<{ activeIndex: Animated.SharedValue<number>; index: number }> = ({ activeIndex, index }) => {
  const tailwind = useTailwind();
  const color = useAnimatedStyle(() => {
    const isActive = activeIndex.value === index;
    return {
      backgroundColor: isActive ? colors.amber['400'] : colors.warmGray['300'],
    };
  });
  return <Animated.View style={[tailwind('rounded-full h-2.5 w-2.5 mx-1'), color]} />;
};
